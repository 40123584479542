import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-handset-provisioning',
  templateUrl: './handset-provisioning.component.html',
  styleUrls: ['./handset-provisioning.component.css']
})
export class HandsetProvisioningComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
