import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { LoginService } from '../../../login/login.service';
import { SwitchboardService } from '../../../switchboard/switchboard.service';

import { SwitchboardObject } from '../../../switchboard/switchboard_object';

@Component({
  selector: 'app-portal-menu-edit',
  templateUrl: './portal-menu-edit.component.html',
  styleUrls: ['./portal-menu-edit.component.css']
})
export class PortalMenuEditComponent implements OnInit {

    account_id;
    parent;

    uuid: string;
    switchboard_object: SwitchboardObject;

    options = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'star', 'hash'];

    constructor(
        private route: ActivatedRoute,
        private authService: LoginService,
        private switchboardService: SwitchboardService
    ) { }

    ngOnInit() {
        this.uuid = this.route.snapshot.paramMap.get('uuid');
        this.getSwitchboardObject();
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account_id = account.id;
            this.getParent();
        });
    }

    getParent() {
        const parent_uuid = this.route.snapshot.queryParamMap.get('parent');
        if (parent_uuid) {
            this.switchboardService.getSwitchboardObject(parent_uuid)
                .subscribe(parent => this.parent = parent);
        }
    }

    backToParent() {
        window.history.go(-2);
    }

    getSwitchboardObject() {
        this.switchboardService.getSwitchboardObject(this.uuid)
            .subscribe((object: SwitchboardObject) => this.switchboard_object = object);
    }

    countOptions() {
        let count = 0;
        for (let option of this.options) {
            if (this.switchboard_object.object_data[`option_${option}_uuid`]) {
                count += 1;
            }
        }

        return `${count}`;
    }

}
