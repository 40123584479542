<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">DNO Blocklist</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">DNO Blocklist</h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <p *ngIf="blocks?.messages?.datetime"><strong>Last upload at</strong>: {{ blocks?.messages?.datetime|date:'medium' }}</p>

                <h3>Upload Blocklist</h3>
                <app-media-upload-form (save)="getDNOBlocks()" uploadTo="dno-blocklist"></app-media-upload-form>
            </div>
        </div>
    </div>
</div>