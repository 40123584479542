<app-modal #memberModal>
    <app-tabs>
        <app-tab name="Divert Numbers" [isActive]="!isVoip">
            <div class="table-responsive">
                <table class="table" *ngIf="external_members else is_loading">
                    <thead class="thead">
                        <tr class="tr">
                            <th class="th">Member</th>
                            <th class="th" *ngIf="object.object_type === 'Queue'">Penalty</th>
                            <th class="th">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody class="tbody">
                        <tr class="tr" *ngFor="let member of external_members">
                            <ng-container *ngIf="member.editing else static_external"> 
                                <td class="td">
                                    <input class="input form-control" type="text" placeholder="E164 Number" [(ngModel)]="member.e164_number" [disabled]="external_disabled">
                                </td>
                                <td class="td" *ngIf="object.object_type === 'Queue'">
                                    <input class="input form-control" type="text" placeholder="Penalty" [(ngModel)]="member.penalty" [disabled]="external_disabled">
                                </td>
                                <td class="td">
                                    <button class="btn btn-success" (click)="saveExternalMember(member)" [disabled]="external_disabled">Save</button>
                                    <button class="btn btn-danger" (click)="member.editing = false" [disabled]="external_disabled">Cancel</button>
                                </td>
                            </ng-container>
                            <ng-template #static_external>
                                <td class="td">
                                    <span class="flag-icon flag-icon-{{ (member.e164_number|number_to_country_code).toLowerCase() }}" [title]="member.e164_number|number_to_country_prefix"></span>
                                    {{ member.e164_number|number_to_local }}
                                    <ng-container *ngIf="member.rates">
                                        <br>
                                        <ng-container *ngIf="member.rates.retail_connection_charge > 0">
                                            <span class="text-muted">{{member.rates.retail_connection_charge}}p per connection</span>
                                            <br>
                                        </ng-container>
                                        <span class="text-muted">{{member.rates.retail_cost_per_minute}}p per minute</span>
                                    </ng-container>
                                </td>
                                <td class="td" *ngIf="object.object_type === 'Queue'">
                                    {{ member.penalty }}
                                </td>
                                <td class="td">
                                    <!-- <button class="btn btn-secondary" (click)="member.editing = true" [disabled]="external_disabled">Edit</button> -->
                                    <button class="btn btn-secondary d-none d-md-inline-block" (click)="rateCheck(member)" [disabled]="external_disabled">Rate</button>
                                    <button class="btn btn-danger" (click)="deleteExternalMember(member)" [disabled]="external_disabled">Delete</button>
                                </td>
                            </ng-template>
                        </tr>
                    </tbody>
                    <tfoot class="tfoot">
                        <tr class="tr">
                            <th>
                                <form [formGroup]="phoneForm" #telephoneForm>
                                    <ngx-intl-tel-input
                                            [preferredCountries]="[CountryISO.UnitedKingdom, CountryISO.UnitedStates]"
                                            [selectedCountryISO]="CountryISO.UnitedKingdom"
                                            [enableAutoCountrySelect]="true"
                                            [enablePlaceholder]="true"
                                            [searchCountryFlag]="true"
                                            [selectFirstCountry]="false"
                                            [phoneValidation]="true"
                                            [numberFormat]="PhoneNumberFormat.National"
                                            name="phone"
                                            formControlName="phone"
                                            #telephoneInput
                                        ></ngx-intl-tel-input>
                                </form>
                            </th>
                            <th class="th">
                                <button class="btn btn-success" (click)="addExternalMember()" [class.is-loading]="external_disabled">Add Divert Number</button>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </app-tab>
        <app-tab name="VoIP Users" [isActive]="isVoip">
                <table class="table table-striped" *ngIf="internal_members else is_loading">
                    <thead class="thead">
                        <tr class="tr">
                            <th class="th">Member</th>
                            <th class="th" *ngIf="object.object_type === 'Queue'">Penalty</th>
                            <th class="th">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody class="tbody">
                        <tr class="tr" *ngFor="let member of internal_members">
                            <ng-container *ngIf="member.editing else static_internal"> 
                                <td class="td">
                                    <select class="form-control" [(ngModel)]="member.sip_switchboard_object_uuid" disabled>
                                        <ng-container *ngFor="let sip of sips">
                                            <option value="{{ sip.uuid }}">
                                                {{ sip.extension_number }} - {{ sip.object_data.name }}
                                            </option>
                                        </ng-container>
                                    </select>
                                </td>
                                <td class="td" *ngIf="object.object_type === 'Queue'">
                                    <input class="input form-control" type="text" placeholder="Penalty" [(ngModel)]="member.penalty" [disabled]="internal_disabled">
                                </td>
                                <td class="td">
                                    <button class="btn btn-success" (click)="saveInternalMember(member)" [disabled]="internal_disabled">Save</button>
                                    <button class="btn btn-danger" (click)="member.editing = false" [disabled]="internal_disabled">Cancel</button>
                                </td>
                            </ng-container>
                            <ng-template #static_internal>
                                <td class="td">
                                    {{ member.sip.extension_number }} - {{ member.sip.object_data.name }}
                                </td>
                                <td class="td" *ngIf="object.object_type === 'Queue'">
                                    {{ member.penalty }}
                                </td>
                                <td class="td">
                                    <button class="btn btn-secondary" *ngIf="object.object_type === 'Queue'" (click)="member.editing = true;member.sip_switchboard_object_uuid = member.sip.uuid" [disabled]="internal_disabled">Edit</button>
                                    <button class="btn btn-danger" (click)="deleteInternalMember(member)" [disabled]="internal_disabled">Delete</button>
                                </td>
                            </ng-template>
                        </tr>
                    </tbody>
                    <tfoot class="tfoot">
                        <tr class="tr">
                            <th class="th">
                                <select class="form-control" [(ngModel)]="internal_member.sip_switchboard_object_uuid" [disabled]="internal_disabled">
                                    <ng-container *ngFor="let sip of sips">
                                        <option value="{{ sip.uuid }}" *ngIf="notAttached(sip)">
                                            {{ sip.extension_number }} - {{ sip.object_data.name }}
                                        </option>
                                    </ng-container>
                                </select>
                            </th>
                            <th class="th" *ngIf="object.object_type === 'Queue'">
                                <input class="input form-control" type="text" placeholder="Penalty" [(ngModel)]="internal_member.penalty" [disabled]="internal_disabled">
                            </th>
                            <th class="th">
                                <button class="btn btn-success" (click)="addInternalMember()" [class.is-loading]="internal_disabled">Add VoIP User</button>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </app-tab>
    </app-tabs>
    <app-rate-checker bundles="all" [show]="false" #rate_checker></app-rate-checker>
</app-modal>

<ng-template #is_loading>
    <table class="table">
        <tr class="tr">
            <td class="td" colspan="3">
                <app-loadingbar></app-loadingbar>
            </td>
        </tr>
    </table>
</ng-template>

