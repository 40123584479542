import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { ReportsService } from '../reports.service';

@Component({
    selector: 'app-call-block-report',
    templateUrl: './call-block-report.component.html',
    styleUrls: ['./call-block-report.component.css']
})
export class CallBlockReportComponent implements OnInit {

    @Input() account_id;

    report;
    page: number;


    constructor(
        private reportsService: ReportsService,
    ) { }

    ngOnInit(): void {
        this.getReports();
    }

    getReports(page = 1) {
        this.page = page;
        this.report = null;
        this.reportsService.getCallBlocks(this.account_id, this.page)
            .subscribe(report => this.report = report);
    }
}
