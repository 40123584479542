<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{ direction|capitalize }} Global Blocking</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">{{ direction|capitalize }} Global Blocking</h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <p class="text-lg">
                    <a class="btn btn-dark" routerLink="/global-blocks/inbound">Inbound</a>
                    <a class="btn btn-dark" routerLink="/global-blocks/outbound">Outbound</a>
                    <a class="btn btn-dark" routerLink="/global-blocks/email">Email</a>
                </p>
                <ul class="list-group">
                    <li class="list-group-item">
                        <div class="input-group mb-3">
                            <input type="text" [(ngModel)]="search" class="form-control" placeholder="Search..">
                            <div class="input-group-append">
                                <button class="btn btn-primary" type="button" id="button-call-block" (click)="searchBlocks()">Search</button>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <ng-container *ngIf="direction === 'inbound'">
                            <input type="text" [(ngModel)]="new_number" class="form-control" placeholder="Number to Block" aria-label="Block Number" aria-describedby="button-call-block">
                        </ng-container>
                        <ng-container *ngIf="direction === 'email'">
                            <input type="text" [(ngModel)]="new_number" class="form-control" placeholder="Email to Block" aria-label="Block Email" aria-describedby="button-call-block">
                        </ng-container>
                        <ng-container *ngIf="direction === 'outbound'">
                            <div class="input-group mb-3">
                                <form [formGroup]="phoneForm" #telephoneForm>
                                    <ngx-intl-tel-input
                                        cssClass="form-control telephone-input"
                                        [preferredCountries]="[CountryISO.UnitedKingdom, CountryISO.UnitedStates]"
                                        [selectedCountryISO]="CountryISO.UnitedKingdom"
                                        [enableAutoCountrySelect]="true"
                                        [enablePlaceholder]="true"
                                        [searchCountryFlag]="true"
                                        [selectFirstCountry]="false"
                                        [phoneValidation]="true"
                                        [numberFormat]="PhoneNumberFormat.National"
                                        name="phone"
                                        formControlName="phone"
                                        #telephoneInput
                                    ></ngx-intl-tel-input>
                                </form>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="direction !== 'email'">
                            <br>
                            <label>
                                Trial Accounts 
                                &nbsp;
                                <i class="fas fa-lightbulb active-state-1" title="Trial Accounts"></i>
                                &nbsp;
                                <input type="radio" name="block-level" [(ngModel)]="block_level" [value]="1">
                            </label>
                            <br>
                            <label>
                                Active & Trial Accounts
                                &nbsp;
                                <i class="fas fa-lightbulb active-state-1" title="Trial Accounts"></i>
                                <span>+</span>
                                <i class="fas fa-lightbulb active-state-2" title="Active Accounts"></i>
                                &nbsp;
                                <input type="radio" name="block-level" [(ngModel)]="block_level" [value]="2">
                            </label>
                        </ng-container>
                        <br>
                        <button class="btn btn-primary" type="button" id="button-call-block" (click)="block()">Block</button>
                    </li>
                    <li class="list-group-item" *ngFor="let call_restriction of call_restrictions?.data">
                        {{ call_restriction.e164_number }}
                        {{ call_restriction.email }}
                        <ng-container *ngIf="direction !== 'email'">
                            -
                            <i class="fas fa-lightbulb active-state-1" *ngIf="call_restriction.block_level >= 1" title="Trial Accounts"></i>
                            <span *ngIf="call_restriction.block_level === 2">+</span>
                            <i class="fas fa-lightbulb active-state-2" *ngIf="call_restriction.block_level === 2" title="Active Accounts"></i>
                            &nbsp;
                            <button class="btn btn-secondary btn-sm float-right" (click)="modify(call_restriction)">Modify</button>
                        </ng-container>
                        <button class="btn btn-danger btn-sm float-right" (click)="unblock(call_restriction)">Unblock</button>
                    </li>
                    <li class="list-group-item" *ngIf="call_restrictions?.data.length === 0">No Call Restrictions</li>
                    <li class="list-group-item">
                        <app-pagination [pagination]="call_restrictions" url="/global-blocks/{{ direction }}"></app-pagination>
                    </li>
                    <li class="list-group-item" *ngIf="!call_restrictions">
                        <app-loadingbar></app-loadingbar>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>


<app-modal title="Modify Block Level" (save)="updateLevel()" #modify_level>
    <label>
        Trial Accounts 
        &nbsp;
        <i class="fas fa-lightbulb active-state-1" title="Trial Accounts"></i>
        &nbsp;
        <input type="radio" name="block-level-edit" [(ngModel)]="block_level_edit" [value]="1">
    </label>
    <br>
    <label>
        Active & Trial Accounts
        &nbsp;
        <i class="fas fa-lightbulb active-state-1" title="Trial Accounts"></i>
        <span>+</span>
        <i class="fas fa-lightbulb active-state-2" title="Active Accounts"></i>
        &nbsp;
        <input type="radio" name="block-level-edit" [(ngModel)]="block_level_edit" [value]="2">
    </label>
</app-modal>
