import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { LoginService } from '../../login/login.service';
import { BillingService } from '../../billing.service';

import { ClipboardHelper } from '../../tools/clipboard.helper';
import { AuthenticationHelper } from '../../login/authentication.helper';
import { LocationHelper } from '../../location.helper';

import { Account } from '../../accounts/account';

declare var moment: any;
declare var $: any;

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

    readonly sid_start: number = 1000000;

    account: Account;

    @Input() isHome: boolean;
    card_toggle = false;

    user: any;
    auth_code: any;
    supportUrl;

    @ViewChild('balanceCard') balanceCard;

    constructor(
        private loginService: LoginService,
        private billingService: BillingService,
    ) { }

    ngOnInit() {
        this.loginService.getCurrentUser()
            .subscribe(user => this.user = user);
        this.supportUrl = LocationHelper.getSupport();

        this.loginService.getCurrentAccount()
        .subscribe(account => {
            this.account = account;
            this.billingService.checkTestCredit(this.account.id)
                .subscribe(() => this.getBalance());
            this.loginService.getAccountSupportAuth(this.account.id)
                .subscribe(auth => this.auth_code = auth.auth_code);
        });
    }

    toggleCards() {
        this.card_toggle = !this.card_toggle;
    }

    logout() {
        this.loginService.logout();
    }

    isProxy() {
        return AuthenticationHelper.isProxy();
    }

    supportCodeToClipboard() {
        ClipboardHelper.addToClipboard(this.auth_code);
    }

    public getBalance() {
        if (this.balanceCard) {
            this.balanceCard.refresh();
        }
    }

}
