<app-modal title="Select Media" (save)="saveMedia()" #media_modal>
    <ng-container *ngIf="upload_type === 'existing'">
        <p *ngIf="type === 'media'">Select the media to be played to the caller - if you dont have any media saved to select then go to the <a routerLink="/media">media folder</a> and create a new file</p>
        <p *ngIf="type === 'music'">Select the music to be played to the caller - if you dont have any music saved to select then go to the <a routerLink="/media">music folder</a> and create a new file</p>
        <div class="input-group">
            <select class="form-control" [(ngModel)]="new_media">
                <option [ngValue]="null">[No media]</option>
                <option *ngFor="let file of media" [value]="file.uuid" 
                        [ngStyle]="{
                            'color': file.is_verified === 1 ? 'black' : 
                                    file.is_verified === 0 ? 'gray' : 
                                    file.is_verified === 2 ? 'red' : 
                                    file.is_verified === null ? 'black' : 'black'
                        }"                
                        [disabled]="file.is_verified === 0 || file.is_verified === 2">
                    {{ file.name }} 
                    <ng-container *ngIf="file.is_verified === 0"> (Pending Verification)</ng-container>
                    <ng-container *ngIf="file.is_verified === 2"> (Not Verified)</ng-container>
                </option>

            </select>

            <div class="input-group-append">
                <button class="btn btn-success" (click)="previewMedia()" target="_blank">
                    Preview

                    <i class="fas fa-play" *ngIf="media_preview && !media_preview.paused"></i>
                </button>
            </div>

        </div>

        <audio *ngIf="media_preview" id="media-preview" controls controlsList="nodownload"></audio>

        <p class="text-center text-lg">
            <strong>Or</strong>
        </p>

        <button class="btn btn-block btn-warning" (click)="upload_type = null">Select another option</button>
    </ng-container>

    <ng-container *ngIf="upload_type === 'upload'">
        <ng-container *ngIf="!uploading">
            <app-media-upload-form [account_id]="account_id" (save)="uploadFile($event)" [uploadTo]="type === 'music' ? 'music' : 'switchboard'" #upload_form></app-media-upload-form>

            <p class="text-center text-lg">
                <strong>Or</strong>
            </p>

            <button class="btn btn-block btn-warning" (click)="upload_type = null">Select another option</button>
        </ng-container>

        <ng-container *ngIf="uploading">
            <app-loadingbar></app-loadingbar>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="upload_type === 'tts'">
        <ng-container *ngIf="!uploading">
            <ng-container *ngIf="type === 'media'">
                <p class="text-center text-lg">
                    <strong>Type in your script. Select the language and voice model</strong>
                </p>

                <app-portal-tts-form [tts_text]="tts_text" [account_id]="account_id" (save)="createTTS($event)" #tts_form></app-portal-tts-form>

                <br>

                <div class="input-group">
                    <button (click)="tts_form.preview()" class="btn btn-primary">Preview</button>
                    &nbsp;
                    <!-- <button class="btn btn-primary" (click)="createTTS()">Create and Save</button> -->
                </div>

                <p class="text-center text-lg">
                    <strong>Or</strong>
                </p>

                <button class="btn btn-block btn-warning" (click)="upload_type = null">Select another option</button>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="uploading">
            <app-loadingbar></app-loadingbar>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="upload_type === null">
        <div class="row">
            <div class="col">
                <div class="card text-center selection-card" *ngIf="media?.length > 0" (click)="upload_type = 'existing'">
                    <div class="card-body darkcard cardrounded">
                        <i class="fa-solid fa-file-audio"></i>
                        <h5 class="card-title">Use an existing audio file</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="card text-center selection-card" (click)="upload_type = 'upload'">
                    <div class="card-body darkcard cardrounded">
                        <i class="fa-solid fa-upload"></i>
                        <h5 class="card-title">Upload a new audio file</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="card text-center selection-card" *ngIf="type === 'media'" (click)="upload_type = 'tts'">
                    <div class="card-body darkcard cardrounded">
                        <i class="fa-regular fa-comment-dots"></i>
                        <h5 class="card-title">Create audio using text to speech</h5>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</app-modal>
