<ng-container *ngIf="logged_in !== null; else loading">
    <ng-container *ngIf="logged_in; else guest_view">
        <ng-container *ngTemplateOutlet="authenticated_view"></ng-container>
    </ng-container>
</ng-container>

<ng-template #authenticated_view>
    <app-dashboard [isHome]="isHome">
        <router-outlet></router-outlet>
    </app-dashboard>
</ng-template>

<ng-template #guest_view>
    <app-basic-layout>
        <router-outlet></router-outlet>
    </app-basic-layout>
</ng-template>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
