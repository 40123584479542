import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { SwitchboardService } from '../../switchboard/switchboard.service';

import { SwitchboardServer } from './switchboard-server.model';

declare var toastr: any;
declare var alertify: any;
declare var Chart: any;

@Component({
    selector: 'app-switchboard-server',
    templateUrl: './switchboard-server.component.html',
    styleUrls: ['./switchboard-server.component.css']
})
export class SwitchboardServerComponent implements OnInit {

    servers: SwitchboardServer[];
    outbound_servers: any[];

    current_server: SwitchboardServer = new SwitchboardServer();
    droplet_info: any;

    @ViewChild('serverDetail') serverDetail;
    @ViewChild('dropletInfo') dropletModal;

    constructor(
        private switchboardService: SwitchboardService
    ) { }

    ngOnInit() {
        this.switchboardService.getOutboundServers()
            .subscribe(servers => this.outbound_servers = servers);
        this.loadServers();
    }

    loadCommitStats() {
        let serverCount = this.servers.length;
        for (let server of this.servers) {
            this.switchboardService.getPBXCommitStats(server.server_host)
            .subscribe(stats => {
                server['stats'] = stats;
                serverCount -= 1;
                if (serverCount === 0) {
                    this.makeStatsChart();
                }
            });
        }
    }

    loadServers() {
        this.servers = null;
        this.switchboardService.getSwitchboardServers()
        .subscribe(servers => {
            this.servers = servers;
            this.loadCommitStats();
        });
    }

    newServer() {
        this.current_server = new SwitchboardServer();
        this.serverDetail.title = 'New Server';
        this.serverDetail.open();
    }

    editServer(server) {
        this.current_server = server;
        this.serverDetail.title = 'Edit Server';
        this.serverDetail.open();
    }

    deleteServer(server: SwitchboardServer) {
        alertify.confirm(`Are you sure you want to delete ${server.name}`, () => {
            this.switchboardService.deleteSwitchboardServer(server)
                .subscribe(_ => this.loadServers());
        });
    }

    saveServer() {
        let request;
        if (this.current_server.id) {
            request = this.switchboardService.updateSwitchboardServer(this.current_server);
        } else {
            request = this.switchboardService.createSwitchboardServer(this.current_server);
        }

        request.subscribe(response => {
            this.serverDetail.loading = false;
            if (response + '') {
                this.serverDetail.close();
                this.loadServers();
                toastr.success('Switchboard Server Saved!');
            }
        });
    }

    commit(server: SwitchboardServer) {
        toastr.info(`Commiting ${server.name}`);
        this.switchboardService.commitSwitchboardServer(server.server_host)
            .subscribe(() => toastr.success(`${server.name} comitted!`));
    }

    makeStatsChart() {
        const canvasElement = <HTMLCanvasElement>document.getElementById('chart-stats');
        const ctx = canvasElement.getContext('2d');
        const chart = new Chart(ctx, {
            // type: 'horizontalBar',
            type: 'bar',
            data: {
                datasets: [
                    {
                        label: 'Seconds to Commit',
                        data: this.servers.map(server => server['last_commit_benchmark_seconds']),
                        backgroundColor: '#f5365c',
                    },
                ],
                labels: this.servers.map(server => server.name),
            },
            options: {
                responsive: true,
                // scales: {
                //     xAxes: [{
                //         stacked: true,
                //         barThickness: 50,
                //         gridLines: {
                //             offsetGridLines: true,
                //         },
                //     }],
                //     yAxes: [{
                //         stacked: true,
                //         barThickness: 50,
                //         gridLines: {
                //             offsetGridLines: true,
                //         }
                //     }],
                // }
            }
        });
    }

    getDropletInfo(server) {
        this.current_server = server;
        this.droplet_info = null;
        this.switchboardService.getServerDropletInfo('switchboard', server)
            .subscribe(info => this.droplet_info = info);
        this.dropletModal.open();
    }

}
