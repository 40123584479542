import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { Observable, of } from 'rxjs';
import { catchError, map, tap, concatMap } from 'rxjs/operators';

import { Client } from '../tools/client';

import { LoginService } from '../login/login.service';

@Injectable({
    providedIn: 'root'
})
export class ReportsService extends Client {

    protected serviceURL = environment.services.switchboard;

    constructor(
        http: HttpClient,
        private authService: LoginService,
    ) {
        super(http);
    }

    getFraudReport(account_id) {
        return this.callApi('get', `/accounts/${account_id}/fraud_report`)
        .pipe(
            tap(_ => this.log(`fetching account fraud report for account ${account_id}`)),
            catchError(this.handleError('getFraudReport', []))
        );
    }

    getCallBlocks(account_id, page = 1) {
        return this.callApi('get', `/accounts/${account_id}/reports/cdr/call_blocks`, { page: page })
        .pipe(
            tap(_ => this.log(`fetching account call blocks report for account ${account_id}`)),
            catchError(this.handleError('getCallBlocks', []))
        );
    }
}
