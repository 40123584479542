<div class="table-responsive">

    <table class="table align-items-center table-flush table-float-header table-striped">

        <thead>
            <tr>
                <th>Card Details</th>
                <th>Amount</th>
                <th>Transaction Status</th>
                <th>Transaction Made</th>
                <th *appViperHasUserRole="'Admin'">&nbsp;</th>
            </tr>
        </thead>
        <tbody *ngIf="history && history.data?.length else no_history">
            <tr *ngFor="let card of history.data" [class.ignored]="card.ignore_record">
                <td>
                    <span class="text-lg">
                        <i [title]="card.card_type" class="fab fa-cc-{{ card.card_type.toLowerCase() }}">&nbsp;{{ card.card_type }}</i>
                        <br>
                        {{ card.cardholder_name }}
                    </span>
                    <br>
                    <span class="text-sm text-muted">**** **** **** {{ card.last4 }}</span>
                    <br>
                    <span class="text-sm text-muted"><strong>Expires</strong>: {{ card.expiration_month }}/{{ card.expiration_year }}</span>
                </td>
                <td>
                    &pound;{{ card.transaction_amount|format_balance }}
                </td>
                <td [class.text-success]="card.processor_response_code < 2000"
                    [class.text-danger]="!card.processor_response_code || (card.processor_response_code >= 2000 && card.processor_response_code < 3000)"
                    [class.text-warning]="card.processor_response_code >= 3000">
                    {{ card.transaction_status }}
                    ({{ card.processor_response_code }})
                    <ng-container *ngIf="card.gateway_rejection_reason">
                        <strong>({{ card.gateway_rejection_reason }})</strong>
                    </ng-container>
                </td>
                <td>
                    {{ (card.created_at)|date:'medium' }}
                </td>
                <td *appViperHasUserRole="'Admin'">
                    <a class="btn btn-primary btn-sm" routerLink="/accounts/detail/{{ card.account_id }}" *ngIf="!account_id">Go To Account ({{ card.account_id + 1000000 }})</a>
                    <br>
                    <br>
                    <button class="btn btn-sm btn-danger" (click)="toggleIgnore(card)">
                        {{ card.ignore_record ? 'Unignore' : 'Ignore' }}
                    </button>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td [attr.colspan]="4">
                    <app-pagination [pagination]="history" [(page)]="history" (page_change)="changePage($event)"></app-pagination>
                </td>
            </tr>
        </tfoot>
    </table>
</div>

<ng-template #no_history>
    <tr>
        <td [attr.colspan]="4">
            <ng-container *ngIf="history else loading">
                No Card History
            </ng-container>
        </td>
    </tr>
</ng-template>

<ng-template #loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
