import { environment } from '../../environments/environment';
import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PipesModule } from '../pipes/pipes.module';
import { ComponentsModule } from '../components/components.module';

import { ReportsRoutingModule } from './reports-routing.module';

import { FraudReportComponent } from './fraud-report/fraud-report.component';
import { CallBlockReportComponent } from './call-block-report/call-block-report.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,

        NgbModule,
        // RecaptchaV3Module,
        // NgxIntlTelInputModule,

        PipesModule,
        // LayoutModule,
        ComponentsModule,
        // SwitchboardModule,
        // SectionsModule,
        // AudioModule,

        // PortalComponentsModule,
        // PortalBillingModule,
        // PortalSettingsModule,

        ReportsRoutingModule,
    ],
    declarations: [
        FraudReportComponent,
        CallBlockReportComponent,
    ],
    exports: [
        FraudReportComponent,
        CallBlockReportComponent,
    ],
    bootstrap: []
})

export class ReportsModule { }
