import { Component, OnInit, Input, ViewChild } from '@angular/core';
// import { ActivatedRoute } from '@angular/router';
// import { Router } from '@angular/router';

import { LoginService } from '../../login/login.service';
import { SwitchboardService } from '../../switchboard/switchboard.service';
import { NotificationService } from '../../notification.service';
import { BillingService } from '../../billing.service';

import { User } from '../user';
import { Account } from '../account';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

    @Input() account_id;

    currentUser: User;
    user_config: any = {};

    sips: any[];

    public users: User[];

    email_logs;
    email_logs_user;

    @ViewChild('userDetail') userDetail;
    @ViewChild('emailLogsDetail') emailLogsDetail;

    constructor(
        private authService: LoginService,
        private switchboardService: SwitchboardService,
        private notificationService: NotificationService,
    ) { }

    ngOnInit() {
        this.getUsers();
        this.currentUser = new User();
    }

    getUsers() {
        let request;
        if (this.account_id) {
            request = this.authService.getUsersForAccount(this.account_id);
        } else {
            this.authService.getCurrentAccount()
                .subscribe(account => this.account_id = account.id);
            request = this.authService.getUsersForCurrentAccount();
        }
        request.subscribe(users => {
            this.users = users;
            this.getSips();
        });
    }

    getSips() {
        this.switchboardService.readSipsOnAccount(this.account_id)
            .subscribe(sips => this.sips = sips);
    }

    newUser() {
        this.currentUser = new User();
        this.userDetail.title = 'New User';
        this.user_config = {};
        this.userDetail.open();
    }

    editUser(user) {
        this.currentUser = user;
        this.userDetail.title = 'Edit User';
        this.authService.getUserConfig(this.account_id, user)
            .subscribe(config => this.user_config = config);
        this.userDetail.open();
    }

    saveUser() {
        let response;

        if (this.currentUser.id) {
            if (this.account_id) {
                response = this.authService.updateUserForAccount(this.account_id, this.currentUser);
            } else {
                response = this.authService.updateUserForCurrentAccount(this.currentUser);
            }
            response.subscribe(out => {
                this.authService.updateUserConfig(this.account_id, this.currentUser, this.user_config)
                    .subscribe(() => toastr.success('User config saved!'));
                this.userDetail.close();
            });
        } else {
            if (this.account_id) {
                response = this.authService.createUserForAccount(this.account_id, this.currentUser);
            } else {
                response = this.authService.createUserForCurrentAccount(this.currentUser);
            }

            response.subscribe(user => {
                this.authService.updateUserConfig(this.account_id, this.currentUser, this.user_config)
                    .subscribe(() => toastr.success('User config saved!'));
                this.getUsers();
                this.userDetail.close();
            });
        }
        toastr.success('User saved!');
    }

    deleteUser(user) {
        let response;

        if (user.deleted) {
            return toastr.error('User has already been deleted');
        }
        alertify.confirm(`Are you sure you want to delete ${user.email}?`, () => {
            if (this.account_id) {
                response = this.authService.deleteUserForAccount(this.account_id, user);
            } else {
                response = this.authService.deleteUserForCurrentAccount(user);
            }

            response.subscribe(out => {
                this.getUsers();
                this.userDetail.close();
            });
            user.deleted = true;
        });
    }

    viewLogs(user, page = null) {
        if (user) {
            this.email_logs_user = user;
        }

        if (page === null) {
            page = 1;
            this.emailLogsDetail.open();
        }

        this.notificationService.getEmailLogs(this.account_id, this.email_logs_user.id, page)
            .subscribe(email_logs => this.email_logs = email_logs);
    }

    resendVerification(user) {
        this.authService.sendVerificationEmail(user.account_id, user)
            .subscribe(response => toastr.success(`Verification email requested`));
    }

    forceVerification(user) {
        alertify.confirm(`Are you sure you want to manually verify this user?`, () => {
            this.authService.forceVerifyUser(user.account_id, user.id)
            .subscribe(response => {
                toastr.success(`User verified`);
                this.getUsers();
            });
        });
    }
}
