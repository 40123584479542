<div class="table-responsive table-striped">
    <table class="table is-bordered is-striped is-hoverable is-fullwidth">
        <thead class="thead">
            <tr class="tr">
                <th class="th">Amount</th>
                <th class="th">Reason</th>
                <th class="th">Created</th>
            </tr>
            <tr class="tr">
                <th class="th">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="input-group-text" id="btnGroupAddon">&pound;</div>
                        </div>
                        <input class="input form-control" type="text" placeholder="Amount" [(ngModel)]="transaction.amount" [disabled]="transaction_disabled">
                    </div>
                </th>
                <th class="th">
                    <input class="input form-control" type="text" placeholder="Reason" [(ngModel)]="transaction.reason" [disabled]="transaction_disabled">
                </th>
                <th class="th">
                    <button class="btn btn-primary is-primary" (click)="addTransaction()" [class.is-loading]="transaction_disabled">Add Transaction</button>
                </th>
            </tr>
        </thead>
        <tbody class="tbody" *ngIf="transactions && transactions.path else no_transactions">
            <tr class="tr" *ngFor="let transaction of transactions.data">
                <td class="td">
                    <div class="row">
                        <div class="col-4">
                            <span>(&pound;{{ transaction.prev_balance|format_balance }})</span>
                            <br>
                            <strong>&pound;{{ transaction.new_balance|format_balance }}</strong>
                        </div>
                        <div class="col-8 text-lg" [class.text-success]="transaction.amount > 0"
                                                 [class.text-danger]="transaction.amount <= 0">
                            {{ transaction.amount|format_balance }}
                        </div>
                    </div>
                </td>

                <td class="td">
                    {{ transaction.reason }}
                </td>
                <td class="td">
                    <ng-container *ngIf="findUser(transaction.user_id) as user else system_user">
                        <img ngxGravatar [email]="user.email" fallback="mm" alt="User Gravatar">
                        <br>
                        {{ user.first_name }} {{ user.last_name }}
                        <br>
                        ({{ user.email }})
                    </ng-container>
                    <ng-template #system_user>
                        System
                    </ng-template>
                    <br>
                    {{ (transaction.created_at)|date:'medium' }}
                </td>
            </tr>
        </tbody>
        <tfoot class="tfoot">
            <tr class="tr">
                <th class="th" colspan="3">
                    <app-pagination [pagination]="transactions" [(page)]="page" (page_change)="getTransactions($event)"></app-pagination>
                </th>
            </tr>
        </tfoot>
    </table>
</div>

<ng-template #no_transactions>
    <tbody class="tbody">
        <tr class="tr">
            <td class="td" colspan="3">
                <span *ngIf="transactions else is_loading">No Transactions Found</span>
            </td>
        </tr>
    </tbody>
</ng-template>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
