<div class="table-responsive">
    <table class="table">
        <thead>
            <tr>
                <th>Date</th>
                <th>Direction</th>
                <th>From</th>
                <th>To</th>
                <th>Reason</th>
                <th>Notification Sent</th>
            </tr>
        </thead>
        <tbody *ngIf="report && report.data?.length else no_report">
            <tr *ngFor="let record of report.data">
                <td>
                    {{ record.blocked_at|date:'medium' }}
                </td>
                <td>
                    {{ record.direction ? 'Outbound' : 'Inbound' }}
                </td>
                <td>
                    <ng-container *ngIf="record.from.length <= 10">
                        {{ record.from }}
                    </ng-container>
                    <ng-container *ngIf="record.from.length > 10">
                        <span class="flag-icon flag-icon-{{ (record.from|number_to_country_code).toLowerCase() }}" [title]="record.from|number_to_country_prefix"></span>
                        {{ record.from|number_to_local }}
                    </ng-container>
                </td>
                <td>
                    <span class="flag-icon flag-icon-{{ (record.to|number_to_country_code).toLowerCase() }}" [title]="record.to|number_to_country_prefix"></span>
                    {{ record.to|number_to_local }}
                </td>
                <td>
                    {{ record.reason }}
                </td>
                <td>
                    {{ record.notification_sent ? 'Yes' : 'No' }}
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td colspan="6">
                    <app-pagination [pagination]="report" [(page)]="page" (page_change)="getReports($event)"></app-pagination>
                </td>
            </tr>
        </tfoot>
    </table>
</div>

<ng-template #no_report>
    <tr>
        <td colspan="6" *ngIf="report">
            No call blocks found
        </td>
        <td colspan="6" *ngIf="!report">
            <app-loadingbar></app-loadingbar>
        </td>
    </tr>
</ng-template>