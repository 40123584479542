<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/announcements">Announcements</a></li>
                <li class="breadcrumb-item active" aria-current="page">Edit Announcement</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">
                            Announcement
                            <span *ngIf="switchboard_object">
                                {{ switchboard_object.object_data.name }}
                                -
                                <span class="text-muted text-sm" data-toggle="tooltip" data-placement="bottom" title="Every part of your system has an extension number that you can call directly using our VoIP service, or transfer a call to">
                                    Extension Number {{ switchboard_object.extension_number }}
                                    <button class="btn btn-sm btn-outline-secondary" (click)="rename_modal.open()">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                </span>
                            </span>

                        </h2>
                    </div>
                </div>
            </div>
            <div class="card-body" *ngIf="switchboard_object else loading">
                <ng-container *ngTemplateOutlet="switchboard_object_edit"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #switchboard_object_edit>
    <div *ngIf="parent" class="btn btn-lg btn-block btn-outline-info text-lg darkcard" (click)="backToParent()">
        <i class="far fa-hand-point-left"></i> Go Back
        <br>
        <span class="text-muted text-sm">
            <i [class]="parent.object_type|capitalize|switchboard_object_icon" [title]="parent.object_type"></i>
            {{ parent.object_data.name }}
        </span>
    </div>

    <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" (click)="media_component.open()">
        <i class="fas fa-bullhorn"></i> Announcement
        <br>
        <div class="text-muted text-sm">
            Using: {{ media_component.getMediaFile()?.name || '[No Media]' }}
        </div>
    </div>

    <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" (click)="destination_modal.open()">
        <i class="fas fa-phone-volume "></i>
        Destination
        <br>
        <span class="text-muted text-sm" *ngIf="forwarding_component.getDestinationObject() as destination">
            Forwarding to:
            <app-forwarding-to-partial [destination]="destination"></app-forwarding-to-partial>
        </span>
    </div>

    <a class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard"
        *appViperBrandIs="['vv']"
        routerLink="/time-management/{{ switchboard_object?.uuid }}">
        <i class="far fa-clock"></i> Time Management
    </a>

    <app-portal-component-delete-switchboard-object [account_id]="account_id" [switchboard_object]="switchboard_object"></app-portal-component-delete-switchboard-object>
</ng-template>

<app-portal-component-rename-switchboard-object [account_id]="account_id" [switchboard_object]="switchboard_object" #rename_modal></app-portal-component-rename-switchboard-object>

<app-modal title="Set Destination" size="big" (save)="destination_modal.close()" #destination_modal>
    <app-portal-component-destination-select [root]="switchboard_object" #forwarding_component></app-portal-component-destination-select>
</app-modal>

<app-portal-component-media-select tts_text="Welcome to XYZ, please hold while we put you through" [account_id]="account_id" [switchboard_object]="switchboard_object" #media_component></app-portal-component-media-select>

<ng-template #loading>
    <div class="card-body">
        <app-loadingbar></app-loadingbar>
    </div>
</ng-template>
