import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { LoginService } from '../../login/login.service';
import { GetAddressService } from '../../get-address.service';
import { ClipboardHelper } from '../../tools/clipboard.helper';

import { Address } from './address.model';

declare var toastr: any;
declare var alertify: any;

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.css']
})
export class AddressesComponent implements OnInit {

    public addresses: Address[];
    possible_addresses;
    country_codes;
    current_address: Address = new Address();
    set_as_billing_address = false;

    @Input() account_id: number;

    @ViewChild('addressDetail') addressDetail;

    constructor(
        private accountService: LoginService,
        private addressService: GetAddressService,
    ) { }

    ngOnInit() {
        this.loadAddresses();
        this.accountService.getCountryCodes()
            .subscribe(codes => this.country_codes = codes);
    }

    loadAddresses() {
        if (this.account_id) {
            this.accountService.getAddresses(this.account_id)
            .subscribe(addresses => {
                this.addresses = addresses;
                this.set_as_billing_address = addresses.length === 0;
            });
        }
    }

    newAddress() {
        this.current_address = new Address();
        this.current_address.country_region = 'UK';
        this.addressDetail.title = 'New Address';
        this.addressDetail.open();
    }

    editAddress(address) {
        this.current_address = address;
        this.addressDetail.title = 'Edit Address';
        this.addressDetail.open();
    }

    saveAddress() {
        let request;
        if (this.current_address.id) {
            request = this.accountService.updateAddress(this.account_id, this.current_address);

        } else {
            request = this.accountService.createAddress(this.account_id, this.current_address);
        }

        request.subscribe(response => {
            this.addressDetail.loading = false;
            if (response + '') {
                if (this.set_as_billing_address) {
                    this.accountService.updateAccountConfig({
                      id: this.account_id,
                    }, {
                      billing_address_id: response.id,
                    }).subscribe(() => {
                      this.loadAddresses();
                      // Notify the change
                      this.accountService.updateBillingAddress(this.current_address);
                    });
                } else {
                this.loadAddresses();
                }
                this.addressDetail.close();
                toastr.success('Address Saved!');
            }
        });
    }

    deleteAddress(address) {
        alertify.confirm(`Are you sure you want to delete this address`, () => {
            this.accountService.deleteAddress(this.account_id, address)
                .subscribe(_ => this.loadAddresses());
        });
    }

    search() {
        this.addressService.getExpandedAddresses(this.current_address.postal_code)
            .subscribe(response => this.possible_addresses = response.addresses);
    }

    setAddress(address, convertAddress = false) {
        let fullAddress;
        if (convertAddress) {
            fullAddress = {
                address_line_1: this.possible_addresses[address].line_1,
                address_line_2: this.possible_addresses[address].line_2,
                city: this.possible_addresses[address].town_or_city,
                state_province: this.possible_addresses[address].county,
            };
        } else {
            fullAddress = this.addresses[address];
        }
        this.current_address = Object.assign(this.current_address || {}, fullAddress);
    }

    addressToClipboard(address) {
        let completeAddress = `${address.address_line_1}, ${address.address_line_2}, ${address.city}, ${address.postal_code}`;
        ClipboardHelper.addToClipboard(completeAddress);
    }

}
