<!-- Top navbar -->
<nav class="navbar navbar-top navbar-expand-md navbar-dark" id="navbar-main">
    <div class="container-fluid">
        <!-- Brand -->
        <a class="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block" routerLink="/">Dashboard</a>

        <!-- Form -->
        
        <!-- <form class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <div class="form-group mb-0">
                <div class="input-group input-group-alternative">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-search"></i></span>
                    </div>
                    <input class="form-control" placeholder="Search" type="text">
                </div>
            </div>
        </form> -->
        
        
        <!-- User -->
        <ul class="navbar-nav align-items-center d-none d-md-flex">
            <li class="nav-item dropdown">
                <a class="nav-link pr-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div class="media align-items-center">
                        <span class="avatar avatar-sm rounded-circle" *ngIf="!!user">
                            <img ngxGravatar [email]="user.email" fallback="mm" alt="User Gravatar">
                        </span>
                        <div class="media-body ml-2 d-none d-lg-block">
                            <span class="badge badge-warning" *ngIf="isProxy()">PROXY</span>
                            <span class="mb-0 text-sm font-weight-bold" *ngIf="!!account">
                                {{ account.company_name }}
                                <ng-container *ngIf="user">- {{ user.email }}</ng-container>
                                <br>
                                Account number - {{ sid_start + account.id }}
                                <br>
                                Your account is currently: {{ account.active_state|formatAccountState }}
                                <br>
                            </span>
                        </div>
                    </div>
                </a>
                <a class="nav-link" *ngIf="auth_code" (click)="supportCodeToClipboard()">
                    <div class="media align-items-center">
                        <span style="width:36px;display:inline-flex;" >
                        </span>
                        <div class="media-body ml-2 d-none d-lg-block">
                            <span class="show-pointer darkcard mb-0 text-sm font-weight-bold">Support Code: {{ auth_code }} <i class="fa-solid fa-copy"></i></span>
                        </div>
                    </div>
                </a>
                <div class="dropdown-menu dropdown-menu-arrow dropdown-menu-right">
                    <div class=" dropdown-header noti-title">
                        <h6 class="text-overflow m-0">Welcome!</h6>
                    </div>
                    <!-- <a routerLink="./examples/profile.html" class="dropdown-item">
                      <i class="ni ni-single-02"></i>
                      <span>My profile</span>
                    </a> -->
                    <a routerLink="/settings" class="dropdown-item" *appViperHasUserRole="'Account Owner'">
                        <i class="ni ni-settings-gear-65"></i>
                        <span>Settings</span>
                    </a>
                    <!-- <a routerLink="./examples/profile.html" class="dropdown-item">
                        <i class="ni ni-calendar-grid-58"></i>
                        <span>Activity</span>
                    </a> -->
                    <a [href]="supportUrl" target="_blank" class="dropdown-item">
                        <i class="ni ni-support-16"></i>
                        <span>Support</span>
                    </a>
                    <!-- <a class="dropdown-item show-pointer" *ngIf="auth_code" (click)="supportCodeToClipboard()">
                        <span>Support Code: {{ auth_code }}</span>
                    </a> -->
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item show-pointer" (click)="logout()">
                      <i class="ni ni-user-run"></i>
                      <span>Logout </span> <span class="badge badge-warning" *ngIf="isProxy()">PROXY</span>
                    </a>
                </div>
            </li>
        </ul>
    </div>
</nav>
<!-- Header -->
<div class="header bg-gradient-primary pb-2 pt-5">
    <div class="container-fluid" *ngIf="isHome || card_toggle === true">
        <div class="header-body">
            <!-- Card stats -->
            <div class="row">
                <div class="col-xl-4 col-lg-6">
                    <app-call-stats-card #callStatsCard></app-call-stats-card>
                </div>
                <div class="col-xl-4 col-lg-6">
                    <app-balance-card #balanceCard></app-balance-card>
                </div>
                <div class="col-xl-4 col-lg-6">
                    <app-voicemail-card #voicemailCard></app-voicemail-card>
                </div>
            </div>
        </div>
        <br>
        <div>
            <app-address-notifications></app-address-notifications>
            <app-service-notifications></app-service-notifications>

            <ng-container *appViperBrandIs="['lv']">
                <ng-container *ngIf="account?.active_state === 1">
                    <ng-container *ngTemplateOutlet="trial_notification"></ng-container>
                    <ng-container *ngTemplateOutlet="activate_bundle_notification"></ng-container>
                </ng-container>
            </ng-container>

            <app-status-notifications></app-status-notifications>
        </div>
    </div>
    <div class="text-center" *ngIf="!isHome">
        <br>
        <button class="btn btn-outline-secondary btn-sm"
                (click)="toggleCards()"
                style="border: none; color: #c6bcf9; ">
            Show/Hide quick info
        </button>
    </div>
</div>

<ng-template #trial_notification>
    <div class="alert alertCustom">
        <i class="fa fa-exclamation-circle  alertIcon"></i>
        <span style="display:inline;margin-left: 2%;">Your account status is <strong>FREE TRIAL PERIOD</strong><br>
        You have free credit to test out the phone system for up to 30 days and make some outbound calls. Once the free credit is used or expires then please add more credit to continue.</span>
    </div>
</ng-template>

<ng-template #activate_bundle_notification>
    <div class="alert alertCustom">
        <i class="fa fa-exclamation-circle alertIcon"></i>
        <span style="display:inline;margin-left: 2%;"><strong>Activate your call bundle</strong><br>
        When you have finished testing you can activate your full account by making a top-up and activating your 100 minute bundle for each VoIP user <a routerLink="/settings/services">here</a></span>
    </div>
</ng-template>