import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Installed Modules
import { GravatarModule } from 'ngx-gravatar';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgSelectModule } from '@ng-select/ng-select';

// My Modules
import { PipesModule } from './pipes/pipes.module';
import { DirectivesModule } from './directives/directives.module';
import { ComponentsModule } from './components/components.module';
import { LayoutModule } from './layout/layout.module';
import { SectionsModule } from './sections/sections.module';
import { AudioModule } from './audio/audio.module';
import { PortalComponentsModule } from './portal/components/portal-components.module';
import { PortalModule } from './portal/portal.module';
import { SwitchboardModule } from './switchboard/switchboard.module';

import { ServersModule } from './servers/servers.module';
import { AccountsModule } from './accounts/accounts.module';
import { RatesModule } from './rates/rates.module';
import { AdminModule } from './admin/admin.module';
import { ReportsModule } from './reports/reports.module';
import { ContractsModule } from './contracts/contracts.module';

// Pages
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ApplicationsComponent } from './applications/applications.component';
import { ServicesComponent } from './services/services.component';
import { ServiceComponent } from './services/service/service.component';
import { SubServicesComponent } from './subservices/subservices.component';
import { SubServiceComponent } from './subservices/subservice/subservice.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { GraphComponent } from './graph/graph.component';
import { SimwoodMapComponent } from './simwood-map/simwood-map.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { VerifyUserComponent } from './verify-user/verify-user.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { BlankComponent } from './tools/blank.component';
import { GlobalBlocksComponent } from './global-blocks/global-blocks.component';
import { NumberSearchComponent } from './number-search/number-search.component';
import { SignupLogComponent } from './signup-log/signup-log.component';
import { GlobalCardHistoryComponent } from './global-card-history/global-card-history.component';
import { AdminCdrsComponent } from './admin-cdrs/admin-cdrs.component';
import { AdminDuplicateDestinationsComponent } from './admin-duplicate-destinations/admin-duplicate-destinations.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { ShopComponent } from './shop/shop.component';
import { BulkSwitchboardGenerateComponent } from './bulk-switchboard-generate/bulk-switchboard-generate.component';
import { PortalFaqComponent } from './portal-faq/portal-faq.component';
import { AdminReportsComponent } from './admin-reports/admin-reports.component';
import { PortalHelpComponent } from './portal-help/portal-help.component';
import { AdminVerifyAddressesComponent } from './admin-verify-addresses/admin-verify-addresses.component';
import { DnoBlocksComponent } from './dno-blocks/dno-blocks.component';
import { AdminVerifyMediaComponent } from './admin-verify-media/admin-verify-media.component';


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,

        GraphComponent,

        HomeComponent,
        ApplicationsComponent,
        ServicesComponent,
        ServiceComponent,
        SubServicesComponent,
        SubServiceComponent,
        SimwoodMapComponent,
        PasswordResetComponent,
        VerifyUserComponent,
        PageNotFoundComponent,
        BlankComponent,
        GlobalBlocksComponent,
        NumberSearchComponent,
        SignupLogComponent,
        GlobalCardHistoryComponent,
        AdminCdrsComponent,
        AdminDuplicateDestinationsComponent,
        PaymentHistoryComponent,
        ShopComponent,
        BulkSwitchboardGenerateComponent,
        PortalFaqComponent,
        AdminReportsComponent,
        PortalHelpComponent,
        AdminVerifyAddressesComponent,
        DnoBlocksComponent,
        AdminVerifyMediaComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,

        GravatarModule,
        NgxIntlTelInputModule,
        NgSelectModule,

        PipesModule,
        DirectivesModule,
        LayoutModule,
        ComponentsModule,
        SwitchboardModule,
        SectionsModule,
        AudioModule,
        PortalComponentsModule,
        PortalModule,
        AdminModule,

        ServersModule,
        AccountsModule,
        RatesModule,
        ReportsModule,
        ContractsModule,

        AppRoutingModule,
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
