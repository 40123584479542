import { Component, OnInit } from '@angular/core';

import { LoginService } from '../../../login/login.service';
import { CdrService } from '../../../cdr.service';

@Component({
    selector: 'app-call-stats-card',
    templateUrl: './call-stats-card.component.html',
    styleUrls: ['./call-stats-card.component.css']
})
export class CallStatsCardComponent implements OnInit {

    account;

    inbound_calls;
    outbound_calls;

    constructor(
        private authService: LoginService,
        private cdrService: CdrService,
    ) { }

    ngOnInit(): void {
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account = account;
            this.loadCalls();
        });
    }

    loadCalls() {
        const today = (new Date()).toISOString().split('T')[0];

        const data = {
            start_time_range_start: `${today} 00:00:00`,
            start_time_range_end: `${today} 23:59:59`,
        };

        this.cdrService.readCDRsForAccount('inbound', this.account.id, data)
            .subscribe(response => this.inbound_calls = response);
        this.cdrService.readCDRsForAccount('outbound', this.account.id, data)
            .subscribe(response => this.outbound_calls = response);
    }

    public refresh() {
        this.loadCalls();
    }

}
