<input type="text" class="form-control" value="{{ displayDate() }}" (click)="pickerVisible = !pickerVisible" placeholder="Select date range">

<div id="dp" *ngIf="pickerVisible">
    <div class="big-calendar">
        <ngb-datepicker (select)="onDateSelection($event)"
            [displayMonths]="2"
            [dayTemplate]="t"
            outsideDays="hidden">
        </ngb-datepicker>
    </div>

    <div class="small-calendar">
        <ngb-datepicker (select)="onDateSelection($event)"
            [displayMonths]="1"
            [dayTemplate]="t"
            outsideDays="hidden">
        </ngb-datepicker>
    </div>
</div>


<ng-template #t let-date let-focused="focused">
  <span class="custom-day"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>
