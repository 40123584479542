import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from '../login/authentication.guard';
import { RoleGuard } from '../sections/roles/role.guard';

import { ContractTypesComponent } from './contract-types/contract-types.component';
import { ContractRateOverridesComponent } from './contract-rate-overrides/contract-rate-overrides.component';

const routes: Routes = [
    {
        path: 'contract-types/rates/:uuid',
        canActivate: [AuthenticationGuard, RoleGuard],
        component: ContractRateOverridesComponent,
        data: { roles: ['Admin'], title: 'Contract Type Rate Overrides' }
    },
    {
        path: 'contract-types',
        canActivate: [AuthenticationGuard, RoleGuard],
        component: ContractTypesComponent,
        data: { roles: ['Admin'], title: 'Contract Types' }
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class ContractsRoutingModule { }
