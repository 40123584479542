import { Component, OnInit } from '@angular/core';

import { LoginService } from '../../../login/login.service';

declare var toastr: any;

@Component({
    selector: 'app-portal-kyc',
    templateUrl: './portal-kyc.component.html',
    styleUrls: ['./portal-kyc.component.css']
})
export class PortalKycComponent implements OnInit {

    account;

    constructor(
        private authService: LoginService,
    ) { }

    ngOnInit(): void {
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account = account;
        });
    }

}
