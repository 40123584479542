<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Invoices</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Invoices</h2>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="invoice_type == 'coinprint'">
                <ng-container *ngIf="hasLegacy">
                    <p class="alert alert-secondary">You can access older invoices by <a routerLink="/invoices/legacy">clicking here</a></p>
                </ng-container>
                <app-invoices-coinprint [account_id]="account_id" *ngIf="account_id"></app-invoices-coinprint>
            </ng-container>
            <ng-container *ngIf="invoice_type == 'kashflow'">
                <app-invoices-kashflow [account_id]="account_id" *ngIf="account_id"></app-invoices-kashflow>
            </ng-container>
        </div>
    </div>
</div>
