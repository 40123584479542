<ng-container *ngIf="!wizard_generating">
    Server Timezone: BST
    <br>
    Server Time: {{ time_now|date:'medium':'BST' }}
    <br>
    <br>
    The active rule is currently: {{ getActiveRule()?.name || 'Closed' }}
    <br>
    <br>
    When Open, send calls to:
    <app-switchboard-object-dropdown [(model)]="wizard.redirect"
                                     [exclude]="['Number']" 
                                     [switchboard_objects]="switchboard_objects"></app-switchboard-object-dropdown>

    <br>
    When Closed, send calls to:
    <app-switchboard-object-dropdown [(model)]="closed_redirect"
                                     [exclude]="['Number']" 
                                     [switchboard_objects]="switchboard_objects"></app-switchboard-object-dropdown>
    <br>
    <p>Simply click on OPEN or CLOSED buttons to set your opening time, or close for the day, and press SAVE when done</p>

    <div class="table-responsive">
        <table class="table is-fullwidth table-flush">
            <tr>
                <th>Monday</th>
                <ng-container *ngIf="wizard.mon !== null">
                    <td>
                        <button class="btn btn-sm btn-success" (click)="wizard.mon = null">Open</button>
                    </td>
                    <td>
                        <app-time-dropdown minutes-interval="5" [(model)]="wizard.mon.time_start"></app-time-dropdown>
                    </td>
                    <td>
                        <app-time-dropdown minutes-interval="5" [(model)]="wizard.mon.time_end"></app-time-dropdown>
                    </td>
                </ng-container>
                <ng-container *ngIf="wizard.mon === null">
                    <td>
                        <button class="btn btn-sm btn-danger" (click)="wizard.mon = { time_start: '09:00', time_end: '17:00' }">Closed</button>
                    </td>
                    <td colspan="2">&nbsp;</td>
                </ng-container>
            </tr>
            <tr>
                <th>Tuesday</th>
                <ng-container *ngIf="wizard.tue !== null">
                    <td>
                        <button class="btn btn-sm btn-success" (click)="wizard.tue = null">Open</button>
                    </td>
                    <td>
                        <app-time-dropdown minutes-interval="5" [(model)]="wizard.tue.time_start"></app-time-dropdown>
                    </td>
                    <td>
                        <app-time-dropdown minutes-interval="5" [(model)]="wizard.tue.time_end"></app-time-dropdown>
                    </td>
                </ng-container>
                <ng-container *ngIf="wizard.tue === null">
                    <td>
                        <button class="btn btn-sm btn-danger" (click)="wizard.tue = { time_start: '09:00', time_end: '17:00' }">Closed</button>
                    </td>
                    <td colspan="2">&nbsp;</td>
                </ng-container>
            </tr>
            <tr>
                <th>Wednesday</th>
                <ng-container *ngIf="wizard.wed !== null">
                    <td>
                        <button class="btn btn-sm btn-success" (click)="wizard.wed = null">Open</button>
                    </td>
                    <td>
                        <app-time-dropdown minutes-interval="5" [(model)]="wizard.wed.time_start"></app-time-dropdown>
                    </td>
                    <td>
                        <app-time-dropdown minutes-interval="5" [(model)]="wizard.wed.time_end"></app-time-dropdown>
                    </td>
                </ng-container>
                <ng-container *ngIf="wizard.wed === null">
                    <td>
                        <button class="btn btn-sm btn-danger" (click)="wizard.wed = { time_start: '09:00', time_end: '17:00' }">Closed</button>
                    </td>
                    <td colspan="2">&nbsp;</td>
                </ng-container>
            </tr>
            <tr>
                <th>Thursday</th>
                <ng-container *ngIf="wizard.thu !== null">
                    <td>
                        <button class="btn btn-sm btn-success" (click)="wizard.thu = null">Open</button>
                    </td>
                    <td>
                        <app-time-dropdown minutes-interval="5" [(model)]="wizard.thu.time_start"></app-time-dropdown>
                    </td>
                    <td>
                        <app-time-dropdown minutes-interval="5" [(model)]="wizard.thu.time_end"></app-time-dropdown>
                    </td>
                </ng-container>
                <ng-container *ngIf="wizard.thu === null">
                    <td>
                        <button class="btn btn-sm btn-danger" (click)="wizard.thu = { time_start: '09:00', time_end: '17:00' }">Closed</button>
                    </td>
                    <td colspan="2">&nbsp;</td>
                </ng-container>
            </tr>
            <tr>
                <th>Friday</th>
                <ng-container *ngIf="wizard.fri !== null">
                    <td>
                        <button class="btn btn-sm btn-success" (click)="wizard.fri = null">Open</button>
                    </td>
                    <td>
                        <app-time-dropdown minutes-interval="5" [(model)]="wizard.fri.time_start"></app-time-dropdown>
                    </td>
                    <td>
                        <app-time-dropdown minutes-interval="5" [(model)]="wizard.fri.time_end"></app-time-dropdown>
                    </td>
                </ng-container>
                <ng-container *ngIf="wizard.fri === null">
                    <td>
                        <button class="btn btn-sm btn-danger" (click)="wizard.fri = { time_start: '09:00', time_end: '17:00' }">Closed</button>
                    </td>
                    <td colspan="2">&nbsp;</td>
                </ng-container>
            </tr>
            <tr>
                <th>Saturday</th>
                <ng-container *ngIf="wizard.sat !== null">
                    <td>
                        <button class="btn btn-sm btn-success" (click)="wizard.sat = null">Open</button>
                    </td>
                    <td>
                        <app-time-dropdown minutes-interval="5" [(model)]="wizard.sat.time_start"></app-time-dropdown>
                    </td>
                    <td>
                        <app-time-dropdown minutes-interval="5" [(model)]="wizard.sat.time_end"></app-time-dropdown>
                    </td>
                    <td colspan="2">&nbsp;</td>
                </ng-container>
                <ng-container *ngIf="wizard.sat === null">
                    <td>
                        <button class="btn btn-sm btn-danger" (click)="wizard.sat = { time_start: '09:00', time_end: '17:00' }">Closed</button>
                    </td>
                    <td colspan="2">&nbsp;</td>
                </ng-container>
            </tr>
            <tr>
                <th>
                    Sunday
                </th>
                <ng-container *ngIf="wizard.sun !== null">
                    <td>
                        <button class="btn btn-sm btn-success" (click)="wizard.sun = null">Open</button>
                    </td>
                    <td>
                        <app-time-dropdown minutes-interval="5" [(model)]="wizard.sun.time_start"></app-time-dropdown>
                    </td>
                    <td>
                        <app-time-dropdown minutes-interval="5" [(model)]="wizard.sun.time_end"></app-time-dropdown>
                    </td>
                </ng-container>
                <ng-container *ngIf="wizard.sun === null">
                    <td>
                        <button class="btn btn-sm btn-danger" (click)="wizard.sun = { time_start: '09:00', time_end: '17:00' }">Closed</button>
                    </td>
                    <td colspan="2">&nbsp;</td>
                </ng-container>
            </tr>
        </table>

        <button class="btn btn-success btn-block" (click)="generateBasicRules()">Save</button>
    </div>
</ng-container>

<ng-container *ngIf="wizard_generating">
    <app-loadingbar></app-loadingbar>
    <p class="text-center">{{ wizard_generation_message }}</p>
</ng-container>