import { Component, OnInit } from '@angular/core';

import { MediaService } from '../../../audio/media.service';
import { SwitchboardService } from '../../../switchboard/switchboard.service';
import { LoginService } from '../../../login/login.service';

import { forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AudioHelper } from '../../../tools/audio';

@Component({
  selector: 'app-verify-media-badge',
  templateUrl: './verify-media-badge.component.html',
  styleUrls: ['./verify-media-badge.component.css']
})
export class VerifyMediaBadgeComponent implements OnInit {

  media: any;
  account_id;
  countWithAdditionalDataAccount;

  constructor(
    private switchboardService: SwitchboardService,
    private mediaService: MediaService,
    private authService: LoginService,
  ) { }

  ngOnInit(): void {
    this.loadMedia();
  }
  
  loadMedia() {
    this.authService.getCurrentAccount().subscribe(account => {
      this.account_id = account.id;
      this.mediaService.getUnverifiedMedia(this.account_id)
        .subscribe(mediaArray => {
          if (mediaArray.length > 0) {
            // Use forkJoin to wait for all additional data calls to complete
            const allMediaRequests = mediaArray.map(mediaItem => 
              this.getAdditionalData(mediaItem.account_id)
                .pipe(
                  catchError(error => {
                    console.error('Error fetching additional data', error);
                    return of(null); // Return null or a default value in case of error
                  }),
                  map((additionalData: any) => ({
                    ...mediaItem as any, // Assert mediaItem as any to access its properties freely
                    additionalData // Add the additional data
                  }))
                )
            );
            
  
            forkJoin(allMediaRequests).subscribe((completedMediaArray: any[]) => {
              this.media = completedMediaArray.filter(item => item && item.additionalData && item.additionalData.account);
              // Count the total number of media items with additionalData.account
              this.countWithAdditionalDataAccount = this.media.length;
              console.log(this.countWithAdditionalDataAccount);
            });
            
          } else {
            this.media = [];
          }
        });
    });
  }
  
  getAdditionalData(accountId: number) {
    return this.switchboardService.getSwitchboardFromAccount(accountId).pipe(
      map(data => data || 0),
      catchError(error => {
        console.error('Error fetching additional data', error);
        return of(0); 
      })
    );
  }
  

}
