<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Time Management</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Time Management</h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <p>Use the following to automatically open and close your system, sending inbound calls to a selectable destination when closed</p>
                <p>
                    Set Time management rules for
                    <span class="dropdown">
                        <button class="btn btn-primary dropdown-toggle" type="button" id="number-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <ng-container *ngIf="getObject() as object">
                                <ng-container *ngIf="object.object_type === 'Number'">
                                    <span class="flag-icon flag-icon-{{ (object.object_data.e164_number|number_to_country_code)?.toLowerCase() }}" [title]="object.object_data.e164_number|number_to_country_prefix"></span>
                                    {{ object.object_data.e164_number|number_to_local }}
                                </ng-container>
                                <ng-container *ngIf="object.object_type !== 'Number'">
                                    <i [class]="object|switchboard_object_icon"></i> {{ object.extension_number }} - {{ object.object_data.name }}
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!object">
                                [Select]
                            </ng-container>
                        </button>
                        <span class="dropdown-menu" aria-labelledby="number-dropdown" *ngIf="switchboard_objects">
                            <ng-container *ngFor="let object of switchboard_objects">
                                <a class="dropdown-item" routerLink="/time-management/{{ object.uuid }}" *ngIf="object.object_type !== 'Sip'">
                                    <ng-container *ngIf="object.object_type === 'Number'">
                                        <span class="flag-icon flag-icon-{{ (object.object_data.e164_number|number_to_country_code)?.toLowerCase() }}" [title]="object.object_data.e164_number|number_to_country_prefix"></span>
                                        {{ object.object_data.e164_number|number_to_local }}
                                    </ng-container>

                                    <ng-container *ngIf="object.object_type !== 'Number'">
                                        <i [class]="object|switchboard_object_icon"></i> {{ object.extension_number }} - {{ object.object_data.name }}
                                    </ng-container>
                              </a>
                            </ng-container>
                        </span>
                    </span>
                </p>

                <ng-container *ngIf="getObject() as switchboard_object">
                    <app-nine-to-five-wizard
                        [account_id]="account_id"
                        [object_id]="switchboard_object.uuid"
                        (generated)="getSwitchboardObjects();getRules()"
                    ></app-nine-to-five-wizard>

                    <!-- <ng-container *ngTemplateOutlet="tab_view"></ng-container> -->
                </ng-container>

                
            </div>
        </div>
    </div>
</div>

<app-modal title="New Rule" (save)="saveRule()" #rule_modal>
    <div class="form-row">
        <label for="rule-form-name">Name</label>
        <input id="rule-form-name" type="text" class="form-control" placeholder="Name" list="state-names" [(ngModel)]="rule.name">
        <datalist id="state-names">
            <ng-container *ngFor="let state of getStates()">
                <option [value]="state.name">{{ state.name }}</option>
            </ng-container>
        </datalist>
    </div>

    <div class="form-row">
        <div class="col-md-6">
            <label>Time Range</label>
            <app-time-dropdown [(model)]="rule.time_range_start"></app-time-dropdown>
        </div>
        <div class="col-md-6">
            <label>&nbsp;</label>
            <app-time-dropdown [(model)]="rule.time_range_end"></app-time-dropdown>
        </div>
    </div>

    <div class="form-row">
        <div class="col-md-12">
            <div class="form-group">
                <label>Weekdays:</label>
            </div>
            <div class="form-group">
                <div *ngFor="let day of days" class="custom-control custom-checkbox custom-control-inline">
                    <input type="checkbox" class="custom-control-input" id="rule-check-day-{{ day }}" [(ngModel)]="rule[day]">
                    <label class="custom-control-label" for="rule-check-day-{{ day }}">{{ day.toUpperCase() }}</label>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="form-group">
        <label for="rulesInputDestination">When closed send calls to</label>
        <app-switchboard-object-dropdown [(model)]="rule.switchboard_destination_uuid" 
                                         [switchboard_objects]="switchboard_objects"></app-switchboard-object-dropdown>
    </div> -->
</app-modal>

<app-modal title="Redirect to" (save)="saveState()" #destination_modal>
    <div class="form-group" *ngIf="state && !saving">
        <label>When "{{ state.name }}" send calls to</label>
        <app-switchboard-object-dropdown [(model)]="state.redirect" 
                                         [exclude]="['Number']" 
                                         [switchboard_objects]="switchboard_objects"></app-switchboard-object-dropdown>
    </div>
    <ng-container *ngIf="saving">
        <app-loadingbar></app-loadingbar>
    </ng-container>
</app-modal>

<app-modal title="Redirect to" (save)="saveDefaultRedirect()" #destination_modal_default>
    <label>When none of the rules apply send calls to</label>
    <app-switchboard-object-dropdown [(model)]="redirect" 
                                     [exclude]="['Number']" 
                                     [switchboard_objects]="switchboard_objects"></app-switchboard-object-dropdown>
</app-modal>

<ng-template #tab_view>
    <app-tabs>
        <app-tab name="Simple" [isActive]="true">
            <app-nine-to-five-wizard
                [account_id]="account_id"
                [object]="getObject()"
                [switchboard_objects]="switchboard_objects"
                [(rules)]="rules"
                (generated)="getSwitchboardObjects();getRules()"
            ></app-nine-to-five-wizard>
        </app-tab>
        <app-tab name="Expert">
            <ng-container *ngTemplateOutlet="expert_rules"></ng-container>
        </app-tab>
    </app-tabs>
</ng-template>

<ng-template #expert_rules>
    <ng-container *ngTemplateOutlet="rule_states"></ng-container>
    <ng-container *ngIf="object">
        <table *ngIf="rules else is_loading" class="table is-bordered is-striped is-hoverable is-fullwidth">
            <tbody>
                <ng-container *ngFor="let rule of rules">
                    <tr>
                        <th>
                            <h5>{{ rule.name }}</h5>
                            <h6 class="text-muted">
                                Redirect to:
                                <br />
                                {{ getDestination(rule) }}
                            </h6>
                        </th>
                        <td>
                            <h5>{{ rule.time_range_start }} to {{ rule.time_range_end }}</h5>
                        </td>
                        <td>
                            <strong>
                                <span *ngFor="let day of days" [class.is-gray]="!rule[day]" [class.is-green]="rule[day]">{{ day|capitalize }}&nbsp;</span>
                            </strong>
                        </td>

                        <td>
                            <button class="btn btn-secondary" (click)="editRule(rule)" [disabled]="internal_disabled">Edit</button>
                            <button class="btn btn-danger" (click)="deleteRule(rule)" [disabled]="internal_disabled">Delete</button>
                        </td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="rules.length === 0">
                    <tr>
                        <td colspan="4">No Rules</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>

        <button class="btn btn-primary" (click)="newRule()">Add New Rule</button>
    </ng-container>
</ng-template>

<ng-template #rule_states>
    <ul class="list-group col-6" *ngIf="rules">
        
        <li class="list-group-item" *ngFor="let state of getStates()">
            When "{{ state.name }}" go to:
            <br>
            <app-forwarding-to-partial [destination]="getSwitchboardObject(state.redirect)"></app-forwarding-to-partial>
            <button (click)="changeState(state)" class="btn btn-primary">Change</button>
        </li>
        <li class="list-group-item">
            <ng-container *ngIf="getStates().length">Otherwise </ng-container>
            <ng-container *ngIf="getObject() as object">
                <ng-container *ngIf="object.object_type === 'Number'">
                    <span class="flag-icon flag-icon-{{ (object.object_data.e164_number|number_to_country_code)?.toLowerCase() }}" [title]="object.object_data.e164_number|number_to_country_prefix"></span>
                    {{ object.object_data.e164_number|number_to_local }}
                </ng-container>
                <ng-container *ngIf="object.object_type !== 'Number'">
                    <i [class]="object|switchboard_object_icon"></i> {{ object.extension_number }} - {{ object.object_data.name }}
                </ng-container>
            </ng-container>
            goes to:
            <br>
            <app-forwarding-to-partial [destination]="getSwitchboardObject(getObject()?.object_data.switchboard_destination_uuid)"></app-forwarding-to-partial>

            <button (click)="changeDefault()" class="btn btn-primary">Change</button>
        </li>
    </ul>
</ng-template>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
