<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Sub Services</h2>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table is-bordered is-striped is-hoverable is-fullwidth">
                    <thead class="thead">
                        <tr class="tr">
                            <th class="th">Name</th>
                            <th class="th">Switchboard Type</th>
                        </tr>
                    </thead>
                    <tbody class="tbody" *ngIf="subservices && subservices.length else no_accounts">
                        <tr class="tr" *ngFor="let subservice of subservices">
                            <td class="td"><a routerLink="/subservices/detail/{{ subservice.id }}">{{ subservice.name }}</a></td>
                            <td class="td"><i [class]="{object_type: subservice.switchboard_type}|switchboard_object_icon"></i> {{ subservice.switchboard_type }}</td>
                        </tr>
                    </tbody>
                    <tfoot class="tfoot">
                        <tr class="tr">
                            <th class="th" colspan="2">
                                <button class="btn btn-primary is-primary" (click)="createSubService()">New Service</button>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>
<app-modal (save)="saveSubService()" title="New Sub Service" #subserviceDetail>
    <div class="field">
        <label class="label">Name</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Name" [(ngModel)]="newSubService.name">
        </div>
    </div>
    <div class="field">
        <label class="label">Service Type</label>
        <div class="control">
            <div class="select">
              <select [(ngModel)]="newSubService.switchboard_type" class="form-control">
                <option *ngFor="let type of switchboardTypes" value="{{ type }}">{{ type }}</option>
              </select>
            </div>
        </div>
    </div>
    <br>
    <br>
    <div class="field">
        <label class="label">Retail Cost</label>
        <div class="control">
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text" id="btnGroupAddon">&pound;</div>
                </div>
                <input class="input form-control" type="text" placeholder="Retail Cost" [(ngModel)]="newSubService.retail_cost">
            </div>
        </div>
    </div>
    <!-- <div class="field">
        <label class="label">Renew Days</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Days to renew (0 = doesn't renew)" [(ngModel)]="newSubService.renew_days">
        </div>
    </div> -->

    <div class="field">
        <label class="label">Feature Code</label>
        <div class="control">
            <!-- <input class="input form-control" type="text" placeholder="" [(ngModel)]="subservice.feature_code"> -->
            <select class="input form-control" [(ngModel)]="newSubService.feature_code">>
                <option value="">[None]</option>
                <option value="GROUP_ID">Group ID</option>
            </select>
        </div>
    </div>
</app-modal>

<ng-template #no_accounts>
    <tbody class="tbody">
        <tr class="tr">
            <td class="td" colspan=2>
                <ng-container *ngIf="subservices else is_loading">No Sub Services Found</ng-container>
            </td>
        </tr>
    </tbody>
</ng-template>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
