<div class="card-group">
    <ng-container *ngFor="let address of addresses">
        <div class="card darkcard">
            <div class="card-body darkcard" (click)="editAddress(address)">
                <p>{{ address.title }} {{ address.first_name }} {{ address.last_name }}</p>
                <p>{{ address.address_line_1 }}</p>
                <p>{{ address.address_line_2 }}</p>
                <p>{{ address.city }}</p>
                <p>{{ address.state_province }}</p>
                <p>{{ address.country_region }}</p>
                <p>{{ address.postal_code }}</p>
            </div>
            <btn class="btn btn-info" *appViperHasUserRole="'Admin'" style="margin: 0;border-radius: 0px;" (click)="addressToClipboard(address)">Copy Address</btn>
            <div class="card-footer" (click)="deleteAddress(address)">
                <i class="fas fa-times"></i>
            </div>
        </div>
    </ng-container>
    <div class="card darkcard" (click)="newAddress()">
        <div class="card-body darkcard">
            New Address
        </div>
    </div>
</div>

<app-modal (save)="saveAddress()" #addressDetail>
    <div class="field">
        <label class="label">Title</label><span class="required">*</span>
        <div class="control">
            <select class="input form-control" [(ngModel)]="current_address.title">
                <option value="Mr">Mr</option>                
                <option value="Mrs">Mrs</option>                
                <option value="Miss">Miss</option>                
                <option value="Ms">Ms</option>                
            </select>
        </div>
    </div>

    <div class="field">
        <label class="label">First Name</label><span class="required">*</span>
        <div class="control">
            <input class="input form-control" type="text" placeholder="First Name" [(ngModel)]="current_address.first_name">
        </div>
    </div>
    <div class="field">
        <label class="label">Last Name</label><span class="required">*</span>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Last Name" [(ngModel)]="current_address.last_name">
        </div>
    </div>

    <hr>

    <div class="field">
        <label class="label">Country</label><span class="required">*</span>
        <div class="control">
            <!-- <input class="input form-control" type="text" placeholder="Country" list="countryCodes" > -->
            <select class="input form-control" id="countryCodes" [(ngModel)]="current_address.country_region">
                <option *ngFor="let code of country_codes" [value]="code.code">{{ code.name }}</option>
            </select>
        </div>
    </div>

    <ng-container *ngIf="current_address.country_region === 'UK'">
        <div class="field">
            <label class="label">Postal Code<span class="requiredstar">*</span></label>
            <div class="control">
                <div class="input-group">
                    <input class="form-control" type="text" placeholder="Postal Code" [(ngModel)]="current_address.postal_code">
                    <span class="input-group-btn">
                        <button class="btn btn-primary" (click)="search()">Search</button>
                    </span>
                </div>
            </div>
        </div>

        <div class="field" *ngIf="possible_addresses?.length">
            <select class="form-control" #addressSuggestionDropdown (change)="setAddress(addressSuggestionDropdown.value, true);addressSuggestionDropdown.value = ''">
                <option value="">Select Address At This Postcode</option>
                <ng-container *ngFor="let possible_address of possible_addresses; let i = index">
                    <option [value]="i">{{ possible_address.line_1 }}, {{ possible_address.line_2 }}, {{ possible_address.town_or_city }}, {{ possible_address.county }}</option>
                </ng-container>
            </select>
        </div>

        <hr>
    </ng-container>

    <div class="field">
        <label class="label">Address Line 1</label><span class="required">*</span>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Address Line 1" [(ngModel)]="current_address.address_line_1">
        </div>
    </div>
    <div class="field">
        <label class="label">Address Line 2</label><span class="required">*</span>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Address Line 2" [(ngModel)]="current_address.address_line_2">
        </div>
    </div>

    <div class="field">
        <label class="label">City</label><span class="required">*</span>
        <div class="control">
            <input class="input form-control" type="text" placeholder="City" [(ngModel)]="current_address.city">
        </div>
    </div>
    <div class="field">
        <label class="label">State Province</label><span class="required">*</span>
        <div class="control">
            <input class="input form-control" type="text" placeholder="State Province" [(ngModel)]="current_address.state_province">
        </div>
    </div>
    
    <div class="field">
        <label class="label">Postal Code</label><span class="required">*</span>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Postal Code" [(ngModel)]="current_address.postal_code">
        </div>
    </div>
    <hr>
    <div class="field">
        <div class="control">
            <label class="label">
                <input type="checkbox" [(ngModel)]="set_as_billing_address">
                &nbsp;
                <strong>Set this address as the billing address</strong>
            </label>
        </div>
    </div>
</app-modal>
