import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from '../login/authentication.guard';
import { RoleGuard } from '../sections/roles/role.guard';

import { DirectDebitExportsComponent } from './direct-debit-exports/direct-debit-exports.component';
import { DirectDebitPayerExportsComponent } from './direct-debit-payer-exports/direct-debit-payer-exports.component';
import { InvoiceCdrsComponent } from './invoices/invoice-cdrs/invoice-cdrs.component';

const routes: Routes = [
    { path: 'direct-debit-exports', canActivate: [AuthenticationGuard, RoleGuard], component: DirectDebitExportsComponent, data: {roles: ['Admin'], title: 'Direct Debit Exports'} },
    { path: 'direct-debit-payer-exports', canActivate: [AuthenticationGuard, RoleGuard], component: DirectDebitPayerExportsComponent, data: {roles: ['Admin'], title: 'Direct Debit Payer Exports'} },
    { path: 'invoices/:invoice', canActivate: [AuthenticationGuard, RoleGuard], component: InvoiceCdrsComponent, data: {roles: ['Account Owner'], title: 'Invoice CDRs'}},
    // { path: 'invoices', canActivate: [AuthenticationGuard, RoleGuard], component: InvoiceCdrsComponent, data: {roles: ['Account Owner'], title: 'Invoice CDRs'}},
    { path: 'accounts/detail/:account/invoices/:invoice', canActivate: [AuthenticationGuard, RoleGuard], component: InvoiceCdrsComponent, data: {roles: ['Admin'], title: 'Invoice CDRs'}},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class BillingRoutingModule { }
