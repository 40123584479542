import { Component, OnInit } from '@angular/core';

import { LoginService } from '../../../login/login.service';

import { KnowYourCustomerService } from '../../../admin/know-your-customer.service';

@Component({
    selector: 'app-address-notifications',
    templateUrl: './address-notifications.component.html',
    styleUrls: ['./address-notifications.component.css']
})
export class AddressNotificationsComponent implements OnInit {

    account;

    has_no_addresses: boolean;

    old_kyc;
    kyc;

    constructor(
        private loginService: LoginService,
        private kycService: KnowYourCustomerService,
    ) { }

    ngOnInit(): void {
        this.loginService.getCurrentAccount()
        .subscribe(account => {
            this.account = account;
        });
        this.getKYC();
    }

    loadAddresses() {
        this.loginService.getAddresses(this.account.id)
            .subscribe(addresses => this.has_no_addresses = addresses.length === 0);
    }

    getKYC() {
        this.kycService.getKYC(this.account.id)
        .subscribe(kyc => {
            this.kyc = kyc;
            this.old_kyc = JSON.stringify(kyc);
        });
        console.log(this.kyc);
    }

}
