import { Component, OnInit, Input } from '@angular/core';

import { SwitchboardObject } from '../../switchboard/switchboard_object';

@Component({
    selector: 'app-forwarding-to-partial',
    templateUrl: './forwarding-to-partial.component.html',
    styleUrls: ['./forwarding-to-partial.component.css']
})
export class ForwardingToPartialComponent {

    @Input() destination;
    @Input('show-edit') show_edit;
    @Input('has-rules') has_rules;

    constructor() { }

}
