declare var moment: any;

export const RuleHelper = {
    days_of_week: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
    months: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],

    serverTime(timezone) {
        return moment().tz(timezone);
    },

    matchesWeekday(time, rule) {
        const dayIndex = time.day();
        const day = this.days_of_week[dayIndex];
        const daysSet = this.days_of_week.filter(day => rule[day]).length > 0;
        return !daysSet || rule[day];
    },

    matchesMonth(time, rule) {
        const monthIndex = time.month();
        const month = this.months[monthIndex];
        const monthsSet = this.months.filter(month => rule[month]).length > 0;
        return !monthsSet || rule[month];
    },

    matchesDay(time, rule) {
        const day = time.date();
        const start = rule.dom_range_start;
        const end = rule.dom_range_end;

        if (!start || !end) {
            return true;
        }

        return start <= day && day <= end;
    },

    matchesTime(time, rule) {
        const hour = time.hour();
        const minute = time.minute();

        const [start_hour, start_minute] = rule.time_range_start.split(':').map(x => +x);
        const [end_hour, end_minute] = rule.time_range_end.split(':').map(x => +x);

        const afterStart = hour > start_hour || (hour === start_hour && minute >= start_minute);
        const beforeEnd = hour < end_hour || (hour === end_hour && minute <= end_minute);

        return afterStart && beforeEnd;
    },

    ruleMatches(time, rule) {
        const matchWeekday = this.matchesWeekday(time, rule);
        const matchMonth = this.matchesMonth(time, rule);
        const matchDay = this.matchesDay(time, rule);
        const matchTime = this.matchesTime(time, rule);
        return matchWeekday && matchMonth && matchDay && matchTime;
    },

    getAllMatchingRules(rules, time) {
        if (!rules) {
            return null;
        }
        return rules.filter(rule => this.ruleMatches(time, rule));
    },

    evaluateRules(rules) {
        const time = this.serverTime('Europe/London');
        const matchingRules = this.getAllMatchingRules(rules, time);
        if (matchingRules?.length) {
            return matchingRules[0];
        }
        return null;
    },

    getStates(rules) {
        const states = [];
        const stateNames = [];
        if (rules) {
            for (let rule of rules) {
                if (stateNames.indexOf(rule.name) === -1) {
                    stateNames.push(rule.name);
                    states.push({
                        name: rule.name,
                        redirect: rule.switchboard_destination_uuid,
                    });
                }
            }
        }
        return states;
    },
};
