<ng-container *ngIf="report">
    <h2>
        Score:
        <span
            [class.text-danger]="report.score >= SCORE_BAD"
            [class.text-warning]="report.score >= SCORE_OK && report.score < SCORE_BAD"
            [class.text-success]="report.score < SCORE_OK"
        >{{ report.score }}</span>
    </h2>

    <div class="table-responsive">
        <table class="table">
            <tr>
                <th>Points</th>
                <th>Reason</th>
            </tr>
            <tr *ngFor="let record of report.reports">
                <td>
                    <span
                        [class.text-danger]="record.points > 0"
                        [class.text-success]="record.points < 0"
                    >{{ record.points }}</span>
                </td>
                <td>{{ record.reason }}</td>
            </tr>
            <tr *ngIf="report && report.reports.length === 0">
                <td colspan="2">No reports found</td>
            </tr>
            <tr *ngIf="!report">
                <td colspan="2">
                    <app-loadingbar></app-loadingbar>
                </td>
            </tr>
        </table>
    </div>
</ng-container>
<ng-container *ngIf="!report">
    <app-loadingbar></app-loadingbar>
</ng-container>