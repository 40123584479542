import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-switchboard-object-dropdown',
    templateUrl: './switchboard-object-dropdown.component.html',
    styleUrls: ['./switchboard-object-dropdown.component.css']
})
export class SwitchboardObjectDropdownComponent implements OnInit {

    @Input() allow_none: boolean;
    @Input() switchboard_objects;
    @Input() object_type = null;
    @Input() exclude = ['Number'];
    @Input('object-key') object_key = 'uuid';

    @Input() model;
    @Output() modelChange = new EventEmitter<string>();

    constructor() { }

    ngOnInit() {
    }

    objectTypes() {
        let types = [];
        if (this.switchboard_objects) {
            types = this.switchboard_objects.map(object => object.object_type);
            types = Array.from(new Set<string>(types));
        }
        return types;
    }

    canShowType(type) {
        const notExcluded = this.object_type === null && this.exclude.indexOf(type) === -1;
        const isType = this.object_type === type;
        return notExcluded || isType;
    }

}
