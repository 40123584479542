<div class="modal fade" tabindex="-1" role="dialog" id="{{ modalid }}">
    <div class="modal-dialog" [class.modal-lg]="size == 'big'" [class.modal-sm]="size == 'small'" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">{{ title }}</h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" *ngIf="!loading">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ng-content></ng-content>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="save.observers.length" class="btn btn-primary" (click)="loading = true;save.emit()" [disabled]="loading">Save</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal" *ngIf="!loading">Close</button>
            </div>
        </div>
    </div>
</div>
