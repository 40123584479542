import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

import { CountryCodes } from '../tools/country-codes';

@Pipe({name: 'number_to_country_code'})
export class NumberToCountryCodePipe implements PipeTransform {

    isCanadianNumber(number: string): boolean {
        let areaCodes = CountryCodes.canadian_area_codes;
        let areaCode = number.substr(1, 3);

        return areaCodes.indexOf(areaCode) !== -1;
    }

    findMatchingCountryAbbreviation(foundPrefix): string {
        const defaultAbbreviation: string = '??';
        if (!foundPrefix) {
            return defaultAbbreviation;
        }
        for (let code in CountryCodes.countries) {
            if (CountryCodes.countries[code] === foundPrefix.name) {
                return code;
            }
        }
        return defaultAbbreviation;
    }

    findMatchingPrefix(number: string) {
        let foundPrefix = null;
        let biggestPrefix = 0;
        for (let prefix of CountryCodes.e164_prefixes) {
            if (number.startsWith(prefix.number) && prefix.number.length > biggestPrefix) {
                biggestPrefix = prefix.number.length;
                foundPrefix = prefix;
            }
        }
        return { foundPrefix, biggestPrefix };
    }

    getCountryAbbreviation(number: string): string {
        let matchingPrefix = this.findMatchingPrefix(number);
        let countryAbbreviation = this.findMatchingCountryAbbreviation(matchingPrefix.foundPrefix);

        // Canadian exception
        if (matchingPrefix.biggestPrefix === 1) {
            countryAbbreviation = this.isCanadianNumber(number) ? 'CA' : 'US';
        }

        return countryAbbreviation;
    }

    transform(number: any): string {
        if (!number) {
            return number;
        }

        return this.getCountryAbbreviation(number);
    }
}
