export class SubService {
    id: number;
    name: string;
    switchboard_type: string;
    retail_cost: number;
    renew_days: number;
    switchboard_quantity: number;
    switchboard_quality: number;
    feature_code: string;
}
