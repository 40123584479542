import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

declare var moment: any;

@Pipe({name: 'has_expired'})
export class HasExpiredPipe implements PipeTransform {

    transform(service: any) {
        return service && service.expires_at && moment(service.expires_at).isBefore();
    }
}
