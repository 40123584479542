import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

import { LoginService } from '../../../login/login.service';
import { HandsetService } from '../handset.service';

import { CountryCodes } from '../../../tools/country-codes';

declare var toastr: any;
declare var alertify: any;
declare var moment: any;

@Component({
  selector: 'app-handset-phonebook',
  templateUrl: './handset-phonebook.component.html',
  styleUrls: ['./handset-phonebook.component.css']
})
export class HandsetPhonebookComponent implements OnInit {

    account_id;

    phonebook_groups;
    contacts;

    new_contact: any = {};
    current_contact: any;

    current_group: any = {};

    new_office_number = new UntypedFormGroup({
        phone: new UntypedFormControl(undefined, [Validators.required])
    });
    new_mobile_number = new UntypedFormGroup({
        phone: new UntypedFormControl(undefined, [Validators.required])
    });

    current_office_number = new UntypedFormGroup({
        phone: new UntypedFormControl(undefined, [Validators.required])
    });
    current_mobile_number = new UntypedFormGroup({
        phone: new UntypedFormControl(undefined, [Validators.required])
    });

    @ViewChild('group_modal') group_modal;

    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;

    constructor(
        private authService: LoginService,
        private handsetService: HandsetService,
    ) { }

    ngOnInit(): void {
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account_id = account.id;
            this.getPhonebookGroups();
            this.getContacts();
        });
    }

    getPhonebookGroups() {
        this.handsetService.getPhonebookGroups(this.account_id)
            .subscribe(groups => this.phonebook_groups = groups);
    }

    getContacts() {
        this.handsetService.getContacts(this.account_id)
            .subscribe(contacts => this.contacts = contacts);
    }

    createContact() {
        const office_number = this.new_office_number.controls.phone.value?.e164Number || '';
        const mobile_number = this.new_mobile_number.controls.phone.value?.e164Number || '';
        this.new_contact['office_number'] = office_number.replace('+', '');
        this.new_contact['mobile_number'] = mobile_number.replace('+', '');

        this.handsetService.createContact(this.account_id, this.new_contact)
        .subscribe(contact => {
            if (contact) {
                toastr.success(`Contact added`);
                this.getContacts();
                this.new_contact = {};
                this.new_office_number.reset();
                this.new_mobile_number.reset();
            }
        });
    }

    editContact(contact) {
        this.current_contact = contact;
        this.current_office_number.setValue({
            phone: this.current_contact.office_number,
        });
        this.current_mobile_number.setValue({
            phone: this.current_contact.mobile_number,
        });
    }

    deleteContact(contact) {
        alertify.confirm('Are you sure you want to delete this contact?', () => {
            this.handsetService.deleteContact(this.account_id, contact)
            .subscribe(() => {
                this.getContacts();
                toastr.success('Deleted Contact!');
            });
        });
    }

    saveContact() {
        const office_number = this.current_office_number.controls.phone.value?.e164Number || '';
        const mobile_number = this.current_mobile_number.controls.phone.value?.e164Number || '';
        this.current_contact['office_number'] = office_number.replace('+', '');
        this.current_contact['mobile_number'] = mobile_number.replace('+', '');

        this.handsetService.updateContact(this.account_id, this.current_contact)
        .subscribe(contact => {
            console.log(contact);
            toastr.success(`Contact edited`);
            this.getContacts();
            this.current_office_number.reset();
            this.current_mobile_number.reset();
            this.current_contact = {};
        });
    }

    checkOpenContact(value) {
        if (+value === -1) {
            this.group_modal.open();
        }
    }

    saveGroup() {
        let request;
        if (this.current_group.id) {
            request = this.handsetService.updatePhonebookGroup(this.account_id, this.current_group);
        } else {
            request = this.handsetService.createPhonebookGroup(this.account_id, this.current_group);
        }

        request.subscribe(group => {
            if (group.id) {
                toastr.success(`Group saved`);
                if (!this.current_group.id) {
                    this.new_contact['phonebook_group_id'] = group.id;
                    this.group_modal.close();
                } else {
                    this.getContacts();
                }
                this.current_group = {};
                this.getPhonebookGroups();
            } else {
                this.group_modal.stopLoading();
            }
        });
    }

    editGroup(group) {
        this.current_group = group;
    }

    deleteGroup(group) {
        alertify.confirm('Are you sure you want to delete this group?', () => {
            this.handsetService.deletePhonebookGroup(this.account_id, group)
            .subscribe(() => {
                this.getPhonebookGroups();
                toastr.success('Deleted Group!');
            });
        });
    }

}
