import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

import { RatesService } from '../../rates/rates.service';

import { CountryCodes } from '../../tools/country-codes';

@Component({
    selector: 'app-rate-checker',
    templateUrl: './rate-checker.component.html',
    styleUrls: ['./rate-checker.component.css']
})
export class RateCheckerComponent implements OnInit {

    @Input() bundles = [];
    @Input() show = true;

    public rates;
    country_code = '44';
    search_term;

    public countryCodes = CountryCodes.e164_prefixes;

    phoneForm = new UntypedFormGroup({
        phone: new UntypedFormControl(undefined, [Validators.required])
    });

    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;

    constructor(
        private ratesService: RatesService,
    ) { }

    ngOnInit() {

    }

    public search() {
        const search = this.phoneForm.controls.phone.value.e164Number.replace('+', '');
        this.searchNumber(search, rates => this.rates = rates);
    }

    public searchNumber(search, callback) {
        this.ratesService.billNumber(search, this.bundles)
            .subscribe(callback);
    }


}
