import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { KnowYourCustomerService } from '../../admin/know-your-customer.service';

@Component({
  selector: 'app-admin-historic-kyc',
  templateUrl: './admin-historic-kyc.component.html',
  styleUrls: ['./admin-historic-kyc.component.css']
})

export class AdminHistoricKycComponent implements OnInit {
  @Input() account_id = null;
  old_kyc = [];

  constructor(private kycService: KnowYourCustomerService) { }

  ngOnInit(): void {
    this.getHistoricKYC();
  }

  getHistoricKYC() {
    this.kycService.getHistoricKYC(this.account_id)
      .subscribe(kyc => {
        this.old_kyc = kyc.data;
      });
  }
}
