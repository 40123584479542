<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Voicemails</h2>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table align-items-center table-flush">
                    <tbody *ngIf="accounts && accounts.path else no_accounts">
                        <tr *ngFor="let account of accounts.data">
                            <th scope="row">
                                <h6 class="text-muted">{{ account.id + sid_start }}</h6>
                                <a routerLink="/voicemail-inboxes/detail/{{ account.id }}">{{ account.company_name }}</a>
                            </th>
                            <td><i class="fas fa-lightbulb active-state-{{ account.active_state }}"></i> {{ account.active_state|formatAccountState }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
                <app-pagination [pagination]="accounts" url="/voicemail-inboxes"></app-pagination>
            </div>
        </div>
    </div>
</div>

<ng-template #no_accounts>
    <tbody class="tbody">
        <tr class="tr">
            <td class="td" colspan="3">
                <span *ngIf="accounts else is_loading">No Accounts Found</span>
            </td>
        </tr>
    </tbody>
</ng-template>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
