<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Number Search</h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="input-group">
                    <!-- <div class="input-group-prepend">
                        <input type="text" placeholder="Country" class="form-control" list="countryCodes" [(ngModel)]="country_code">
                        <datalist id="countryCodes">
                            <option *ngFor="let code of countryCodes" [value]="code.number">{{ code.name }}</option>
                        </datalist>
                    </div> -->
                    <input type="text" class="form-control text-lg" [(ngModel)]="number_search" placeholder="Number" (keyup.enter)="search()">
                    <div class="input-group-append">
                        <button class="btn btn-primary" (click)="search()">Search</button>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table is-bordered is-striped is-hoverable is-fullwidth">
                    <tbody class="tbody" *ngIf="results_numbers?.length || results_diverts?.length else no_numbers">
                        <tr *ngFor="let number of results_numbers">
                            <td>
                                <a routerLink="/accounts/detail/{{ number.account_id }}">
                                    <span class="flag-icon flag-icon-{{ (number.object_data.e164_number|number_to_country_code).toLowerCase() }}" [title]="number.object_data.e164_number|number_to_country_prefix"></span>
                                    {{ number.object_data.e164_number|number_to_local }}
                                    -
                                    <span class="text-muted text-sm">{{ number.object_data.name }}</span>
                                </a>
                            </td>
                            <td>
                                {{ sid_base + number.account_id }}
                            </td>
                        </tr>
                        <tr *ngFor="let group of results_diverts">
                            <td>
                                <a routerLink="/accounts/detail/{{ group.account_id }}">
                                    <span class="text-muted text-sm">Member of {{ group.name }} ({{ group.extension_number }})</span>
                                    <br>
                                    <app-forwarding-to-partial [destination]="group" show-edit="true"></app-forwarding-to-partial>
                                </a>
                            </td>
                            <td>
                                {{ sid_base + group.account_id }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<ng-template #no_numbers>
    <tr *ngIf="!!results_numbers && !!results_diverts">
        <td>No Numbers</td>
    </tr>
</ng-template>