import { Component, OnInit, OnChanges, Input, ViewChild } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { filter, map } from 'rxjs/operators';

import { LoginService } from './login/login.service';

import { LocationHelper } from './location.helper';

declare var toastr: any;
declare var window: any;
declare var $: any;

const DEFAULT_BRAND_NAME = 'Viper';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnChanges {
    title = '';

    public app = undefined;
    public datenow;
    public mainsite;
    logged_in = null;
    isHome = false;

    constructor(
        private authService: LoginService,
        private titleService: Title,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.mainsite = LocationHelper.getMainSite();
        this.datenow = new Date();
        this.getCurrentApp();
        this.initTitleEvent();
        this.checkLoggedIn();
    }

    getPageTitle() {
        const child = this.activatedRoute.firstChild;
        if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
        }
        return null;
    }

    initTitleEvent() {
        this.router.events
        .pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => {
                window.scrollTo(0, 0);
                this.checkLoggedIn();
                this.isHome = this.router.url === '/home';
                return this.getPageTitle();
            })
        ).subscribe(title => this.setTitle(title));
    }

    ngOnChanges() {
        this.checkLoggedIn();
    }

    checkLoggedIn() {
        this.authService.getCurrentUser()
            .subscribe(user => this.logged_in = !!user?.id);
    }

    getAppFromURL() {
        this.authService.getApps()
        .subscribe(apps => {
            this.app = LocationHelper.getAppFromURL(apps);
            this.setTitle();
            this.setHeaders();
        }, () => window.location.reload());
    }

    getCurrentApp() {
        this.authService.getCurrentApp()
        .subscribe(app => {
            if (app.uuid) {
                this.app = app;
                this.setTitle();
                this.setHeaders();
            } else {
                this.getAppFromURL();
            }
        });
    }

    getBrandName() {
        if (this.app) {
            return this.app.name;
        }
        return DEFAULT_BRAND_NAME;
    }

    setTitle(title = null) {
        const brand = this.getBrandName();

        if (title !== null) {
            this.title = title;
        }

        window.alertify.defaults.glossary.title = brand;

        if (this.title) {
            this.titleService.setTitle(`${this.title} - ${brand}`);
        } else {
            this.titleService.setTitle(brand);
        }

    }

    setHeaders() {
        if (this.app) {
            const app_name = this.getBrandName();
            const short_name = this.app.short_name;
            document.getElementById('meta-description').setAttribute('content', `${app_name} Portal`);
            document.getElementById('fav-icon-apple').setAttribute('href', `/assets/icons/${short_name}/apple-touch-icon.png`);
            document.getElementById('fav-icon-32').setAttribute('href', `/assets/icons/${short_name}/favicon-32x32.png`);
            document.getElementById('fav-icon-16').setAttribute('href', `/assets/icons/${short_name}/favicon-16x16.png`);
            document.getElementById('fav-icon-main').setAttribute('href', `/assets/icons/${short_name}/favicon.png`);
            document.getElementById('custom-css').setAttribute('href', `/assets/css/${short_name}/custom.css`);
        }
    }
}
