import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { LoginService } from '../../login/login.service';
import { KnowYourCustomerService } from '../../admin/know-your-customer.service';
import { GetAddressService } from '../../get-address.service';

declare var toastr: any;

@Component({
    selector: 'app-kyc',
    templateUrl: './kyc.component.html',
    styleUrls: ['./kyc.component.css']
})
export class KycComponent implements OnInit {

    @Input() admin = false;
    @Input() account;
    
    @Input() users;


    country_codes;
    postcode_search;
    addresses;

    old_kyc;
    kyc;

    constructor(
        private authService: LoginService,
        private kycService: KnowYourCustomerService,
        private addressService: GetAddressService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.getKYC();
        this.authService.getCountryCodes()
            .subscribe(codes => this.country_codes = codes);
    }

    getKYC() {
        this.kycService.getKYC(this.account.id)
        .subscribe(kyc => {
            this.kyc = kyc;
            this.old_kyc = JSON.stringify(kyc);
        });
    }

    submitKYC() {
        if (!this.kyc.existing_phone_numbers) {
            toastr.error("Existing Phone Number required");
        }
        else{
            if (JSON.stringify(this.kyc) !== this.old_kyc) {
                this.kycService.createKYC(this.account.id, this.kyc)
                .subscribe(response => {
                    if (response !== 'FAIL') {
                        toastr.success('Customer details saved');
                        if (!this.admin) {
                            this.router.navigate([`/settings`]);
                        }
                    }
                });
            } else {
                toastr.success('Customer details saved');
                if (!this.admin) {
                    this.router.navigate([`/settings`]);
                }
            }
        }
    }

    searchPostcode() {
        this.addressService.getExpandedAddresses(this.postcode_search)
            .subscribe(response => this.addresses = response.addresses);
    }

    setAddress(address, convertAddress = false) {
        let fullAddress;
        if (convertAddress) {
            fullAddress = {
                office_address_line_1: this.addresses[address].line_1,
                office_address_line_2: this.addresses[address].line_2,
                office_city: this.addresses[address].town_or_city,
                office_state_province: this.addresses[address].county,
                office_postal_code: this.postcode_search,
            };
        } else {
            fullAddress = this.addresses[address];
        }
        this.kyc = Object.assign(this.kyc || {}, fullAddress);
    }

    verify(kyc) {
        this.kycService.verifyKYC(kyc.account_id)
        .subscribe(() => {
            toastr.success(`KYC Details Accepted!`);
        });
    }

    hold(kyc) {
        this.kycService.holdKYC(kyc.account_id)
        .subscribe(() => {
            toastr.success(`KYC Details Requires Update`);
        });
    }

    deny(kyc) {
        this.kycService.deleteKYC(kyc.account_id)
        .subscribe(() => {
            toastr.info(`KYC Details Rejected`);
        });
    }


}
