declare var $: any;

export const SipsHelper = {
    sid_start: 1000000,

    generatePassword(length = 15) {
        const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let password = '';
        for (let i = 0; i < length; i++) {
           password += charset[Math.floor(Math.random() * charset.length)];
        }
        return password;
    },

    generateGSWaveQR(object, account_id, host, password) {
        let name = object.object_data.name;
        let user = `${account_id + this.sid_start}-${object.extension_number}`;

        // XML Encoding for safety
        name = $('<div />').text(name).html();
        host = $('<div />').text(host).html();
        user = $('<div />').text(user).html();
        password = $('<div />').text(password).html();

        const xml = `<?xml version="1.0" encoding="utf-8"?>
                    <AccountConfig version="1"><Account>
                    <RegisterServer>${host}</RegisterServer><OutboundServer>${host}</OutboundServer>
                    <UserID>${user}</UserID><AuthID>${user}</AuthID>
                    <AuthPass>${password}</AuthPass>
                    <AccountName>${name}</AccountName><DisplayName>${name}</DisplayName>
                    <Voicemail>1571</Voicemail>
                    </Account></AccountConfig>`;

        // let element $('#GSQR');
        const element  = $('<div />');
        element.qrcode({
            render: 'canvas',
            text: xml,
            size: 300,
        });

        return element.children()[0].toDataURL();
    },

    generateIPVNQR(object, username, cloud_password) {
        
        const provision = 'csc:'+username+object.extension_number+':'+cloud_password+'@IPVN-VOIP';

        const element  = $('<div />');
        element.qrcode({
            render: 'canvas',
            text: provision,
            size: 300,
        });

        return element.children()[0].toDataURL();
    },

};
