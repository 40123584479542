import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { LoginService } from '../../login/login.service';

declare var toastr: any;

@Component({
  selector: 'app-user-notifications',
  templateUrl: './user-notifications.component.html',
  styleUrls: ['./user-notifications.component.css']
})
export class UserNotificationsComponent implements OnInit {

    @Input() user;

    preferences;

    @ViewChild('noticications_modal') notificationsModal;

    constructor(
        private authService: LoginService,
    ) { }

    ngOnInit() {
        // this.getPreferences();
    }

    getPreferences() {
        this.preferences = null;
        this.authService.getUserConfig(this.user.account_id, this.user)
            .subscribe(preferences => this.preferences = preferences);
    }

    open(user) {
        this.user = user;
        this.getPreferences();
        this.notificationsModal.open();
    }

    save() {
        this.authService.updateUserConfig(this.user.account_id, this.user, this.preferences)
        .subscribe(response => {
            toastr.success(`Email preferences saved!`);
            this.notificationsModal.close();
        });
    }

}
