import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { LoginService } from '../login/login.service';
import { CdrService } from '../cdr.service';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-admin-duplicate-destinations',
    templateUrl: './admin-duplicate-destinations.component.html',
    styleUrls: ['./admin-duplicate-destinations.component.css']
})
export class AdminDuplicateDestinationsComponent implements OnInit {

    direction = 'outbound';
    page = 1;
    report;

    accounts = null;

    new_ignore;

    @ViewChild('accounts_modal') accountsModal;

    constructor(
        private authService: LoginService,
        private cdrService: CdrService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.activatedRoute.paramMap.subscribe(params => {
            this.direction = params.get('direction') || this.direction;
            this.page = +params.get('page') || this.page;
            this.loadReport();
        });
    }

    loadReport() {
        if (this.direction === 'ignored') {
            this.cdrService.getIgnoreList(this.page)
                .subscribe(report => this.report = report);
        } else {
            this.cdrService.getDuplicateDestinations(this.direction)
            .subscribe(report => {
                this.report = report;
            });
        }
    }

    viewAccounts(destination) {
        this.accounts = null;
        this.cdrService.getDuplicateDestinationsDetail(this.direction, destination)
        .subscribe(accounts => {
            this.accounts = accounts;
        });
        this.accountsModal.title = `View Accounts For ${destination}`;
        this.accountsModal.open();
    }

    deleteIngored(cli) {
        alertify.confirm(`Are you sure you want to delete ${cli.e164_number}?`, () => {
            this.cdrService.removeFromIgnoreList(cli.e164_number)
            .subscribe(response => {
                this.loadReport();
                toastr.success(`${cli.e164_number} removed from ignore list`);
            });
        });
    }

    addIgnored() {
        if (!this.new_ignore) {
            toastr.danger('Please input a CLI');
        }

        this.cdrService.addToIgnoreList(this.new_ignore)
        .subscribe(response => {
            if (response.e164_number) {
                this.new_ignore = null;
                this.loadReport();
                toastr.success(`${response.e164_number} added to ignore list`);
            }
        });
    }

}
