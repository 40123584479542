<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Bundles</h2>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table is-bordered is-striped is-hoverable is-fullwidth">
                    <thead class="thead">
                        <tr class="tr">
                            <th class="th">Name</th>
                            <th class="th">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody class="tbody" *ngIf="bundles && bundles.length else no_bundles">
                        <tr class="tr" *ngFor="let bundle of bundles">
                            <td class="td">
                                <span class="text-lg">{{ bundle.name }}</span>
                                <button class="btn btn-sm btn-outline-secondary" (click)="editBundle(bundle)">
                                    <i class="fas fa-edit"></i>
                                </button>
                                <br>
                                <span class="text-sm text-muted">Using:</span>
                                <ul>
                                    <li *ngFor="let sheet of bundle.sheets">
                                        {{ sheet.name }} ({{ sheet.minutes }} minutes)
                                        <button class="btn btn-sm btn-danger" (click)="detachSheet(sheet)" [attr.disabled]="sheet.deleting" [class.is-loading]="sheet.deleting">Detach</button>
                                    </li>
                                    <li *ngIf="bundle.sheets.length === 0">No Sheets</li>
                                </ul>
                                <button class="btn btn-primary btn-sm" (click)="loadAttachedSheets(bundle)">Attach a Sheet</button>
                            </td>
                            <td class="td"><button class="btn btn-danger is-danger" (click)="deleteBundle(bundle)" [attr.disabled]="bundle.deleting">Delete</button></td>
                        </tr>
                    </tbody>
                    <tfoot class="tfoot">
                        <tr class="tr">
                            <th class="th" colspan="2">
                                <button class="btn btn-primary is-primary" (click)="createBundle()">Add Bundle</button>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>

<app-modal title="New Bundle" (save)="saveBundle()" #bundleModal>
    <div class="field">
        <label class="label">Name</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Name" [(ngModel)]="bundle.name">
        </div>
    </div>
</app-modal>

<app-modal title="Add sheet for {{ bundle.name }}" (save)="attachSheet()" #attachedSheetsModal>
    <div class="select">
        <select [(ngModel)]="sheet_id" class="form-control">
            <ng-container *ngFor="let sheet of allSheets">
                <option value="{{ sheet.id }}" *ngIf="sheetNotAttached(sheet)">
                    {{ sheet.name }}
                </option>
            </ng-container>
        </select>
    </div>
</app-modal>

<ng-template #no_bundles>
    <tbody class="tbody">
        <tr class="tr">
            <td class="td" colspan="2">
                <ng-container *ngIf="bundles else loading_bar">No Bundles Found</ng-container>
            </td>
        </tr>
    </tbody>
</ng-template>

<ng-template #loading_bar>
    <app-loadingbar></app-loadingbar>
</ng-template>
