import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { LoginService } from '../login/login.service';

declare var moment: any;
declare var Chart: any;
declare var $: any;

@Component({
  selector: 'app-admin-reports',
  templateUrl: './admin-reports.component.html',
  styleUrls: ['./admin-reports.component.css']
})
export class AdminReportsComponent implements OnInit {

    page;
    reports: any;
    current_report;
    report_type;
    report_types = [];

    @ViewChild('report_modal') reportModal;

    constructor(
        private authService: LoginService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe(params => {
            this.report_type = params.get('name');
            this.page = +params.get('page') || 1;
            this.getReports();
        });
    }

    getReports() {
        let request;
        if (this.report_type) {
            request = this.authService.readReportsOfName(this.report_type, this.page);
        } else {
            request = this.authService.readAllReports();
        }
        request.subscribe(reports => {
            this.reports = reports;
            if (!this.report_type) {
                this.report_types = this.reports.map(report => report.url_name);
            }
        });
    }

    viewReport(report) {
        this.current_report = report;
        this.reportModal.open();
    }

    reportData() {
        if (this.current_report) {
            return JSON.parse(this.current_report.report_data);
        }
        return null;
    }

}
