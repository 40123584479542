import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from '../login/authentication.guard';
import { RoleGuard } from '../sections/roles/role.guard';

import { PortalNumbersComponent } from './numbers/portal-numbers/portal-numbers.component';
import { PortalNumberEditComponent } from './numbers/portal-number-edit/portal-number-edit.component';
import { PortalGroupsComponent } from './groups/portal-groups/portal-groups.component';
import { PortalGroupEditComponent } from './groups/portal-group-edit/portal-group-edit.component';
import { PortalCallstatsComponent } from './portal-callstats/portal-callstats.component';
import { PortalAnnouncementsComponent } from './announcements/portal-announcements/portal-announcements.component';
import { PortalAnnouncementEditComponent } from './announcements/portal-announcement-edit/portal-announcement-edit.component';
import { PortalMenusComponent } from './menus/portal-menus/portal-menus.component';
import { PortalMenuEditComponent } from './menus/portal-menu-edit/portal-menu-edit.component';
import { PortalVoicemailsComponent } from './voicemails/portal-voicemails/portal-voicemails.component';
import { PortalVoicemailEditComponent } from './voicemails/portal-voicemail-edit/portal-voicemail-edit.component';
import { PortalQueuesComponent } from './queues/portal-queues/portal-queues.component';
import { PortalQueueEditComponent } from './queues/portal-queue-edit/portal-queue-edit.component';
import { PortalSipsComponent } from './sips/portal-sips/portal-sips.component';
import { PortalSipEditComponent } from './sips/portal-sip-edit/portal-sip-edit.component';

import { PortalMediaComponent } from './portal-media/portal-media.component';
import { PortalNewSwitchboardObjectComponent } from './portal-new-switchboard-object/portal-new-switchboard-object.component';
import { PortalNewNumberComponent } from './portal-new-number/portal-new-number.component';
import { PortalCallRestrictionsComponent } from './portal-call-restrictions/portal-call-restrictions.component';
import { PortalTimeManagementComponent } from './portal-time-management/portal-time-management.component';
import { PortalTemporaryCloseComponent } from './portal-temporary-close/portal-temporary-close.component';
import { PortalChannelsComponent } from './portal-channels/portal-channels.component';
import { PortalBundlesComponent } from './portal-bundles/portal-bundles.component';
import { PortalFlowComponent } from './portal-flow/portal-flow.component';

import { HandsetProvisioningComponent } from './handset-provisioning/handset-provisioning.component';
import { HandsetSpeeddialComponent } from './handset-provisioning/handset-speeddial/handset-speeddial.component';
import { HandsetPhonebookComponent } from './handset-provisioning/handset-phonebook/handset-phonebook.component';


const routes: Routes = [
    { path: 'numbers', canActivate: [AuthenticationGuard], component: PortalNumbersComponent, data: {title: 'Numbers'} },
    { path: 'numbers/new', canActivate: [AuthenticationGuard, RoleGuard], component: PortalNewNumberComponent, data: {roles: ['Account Owner'], title: 'New Number'} },
    { path: 'numbers/:uuid', canActivate: [AuthenticationGuard], component: PortalNumberEditComponent, data: {title: 'Number'} },

    { path: 'groups', canActivate: [AuthenticationGuard], component: PortalGroupsComponent, data: {title: 'Groups'} },
    { path: 'groups/new', canActivate: [AuthenticationGuard, RoleGuard], component: PortalNewSwitchboardObjectComponent, data: {roles: ['Account Owner'],  object: 'group', title: 'New Group' } },
    { path: 'groups/:uuid', canActivate: [AuthenticationGuard], component: PortalGroupEditComponent, data: {title: 'Group'} },

    { path: 'announcements', canActivate: [AuthenticationGuard], component: PortalAnnouncementsComponent, data: {title: 'Announcements'} },
    { path: 'announcements/new', canActivate: [AuthenticationGuard, RoleGuard], component: PortalNewSwitchboardObjectComponent, data: {roles: ['Account Owner'],  object: 'announcement', title: 'New Announcement' } },
    { path: 'announcements/:uuid', canActivate: [AuthenticationGuard], component: PortalAnnouncementEditComponent, data: {title: 'Announcement'} },

    { path: 'menus', canActivate: [AuthenticationGuard], component: PortalMenusComponent, data: {title: 'Menus'} },
    { path: 'menus/new', canActivate: [AuthenticationGuard, RoleGuard], component: PortalNewSwitchboardObjectComponent, data: {roles: ['Account Owner'],  object: 'menu', title: 'New Menu' } },
    { path: 'menus/:uuid', canActivate: [AuthenticationGuard], component: PortalMenuEditComponent, data: {title: 'Menu'} },

    { path: 'voicemails', canActivate: [AuthenticationGuard], component: PortalVoicemailsComponent, data: {title: 'Voicemails'} },
    { path: 'voicemails/new', canActivate: [AuthenticationGuard, RoleGuard], component: PortalNewSwitchboardObjectComponent, data: {roles: ['Account Owner'],  object: 'voicemail', title: 'New Voicemail' } },
    { path: 'voicemails/:uuid', canActivate: [AuthenticationGuard], component: PortalVoicemailEditComponent, data: {title: 'Voicemail'} },

    { path: 'queues', canActivate: [AuthenticationGuard], component: PortalQueuesComponent, data: {title: 'Queues'} },
    { path: 'queues/new', canActivate: [AuthenticationGuard, RoleGuard], component: PortalNewSwitchboardObjectComponent, data: {roles: ['Account Owner'],  object: 'queue', title: 'New Queue' } },
    { path: 'queues/:uuid', canActivate: [AuthenticationGuard], component: PortalQueueEditComponent, data: {title: 'Queue'} },

    { path: 'sips', canActivate: [AuthenticationGuard], component: PortalSipsComponent, data: {title: 'VoIP Users'} },
    { path: 'sips/new', canActivate: [AuthenticationGuard, RoleGuard], component: PortalNewSwitchboardObjectComponent, data: {roles: ['Account Owner'],  object: 'sip', title: 'New VoIP User' } },
    { path: 'sips/:uuid', canActivate: [AuthenticationGuard], component: PortalSipEditComponent, data: {title: 'VoIP User'} },

    { path: 'call-stats/:direction/number/:number/:page', canActivate: [AuthenticationGuard], component: PortalCallstatsComponent, data: {title: 'Call Stats'} },
    { path: 'call-stats/:direction/number/:number', canActivate: [AuthenticationGuard], component: PortalCallstatsComponent, data: {title: 'Call Stats'} },
    { path: 'call-stats/:direction/:page', canActivate: [AuthenticationGuard], component: PortalCallstatsComponent, data: {title: 'Call Stats'} },
    { path: 'call-stats/:direction', canActivate: [AuthenticationGuard], component: PortalCallstatsComponent, data: {title: 'Call Stats'} },
    { path: 'call-stats', canActivate: [AuthenticationGuard], component: PortalCallstatsComponent, data: {title: 'Call Stats'} },

    { path: 'call-restrictions/:direction', canActivate: [AuthenticationGuard], component: PortalCallRestrictionsComponent, data: {title: 'Call Restrictions'} },
    { path: 'call-restrictions', canActivate: [AuthenticationGuard], component: PortalCallRestrictionsComponent, data: {title: 'Call Restrictions'} },

    { path: 'time-management', canActivate: [AuthenticationGuard], component: PortalTimeManagementComponent, data: {title: 'Time Management'} },
    { path: 'time-management/:number', canActivate: [AuthenticationGuard], component: PortalTimeManagementComponent, data: {title: 'Time Management'} },
    { path: 'temporary-close', canActivate: [AuthenticationGuard], component: PortalTemporaryCloseComponent, data: {title: 'Temporary Close'} },
    { path: 'temporary-close/:number', canActivate: [AuthenticationGuard], component: PortalTemporaryCloseComponent, data: {title: 'Temporary Close'} },

    { path: 'channels', canActivate: [AuthenticationGuard], component: PortalChannelsComponent, data: {title: 'Channels'} },

    { path: 'inbound-bundles/:object_uuid', canActivate: [AuthenticationGuard], component: PortalBundlesComponent, data: { direction: 'inbound', title: 'Inbound Bundles' } },
    { path: 'inbound-bundles', canActivate: [AuthenticationGuard], component: PortalBundlesComponent, data: { direction: 'inbound', title: 'Inbound Bundles' } },
    { path: 'outbound-bundles/:object_uuid', canActivate: [AuthenticationGuard], component: PortalBundlesComponent, data: { direction: 'outbound', title: 'Outbound Bundles' } },
    { path: 'outbound-bundles', canActivate: [AuthenticationGuard], component: PortalBundlesComponent, data: { direction: 'outbound', title: 'Outbound Bundles' } },

    { path: ':object/:uuid/flow', canActivate: [AuthenticationGuard], component: PortalFlowComponent, data: {title: 'Flow'} },
    { path: 'flow', canActivate: [AuthenticationGuard], component: PortalFlowComponent, data: {title: 'Flow'} },

    { path: 'handsets', canActivate: [AuthenticationGuard], component: HandsetProvisioningComponent, data: {title: 'Handsets'} },
    { path: 'handsets/speed-dial/:handset_id', canActivate: [AuthenticationGuard], component: HandsetSpeeddialComponent, data: {title: 'Handset Speedial'} },
    { path: 'handsets/:account_id/speed-dial/:handset_id', canActivate: [AuthenticationGuard], component: HandsetSpeeddialComponent, data: {title: 'Handset Speedial'} },
    { path: 'handsets/phonebook', canActivate: [AuthenticationGuard], component: HandsetPhonebookComponent, data: {title: 'Handset Phonebook'} },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class PortalRoutingModule { }
