<ng-container *ngIf="destination">
    <i class="{{ destination?.object_type|switchboard_object_icon }}" [title]="destination?.object_type"></i>
    ({{ destination.object_type|alias }})
    <ng-container *ngIf="destination.object_type === 'Number'">
        <span class="flag-icon flag-icon-{{ (destination.object_data.e164_number|number_to_country_code).toLowerCase() }}" [title]="destination.object_data.e164_number|number_to_country_prefix"></span>
                            
        {{ destination.object_data.e164_number|number_to_local }}

        -
    </ng-container>
    {{ destination?.object_data.name }}
    <span class="badge badge-pill badge-contrast badge-info" *ngIf="has_rules">Destination May Vary</span>

    <ng-container *ngIf="destination.object_data.internal_members?.length || destination.object_data.external_members?.length">
        <ul class="memberbox">
            <ng-container *ngIf="(destination.object_data.external_members || []).concat(destination.object_data.internal_members || []) as members">
                <ng-container *ngFor="let member of members; let i = index">
                    <li *ngIf="i < 2">
                        <ng-container *ngIf="member.e164_number">
                            <span class="flag-icon flag-icon-{{ (member.e164_number|number_to_country_code).toLowerCase() }}" [title]="member.e164_number|number_to_country_prefix"></span>
                            {{ member.e164_number|number_to_local }}
                        </ng-container>
                        <ng-container *ngIf="member.sip">
                            {{ member.sip.extension_number}} - {{ member.sip.object_data.name }}
                        </ng-container>
                    </li>
                </ng-container>
                <ng-container *ngIf="members.length > 2">
                    <li>..and {{ members.length - 2 }} more</li>
                </ng-container>
            </ng-container>
        </ul>
    </ng-container>
<!--     <a class="text-sm renamebutton" *ngIf="show_edit" routerLink="/{{ destination?.object_type.toLowerCase() }}s/{{ destination?.uuid }}">
        (edit {{ (destination.object_type|alias).toLowerCase() }})
    </a> -->
</ng-container>
