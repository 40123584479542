import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { BillingService } from '../../billing.service';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-invoice-adjustment',
    templateUrl: './invoice-adjustment.component.html',
    styleUrls: ['./invoice-adjustment.component.css']
})
export class InvoiceAdjustmentComponent implements OnInit {

    @Input() account_id;
    @Input() recurring = false;

    page = 1;

    adjustments;

    current_adjustment: any = {};
    datepickerdate = '';

    constructor(
        private billingService: BillingService,
    ) { }

    ngOnInit(): void {
        this.getInvoiceAdjustments();
    }

    getInvoiceAdjustments() {
        let request;
        if (this.recurring) {
            request = this.billingService.getRecurringInvoiceAdjustments(this.account_id, this.page);
        } else {
            request = this.billingService.getInvoiceAdjustments(this.account_id, this.page);
        }
        request.subscribe(adjustments => this.adjustments = adjustments);
    }

    changePage(page) {
        this.page = page;
        this.getInvoiceAdjustments();
    }

    createAdjustment() {
        let request;
        const current_adjustment = { ...this.current_adjustment };
        current_adjustment.amount *= 100;
        if (this.recurring) {
            request = this.billingService.createRecurringInvoiceAdjustment(this.account_id, current_adjustment);
        } else {
            request = this.billingService.createInvoiceAdjustment(this.account_id, current_adjustment);
        }
        request.subscribe(out => {
            if (out.uuid) {
                this.current_adjustment = {};
                this.datepickerdate = '';
                toastr.success('Adjustment created!');
                this.page = 1;
                this.getInvoiceAdjustments();
            }
        });
    }

    deleteAdjustment(adjustment) {
        let request;
        alertify.confirm(`Are you sure you want to cancel this invoice adjustment?`, () => {
            if (this.recurring) {
                request = this.billingService.deleteRecurringInvoiceAdjustment(this.account_id, adjustment);
            } else {
                request = this.billingService.deleteInvoiceAdjustment(this.account_id, adjustment);
            }
            request.subscribe(out => {
                toastr.info('Deleting invoice adjustment!');
                this.getInvoiceAdjustments();
            });
        });
    }

    onDateSelect(date_picker) {
        this.current_adjustment.expires_at = `${date_picker.year}-${date_picker.month}-${date_picker.day}`;
    }

}
