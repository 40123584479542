import { Component, OnInit } from '@angular/core';

import { KnowYourCustomerService } from '../../../admin/know-your-customer.service';

@Component({
    selector: 'app-kyc-badge',
    templateUrl: './kyc-badge.component.html',
    styleUrls: ['./kyc-badge.component.css']
})
export class KycBadgeComponent implements OnInit {

    kycs: any;

    constructor(
        private kycService: KnowYourCustomerService,
    ) { }

    ngOnInit(): void {
        this.kycService.getAdminKYC()
            .subscribe(kycs => this.kycs = kycs);
    }

}
