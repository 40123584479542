<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Temporary Close</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Temporary Close</h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <p>Use the following to temporarily close your system, sending inbound calls to a selectable destination when closed</p>
                <p>This is great for planning ahead for closed times, like New Year and holiday periods. You can plan up to 12 months ahead.</p>
                <p class="font-weight-bold advancedlink">
                    <a *ngIf="!advancedMode" (click)="advancedMode = true;">Use Advanced Mode</a>
                    <a *ngIf="advancedMode" (click)="advancedMode = false;">Use Basic Mode</a>
                </p>
                <p class="font-weight-bold">
                    Set Temporary Close rules on
                    <span class="dropdown">
                        <button class="btn btn-primary dropdown-toggle" type="button" id="number-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <ng-container *ngIf="getNumber()?.object_data.e164_number">
                                <span class="flag-icon flag-icon-{{ (getNumber()?.object_data.e164_number|number_to_country_code)?.toLowerCase() }}" [title]="getNumber()?.object_data.e164_number|number_to_country_prefix"></span>
                                {{ getNumber()?.object_data.e164_number|number_to_local }}
                            </ng-container>
                            <ng-container *ngIf="!getNumber()?.object_data.e164_number">
                                <i [class]="getObject()|switchboard_object_icon"></i> {{ getObject()?.extension_number }} - {{ getObject()?.object_data.name }}
                            </ng-container>
                            <ng-container *ngIf="advancedMode">
                                <ng-container *ngIf="!number">
                                    [Select Object]
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!advancedMode">
                                <ng-container *ngIf="!number">
                                    [Select Number]
                                </ng-container>
                            </ng-container>
                        </button>
                        <span class="dropdown-menu" aria-labelledby="number-dropdown">
                            <ng-container *ngIf="!advancedMode">
                                <a class="dropdown-item" *ngFor="let number of numbers" routerLink="/temporary-close/{{ number.uuid }}">
                                    <span class="flag-icon flag-icon-{{ (number.object_data.e164_number|number_to_country_code).toLowerCase() }}" [title]="number.object_data.e164_number|number_to_country_prefix"></span>
                                    {{ number.object_data.e164_number|number_to_local }}
                                </a>
                            </ng-container>
                            <ng-container *ngIf="advancedMode">
                                <ng-container *ngFor="let object of switchboard_objects">
                                    <a class="dropdown-item" *ngIf="object.object_type !== 'Sip' && object.object_type !== 'Number'" routerLink="/temporary-close/{{ object.uuid }}">
                                        <i [class]="object|switchboard_object_icon"></i> {{ object.extension_number }} - {{ object.object_data.name }}
                                    </a>
                                </ng-container>
                            </ng-container>
                        </span>
                    </span>
                </p>

                <ng-container *ngIf="number">
                    <table *ngIf="rules else is_loading" class="table is-bordered is-striped is-hoverable is-fullwidth darkcard">
                        <tbody>
                            <ng-container *ngFor="let rule of rules">
                                <tr>
                                    <th>
                                        <h5 class="whitetext">{{ rule.name }}</h5>
                                        <h6 class="text-muted whitetext">
                                            Redirect to:
                                            <br />
                                            {{ getDestination(rule) }}
                                        </h6>
                                    </th>
                                    <td>
                                        <h5 class="whitetext">
                                            {{ rule.date_from|format_local_date }} {{ rule.date_from.split(' ')[1] }}
                                            <br>
                                            to
                                            <br>
                                            {{ rule.date_to|format_local_date }} {{ rule.date_to.split(' ')[1] }}
                                        </h5>
                                    </td>
                                    <td>
                                        <!-- <button class="btn btn-secondary" (click)="editRule(rule)" [disabled]="internal_disabled">Edit</button> -->
                                        <button class="btn btn-danger" (click)="deleteRule(rule)" [disabled]="internal_disabled">Delete</button>
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="rules.length === 0">
                                <tr>
                                    <td colspan="3">No Rules</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>

                    <button class="btn btn-primary" (click)="newRule()">Add New Temporary Close Rule</button>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<app-modal title="New Temporary Close" size="big" (save)="saveRule()" #rule_modal>
    <!-- <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="preset-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Use Preset
        </button>
        <div class="dropdown-menu" aria-labelledby="preset-dropdown">
            <a class="dropdown-item" *ngFor="let preset of presets" (click)="usePreset(preset)">{{ preset.name }}</a>
        </div>
    </div> -->

    <div class="form-row">
        <label for="rule-form-name">Name</label>
        <input id="rule-form-name" type="text" class="form-control" placeholder="e.g. 'New Years Day' or 'Bank Holiday'" [(ngModel)]="rule.name">
    </div>

    <div class="form-row">
        <div class="col">
            <h4>Date Range</h4>
            <app-ngbd-datepicker-range #datepicker></app-ngbd-datepicker-range>
        </div>
    </div>

    <div class="form-row">
        <div class="col-md-6">
            <label>Time Start</label>
            <app-time-dropdown [(model)]="rule.start_time"></app-time-dropdown>
        </div>
        <div class="col-md-6">
            <label>Time End</label>
            <app-time-dropdown [(model)]="rule.end_time"></app-time-dropdown>
        </div>
    </div>

    <div class="form-group">
        <label for="rulesInputDestination">When closed send calls to</label>
        <app-switchboard-object-dropdown [(model)]="rule.switchboard_destination_uuid" 
                                         [switchboard_objects]="switchboard_objects"></app-switchboard-object-dropdown>
    </div>
</app-modal>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
