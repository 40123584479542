import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { LoginService } from '../login/login.service';
import { SwitchboardService } from '../switchboard/switchboard.service';

declare var toastr: any;

@Component({
  selector: 'app-admin-verify-addresses',
  templateUrl: './admin-verify-addresses.component.html',
  styleUrls: ['./admin-verify-addresses.component.css']
})
export class AdminVerifyAddressesComponent implements OnInit {

    addresses;
    page = 1;

    constructor(
        private authService: LoginService,
        private switchboardService: SwitchboardService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.loadAddresses();
    }

    loadAddresses() {
        this.authService.getAdminAddresses(this.page)
            .subscribe(addresses => this.addresses = addresses);
    }

    changePage(page) {
        this.page =  page;
        this.loadAddresses();
    }

    accept(address) {
        let number = address.switchboard_object;
        address.needs_verification = 0;
        this.switchboardService.updateNumberAddress(number.account_id, {uuid: number.uuid}, address)
        .subscribe(() => {
            toastr.success(`Address Accepted!`);
            this.page = 1;
            this.loadAddresses();
        });
    }

    deny(address) {
        this.switchboardService.deleteNumberAddress(address.switchboard_object)
        .subscribe(() => {
            toastr.info(`Address Rejected`);
            this.page = 1;
            this.loadAddresses();
        });
    }

}
