<ng-container *ngIf="!uploading">
    <label for="">Text To Speech Voice</label>
    <label for="">Text</label>
    <textarea class="form-control" [(ngModel)]="tts_text"></textarea>

    <p>
        Character limit: {{ tts_limit - tts_text.length }} characters left
        <br>
        (You have a daily limit of {{ daily_tts_limit - tts_text.length }} characters left)
        <!-- <i class="far fa-question-circle text-lg" data-toggle="tooltip" data-placement="top" title="Some text about punctuation"></i> -->
    </p>
    <ng-container *ngIf="voices">
        <select class="form-control" [(ngModel)]="voice_language" (change)="voice_index = 0">
            <option *ngFor="let language of voice_languages" [value]="language">{{ getLanguageName(language) }}</option>
        </select>
        &nbsp;
        <select class="form-control" [(ngModel)]="voice_index">
            <option *ngFor="let voice of availableVoices(); let i = index" [value]="i">
                {{ voice.gender_label }}
            </option>
        </select>
    </ng-container>
</ng-container>

<audio *ngIf="media_preview" id="tts-preview" controls controlsList="nodownload"></audio>

<ng-container *ngIf="uploading">
    <app-loadingbar></app-loadingbar>
</ng-container>