import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { LoginService } from '../../../login/login.service';
import { SwitchboardService } from '../../../switchboard/switchboard.service';
import { MediaService } from '../../../audio/media.service';
import { ServicesService } from '../../../services/services.service';

import { SwitchboardObject } from '../../../switchboard/switchboard_object';

declare var moment: any;

@Component({
    selector: 'app-portal-voicemails',
    templateUrl: './portal-voicemails.component.html',
    styleUrls: ['./portal-voicemails.component.css']
})
export class PortalVoicemailsComponent implements OnInit {

    @Input() account_id;

    hasAvailableServices = false;

    voicemails: SwitchboardObject[];
    switchboard_objects: SwitchboardObject[];
    current_number: SwitchboardObject;
    media: any[];

    services = {};

    constructor(
        private authService: LoginService,
        private switchboardService: SwitchboardService,
        private serviceService: ServicesService,
        private mediaService: MediaService
    ) { }

    ngOnInit() {
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account_id = account.id;
            this.loadVoicemails();
            this.loadSwitchboardObjects();
            this.loadMedia();
        });
    }

    loadVoicemails() {
        this.switchboardService.readVoicemailsOnAccount(this.account_id)
            .subscribe(voicemails => this.voicemails = voicemails);
    }

    loadSwitchboardObjects() {
        const object_type = 'Voicemail';
        this.switchboardService.getSwitchboardObjectsForAccount(this.account_id)
        .subscribe(objects => {
            this.switchboard_objects = objects;
            const used_services = objects
                .filter(object => object.object_type === object_type)
                .map(object => object.service_instance_id);
            this.serviceService.getServicesForAccount(this.account_id, object_type)
            .subscribe(services => {
                const free_services = services.filter(service => !used_services.includes(service.id));
                const hasFreeServices = free_services.length > 0;
                this.hasAvailableServices ||= hasFreeServices;
                for (let service of services) {
                    this.services[service.id] = service;
                }
            });
        });

        this.serviceService.getAvailableServices(this.account_id, object_type)
            .subscribe(services => this.hasAvailableServices ||= services.length > 0);
    }

    loadMedia() {
        this.mediaService.getSwitchboardMedia(this.account_id)
            .subscribe(media => this.media = media);
    }

    getSwitchboardObject(uuid) {
        for (let object of this.switchboard_objects) {
            if (object.uuid === uuid) {
                return object;
            }
        }
        return null;
    }

}
