import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-time-dropdown',
  templateUrl: './time-dropdown.component.html',
  styleUrls: ['./time-dropdown.component.css']
})
export class TimeDropdownComponent implements OnInit {

    @Input() time: string;
    @Input('minutes-interval') minutesInterval: number = 1;

    hour: string;
    minutes: string;

    @Output() modelChange = new EventEmitter<string>();

    constructor() { }

    ngOnInit() {
    }

    onModelChange() {
        this.modelChange.emit(`${this.hour}:${this.minutes}`);
    }

    @Input('model')
    set value(val: string) {
        val += '';
        const time = val.split(':');
        this.hour = time[0];
        this.minutes = time[1];
    }

}
