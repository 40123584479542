import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { ServicesService } from './services.service';

import { Service } from './service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

    switchboardTypes: string[];
    services: Service[] = null;
    newService: Service = new Service();

    @ViewChild('serviceDetail') serviceModal;

    constructor(
        private servicesService: ServicesService,
        private router: Router
    ) { }

    ngOnInit() {
        this.servicesService.getSwitchboardTypes()
            .subscribe(types => this.switchboardTypes = types);
        this.servicesService.getServices()
            .subscribe(services => this.services = services);
    }

    createService() {
        this.newService = new Service();
        this.serviceModal.open();
    }

    saveService() {
        const service = { ...this.newService };
        service.retail_cost *= 100;
        this.servicesService.createService(service)
        .subscribe(service => {
            if (service.id) {
                this.serviceModal.close();
                this.router.navigate([`/services/detail/${service.id}`]);
            } else {
                this.serviceModal.stopLoading();
            }
        }, () => this.serviceModal.stopLoading());
    }

}
