<div style="display: flex; " *ngIf="show">
    <form [formGroup]="phoneForm" #telephoneForm>
        <ngx-intl-tel-input
            cssClass="form-control telephone-input"
            [preferredCountries]="[CountryISO.UnitedKingdom, CountryISO.UnitedStates]"
            [selectedCountryISO]="CountryISO.UnitedKingdom"
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true"
            [searchCountryFlag]="true"
            [selectFirstCountry]="false"
            [phoneValidation]="true"
            [numberFormat]="PhoneNumberFormat.National"
            name="phone"
            formControlName="phone"
            #telephoneInput
        ></ngx-intl-tel-input>
    </form>
    <button class="btn btn-primary" (click)="search()">Search</button>
</div>

<!-- <ul *ngIf="rates">
    <li *ngFor="let rate of rates?.data">{{ rate.name }}</li>
</ul> -->
