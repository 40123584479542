<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Duplicate Destinations</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">{{ direction|capitalize }} Duplicate Destinations</h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <a routerLink="/duplicate-destinations/inbound" class="btn btn-primary" type="button">
                            Inbound
                        </a>

                        <a routerLink="/duplicate-destinations/outbound" class="btn btn-primary" type="button">
                            Outbound
                        </a>

                        <a routerLink="/duplicate-destinations/ignored" class="btn btn-primary" type="button">
                            Ignored
                        </a>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <ng-container *ngIf="direction === 'ignored'">
                    <ng-container *ngTemplateOutlet="ignored_report"></ng-container>
                </ng-container>
                <ng-container *ngIf="direction !== 'ignored'">
                    <ng-container *ngTemplateOutlet="duplicate_report"></ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #ignored_report>
    <table class="table align-items-center table-flush table-float-header table-striped">
        <thead>
            <th>CLI</th>
            <th>&nbsp;</th>
        </thead>
        <tbody *ngIf="report?.data?.length else no_ignored">
            <tr *ngFor="let cli of report.data">
                <td>{{ cli.e164_number }}</td>
                <td>
                    <button class="btn btn-danger" (click)="deleteIngored(cli)">Remove</button>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td>
                    <input class="form-control" placeholder="e.g. 0123456789" type="text" [(ngModel)]="new_ignore">
                </td>
                <td>
                    <button class="btn btn-success" (click)="addIgnored()">Add</button>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <app-pagination *ngIf="report" [pagination]="report" url="/duplicate-destinations/ignored"></app-pagination>
                </td>
            </tr>
        </tfoot>
    </table>
</ng-template>

<ng-template #duplicate_report>
    <table class="table align-items-center table-flush table-float-header table-striped">
        <thead>
            <tr>
                <th>Destination</th>
                <th>Account Count</th>
                <th>&nbsp;</th>
            </tr>
        </thead>
        <tbody *ngIf="report?.length else no_report">
            <tr *ngFor="let destination of report">
                <ng-container *ngIf="destination.cli || destination.dest as dest">
                    <td>
                        <span class="flag-icon flag-icon-{{ (dest|number_to_country_code).toLowerCase() }}" [title]="dest|number_to_country_prefix"></span>
                        {{ dest|number_to_local }}
                    </td>
                    <td>{{ destination.account_count }}</td>
                    <td>
                        <button class="btn btn-primary" (click)="viewAccounts(dest)">View Accounts</button>
                    </td>
                </ng-container>
            </tr>
        </tbody>
    </table>
</ng-template>

<ng-template #no_ignored>
    <tbody *ngIf="report !== null else loading">
        <tr>
            <td class="text-center" [attr.colspan]="4">
                No Ignored CLIs
            </td>
        </tr>
    </tbody>
</ng-template>

<ng-template #no_report>
    <tbody *ngIf="report !== null else loading">
        <tr>
            <td class="text-center" [attr.colspan]="4">
                No Duplicate Destinations
            </td>
        </tr>
    </tbody>
</ng-template>

<ng-template #loading>
    <app-loadingbar></app-loadingbar>
</ng-template>

<app-modal #accounts_modal>
    <div class="table-responsive">
        <table class="table align-items-center table-flush table-float-header table-striped">
            <thead>
                <tr>
                    <th>CLI</th>
                    <th>Account ID</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody *ngIf="accounts?.length else loading">
                <tr *ngFor="let account of accounts">
                    <ng-container *ngIf="account.cli || account.dest as dest">
                        <td>
                            <span class="flag-icon flag-icon-{{ (dest|number_to_country_code).toLowerCase() }}" [title]="dest|number_to_country_prefix"></span>
                            {{ dest|number_to_local }}
                        </td>
                        <td>
                            <a routerLink="/accounts/detail/{{ account.account_id }}">{{ account.account_id + 1000000 }}</a>
                        </td>
                        <td>{{ account.account.active_state|formatAccountState }}</td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>
</app-modal>
