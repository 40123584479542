import { Component, OnInit } from '@angular/core';

import { SimwoodService } from '../simwood.service';

declare var google: any;

@Component({
    selector: 'app-simwood-map',
    templateUrl: './simwood-map.component.html',
    styleUrls: ['./simwood-map.component.css']
})
export class SimwoodMapComponent implements OnInit {

    private response;

    constructor(
        private simwoodService: SimwoodService
    ) { }

    ngOnInit() {
        this.simwoodService.getInProgressCalls()
        .subscribe(response => {
            this.response = response;
            if (response + '') {
                this.drawChart();
                this.drawTable();
            }
        });
    }

    drawChart() {
        const countries = this.response['countries'];

        // Use Google GeoChart Visualation
        google.load('visualization', '1', {packages: ['geochart']});
        google.setOnLoadCallback(drawChart);

        function drawChart() {
            var data = new google.visualization.DataTable();
            const options = {};
            data.addColumn('string', 'Country', 'ISO');
            data.addColumn('number', 'Cost', 'a');
            data.addColumn('number', 'Count', 'b');


            for (let country in countries) {
                if (countries.hasOwnProperty(country)) {
                    data.addRows([
                        [country, countries[country]['total'], countries[country]['callcount']],
                    ]);
                }
            }

            // Draw the chart
            var chart = new google.visualization.GeoChart(document.getElementById('chart_div'));
            chart.draw(data, options);
        }
    }

    drawTable() {
        const calls = this.response['calls'];

        // Generate a Google table showing breakouts
        google.load('visualization', '1', {packages: ['table']});
        google.setOnLoadCallback(drawTable);

        function drawTable() {
            var data = new google.visualization.DataTable();
            const options = { 'sortColumn': 2, 'sortAscending': false};
            data.addColumn('number', 'ID', 'id');
            data.addColumn('string', 'Breakout', 'breakout');
            data.addColumn('number', 'Cost', 'cost');
            data.addColumn('number', 'Count', 'count');

            for (let call in calls) {
                if (calls.hasOwnProperty(call)) {
                    data.addRows([
                        [call, calls[call]['location'], calls[call]['total'], calls[call]['callcount']],
                    ]);
                }
            }

            var table = new google.visualization.Table(document.getElementById('table_div'));
            table.draw(data, options);
        }
    }

}
