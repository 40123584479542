import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { LoginService } from '../../login/login.service';
import { SwitchboardService } from '../../switchboard/switchboard.service';

import { SwitchboardObject } from '../../switchboard/switchboard_object';
import { RuleHelper } from '../portal-time-management/rule.helper';

declare var alertify: any;

@Component({
    selector: 'app-portal-flow',
    templateUrl: './portal-flow.component.html',
    styleUrls: ['./portal-flow.component.css']
})
export class PortalFlowComponent implements OnInit {

    uuid;
    object_type;
    account_id;

    switchboard_objects;
    flow = [];

    @ViewChild('destination_modal') destinationModal;
    @ViewChild('forwarding_component') forwardingComponent;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: LoginService,
        private switchboardService: SwitchboardService
    ) { }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            this.object_type = (params.get('object') || '').replace(/s$/, '');
            this.uuid = params.get('uuid');

            this.authService.getCurrentAccount()
            .subscribe(account => {
                this.account_id = account.id;
                this.getSwitchboardObjects();
            });
        });
    }

    getSwitchboardObject(uuid) {
        for (let object of this.switchboard_objects) {
            if (uuid === object.uuid) {
                return object;
            }
        }
        return null;
    }

    getSwitchboardObjects() {
        this.switchboard_objects = null;
        this.switchboardService.getSwitchboardObjectsForAccount(this.account_id)
        .subscribe(objects => {
            this.switchboard_objects = objects;
            for (let object of objects) {
                if (object.has_rules) {
                    this.switchboardService.getSwitchboardObjectRules(this.account_id, object.uuid)
                    .subscribe(rules => {
                        object['rules'] = rules;
                        // NOTE: Potentially, the rule state could just
                        //       display the current active rule by using
                        //       `RuleHelper.evaluateRules(rules)`
                        //       however, for now this will be a static
                        //       constant.
                        object['rule_state'] = "Open";
                        this.calculateFlow();
                    });
                }
            }
            this.calculateFlow();
        });
    }

    getStates(rules) {
        return RuleHelper.getStates(rules);
    }

    getRedirectField(object) {
        let field = 'switchboard_destination_uuid';

        if (object.object_type === 'Sip') {
            field = 'voicemail_uuid';
        }

        if (object.object_type === 'Menu' && object['chosen_option']) {
            field = `option_${object['chosen_option']}_uuid`;
        }

        return field;
    }

    getRedirectUUID(object) {
        if (object['rule_state']) {
            return object.rule_state.redirect;
        }

        const field = this.getRedirectField(object);
        return object.object_data[field];
    }

    getNextNode(node) {
        const object = this.getSwitchboardObject(node);
        const redirect_uuid = this.getRedirectUUID(object);
        const objects = this.flow.map(x => x.uuid).filter(x => x);

        if (redirect_uuid === node) {
            this.flow.push({
                loops: 'itself'
            });
            return null;
        } else if (objects.indexOf(redirect_uuid) !== -1) {
            this.flow.push({
                loops: this.getSwitchboardObject(redirect_uuid)
            });
            return null;
        } else {
            return redirect_uuid;
        }
    }

    calculateFlow() {
        let object;
        let node = this.uuid;
        this.flow = [];
        while (node) {
            object = this.getSwitchboardObject(node);

            if (object.has_rules && object.rules && object.rules.length) {
                object['current_rule'] = RuleHelper.evaluateRules(object.rules);
            }

            if (object) {
                this.flow.push(object);
                node = this.getNextNode(node);
            } else {
                node = null;
            }
        }
    }

    showRedirect(switchboard_object) {
        if (switchboard_object.has_rules && switchboard_object.rules.length) {
            alertify.confirm(`This destination is managed by "Time Management" and therefore cannot be changed directly here.

            Would you like to go to Time Management and change the rules and destinations for this service?`, () => {
                this.router.navigate(['time-management', switchboard_object.uuid]);
            });
            return false;
        }

        this.forwardingComponent.root = switchboard_object;
        // TODO: change settings of forwardingComponent depending on the object_type
        this.forwardingComponent.types = [
            // 'queue',
            'group',
            'announcement',
            'menu',
            'voicemail',
            'sip',
        ];
        this.forwardingComponent.field = this.getRedirectField(switchboard_object);

        if (switchboard_object.object_type === 'Sip') {
            this.forwardingComponent.types = ['voicemail'];
        }

        this.destinationModal.open();
    }

    setMenuOption(menu, option) {
        menu['chosen_option'] = option;
        this.calculateFlow();
    }

}
