<app-modal title="Emergency Details" (save)="saveAddress()" #emergency_modal>
    <p>
        UK customers need to register an address against every number used for outbound calling. The details entered are automatically updated with the 999 emergency services and used in the case that you dial 999
    </p>
    <div class="field">
        <select class="form-control" #addressDropdown (change)="setAddress(addressDropdown.value);addressDropdown.value = ''">
            <option value="">Select Saved Address</option>
            <option [value]="{country_region: 'UK'}">Add New Address</option>
            <ng-container *ngFor="let address of addresses; let i = index">
                <option [value]="i">{{ address.first_name }} {{ address.last_name }}, {{ address.address_line_1 }}, {{ address.address_line_2 }}, {{ address.city }}, {{ address.state_province }}, {{ address.country_region }}, {{ address.postal_code }}</option>
            </ng-container>
        </select>
    </div>

    <hr>

    <ng-container *ngIf="address">
        <div class="field">
            <label class="label">Title<span class="requiredstar">*</span></label>
            <div class="control">
                <select class="input form-control" [(ngModel)]="address.title">
                    <option value="Mr">Mr</option>                
                    <option value="Mrs">Mrs</option>                
                    <option value="Miss">Miss</option>                
                    <option value="Ms">Ms</option>                
                </select>
            </div>
        </div>

        <div class="field">
            <label class="label">First Name<span class="requiredstar">*</span></label>
            <div class="control">
                <input class="input form-control" type="text" placeholder="First Name" [(ngModel)]="address.first_name">
            </div>
        </div>
        <div class="field">
            <label class="label">Last Name<span class="requiredstar">*</span></label>
            <div class="control">
                <input class="input form-control" type="text" placeholder="Last Name" [(ngModel)]="address.last_name">
            </div>
        </div>

        <div class="field">
            <label class="label">Company Name<span class="requiredstar">*</span></label>
            <div class="control">
                <input class="input form-control" type="text" placeholder="Company Name" [(ngModel)]="address.company_name">
            </div>
        </div>

        <div class="field">
            <label class="label">Company Suffix<span class="requiredstar">*</span></label>
            <div class="control">
                <input class="input form-control" type="text" placeholder="Company Suffix" [(ngModel)]="address.company_suffix">
            </div>
        </div>

        <hr>

        <div class="field">
            <label class="label">Country<span class="requiredstar">*</span></label>
            <div class="control">
                <!-- <input class="input form-control" type="text" placeholder="Country" list="countryCodes" [(ngModel)]="address.country_region"> -->
                <select id="countryCodes" class="input form-control" [(ngModel)]="address.country_region">
                    <option *ngFor="let code of country_codes" [value]="code.code">{{ code.name }}</option>
                </select>
            </div>
        </div>

        <ng-container *ngIf="address.country_region === 'UK'">
            <div class="field">
                <label class="label">Postal Code<span class="requiredstar">*</span></label>
                <div class="control">
                    <div class="input-group">
                        <input class="form-control" type="text" placeholder="Postal Code" [(ngModel)]="address.postal_code">
                        <span class="input-group-btn">
                            <button class="btn btn-primary" (click)="search()">Search</button>
                        </span>
                    </div>
                </div>
            </div>

            <div class="field" *ngIf="possible_addresses.length">
                <select class="form-control" #addressSuggestionDropdown (change)="setAddress(addressSuggestionDropdown.value, true);addressSuggestionDropdown.value = ''">
                    <option value="">Select Address At This Postcode</option>
                    <ng-container *ngFor="let possible_address of possible_addresses; let i = index">
                        <option [value]="i">{{ possible_address.line_1 }}, {{ possible_address.line_2 }}, {{ possible_address.town_or_city }}, {{ possible_address.county }}, {{ address.postal_code }}</option>
                    </ng-container>
                </select>
            </div>

            <hr>
        </ng-container>

        <div class="field">
            <label class="label">Address Line 1<span class="requiredstar">*</span></label>
            <div class="control">
                <input class="input form-control" type="text" placeholder="Address Line 1" [(ngModel)]="address.address_line_1">
            </div>
        </div>
        <div class="field">
            <label class="label">Address Line 2<span class="requiredstar">*</span></label>
            <div class="control">
                <input class="input form-control" type="text" placeholder="Address Line 2" [(ngModel)]="address.address_line_2">
            </div>
        </div>

        <div class="field">
            <label class="label">City<span class="requiredstar">*</span></label>
            <div class="control">
                <input class="input form-control" type="text" placeholder="City" [(ngModel)]="address.city">
            </div>
        </div>
        <div class="field">
            <label class="label">County/State<span class="requiredstar">*</span></label>
            <div class="control">
                <input class="input form-control" type="text" placeholder="State Province" [(ngModel)]="address.state_province">
            </div>
        </div>
        
        <div class="field">
            <label class="label">Postal Code<span class="requiredstar">*</span></label>
            <div class="control">
                <input class="input form-control" type="text" placeholder="Postal Code" [(ngModel)]="address.postal_code">
            </div>
        </div>
    </ng-container>
</app-modal>
