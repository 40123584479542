import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

declare var toastr: any;

@Injectable({
    providedIn: 'root'
})
export class StatusPageService {

    endpoint: string = 'https://status.ip-vn.com/api/v1';

    constructor(private httpClient: HttpClient) {}

    httpHeader = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
    };

    processError(err: any): Observable<never> {
        let message = '';

        if (err.error instanceof ErrorEvent) {
            message = err.error.message;
        } else {
            message = `Error Code: ${err.status}\nMessage: ${err.message}`;
        }

        console.log(message);

        return throwError(message);
    }

    // protected showError(message) {
    //     if (['Invalid token', 'Unauthorised'].indexOf(message) !== -1) {
    //         return false;
    //     }
    //     toastr.error(message);
    // }

    incidents(params): Observable<any> {
        return this.httpClient
            .get<any>(this.endpoint + '/incidents', {params: params})
            .pipe(retry(1), catchError(this.processError));
    }

    components(params): Observable<any> {
        return this.httpClient
            .get<any>(this.endpoint + '/components', {params: params})
            .pipe(retry(1), catchError(this.processError));
    }

    maintenance(params): Observable<any> {
        return this.httpClient
            .get<any>(this.endpoint + '/schedules', {params: params})
            .pipe(retry(1), catchError(this.processError));
    }
}
