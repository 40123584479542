<app-modal title="Keys" (save)="keys_modal.close()" size="big" #keys_modal>
    <div class="table-responsive">
        <table class="table is-bordered is-striped is-hoverable is-fullwidth">
            <thead class="thead">
                <tr class="tr">
                    <th class="th">Name</th>
                    <th class="th">Created</th>
                    <th class="th">Expires</th>
                    <th class="th">&nbsp;</th>
                </tr>
            </thead>
            <tbody class="tbody" *ngIf="keys?.length else no_keys">
                <tr class="tr" *ngFor="let key of keys">
                    <td class="td">{{ key.name }}</td>
                    <td class="td" >{{ (key.created_at)|date:'medium' }}</td>
                    <td class="td" [title]="key.expires_at">{{ key.expires_at|from_now }}</td>
                    <td class="td"><button class="btn btn-danger" (click)="delete(key)">Delete</button></td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <input type="text" class="form-control" placeholder="Name" [(ngModel)]="newKeyName">
                    </td>
                    <td>
                        <input type="text" class="form-control" placeholder="Expire in (hours)" [(ngModel)]="newKeyHours">
                        <!-- <input type="text" class="form-control" placeholder="Refresh in (hours)" [(ngModel)]="newKeyDays"> -->
                    </td>
                    <td>
                        <button class="btn btn-primary" (click)="generate()">Generate</button>
                    </td>
                    <td>
                        <button class="btn btn-primary" (click)="login()">Login</button>
                    </td>
                </tr>
                <tr *ngIf="generatedKey">
                    <td colspan="2">
                        <input type="text" class="form-control" [value]="generatedKey" readonly>
                    </td>
                    <td>
                        <button class="btn btn-success" (click)="addToClipboard()">Copy </button>
                    </td>
                    <td>
                        <button class="btn btn-danger" (click)="generatedKey = null">Clear</button>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <button class="btn btn-success btn-block" (click)="newKeyName = 'QUICK_LOGIN'; newKeyHours = 1; login();">
                            Quick Login
                        </button>
                    </td>
                </tr>
            </tfoot>
        </table>

        

    </div>
</app-modal>

<ng-template #no_keys>
    <tbody class="tbody">
        <tr class="tr">
            <td class="td" colspan="4">No Keys Found</td>
        </tr>
    </tbody>
</ng-template>
