<ng-container *ngIf="kyc">
    <div class="field" *ngIf="kyc.company_name">
        Details last updated {{ kyc.updated_at | date:'medium' }}
    </div>

    <ng-container *ngIf="this.admin && kyc.company_name">
        <table class="table align-items-center table-flush table-float-header table-striped">
            <tbody>
                <tr *ngIf="!kyc.is_verified">
                    <td>
                        <button class="btn btn-success" (click)="verify(kyc)" *ngIf="!kyc.on_hold"><i class="fas fa-check"></i> Accept</button>
                        <button class="btn btn-warning" (click)="hold(kyc)" *ngIf="!kyc.on_hold"><i class="fas fa-check"></i> Require Update</button>
                        <button class="btn btn-danger" (click)="deny(kyc)"><i class="fas fa-times"></i> Deny</button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="field" *ngIf="kyc.is_verified">
            Verified at {{ kyc.verified_at | date:'medium' }}
        </div>
    </ng-container>

    <div class="field">
        <label class="label">Company Name</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="" [(ngModel)]="kyc.company_name">
        </div>
    </div>

    <div class="field">
        <label class="label">Company Registration Number</label>
        <div class="control">
          <input class="input form-control" type="text" placeholder="" [(ngModel)]="kyc.company_number">
        </div>
    </div>

    <div class="field">
        <label class="label">Trading Name</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="" [(ngModel)]="kyc.trading_name">
        </div>
    </div>

    <div class="field">
        <label class="label">Industry</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="" [(ngModel)]="kyc.industry">
        </div>
    </div>

    <hr>

    <ng-container>
        <h3>Registered Office Address</h3>

        <div class="field">
            <label class="label">Country</label>
            <div class="control">
                <select class="input form-control" id="countryCodes" [(ngModel)]="kyc.office_country">
                    <option *ngFor="let code of country_codes" [value]="code.code">{{ code.name }}</option>
                </select>
            </div>
        </div>

        <ng-container *ngIf="kyc.office_country === 'UK'">
            <div class="field">
                <label class="label">Postal Code</label>
                <div class="control">
                    <div class="input-group">
                        <input class="input form-control" type="text" placeholder="" [(ngModel)]="postcode_search">
                        <span class="input-group-btn">
                            <button class="btn btn-primary" (click)="searchPostcode()">Search</button>
                        </span>
                    </div>
                </div>
            </div>

            <div class="field" *ngIf="addresses?.length">
                <select class="form-control" #addressSuggestionDropdown (change)="setAddress(addressSuggestionDropdown.value, true);addressSuggestionDropdown.value = ''">
                    <option value="">Select Address At This Postcode</option>
                    <ng-container *ngFor="let address of addresses; let i = index">
                        <option [value]="i">{{ address.line_1 }}, {{ address.line_2 }}, {{ address.town_or_city }}, {{ address.county }}</option>
                    </ng-container>
                </select>
            </div>

            <hr>
        </ng-container>

        <div class="field">
            <label class="label">Line 1</label>
            <div class="control">
                <input class="input form-control" type="text" placeholder="" [(ngModel)]="kyc.office_address_line_1">
            </div>
        </div>

        <div class="field">
            <label class="label">Line 2</label>
            <div class="control">
                <input class="input form-control" type="text" placeholder="" [(ngModel)]="kyc.office_address_line_2">
            </div>
        </div>

        <div class="field">
            <label class="label">City</label>
            <div class="control">
                <input class="input form-control" type="text" placeholder="" [(ngModel)]="kyc.office_city">
            </div>
        </div>

        <div class="field">
            <label class="label">State / Province</label>
            <div class="control">
                <input class="input form-control" type="text" placeholder="" [(ngModel)]="kyc.office_state_province">
            </div>
        </div>

        <div class="field">
            <label class="label">Postal Code</label>
            <div class="control">
                <input class="input form-control" type="text" placeholder="" [(ngModel)]="kyc.office_postal_code">
            </div>
        </div>

    </ng-container>

    <hr>

    <div class="field">
        <label class="label">Existing Phone Numbers</label>
        <div class="control">
            <textarea class="input form-control" type="text" placeholder="" [(ngModel)]="kyc.existing_phone_numbers"></textarea>
        </div>
    </div>

    <div class="field">
        <label class="label">Existing Websites</label>
        <div class="control">
            <textarea class="input form-control" type="text" placeholder="" [(ngModel)]="kyc.existing_websites"></textarea>
        </div>
    </div>

    <div class="field">
        <label class="label">Intended Use of your number/s</label>
        <div class="control">
            <textarea class="input form-control" type="text" placeholder="" [(ngModel)]="kyc.intended_use"></textarea>
        </div>
    </div>

    <div class="field">
        <label class="label">Tell us more about your business</label>
        <div class="control">
            <textarea class="input form-control" type="text" placeholder="" [(ngModel)]="kyc.company_info"></textarea>
        </div>
    </div>


    <hr>

    <h3>Senior Manager</h3>

    <div class="field">
        <label class="label">Name</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="" [(ngModel)]="kyc.senior_manager_name">
        </div>
    </div>

    <div class="field">
        <label class="label">Email</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="" [(ngModel)]="kyc.senior_manager_email">
        </div>
    </div>

    <div class="field">
        <label class="label">Phone</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="" [(ngModel)]="kyc.senior_manager_phone">
        </div>
    </div>

    <br>

    <div class="field">
        <button class="btn btn-primary is-link" (click)="submitKYC()">Submit</button>
    </div>
</ng-container>
