import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

import { ServiceTypes } from '../switchboard/switchboard_object';

@Pipe({name: 'switchboard_object_icon'})
export class SwitchboardObjectIconPipe implements PipeTransform {

    transform(object: any) {
        let type = object;
        if (object.object_type) {
            type = object.object_type;
        } else if (object.switchboard_type) {
            type = object.switchboard_type;
        }
        return ServiceTypes[type] || '';
    }
}
