<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item" *ngIf="uuid"><a routerLink="/{{ object_type }}s">{{ object_type|capitalize }}s</a></li>
                <li class="breadcrumb-item" *ngIf="uuid"><a routerLink="/{{ object_type }}s/{{ uuid }}">Edit {{ object_type }}</a></li>
                <li class="breadcrumb-item active" aria-current="page">Call Flow</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Call Flow</h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <ng-container *ngIf="uuid else select_number">
                    <ng-container *ngTemplateOutlet="flow_list"></ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #flow_list>
    <ng-container *ngIf="flow.length else loading">
        <ng-container *ngFor="let object of flow; let i = index">
            <ng-container *ngIf="object.uuid else termination_display">
                <ng-container *ngTemplateOutlet="darkcard; context: { object: object }"></ng-container>
                <ng-container *ngTemplateOutlet="rule_view; context: { object: object }"></ng-container>

                <ng-container *ngIf="object.object_type === 'Menu'">
                    <ng-container *ngTemplateOutlet="menu_buttons; context: { object: object }"></ng-container>
                </ng-container>

                <ng-container *ngIf="['Voicemail'].indexOf(object.object_type) === -1">
                    <div style="font-size:1em!important;" class="btn btn-lg btn-block btn-outline-info text-lg sendtobox" (click)="showRedirect(object)">
                        <ng-container [ngSwitch]="object.object_type">
                            <ng-container *ngSwitchCase="'Number'">
                                Sends call to
                            </ng-container>
                            <ng-container *ngSwitchCase="'Announcement'">
                                Media plays then sends call to
                            </ng-container>
                            <ng-container *ngSwitchCase="'Menu'">
                                <ng-container *ngIf="object.chosen_option">
                                    <!-- When option -->...
                                    <ng-container *ngIf="object.chosen_option !== 'hash' && object.chosen_option !== 'star'">
                                        {{ object.chosen_option }}
                                    </ng-container>
                                    <ng-container *ngIf="object.chosen_option === 'star'">
                                        <i class="fas fa-asterisk"></i>
                                    </ng-container>
                                    <ng-container *ngIf="object.chosen_option === 'hash'">
                                        <i class="fas fa-hashtag"></i>
                                    </ng-container>
                                    is selected then send calls to
                                </ng-container>
                                <ng-container *ngIf="!object.chosen_option">
                                    If no input detected after {{ object.object_data.timeout }} seconds then send calls to
                                </ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="'Group'">
                                If no answer in {{ object.object_data.timeout }} seconds then send calls to
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                Redirects to
                            </ng-container>
                        </ng-container>
                        <div style="font-size: 0.7rem;" *ngIf="!object.rules?.length">(click to edit)</div>
                        <div style="font-size: 0.7rem;" *ngIf="object.rules?.length"><i class="far fa-clock"></i> (set by time management)</div>
                        <i class="fas fa-arrow-down" style="display:block;"></i>
                    </div>
                </ng-container>

                <div class="btn btn-lg btn-block text-lg btn-danger" style="cursor: default; " *ngIf="i === flow.length -1 && ['Voicemail'].indexOf(object.object_type) === -1">
                    Nothing (Call Ends)
                </div>
            </ng-container>
            <ng-template #termination_display>
                <div class="btn btn-lg btn-block text-lg btn-danger" style="cursor: default; ">
                    <ng-container *ngIf="object.loops?.uuid">
                        Looping back to:
                        <br>
                        <app-forwarding-to-partial [destination]="object.loops" show-edit="true"></app-forwarding-to-partial>
                    </ng-container>
                    <ng-container *ngIf="object.rules?.uuid">
                        Managed by <a routerLink="/time-management/{{ object.rules.uuid }}">Time Management</a>
                    </ng-container>
                    <ng-container *ngIf="!object.loops?.uuid && !object.rules?.uuid">{{ object.loops|capitalize }}</ng-container>
                </div>
            </ng-template>
        </ng-container>
    </ng-container>
</ng-template>

<app-modal title="Redirect {{ forwarding_component.root?.object_data.name }} to" size="big" #destination_modal>
    <app-portal-component-destination-select (save)="calculateFlow();destination_modal.close()" #forwarding_component></app-portal-component-destination-select>
</app-modal>

<ng-template #darkcard let-object="object">
    <div class="btn btn-lg btn-block text-lg" 
         [class.dropdown-toggle]="object.object_type === 'Number'"
         [class.btn-success]="object.object_type === 'Number'"
         [class.btn-warning]="object.object_type !== 'Number' && ['Voicemail'].indexOf(object.object_type) === -1"
         [class.btn-danger]="['Voicemail'].indexOf(object.object_type) !== -1"
         [attr.data-toggle]="object.object_type === 'Number' ? 'dropdown' : null"
         id="dropdown-{{ object.uuid }}"
         style="cursor: default; ">
        <ng-container *ngIf="object.loops">
            Loops to:
            <br>
        </ng-container>
        <app-forwarding-to-partial [destination]="object" show-edit="true"></app-forwarding-to-partial>

        <ng-container [ngSwitch]="object.object_type">
            <ng-container *ngSwitchCase="'Number'">
            </ng-container>
            <ng-container *ngSwitchCase="'Announcement'">
            </ng-container>
            <ng-container *ngSwitchCase="'Menu'">
            </ng-container>
            <ng-container *ngSwitchCase="'Group'">
            </ng-container>
            <ng-container *ngSwitchCase="'Voicemail'">
            </ng-container>
            <ng-container *ngSwitchDefault>
            </ng-container>
        </ng-container>
    </div>
    <div class="dropdown-menu" aria-labelledby="dropdown-{{ object.uuid }}">
        <ng-container *ngFor="let object of switchboard_objects">
            <a *ngIf="object.object_type === 'Number'" class="dropdown-item" routerLink="/numbers/{{ object.uuid }}/flow">
                <span class="flag-icon flag-icon-{{ (object.object_data.e164_number|number_to_country_code).toLowerCase() }}" [title]="object.object_data.e164_number|number_to_country_prefix"></span>
                {{ object.object_data.e164_number|number_to_local }}
                -
                <span class="text-muted text-sm">{{ object.object_data.name }}</span>
            </a>
        </ng-container>
    </div>
</ng-template>

<ng-template #rule_view let-object="object">
    <ng-container *ngIf="object.has_rules && object.rules?.length">
        <div class="dropdown numbadtop" style="display: block; ">
            <div style="width: 80%; margin: auto; ">
                <span style="font-weight: 800; ">Destination Is Set By Time Management</span>
                <br>
                Click below to see destinations for
                <br>
                <span style="font-weight: 800; color: #0f3852; ">Open</span> and <span style="font-weight: 800; color: #0f3852; ">Closed</span> (DEFAULT)
            </div>

            <div class="btn btn-primary dropdown-toggle" data-toggle="dropdown" id="dropdown-rules-{{ object.uuid }}">
                View flow: {{ object.rule_state?.name || 'Default'}} <span class="badge badge-success" *ngIf="object?.current_rule?.name === object?.rule_state?.name">Active</span>
            </div>
            <div class="dropdown-menu">
                <a class="dropdown-item" (click)="object.rule_state = null;calculateFlow()">Default</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item"
                   *ngFor="let state of getStates(object.rules)"
                   (click)="object.rule_state = state;calculateFlow()">
                   {{ state.name }}
                   <span class="badge badge-success" *ngIf="object.current_rule?.name === state.name">Active</span>
               </a>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #select_number>
    <p>Simply select the telephone number from the drop down list, then set out the flow of the calls using the services (call forwarding groups, IVR Menus, voicemail etc) on your account. If you need more services then just order these via the <a routerLink="/shop">SHOP</a>.</p>
    <div class="btn btn-lg btn-block text-lg btn-success dropdown-toggle" 
         data-toggle="dropdown"
         style="cursor: default; ">
         Select Number
    </div>
    <div class="dropdown-menu">
        <ng-container *ngFor="let object of switchboard_objects">
            <a *ngIf="object.object_type === 'Number'" class="dropdown-item" routerLink="/numbers/{{ object.uuid }}/flow">
                <span class="flag-icon flag-icon-{{ (object.object_data.e164_number|number_to_country_code).toLowerCase() }}" [title]="object.object_data.e164_number|number_to_country_prefix"></span>
                {{ object.object_data.e164_number|number_to_local }}
                -
                <span class="text-muted text-sm">{{ object.object_data.name }}</span>
            </a>
        </ng-container>
    </div>
</ng-template>

<ng-template #menu_buttons let-object="object">
    <div class="numbadtop">When option...
        <div style="font-size: 0.7rem;">(click the option below to see its destination)</div>
    </div>
    <div class="btn-group flex-button-container">
        <div class="btn btn-lg text-lg flex-button" [class.btn-outline-info]="object.chosen_option !== '1'" [class.btn-info]="object.chosen_option === '1'" (click)="setMenuOption(object, '1')">
            1
        </div>
        <div class="btn btn-lg text-lg flex-button" [class.btn-outline-info]="object.chosen_option !== '2'" [class.btn-info]="object.chosen_option === '2'" (click)="setMenuOption(object, '2')">
            2
        </div>
        <div class="btn btn-lg text-lg flex-button" [class.btn-outline-info]="object.chosen_option !== '3'" [class.btn-info]="object.chosen_option === '3'" (click)="setMenuOption(object, '3')">
            3
        </div>
        <div class="btn btn-lg text-lg flex-button" [class.btn-outline-info]="object.chosen_option !== '4'" [class.btn-info]="object.chosen_option === '4'" (click)="setMenuOption(object, '4')">
            4
        </div>
        <div class="btn btn-lg text-lg flex-button" [class.btn-outline-info]="object.chosen_option !== '5'" [class.btn-info]="object.chosen_option === '5'" (click)="setMenuOption(object, '5')">
            5
        </div>
        <div class="btn btn-lg text-lg flex-button" [class.btn-outline-info]="object.chosen_option !== '6'" [class.btn-info]="object.chosen_option === '6'" (click)="setMenuOption(object, '6')">
            6
        </div>
        <div class="btn btn-lg text-lg flex-button" [class.btn-outline-info]="object.chosen_option !== '7'" [class.btn-info]="object.chosen_option === '7'" (click)="setMenuOption(object, '7')">
            7
        </div>
        <div class="btn btn-lg text-lg flex-button" [class.btn-outline-info]="object.chosen_option !== '8'" [class.btn-info]="object.chosen_option === '8'" (click)="setMenuOption(object, '8')">
            8
        </div>
        <div class="btn btn-lg text-lg flex-button" [class.btn-outline-info]="object.chosen_option !== '9'" [class.btn-info]="object.chosen_option === '9'" (click)="setMenuOption(object, '9')">
            9
        </div>
        <div class="btn btn-lg text-lg flex-button" [class.btn-outline-info]="object.chosen_option !== 'star'" [class.btn-info]="object.chosen_option === 'star'" (click)="setMenuOption(object, 'star')">
            <i class="fas fa-asterisk"></i>
        </div>
        <div class="btn btn-lg text-lg flex-button" [class.btn-outline-info]="object.chosen_option !== '0'" [class.btn-info]="object.chosen_option === '0'" (click)="setMenuOption(object, '0')">
            0
        </div>
        <div class="btn btn-lg text-lg flex-button" [class.btn-outline-info]="object.chosen_option !== 'hash'" [class.btn-info]="object.chosen_option === 'hash'" (click)="setMenuOption(object, 'hash')">
            <i class="fas fa-hashtag"></i>
        </div>
        <div class="btn btn-lg text-lg" style="width:100%;margin:0;border-radius:0;" [class.btn-outline-info]="object.chosen_option" [class.btn-info]="!object.chosen_option" (click)="setMenuOption(object, null)">
            If No Option Chosen
        </div>
    </div>
</ng-template>

<ng-template #loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
