<p>
   Below is the current destination, but you can select a new destination service by clicking on one of those listed
</p>
<div style="margin-bottom:0;" class="table table-hoverable table-fullwidth tableactive">
   <div class="tr activeselect" *ngIf="getDestinationObject() as destination else no_destination">
      <h2 style="color:white;">Calls forward to:</h2>
      <div class="flexview">
         <div class="activeinner" style="flex:4;">
            <h5 style="font-weight:700;">{{ destination.object_data.name }}</h5>
            <h6>{{ destination.extension_number }}</h6>
         </div>
         <a style="flex:1;border-radius:0px 5px 5px 0px;line-height:2;" routerLink="/{{ destination.object_type.toLowerCase() }}s/{{ destination.uuid }}" class="btn btn-primary">Edit {{ destination.object_type }}</a>
      </div>
      <div class="activeselect">
         <button class="btn btn-danger" *ngIf="nullable" (click)="selectObject({uuid: null})">Remove</button>
      </div>
   </div>
   <ng-template #no_destination>
      <div class="tr">
         <div colspan="3">No destination (yet!)</div>
      </div>
   </ng-template>
</div>
<div class="accordion" id="accordion-group">
   <div class="custom-btn" data-target="#accordion-destination-select" data-toggle="collapse">Click here to change destination<i style="margin-left:.5rem;" class="fas fa-chevron-down"></i></div>
   <div class="accordion" id="accordion-destination-select" data-parent="#accordion-group" class="collapse">
      <ng-container *ngFor="let type of types">
         <!-- &nbsp; -->
         <div class="btn btn-outline-light btn-link btn-lrg btn-block menuitem" data-toggle="collapse" [attr.data-target]="'#' + type + '-select'" aria-expanded="true" [class.aria-controls]="type + '-select'">
            <i [class]="type.replace('_', ' ')|capitalize|switchboard_object_icon" [title]="type"></i> {{ type|alias|capitalize }}
            <i class="fas fa-chevron-down"></i>
         </div>
         <div id="{{ type }}-select" class="collapse" [class.aria-labelledby]="'heading-' + type" data-parent="#accordion-destination-select">
            <ng-container [ngTemplateOutlet]="object_table" [ngTemplateOutletContext]="{type: type}"></ng-container>
         </div>
      </ng-container>
      <ng-container *ngIf="getDestinationObject()">
         <div class="btn btn-outline-danger btn-link btn-lrg btn-block" (click)="selectObject({uuid: null})">
            Or End Call
         </div>
      </ng-container>
   </div>
</div>
<ng-template #object_table let-type="type">
   <div class="table-responsive">
      <table class="table table-hoverable table-fullwidth table-inner" style="border-collapse:separate;margin:0;">
         <tbody class="tbody">
            <ng-container *ngFor="let object of switchboard_objects">
               <tr class="tr" *ngIf="object.object_type === ((type.replace('_', ' ')|capitalize).replace(' ', '')) && rootDestination() === object.uuid">
                  <th>
                     <h5>{{ object.object_data.name }}</h5>
                     <h6 class="text-muted">{{ object.extension_number }}</h6>
                  </th>
                  <td class="td"><a routerLink="/{{ type.replace('_', '-') }}s/{{ object.uuid }}" class="btn btn-primary">Edit</a></td>
                  <td></td>
               </tr>
            </ng-container>
            <ng-container *ngFor="let object of switchboard_objects">
               <tr class="tr" *ngIf="object.object_type === ((type.replace('_', ' ')|capitalize).replace(' ', '')) && rootDestination() !== object.uuid">
                  <th style="border-left:none">
                     <h5>{{ object.object_data.name }}</h5>
                     <h6 class="text-muted">{{ object.extension_number }}</h6>
                  </th>
                  <!-- <td class="td"><a routerLink="/{{ type }}s/{{ object.uuid }}" class="btn btn-primary">Edit</a></td> -->
                  <td>
                     <div class="btn btn-success" (click)="selectObject(object)">Select</div>
                  </td>
                  <td></td>
               </tr>
            </ng-container>
         </tbody>
         <tfoot>
            <tr class="nostyling">
               <td colspan="3" class="nostyling">
                  <a *ngIf="root" class="addbutton" routerLink="/{{ type.replace('_', '-') }}s/new" [queryParams]="{ 'parent': root.uuid }"><i class="fas fa-plus" style="font-size:1.5em;"></i> Add New {{ type|alias|capitalize }}</a>
               </td>
            </tr>
         </tfoot>
      </table>
   </div>
</ng-template>

