import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { LoginService } from '../../login/login.service';

import { LocationHelper } from '../../location.helper';

declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

    @Input() isHome: boolean;

    app: any;
    mainsite;
    datenow;

    @ViewChild('navbar_component') navbar_component;

    constructor(
        private loginService: LoginService,
    ) { }

    ngOnInit() {
        this.mainsite = LocationHelper.getMainSite();
        this.datenow = new Date();

        this.loginService.getCurrentApp()
            .subscribe(app => this.app = app);

        $(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
    }

    public getBalance() {
        this.navbar_component.getBalance();
    }

}
