import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-portal-help',
  templateUrl: './portal-help.component.html',
  styleUrls: ['./portal-help.component.css']
})
export class PortalHelpComponent implements OnInit {

    supportUrl

    constructor() { }

    ngOnInit(): void {
        this.supportUrl = location.origin.replace(/\/\/\w+/, '//support');
        setTimeout(() => {
            $(function () {
            $('.troubleshooter').find('[data-sections]').hide();
            $('.troubleshooter').find('.troubleshooting-step:first').show();
            $('.troubleshooter a[data-goto]').click(function (e) {
                e.preventDefault();
                e.stopPropagation();
                const $this = $(this);
                const goto = $this.data('goto');
                const troubleshooter = $this.closest('.troubleshooter');
                document.getElementById('troubleshoot').scrollIntoView(true);
                troubleshooter.find('.troubleshooting-step').hide();
                troubleshooter.find(`[data-step=${goto}]`).show();
                $('.troubleshooter').find('[data-sections]').each((index, partial) => {
                   const $partial = $(partial);
                   const startsWith = $partial.data('sections').replace('*', '');
                   if (goto.indexOf(startsWith) !== -1) {
                       $partial.show();
                   } else {
                       $partial.hide();
                   }
                })
            });
        });
        }, 1000);
    } 
}
