<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Direct Debit Exports</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Direct Debit Exports</h2>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="dd_exports && dd_exports?.data?.length else no_exports">
                <ng-container *ngTemplateOutlet="list_exports"></ng-container>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #list_exports>
    <div class="table-responsive">
        <table class="table align-items-center table-flush table-striped">
            <thead>
                <th>Date</th>
                <th>&nbsp;</th>
            </thead>
            <tbody>
                <tr *ngFor="let dd_export of dd_exports.data">
                    <td>{{ dd_export.created_at|date:'medium' }}</td>
                    <td>
                        <button class="btn btn-secondary" (click)="viewExport(dd_export)">View</button>
                        <button class="btn btn-primary" (click)="downloadExport(dd_export)">Download</button>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <!-- pagination -->
                <tr>
                    <td colspan="2">
                        <app-pagination [pagination]="dd_exports" url="/direct-debit-payer-exports"></app-pagination>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</ng-template>

<ng-template #no_exports>
    <div class="card-body">
        <p *ngIf="dd_exports else loading">No exports found</p>
    </div>
</ng-template>

<ng-template #loading>
    <app-loadingbar></app-loadingbar>
</ng-template>

<app-modal title="Direct Debit Exports" size="big" #exportModal>
    <ng-container *ngIf="dd_export else loading">
        <div class="table-responsive">
            <table class="table">
                <tr>
                    <th>Reference</th>
                    <th>Amount</th>
                    <th>Created</th>

                </tr>
                <tr *ngFor="let line of dd_export.lines">
                    <td>
                        {{ line.reference }}<br>
                    </td>
                    <td>
                        &pound;{{ line.amount|format_balance }}<br>
                    </td>
                    <td>
                        {{ line.created_at|date:'medium' }}<br>
                    </td>
                </tr>
            </table>
        </div>
    </ng-container>
</app-modal>