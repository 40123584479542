import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { LoginService } from '../../login/login.service';
import { SwitchboardService } from '../../switchboard/switchboard.service';

import { Rule } from '../../switchboard/rules/rule.model';

declare var toastr: any;
declare var $: any;
declare var alertify: any;

@Component({
    selector: 'app-portal-temporary-close',
    templateUrl: './portal-temporary-close.component.html',
    styleUrls: ['./portal-temporary-close.component.css']
})
export class PortalTemporaryCloseComponent implements OnInit {

    months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

    account_id;
    number;
    object;

    switchboard_objects;
    numbers;
    rules;

    advancedMode = false;

    presets = [
        {
            name: 'New Years Day',
            months_json: JSON.stringify(['jan']),
            dom_range_start: '1',
            dom_range_end: '1',
            time_range_start: '00:00',
            time_range_end: '23:59',
        },
        {
            name: 'Christmas',
            months_json: JSON.stringify(['dec']),
            dom_range_start: '25',
            dom_range_end: '26',
            time_range_start: '00:00',
            time_range_end: '23:59',
        },
    ];

    rule = {
        name: null,
        to_date: null,
        from_date: null,
        start_time: null,
        end_time: null,
        switchboard_destination_uuid: null,
    };

    @ViewChild('rule_modal') ruleModal;
    @ViewChild('datepicker') datepicker;

    constructor(
        private route: ActivatedRoute,
        private authService: LoginService,
        private switchboardService: SwitchboardService
    ) { }

    ngOnInit() {
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account_id = account.id;
            this.getSwitchboardObjects();
            this.getNumbers();
            this.route.paramMap.subscribe(params => {
                this.number = params.get('number');
                this.object = params.get('number');
                this.getRules();
            });
        });
    }

    getSwitchboardObjects() {
        this.switchboardService.getSwitchboardObjectsForAccount(this.account_id)
            .subscribe(objects => {
                this.switchboard_objects = objects.sort((a, b) => b.extension_number - a.extension_number);
            });
    }
    

    getNumbers() {
        this.switchboardService.readNumbersOnAccount(this.account_id)
            .subscribe(numbers => this.numbers = numbers);
    }

    getNumber() {
        if (this.numbers) {
            return this.numbers.filter(n => n.uuid === this.number)[0];
        }
        return null;
    }

    getObject() {
        if (this.switchboard_objects) {
            return this.switchboard_objects.filter(n => n.uuid === this.number)[0];
        }
        return null;
    }

    getRules() {
        this.rules = null;
        if (this.number) {
            this.switchboardService.getSwitchboardObjectTempRules(this.account_id, this.number)
                .subscribe(rules => this.rules = rules);
        }
    }

    getDestination(rule) {
        let object, destination;
        const uuid = rule.switchboard_destination_uuid;
        if (this.switchboard_objects) {
            for (object of this.switchboard_objects) {
                if (uuid === object.uuid) {
                    destination = object;
                }
            }
        }

        if (destination) {
            return `${destination.extension_number} - ${destination.object_data.name}`;
        }

        return '';
    }

    newRule() {
        this.datepicker.fromDate = null;
        this.datepicker.toDate = null;
        this.rule = {
            name: null,
            to_date: null,
            from_date: null,
            start_time: null,
            end_time: null,
            switchboard_destination_uuid: null,
        };
        this.ruleModal.open();
    }

    editRule(rule) {
        this.datepicker.fromDate = this.splitToDate(rule.date_from.split(' ')[0]);
        this.datepicker.toDate = this.splitToDate(rule.date_to.split(' ')[0]);
        this.rule = rule;
        this.rule['start_time'] = this.splitToTime(rule.date_from);
        this.rule['end_time'] = this.splitToTime(rule.date_to);
        this.ruleModal.open();
    }

    deleteRule(rule, edit = false) {
        if(edit) {
            this.switchboardService.deleteSwitchboardObjectTempRule(this.account_id, this.number, rule)
                .subscribe(_ => this.saveRule());
        }
        if (!edit) {
            alertify.confirm(`Are you sure you want to delete ${rule.name}`, () => {
                this.switchboardService.deleteSwitchboardObjectTempRule(this.account_id, this.number, rule)
                    .subscribe(_ => this.getRules());
            });
        }
    }

    // usePreset(preset) {
    //     this.rule = preset as Rule;
    // }

    saveRule() {
        let request;
        const start_date = this.getStartDate();
        const end_date = this.getEndDate();

        if (!start_date) {
            toastr.error('Start Date Required');
            this.ruleModal.stopLoading();
            return false;
        }

        if (!end_date) {
            toastr.error('End Date Required');
            this.ruleModal.stopLoading();
            return false;
        }

        if (!this.rule['start_time']) {
            toastr.error('Start Time Required');
            this.ruleModal.stopLoading();
            return false;
        }

        if (!this.rule['end_time']) {
            toastr.error('End Time Required');
            this.ruleModal.stopLoading();
            return false;
        }

        let rule = this.rule;

        rule['date_from'] = `${start_date} ${rule['start_time']}:00`;
        rule['date_to'] = `${end_date} ${rule['end_time']}:00`;

        if (this.rule['uuid']) {
            request = this.switchboardService.updateSwitchboardObjectTempRule(this.account_id, this.number, rule);
        } else {
            request = this.switchboardService.createSwitchboardObjectTempRule(this.account_id, this.number, rule);
        }

        request.subscribe(response => {
            if (response + '') {
                this.getRules();
                toastr.success('Rule Saved!');
                this.ruleModal.close();
            } else {
                this.ruleModal.stopLoading();
            }
        });
    }

    getStartDate() {
        if (this.datepicker.fromDate) {
            return `${this.datepicker.fromDate.year}-${this.datepicker.fromDate.month}-${this.datepicker.fromDate.day}`;
        }
    }

    getEndDate() {
        if (this.datepicker.toDate) {
            return `${this.datepicker.toDate.year}-${this.datepicker.toDate.month}-${this.datepicker.toDate.day}`;
        }
    }

    splitToDate(date: string) {
        const dateList = date.split('-');
        return {
            year: +dateList[0],
            month: +dateList[1],
            day: +dateList[2],
        };
    }

    // splitToTime(dateTime: string) {
    //     const timePart = dateTime.split(' ')[1]; // Split by space and take the second part
    //     const timeList = timePart.split(':');
    //     return {
    //         hour: +timeList[0],
    //         minute: +timeList[1],
    //         second: +timeList[2],
    //     };
    // }

    splitToTime(dateTime: string) {
        const timePart = dateTime.split(' ')[1]; // Split by space and take the second part
        const timeList = timePart.split(':');
        return `${timeList[0]}:${timeList[1]}`; // Returns the hour and minute
    }
}
