import { Component, OnInit, Input, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';

import { MediaService } from '../../media.service';

import { CountryCodes } from '../../../tools/country-codes';
import { AudioHelper } from '../../../tools/audio';

declare var toastr: any;
declare var alertify: any;

@Component({
  selector: 'app-portal-tts-form',
  templateUrl: './portal-tts-form.component.html',
  styleUrls: ['./portal-tts-form.component.css']
})
export class PortalTtsFormComponent implements OnInit, OnDestroy {

    @Input() account_id;

    @Output() save = new EventEmitter<any>();

    private max_individual_tts = 500;
    tts_limit: any;
    daily_tts_limit: number;

    uploading = false;
    media_preview: any;
    voices: any[];
    voice_languages: string[];
    voice_language: string = navigator.language;
    voice_index = 0;
    @Input() tts_text = '';

    private preview_element_id = 'tts-preview';

    constructor(
        private mediaService: MediaService,
    ) { }

    ngOnInit() {
        this.getVoices();
        this.getTTSLimit();
    }

    ngOnDestroy() {
        this.stopCurrentMedia();
    }

    getVoices() {
        this.mediaService.getTTSVoices()
        .subscribe(voices => {
            this.voices = voices;
            this.voice_languages = Object.keys(this.voices);
        });
    }

    getTTSLimit() {
        if (this.account_id) {
            this.mediaService.getTTSDailyLimit(this.account_id)
            .subscribe(limit => {
                this.daily_tts_limit = limit;
                this.tts_limit = Math.min(this.daily_tts_limit, this.max_individual_tts);
            });
        } else {
            setTimeout(() => this.getTTSLimit(), 1000);
        }
    }

    getLanguageName(value) {
        const splitted = value.split('-');
        const language = CountryCodes.languages[splitted[0]] || splitted[0];
        const country = CountryCodes.countries[splitted[1]] || splitted[1];
        return `${language} (${country})`;
    }

    availableVoices() {
        let gender;
        let voices = [];
        let voices_count = {};
        const genders = ['Unspecified', 'Male', 'Female', 'Neutral'];
        if (this.voice_language) {
            voices = this.voices[this.voice_language];
            for (let i = 0; i < voices.length; i++) {
                gender = voices[i].gender;
                if (!voices_count[gender]) {
                    voices_count[gender] = 0;
                }
                voices_count[gender] += 1;
                voices[i].gender_label = `${genders[gender]} ${voices_count[gender]}`;
            }
        }
        return voices;
    }

    public preview() {
        this.stopCurrentMedia();

        if ((this.tts_limit - this.tts_text.length) < 0) {
            toastr.error('Text is over the TTS limit');
            return false;
        }

        if (this.uploading) {
            toastr.error('Currently uploading');
            return false;
        }

        const voice = this.availableVoices()[this.voice_index];
        this.mediaService.getTTSPreview({
            text: this.tts_text,
            voice: voice.name,
            gender: voice.gender,
            language_code: this.voice_language,
        }).subscribe(data => {
            this.media_preview = true;
            setTimeout(() => AudioHelper.loadFileToAudioElement(this.preview_element_id)(data), 100);
            this.getTTSLimit();
        });
    }

    public create() {
        if ((this.tts_limit - this.tts_text.length) < 0) {
            toastr.error('Text is over the TTS limit');
            return false;
        }

        if (this.uploading) {
            toastr.error('Currently uploading');
            return false;
        }

        this.uploading = true;
        const voice = this.availableVoices()[this.voice_index];
        this.mediaService.createTTSMedia(this.account_id, {
            name: `"${this.tts_text}" ${this.getLanguageName(this.voice_language)} ${voice.gender_label}`.substring(0, 50),
            text: this.tts_text,
            voice: voice.name,
            gender: voice.gender,
            language_code: this.voice_language,
        }).subscribe(tts_file => {
            this.uploading = false;
            this.tts_text = '';
            this.save.emit(tts_file);
        });
    }

    public reset() {
        if (this.uploading) {
            toastr.error('Currently uploading');
            return false;
        }

        alertify.confirm('Are you sure you want to reset the daily TTS limit?', () => {
            this.mediaService.resetDailyTTSLimit(this.account_id)
            .subscribe(() => {
                toastr.success('Limit reset!');
                this.getTTSLimit();
            });
        });
    }

    stopCurrentMedia() {
        if (this.media_preview) {
            const player = <HTMLMediaElement> document.getElementById(this.preview_element_id);
            player.pause();
        }
    }

}
