import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { LoginService } from '../../login/login.service';

import { catchError, switchMap, tap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

declare var moment: any;
// declare var Chart: any;
declare var $: any;

function getDateRange(from = null, to = null) {
    let dateArray = [];
    let currentDate = moment().subtract(30, 'days');
    let stopDate = moment();

    if (from && to) {
        currentDate = moment(`${from.year}-${from.month}-${from.day}`);
        stopDate = moment(`${to.year}-${to.month}-${to.day}`);
    }

    while (currentDate <= stopDate) {
        dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
        currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
}

@Component({
    selector: 'app-event-log',
    templateUrl: './event-log.component.html',
    styleUrls: ['./event-log.component.css']
})
export class EventLogComponent implements OnInit {

    @Input() fullpage = true;
    @Input() account_id = null;

    page = 1;

    event_type = '';
    date_start = null;
    date_end = null;

    event_logs: any;

    allUsersCache = {};

    @ViewChild('datepicker') datepicker;

    constructor(
        private authservice: LoginService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit(): void {
        if (this.fullpage) {
            this.activatedRoute.paramMap.subscribe(params => {
                this.page = +params.get('page') || 1;
                this.account_id = +params.get('account_id');
                this.getEventLog();
            });
        } else {
            this.getEventLog();
        }
    }

    splitToDate(date: string) {
        const dateList = date.split('-');
        return {
            year: +dateList[0],
            month: +dateList[1],
            day: +dateList[2],
        };
    }

    getStartDate() {
        if (this.datepicker && this.datepicker.fromDate) {
            return `${this.datepicker.fromDate.year}-${this.datepicker.fromDate.month}-${this.datepicker.fromDate.day}`;
        }
    }

    getEndDate() {
        if (this.datepicker && this.datepicker.toDate) {
            return `${this.datepicker.toDate.year}-${this.datepicker.toDate.month}-${this.datepicker.toDate.day}`;
        }
    }

    changePage(page) {
        this.page =  page;
        this.getEventLog();
    }

    // getEventLog() {
    //     this.date_start = this.getStartDate();
    //     this.date_end = this.getEndDate();
    //     this.authservice.getAccountEventLog(this.page, this.account_id, this.event_type, this.date_start, this.date_end)
    //         .subscribe(event_logs => {
    //             this.event_logs = event_logs;
    //             console.log('Event logs fetched:', this.event_logs);
        
    //             // Now that we're sure `this.event_logs` is populated, proceed with enriching it
    //             console.log('All Users', this.allUsersCache);

    //             console.log(this.event_logs.data);

    //             console.log('Event logs type:', Array.isArray(this.event_logs.data) ? 'Array' : typeof this.event_logs.data);
    //             console.log('Event logs length:', Array.isArray(this.event_logs.data) ? this.event_logs.data.length : 'N/A');

                
    //             this.event_logs.data.forEach(log => {
    //                 if (log.user_id) {
    //                     console.log(`User ID ${log.user_id} in cache:`, log.user_id in this.allUsersCache);
    //                     console.log(this.allUsersCache[log.user_id].first_name);
    //                     log.userName = this.allUsersCache[log.user_id].first_name; // Adjust based on actual data
    //                 }
    //             });
                
    //         });
    // }

    getEventLog() {
            this.date_start = this.getStartDate();
            this.date_end = this.getEndDate();
            this.getEventLogsWithUserNames(this.account_id, this.page, this.event_type, this.date_start, this.date_end)
    }

    getEventLogsWithUserNames(account, page, event_type, date_start, date_end) {
        this.fetchAllUsersForAccount().pipe(
            switchMap(() => {
                // Once users are fetched and cached, fetch the event logs
                return this.authservice.getAccountEventLog(page, account, event_type, date_start, date_end);
            }),
            tap((response: any) => { // Asserting the response to 'any' to bypass TypeScript's type checking
                // Assuming response has a 'data' property that is an array of logs
                let eventLogs = response.data as any[]; // Further asserting that 'data' is an array
    
                // Sort eventLogs by created_at in descending order
                eventLogs = eventLogs.sort((a, b) => {
                    // Assuming created_at is in ISO 8601 format (e.g., "2023-03-15T10:21:48.000Z")
                    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                });
    
                eventLogs.forEach(log => {
                    if (log.user_id && this.allUsersCache[log.user_id]) {
                        log.userName = this.allUsersCache[log.user_id].first_name; // Enrich event logs with user names
                    }
                });
                this.event_logs = eventLogs; // Update your component's state with enriched and sorted event logs
            })
        ).subscribe({
            error: (error) => console.error('Error in fetching event logs with user names:', error)
        });
    }
    
    
    fetchAllUsersForAccount(): Observable<any> {
        return this.authservice.getUsersForCurrentAccount().pipe(
            tap((users: any[]) => { // Asserting users to be of type any[]
                users.forEach(user => {
                    this.allUsersCache[user.id] = user;
                });
            }),
            catchError(error => {
                console.error('Error fetching users:', error);
                return of([]); // Return an empty observable array in case of error
            })
        );
    }
    
    
    fetchUserNames(userIds: string[]) {
        userIds.forEach(userId => {
            const user = this.allUsersCache[userId];
            if (user) {
                // Now you have the user object, you can get the name or any other property
                const userName = user.name; // Adjust based on the actual property name
                this.updateEventLogsWithUserName(userId, userName);
            } else {
                console.log(`User with ID ${userId} not found in cache.`);
                // Handle cases where the user isn't found, if necessary
            }
        });
    } 

    updateEventLogsWithUserName(userId: string, userName: string) {
        this.event_logs.forEach(log => {
            if (log.user_id === userId) {
                log.userName = userName; 
            }
        });
    }
    
    

}
