import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { RatesService } from '../rates.service';

import { Sheet } from './sheet';
import { SheetBuilderRule } from './sheet-builder-rule.model';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-sheets',
    templateUrl: './sheets.component.html',
    styleUrls: ['./sheets.component.css']
})
export class SheetsComponent implements OnInit {

    sheets: Sheet[] = null;
    sheet: Sheet = new Sheet();

    rates: any;
    rates_page = 1;

    sheet_rules: any[];
    new_rule = new SheetBuilderRule();

    @ViewChild('sheetModal') detailsModal;

    constructor(
        private ratesService: RatesService,
        private router: Router
    ) { }

    ngOnInit() {
        this.loadSheets();
    }

    loadSheets() {
        this.sheets = null;
        this.ratesService.getSheets().subscribe(sheets => this.sheets = sheets);
    }

    createSheet() {
        this.sheet = new Sheet();
        this.detailsModal.title = 'New Sheet';
        this.detailsModal.open();
    }

    editSheet(sheet) {
        this.sheet = sheet;
        this.detailsModal.title = 'Edit Sheet';
        this.detailsModal.open();
    }

    deleteSheet(sheet) {
        alertify.confirm(`Are you sure you want to delete ${sheet.name}?`, () => {
            this.ratesService.deleteSheet(sheet)
            .subscribe(out => {
                toastr.success('Deleting sheet!');
                this.loadSheets();
            });
        });
    }

    saveSheet() {
        if (this.sheet['id']) {
            this.ratesService.updateSheet(this.sheet)
            .subscribe(sheet => {
                if (sheet.id) {
                    toastr.success('Sheet saved!');
                    this.detailsModal.close();
                    this.loadSheets();
                } else {
                    this.detailsModal.stopLoading();
                }
            });
        } else {
            this.ratesService.createSheet(this.sheet)
            .subscribe(sheet => {
                if (sheet.id) {
                    toastr.success('Sheet created!');
                    this.detailsModal.close();
                    this.sheets.push(sheet);
                } else {
                    this.detailsModal.stopLoading();
                }
            });
        }
    }

    getSheetRates(sheet = null, page = null) {
        if (sheet) {
            this.sheet = sheet;
            this.rates_page = 1;
        }
        if (page) {
            this.rates_page = page;
        }
        this.rates = null;
        this.ratesService.getRatesForSheet(this.sheet, this.rates_page)
            .subscribe(rates => this.rates = rates);
    }

    getRules(sheet) {
        this.sheet = sheet;
        this.sheet_rules = null;
        this.ratesService.getSheetRules(this.sheet)
            .subscribe(rules => this.sheet_rules = rules);
    }

    addRule() {
        this.ratesService.createSheetRule(this.sheet, this.new_rule)
        .subscribe(rule => {
            if (rule.id) {
                this.sheet_rules.push(rule);
                toastr.success('Rule added!');
                this.new_rule = new SheetBuilderRule();
            }
        });

    }

    removeRule(rule) {
        alertify.confirm('Are you sure you want to delete this rule?', () => {
            this.ratesService.deleteSheetRule(this.sheet, rule)
            .subscribe(() => {
                toastr.success('Rule deleted!');
                this.sheet_rules.splice(rule, 1);
            });
        });
    }
}
