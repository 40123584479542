<ng-container *ngTemplateOutlet="list_direct_debits"></ng-container>

<ng-template #list_direct_debits>
    <div class="table-responsive">
        <table class="table align-items-center table-flush table-striped">
            <thead>
                <th>Name</th>
                <th>Bank Details</th>
                <th>Active</th>
                <th>Confirmed</th>
                <th>Exported At</th>
                <th>&nbsp;</th>
            </thead>
            <tbody>
                <ng-container *ngIf="direct_debits && direct_debits?.data?.length else no_direct_debits">
                    <tr *ngFor="let direct_debit of direct_debits.data">
                        <td>
                            {{ direct_debit.name_line_1 }} {{ direct_debit.name_line_2 }}
                            <ng-container *ngIf="direct_debit.reference">
                                <br>
                                <b>Reference:</b> {{ direct_debit.reference }}
                            </ng-container>

                            <ng-container *ngIf="direct_debit.alternate_key">
                                <br>
                                <b>Alternate Key:</b> {{ direct_debit.alternate_key }}
                            </ng-container>
                        </td>
                        <td>
                            <b>Sort Code:</b> {{ direct_debit.bank_sort_code }}
                            <br>
                            <b>Account Number:</b> {{ direct_debit.bank_account_number }}
                        </td>
                        <td>
                            <i class="fas big-icon"
                               [class.fa-check-circle]="direct_debit.active"
                               [class.fa-times-circle]="!direct_debit.active"
                               [class.text-success]="direct_debit.active"
                               [class.text-danger]="!direct_debit.active"
                               [title]="direct_debit.active ? 'Active' : 'Inactive'"
                            ></i>
                        </td>
                        <td>
                            <i class="fas big-icon"
                               [class.fa-check-circle]="direct_debit.confirmed"
                               [class.fa-times-circle]="!direct_debit.confirmed"
                               [class.text-success]="direct_debit.confirmed"
                               [class.text-danger]="!direct_debit.confirmed"
                               [title]="direct_debit.active ? 'Confirmed' : 'Not Confirmed'"
                            ></i>
                        </td>
                        <td>{{ direct_debit.exported_at|date:'medium' || 'Not Exported' }}</td>
                        <td>
                            <button class="btn btn-secondary" (click)="editDirectDebit(direct_debit)">Edit</button>
                            <button class="btn btn-danger" *ngIf="direct_debit.active"  (click)="deleteDirectDebit(direct_debit)">Cancel</button>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="6">
                        <app-pagination [pagination]="direct_debit" [(page)]="page" (page_change)="changePage($event)"></app-pagination>
                    </td>
                </tr>
                <tr>
                    <td colspan="6">
                        <button class="btn btn-primary" (click)="newDirectDebit()">New Direct Debit</button>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</ng-template>

<ng-template #no_direct_debits>
    <tr>
        <td colspan="6">
            <ng-container *ngIf="direct_debits else loading">No Direct Debits found</ng-container>
        </td>
    </tr>
</ng-template>

<ng-template #loading>
    <app-loadingbar></app-loadingbar>
</ng-template>

<app-modal title="{{ current_direct_debit.uuid ? 'Edit' : 'New' }} Direct Debit" (save)="saveDirectDebit()" #directDebitModal>

    <div class="field form-group">
        <label class="label form-control-label">Name Line 1</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Name Line 1" [(ngModel)]="current_direct_debit.name_line_1">
        </div>
    </div>

    <div class="field form-group">
        <label class="label form-control-label">Name Line 2</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Name Line 2" [(ngModel)]="current_direct_debit.name_line_2">
        </div>
    </div>

    <div class="field form-group">
        <label class="label form-control-label">Bank Account Number</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Account Number" [(ngModel)]="current_direct_debit.bank_account_number">
        </div>
    </div>

    <div class="field form-group">
        <label class="label form-control-label">Bank Sort Code</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Sort Code" [(ngModel)]="current_direct_debit.bank_sort_code">
        </div>
    </div>

    <div class="field form-group">
        <label class="label form-control-label">Alternate Key</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Alternate Key" [(ngModel)]="current_direct_debit.alternate_key">
        </div>
    </div>

    <div class="field form-group">
        <label class="label form-control-label">
            Active
            <input class="input" type="checkbox" [(ngModel)]="current_direct_debit.active" (change)="current_direct_debit.active = +current_direct_debit.active">
        </label>
    </div>

    <div class="field form-group">
        <label class="label form-control-label">
            Confirmed
            <input class="input" type="checkbox" [(ngModel)]="current_direct_debit.confirmed" (change)="current_direct_debit.confirmed = +current_direct_debit.confirmed">
        </label>
    </div>
</app-modal>