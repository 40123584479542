export const SessionHelper = {
    load(storage_name, default_value = null) {
        const item = sessionStorage.getItem(storage_name);
        const default_json = JSON.stringify(default_value);
        return JSON.parse(item || default_json);
    },
    save(storage_name, data) {
        sessionStorage.setItem(storage_name, JSON.stringify(data));
    },
    remove(storage_name) {
        sessionStorage.removeItem(storage_name);
    },
};
