import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { BillingService } from '../../billing.service';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-direct-debits',
    templateUrl: './direct-debits.component.html',
    styleUrls: ['./direct-debits.component.css']
})
export class DirectDebitsComponent implements OnInit {

    @Input() account_id;

    direct_debits;
    current_direct_debit: any = {};

    page = 1;

    @ViewChild('directDebitModal') directDebitModal;

    constructor(
        private billingService: BillingService,
    ) { }

    ngOnInit(): void {
        this.getDirectDebits();
    }

    getDirectDebits() {
        this.billingService.getDirectDebits(this.account_id, this.page)
            .subscribe(direct_debits => this.direct_debits = direct_debits);
    }

    changePage(page) {
        this.page = page;
        this.getDirectDebits();
    }

    newDirectDebit() {
        this.current_direct_debit = {};
        this.directDebitModal.open();
    }

    editDirectDebit(direct_debit) {
        this.current_direct_debit = direct_debit;
        this.directDebitModal.open();
    }

    saveDirectDebit() {
        let request;
        if (this.current_direct_debit.uuid) {
            request = this.billingService.updateDirectDebit(this.account_id, this.current_direct_debit);
        } else {
            request = this.billingService.createDirectDebit(this.account_id, this.current_direct_debit);
        }

        request.subscribe(direct_debit => {
            if (direct_debit.uuid) {
                toastr.success('Direct Debit Saved!');
                this.page = 1;
                this.getDirectDebits();
                this.directDebitModal.close();
            } else {
                this.directDebitModal.stopLoading();
            }
        });
    }

    deleteDirectDebit(direct_debit) {
        alertify.confirm(`Are you sure you want to cancel this direct debit? (this will set it as inactive)`, () => {
            direct_debit['active'] = 0;
            this.billingService.updateDirectDebit(this.account_id, direct_debit)
            .subscribe(out => {
                toastr.info('Direct debit inactive!');
                this.getDirectDebits();
            });
        });
    }

}
