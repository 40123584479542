import { Directive, ViewContainerRef, TemplateRef, OnInit, Input } from '@angular/core';

import { LoginService } from '../login/login.service';
import { ServicesService } from '../services/services.service';

@Directive({
  selector: '[appViperAccountHasService]'
})
export class AccountHasServiceDirective implements OnInit {

    @Input('appViperAccountHasService') service: string;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private template: TemplateRef<any>,
        private servicesService: ServicesService,
    ) { }

    ngOnInit(): void {
        this.servicesService.account_services.getData()
            .subscribe(account_services => this.accountHasService(account_services));
    }

    accountHasService(account_services) {
        this.viewContainerRef.clear();
        const matchingServices = account_services.filter(x => x.service.switchboard_type === this.service);
        const hasService = !!matchingServices.length;
        if (hasService) {
            this.viewContainerRef.createEmbeddedView(this.template);
        }
    }

}
