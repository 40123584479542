import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { SwitchboardService } from '../switchboard.service';

import { SwitchboardObject } from '../switchboard_object';
import { Rule } from './rule.model';

declare var toastr: any;
declare var alertify: any;
declare var $: any;

@Component({
    selector: 'app-rules',
    templateUrl: './rules.component.html',
    styleUrls: ['./rules.component.css']
})
export class RulesComponent implements OnInit {

    protected days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
    protected months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

    @Input() account_id: number;
    @Input() object: SwitchboardObject = new SwitchboardObject();
    @Input() switchboard_objects: SwitchboardObject[];

    rules: Rule[];
    current_rule: Rule = new Rule();

    @ViewChild('rulesModal') rulesModal;

    constructor(
        private switchboardService: SwitchboardService
    ) { }

    ngOnInit() {
    }

    getDestination(rule) {
        let object, destination;
        const uuid = rule.switchboard_destination_uuid;
        for (object of this.switchboard_objects) {
            if (uuid === object.uuid) {
                destination = object;
            }
        }

        if (destination) {
            return `${destination.extension_number} - ${destination.object_data.name}`;
        }

        return '';
    }

    loadRules() {
        this.rules = null;
        this.switchboardService.getSwitchboardObjectRules(this.account_id, this.object.uuid)
            .subscribe(rules => this.rules = <Rule[]>rules);
    }

    manageRules(account_id, object) {
        this.account_id = account_id;
        this.rulesModal.title = `Rules for ${object.object_data.name}`;
        this.object = object;
        this.loadRules();
        this.rulesModal.open();
    }

    getRequestData() {
        let day, month;
        for (day of this.days) {
            this.current_rule[day] = +this.current_rule[day] || 0;
        }

        for (month of this.months) {
            this.current_rule[month] = +this.current_rule[month] || 0;
        }

        return this.current_rule;
    }

    setupForm(rule) {
        let rrule;
        for (rrule of this.rules) {
            rrule.editing = false;
        }
        rule.editing = true;
        this.current_rule = rule as Rule;
    }

    newRule() {
        this.setupForm(new Rule());
    }

    editRule(rule) {
        this.setupForm(rule);
    }

    saveRule() {
        let request;
        const rule = this.getRequestData();

        if (this.current_rule.uuid) {
            request = this.switchboardService.updateSwitchboardObjectRule(this.account_id, this.object.uuid, rule);
        } else {
            request = this.switchboardService.createSwitchboardObjectRule(this.account_id, this.object.uuid, rule);
        }

        request.subscribe(response => {
            this.current_rule.saving = false;
            if (response + '') {
                this.current_rule.editing = false;
                this.loadRules();
                toastr.success('Rule Saved!');
            }
        });
    }

    deleteRule(rule) {
        alertify.confirm(`Are you sure you want to delete ${rule.name}`, () => {
            this.switchboardService.deleteSwitchboardObjectRule(this.account_id, this.object.uuid, rule)
                .subscribe(_ => this.loadRules());
        });
    }

}
