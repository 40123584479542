<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Voicemail Boxes</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Voicemail Boxes</h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <ng-container *ngTemplateOutlet="list_switchboard_objects"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #list_switchboard_objects>
    <ng-container *appViperHasUserRole="'Account Owner'">
        <a class="btn btn-lg btn-block btn-outline-success text-lg" *ngIf="hasAvailableServices" routerLink="/voicemails/new">
            <i class="fas fa-plus "></i>
            New Voicemail Box
        </a>
    </ng-container>

    <br>
    <h3 *ngIf="voicemails"> You have {{ voicemails.length }} {{ voicemails.length === 1 ? 'voicemail box' : 'voicemail boxes' }}</h3>
    <br>

    <ng-container *ngIf="voicemails?.length else no_switchboard_objects">
        <div class="card" *ngFor="let switchboard_object of voicemails; let i = index">
            <div class="card-body darkbody">
                <div class="row">
                    <div class="col">
                        <h2 class="whitetext">
                            {{ switchboard_object.object_data.name }}
                            -
                            <span class="text-muted text-sm" data-toggle="tooltip" data-placement="bottom" title="Every part of your system has an extension number that you can call directly using our VoIP service, or transfer a call to">Extension Number {{ switchboard_object.extension_number }}</span>
                            &nbsp;
                            <span class="badge badge-pill badge-contrast badge-success" *ngIf="services[switchboard_object.service_instance_id]|has_expired">Expired</span>
                        </h2>
                    </div>
                    <div class="col-2">
                        <a class="btn btn-primary" routerLink="/voicemails/{{ switchboard_object.uuid }}">Edit</a>
                    </div>
                </div>
                <app-list-subservices-partial [service]="services[switchboard_object.service_instance_id]" [switchboard_object]="switchboard_object"></app-list-subservices-partial>
                <div class="row">
                    <div class="col">
                        {{ switchboard_object.object_data.subscribers.length }} subscribers
                    </div>
                </div>
                <div class="row">
                    <div class="col" *ngIf="switchboard_objects && switchboard_object.object_data.sip as destination else email_forward">
                        Forwarding to: 
                        <app-forwarding-to-partial [destination]="destination" show-edit="true"></app-forwarding-to-partial>
                    </div>
                    <ng-template #email_forward>
                        <div class="col">
                            Forwarding to: {{ switchboard_object.object_data.email }}
                        </div>
                    </ng-template>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col text-right text-muted text-sm">
                        <strong>Last Edited: </strong> <span class="datetime">{{ (switchboard_object.updated_at)|date:'medium' }}</span>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #no_switchboard_objects>
    <div class="card-body">
        <p *ngIf="voicemails?.length === 0 else loading">No Voicemails Found</p>
    </div>
</ng-template>

<ng-template #loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
