<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Accounts</h2>
                    </div>
                </div>
            </div>
            <div class="table-responsive table-striped">
                <table class="table align-items-center table-flush">
                    <tbody *ngIf="accounts && accounts.path else no_accounts">
                        <tr>
                            <td>
                                <input type="text" aria-label="Company Name" class="form-control" placeholder="Company" [(ngModel)]="search_company" (keyup.enter)="searchAccounts()" autocomplete="off">
                                <br>
                                <input type="text" aria-label="Company ID" class="form-control" placeholder="ID" [(ngModel)]="search_id" (keyup.enter)="searchAccounts()" autocomplete="off">
                            </td>
                            <td>
                                <input type="text" aria-label="User Email" class="form-control" placeholder="Email" [(ngModel)]="search_email" (keyup.enter)="searchAccounts()" autocomplete="off">
                                <br>
                                <select aria-label="Active State" [(ngModel)]="search_state" class="form-control">
                                    <option value="">All</option>
                                    <ng-container *ngFor="let state of active_states;let i = index">
                                        <option *ngIf="i" [value]="i">{{ state }} ({{ account_totals[state] }})</option>
                                    </ng-container>
                                </select>
                            </td>
                            <td>
                                <input type="text" style="display:none" autocomplete="off">
                                <input type="text" aria-label="Contact Number" class="form-control" placeholder="Contact Number" [(ngModel)]="search_contact" (keyup.enter)="searchAccounts()" autocomplete="new-password">
                                <br>
                                <select aria-label="Services" [(ngModel)]="search_service" class="form-control">
                                    <option value="">All</option>
                                    <ng-container *ngFor="let service of services">
                                        <option [value]="service.id">{{ service.name }}</option>
                                    </ng-container>
                                </select>
                            </td>
                            <td>
                                <input type="text" aria-label="Direct Debit Reference" class="form-control" placeholder="Direct Debit Reference" [(ngModel)]="search_ddref" (keyup.enter)="searchAccounts()" autocomplete="new-password" *appViperIsPostpay>
                                <br>
                                <button class="btn btn-primary" (click)="searchAccounts()">Search</button>
                                <button class="btn btn-danger" (click)="search_company = '';search_id = '';search_email = '';search_state = '';search_contact = '';search_ddref = '';searchAccounts()">Clear</button>
                            </td>
                        </tr>
                        <tr *ngFor="let account of accounts.data">
                            <th scope="row">
                                <span class="text-muted">{{ account.id + sid_start }} - </span>
                                <a routerLink="/accounts/detail/{{ account.id }}">{{ account.company_name || '[No Name]' }}</a>
                            </th>
                            <td>
                                {{ (account.created_at)|date:'medium' }}
                            </td>
                            <td>
                                Risk Score: {{ account.risk_score }}
                            </td>
                            <td>
                                <i class="fas fa-lightbulb active-state-{{ account.active_state }}"></i> {{ active_states[account.active_state] }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
                <app-pagination [pagination]="accounts" url="/accounts"></app-pagination>
                <div class="col text-right">
                    <button (click)="createAccount()" class="btn btn-sm btn-primary">New Account</button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-modal (save)="saveAccount()" title="New Account" #accountDetail modalid="accountDetail">
    <div class="field form-group">
        <label class="label form-control-label" for="company-name-input">Company Name</label>
        <div class="control">
            <input id="company-name-input" class="input form-control" type="text" placeholder="Company Name" [(ngModel)]="newAccount.company_name">
        </div>

        <label class="label form-control-label" for="first-name-input">First Name</label>
        <div class="control">
            <input id="first-name-input" class="input form-control" type="text" placeholder="First Name" [(ngModel)]="newAccount.first_name">
        </div>

        <label class="label form-control-label" for="surname-input">Surname</label>
        <div class="control">
            <input id="surname-input" class="input form-control" type="text" placeholder="Surname" [(ngModel)]="newAccount.last_name">
        </div>

        <label class="label form-control-label" for="email-input">Email</label>
        <div class="control">
            <input id="email-input" class="input form-control" type="email" placeholder="email@website.com" [(ngModel)]="newAccount.email">
        </div>
    </div>
    <div class="field form-group">
        <label class="label form-control-label" for="password-input">Password</label>
        <div class="control">
            <input id="password-input" class="input form-control" type="password" [(ngModel)]="newAccount.password">
        </div>

        <label class="label form-control-label" for="password-confirm-input">Password Confirmation</label>
        <div class="control">
            <input id="password-confirm-input" class="input form-control" type="password" [(ngModel)]="newAccount.password_confirmation">
        </div>
    </div>
</app-modal>

<ng-template #no_accounts>
    <tbody class="tbody">
        <tr class="tr">
            <td class="td" colspan="3">
                <span *ngIf="accounts else is_loading">No Accounts Found</span>
            </td>
        </tr>
    </tbody>
</ng-template>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
