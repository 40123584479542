<app-modal title="Roles for {{ user.email }}" (save)="rolesModal.close()" #rolesModal>
    <table *ngIf="roles else is_loading">
        <tr *ngFor="let role of assignableRoles">
            <th>{{ role.name }}</th>
            <td>{{ role.description }}</td>
            <td>
                <button class="btn btn-danger" *ngIf="roleExists(role)" (click)="removeRole(role)" [class.is-loading]="role.disabled" [attr.disabled]="role.disabled">Remove</button>
                <button class="btn btn-success" *ngIf="!roleExists(role)" (click)="addRole(role)" [class.is-loading]="role.disabled" [attr.disabled]="role.disabled">Add</button>
            </td>
        </tr>
    </table>
</app-modal>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
