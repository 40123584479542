import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { LoginService } from '../../../login/login.service';
import { SwitchboardService } from '../../../switchboard/switchboard.service';
import { GetAddressService } from '../../../get-address.service';

import { Address } from '../../../sections/addresses/address.model';

declare var toastr: any;

@Component({
    selector: 'app-portal-number-emergency',
    templateUrl: './portal-number-emergency.component.html',
    styleUrls: ['./portal-number-emergency.component.css']
})
export class PortalNumberEmergencyComponent implements OnInit {

    addresses: Address[];
    country_codes;
    possible_addresses = [];

    address;

    @Input() account_id: number;
    @Input() switchboard_object;

    @Output() save = new EventEmitter<any>();

    @ViewChild('emergency_modal') emergency_modal;

    constructor(
        private accountService: LoginService,
        private switchboardService: SwitchboardService,
        private addressService: GetAddressService,
    ) { }

    ngOnInit() {
        this.loadAddresses();
        this.accountService.getCountryCodes()
            .subscribe(codes => this.country_codes = codes);
    }

    loadAddresses() {
        if (this.account_id) {
            this.accountService.getAddresses(this.account_id)
                .subscribe(addresses => this.addresses = addresses);
        } else {
            setTimeout(() => this.loadAddresses(), 500);
        }
    }

    public saveAddress() {
        const address = this.address;
        this.switchboardService.updateNumberAddress(this.account_id, this.switchboard_object, address)
        .subscribe(response => {
            if (response !== 'FAIL') {
                toastr.success(`Emergency Address Saved!`);
                this.emergency_modal.close();
                this.switchboard_object.object_data.address_record = this.address;
                this.save.emit();
            } else {
                this.emergency_modal.stopLoading();
            }
        });
    }

    setAddress(address, convertAddress = false) {
        let fullAddress;
        if (convertAddress) {
            fullAddress = {
                address_line_1: this.possible_addresses[address].line_1,
                address_line_2: this.possible_addresses[address].line_2,
                city: this.possible_addresses[address].town_or_city,
                state_province: this.possible_addresses[address].county,
            };
        } else {
            fullAddress = this.addresses[address];
        }
        this.address = Object.assign(this.address || {}, fullAddress);
    }

    public open() {
        this.address = this.switchboard_object.object_data.address_record;
        this.emergency_modal.open();
    }

    search() {
        this.addressService.getExpandedAddresses(this.address.postal_code)
            .subscribe(response => this.possible_addresses = response.addresses);
    }

}
