import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { SwitchboardService } from '../../../switchboard/switchboard.service';

import { SwitchboardObject } from '../../../switchboard/switchboard_object';

declare var toastr: any;

@Component({
    selector: 'app-portal-component-timeout-switchboard-object',
    templateUrl: './portal-component-timeout-switchboard-object.component.html',
    styleUrls: ['./portal-component-timeout-switchboard-object.component.css']
})
export class PortalComponentTimeoutSwitchboardObjectComponent implements OnInit {

    @Input() account_id;
    @Input() switchboard_object: SwitchboardObject;

    new_timeout: string;

    @ViewChild('timeout_modal') timeoutModal;

    constructor(
        private switchboardService: SwitchboardService
    ) { }

    ngOnInit() {
    }

    open() {
        this.new_timeout = this.switchboard_object.object_data.timeout;
        this.timeoutModal.open();
    }

    setTimeout() {
        this.switchboardService.updateSwitchboardObjectForAccount(this.account_id, {
            uuid: this.switchboard_object.uuid,
            timeout: this.new_timeout,
        }).subscribe(response => {
            toastr.success(`Saved timeout!`);
            this.switchboard_object.object_data.timeout = this.new_timeout;
            this.timeoutModal.close();
        });
    }
}
