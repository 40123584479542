import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from '../login/authentication.guard';
import { RoleGuard } from '../sections/roles/role.guard';

import { AccountsComponent } from './accounts.component';
import { AccountComponent } from './account/account.component';

const routes: Routes = [
    { path: 'accounts', canActivate: [AuthenticationGuard, RoleGuard], component: AccountsComponent, data: {roles: ['Admin'], title: 'Accounts'} },
    { path: 'accounts/:page', canActivate: [AuthenticationGuard, RoleGuard], component: AccountsComponent, data: {roles: ['Admin'], title: 'Accounts'} },
    { path: 'accounts/detail/:id', canActivate: [AuthenticationGuard, RoleGuard], component: AccountComponent, data: {roles: ['Admin'], title: 'Account'} },
    { path: 'account', canActivate: [AuthenticationGuard, RoleGuard], component: AccountComponent, data: {roles: ['Admin'], title: 'Account'} },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AccountsRoutingModule { }
