<a class="card card-stats mb-4 mb-xl-0" routerLink="/call-stats">
    <div class="card-body">
        <div class="row">
            <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">Today's Calls</h5>
            </div>
            <div class="col-auto">
                <div class="icon icon-shape bg-contrast text-white rounded-circle shadow">
                    <i class="fas fa-chart-line"></i>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p class="h2 font-weight-bold mb-0" *ngIf="outbound_calls else loading">
                    {{ outbound_calls.total }} <span class="text-sm text-muted">Outbound</span>
                </p>
                <p class="h2 font-weight-bold mb-0" *ngIf="inbound_calls">
                    {{ inbound_calls.total }} <span class="text-sm text-muted">Inbound</span>
                </p>
            </div>
        </div>
        <!-- <p class="mt-3 mb-0 text-muted text-sm">
            <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
            <span class="text-nowrap">Since last month</span>
        </p> -->
    </div>
</a>

<ng-template #loading>
    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
</ng-template>