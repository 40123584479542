import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { SwitchboardService } from '../../../switchboard/switchboard.service';

import { SwitchboardObject } from '../../../switchboard/switchboard_object';

declare var toastr: any;

@Component({
    selector: 'app-portal-component-field-edit-switchboard-object',
    templateUrl: './portal-component-field-edit-switchboard-object.component.html',
    styleUrls: ['./portal-component-field-edit-switchboard-object.component.css']
})
export class PortalComponentFieldEditSwitchboardObjectComponent implements OnInit {

    @Input() account_id;
    @Input() switchboard_object: SwitchboardObject;
    @Input() field: string;

    new_value: string;

    @ViewChild('field_modal') fieldModal;

    constructor(
        private switchboardService: SwitchboardService
    ) { }

    ngOnInit() {
    }

    open() {
        this.new_value = this.switchboard_object.object_data[this.field];
        this.fieldModal.open();
    }

    saveObject() {
        let data = {
            uuid: this.switchboard_object.uuid,
        };
        data[this.field] = this.new_value;

        this.switchboardService.updateSwitchboardObjectForAccount(this.account_id, data)
        .subscribe(response => {
            toastr.success(`Saved ${this.field.replace(/_/, ' ')}!`);
            this.switchboard_object.object_data[this.field] = this.new_value;
            this.fieldModal.close();
        });
    }

}
