<div class="table-responsive">
    <table class="table align-items-center table-flush table-striped">
        <thead>
            <th>ID</th>
            <th>Date</th>
            <th>Paid</th>
            <th>&nbsp;</th>
        </thead>
        <tbody>
            <ng-container *ngIf="invoices && invoices.data?.length else no_invoices">
                <tr *ngFor="let invoice of invoices.data">
                    <td>{{ invoice.id }}</td>
                    <td>{{ invoice.created_at|format_local_date }}</td>
                    <td class="text-align">
                        &pound;{{ (invoice.paid_amount * 100)|format_balance }}
                        <ng-container *ngIf="invoice.credit_notes?.length">
                            <br>
                            <br>
                            <strong>Credit Notes:</strong>
                            <ng-container *ngFor="let creditNote of invoice.credit_notes">
                                <br>
                                #{{ creditNote.id }} - Total: &pound;{{ creditNote.final_total|number:'1.2-2' }}
                                <a class="btn btn-secondary btn-sm" [href]="creditNote.download_link" target="_blank">Download</a>
                            </ng-container>
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngIf="+invoice.paid_amount < +invoice.final_total">
                            <a class="btn btn-danger" routerLink="/invoices/pay/{{ invoice.uuid }}">Pay Invoice (&pound;{{ invoiceToPay(invoice)|format_balance }})</a>
                            <br>
                            <br>
                        </ng-container>
                        <button class="btn btn-secondary" *ngIf="admin" (click)="viewPayments(invoice)">View Payments</button>

                        <ng-container *ngIf="invoice.has_cdr">
                            <a class="btn btn-secondary" *ngIf="admin" routerLink="/accounts/detail/{{ account_id }}/invoices/{{ invoice.uuid }}">View CDRs</a>
                            <a class="btn btn-secondary" *ngIf="!admin" routerLink="/invoices/{{ invoice.uuid }}">View CDRs</a>
                            <button class="btn btn-secondary" (click)="downloadCDRs(invoice)">Download CDRs</button>
                        </ng-container>

                        <a [href]="invoice.download_link" target="_blank" class="btn btn-primary">Download Invoice</a>
                        <button *ngIf="admin" class="btn btn-primary" (click)="newCreditNote(invoice)">Add Credit Note</button>
                    </td>
                </tr>
            </ng-container>
        </tbody>
        <tfoot>
            <tr>
                <td colspan="4">
                    <app-pagination [pagination]="invoices" [(page)]="page" (page_change)="getInvoices($event)"></app-pagination>
                </td>
            </tr>
        </tfoot>
    </table>
</div>

<ng-template #no_invoices>
    <tr>
        <td colspan="4">
            <ng-container *ngIf="invoices else loading">No invoices found</ng-container>
        </td>
    </tr>
</ng-template>

<ng-template #loading>
    <app-loadingbar></app-loadingbar>
</ng-template>

<app-modal size="big" title="CDRs" #cdrsModal>
    <div class="accordion" id="extension-accordion">
        <ng-container *ngFor="let extension of extension_cdrs| keyvalue">
            <div class="accordion-item"  *ngIf="extension.value.cdrs?.length">
                <h2 class="accordion-header" id="extension-heading-{{ extension.key }}">
                    <a
                        class="accordion-button collapsed"
                        type="button"
                        data-toggle="collapse"
                        [attr.data-target]="'#extension-collapse-' + extension.key"
                        aria-controls="extension-collapse-{{ extension.key }}"
                        style="cursor: pointer; "
                    >
                        {{ extension.key }} <i class="fas fa-chevron-circle-right"></i>
                    </a>
                </h2>
                <div
                    id="extension-collapse-{{ extension.key }}"
                    class="collapse"
                    aria-labelledby="extension-heading-{{ extension.key }}"
                    data-parent="#extension-accordion"
                >
                    <div class="accordion-body">
                        <div class="table-responsive">
                            <table class="table align-items-center table-flush table-float-header">
                                <thead>
                                    <tr>
                                        <th>Start Time</th>
                                        <th>Destination</th>
                                        <th>cli</th>
                                        <th>Duration</th>
                                        <th>Cost</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="extension.value.cdrs?.length else no_excdrs">
                                    <tr *ngFor="let cdr of extension.value.cdrs">
                                        <td>{{ (cdr.start_time)|date:'medium' }}</td>
                                        <td>{{ cdr.dest }}</td>
                                        <td>{{ cdr.cli }}</td>
                                        <td>{{ cdr.duration }}</td>
                                        <td>&pound;{{ cdr.retail_cost|format_balance }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</app-modal>

<app-modal title="New Credit Note" size="big" (save)="saveCreditNote()" #credit_note_modal>
    <div class="table-responsive" *ngIf="credit_note">
        <table class="table align-items-center table-flush table-striped">
            <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th>Quantity</th>
                    <th>Price (pound)</th>
                    <th>Price + VAT @ {{ this.current_invoice.tax_rate * 100 }}% (pound)</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let line of credit_note.lines">
                    <tr>
                        <td><input class="form-control" type="text" placeholder="Label" [(ngModel)]="line.label"></td>
                        <td><input class="form-control" type="text" placeholder="Quantity" [(ngModel)]="line.quantity"></td>
                        <td>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text" id="btnGroupAddon">&pound;</div>
                                </div>
                                <input class="form-control" type="text" placeholder="Price" [(ngModel)]="line.unit_price">
                            </div>
                        </td>
                        <td>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text" id="btnGroupAddon">&pound;</div>
                                </div>
                                <input class="form-control" type="text" placeholder="Price + VAT" disabled [value]="addCreditNoteTax(line.unit_price)|number:'1.2-2'">
                            </div>
                        </td>
                        <td><button class="btn btn-sm btn-danger" (click)="removeLine(line)">X</button></td>
                    </tr>
                </ng-container>
                <tr>
                    <th>Total</th>
                    <th>&nbsp;</th>
                    <th>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text" id="btnGroupAddon">&pound;</div>
                            </div>
                            <input class="form-control" type="text" disabled [value]="creditNoteTotal()|number:'1.2-2'">
                        </div>
                    </th>
                    <th>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text" id="btnGroupAddon">&pound;</div>
                            </div>
                            <input class="form-control" type="text" disabled [value]="addCreditNoteTax(creditNoteTotal())|number:'1.2-2'">
                        </div>
                    </th>
                    <th>&nbsp;</th>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="5">
                        <button class="btn btn-success" (click)="addLine()">Add Line</button>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</app-modal>

<app-modal title="Invoice Payments" size="big" #paymentsModal>
    <div class="table-responsive">
        <table class="table align-items-center table-flush table-striped">
            <thead>
                <tr>
                    <th>Method</th>
                    <th>Amount</th>
                    <th>Time</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let payment of payments">
                    <tr>
                        <ng-container *ngIf="payment.edit">
                            <td>
                                <input class="form-control" type="text" placeholder="Method (e.g. cheque)" [(ngModel)]="current_payment.method">
                            </td>
                            <td>
                                <input class="form-control" type="text" placeholder="Amount (in £s)" [(ngModel)]="current_payment.amount">
                            </td>
                        </ng-container>
                        <ng-container *ngIf="!payment.edit">
                            <td>
                                {{ payment.method }}
                            </td>
                            <td>
                                {{ payment.amount }}
                            </td>
                            <td>
                                {{ payment.created_at | date:'dd/MM/yyyy HH:mm:ss' }}
                            </td>
                        </ng-container>
                        <td>
                            <ng-container *ngIf="payment.edit">
                                <button class="btn btn-sm btn-primary" (click)="savePayment()" title="Save"><i class="fas fa-save"></i></button>
                                <button class="btn btn-sm btn-danger" (click)="payment.edit = false" title="Cancel"><i class="fa-solid fa-xmark"></i></button>
                            </ng-container>
                            <ng-container *ngIf="!payment.edit">
                                <button class="btn btn-sm btn-secondary" (click)="editPayment(payment)" title="Edit"><i class="fa-solid fa-pen"></i></button>
                                <button class="btn btn-sm btn-danger" (click)="deletePayment(payment)" title="Delete"><i class="fa-solid fa-trash"></i></button>
                            </ng-container>
                        </td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="payments?.length === 0">
                    <tr>
                        <td colspan="3">No payments</td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="payments === null">
                    <tr>
                        <td colspan="3"><app-loadingbar></app-loadingbar></td>
                    </tr>
                </ng-container>
            </tbody>
            <tfoot *ngIf="new_payment">
                <tr>
                    <td>
                        <input class="form-control" type="text" placeholder="Method (e.g. cheque)" [(ngModel)]="new_payment.method">
                    </td>
                    <td>
                        <input class="form-control" type="text" placeholder="Amount (in £s)" [(ngModel)]="new_payment.amount">
                    </td>
                    <td>
                        <button class="btn btn-success" (click)="addPayment()"><i class="fa-solid fa-plus"></i> Add Payment</button>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</app-modal>
