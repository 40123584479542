<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/numbers">Numbers</a></li>
                <li class="breadcrumb-item active" aria-current="page">Edit Number</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">
                            Edit
                            <span *ngIf="switchboard_object">
                                <span class="flag-icon flag-icon-{{ (switchboard_object?.object_data.e164_number|number_to_country_code).toLowerCase() }}" [title]="switchboard_object?.object_data.e164_number|number_to_country_prefix"></span>
                                {{ switchboard_object?.object_data.e164_number|number_to_local }}
                                -
                                <span class="text-muted text-sm">
                                    {{ switchboard_object?.object_data.name }}
                                    <!-- <button class="btn btn-sm btn-outline-secondary renamebutton" (click)="rename_modal.open()">
                                        <i class="fas fa-edit"></i>
                                        <span class="rename">Rename</span>
                                    </button> -->
                                </span>

                                <ng-container *ngIf="switchboard_object.service_instance as service">
                                    <ng-container *ngIf="service.is_bundle && !service.has_bundle">
                                        <span class="badge badge-pill badge-info" *ngIf="service.has_minutes">{{ service|minutes_used }} minutes used</span>
                                        <span class="badge badge-pill badge-warning" *ngIf="!service.has_minutes">Maxed Out</span>
                                    </ng-container>
                                </ng-container>
                            </span>

                        </h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <ng-container *ngTemplateOutlet="switchboard_object_edit"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #switchboard_object_edit>
    <div *ngIf="parent" class="btn btn-lg btn-block btn-outline-info text-lg darkcard" (click)="backToParent()">
        <i class="far fa-hand-point-left"></i> Go Back
        <br>
        <span class="text-muted text-sm">
            <i [class]="parent.object_type|capitalize|switchboard_object_icon" [title]="parent.object_type"></i>
            {{ parent.object_data.name }}
        </span>
    </div>
    
    <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" (click)="destination_modal.open()">
        <i class="fas fa-phone-volume "></i>
        Click here to change where your calls are sent to
        <br>
        <span class="text-muted text-sm" *ngIf="forwarding_component.getDestinationObject() as destination else no_destination">
            Calls are currently forwarded to the following:
            <br>
            <app-forwarding-to-partial [destination]="destination"></app-forwarding-to-partial>
        </span>
        <ng-template #no_destination>
        <span class="text-muted text-sm" *ngIf="switchboard_object?.object_data.switchboard_destination_uuid === null else loading_bar">No Destination Set</span>
        </ng-template>
    </div>

    <a class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" routerLink="/{{ switchboard_object?.object_type.toLowerCase() }}s/{{ switchboard_object?.uuid }}/flow">
        <i class="fas fa-exchange-alt"></i> Call Flow
    </a>

    <a class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" routerLink="/temporary-close/{{ switchboard_object?.uuid }}">
        <i class="far fa-calendar-times"></i> Set Holiday Close
    </a>

    <a class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" routerLink="/time-management/{{ switchboard_object?.uuid }}">
        <i class="far fa-clock"></i> Time Management
    </a>

    <a class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" routerLink="/call-stats/inbound/number/{{ switchboard_object?.object_data.e164_number }}">
        <i class="fas fa-history"></i> View/Download Call History and Stats
    </a>

    <a class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" routerLink="/inbound-bundles/{{ switchboard_object?.uuid }}" *appViperHasInboundBundles>
        <i class="fas fa-star"></i> Add an Inbound Call Bundle

        <ng-container *ngIf="switchboard_object?.has_bundle && switchboard_object.bundle">
            <span class="badge badge-pill badge-info" *ngIf="switchboard_object.bundle.has_minutes">{{ switchboard_object.bundle|minutes_used }} minutes used</span>
            <span class="badge badge-pill badge-warning" *ngIf="!switchboard_object.bundle.has_minutes">Maxed Out</span>
            <span class="badge badge-pill badge-contrast badge-success" *ngIf="switchboard_object.bundle|has_expired">Expired</span>
        </ng-container>

        <ng-container *ngIf="switchboard_object?.has_bundle && !switchboard_object.bundle && switchboard_object.service_instance.is_bundle">
            <span class="badge badge-pill badge-info" *ngIf="switchboard_object.service_instance.has_minutes">{{ switchboard_object.service_instance|minutes_used }} minutes used</span>
            <span class="badge badge-pill badge-warning" *ngIf="!switchboard_object.service_instance.has_minutes">Maxed Out</span>
            <span class="badge badge-pill badge-contrast badge-success" *ngIf="switchboard_object.service_instance|has_expired">Expired</span>
        </ng-container>
    </a>

    <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" (click)="toggleACR()">
        <i class="fas fa-phone-slash"></i> Reject calls from Witheld Numbers
        <br>
        <span class="text-muted text-sm">Currently: {{ switchboard_object?.object_data.acr_enabled ? 'Enabled' : 'Disabled' }}</span>
    </div>

    <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" (click)="emergency_modal.open()">
        <i class="fas fa-first-aid"></i> Add your 999 Address Registration <span class="badge badge-pill badge-info" *ngIf="emergency_modal?.address?.needs_verification">Verification Pending</span>
    </div>

    <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" *appViperAccountHasService="'CallRecording'" (click)="toggleCallRecording()">
        <i class="fas fa-compact-disc"></i> Call Recording
        <br>
        <span class="text-muted text-sm">Currently: {{ switchboard_object?.object_data.callrecording_enabled ? 'Enabled' : 'Disabled' }}</span>
    </div>

    <app-portal-component-delete-switchboard-object [account_id]="account_id" [switchboard_object]="switchboard_object"></app-portal-component-delete-switchboard-object>
</ng-template>

<app-portal-component-rename-switchboard-object [account_id]="account_id" [switchboard_object]="switchboard_object" #rename_modal></app-portal-component-rename-switchboard-object>

<app-modal title="Set Call Forwarding" size="big" #destination_modal>
    <app-portal-component-destination-select (save)="destination_modal.close()" [root]="switchboard_object" #forwarding_component></app-portal-component-destination-select>
</app-modal>

<app-portal-number-emergency 
    [account_id]="account_id"
    [switchboard_object]="switchboard_object"
    #emergency_modal
></app-portal-number-emergency>

<ng-template #loading_bar>
    <app-loadingbar></app-loadingbar>
</ng-template>
