<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item" *ngIf="account_id"><a routerLink="/accounts/detail/{{ account_id }}">Account</a></li>
                <li class="breadcrumb-item active" aria-current="page">Shop</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Shop</h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <ng-container *ngIf="!generating && !finished">
                    <p>This is where you can add the services you need.  Once completed use the <a routerLink="/flow">CALL FLOW MANAGER</a> icon on the dashboard to configure the call flow in the order required.</p>
                    <p>Just add items to the cart and then increase the quantity as needed.</p>
                    
                    <button class="btn btn-primary btn-cart" (click)="jumpToCart()">Go To Cart</button>

                    <ng-container *ngTemplateOutlet="services_list"></ng-container>
                    <hr>
                    <ng-container *ngTemplateOutlet="object_table"></ng-container>
                </ng-container>
                <ng-container *ngIf="generating && !finished">
                    <ng-container *ngTemplateOutlet="generating_switchboard"></ng-container>
                </ng-container>
                <ng-container *ngIf="!generating && finished">
                    <ng-container *ngTemplateOutlet="generation_finished"></ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #services_list>
    <div class="table-responsive">
        <table class="table">
            <tbody>
                <ng-container *ngFor="let service of single_services">
                    <ng-container *ngIf="canAdd(service)">
                        <tr>
                            <td>
                                <h4>
                                    <i [class]="service|switchboard_object_icon" [title]="service.switchboard_type"></i>
                                {{ service_details[service.switchboard_type]?.name || service.name }}
                                </h4>
                                <p>
                                    {{ service_details[service.switchboard_type]?.description }}
                                </p>
                            </td>
                            <td>
                                <p>
                                    <strong>
                                        <ng-container *ngIf="service.multiple">From</ng-container> &pound;{{ service.retail_cost|format_balance }} per month
                                    </strong>
                                    <br />
                                    <ng-container *ngIf="service_details[service.switchboard_type] else coming_soon">
                                        <ng-container *ngIf="global_services.indexOf(service.switchboard_type) === -1">
                                            <button class="btn btn-primary" (click)="openCreateModal(service.switchboard_type)">Buy</button>
                                        </ng-container>
                                        <ng-container *ngIf="global_services.indexOf(service.switchboard_type) !== -1">
                                            <button class="btn btn-primary" (click)="createObject(service)">Buy</button>
                                        </ng-container>
                                    </ng-container>
                                </p>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
                <!-- <tr *ngFor="let service of sub_services">
                    <td><a class="btn btn-primary" routerLink="{{ service.switchboard_type === 'Number' ? '/inbound-bundles' : '/outbound-bundles' }}">Buy</a></td>
                    <td>&pound;{{ service.retail_cost|format_balance }} per month</td>
                    <td>
                        <i [class]="service|switchboard_object_icon" [title]="service.switchboard_type"></i>
                        {{ service.name }}
                    </td>
                    <td>Covers {{ getSheets(service)[0].minutes }} minutes of inbound calls per month {{ service.switchboard_type === 'Number' ? 'forwarded to your destination number' : 'over VoIP' }}</td>
                </tr> -->
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #object_table>
    <h3 id="cart">Shopping Cart</h3>
    <br>
    <div class="table-responsive">
        <table class="table align-items-center table-flush table-float-header table-striped">
            <tbody>
                <tr *ngFor="let object of objects; let i = index">
                    <td>
                        <h4><i [class]="object.service|switchboard_object_icon" [title]="object.service.switchboard_type"></i>
                        {{ object.service.name }}</h4>

                        <br />

                        <ng-container *ngIf="object.service.switchboard_type === 'Sip'">
                            <ng-container *ngFor="let number of numbers">
                                <ng-container *ngIf="number.uuid === object.data.number">
                                    <span class="flag-icon flag-icon-{{ (number.object_data.e164_number|number_to_country_code).toLowerCase() }}"></span> {{ number.object_data.e164_number|number_to_local }} ({{ number.object_data.name }})
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="object.service.switchboard_type === 'Number'">
                            <span class="flag-icon flag-icon-{{ (object.data.e164_number|number_to_country_code).toLowerCase() }}"></span> {{ object.data.e164_number|number_to_local }}
                        </ng-container>
                        <br>
                        <ng-container *ngIf="object.bundle || object.addons?.length">
                            with:
                            <ul>
                                <li *ngIf="object.bundle">{{ object.bundle.name }} (@ &pound;{{ object.bundle.retail_cost|format_balance }})</li>
                                <li *ngFor="let addon of object.addons">{{ addon.name }} (@ &pound;{{ addon.retail_cost|format_balance }})</li>
                            </ul>
                        </ng-container>
                        <ng-container *ngIf="getServiceById(object.service.id)?.sub_services.length">
                            <button class="btn btn-sm btn-primary" (click)="openAddonModal(object)">Bundles and Addons</button>
                        </ng-container>

                        <br/>

                        <span class="text-lg">&pound; {{ objectCost(object)|format_balance }}</span>

                        &nbsp;

                        <ng-container *ngIf="object.unique || object.service.unique_per_account">
                            <button class="btn btn-secondary btn-sm disabled" disabled="disabled">-</button>
                            {{ object.quantity }}
                            <button class="btn btn-secondary btn-sm disabled" disabled="disabled">+</button>
                        </ng-container>
                        <ng-container *ngIf="!object.unique && !object.service.unique_per_account">
                            <button class="btn btn-secondary btn-sm" 
                                    [disabled]="object.quantity === 1"
                                    [class.disabled]="object.quantity === 1"
                                    (click)="object.quantity = object.quantity - 1;saveObjects()">-</button>
                            {{ object.quantity }}
                            <button class="btn btn-secondary btn-sm" (click)="object.quantity = object.quantity + 1;saveObjects()">+</button>
                        </ng-container>

                        <button class="btn btn-danger btn-sm" title="Delete" (click)="delete(i)">
                            <i class="fas fa-trash"></i>
                        </button>
                    </td>
                </tr>
                <tr *ngIf="objects.length === 0">
                    <td colspan="1">No Switchboard Objects</td>
                </tr>
            </tbody>
        </table>
        <div>Total: &pound;{{ getTotal()|format_balance }}</div>
        <ng-container *ngIf="app?.billing_type !== 1">
            <div>Balance: &pound;{{ balance|format_balance }}</div>
            <div>Balance After:
                <span [class.text-danger]="balance - getTotal() < 0">
                    &pound;{{ (balance - getTotal())|format_balance }}
                </span>
            </div>
        </ng-container>
        <button class="btn btn-success" [disabled]="!objects.length" [class.disabled]="!objects.length" (click)="generate()">Checkout</button>
        <br>
        <br>
        <ng-container *ngIf="app?.billing_type !== 1">
            <button class="btn btn-primary" routerLink="/topup">Topup Credit Here</button>
            <br>
            <br>
        </ng-container>
        <button class="btn btn-primary" routerLink="/settings/services">View My Services</button>
    </div>
</ng-template>

<ng-template #generating_switchboard>
    <app-loadingbar></app-loadingbar>
    <p class="text-center">{{ generation_message }}</p>
</ng-template>

<ng-template #generation_finished>
    <h1 class="text-lg text-center">
        <i class="fas fa-check-circle text-success text-lg"></i>
        <br>
        Purchase Complete!
    </h1>

    <div class="table-responsive">
        <table class="table align-items-center table-flush table-float-header table-striped">
            <tbody>
                <tr *ngFor="let object of generated; let i = index">
                    <td>
                        <h3>
                            <i [class]="object.service|switchboard_object_icon" [title]="object.service.switchboard_type"></i>
                            {{ object.service.name }}
                        </h3>
                        <a routerLink="/{{ toSnakeCase(object.service.switchboard_type, '-') }}s/{{ object.object.uuid }}">{{ object.data.name }}</a>
                        <br>
                        <span class="text-lg">&pound; {{ objectCost(object)|format_balance }}</span>
                        <br>
                        <ng-container *ngIf="object.sub_services?.length">
                            with
                            <ul>
                                <li *ngFor="let sub_service of object.sub_services">{{ sub_service.name }} (@ &pound;{{ sub_service.retail_cost|format_balance }})</li>
                            </ul>
                        </ng-container>
                        <br>
                        <ng-container *ngIf="object.service.switchboard_type === 'Sip'">
                            <ng-container *ngFor="let number of numbers">
                                <ng-container *ngIf="number.uuid === object.data.number_uuid">
                                    <span class="flag-icon flag-icon-{{ (number.object_data.e164_number|number_to_country_code).toLowerCase() }}" [title]="number.object_data.e164_number|number_to_country_prefix"></span> {{ number.object_data.e164_number|number_to_local }} ({{ number.object_data.name }})
                                    <br>
                                    <br>
                                    Password: {{ object.data.secret }}
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="object.service.switchboard_type === 'Number'">
                            <span class="flag-icon flag-icon-{{ (object.data.e164_number|number_to_country_code).toLowerCase() }}"></span> {{ object.data.e164_number|number_to_local }}
                        </ng-container>
                        <ng-container *ngIf="object.service.switchboard_type === 'Channel'">
                            Quantity: {{ object.data.quantity }}
                        </ng-container>
                    </td>
                </tr>
                <tr *ngIf="generated.length === 0">
                    <td colspan="1">No Switchboard Objects</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th>Total: &pound;{{ getTotalGenerated()|format_balance }}</th>
                </tr>
                <tr>
                    <th *ngIf="app.billing_type !== 1">Current Balance: &pound;{{ balance|format_balance }}</th>
                </tr>
            </tfoot>
        </table>
    </div>

    <div class="btn btn-lg btn-block btn-link btn-outline-light text-lg" (click)="finished = false; generated = [];getServices()">
        Purchase More
    </div>
    <br>
    <br>
    <div class="btn btn-lg btn-block btn-link btn-outline-light text-lg" routerLink="/settings/services">
        View My Services
    </div>
</ng-template>

<ng-template #number_select>
    <label>Select Area Code</label>
    <div>
        <div class="row">
            <div class="col-lg-4 col-12">
                <ng-select [items]="countryCodes" 
                           bindLabel="name" 
                           bindValue="number" 
                           [(ngModel)]="country_code"
                           (change)="getAreas()"
                           >
                </ng-select>
            </div>
            <span class="invisible-lg hidden-lg d-lg-none">&nbsp;</span>
            <div class="col-lg-8 col-12">
                <ng-select [items]="areas" 
                           bindLabel="label" 
                           bindValue="prefix" 
                           [(ngModel)]="area"
                           (change)="getNumbers()"
                           >
                </ng-select>
            </div>
        </div>
        <br>
        <div class="input-group-append">
            <button class="btn btn-outline-primary" (click)="getNumbers()">Search</button>
        </div>
    </div>

    <br>

    <ng-container *ngIf="purchasable_numbers !== null else loading_bar">
        <app-tabs *ngIf="purchasable_numbers || memorable_numbers">
            <app-tab name="Regular Numbers" [isActive]="true">
                <div class="card-columns customcard">
                    <ng-container *ngFor="let number of purchasable_numbers">
                        <div class="card" *ngIf="number && canAddNumber(number)">
                            <div class="card-body darkcard">
                                <h3 class="card-title">
                                    {{ number.area }} {{ number.number }}
                                    <ng-container *ngIf="getNumberService(number) as service">
                                        <br>
                                        <div class="text-muted text-sm" *ngIf="service.service_id">
                                            Free 
                                            <ng-container *appViperIsNotPostpay>
                                                until {{ service.expires_at|date:'shortDate' }} then
                                            </ng-container>
                                        </div>
                                        <span class="badge badge-pill badge-success" >
                                             £{{ (service.service_id ? service?.service.retail_cost : service.retail_cost)|format_balance }}/month
                                        </span>
                                    </ng-container>
                                </h3>
                                <button class="btn btn-primary" [attr.disabled]="!getNumberService(number) || null" (click)="addNumber(number)">Purchase</button>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="btn btn-lg btn-block btn-outline-warning text-lg" (click)="getNumbers()">More Numbers</div>
            </app-tab>
            <app-tab name="Memorable Numbers">
                <input type="text" class="form-control" style="width: 50%; margin: 10px" placeholder="Search for a specific number" [(ngModel)]="searchTerm" (keyup)="getMemorableNumbers()">
                <div class="card-columns">
                    <ng-container *ngFor="let number of memorable_numbers">
                        <div class="card" *ngIf="number && canAddNumber(number)">
                            <div class="card-body darkcard">
                                <h3 class="card-title">
                                    {{ number.area }} {{ number.number }}
                                    <br>
                                    <ng-container *ngIf="getNumberService(number) as service">
                                        <div class="text-muted text-sm" *ngIf="service.service_id">
                                            Free
                                            <ng-container *appViperIsNotPostpay>
                                                until {{ service.expires_at|date:'shortDate' }} then
                                            </ng-container>
                                        </div>
                                        <span class="badge badge-pill badge-success" >
                                             £{{ (service.service_id ? service?.service.retail_cost : service.retail_cost)|format_balance }}/month
                                        </span>
                                    </ng-container>
                                </h3>
                                <button class="btn btn-primary" [attr.disabled]="!getNumberService(number) || null" (click)="addNumber(number)">Purchase</button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </app-tab>
            <app-tab name="Top 5 Numbers">
                <ng-container *ngIf="top_numbers?.length">
                    <div class="card-columns">
                        <ng-container *ngFor="let number of top_numbers">
                            <div class="card" *ngIf="number && canAddNumber(number)">
                                <div class="card-body darkcard">
                                    <h3 class="card-title">
                                        {{ number.area }} {{ number.number }}
                                        <br>
                                        <ng-container *ngIf="getNumberService(number) as service">
                                            <div class="text-muted text-sm" *ngIf="service.service_id">
                                                Free
                                                <ng-container *appViperIsNotPostpay>
                                                    until {{ service.expires_at|date:'shortDate' }} then
                                                </ng-container>
                                            </div>
                                            <span class="badge badge-pill badge-success" >
                                                 £{{ (service.service_id ? service?.service.retail_cost : service.retail_cost)|format_balance }}/month
                                            </span>
                                        </ng-container>
                                    </h3>
                                    
                                    <button class="btn btn-primary" [attr.disabled]="!getNumberService(number) || null" (click)="addNumber(number)">Purchase</button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </app-tab>
        </app-tabs>
    </ng-container>
</ng-template>

<app-modal title="New Service" size="big" #create_object_modal>
    <p>Select the service and enter the quantity on the next screen</p>

    <ng-container *ngIf="no_name_services.indexOf(switchboard_type) === -1">
        <label>Name</label>
        <input class="form-control" [(ngModel)]="object.name" [placeholder]="object.default_name" maxlength="128">
    </ng-container>

    <ng-container *ngIf="switchboard_type === 'Sip'">
        <label for="object-number">Outbound Caller ID</label>

        <select class="form-control" [(ngModel)]="object.number" id="object-number">
            <ng-container *ngFor="let number of numbers">
                <option *ngIf="number.object_data.address_record" [value]="number.uuid">{{ number.object_data.e164_number|number_to_local }} ({{ number.object_data.name }})</option>
            </ng-container>
        </select>
    </ng-container>

    <ng-container *ngIf="objects_with_media.indexOf(switchboard_type) !== -1">
        <br>
        <p>
            Currently Using: {{ media_component.getMediaFile()?.name || '[No Media]' }}
        </p>
    </ng-container>

    <table class="table align-items-center table-flush" *ngIf="switchboard_type !== 'Number'">
        <tbody *ngIf="services">
            <ng-container *ngFor="let service of services">
                <tr *ngIf="switchboard_type === service.switchboard_type && canAdd(service)">
                    <th scope="row">
                        <h3>{{ service.name }}</h3>
                        <h4>
                            <span class="text-lg">&pound;{{ service.retail_cost|format_balance }}</span>
                            &nbsp;
                            <button class="btn btn-primary" (click)="createObject(service)">Select</button>
                        </h4>
                    </th>
                </tr>
            </ng-container>
        </tbody>
    </table>

    <ng-container *ngIf="switchboard_type === 'Number'">
        <ng-container *ngTemplateOutlet="number_select"></ng-container>
    </ng-container>
</app-modal>

<app-modal title="Add Bundles and Addons" size="big" #addons_modal>
    <ng-container *ngIf="sub_services?.bundles.length">
        <h2>Bundles</h2>
        <table class="table">
            <tr *ngFor="let bundle of sub_services.bundles">
                <td>
                    <h4>{{ bundle.name }}</h4>
                    <p>&pound;{{ bundle.retail_cost|format_balance }} per 30 days</p>
                    <button
                        class="btn btn-success"
                        (click)="setBundle(bundle)"
                        *ngIf="sub_services.object.bundle?.id !== bundle.id"
                    >
                        Select
                    </button>
                    <button class="btn" *ngIf="sub_services.object.bundle?.id === bundle.id" disabled>Selected</button>
                </td>
            </tr>
            <tr>
                <td>
                    <h4>No Bundle</h4>
                    <button
                        class="btn btn-success"
                        (click)="sub_services.object.bundle = null;saveObjects()"
                        *ngIf="sub_services.object.bundle"
                    >
                        Select
                    </button>
                    <button class="btn" *ngIf="!sub_services.object.bundle" disabled>Selected</button>
                </td>
            </tr>
        </table>
    </ng-container>

    <ng-container *ngIf="sub_services?.addons.length">
        <h2>Addons</h2>
        <table class="table">
            <tr *ngFor="let addon of sub_services.addons">
                <td>
                    <h4>{{ addon.name }}</h4>
                    <p>&pound;{{ addon.retail_cost|format_balance }} per 30 days</p>
                    <button
                        class="btn btn-success"
                        (click)="toggleAddon(addon)"
                        [class.btn-success]="!hasAddon(addon)"
                        [class.btn-danger]="hasAddon(addon)"
                    >
                        {{ !hasAddon(addon) ? 'Add' : 'Remove' }}
                    </button>
                </td>
            </tr>
        </table>
    </ng-container>
</app-modal>

<app-portal-component-media-select
    [account_id]="account.id"
    [switchboard_object]="object_media"
    [tts_text]="switchboard_type === 'Announcement' ? 'Welcome to XYZ, please hold while we put you through' : 'Welcome to XYZ. Press 1 for Sales. Press 2 for Service. Press 3 for support. or hold for the next available operator.'"
    [update]="false"
    (save)="create_object_modal.open()"
    #media_component
></app-portal-component-media-select>

<ng-template #coming_soon>
    <button class="btn btn-danger disabled" disabled>Coming Soon...</button>
</ng-template>

<ng-template #loading_bar>
    <app-loadingbar></app-loadingbar>
</ng-template>
