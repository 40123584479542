import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';


import { environment } from '../../../../environments/environment';

import { LoginService } from '../../../login/login.service';
import { SwitchboardService } from '../../../switchboard/switchboard.service';
import { ServicesService } from '../../../services/services.service';

import { CountryCodes } from '../../../tools/country-codes';
import { SwitchboardObject } from '../../../switchboard/switchboard_object';

import { SipsHelper } from '../sips.helper';

import { tap, switchMap, filter, map } from 'rxjs/operators';
import { Observable, from } from 'rxjs';

declare var toastr: any;
declare var alertify: any;
declare var $: any;

@Injectable({
    providedIn: 'root'
})

@Component({
    selector: 'app-portal-sip-edit',
    templateUrl: './portal-sip-edit.component.html',
    styleUrls: ['./portal-sip-edit.component.css']
})
export class PortalSipEditComponent implements OnInit {

    account_id;
    parent;
    sid_start = 1000000;
    host = 'pbx2-staging.s-ip.co.uk';
    zoiper_provider_id = '81c78ddf995fde1f7ac5a90ae56d1735';

    uuid: string;
    switchboard;
    switchboard_object: SwitchboardObject;
    numbers: SwitchboardObject[];
    sips: SwitchboardObject[];
    handsets: any[];
    handset_id: any;

    device_type: string;
    password: string;
    cloud_password: string;
    cloud_device: any = {};

    gsqr_img_url;
    ipvnqr_img_url;

    current_handset: any = {};

    @ViewChild('handset_modal') handsetModal;


    countryCodes = CountryCodes.e164_prefixes;

    new_country_code = '';

    @ViewChild('country_code_modal') countryCodeModal;
    @ViewChild('cli_modal') cliModal;
    @ViewChild('emergency_modal') emergencyModal;
    @ViewChild('details_modal') detailsModal;

    constructor(
        private route: ActivatedRoute,
        private authService: LoginService,
        private switchboardService: SwitchboardService,
        private servicesService: ServicesService,
        private router: Router,
    ) { }

    ngOnInit() {
        this.host = environment.sip_register;
        this.uuid = this.route.snapshot.paramMap.get('uuid');
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account_id = account.id;
            this.getSwitchboardObject();
            this.getNumbers();
            this.getSips();
            this.getParent();
            this.switchboardService.getSwitchboardFromAccount(this.account_id)
            .subscribe(switchboard => {
                this.switchboard = switchboard;
                this.host = switchboard.registration_endpoint
            });
            this.switchboardService.getRegistrationServer(this.account_id)
            .subscribe(pbx => {
                if (pbx.id) {
                    this.host = pbx.registration_host;
                }
            });
            this.getHandsets();
        });
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.uuid = this.route.snapshot.paramMap.get('uuid');
            this.getSwitchboardObject();
        });
            // Retrieve navigation extras state
        this.route.queryParams.subscribe(params => {
            if (params['openModal'] === 'true') {
                // Delay the modal opening until after the current change detection cycle
                setTimeout(() => {
                    this.startDeviceWizard();
                }, 0);
            }
        });
    }



    getParent() {
        const parent_uuid = this.route.snapshot.queryParamMap.get('parent');
        if (parent_uuid) {
            this.switchboardService.getSwitchboardObject(parent_uuid)
                .subscribe(parent => this.parent = parent);
        }
    }

    backToParent() {
        window.history.go(-2);
    }

    getSips() {
        this.switchboardService.readSipsOnAccount(this.account_id)
            .subscribe(sips => this.sips = sips);
    }

    getSwitchboardObject(saved=false) {
        this.switchboardService.getSwitchboardObject(this.uuid)
        .subscribe((object: SwitchboardObject) => {
            if (object) {
                this.switchboard_object = object;
                this.switchboard_object.object_data.number = this.switchboard_object.object_data.number || {};
                this.servicesService.getServicesForAccount(this.account_id)
                .subscribe(services => {
                    for (let service of services) {
                        if (object.service_instance_id === service.id) {
                            object.has_bundle = service.has_bundle;
                            object.minutes_used = service.sheet_instances.reduce((a, b) => a + b.minutes_used, 0);
                            object.bundle = service.sub_service_instances.filter(x => x.is_bundle)[0];
                            object.service_instance = service;
                        }
                    }
                });
                this.route.queryParams.subscribe(params => {
                    if (params['openModal'] === 'true' && saved === false) {
                        setTimeout(() => {
                            this.startDeviceWizard();
                        }, 0);
                    }
                });
            }
        });
    }

    getNumbers() {
        this.switchboardService.readNumbersOnAccount(this.account_id)
            .subscribe(numbers => this.numbers = numbers);
    }

    getHandsets() {
        this.switchboardService.getSipDevices(this.account_id)
            .subscribe(handsets => this.handsets = handsets);
    }

    getCountryFromCode() {
        const number = this.switchboard_object.object_data.local_e164_prefix;
        for (let code of this.countryCodes) {
            if (code.number === number) {
                return code.name;
            }
        }
        return null;
    }

    saveNumber() {
        this.switchboardService.updateSwitchboardObjectForAccount(this.account_id, {
            uuid: this.uuid,
            number_uuid: this.switchboard_object.object_data.number.uuid,
        }).subscribe(response => {
            if (response.uuid) {
                toastr.success(`Caller ID Saved!`);
                this.getSwitchboardObject();
                this.cliModal.close();
            } else {
                this.cliModal.stopLoading();
            }
        });
    }

    regenerateDetails() {
        alertify.confirm(`Regenerate the details for this VoIP user?`, () => {
            this.password = SipsHelper.generatePassword();
            this.switchboardService.updateSwitchboardObjectForAccount(this.account_id, {
                uuid: this.uuid,
                secret: this.password,
            })
            .subscribe(response => {
                toastr.success(`Sip Details Regenerated!`);
                this.switchboard_object.object_data.local_e164_prefix = this.new_country_code;
                this.gsqr_img_url = SipsHelper.generateGSWaveQR(this.switchboard_object, this.account_id, this.host, this.password);
            });
        });
    }

    async generateDetailsCloudSoftphone() {
        const confirmed = await this.confirmDialog(`Regenerate the details for this VoIP user?`);
        if (confirmed) {
            this.password = SipsHelper.generatePassword();
            this.cloud_password = SipsHelper.generatePassword(8);
            this.updateSwitchboardObject().subscribe(() => {
                this.switchboardService.createCloudSoftphone(this.account_id, this.switchboard_object, this.cloud_password)
                    .pipe(
                        switchMap(data => this.updateCloudSoftphoneLine(data))
                    )
                    .subscribe(() => {
                        this.handleSuccess();
                    });
                });
        }
    }


    

    
    confirmDialog(message: string): Promise<boolean> {
        return new Promise(resolve => {
            alertify.confirm(message, (result) => {
                resolve(result);
            });
        });
    }
    
    private updateCloudSoftphoneLine(data: any): Observable<any> {
        return this.switchboardService.updateCloudSoftphoneLine(this.account_id, data, this.uuid);
    }
    
    private updateSwitchboardObject(): Observable<any> {
        return this.switchboardService.updateSwitchboardObjectForAccount(this.account_id, {
            uuid: this.uuid,
            secret: this.password,
        });
    }
    
    private handleSuccess(): void {
        toastr.success(`Cloud Softphone Details Generated!`);
        this.switchboard_object.object_data.local_e164_prefix = this.new_country_code;
        this.ipvnqr_img_url = SipsHelper.generateIPVNQR(this.switchboard_object, this.account_id, this.cloud_password);
        toastr.success(`VoIP User Assigned`);
        this.getHandsets();
        this.getSwitchboardObject(true);
    }
    
    regenerateDetailsCloudSoftphone() {
        alertify.confirm(`Regenerate the details for this VoIP user?`, () => {
            this.password = SipsHelper.generatePassword();
            this.cloud_password = SipsHelper.generatePassword(8);
            this.ipvnqr_img_url = SipsHelper.generateIPVNQR(this.switchboard_object, this.account_id, this.cloud_password);
    
            this.switchboardService.updateCloudSoftphone(this.account_id, this.handset_id, this.cloud_password, this.switchboard_object)
                .pipe(
                    switchMap(() => this.switchboardService.updateSwitchboardObjectForAccount(this.account_id, {
                        uuid: this.uuid,
                        secret: this.password,
                    }))
                )
                .subscribe(() => {
                    toastr.success(`Cloud Softphone Details Regenerated!`);
                    this.switchboard_object.object_data.local_e164_prefix = this.new_country_code;
                });
        });
    }
    
    removeCloudSoftphone() {
        alertify.confirm(`Are you sure you want to remove this handset?`, () => {
            this.switchboardService.deleteCloudSoftphone(this.account_id, this.handset_id)
                .subscribe(() => {
                    toastr.success('Device removed');
                    this.cloud_password = null;
                    this.detailsModal.close();
                    this.getHandsets();
                    this.password = SipsHelper.generatePassword();
                    this.switchboardService.updateSwitchboardObjectForAccount(this.account_id, {
                        uuid: this.uuid,
                        secret: this.password,
                    })
                    .subscribe(() => {
                        toastr.success('User removed');    
                    })
                });
        });
    }

    openCountryCode() {
        this.new_country_code = this.switchboard_object.object_data.local_e164_prefix;
        this.countryCodeModal.open();
    }

    saveCountryCode() {
        this.switchboardService.updateSwitchboardObjectForAccount(this.account_id, {
            uuid: this.uuid,
            local_e164_prefix: this.new_country_code,
        })
        .subscribe(response => {
            toastr.success(`Country code saved!`);
            this.switchboard_object.object_data.local_e164_prefix = this.new_country_code;
            this.countryCodeModal.close();
        });
    }

    toggleInternational() {
        this.switchboardService.toggleSwitchboardObjectValue(
            this.account_id,
            this.switchboard_object,
            'international_enabled',
            {field_name: 'International Calls'}
        );
    }

    toggleWithheld() {
        this.switchboardService.toggleSwitchboardObjectValue(
            this.account_id,
            this.switchboard_object,
            'withheld_cli',
            {
                enable: 'withhold',
                disable: 'show',
                confirm_message: (direction, options) => `Are you sure you want to ${direction} your number?`,
                success_message: (direction, options) => `Number set to now ${direction}!`,
            }
        );
    }

    toggleCallRecording() {
        this.switchboardService.toggleSwitchboardObjectValue(
            this.account_id,
            this.switchboard_object,
            'callrecording_enabled',
            {field_name: 'Call Recording'}
        );
    }

    checkNumber() {
        let number, num;
        for (num of this.numbers) {
            if (num.uuid === this.switchboard_object.object_data.number.uuid) {
                number = num;
            }
        }

        if (number && !number.object_data.address_record) {
            this.switchboard_object.object_data.number.uuid = null;
            this.emergencyModal.switchboard_object = number;
            this.cliModal.close(() => this.emergencyModal.open());
        }
    }

    selectDeviceLine(device_line, nullify = false) {
        let message = 'Are you sure you want to set the VoIP User to this line?';
        if (device_line.switchboard_object_uuid) {
            message += ' This will replace the current VoIP User';
        }

        if (nullify) {
            message = 'Are you sure you want to remove this VoIP User on this line?';
        }

        alertify.confirm(message, () => {
            device_line.switchboard_object_uuid = this.uuid;

            if (nullify) {
                device_line.switchboard_object_uuid = null;
            }

            this.switchboardService.updateSipDeviceLine(this.account_id, device_line)
            .subscribe(line => {
                toastr.success('Handset set up');
                this.getHandsets();
                this.getSwitchboardObject();
            });
        });
    }

    getSip(device_line) {
        for (let sip of this.sips) {
            if (sip.uuid === device_line.switchboard_object_uuid) {
                return sip;
            }
        }
        return null;
    }

    getHandset() {
        if (this.switchboard_object['sip_device_lines'].length) {
            let device_id = this.switchboard_object['sip_device_lines'][0]['sip_device_id'];
            for (let handset of this.handsets) {
                if (handset.id === device_id) {
                    return handset;
                }
            }
        }
        return null;
    }

    getHandsetAll(switchboard_object) {
        if (switchboard_object.length) {
            let device_id = switchboard_object['sip_device_lines'][0]['sip_device_id'];
            for (let handset of this.handsets) {
                if (handset.id === device_id) {
                    return handset;
                }
            }
        }
        return null;
    }

    startDeviceWizard() {
        let device = this.getHandset();
        this.device_type = '';
        this.password = '';
        this.handset_id = null;

        if (device) {
            this.device_type = 'handset';
            this.handset_id = device.id;
        }

        this.detailsModal.open();
    }

    hasLinesSet(handset) {
        return !!handset.sip_device_lines.filter(line => line.switchboard_object_uuid).length;
    }

    startDeviceWizardAll(switchboard_object) {
        let device = this.getHandsetAll(switchboard_object);
        this.device_type = '';
        this.password = '';
        this.handset_id = null;

        if (device) {
            this.device_type = 'handset';
            this.handset_id = device.id;
        }

        this.detailsModal.open();
    }

}
