import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { ServicesService } from '../../../services/services.service';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-portal-component-addons',
    templateUrl: './portal-component-addons.component.html',
    styleUrls: ['./portal-component-addons.component.css']
})
export class PortalComponentAddonsComponent implements OnInit {

    @Input() account_id;
    @Input() switchboard_object;

    current_addons = [];
    available_addons = [];

    @ViewChild('addons_modal') addons_modal;

    constructor(
        private servicesService: ServicesService,
    ) { }

    ngOnInit() {
        this.getAddons();
    }

    getCurrentAddons() {
        this.servicesService.getSubServicesForServiceInstance(this.account_id, this.switchboard_object.service_instance_id)
        .subscribe(sub_services => {
            this.current_addons = [];
            for (let sub_service of sub_services) {
                if (!sub_service.is_bundle) {
                    this.current_addons.push(sub_service);
                }
            }
        });
    }

    getAvailableAddons() {
        this.servicesService.getPossibleSubServicesForInstance(this.account_id, this.switchboard_object.service_instance_id)
        .subscribe(sub_services => {
            this.available_addons = [];
            for (let sub_service of sub_services) {
                if (!sub_service.is_bundle) {
                    this.available_addons.push(sub_service);
                }
            }
        });
    }

    getAddons() {
        this.getCurrentAddons();
        this.getAvailableAddons();
    }

    purchase(addon) {
        alertify.confirm(`Are you sure you want to purchase ${addon.name}?`, () => {
            this.servicesService.addSubServiceToAccount(this.account_id, this.switchboard_object.service_instance_id, addon.id)
                .subscribe(() => this.getAddons());
        });
    }

    delete(addon) {
        alertify.confirm(`Are you sure you want to remove ${addon.sub_service.name}?`, () => {
            this.servicesService.deleteSubServiceOnAccount(this.account_id, addon.service_instance_id, addon.id)
                .subscribe(() => this.getAddons());
        });
    }

    open() {
        this.getAddons();
        this.addons_modal.open();
    }

}
