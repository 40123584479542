import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, map, concatMap } from 'rxjs/operators';

import { LoginService } from '../../login/login.service';

import { RoleHelper } from './role.helper';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate {

    constructor (
        private authService: LoginService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    roleExists(allowed_roles, roles) {
        for (let allowed_role of allowed_roles) {
            if (RoleHelper.hasRoleName(roles, allowed_role)) {
                return true;
            }
        }

        return this.router.createUrlTree(['/home']);
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        const allowed_roles = route.data.roles as Array<string>;

        if (!allowed_roles || allowed_roles.length === 0) {
            return true;
        }

        return this.authService.getRolesForCurrentUser().pipe(map(roles => {
            return this.roleExists(allowed_roles, roles);
        }));
    }
}
