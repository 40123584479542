import { Component, OnInit } from '@angular/core';

import { LoginService } from '../../../login/login.service';
import { ServicesService } from '../../../services/services.service';

declare var moment: any;

@Component({
    selector: 'app-service-notifications',
    templateUrl: './service-notifications.component.html',
    styleUrls: ['./service-notifications.component.css']
})
export class ServiceNotificationsComponent implements OnInit {

    services;

    has_maxed_out: boolean;
    has_expired: boolean;

    constructor(
        private loginService: LoginService,
        private servicesService: ServicesService,
    ) { }

    ngOnInit(): void {
        this.loadServices();
    }

    loadServices() {
        this.servicesService.getCachedAccountServices()
        .subscribe(services => {
            this.services = services;
            for (let service of this.services) {
                if (service.has_bundle && !service.has_minutes) {
                    this.has_maxed_out = true;
                }

                if (service.expires_at && moment(service.expires_at).isBefore()) {
                    this.has_expired = true;
                }
            }
        });
    }

}
