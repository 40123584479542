import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { SwitchboardService } from '../switchboard/switchboard.service';


@Component({
    selector: 'app-dno-blocks',
    templateUrl: './dno-blocks.component.html',
    styleUrls: ['./dno-blocks.component.css']
})
export class DnoBlocksComponent implements OnInit {

    blocks;

    constructor(
        private switchboardService: SwitchboardService,
    ) { }

    ngOnInit(): void {
        this.getDNOBlocks();
    }

    getDNOBlocks() {
        this.switchboardService.getDNOBlocklist()
            .subscribe(blocks => this.blocks = blocks);
    }

}
