import { Component, OnInit, Input } from '@angular/core';

declare var moment: any;

@Component({
    selector: 'app-list-subservices-partial',
    templateUrl: './list-subservices-partial.component.html',
    styleUrls: ['./list-subservices-partial.component.css']
})
export class ListSubservicesPartialComponent implements OnInit {

    @Input() switchboard_object;
    @Input() service;
    @Input() sub_services; // TODO : Deprecated. To be removed.

    constructor() { }

    ngOnInit() {
    }

    getSubServices() {
        // TODO : Regression
        if (this.sub_services) {
            return this.sub_services;
        }

        let sub_services = {
            bundles: [],
            addons: [],
        };
        if (this.service) {
            for (let sub_service of this.service.sub_service_instances) {
                let type = sub_service.sheet_instances.length ? 'bundles' : 'addons';
                sub_services[type].push(sub_service);
            }
        }

        return sub_services;
    }

}
