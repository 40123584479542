import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

import { LoginService } from '../../login/login.service';
import { SwitchboardService } from '../switchboard.service';

import { SwitchboardObject } from '../switchboard_object';

import { CountryCodes } from '../../tools/country-codes';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-members',
    templateUrl: './members.component.html',
    styleUrls: ['./members.component.css']
})
export class MembersComponent implements OnInit {

    @Input() account_id: number;
    @Input() object: SwitchboardObject = new SwitchboardObject();
    @Input() sips: any[];

    @Output() save = new EventEmitter<any>();

    public internal_members: any[];
    public external_members: any[];

    internal_member = {};
    external_member = {};

    isVoip = false;
    internal_disabled = false;
    external_disabled = false;

    public countryCodes = CountryCodes.e164_prefixes;

    @ViewChild('memberModal') memberModal;
    @ViewChild('rate_checker') rateChecker;
    @ViewChild('telephoneForm') telephoneForm;
    @ViewChild('telephoneInput') telephoneInput;

    phoneForm = new UntypedFormGroup({
        phone: new UntypedFormControl(undefined, [Validators.required])
    });

    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;

    constructor(
        private authService: LoginService,
        private switchboardService: SwitchboardService
    ) { }

    ngOnInit() {
        const voipBrands = ['lv'];
        this.authService.getCurrentApp()
            .subscribe(app => this.isVoip = voipBrands.includes(app.short_name));
    }

    notAttached(sip) {
        return this.internal_members.map(m => m.sip.uuid).indexOf(sip.uuid) === -1;
    }

    loadInternalMembers() {
        this.internal_members = null;
        this.switchboardService.getInternalMembers(this.account_id, this.object)
            .subscribe(members => this.internal_members = members);
    }

    loadExternalMembers() {
        this.external_members = null;
        this.switchboardService.getExternalMembers(this.account_id, this.object)
            .subscribe(members => this.external_members = members);
    }

    manageMembers(account_id, object) {
        this.account_id = account_id;
        this.memberModal.title = `Members for ${object.object_data.name}`;
        this.object = object;
        this.loadInternalMembers();
        this.loadExternalMembers();
        this.memberModal.open();
    }

    addInternalMember() {
        if (!this.internal_member['sip_switchboard_object_uuid']) {
            toastr.error(`Sip required`);
            return false;
        }
        this.internal_disabled = true;
        this.switchboardService.createInternalMember(this.account_id, this.object, this.internal_member)
        .subscribe(() => {
            this.loadInternalMembers();
            this.internal_disabled = false;
            this.internal_member = {};
            toastr.success('Internal Member Added!');
            this.save.emit();
        });
    }

    addExternalMember() {

        if (!this.phoneForm.controls.phone && !this.phoneForm.controls.phone.value.e164Number) {
            toastr.error('Number required');
            return false;
        }

        const number = this.phoneForm.controls.phone.value.e164Number;
        this.external_member['e164_number'] = number.replace('+', '');

        this.external_disabled = true;
        this.switchboardService.createExternalMember(this.account_id, this.object, this.external_member)
        .subscribe(() => {
            this.loadExternalMembers();
            this.external_disabled = false;
            this.external_member = {};
            this.phoneForm.reset();
            toastr.success('External Member Added!');
            this.save.emit();
        });
    }

    saveInternalMember(member) {
        this.internal_disabled = true;
        this.switchboardService.updateInternalMember(this.account_id, this.object, member)
        .subscribe(() => {
            this.loadInternalMembers();
            this.internal_disabled = false;
            toastr.success('Internal Member Saved!');
            this.save.emit();
        });
    }

    deleteInternalMember(member) {
        alertify.confirm('Are you sure you want to delete this internal member?', () => {
            this.internal_disabled = true;
            this.switchboardService.deleteInternalMember(this.account_id, this.object, member)
            .subscribe(() => {
                this.loadInternalMembers();
                this.internal_disabled = false;
                toastr.success('Deleted Internal Member!');
                this.save.emit();
            });
        });
    }

    saveExternalMember(member) {
        this.external_disabled = true;
        this.switchboardService.updateExternalMember(this.account_id, this.object, member)
        .subscribe(() => {
            this.loadExternalMembers();
            this.external_disabled = false;
            toastr.success('External Member Saved!');
            this.save.emit();
        });

    }

    deleteExternalMember(member) {
        alertify.confirm('Are you sure you want to delete this external member?', () => {
            this.external_disabled = true;
            this.switchboardService.deleteExternalMember(this.account_id, this.object, member)
            .subscribe(() => {
                this.loadExternalMembers();
                this.external_disabled = false;
                toastr.success('Deleted External Member!');
                this.save.emit();
            });
        });
    }

    rateCheck(member) {
        this.rateChecker.searchNumber(member.e164_number, rates => member.rates = rates);
    }

}
