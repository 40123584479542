export const RoleHelper = {
    hasRoleName(roles, role_name) {
        const role_names = roles.map(role => role.name);
        return role_names.indexOf(role_name) !== -1;
    },
    hasRoleID(roles, role_id) {
        const role_ids = roles.map(role => role.id);
        return role_ids.indexOf(role_id) !== -1;
    }
};
