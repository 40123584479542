<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/sips">VoIP Users</a></li>
                <li class="breadcrumb-item active" aria-current="page">Edit VoIP User</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">
                            <span *ngIf="switchboard_object|isNotNull">
                                {{ switchboard_object?.object_data.name }}
                                -
                                <span class="text-muted text-sm" data-toggle="tooltip" data-placement="bottom" title="Every part of your system has an extension number that you can call directly using our VoIP service, or transfer a call to">
                                    Extension Number {{ switchboard_object?.extension_number }}
                                    <button class="btn btn-sm btn-outline-secondary" (click)="rename_modal.open()">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                </span>

                                <ng-container *ngIf="switchboard_object.service_instance as service">
                                    <ng-container *ngIf="service.is_bundle && !service.has_bundle">
                                        <span class="badge badge-pill badge-info" *ngIf="service.has_minutes">{{ service|minutes_used }} minutes used</span>
                                        <span class="badge badge-pill badge-warning" *ngIf="!service.has_minutes">Maxed Out</span>
                                    </ng-container>
                                </ng-container>
                            </span>

                        </h2>
                    </div>
                </div>
            </div>
            <div class="card-body" *ngIf="switchboard_object else loading">
                <ng-container *ngTemplateOutlet="switchboard_object_edit"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #switchboard_object_edit>
    <div *ngIf="parent" class="btn btn-lg btn-block btn-outline-info text-lg darkcard" (click)="backToParent()">
        <i class="far fa-hand-point-left"></i> Go Back
        <br>
        <span class="text-muted text-sm">
            <i [class]="parent.object_type|capitalize|switchboard_object_icon" [title]="parent.object_type"></i>
            {{ parent.object_data.name }}
        </span>
    </div>

    <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" 
         (click)="startDeviceWizard()"
         *ngIf="switchboard_object?.object_data.number?.uuid">
        <i class="fas fa-info-circle"></i> Set up your Device
    </div>

    <ng-container *ngIf="!switchboard_object?.object_data.number?.uuid">
        <div class="alert alert-warning">
            In order to use VoIP you must have a valid number to use as a caller ID which is set with emergency details. Please set your caller ID to the appropriate caller ID.
        </div>
    </ng-container>

    <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" (click)="cli_modal.open()">
        <i class="fas fa-phone"></i>
        Caller ID
        <br>
        <span class="text-muted text-sm">
            <span class="flag-icon flag-icon-{{ (switchboard_object?.object_data.number.object_data?.e164_number|number_to_country_code)?.toLowerCase() }}" [title]="switchboard_object?.object_data.number.object_data?.e164_number|number_to_country_prefix"></span>
            {{ switchboard_object?.object_data.number.object_data?.e164_number|number_to_local }}
        </span>
    </div>

    <ng-container *ngIf="switchboard_object?.object_data.number?.uuid">
        <a class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" routerLink="/outbound-bundles/{{ switchboard_object.uuid }}" *appViperHasOutboundBundles>
            <i class="fas fa-star"></i> Call Bundle
            <ng-container *ngIf="switchboard_object.has_bundle && switchboard_object.bundle">
                <span class="badge badge-pill badge-info" *ngIf="switchboard_object.bundle.has_minutes">{{ switchboard_object.bundle|minutes_used }} minutes used</span>
                <span class="badge badge-pill badge-warning" *ngIf="!switchboard_object.bundle.has_minutes">Maxed Out</span>
                <span class="badge badge-pill badge-contrast badge-success" *ngIf="switchboard_object.bundle|has_expired">Expired</span>
            </ng-container>

            <ng-container *ngIf="switchboard_object.has_bundle && !switchboard_object.bundle && switchboard_object.service_instance.is_bundle">
                <span class="badge badge-pill badge-info" *ngIf="switchboard_object.service_instance.has_minutes">{{ switchboard_object.service_instance|minutes_used }} minutes used</span>
                <span class="badge badge-pill badge-warning" *ngIf="!switchboard_object.service_instance.has_minutes">Maxed Out</span>
                <span class="badge badge-pill badge-contrast badge-success" *ngIf="switchboard_object.service_instance|has_expired">Expired</span>
            </ng-container>

        </a>

        <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" (click)="voicemail_modal.open()">
            <i class="fas fa-microphone-alt"></i>
            Voicemail
            <br>
            <span class="text-muted text-sm" *ngIf="voicemail_component.getDestinationObject() as voicemail">
                Forwarding to:
                <br>
                <app-forwarding-to-partial [destination]="voicemail"></app-forwarding-to-partial>
            </span>
        </div>

        <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" (click)="toggleInternational()">
            <i class="fas fa-globe"></i> International Calling
            <br>
            <span class="text-muted text-sm">Currently: {{ switchboard_object?.object_data.international_enabled ? 'Enabled' : 'Disabled' }}</span>
        </div>


        <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" (click)="openCountryCode()">
            <i class="fas fa-map-marked-alt"></i> Country Of Origin
            <br>
            <div class="text-muted text-sm">
                {{ switchboard_object?.object_data.local_e164_prefix }} <span *ngIf="getCountryFromCode()">({{ getCountryFromCode() }})</span>
            </div>
        </div>

        <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" (click)="toggleWithheld()">
            <i class="fas fa-ban"></i> Withhold number
            <br>
            <span class="text-muted text-sm">Currently: {{ switchboard_object?.object_data.withheld_cli ? 'Withholding' : 'Showing' }} number</span>
        </div>

        <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" *appViperAccountHasService="'CallRecording'" (click)="toggleCallRecording()">
            <i class="fas fa-compact-disc"></i> Call Recording
            <br>
            <span class="text-muted text-sm">Currently: {{ switchboard_object?.object_data.callrecording_enabled ? 'Enabled' : 'Disabled' }}</span>
        </div>
    </ng-container>

    <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" (click)="music_component.open()" *appViperAccountHasService="'MusicOnHold'">
        <i class="fas fa-music"></i> Music On Hold
        <br>
        <div class="text-muted text-sm">
            <ng-container *ngIf="music_component?.getMediaFile() as music">
                ({{ music.name }})
            </ng-container>
        </div>
    </div>

    <app-portal-component-delete-switchboard-object
        [account_id]="account_id"
        [switchboard_object]="switchboard_object"
    ></app-portal-component-delete-switchboard-object>
</ng-template>

<app-portal-number-emergency 
    [account_id]="account_id"
    [switchboard_object]="switchboard_object"
    (save)="switchboard_object.object_data.number.uuid = emergency_modal.switchboard_object.uuid; cli_modal.open()"
    #emergency_modal
></app-portal-number-emergency>

<app-portal-component-rename-switchboard-object [account_id]="account_id" [switchboard_object]="switchboard_object" #rename_modal></app-portal-component-rename-switchboard-object>

<app-modal title="Set Voicemail" size="big" #voicemail_modal>
    <app-portal-component-destination-select 
        [root]="switchboard_object" 
        [types]="['voicemail']"
        nullable="1"
        field="voicemail_uuid"
        #voicemail_component
    ></app-portal-component-destination-select>
</app-modal>

<app-modal title="Set Caller ID" size="big" (save)="saveNumber()" #cli_modal>
    <select class="form-control" [(ngModel)]="switchboard_object?.object_data.number.uuid" (change)="checkNumber()">
        <option *ngFor="let number of numbers" [value]="number.uuid">{{ number.object_data.e164_number|number_to_local }} - {{ number.object_data.name }}</option>
    </select>
</app-modal>

<app-modal title="Set Country Code" size="small" (save)="saveCountryCode()" #country_code_modal>
    <input type="text" class="form-control" list="countryCodes" [(ngModel)]="new_country_code">
    <datalist id="countryCodes">
        <option *ngFor="let code of countryCodes" [value]="code.number">{{ code.name }}</option>
    </datalist>
</app-modal>

<app-modal title="VoIP/SIP User Details" size="big" #details_modal>
    <ng-container *ngIf="!device_type">
        <p>Choose a device type</p>
        <div class="card-columns">
            <a class="card text-center btn-wiz" (click)="device_type = 'soft'">
                <div class="card-body darkcard cardrounded">
                    <i class="fas fa-mobile-alt"></i>
                    <h5 class="card-title">Mobile/Softphones</h5>
                </div>
            </a>
            <a class="card text-center btn-wiz" (click)="device_type = 'pc'">
                <div class="card-body darkcard cardrounded">
                    <i class="fas fa-laptop"></i>
                    <h5 class="card-title">PC/Laptop</h5>
                </div>
            </a>
            <a class="card text-center btn-wiz" (click)="device_type = 'handset'">
                <div class="card-body darkcard cardrounded">
                    <i class="fas fa-phone"></i>
                    <h5 class="card-title">Handsets</h5>
                </div>
            </a>
            <a class="card text-center btn-wiz" (click)="device_type = 'manual'">
                <div class="card-body darkcard cardrounded">
                    <i class="fas fa-cogs"></i>
                    <h5 class="card-title">Manual Setup</h5>
                </div>
            </a>
        </div>
        <!-- <button class="btn btn-link" (click)="device_type = 'details'">
            Just show the details
        </button> -->
    </ng-container>
    <ng-container *ngIf="device_type === 'handset'">
        <div class="alert alert-warning" *ngIf="!handsets?.length">
            You currently do not have any handsets. Please set some up on the <a routerLink="/handsets">Handsets page</a>
        </div>

        <ng-container *ngIf="handsets?.length">
            <ng-container *ngIf="!getHandset()">
                <p>Select your handset to link your sip to.</p>

                <div class="field">
                    <select class="form-control" [(ngModel)]="handset_id">
                        <option value="">Select Handset</option>
                        <option *ngFor="let handset of handsets" [value]="handset.id">
                            {{ handset.mac_code }}
                            ({{ handset.device_manufacturer }} {{ handset.device_model }})
                        </option>
                    </select>
                </div>

                <p>Or set up a new handset on the <a routerLink="/handsets">Handsets page</a></p>
            </ng-container>

            <h4 *ngIf="getHandset() as handset">
                <span *ngIf="handset.device_manufacturer !== 'IPVN'">{{ handset.mac_code }}</span>
                ({{ handset.device_manufacturer }} {{ handset.device_model }})
            </h4>

            <div class="container" *ngIf="handset_id">
                <ng-container *ngFor="let handset of handsets">
                    <ng-container *ngIf="handset.id == handset_id">
                        <ng-container *ngIf="handset.device_manufacturer !== 'IPVN'">
                            <div class="row" style="padding:  5px; " *ngFor="let device_line of handset.sip_device_lines">
                                <div class="col-2">Line {{ device_line.line_number }}</div>
                                <div class="col"><app-forwarding-to-partial [destination]="getSip(device_line)"></app-forwarding-to-partial></div>
                                <div class="col">
                                    <button class="btn btn-success" (click)="selectDeviceLine(device_line)">Select line</button>
                                    &nbsp;
                                    <button *ngIf="getSip(device_line)" class="btn btn-danger" (click)="selectDeviceLine(device_line, true)">Remove</button>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="handset.device_manufacturer == 'IPVN'">                            
                            <div class="table-responsive">
                                <div>
                                    <p>
                                        You are using our Cloud Softphone App
                                    </p>
                                    <p class="text-center">
                                        <img src="https://content.si-p.co.uk/img/cloud_softphone_logo.png" width="175" alt="">
                                    </p>
                                    <p>
                                        If you need to set the app up on another device, or use this VoIP user with another app or phone, click the button below.
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div class="btn btn-lg btn-block btn-outline-danger text-lg" (click)="removeCloudSoftphone()" style="margin-top:1%">Remove Cloud Softphone App from this User</div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="device_type === 'soft'">
        <h2>App Setup Instructions</h2>
        <p>Use our Cloud Softphone app on your mobile device to make and receive calls over your internet connection.</p>
        <p>To use our app please <span style="font-weight:600">click on the icon below</span>, otherwise select the option to use another app.
        </p>
        <div style="display: flex;flex-flow: column;">
            <a class="text-center btn-wiz" (click)="device_type = 'soft-ipvn'" style="display: flex;flex-flow: column;margin-left: auto;margin-right: auto;margin-bottom:2%;">
                <img _ngcontent-lrx-c238="" src="https://content.si-p.co.uk/img/cloud_softphone_logo.png" width="200" alt="" style="padding: 3%;">
                <label style="font-weight: 600;">Cloud Softphone</label>
            </a>
        </div>
        <a class="btn-wiz" (click)="device_type = 'soft-legacy'">
            <div class="alert alert-danger" style="text-align:center">
                We recommend using Cloud Softphone, but click here to use one of our legacy options.
            </div>
        </a>
    </ng-container>
    <ng-container *ngIf="device_type === 'soft-legacy'">
        <div class="card-columns">    
            <a class="card text-center btn-wiz" (click)="device_type = 'soft-zoiper'">
                <div class="card-body darkcard cardrounded">
                    <i class="fas fa-mobile-alt"></i>
                    <h5 class="card-title">Zoiper <br><span class="badge alert-danger">Legacy</span></h5>
                </div>
            </a>
            <a class="card text-center btn-wiz" (click)="device_type = 'soft-grandstream'">
                <div class="card-body darkcard cardrounded">
                    <i class="fas fa-mobile-alt"></i>
                    <h5 class="card-title">Grandstream Wave Lite <br><span class="badge alert-danger">Legacy</span></h5>
                </div>
            </a>
            <a class="card text-center btn-wiz" (click)="device_type = 'soft-acrobits'">
                <div class="card-body darkcard cardrounded">
                    <i class="fas fa-mobile-alt"></i>
                    <h5 class="card-title">Acrobits <br><span class="badge alert-danger">Legacy</span></h5>
                </div>
            </a>
        </div>
    </ng-container>
    <ng-container *ngIf="device_type === 'soft-grandstream'">
        <h2>Soft Phone Setup Instructions</h2>
        <p class="alert alert-danger">
            GS Wave Lite is no longer available for iPhone or Android. If you don't already have GS Wave Lite on your device, you'll need to select another option.
        </p>
        <div>
            <p>You have chosen to use the Grandstream Wave softphone app to register your VoIP User.</p>
            <p class="text-center">
                <img src="/assets/images/sip-setup/grandstream.png" alt="">
            </p>
            <p style="background-color:lightgray;padding: 5px;font-weight:500;">
                Please visit the Google play or app store and download the Grandstream Wave Lite app onto the device
                <br />
                <a href="https://play.google.com/store/apps/details?id=com.grandstream.wave&hl=en_US" target="_blank">
                    <img src="/assets/images/app_badges/google-play-badge.png" width="150" style="margin:1px" />
                </a>
                <a href="https://apps.apple.com/us/app/grandstream-wave/id1029274043" target="_blank">
                    <img src="/assets/images/app_badges/app_store.svg" style="margin:10px" />
                </a>
            </p>

            <ng-container *ngIf="!password">
                <p>Once you have downloaded the app on your mobile device, please click the button below to set up the app</p>
            </ng-container>
        </div>

        <div class="ui segment" *ngIf="password">
            <hr>
            <div class="ui container">
                <div id="soft-phone-instructions">
                    <p>Now please open the app and go to settings > account, and then tap (+) icon in the top corner.</p>

                    <p>We recommend that you view the QR code on a different device to the one you are using to set up the app (for example - have the QR code screen open on a laptop). </p>

                    <ul style="list-style-position: inside;background-color:lightgray;padding-bottom:2%;border-bottom:2px solid #f2f2f2;">
                        <li>If you are viewing these instructions on a different device than you wish to use GSWave with, then tap "UCM Account (Scan QR Code). This will activate your camera. Point it at the QR code shown here and this will program your soft phone so that it's ready for use.</li>
                        <li>If you're viewing the instruction on the same device you want to set the app up with, then save the image below and Tap "UCM Account (Select QR Code Image) in GS Wave. You can then select a QR code saved in your images</li>
                    </ul>

                    <div id="GSQR"style="margin-top: 2%">
                        <img src="{{ gsqr_img_url }}" style="width: 300px;display:block; margin-left: auto; margin-right: auto; margin-bottom: 15px" alt="">
                    </div>

                    <p>
                        When finished please dial 121 from the dialer screen on the app.  121 is our echo test line that repeats everything it hears back so that you can check to make sure that you are connected correctly and test the sound quality.
                    </p>

                    <p>
                        If you are having any issues with things like sound quality then please use the HELP section on the portal where you will find lots of useful information, or reach out to our team using the CHAT facility on our portal and website.
                    </p>
                </div>
            </div>
        </div>
        <ng-container *ngIf="!password">
            <div class="btn btn-lg btn-block btn-outline-success text-lg" (click)="regenerateDetails()">
                Regenerate Password and Show QR Code
                <br>
                <span class="text-sm text-muted">(You will need to reprogram any devices using this account)</span>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="device_type === 'soft-zoiper'">
        <h2>Soft Phone Setup Instructions</h2>
        <div>
            <p>You have chosen to use the Zoiper softphone app to register your VoIP User.</p>
            <p class="text-center">
                <img src="/assets/images/sip-setup/zoiper.png" alt="">
            </p>
            <p>
                To install the free soft phone please firstly visit your mobile app store and download the free version of ZOIPER SOFTPHONE.
                <br />
                <a href="https://play.google.com/store/apps/details?id=com.zoiper.android.app" target="_blank">
                    <img src="/assets/images/app_badges/google-play-badge.png" width="150" style="margin:1px" />
                </a>
                <a href="https://itunes.apple.com/gb/app/zoiper-sip-softphone-voip/id438949960" target="_blank">
                    <img src="/assets/images/app_badges/app_store.svg" style="margin:10px" />
                </a>
            </p>
            <ng-container *ngIf="!password">
                <p>Once you have downloaded the app on your mobile device, please click the button below to set up the app</p>
            </ng-container>
        </div>
        <div class="ui segment" *ngIf="password">
            <div class="ui container">
                <div id="soft-phone-instructions">
                    <hr>
                    <p>Run this and on the dial pad screen click on the QR code on the bottom left of the screen.</p>

                    <p>This will activate your camera. Point it at the QR code shown here and this will program your soft phone ready for use.</p>

                    <div class="zoiper-img" style="height:271px;overflow:hidden;margin:15px">
                        <img src="https://oem.zoiper.com/qr.php?provider_id={{ zoiper_provider_id }}&u={{ account_id + sid_start }}-{{ switchboard_object?.extension_number }}&h={{ host }}&p={{ password }}&a={{ account_id + sid_start }}-{{ switchboard_object?.extension_number }}&tr=1" alt="QR image" style="width: 300px;display:block; margin-left: auto; margin-right: auto;" />
                    </div>

                    <p>
                        When finished please dial 121 from the dialer screen on the app.  121 is our echo test line that repeats everything it hears back so that you can check to make sure that you are connected correctly and test the sound quality.
                    </p>

                    <p>
                        If you are having any issues with things like sound quality then please use the HELP section on the portal where you will find lots of useful information, or reach out to our team using the CHAT facility on our portal and website.
                    </p>
                </div>
            </div>
        </div>
        <ng-container *ngIf="!password">
            <div class="btn btn-lg btn-block btn-outline-success text-lg" (click)="regenerateDetails()">
                Regenerate Password and Show QR Code
                <br>
                <span class="text-sm text-muted">(You will need to reprogram any devices using this account)</span>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="device_type === 'soft-acrobits'">
        <h2>Acrobits Setup Instructions</h2>
        <div>
            <p>
                You can follow the PDF guide below to configure the app once you have downloaded it.
            </p>
            <p>
                <a href="https://apps.apple.com/gb/app/acrobits-softphone/id314192799" target="_blank">
                    <img src="/assets/images/app_badges/app_store.svg" style="margin:10px" />
                </a>
            </p>
            <a href="https://content.si-p.co.uk/pdf/Acrobits_Softphone_Guide.pdf" target="_blank">
                <button class="download-button">
                    Acrobits Setup Guide <i _ngcontent-okf-c214="" class="far fa-file-pdf"></i>
                </button>
            </a>
        </div>
        <div class="table-responsive">
            <table class="table">
                <tr>
                    <th>&nbsp;</th>
                    <th>Detail</th>
                </tr>
                <tr>
                    <th>Host</th>
                    <td>{{ host }}</td>
                </tr>
                <tr>
                    <th>Username</th>
                    <td>{{ account_id + sid_start }}-{{ switchboard_object?.extension_number }}</td>
                </tr>
                <tr>
                    <th>Password</th>
                    <td *ngIf="password">{{ password }}</td>
                    <td *ngIf="!password">
                        <!-- ******** -->
                        <div class="btn btn-sm btn-success" (click)="regenerateDetails()">Password Hidden. Regenerate to create a new password</div>
                    </td>
                </tr>
                <tr>
                    <th>Port (if required)</th>
                    <td>5060</td>
                </tr>
            </table>
        </div>
    </ng-container>

    <ng-container *ngIf="device_type === 'soft-ipvn'">
        <h2>App Setup Instructions</h2>
        <div>
            <p>
                Setting up our app is simple, just follow the steps below
                <ol>
                    <li>Download and install the app from your app store on the following links.<br>
                        <a href="https://apps.apple.com/us/app/cloud-softphone/id567475545" target="_blank">
                            <img src="/assets/images/app_badges/app_store.svg" style="margin:1px" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=cz.acrobits.softphone.cloudphone&hl=en&gl=US" target="_blank">
                            <img src="/assets/images/app_badges/google-play-badge.png" width="150" />
                        </a>
                    </li>
                    <li>Either select the option to scan the QR code or type in the username and password displayed.</li>
                    <li>You are now ready to make and receive calls!</li>
                </ol>
        </div>
        <hr *ngIf="cloud_password">
        <div id="IPVNQR" *ngIf="cloud_password" style="margin-top: 2%">
            <img src="{{ ipvnqr_img_url }}" style="width: 150px;display:block; margin-left: auto; margin-right: auto; margin-bottom: 15px" alt="">
        </div>
        
        <div *ngIf="cloud_password" class="table-responsive">
            <table class="table">
                <tr>
                    <th>&nbsp;</th>
                    <th>Detail</th>
                </tr>
                <tr>
                    <th>App Username</th>
                    <td>{{ account_id }}{{ switchboard_object?.extension_number }}@IPVN-VOIP</td>
                </tr>
                <tr>
                    <th>App Password <i>*case sensitive</i></th>
                    <td *ngIf="cloud_password">{{ cloud_password }}</td>
                </tr>
            </table>
        </div>

        <ng-container *ngIf="!password">
            <div class="btn btn-lg btn-block btn-outline-success text-lg" (click)="generateDetailsCloudSoftphone()">
                Continue to QR Code
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="device_type === 'pc'">
        <div class="ui segment" *ngIf="password">
            <h2>PC/Laptop Setup Instructions</h2>
            <p>You can download the pre-configured PC/laptop version to work with Windows / MAC here</p>
            <p>Just click on the button below and follow the steps to download and run the program which is then automatically ready to use</p>
            <div class="ui container">
                <div class="fields">
                    <a class="btn btn-info" href="https://www.zoiper.com/en/page/2e055659aa58ecab6b1cd9d17c5eb1c3?&u={{ account_id + sid_start }}-{{ switchboard_object?.extension_number }}&h={{ host }}&p={{ password }}&a={{ account_id + sid_start }}-{{ switchboard_object?.extension_number }}&tr=1" target="_blank">Download</a>
                </div>
            </div>
        </div>
        <ng-container *ngIf="!password">
            <div class="btn btn-lg btn-block btn-outline-success text-lg" (click)="regenerateDetails()">
                Regenerate Password
                <br>
                <span class="text-sm text-muted">(You will need to reprogram any devices using this account)</span>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="device_type === 'manual'">
        <div class="alert alert-secondary">
            If you are programming a device yourself then please use the following details, noting that any AUTH USERNAMES will be identical to the username
        </div>

        <div class="table-responsive">
            <table class="table">
                <tr>
                    <th>&nbsp;</th>
                    <th>Detail</th>
                </tr>
                <tr>
                    <th>Host</th>
                    <td>{{ host }}</td>
                </tr>
                <tr>
                    <th>Username</th>
                    <td>{{ account_id + sid_start }}-{{ switchboard_object?.extension_number }}</td>
                </tr>
                <tr>
                    <th>Password</th>
                    <td *ngIf="password">{{ password }}</td>
                    <td *ngIf="!password">
                        <!-- ******** -->
                        <div class="btn btn-sm btn-success" (click)="regenerateDetails()">Password Hidden. Regenerate to create a new password</div>
                    </td>
                </tr>
                <tr>
                    <th>Port (if required)</th>
                    <td>5060</td>
                </tr>
            </table>
        </div>

        <ng-container *ngIf="!password">
            <div class="btn btn-lg btn-block btn-outline-success text-lg" (click)="regenerateDetails()">
                Regenerate Password
                <br>
                <span class="text-sm text-muted">(You will need to reprogram any devices using this account)</span>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="device_type === 'details'">
        <div class="alert alert-secondary">
            If you are programming a device yourself then please use the following details, noting that any AUTH USERNAMES will be identical to the username
        </div>

        <div class="table-responsive">
            <table class="table">
                <tr>
                    <th>&nbsp;</th>
                    <th>Detail</th>
                </tr>
                <tr>
                    <th>Host</th>
                    <td>{{ host }}</td>
                </tr>
                <tr>
                    <th>Username</th>
                    <td>{{ account_id + sid_start }}-{{ switchboard_object?.extension_number }}</td>
                </tr>
                <tr>
                    <th>Port (if required)</th>
                    <td>5060</td>
                </tr>
            </table>
        </div>
    </ng-container>
</app-modal>

<app-portal-component-media-select [account_id]="account_id" [switchboard_object]="switchboard_object" type="music" #music_component></app-portal-component-media-select>

<app-portal-component-field-edit-switchboard-object
    [account_id]="account_id"
    [switchboard_object]="switchboard_object" 
    field="voicemail_email"
    #email_modal
></app-portal-component-field-edit-switchboard-object>

<ng-template #loading>
    <div class="card-body">
        <app-loadingbar></app-loadingbar>
    </div>
</ng-template>
