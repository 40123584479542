<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow extrapadding">
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Help</h2>
                    </div>
                </div>
            </div>
            <div class="container"></div>
            <div class="help-intro">
                <div class="troubleshooter" id="troubleshoot" data-parent="#voip-troubleshoot-accordion" style="">
                    <div class="troubleshooting-step step-1" data-step="start">
                        <p class="step-heading">How can we help?</p>
                        <p>Just pick the category below that you need help with.</p>
                        <ul class="ts-question">
                            <a href="#" data-goto="voip-overstart">
                                <li>VoIP</li>
                            </a>
                            <a href="#" data-goto="call-forwarding-start">
                                <li>Call Forwarding</li>
                            </a>
                            <a href="#" data-goto="auto-attendant-start">
                                <li>Auto Attendant</li>
                            </a>
                            <a href="#" data-goto="announcement-start">
                                <li>Announcements</li>
                            </a>
                            <a href="#" data-goto="voicemail-start">
                                <li>Voicemail</li>
                            </a>
                            <a href="#" data-goto="call-recording-start">
                                <li>Call Recording</li>
                            </a>
                            <a href="#" data-goto="time-management-start">
                                <li>Time Management</li>
                            </a>
                            <a href="#" data-goto="call-bundle-start">
                                <li>Call Bundles</li>
                            </a>
                            <a href="#" data-goto="porting-start">
                                <li>Porting a Number</li>
                            </a>
                            <a href="#" data-goto="music-on-hold-start">
                                <li>Music on Hold</li>
                            </a>
                            <a href="#" data-goto="whatsapp-start">
                                <li>Registering with WhatsApp Business</li>
                            </a>
                        </ul>
                    </div>
                    <div class="troubleshooting-step step-1" data-step="voip-overstart">
                        <p class="step-heading">Do you have a problem with VoIP, or do you need help setting it up?</p>
                        <ul class="ts-question">
                            <a href="#" data-goto="voip-start">
                                <li>Problem with VoIP</li>
                            </a>
                            <a href="#" data-goto="voip-setup-start">
                                <li>Help setting up VoIP</li>
                            </a>
                        </ul>
                        <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                    </div>
                    <div class="troubleshooting-step step-1" data-step="voip-start">
                        <p class="step-heading">VoIP</p>
                        <p>We want to make sure that your user experience is absolutely perfect, however we also know that things don’t always go to plan straight away.  Sometimes devices, app settings, local networks and wifi routers may cause problems and interfere with the quality of your VoIP call or stop things from working as they should.</p>
                        <p>There can be several reasons why things may fail, so we have outlined the most common and how to remedy these.  We want to make sure that if for any reason your experience isn't perfect, you have the knowledge to fix it.</p>
                        <p class="questionHeading">What issue are you having?</p>
                        <ul class="ts-question">
                            <a href="#" data-goto="voip-section-1">
                                <li>Call drops</li>
                            </a>
                            <a href="#" data-goto="voip-section-1">
                                <li>Missing or distorted speech Echo or one-way speech</li>
                            </a>
                            <a href="#" data-goto="voip-section-5">
                                <li>VoIP devices not registering / becoming de-registered</li>
                            </a>
                            <a href="#" data-goto="voip-section-2">
                                <li>Call transfers failing</li>
                            </a>
                            <a href="#" data-goto="voip-section-2">
                                <li>Over ringing - other phones in the call group continue to ring after the call has been answered</li>
                            </a>
                            <a href="#" data-goto="voip-section-1a">
                                <li>“Ghost” incoming calls from strange telephone numbers like 1000000</li>
                            </a>
                            <a href="#" data-goto="voip-section-9">
                                <li>VoIP App does not ring / wake for inbound calls</li>
                            </a>
                            <a href="#" data-goto="voip-section-10">
                                <li>Can’t press a button to answer a call on the VoIP App</li>
                            </a>
                            <a href="#" data-goto="voip-section-8">
                                <li>Other issue</li>
                            </a>
                        </ul>
                        <a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt" ></i>Start Again</a>
                    </div>
                    <div class="troubleshooting-step" data-step="voip-section-1a">
                        <p class="step-heading">"Ghost" Calls</p>
                        <p>
                            This is usually caused by mishandled data on your network. To prevent this, on your device, go to Settings > Account Settings > and enable Only Accept SIP Requests from Known Servers. Please note, this setting is available on Grandstream devices, but may be under a different name, or not available at all with some phones.
                        </p>
                        <p class="questionHeading">Has this resolved your issue?</p>
                        <ul class="ts-question">
                            <a href="#" data-goto="start">
                                <li>Yes</li>
                            </a>
                            <a href="#" data-goto="voip-section-2">
                                <li>No, I still have problems.</li>
                            </a>
                        </ul>
                        <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                    </div>
                    <div class="troubleshooting-step" data-step="voip-section-1">
                        <p class="step-heading">
                            Turn off Wifi and use mobile data
                        </p>
                        <p>The first thing to try is swapping from wifi to mobile or visa versa.  This will help to diagnose which network is causing the issues.  Sometimes the router that controls your local network may not handle the voice data correctly, causing it to be lost on the network and not reach the destination properly</p>
                        <p>If you want to use wifi, then we suggest that you give the wifi router a reboot, then test again.</p>
                        <p><span class="alert-link">NOTE: Voip relies on a good data connection to work reliably.</span> If you're indoors, then try to connect to Wifi and this should improve the connection. When you're out and about make sure that you have at least 4g. If you know that you will be in a poor data coverage area, you can set your incoming calls to forward directly to your mobile number instead.</p>
                        <p class="questionHeading">Has this resolved your issue?</p>
                        <ul class="ts-question">
                            <a href="#" data-goto="voip-start">
                                <li>Yes</li>
                            </a>
                            <a href="#" data-goto="voip-section-2">
                                <li>No, I still have problems.</li>
                            </a>
                        </ul>
                        <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                    </div>
                    <div class="troubleshooting-step" data-step="voip-section-2">
                        <p class="step-heading">Try switching the transport protocol between UDP and TCP</p>
                        <p>Depending on the router you use, you may need to change your ‘Transmission Protocol’ from UDP to TCP.  This can be done within the account settings of the app you use, or in the account setup section of your desk phone.</p>
                        <p><span class="alert-link">When using an app like Grandstream Wave;</span>
                        Go to settings, then select the VoIP user account, change Transmission Protocol from UDP to TCP
                    </p>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes</li>
                        </a>
                        <a href="#" data-goto="voip-section-3">
                            <li>No, I still have problems.</li>
                        </a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="voip-section-3">
                    <p class="step-heading">Reboot your router</p>
                    <p>Basic routers are fine for web browsing, as when browsing the internet you probably won’t notice delays due to network packets being mis-routed or lost, but on a phone call this could result in the following side effects-</p>
                    <p>Typical issues related to basic routers</p>
                    <ul>
                        <li>Call drops</li>
                        <li>Missing or distorted speech</li>
                        <li>Echo or one-way speech</li>
                        <li>VoIP devices not registering / becoming de-registered</li>
                        <li>Call transfers failing</li>
                        <li>Over ringing - other phones in the call group ring after the call has been answered</li>
                        <li>Erroneous incoming calls from strange telephone numbers like 1000000</li>
                    </ul>
                    <p>A simple reboot of the router may resolve the issues, so it's good to try this first.</p>
                    <p>Note - if you are going to have several VoIP devices and need to guarantee call quality then we strongly recommend the use of Draytek Vigor or equivalent routers. For more information on these please contact our support team for advice as we can arrange to remotely configure your router once it is online.</p>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes</li>
                        </a>
                        <a href="#" data-goto="voip-section-8">
                            <li>No, I still have problems.</li>
                        </a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="voip-section-4">
                    <p class="step-heading">VoIP user not working / unregistered</p>
                    <p>There are a few different thing to try here;</p>
                    <p>Firstly, toggle your account on and off - First toggle your account off and back on by clicking on the account.</p>
                    <p>Check that you have data connection or wifi is switched on on the device</p>
                    <p>Rescan the QR code on the customer portal - follow the same steps for when you set up the voip user - remember to click on “regenerate code” and scan the new QR code</p>
                    <p class="questionHeading" class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes</li>
                        </a>
                        <a href="#" data-goto="voip-section-5">
                            <li>No, I still have problems.</li>
                        </a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="voip-section-5">
                    <p class="step-heading">What device are you using?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="voip-section-6">
                            <li>A Softphone / Mobile App</li>
                        </a>
                        <a href="#" data-goto="voip-section-7">
                            <li>VoIP Device (Handset)</li>
                        </a>
                    </ul>
                </div>
                <div class="troubleshooting-step" data-step="voip-section-6">
                    <p class="step-heading">If you're using a softphone -</p>
                    <ol>
                        <li>Firstly, Toggle your account on and off</li>
                        <li>Switch from wifi to mobile data to eliminate any local network/router issues</li>
                        <li>Change the transport protocol from UDP to TCP on the app if possible</li>
                        <li>If using Grandstream Wave then make sure that all network types are selected to be used - wifi / mobile data etc</li>
                        <li>Make sure that the app is not forced to sleep by your phone when in the background</li>
                        <li>If you have tried all the above and it still is not registering then rescan the QR code on the customer portal - (follow the same steps for when you set up the voip user - remember to click on “regenerate code” and scan the new QR code)</li>
                    </ol>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes</li>
                        </a>
                        <a href="#" data-goto="voip-section-8">
                            <li>No, I still have problems.</li>
                        </a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="voip-section-7">
                    <p class="step-heading">If you're using a VoIP Handset -</p>
                    <ol>
                        <li>Check network cables are plugged in to the correct ports </li>
                        <li>Check that DND (Do Not Disturb) is not active</li>
                        <li>Check for diverts set from the handset</li>
                        <p class="questionHeading">Has this resolved your issue?</p>
                    </ol>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes</li>
                        </a>
                        <a href="#" data-goto="voip-section-8">
                            <li>No, I still have problems.</li>
                        </a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="voip-section-9">
                    <p class="step-heading">Common causes</p>
                    <ul>
                        <li>Battery saving mode apps</li>
                        <li>VoIP user not in call forwarding group</li>
                        <li>Phone forcing the app to sleep</li>
                        <li>Permissions denied for things like screen overlays</li>
                    </ul>
                    <p>Firstly, you will want to check that the voip user is set up in the call forwarding group.</p>
                    <ul>
                        <li>From your customer portal dashboard, click on “Call flow manager”</li>
                        <li>Select the number you need to check the call flow for</li>
                        <li>Check voip user is included in the call group</li>
                    </ul>
                    <p>Make sure you leave the app running in the background - If you close the app the SIP / VoIP User will be disconnected.</p>
                    <p><span class="alert-link">If you are using an iphone device</span>, make sure your Background App Refresh is on - This will keep the SIP active in the background when the app is not in use. Go into the settings and scroll down till you see ‘GS Wave’ and make sure background app refresh is on. Also, make sure that your iphone is not on “low battery mode” as this will close all unused apps.</p>
                    <p><span class="alert-link">If you are using an android device</span> - You may need to go to Settings > Apps > GS Wave, and ensure Battery Optimization is disabled, as this can prevent calls reaching you when your phone is locked.</p>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes</li>
                        </a>
                        <a href="#" data-goto="voip-section-8">
                            <li>No, I still have problems.</li>
                        </a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="voip-section-10">
                    <p class="step-heading">Solution</p>
                    <p>Go to <span class="alert-link">Settings > Apps > GS Wave</span>, and make sure <span class="alert-link">Display over other apps</span> is enabled.</p>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes</li>
                        </a>
                        <a href="#" data-goto="voip-section-8">
                            <li>No, I still have problems.</li>
                        </a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="voip-section-8">
                    <p class="step-heading">Raise a Support Ticket</p>
                    <p>Thank you for taking the time to go through our troubleshooting section</p>
                    <p>If you have gone through all the ways to try and fix the issue, or can't find a solution to your problem then please use the link below to create a support ticket and our helpful team will get back in touch shortly to try to fix your issue</p>
                    <ul class="ts-question">
                        <a [href]="supportUrl" target="_blank">
                            <li>Raise a Ticket</li>
                        </a>
                    </ul>
                    <div class="start-again"><a class="start-again-button" href="#" data-goto="music-on-hold-start"><i class="fas fa-redo-alt" ></i>Start Again</a></div>
                </div>
                <div class="start-again" data-sections="voip-section-"><a class="start-again-button" href="#" data-goto="voip-start">Go back</a></div>
                
                <div class="troubleshooting-step step-1" data-step="voip-setup-start">
                    <p class="step-heading">VoIP Set Up Troubleshooting</p>
                    <p>This guide should enable you to quickly and simply set up your VoIP users</p>
                    <p class="questionHeading">Firstly, we need to know which device you are going to use</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="voip-setup-section-1">
                            <li>Mobile Phone</li>
                        </a>
                        <a href="#" data-goto="voip-setup-section-2">
                            <li>VoIP Phone</li>
                        </a>
                        <a href="#" data-goto="voip-setup-section-3">
                            <li>PC/Laptop/Tablet</li>
                        </a>
                        <a href="#" data-goto="voip-setup-section-4">
                            <li>Something else</li>
                        </a>
                        <a href="#" data-goto="voip-start">
                            <li>I’ve already set up my VoIP user but am having issues</li>
                        </a>
                    </ul>
                    <a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt" ></i>Start Again</a>
                </div>
                <div class="troubleshooting-step" data-step="voip-setup-section-1">
                    <p class="step-heading">Setting up a VoIP user on a mobile phone</p>
                    <p>The most common way our customers use their VoIP user is via an app installed onto a mobile phone.</p>
                    <p>It's really simple to set up and means that you don't have to buy any new hardware and you can answer and make calls wherever you are.</p>
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2020/01/voip1-e1621336137608.png">
                    <ol>
                        <li>Firstly, from your main dashboard, click on My VoIP users</li>
                        <li>You can either add a new voip user or set up any existing voip users you already have.  If you need a new voip user, click on + New VoIP user, or simply click EDIT on the existing user you need to set up.</li>
                        <img src="https://numberpeople.co.uk/wp-content/uploads/2021/05/showqr.png">
                        <li>Select Mobile/Softphone, and then choose which app you would like to use. We recommend Cloud Softphone, but you can also choose from the Legacy options.</li>
                        <li>After choosing the app, click on 'Continue to QR Code'</li>
                        <img src="https://numberpeople.co.uk/wp-content/uploads/2021/05/regenbuton.png">
                        <li>Click OK to confirm you would like to generate the details for this user</li>
                        <li>You'll now see setup instructions for the app which you'll need to follow along with. This screen also has links to download the app from the Play/App Store.</li>
                        <li>Once you've completed the steps listed within the app, you're all ready to make and receive calls!</li>
                    </ol>
                    <p>You can watch a video guide for setting up a voip user here</p>
                    <p><a href="https://www.youtube.com/watch?v=QAe2GgfwlGc&feature=youtu.be" target="_blank"><button>Grandstream Wave <i class="fab fa-youtube"></i>
                    </button></a></p>
                    <p><a href="https://www.youtube.com/watch?v=EqLVmRUnzLQ" target="_blank"><button>Zoiper Lite <i class="fab fa-youtube"></i>
                    </button></a></p>
                    <p>Or, you can download a PDF guide</p>
                    <p><a href="https://content.si-p.co.uk/pdf/Cloud_Softphone_Guide.pdf" target="_blank"><button>Cloud Softphone <i class="far fa-file-pdf"></i>
                    </button></a></p>
                    <p><a href="https://content.si-p.co.uk/pdf/Acrobits_Softphone_Guide.pdf" target="_blank"><button>Acrobits <i class="far fa-file-pdf"></i>
                    </button></a></p>
                    <p><a href="https://ipvn.s-ip.co.uk/grandstreamwave.pdf" target="_blank"><button>Grandstream Wave <i class="far fa-file-pdf"></i>
                    </button></a></p>
                    <p><a href="https://ipvn.s-ip.co.uk/zoipersetup.pdf" target="_blank"><button>Zoiper Lite <i class="far fa-file-pdf"></i>
                    </button></a></p>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="voip-setup-start">
                            <li>Yes, all working</li>
                        </a>
                        <a href="#" data-goto="voip-setup-section-4">
                            <li>No, I still have problems.</li>
                        </a>
                    </ul>
                </div>
                <div class="troubleshooting-step" data-step="voip-setup-section-2">
                    <p class="step-heading">Setting up a VoIP Device</p>
                    <p>If you already have a SIP enabled VoIP Phone you can use this on our network. You will need the admin username and password for your hardware, you can then simply enter your new SIP credentials into the device to reprogram it.
                        If you already have a SIP enabled VoIP Phone you can use this on our network. You will need the admin username and password for your hardware, you can then simply enter your new SIP credentials into the device to reprogram it.
                    </p>
                    <p><strong>Just follow the below steps;</strong></p>
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2020/01/voip1-e1621336137608.png">
                    <li>Firstly, from your main dashboard click on My VoIP users</li>
                    <li>You can either add a new voip user or set up any existing voip users you already have.  If you need a new voip user, click on + New VoIP user, or simply click EDIT on the existing user you need to set up.</li>
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2021/05/showqr.png">
                    <li>Once you have click on edit, you will be presented with a new choice of options, please click on the first option Show QR Code & Set up your device</li>
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2021/05/regenbuton.png">
                    <li>Then click on the Green button Regenerate Password and Show QR Code</li>
                    <li>Click OK to confirm you would like to regenerate the details for this user</li>
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2021/05/sipdetails.png">
                    <li>Use the displayed information to program your device</li>
                    <li>Your device should register to our network and be ready to use immediately</li>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="voip-setup-start">
                            <li>Yes, all working</li>
                        </a>
                        <a href="#" data-goto="voip-setup-section-4">
                            <li>No, I still have problems.</li>
                        </a>
                    </ul>
                </div>
                <div class="troubleshooting-step" data-step="voip-setup-section-3">
                    <p class="step-heading">Setting up a softphone on your PC, Laptop or tablet</p>
                    <p>Using a headset with a microphone, you can turn your existing hardware into a device to make and receive calls.</p>
                    <p>Please firstly install the softphone that you would like to use on the device, then follow the below steps to find the SIP credentials to program the device to use on our network.</p>
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2020/01/voip1-e1621336137608.png">
                    <ol>
                        <li>Firstly, from your main dashboard click on My VoIP users</li>
                        <li>You can either add a new voip user or set up any existing voip users you already have.  If you need a new voip user, click on + New VoIP user, or simply click EDIT on the existing user you need to set up.</li>
                        <img src="https://numberpeople.co.uk/wp-content/uploads/2021/05/showqr.png">
                        <li>Once you have click on edit, you will be presented with a new choice of options, please click on the first option Show QR Code & Set up your device</li>
                        <img src="https://numberpeople.co.uk/wp-content/uploads/2021/05/regenbuton.png">
                        <li>Then click on the Green button Regenerate Password and Show QR Code</li>
                        <li>Click OK to confirm you would like to regenerate the details for this user</li>
                        <img src="https://numberpeople.co.uk/wp-content/uploads/2021/05/sipdetails.png">
                        <li>Use the displayed information to program your device</li>
                        <li>Your device should register to our network and be ready to use immediately</li>
                    </ol>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="voip-setup-start">
                            <li>Yes, all working</li>
                        </a>
                        <a href="#" data-goto="voip-setup-section-4">
                            <li>No, I still have problems.</li>
                        </a>
                    </ul>
                </div>
                <div class="troubleshooting-step" data-step="voip-setup-section-4">
                    <p class="step-heading">Raise a Support Ticket</p>
                    <p>Thank you for taking the time to go through our troubleshooting section</p>
                    <p>If you have gone through all the ways to try and fix the issue, or can't find a solution to your problem then please use the link below to create a support ticket and our helpful team will get back in touch shortly to try to fix your issue</p>
                    <ul class="ts-question">
                        <a [href]="supportUrl" target="_blank">
                            <li>Raise a Ticket</li>
                        </a>
                    </ul>
                    <div class="start-again"><a class="start-again-button" href="#" data-goto="music-on-hold-start"><i class="fas fa-redo-alt" ></i>Start Again</a></div>
                </div>
                <div class="start-again" data-sections="voip-setup-section-"><a class="start-again-button" href="#" data-goto="voip-setup-start">Go back</a></div>
                
                <div class="troubleshooting-step step-1" data-step="call-forwarding-start">
                    <p class="step-heading">Call Forwarding</p>
                    <p class="questionHeading">What help do you need?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="call-forwarding-section-1">
                            <li>Check where my number is forwarding to</li>
                        </a>
                        <a href="#" data-goto="call-forwarding-section-2">
                            <li>Change my forwarding destination</li>
                        </a>
                        <a href="#" data-goto="call-forwarding-section-5">
                            <li>Adding a call forwarding group</li>
                        </a>
                        <a href="#" data-goto="call-forwarding-section-6">
                            <li>My call forwarding is not working</li>
                        </a>
                        <a href="#" data-goto="call-forwarding-section-9">
                            <li>Show the callers number on inbound calls</li>
                        </a>
                        <a href="#" data-goto="call-forwarding-section-8">
                            <li>Something else</li>
                        </a>
                    </ul>
                    <a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt" ></i>Start Again</a>
                </div>
                <div class="troubleshooting-step" data-step="call-forwarding-section-1">
                    <p class="step-heading">Where are my calls forwarding to?</p>
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2021/05/callflow.png">
                    <p>From your main dashboard page - click on “Call Flow Manager”</p>
                    <p>Select the number you want to check</p>
                    <p>You should then be able to view the call flow of that number, including the call forwarding group members (divert numbers & voip users)</p>
                    <p>You can edit any aspect of the call flow here, including where calls are sent to and what happens if there is no answer.  Just click to edit - the call flow manager is completely interactive.</p>
                    <p class="questionHeading">Has this resolved your enquiry?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes</li>
                        </a>
                        <a href="#" data-goto="call-forwarding-section-7">
                            <li>No, I still need help.</li>
                        </a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="call-forwarding-section-2">
                    <p class="step-heading">How to change my forwarding destination</p>
                    <p class="questionHeading">What do you want to do?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="call-forwarding-section-3">
                            <li>Add a new group member</li>
                        </a>
                        <a href="#" data-goto="call-forwarding-section-4">
                            <li>Completely change how my calls are handled</li>
                        </a>
                    </ul>
                </div>
                <div class="troubleshooting-step" data-step="call-forwarding-section-3">
                    <p class="step-heading">Adding a new group member</p>
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2020/01/members1.png">
                    <p>To add a new group member to an existing call forwarding group, then click on “Call Forwarding Groups”</p>
                    <p>Find the group you want to add to & click on “edit”</p>
                    <p>Then click on “forward calls to”</p>
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2020/01/forward4.png">
                    <p>This will then pop up a section where you can add new Divert members or VoIP users. Just edit these as you need to and once your happy, just click “close” and your changes are instantly updated for you</p>
                    <p class="questionHeading">Has this resolved your enquiry?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes, all working</li>
                        </a>
                        <a href="#" data-goto="call-forwarding-section-7">
                            <li>No, I still need help.</li>
                        </a>
                    </ul>
                </div>
                <div class="troubleshooting-step" data-step="call-forwarding-section-4">
                    <p class="step-heading">Changing how calls are routed</p>
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2021/05/callflow.png">
                    <p>From the main dashboard page, click on “Call Flow Manager”</p>
                    <p>Select the number you wish to manage</p>
                    <p>This will then display your call flow for that chosen number</p>
                    <p>This section is completely interactive and any changes that you make will be live instantly</p>
                    <p>To change the routing for this number, click on the editable blue arrow - “send calls to”</p>
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2021/05/callsforwardtoo.png">
                    <p>Here you can either edit your current group members, or change the routing of calls to;</p>
                    <p>A new group
                        <br>An announcement
                        <br>A menu
                        <br>Directly to Voicemail
                        <br>Directly to a VoIP user
                    </p>
                    <p>Just use the drop down options to either select or create your new call forwarding destination</p>
                    <p class="questionHeading">Has this helped?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes, all fixed</li>
                        </a>
                        <a href="#" data-goto="call-forwarding-section-7">
                            <li>No, I still need help.</li>
                        </a>
                    </ul>
                </div>
                <div class="troubleshooting-step" data-step="call-forwarding-section-5">
                    <p class="step-heading">Add a new call forwarding group</p>
                    <p>A call forwarding group is a place where you add your number/s or VoIP member/s which are available to answer a call.  When you set your number up we give you the first group free (this will be automatically created on sign up – this is why we need to know where you want to answer your calls)</p>
                    <p>All numbers or voip members in a call group will ring at the same time and the first to answer the call will receive it.
                        You can add additional call groups at just £1 a month, these are perfect if you want to stagger the call flow.. Ie. Ring one group first, if no answer ring a different group.
                    </p>
                    <p>Additional call groups are also used really well when using an IVR menu so that different phones are called depending on the option selected by the caller eg. Press 1 for sales rings only the Sales group</p>
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2020/01/members1.png">
                    <p>To add a new call forwarding group, click on “Call Forwarding Groups” from your main dashboard page</p>
                    <p>Then, simply click on “+ New Call Forwarding group”</p>
                    <p>Follow the on screen prompts to pay for the new group, and once it's been added make sure you add your group members - these can be divert numbers, or VoIP users.</p>
                    <p>Once you have created your group, don't forget to add it to your call flow so your routing to this new group works as you want it to.</p>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes, all fixed</li>
                        </a>
                        <a href="#" data-goto="call-forwarding-section-7">
                            <li>No, I still need help.</li>
                        </a>
                    </ul>
                </div>
                <div class="troubleshooting-step" data-step="call-forwarding-section-6">
                    <p class="step-heading">Call forwarding not working</p>
                    <p class="questionHeading">Where are your calls being forwarded to?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="call-forwarding-section-7">
                            <li>A group of mobiles / landline numbers</li>
                        </a>
                        <a href="#" data-goto="voip-section-9">
                            <li>Voip user/s</li>
                        </a>
                    </ul>
                </div>
                <div class="troubleshooting-step" data-step="call-forwarding-section-9">
                    <p class="step-heading">How To Show Caller’s ID</p>
                    <p>When you receive a forwarded call, we present your virtual phone number as the inbound caller ID. If you need to see the caller's number instead, you can enable this feature from the Account Settings section of the portal.
                    </p>
                    <p>If you would prefer a PDF guide<a href="https://content.si-p.co.uk/pdf/Sign_Up_For_WhatsApp.pdf"><button>Click Here <i class="far fa-file-pdf"></i></button></a></p>
                    <p>To watch a video guide<a href="https://youtu.be/WeLHtl7-jcI"><button>Click Here <i class="fab fa-youtube"></i></button></a></p>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes, all fixed</li>
                        </a>
                        <a href="#" data-goto="call-forwarding-section-8">
                            <li>No, I still need help.</li>
                        </a>
                    </ul>
                </div>
                <div class="troubleshooting-step" data-step="call-forwarding-section-7">
                    <p class="step-heading">Please check the following first;</p>
                    <p>Check your credit balance - Do you have enough credit to forward your calls?</p>
                    <p>A very low balance will mean that calls can not be carried, if your balance is below £1 we would recommend that you top-up your account.</p>
                    <p>Has your bundle (if applicable) expired or maxed out?</p>
                    <p>If you have a call forwarding bundle, check that it hasn't expired or maxed out - you can check this by clicking on “my services” and check the status of the bundle</p>
                    <p>If you are using mobile number to forward to - ensure that all group members are switched on and have good signal</p>
                    <p>Are you forwarding to a high cost non-UK destination?</p>
                    <p>We automatically block very high cost destinations, if you have received an email indicating “blocked call alerts” then simply get in touch with our customer services team and we can help you fix this issue.</p>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes, all fixed</li>
                        </a>
                        <a href="#" data-goto="call-forwarding-section-8">
                            <li>No, I still need help.</li>
                        </a>
                    </ul>
                </div>
                <div class="troubleshooting-step" data-step="call-forwarding-section-8">
                    <p class="step-heading">Raise a Support Ticket</p>
                    <p>Thank you for taking the time to go through our troubleshooting section</p>
                    <p>If you have gone through all the ways to try and fix the issue, or can't find a solution to your problem then please use the link below to create a support ticket and our helpful team will get back in touch shortly to try to fix your issue</p>
                    <ul class="ts-question">
                        <a [href]="supportUrl" target="_blank">
                            <li>Raise a Ticket</li>
                        </a>
                    </ul>
                    <div class="start-again"><a class="start-again-button" href="#" data-goto="music-on-hold-start"><i class="fas fa-redo-alt" ></i>Start Again</a></div>
                </div>
                <div class="start-again" data-sections="call-forwarding-section-"><a class="start-again-button" href="#" data-goto="call-forwarding-start">Go back</a></div>
                
                <div class="troubleshooting-step step-1" data-step="auto-attendant-start">
                    <p class="step-heading">Auto-Attendant</p>
                    <p>An Auto Attendant menu is also sometimes called an IVR.  This is used as a call greeting which directs callers to choose a department or option to be put through to.</p>
                    <p>E.G “Welcome to Dave’s PC repairs. To enable us to handle your call efficiently please choose from one of the following options.  Press 1 for new enquiries, press 2 to track your repair or press 3 of anything else”</p>
                    <p>They are a fantastic way to give your phone number a professional image and route your calls to people who can correctly deal with the call.</p>
                    <p>IVR menus can be used alongside groups, announcements and voicemails making them a valuable tool for handling incoming calls.</p>
                    <p class="questionHeading">What do you need help with?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="auto-attendant-section-1">
                            <li>Adding an Auto Attendant</li>
                        </a>
                        <a href="#" data-goto="auto-attendant-section-2">
                            <li>Creating media for the Auto Attendant</li>
                        </a>
                        <a href="#" data-goto="auto-attendant-section-3">
                            <li>Calls are not being routed to my Auto Attendant</li>
                        </a>
                    </ul>
                    <a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt" ></i>Start Again</a>
                </div>
                <div class="troubleshooting-step" data-step="auto-attendant-section-1">
                    <p class="step-heading">Adding an Auto Attendant</p>
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2020/01/IVR1.png">
                    <p>To add an auto attendant / IVR menu, click on “IVR menus” from the main dashboard screen.</p>
                    <p>Follow the onscreen prompts to complete the purchase/addition to your account</p>
                    <p>Once you have added the menu system, please download our handy user guide for full step-by-step instructions on how to set up the auto attendant, adding your personalisation and adding the menu system into your call flow</p>
                    <a href="https://ipvn.s-ip.co.uk/Menus.pdf"><button>Set-up Guide <i class="far fa-file-pdf"></i>
                    </button></a>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes</li>
                        </a>
                        <a href="#" data-goto="auto-attendant-section-4">
                            <li>No, I still need help</li>
                        </a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="auto-attendant-section-2">
                    <p class="step-heading">Adding media to your auto attendant</p>
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2020/01/whisper1.png">
                    <p>All media can be managed via the media section of your customer portal.</p>
                    <p>From the main dashboard page, click on “My Media” and then click on “Create new media”</p>
                    <p>This will then take you to a pop up where you can either upload your media file, or you can create your own media by using the Text to Speech service.  Here, you can type in your announcement script and choose which accent to use and also select a male or female voice.</p>
                    <p>Have a good play around with this, as as soon as you have created something that you like, just click “create and save”</p>
                    <p>You can  download our handy user guide for full step-by-step instructions on how to set up the auto attendant, adding your personalisation and adding the menu system into your call flow</p>
                    <a href="https://ipvn.s-ip.co.uk/Menus.pdf"><button>Set-up Guide <i class="far fa-file-pdf"></i>
                    </button></a>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes, all working</li>
                        </a>
                        <a href="#" data-goto="auto-attendant-section-4">
                            <li>No, I still need help</li>
                        </a>
                    </ul>
                </div>
                <div class="troubleshooting-step" data-step="auto-attendant-section-3">
                    <p class="step-heading">Calls aren't not reaching my auto attendant</p>
                    <p>If you have already fully set up your Auto Attendant System, but it's not working when your number is dialled, firstly please check the following;</p>
                    <p>Check you have added the menu system into your call flow</p>
                    <p>From your main dashboard, click on “Call Flow Manager” and choose the number you are wanting to check.  This will then display the call flow.  Normally, your menu system will sit at the top of your call flow, if it's not then simply change this by clicking on “send calls to” which will bring up your options.  Click on “menu” and select your menu from the drop down list</p>
                    <p>For full step-by-step instructions on menu systems, please download our handy user guide </p>
                    <a href="https://ipvn.s-ip.co.uk/Menus.pdf"><button>Set-up Guide</button></a>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes, all working</li>
                        </a>
                        <a href="#" data-goto="auto-attendant-section-4">
                            <li>No, I still need help</li>
                        </a>
                    </ul>
                </div>
                <div class="troubleshooting-step" data-step="auto-attendant-section-4">
                    <p class="step-heading">Raise a Support Ticket</p>
                    <p>Thank you for taking the time to go through our troubleshooting section</p>
                    <p>If you have gone through all the ways to try and fix the issue, or can't find a solution to your problem then please use the link below to create a support ticket and our helpful team will get back in touch shortly to try to fix your issue</p>
                    <ul class="ts-question">
                        <a [href]="supportUrl" target="_blank">
                            <li>Raise a Ticket</li>
                        </a>
                    </ul>
                    <div class="start-again"><a class="start-again-button" href="#" data-goto="music-on-hold-start"><i class="fas fa-redo-alt" ></i>Start Again</a></div>
                </div>
                <div class="start-again" data-sections="auto-attendant-section-"><a class="start-again-button" href="#" data-goto="auto-attendant-start">Go back</a></div>
                
                <div class="troubleshooting-step step-1" data-step="announcement-start">
                    <p class="step-heading">Announcements</p>
                    <p>A personalised greeting can make a huge impact on your callers and validate your business as a professional service.</p>
                    <p>Your greeting can be absolutely anything you want, and can be either uploaded to us, or you can create your own media file by using out quick and simple text to speech service.</p>
                    <p>Eg – “Welcome to Dave’s PC repairs, please hold the line whilst we pass you to the next available advisor”</p>
                    <p>You can also use these custom announcements advertise other services that you offer boosting your trade-up sales</p>
                    <p>Eg “ Thank you for calling Dave’s PC Repairs, your call is in a queue and will be answered shortly.  Did you know that we have a half price offer on our antivirus software, just ask the advisor to tell you more. Please hold the line”</p>
                    <p class="questionHeading">What do you need help with?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="announcement-section-1">
                            <li>Adding an announcement</li>
                        </a>
                        <a href="#" data-goto="announcement-section-2">
                            <li>How to create or upload my own media</li>
                        </a>
                        <!-- <a href="#" data-goto="announcement-section-3">
                            <li>How to add my announcement into my call flow</li>
                        </a> -->
                        <a href="#" data-goto="announcement-section-4">
                            <li>Something else</li>
                        </a>
                    </ul>
                    <a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt" ></i>Start Again</a>
                </div>
                <div class="troubleshooting-step" data-step="announcement-section-1">
                    <p class="step-heading">Adding an announcement</p>
                    <p>You can watch a video on how to add an announcement to your account <a href="https://www.youtube.com/watch?v=6PN16PesjKI"><button>here <i class="fab fa-youtube"></i>
                    </button></a></p>
                    <p>Alternatively, you can follow the steps below;</p>
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2020/01/ann1.png">
                    <ol>
                        <li>From dashboard homepage, click on “Intro Announcements”</li>
                        <li>Click “Add new announcement” and follow onscreen prompts</li>
                        <li>This will then take to you the section to set up the announcement. Click on “announcement” to add or create your media to play. If you already have your media recorded or created, you can use the dropdown box to select it. If you haven't added it yet, just click on “Create / Upload new file” and follow the on screen prompts. You can drop a file here, or use the text to speech box to type in your script to create your own. ONce your happy, click “save”</li>
                        <li>Next, set your destination for this announcement. This is where you set what happens to the call after the announcement is played to your callers - most people will send this though to a call group, or maybe a voicemail if it is being used for out of hours.</li>
                        <li>That’s it, your announcement has been created. Just make sure that you add this announcement into your call flow via the call flow manager.</li>
                    </ol>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes</li>
                        </a>
                        <a href="#" data-goto="announcement-section-4">
                            <li>No, I still need help</li>
                        </a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="announcement-section-2">
                    <p class="step-heading">Creating / uploading media to your announcement</p>
                    <p>Your announcement media can be changed at anytime, as often as you like</p>
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2020/01/ann1.png">
                    <ol>
                        <li>From main dashboard click on “Intro announcements”</li>
                        <li>Find the announcement you wish to manage and click “EDIT”</li>
                        <li>From the edit screen, click on “Announcement”</li>
                        <img src="https://numberpeople.co.uk/wp-content/uploads/2021/05/selectmedia.png">
                        <li>You can then use the drop down to select an existing media, or if you need to create or upload a new media file then click on “Create / upload new file”</li>
                        <img src="https://numberpeople.co.uk/wp-content/uploads/2020/01/ivr7.png">
                        <li>Here, you can choose a previously used file, drop a new file if you already have your own media you wish to use, or you can use the Text to speech service to type in your own script and make your own media.  Have a good play around when using the text to speech, you can change the accent, gender of voice etc.  Once your happy click “save”</li>
                    </ol>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes, all working</li>
                        </a>
                        <a href="#" data-goto="announcement-section-4">
                            <li>No, I still need help</li>
                        </a>
                    </ul>
                </div>
                <div class="troubleshooting-step" data-step="announcement-section-3">
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes, all working</li>
                        </a>
                        <a href="#" data-goto="announcement-section-4">
                            <li>No, I still need help</li>
                        </a>
                    </ul>
                </div>
                <div class="troubleshooting-step" data-step="announcement-section-4">
                    <p class="step-heading">Raise a Support Ticket</p>
                    <p>Thank you for taking the time to go through our troubleshooting section</p>
                    <p>If you have gone through all the ways to try and fix the issue, or can't find a solution to your problem then please use the link below to create a support ticket and our helpful team will get back in touch shortly to try to fix your issue</p>
                    <ul class="ts-question">
                        <a [href]="supportUrl" target="_blank">
                            <li>Raise a Ticket</li>
                        </a>
                    </ul>
                    <div class="start-again"><a class="start-again-button" href="#" data-goto="music-on-hold-start"><i class="fas fa-redo-alt" ></i>Start Again</a></div>
                </div>
                <div class="start-again" data-sections="announcement-section-"><a class="start-again-button" href="#" data-goto="announcement-start">Go back</a></div>
                
                <div class="troubleshooting-step step-1" data-step="voicemail-start">
                    <p class="step-heading">Voicemail</p>
                    <p class="questionHeading">What do you need help with?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="voicemail-section-1">
                            <li>Adding Voicemail</li>
                        </a>
                        <a href="#" data-goto="voicemail-section-2">
                            <li>Managing my voicemail messages</li>
                        </a>
                        <a href="#" data-goto="voicemail-section-3">
                            <li>Changing where my voicemails are sent</li>
                        </a>
                        <a href="#" data-goto="voicemail-section-4">
                            <li>Something else</li>
                        </a>
                    </ul>
                    <a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt" ></i>Start Again</a>
                </div>
                <div class="troubleshooting-step" data-step="voicemail-section-1">
                    <p class="step-heading">Adding voicemail</p>
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2020/01/vmail1.png">
                    <ol>
                        <li>From your portal dashboard, click on “Voicemail Inboxes”</li>
                        <li>Click “+ new voicemail box” to add a new voicemail & follow onscreen prompts to complete the purchase</li>
                        <img src="https://numberpeople.co.uk/wp-content/uploads/2020/01/vmail7-e1621339899707.png">
                        <li>You will then be taken to the “edit voicemail” screen - this is where you set up the voicemail</li>
                        <li>Click “announcement” to add your personalised voicemail greeting - just follow the onscreen prompts to choose an existing media recording, or create a new one”</li>
                        <li>Once you are happy with the media, you can then click on “email address” to set where you would like the audio files to be sent to.  The audio files can be listened to anywhere that you pick up that email inbox.</li>
                        <li>If you have VoIP users which you would like to subscribe to this email you can add then here too.  This will allow those voip users to dial 1571 from their voip extension to listen to the messages.</li>
                        <li>Your voicemail is now all set up!</li>
                        <li>Make sure that you add your voicemail to your call flow via the “call flow manager” which you can find on the dashboard. Voicemails are typically added to the end of the call flow - check out our call flow guide if you need any help with this.</li>
                    </ol>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes</li>
                        </a>
                        <a href="#" data-goto="voicemail-section-4">
                            <li>No, I still need help</li>
                        </a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="voicemail-section-2">
                    <p class="step-heading">Managing your voicemail messages</p>
                    <ol>
                        <li>Using the portal left-hand side bar, click on “voicemail messages”</li>
                        <img src="https://numberpeople.co.uk/wp-content/uploads/2021/05/voicemailbox.png">
                        <li>This will display your current voicemail boxes.  Click on the name of the voicemail box to expand and view the messages</li>
                        <li>Use the onscreen buttons to play, pause, download, mark as read or delete any message stored.</li>
                    </ol>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes, all working</li>
                        </a>
                        <a href="#" data-goto="voicemail-section-4">
                            <li>No, I still need help</li>
                        </a>
                    </ul>
                </div>
                <div class="troubleshooting-step" data-step="voicemail-section-3">
                    <p class="step-heading">Editing your voicemail box</p>
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2020/01/vmail1.png">
                    <ol>
                        <li>From the Main dashboard page, click on “Voicemail inboxes”</li>
                        <li>Select the voicemail you wish to make adjustments to and click “EDIT”</li>
                        <li>Here you can change the name, the media used for voicemail greeting, email address, voip subscribers and also delete the voicemail box</li>
                    </ol>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes, all working</li>
                        </a>
                        <a href="#" data-goto="voicemail-section-4">
                            <li>No, I still need help</li>
                        </a>
                    </ul>
                </div>
                <div class="troubleshooting-step" data-step="voicemail-section-4">
                    <p class="step-heading">Raise a Support Ticket</p>
                    <p>Thank you for taking the time to go through our troubleshooting section</p>
                    <p>If you have gone through all the ways to try and fix the issue, or can't find a solution to your problem then please use the link below to create a support ticket and our helpful team will get back in touch shortly to try to fix your issue</p>
                    <ul class="ts-question">
                        <a [href]="supportUrl" target="_blank">
                            <li>Raise a Ticket</li>
                        </a>
                    </ul>
                    <div class="start-again"><a class="start-again-button" href="#" data-goto="music-on-hold-start"><i class="fas fa-redo-alt" ></i>Start Again</a></div>
                </div>
                <div class="start-again" data-sections="voicemail-section-"><a class="start-again-button" href="#" data-goto="voicemail-start">Go back</a></div>
                
                <div class="troubleshooting-step step-1" data-step="call-recording-start">
                    <p class="step-heading">Call Recording</p>
                    <p class="questionHeading">What do you need help with?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="call-recording-section-1">
                            <li>Purchasing call recording</li>
                        </a>
                        <a href="#" data-goto="call-recording-section-2">
                            <li>How to set up call recording for inbound calls</li>
                        </a>
                        <a href="#" data-goto="call-recording-section-3">
                            <li>How to set up call recording for outbound calls</li>
                        </a>
                        <a href="#" data-goto="call-recording-section-4">
                            <li>How to listen to and manage your call recordings</li>
                        </a>
                        <a href="#" data-goto="call-recording-section-5">
                            <li>Something else</li>
                        </a>
                    </ul>
                    <a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt" ></i>Start Again</a>
                </div>
                <div class="troubleshooting-step" data-step="call-recording-section-1">
                    <p class="step-heading">Purchasing call recording</p>
                    <p>Call recording is purchased in blocks of 500MB storage space. So, if you need 1000MB of space you can purchase 2 x Call recording.
                        However, you can delete or download recordings to free up storage space.
                    </p>
                    <p>Call recording can be added to your account at any time. Once the service is active on your account, you then have to enable it on each telephone number and/or VoIP user that you want to record calls for.</p>
                    <p>To purchase Call Recording</p>
                    <ol>
                        <li>From the Main dashboard page go to SHOP</li>
                        <li>Scroll down to the item “Call Recording” and click buy</li>
                        <li>Then follow the onscreen prompts to checkout to make the purchase</li>
                    </ol>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes</li>
                        </a>
                        <a href="#" data-goto="call-recording-section-5">
                            <li>No, I still need help</li>
                        </a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="call-recording-section-2">
                    <p class="step-heading">Adding call recording to your incoming calls</p>
                    <p>Once you have purchased the call recording service, you then need to let us know which calls to record.</p>
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2021/05/mynumbers.png">
                    <ol>
                        <li>From your main dashboard page, click on “My Phone Numbers”</li>
                        <li>Then, scroll down to the number you wish to add call recording to and click “Manage my number”</li>
                        <li>You should then see the option for “Call recording”. Click on this to enable</li>
                        <li>A pop-up will then appear asking if you would like to enable call recording - Click OK to confirm</li>
                        <li>Call recording is then enabled on this number for your incoming calls</li>
                        <li>Repeat these steps for other numbers on your account if needed</li>
                    </ol>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes</li>
                        </a>
                        <a href="#" data-goto="call-recording-section-5">
                            <li>No, I still need help</li>
                        </a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="call-recording-section-3">
                    <p class="step-heading">Adding call recording to outbound calls</p>
                    <p>You can activate call recording on outbound calls by letting us know which VoIP users should have this service enabled.</p>
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2020/01/voip1-e1621336137608.png">
                    <ol>
                        <li>From the main dashboard page, click on “My VoIP Users”</li>
                        <li>Then choose which VoIP user to activate the service on and click “EDIT"</li>
                        <img src="https://numberpeople.co.uk/wp-content/uploads/2021/05/voiprecord.png">
                        <li>Then scroll down to “call recording” and click on it to enable</li>
                        <li>A pop-up will then ask you to confirm your selection - click OK</li>
                        <li>Call recording will then be enabled on this user</li>
                        <li>Repeat these steps to add call recordings to other Voip users if needed.</li>
                    </ol>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes</li>
                        </a>
                        <a href="#" data-goto="call-recording-section-4">
                            <li>No, I still need help</li>
                        </a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="call-recording-section-4">
                    <p class="step-heading">How to listen to and manage your call recordings</p>
                    <ol>
                        <li>From the dashboard click on “call recordings”</li>
                        <li>Here, you can filter the recordings, by type (inbound/outbound, and also search by date and/or telephone number</li>
                        <li>Once you have found the recording you need, you can press play to listen to the recording, click on download to save the recordings locally and delete if you don't want to keep it.</li>
                    </ol>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes</li>
                        </a>
                        <a href="#" data-goto="call-recording-section-4">
                            <li>No, I still need help</li>
                        </a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="call-recording-section-5">
                    <p class="step-heading">Raise a Support Ticket</p>
                    <p>Thank you for taking the time to go through our troubleshooting section</p>
                    <p>If you have gone through all the ways to try and fix the issue, or can't find a solution to your problem then please use the link below to create a support ticket and our helpful team will get back in touch shortly to try to fix your issue</p>
                    <ul class="ts-question">
                        <a [href]="supportUrl" target="_blank">
                            <li>Raise a Ticket</li>
                        </a>
                    </ul>
                    <div class="start-again"><a class="start-again-button" href="#" data-goto="music-on-hold-start"><i class="fas fa-redo-alt" ></i>Start Again</a></div>
                </div>
                <div class="start-again" data-sections="call-recording-section-"><a class="start-again-button" href="#" data-goto="call-recording-start">Go back</a></div>
                <div class="troubleshooting-step step-1" data-step="time-management-start">
                    <p class="step-heading">Call Recording</p>
                    <p class="questionHeading">What do you need help with?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="time-management-section-1">
                            <li>Set my daily opening hours</li>
                        </a>
                        <a href="#" data-goto="time-management-section-2">
                            <li>Set up a temporary closed / holiday period</li>
                        </a>
                        <a href="#" data-goto="time-management-section-3">
                            <li>Something else</li>
                        </a>
                    </ul>
                    <a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt" ></i>Start Again</a>
                </div>
                <div class="troubleshooting-step" data-step="time-management-section-1">
                    <p class="step-heading">Setting your opening hours</p>
                    <ol>
                        <img src="https://numberpeople.co.uk/wp-content/uploads/2021/05/timemanagement.png">
                        <li>From your dashboard home screen click on Time Management</li>
                        <li>Then Select the number you wish to manage</li>
                        <li>You will then need to set where calls should be routed when both open and closed.</li>
                        <li>Then select which days you want to be either open or closed. Note: To change from either open or closed, simply click the open or close button, this will toggle between each status.</li>
                        <li>We have pre-set opening hours to be 9am to 5pm, but change this as needed</li>
                        <li>Once you are happy with your change, please click “save”</li>
                    </ol>
                    <p>If you would prefer a PDF guide<a href="https://ipvn.s-ip.co.uk/timemanagement.pdf"><button>Click Here <i class="far fa-file-pdf"></i>
                    </button></a></p>
                    <p>To watch a video guide<a href="https://www.youtube.com/watch?v=gIKXLLsGJQE"><button>Click Here <i class="fab fa-youtube"></i>
                    </button></a></p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes</li>
                        </a>
                        <a href="#" data-goto="time-management-section-5">
                            <li>No, I still need help</li>
                        </a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="time-management-section-2">
                    <p class="step-heading">Here, we will show you how to put a temporary closure on your number.</p>
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2021/05/tempclose.png">
                    <ol>
                        <li>From your home dashboard click on Temporary close</li>
                        <li>Then, select the number you wish to manage</li>
                        <li>Then click on Add New Temporary Close Rule</li>
                        <li>You can then name your closed period - EG “Bank holiday” and then select the dates and times that you wish to close</li>
                        <li>The last thing you need to do is to select where your calls should go to when you closed and then click save.</li>
                    </ol>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes</li>
                        </a>
                        <a href="#" data-goto="time-management-section-5">
                            <li>No, I still need help</li>
                        </a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="time-management-section-3">
                    <p class="step-heading">Raise a Support Ticket</p>
                    <p>Thank you for taking the time to go through our troubleshooting section</p>
                    <p>If you have gone through all the ways to try and fix the issue, or can't find a solution to your problem then please use the link below to create a support ticket and our helpful team will get back in touch shortly to try to fix your issue</p>
                    <ul class="ts-question">
                        <a [href]="supportUrl" target="_blank">
                            <li>Raise a Ticket</li>
                        </a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="start-again" data-sections="time-management-section-"><a class="start-again-button" href="#" data-goto="time-management-start">Go back</a></div>
                <div class="troubleshooting-step step-1" data-step="call-bundle-start">
                    <p class="step-heading">Call Bundles</p>
                    <p class="questionHeading">What do you need help with?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="call-bundle-section-1">
                            <li>Adding an inbound (call forwarding) bundle</li>
                        </a>
                        <a href="#" data-goto="call-bundle-section-2">
                            <li>Adding an outbound bundle (to make calls)</li>
                        </a>
                        <a href="#" data-goto="call-bundle-section-3">
                            <li>What destinations are included in a bundle?</li>
                        </a>
                    </ul>
                    <a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt" ></i>Start Again</a>
                </div>
                <div class="troubleshooting-step" data-step="call-bundle-section-1">
                    <p class="step-heading">Adding an inbound (call forwarding) bundle</p>
                    <ol>
                        <img src="https://numberpeople.co.uk/wp-content/uploads/2021/05/inboundbundle.png">
                        <li>From your main dashboard page click on Inbound call bundle</li>
                        <li>Select the number you wish to add the bundle to</li>
                        <li>You can then type your forwarding destination into the red box to ensure that the bundle will cover this forwarding destination</li>
                        <li>You can then choose which bundle you would like to purchase</li>
                        <li>Click to confirm purchase and once that purchase has been confirmed your bundle will be ready to use immediately</li>
                    </ol>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes</li>
                        </a>
                        <a href="#" data-goto="call-bundle-section-4">
                            <li>No, I still need help</li>
                        </a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="call-bundle-section-2">
                    <p class="step-heading">Outbound bundles are added to a VoIP user and used to make outbound calls</p>
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2021/05/outboundbundle.png">
                    <ol>
                        <li>From your main dashboard click on Outbound Call Bundles</li>
                        <li>Select the VoIP user to which the bundle should be applied to</li>
                        <li>You can use the rate checker to checker whether a destination you would like to dial would be included in the outbound bundle</li>
                        <li>The available bundles will then be displayed - simply choose the one you would like to add</li>
                        <li>Click to confirm the purchase, as as soon as the purchase is complete the bundle will be ready to use immediately</li>
                    </ol>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes</li>
                        </a>
                        <a href="#" data-goto="call-bundle-section-4">
                            <li>No, I still need help</li>
                        </a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="call-bundle-section-3">
                    <p class="step-heading">Finding out which destinations are included in a bundle</p>
                    <p>Our bundles include minutes to all standard UK landline and UK mobiles. They also cover many international destinations. You can check whether a specific destination is covered in a bundle using either the rate checker on our website, or on the bundle section of the portal.</p>
                    <p>Please note: International rates can vary from day to day, so the rate checker information is accurate at the time of enquiry.</p>
                    <p><strong>Check via the website</strong></p>
                    <p>
                        Go to <a href="https://numberpeople.co.uk/call-forwarding-rates-bundles/">https://numberpeople.co.uk/call-forwarding-rates-bundles/</a> to check call forwarding bundles
                        <br>
                        Go to <a href="https://numberpeople.co.uk/outbound-rates-bundles/">https://numberpeople.co.uk/outbound-rates-bundles/</a> to check outbound call bundles
                    </p>
                    <p><strong>Check via the portal</strong></p>
                    <ol>
                        <li>From your main dashboard page select the type of bundle you want to check - Inbound call bundles or Outbound calls bundle</li>
                        <li>Select the bundle or voip user to which you are looking to apply the bundle to</li>
                        <li>Use the red Rate checker box to type in the destination you wish to check</li>
                        <li>Information will be displayed below to show which bundle covers that destination</li>
                    </ol>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes</li>
                        </a>
                        <a href="#" data-goto="call-bundle-section-4">
                            <li>No, I still need help</li>
                        </a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="call-bundle-section-4">
                    <p class="step-heading">Raise a Support Ticket</p>
                    <p>Thank you for taking the time to go through our troubleshooting section</p>
                    <p>If you have gone through all the ways to try and fix the issue, or can't find a solution to your problem then please use the link below to create a support ticket and our helpful team will get back in touch shortly to try to fix your issue</p>
                    <ul class="ts-question">
                        <a [href]="supportUrl" target="_blank">
                            <li>Raise a Ticket</li>
                        </a>
                    </ul>
                    <div class="start-again"><a class="start-again-button" href="#" data-goto="music-on-hold-start"><i class="fas fa-redo-alt" ></i>Start Again</a></div>
                </div>
                <div class="start-again" data-sections="call-bundle-section-"><a class="start-again-button" href="#" data-goto="call-bundle-start">Go back</a></div>
                <div class="troubleshooting-step step-1" data-step="music-on-hold-start">
                    <p class="step-heading">Music on hold</p>
                    <p>Music on hold can be used to promote your business when people are waiting to speak to you (replacing the ring, ring) and also also be used when you place callers on hold.</p>
                    <p>It opens up the door to start marketing or giving advice to your callers before they have even spoken to an agent.</p>
                    <p>You either use our own library of royalty free music to choose from, or of course you can upload your own media to play – this is all managed easily on your customer portal.</p>
                    <p class="questionHeading">What do you need help with?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="music-on-hold-section-1"><li>Purchasing Music on hold</li></a>
                        <a href="#" data-goto="music-on-hold-section-2"><li>Adding Music on hold to call forwarding groups</li></a>
                        <a href="#" data-goto="music-on-hold-section-3"><li>Adding music on hold to voip users</li></a>
                        <a href="#" data-goto="music-on-hold-section-3"><li>How can I browse music libraries / add my own media?</li></a>
                    </ul>
                    <a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt" ></i>Start Again</a>
                </div>
                <div class="troubleshooting-step" data-step="music-on-hold-section-1">
                    <p class="step-heading">Purchasing Music on hold</p>
                    <ol>
                        <img src="https://numberpeople.co.uk/wp-content/uploads/2021/05/shop.png">
                        <li>From portal dashboard, click on “Shop”</li>
                        <li>Scroll down to music on hold and click “buy”</li>
                        <li>Follow on-screen prompts to complete the purchase</li>
                        <li>You can then choose which bundle you would like to purchase</li>
                        <li>That's it, music on hold can now be added to any call forwarding group or any voip user</li>
                    </ol>
                    <ul class="ts-question">
                        <a href="#" data-goto="start"><li>Yes</li></a>
                        <a href="#" data-goto="music-on-hold-section-4"><li>No, I still need help</li></a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="music-on-hold-section-2">
                    <p class="step-heading">Adding Music on Hold to a call group</p>
                    <p>Music on hold can be added per call group, meaning you can have different things played to callers depending on the group you are forwarding to.</p>
                    <p>This means you can specifically target different groups of callers with different messages.  For example, for the sales group you can talk about additional products and for your support line you can suggest users check out your FAQ page etc.</p>
                    <p>The possibilities are huge</p>
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2020/01/whisper4.png">
                    <ol>
                        <li>Click on “Call Forwarding Groups” from dashboard homepage</li>
                        <li>Find the group you want to add music on hold to, and then click “edit”</li>
                        <li>Scroll down to Music on hold section and click to open the pop-up</li>
                        <img src="https://numberpeople.co.uk/wp-content/uploads/2021/05/moh.png">
                        <li>Use the dropdown section to add any existing media you have in your media library, or click “create new file” to create your own media to use</li>
                        <li>If you click to create you own, you can drag and drop, or upload your media file, then click “save”</li>
                        <li>That’s it your media is ready to use, if you want to add media to different groups, simply follow the same process.</li>
                    </ol>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start"><li>Yes</li></a>
                        <a href="#" data-goto="music-on-hold-section-4"><li>No, I still need help</li></a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="music-on-hold-section-3">
                    <p class="step-heading">Adding Music on hold to your voip user</p>
                    <p>Adding music on hold to your voip user will allow you to play the hold music when you place your callers on hold.</p>
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2020/01/voip1-e1621336137608.png">
                    <ol>
                        <li>From the portal dashboard click on “My VoIP Users”</li>
                        <li>Find the voip user and click on “edit”</li>
                        <li>Scroll down to music on hold and click to launch the pop-up</li>
                        <img src="https://numberpeople.co.uk/wp-content/uploads/2021/05/moh.png">
                        <li>Use the drop down section to add your existing media, or click “Create / upload new media” to upload your media</li>
                        <li>Once you have added media, click “save”</li>
                        <li>That’s it, your music on hold is ready to use. You can repeat this process if you have other voip users that you want to add music on hold facility to.</li>
                    </ol>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start"><li>Yes</li></a>
                        <a href="#" data-goto="music-on-hold-section-4"><li>No, I still need help</li></a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="music-on-hold-section-5">
                    <p class="step-heading">How to browse the music library</p>
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2020/01/whisper1.png">
                    <ol>
                        <li>From the dashboard, click on “my media”</li>
                        <img src="https://numberpeople.co.uk/wp-content/uploads/2021/05/mediatab.png">
                        <li>Using the tabs, click on “library”</li>
                        <li>Here you will find some royalty-free music that you can use on your account.  You can preview the music by clicking on the play button</li>
                        <li>If there are any samples here that you want to use, you can click on “Add to my music” This will then be added to your media section to use across your account.</li>
                        <li>Once it's been added to your music it will then be displayed within the drop-down sections when you add music on hold to your call groups or voip users</li>
                        <li>If you prefer to use your own music you can upload this by clicking on “upload my music” on either the library or my music tab</li>
                        <li>Your music is now ready to be used.</li>
                    </ol>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start"><li>Yes</li></a>
                        <a href="#" data-goto="music-on-hold-section-4"><li>No, I still need help</li></a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="music-on-hold-section-4">
                    <p class="step-heading">Raise a Support Ticket</p>
                    <p>Thank you for taking the time to go through our troubleshooting section</p>
                    <p>If you have gone through all the ways to try and fix the issue, or can't find a solution to your problem then please use the link below to create a support ticket and our helpful team will get back in touch shortly to try to fix your issue</p>
                    <ul class="ts-question">
                        <a [href]="supportUrl" target="_blank"><li>Raise a Ticket</li></a>
                    </ul>
                    <div class="start-again" data-sections="music-on-hold-section-"><a class="start-again-button" href="#" data-goto="music-on-hold-start"><i class="fas fa-redo-alt" ></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="whatsapp-start">
                    <p class="step-heading">Using your Virtual Number with WhatsApp Business</p>
                    <ol>
                        <li>Registering your virtual phone number with Whatsapp Business is simple to do once you have added credit to your account. As long as your account has been topped and is not in trial then you will be able to use the Whatsapp app to register your virtual phone number with the Whatsapp service. Use either our PDF or video guide below to see how to set this up.</li>
                        <p><a href="https://content.si-p.co.uk/pdf/Sign_Up_For_WhatsApp.pdf"><button>Click Here <i class="far fa-file-pdf"></i></button></a></p>
                    </ol>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start">
                            <li>Yes</li>
                        </a>
                        <a href="#" data-goto="whatsapp-section-2">
                            <li>No, I still need help</li>
                        </a>
                    </ul>
                    <div class="start-again">
                        <a href="#" data-goto="start" class="start-again-button">
                            <i class="fas fa-redo-alt"></i>
                            Start Again
                        </a>
                    </div>
                </div>
                <div class="troubleshooting-step" data-step="whatsapp-section-2">
                    <p class="step-heading">Raise a Support Ticket</p>
                    <p>Thank you for taking the time to go through our troubleshooting section</p>
                    <p>If you have gone through all the ways to try and fix the issue, or can't find a solution to your problem then please use the link below to create a support ticket and our helpful team will get back in touch shortly to try to fix your issue</p>
                    <ul class="ts-question">
                        <a [href]="supportUrl" target="_blank">
                            <li>Raise a Ticket</li>
                        </a>
                    </ul>
                    <div class="start-again">
                        <a class="start-again-button" href="#" data-goto="whatsapp-start"><i class="fas fa-redo-alt" ></i>Start Again</a>
                    </div>
                </div>
                <div class="start-again" data-sections="whatsapp-section-">
                    <a class="start-again-button" href="#" data-goto="whatsapp-start">Go back</a>
                </div>
                <div class="troubleshooting-step step-1" data-step="porting-start">
                    <p class="step-heading">Porting / moving your existing number to us</p>
                    <p>A number port is the process of moving a telephone number from one provider to another, a bit like when you move your mobile number from one network to another.</p>
                    <p>It allows you to enjoy services from any supplier without the inconvenience and cost of changing your telephone number when you move.</p>
                    <p>Porting your telephone number is simple. Start the number porting process by completing the number porting form online <a href="https://docs.google.com/forms/d/e/1FAIpQLSdlkuQOU1iMhomvjbfnCqhOdopFlfueEsaPs4eWxo5xAyMsWQ/viewform"><button>Number porting form</button></a></p>
                    <p><strong>What happens after I have completed the porting form?</strong><br>
                    Once submitted we will get straight on with the porting application and process this with the losing provider.</p>
                    <p>We keep you informed of the process of your porting request using our online ticketing system which automatically emails you updates on the number porting process.</p>
                    <p>Porting a number to our network normally takes around 14 days, but does depend on the speed of which the losing provider works.</p>
                    <p>Once the number porting process is complete, you will be able to see your telephone number on your account on our online portal, and be able to control your call routing and add extra features if needed.</p>
                    <p class="questionHeading">Has this resolved your issue?</p>
                    <ul class="ts-question">
                        <a href="#" data-goto="start"><li>Yes</li></a>
                        <a href="#" data-goto="porting-section-4"><li>No, I still need help</li></a>
                    </ul>
                    <div class="start-again"><a href="#" data-goto="start" class="start-again-button"><i class="fas fa-redo-alt"></i>Start Again</a></div>
                </div>
                <div class="troubleshooting-step" data-step="porting-section-4">
                    <p class="step-heading">Raise a Support Ticket</p>
                    <p>Thank you for taking the time to go through our troubleshooting section</p>
                    <p>If you have gone through all the ways to try and fix the issue, or can't find a solution to your problem then please use the link below to create a support ticket and our helpful team will get back in touch shortly to try to fix your issue</p>
                    <ul class="ts-question">
                        <a [href]="supportUrl" target="_blank"><li>Raise a Ticket</li></a>
                    </ul>
                    <div class="start-again"><a class="start-again-button" href="#" data-goto="music-on-hold-start"><i class="fas fa-redo-alt" ></i>Start Again</a></div>
                </div>
                <div class="start-again" data-sections="porting-section-"><a class="start-again-button" href="#" data-goto="porting-start">Go Back</a></div>
            </div>
        </div>
    </div>
</div>
