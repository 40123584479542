<app-modal title="Notifications Preferences" (save)="save()" #noticications_modal>
    <ng-container *ngIf="preferences else loading">
        <h3>Message Delivery</h3>
<!--         <div class="field">
            <label>
                Receive messages through SMS
                &nbsp;
                <input type="checkbox" [(ngModel)]="+preferences.smsEnabled">
            </label>
        </div> -->
        <!-- <div class="field">
            <label>
                Receive messages through Email
                &nbsp;
                <input type="checkbox" [(ngModel)]="+preferences.emailEnabled">
            </label>
        </div>
        <h3>Message Lists</h3>
        <div class="field">
            <label>
                Receive messages about Billing
                &nbsp;
                <input type="checkbox" [(ngModel)]="+preferences.subscribeBilling">
            </label>
        </div>
        <div class="field">
            <label>
                Receive messages about Services
                &nbsp;
                <input type="checkbox" [(ngModel)]="+preferences.subscribeServices">
            </label>
        </div> -->

        <div class="field">
            <label>
                Receive messages about Billing
                &nbsp;
                <input type="checkbox" [(ngModel)]="+preferences.subscribe_billing_emails" (change)="preferences.subscribe_billing_emails = +preferences.subscribe_billing_emails">
            </label>
        </div>
        
    </ng-container>
</app-modal>

<ng-template #loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
