<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Voicemail</h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <p>Click on voicemail to show the contents</p>
            </div>

            <div class="table-responsive">
                <div class="accordion" id="voicemail-accordion" *ngIf="voicemails !== {} else no_voicemails">
                    <ng-container *ngFor="let extension of voicemails | keyvalue">
                        <div class="card">
                            <div class="card-header" id="extension-{{ extension.key }}-voicemails">
                                <h2 class="mb-0">
                                    <button class="btn btn-primary" type="button" data-toggle="collapse" [attr.data-target]="'#collapse-' + extension.key +'-voicemails'" aria-expanded="true" aria-controls="collapseOne">
                                        <ng-container *ngFor="let box of boxes">
                                            <ng-container *ngIf="box.extension_number == extension.key">
                                                {{ box.object_data.name }}
                                            </ng-container>
                                        </ng-container>
                                        ({{ extension.key }})
                                    </button>
                                </h2>
                                <ng-container *ngFor="let box of boxes">
                                    <ng-container *ngIf="box.extension_number == extension.key">
                                        <a routerLink="/voicemails/{{ box.uuid }}">(edit voicemail inbox)</a>
                                    </ng-container>
                                </ng-container>
                                
                                <br>
                                <div class="progress" style="height: 2em">
                                    <div class="progress-bar" 
                                         role="progressbar"
                                         [attr.aria-valuenow]="capacity[extension.key]?.current_voicemails"
                                         aria-valuemin="0"
                                         [attr.aria-valuemax]="capacity[extension.key]?.max_voicemails"
                                         [ngStyle]="{'width': ((capacity[extension.key]?.current_voicemails * 100) / capacity[extension.key]?.max_voicemails) + '%'}"
                                    >
                                        {{ capacity[extension.key]?.current_voicemails }} / {{ capacity[extension.key]?.max_voicemails }}
                                    </div>
                                </div>
                            </div>

                            <div [id]="'collapse-' + extension.key +'-voicemails'" class="collapse" [attr.aria-labelledby]="'extension-' + extension.key +'-voicemails'" data-parent="#voicemail-accordion">
                                <div class="card-body">
                                    <table class="table" style="table-layout: fixed; width: 69vw;">
                                        <thead>
                                            <tr>
                                                <th class="col">#</th>
                                                <th class="col">&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let voicemail of extension.value.all">
                                                <td style="word-wrap: break-word">
                                                    <audio *ngIf="voicemail.loaded" id="voicemail-audio-controller-{{ voicemail.origmailbox }}-{{ voicemail.age }}-{{ voicemail.id }}" controls controlsList="nodownload"></audio>
                                                    <button class="btn btn-success" *ngIf="!voicemail.loaded" (click)="getSound(voicemail)">Play</button>
                                                    <br>
                                                    <span class="text-sm text-muted">
                                                        <span class="badge badge-success" *ngIf="voicemail.age === 'new'">New</span>
                                                        A {{ voicemail.duration }} second call from <strong>{{ voicemail.callerid }}</strong>
                                                        <br>
                                                        on <em>{{ (voicemail.origtime * 1000)|date:'medium' }}</em>
                                                    </span>
                                                </td>
                                                <td>
                                                    <button class="btn btn-primary btn-sm" (click)="download(voicemail)" target="_blank" title="Download">
                                                        <i class="fas fa-download"></i>
                                                    </button>
                                                    <button class="btn btn-warning btn-sm" *ngIf="voicemail.age === 'new'" (click)="markAsRead(voicemail)">Mark as Read</button>
                                                    <button class="btn btn-warning btn-sm" *ngIf="voicemail.age === 'old'" (click)="markAsUnread(voicemail)">Mark as Unread</button>
                                                    <button class="btn btn-danger btn-sm" (click)="delete(voicemail)" title="Delete">
                                                        <i class="far fa-trash-alt"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr *ngIf="extension.value.all === 0">
                                                <td colspan="2">No voicemails</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colspan="2">
                                                    <div class="field is-grouped">
                                                        <button class="btn btn-danger is-link" (click)="deleteAll(extension.key)">Delete all</button>
                                                        <button class="btn btn-danger is-danger" (click)="deleteAllRead(extension.key)">Delete all read</button>
                                                        <button class="btn btn-danger is-text" (click)="deleteAllUnread(extension.key)">Delete all unread</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="card-footer">
            </div>
        </div>
    </div>
</div>

<ng-template #no_voicemails>
    <tbody class="tbody">
        <tr class="tr">
            <td class="td" colspan="3">
                <span *ngIf="accounts else is_loading">No Voicemails Found</span>
            </td>
        </tr>
    </tbody>
</ng-template>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
