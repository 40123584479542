import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

declare var moment: any;

@Pipe({name: 'format_local_date'})
export class FormatLocalDatePipe implements PipeTransform {

    transform(value: any) {
        return moment(value).format('LL');
    }
}
