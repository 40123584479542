import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { LoginService } from '../../login/login.service';
import { ServicesService } from '../../services/services.service';
import { MediaService } from '../media.service';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-portal-music',
    templateUrl: './portal-music.component.html',
    styleUrls: ['./portal-music.component.css']
})
export class PortalMusicComponent implements OnInit {

    account: any;
    account_id: number;

    hasMusicOnHold = false;
    service;

    @ViewChild('parent') parent;

    constructor(
        private route: ActivatedRoute,
        private authService: LoginService,
        private servicesService: ServicesService,
        private mediaService: MediaService,
        private router: Router
    ) { }

    ngOnInit() {
        // if (!this.parent.hasPermission()) {
        //     return false;
        // }

        this.account_id = +this.route.snapshot.paramMap.get('id');
        this.getAccount();
    }

    getAccount() {
        let request;
        if (this.account_id) {
            request = this.authService.getAccount(this.account_id);
        } else {
            request = this.authService.getCurrentAccount();
        }
        request.subscribe(account => {
            this.account = account;
            this.getMusicOnHoldService();

        });
    }

    getMusicOnHoldService() {
        this.servicesService.getServicesForAccount(this.account.id)
        .subscribe(services => {
            this.hasMusicOnHold = !!services.filter(x => x.service.switchboard_type === 'MusicOnHold').length;
            if (!this.hasMusicOnHold) {
                this.servicesService.getAvailableServices(this.account.id, 'MusicOnHold')
                .subscribe(available_services => {
                    this.service = false;
                    if (available_services.length) {
                        this.service = available_services[0];
                    }
                });
            }
        });
    }

    purchase() {
        const price = (this.service.retail_cost / 100);
        alertify.confirm(`Are you sure you want to purchase Music On Hold for £${price} per month?`, () => {
            this.servicesService.addServiceToAccount(this.account.id, this.service.id)
            .subscribe(done => {
                this.servicesService.refresh();
                this.getAccount();
            });
        });
    }

}
