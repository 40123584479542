import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { ServicesService } from '../services/services.service';

import { SubService } from './subservice';

@Component({
    selector: 'app-subservices',
    templateUrl: './subservices.component.html',
    styleUrls: ['./subservices.component.css']
})
export class SubServicesComponent implements OnInit {

    switchboardTypes: string[];
    subservices: SubService[];
    newSubService: SubService = new SubService();

    @ViewChild('subserviceDetail') subserviceModal;

    constructor(
        private servicesService: ServicesService,
        private router: Router
    ) { }

    ngOnInit() {
        this.servicesService.getSwitchboardTypes()
            .subscribe(types => this.switchboardTypes = types);
        this.servicesService.getSubServices()
            .subscribe(subservices => this.subservices = subservices);
    }

    createSubService() {
        this.newSubService = new SubService();
        this.subserviceModal.open();
    }

    saveSubService() {
        const subservice = { ...this.newSubService };
        subservice.retail_cost *= 100;
        this.servicesService.createSubService(subservice)
        .subscribe(subservice => {
            if (subservice.id) {
                this.subserviceModal.close();
                this.router.navigate([`/subservices/detail/${subservice.id}`]);
            } else {
                this.subserviceModal.stopLoading();
            }
        });
    }

}
