import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

@Pipe({name: 'format_balance'})
export class FormatBalancePipe implements PipeTransform {

    transform(balance: number) {
        const sign = balance < 0 ? '-' : '';
        balance = Math.abs(+balance);
        const pencePerPound = 100;
        const pounds = Math.floor(balance / pencePerPound);
        let pence = (balance % pencePerPound) + '';

        if (isNaN(pounds)) {
            return '';
        }

        if (pence.split('.')[0].length === 1) {
            pence = '0' + pence;
        }
        pence = pence.replace('.', '');
        pence = pence.substr(0, 2);

        return `${sign}${pounds}.${pence}`;
    }
}
