import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { LoginService } from '../../../login/login.service';
import { SwitchboardService } from '../../../switchboard/switchboard.service';

declare var toastr: any;
declare var alertify: any;

@Component({
  selector: 'app-portal-settings',
  templateUrl: './portal-settings.component.html',
  styleUrls: ['./portal-settings.component.css']
})
export class PortalSettingsComponent implements OnInit, OnDestroy {

    cancelledState = 5;

    loading = false;

    app;
    account;
    new_name;

    switchboard_config: any;

    survey_link;

    @ViewChild('rename_modal') renameModal;
    @ViewChild('cancelled_modal') cancelledModal;

    constructor(
        private authService: LoginService,
        private switchboardService: SwitchboardService,
    ) { }

    ngOnInit() {
        this.getAccount();
    }

    ngOnDestroy() {
        alertify.confirm().destroy();
    }

    getAccount() {
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account = account;
            this.authService.getCurrentApp()
            .subscribe(app => {
                this.app = app;
                this.survey_link = "https://docs.google.com/forms/d/e/1FAIpQLScx7H6nnQwiFAek7DALUA6MhhwtVNJkuijzOaW-YZS0w1grMg/viewform?usp=pp_url&entry.216801417=PRODUCTNAMEREPLACE&entry.1583603956=ACCOUNTNUMBERREPLACE&entry.2113227947=COMPANYNAMEREPLACE&entry.643970997=CUSTOMERNAMEREPLACE";
                this.survey_link = this.survey_link.replace('ACCOUNTNUMBERREPLACE', this.account.id + 1000000);
                this.survey_link = this.survey_link.replace('PRODUCTNAMEREPLACE', this.app.name);
                this.survey_link = this.survey_link.replace('COMPANYNAMEREPLACE', this.account.name);
                this.authService.getCurrentUser()
                .subscribe(user => {
                    this.survey_link = this.survey_link.replace('CUSTOMERNAMEREPLACE', `${user.first_name} ${user.last_name}`);

                });
                this.switchboardService.getSwitchboardConfig(this.account.id)
                    .subscribe(config => this.switchboard_config = config);
            });
        });
    }

    rename() {
        this.new_name = this.account.company_name;
        this.renameModal.open();
    }

    saveName() {
        this.authService.updateAccount({
            id: this.account.id,
            company_name: this.new_name,
        }).subscribe(response => {
            if (response.id) {
                toastr.success(`Company Renamed!`);
                this.account.company_name = this.new_name;
                this.renameModal.close();
            } else {
                this.renameModal.stopLoading();
            }
        });
    }

    cancelAccount() {
        var win = window.open(this.survey_link, '_blank');
        win.focus();

        const cancelMessage = "This will cancel your account and phone number - you will have 60 days before the account is permanently deleted to retrieve payment receipts etc.  If you wish to make the account live again in this time then you can top up the account or raise a support ticket - Are you sure you wish to cancel your account?";
        alertify.confirm(cancelMessage, () => {
            this.loading = true;
            this.authService.updateAccount({
                id: this.account.id,
                active_state: this.cancelledState,
            }).subscribe(response => {
                this.loading = false;
                if (response.id) {
                    toastr.success(`Account Cancelled!`);
                    this.authService.resetCache();
                    this.cancelledModal.open();
                }
            });
            // this.cancelledModal.open();
        }).set('labels', {
            ok: 'Yes I want to delete my account!',
            cancel: 'No, I have changed my mind',
        });
    }

    toggleUntrustedCLI() {
        const options = {
            field_name: 'Show callers ID on call forwarding',
            confirm_message: (_direction, _options) => `Are you sure you want to ${_direction} ${_options.field_name}?`,
            success_message: (_direction, _options) => `${_options.field_name} ${_direction}d!`,
        };
        const fieldValue = this.switchboard_config.allow_untrusted_uk_divert_cli;
        const toggle = +!fieldValue;
        const direction = toggle ? 'enable' : 'disable';


        alertify.confirm(options.confirm_message(direction, options), () => {
            this.switchboardService.updateSwitchboardConfig(this.account.id, {
                allow_untrusted_uk_divert_cli : toggle,
            }).subscribe(response => {
                toastr.success(options.success_message(direction, options));
                this.switchboard_config.allow_untrusted_uk_divert_cli = toggle;
            });
        });
    }

}
