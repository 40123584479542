export class Rate {
    id: number;
    name: string;
    prefix: number;
    retail_cost_per_minute: number;
    retail_connection_charge: number;
    wholesale_cost_per_minute: number;
    wholesale_connection_charge: number;
    shortcode: string;
    source: string;
}
