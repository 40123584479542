import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../environments/environment';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Client } from './tools/client';

function downloadAsCSV(csvdata, fileName) {
    const blob = new Blob([csvdata], { type: 'text/csv'});
    const url = window.URL.createObjectURL(blob);
    const link = window.document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
}

@Injectable({
    providedIn: 'root'
})
export class CdrService extends Client {

    protected serviceURL = environment.services.cdr;

    // NOTE: I don't think this is used
    readCDR(direction, record) {
        return this.callApi('get', `/reports/cdr/${direction}/${record}`)
        .pipe(
            tap(_ => this.log(`fetching ${direction} cdr ${record}`)),
            catchError(this.handleError('readCDR', []))
        );
    }

    readCDRsForAccount(direction, account, data = {}) {
        const path = `/accounts/${account}/reports/cdr/${direction}`;

        if (data['download']) {
            return this.downloadResourcePath(path, data)
            .subscribe(csv => {
                downloadAsCSV(csv, `callstats-${direction}-${account}.csv`);
            });
        }

        return this.callApi('get', path, data)
        .pipe(
            tap(_ => this.log(`fetching ${direction} cdrs for account ${account}`)),
            catchError(this.handleError('readCDRsForAccount', []))
        );
    }

    readCDRsForAccountExtension(direction, account, extension, data = {}) {
        const path = `/accounts/${account}/reports/cdr/${direction}`;

        const param = direction === 'outbound' ? 'from_extension' : 'recv_number';
        data[param] = extension;

        if (data['download']) {
            return this.downloadResourcePath(path, data)
            .subscribe(csv => {
                downloadAsCSV(csv, `callstats-${direction}-${account}-${extension}.csv`);
            });
        }

        return this.callApi('get', path, data)
        .pipe(
            tap(_ => this.log(`fetching ${direction} cdrs for account ${account}`)),
            catchError(this.handleError('readCDRsForAccountExtension', []))
        );
    }

    getCDRCounts(account, direction, number = null, from = null, to = null) {
        let data = {};

        if (from && to) {
            data['start_time_range_start'] = `${from.year}-${from.month}-${from.day}`;
            data['start_time_range_end'] = `${to.year}-${to.month}-${to.day}`;
        }

        if (number) {
            const param = direction === 'outbound' ? 'from_extension' : 'recv_number';
            data[param] = number;
        }

        const path  = `/accounts/${account}/reports/cdr/${direction}/count`;
        return this.callApi('get', path, data)
        .pipe(
            tap(_ => this.log(`fetching ${direction} cdr counts for account ${account}`)),
            catchError(this.handleError('getCDRCounts', []))
        );
    }

    readCDRsByExtension(account_id, invoice_uuid) {
        return this.callApi('get', `/accounts/${account_id}/reports/cdr/invoice/${invoice_uuid}`)
        .pipe(
            tap(_ => this.log(`fetching cdrs by extension for ${invoice_uuid}`)),
            catchError(this.handleError('readCDRsByExtension', []))
        );
    }

    /* Admin CDRs */

    readCDRs(direction, data = {}) {
        const path = `/reports/cdr/${direction}/search`;

        if (data['download']) {
            return this.downloadResourcePath(path, data)
            .subscribe(csv => {
                downloadAsCSV(csv, `cdrs-${direction}.csv`);
            });
        }

        return this.callApi('get', path, data)
        .pipe(
            tap(_ => this.log(`fetching ${direction} cdrs`)),
            catchError(this.handleError('readCDRs', []))
        );
    }

    getDuplicateDestinations(direction) {
        return this.callApi('get', `/reports/cdr/${direction}/duplicates`)
        .pipe(
            tap(_ => this.log(`fetching ${direction} duplicate destinations`)),
            catchError(this.handleError('getDuplicateDestinations', []))
        );
    }

    getDuplicateDestinationsDetail(direction, destination) {
        return this.callApi('get', `/reports/cdr/${direction}/duplicates/${destination}`)
        .pipe(
            tap(_ => this.log(`fetching ${direction}  duplicate destination details for ${destination}`)),
            catchError(this.handleError('getDuplicateDestinationsDetail', []))
        );
    }

    /* Duplicate Destination Ignore List */

    getIgnoreList(page) {
        return this.callApi('get', `/reports/cdr/ignored_clis`, {
            page: page,
        })
        .pipe(
            tap(_ => this.log(`fetching ignored CLIs`)),
            catchError(this.handleError('getIgnoreList', []))
        );
    }

    addToIgnoreList(cli) {
        return this.callApi('post', `/reports/cdr/ignored_clis/${cli}`).pipe(
            tap(_ => this.log(`add ${cli} to ignored CLIs`)),
            catchError(this.handleError('addToIgnoreList', []))
        );
    }

    removeFromIgnoreList(cli) {
        return this.callApi('delete', `/reports/cdr/ignored_clis/${cli}`)
        .pipe(
            tap(_ => this.log(`remove ${cli} from ignored CLIs`)),
            catchError(this.handleError('removeFromIgnoreList', []))
        );
    }

    downloadCDRCSV(account_id, invoice) {
        const path = `/accounts/${account_id}/reports/cdr/invoice/${invoice.uuid}/csv_download`;

        return this.downloadResourcePath(path, {})
        .subscribe(csv => {
            downloadAsCSV(csv, `callstats-${invoice.id}-${invoice.created_at}.csv`);
        });
    }
}
