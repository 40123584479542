<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Previous KYC</h2>
                    </div>
                </div>
            </div>
            <div class="table-responsive" *ngIf="old_kyc && old_kyc.length > 0; else noDataTemplate">
                <table class="table align-items-center table-flush table-float-header table-striped">
                    <thead>
                        <tr>
                            <th>Company Name</th>
                            <th>Trading Name</th>
                            <th>Industry</th>
                            <th>Company Number</th>
                            <th>Office Address</th>
                            <th>City</th>
                            <th>State/Province</th>
                            <th>Postal Code</th>
                            <th>Country</th>
                            <th>Phone Numbers</th>
                            <th>Website</th>
                            <th>Senior Manager</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Denied At</th>
                            <th>Company Info</th>
                            <th>Intended Use</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of old_kyc">
                            <td>{{ item.company_name }}</td>
                            <td>{{ item.trading_name || 'Not Entered' }}</td>
                            <td>{{ item.industry }}</td>
                            <td>{{ item.company_number || 'Not Entered' }}</td>
                            <td>{{ item.office_address_line_1 }} {{ item.office_address_line_2 }}</td>
                            <td>{{ item.office_city }}</td>
                            <td>{{ item.office_state_province }}</td>
                            <td>{{ item.office_postal_code }}</td>
                            <td>{{ item.office_country }}</td>
                            <td>{{ item.existing_phone_numbers }}</td>
                            <td>{{ item.existing_websites || 'Not Entered' }}</td>
                            <td>{{ item.senior_manager_name }}</td>
                            <td>{{ item.senior_manager_email }}</td>
                            <td>{{ item.senior_manager_phone }}</td>
                            <td>{{ item.verified_at }}</td>
                            <td>{{ item.company_info }}</td>
                            <td>{{ item.intended_use }}</td>
                        </tr>
                    </tbody>
                </table>                
            </div>
        </div>
    </div>
</div>

<ng-template #noDataTemplate>
    <p>No KYC data available.</p>
</ng-template> 
