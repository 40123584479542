<app-modal title="Contracts for {{ switchboard_object?.extension_number }} - {{ switchboard_object?.object_data.name }}" size="big" #historicalContractModal>
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th>Contract</th>
                    <th>Device</th>
                    <th>Started At</th>
                    <th>Cancelled At</th>
                </tr>
            </thead>
            <tbody *ngIf="contracts?.data?.length else no_contracts">
                <tr *ngFor="let contract of contracts.data">
                    <td>
                        {{ contract.sip_contract_type.name }}
                    </td>
                    <td>
                        <ng-container *ngIf="contract.sip_device_id && contract.sip_device">
                            {{ contract.sip_device?.device_manufacturer }} {{ contract.sip_device?.device_model }} ({{ contract.sip_device?.mac_code }})
                        </ng-container>
                    </td>
                    <td>
                        {{ contract.started_at|date:'mediumDate' }}
                    </td>
                    <td>
                        {{ contract.cancelled_at|date:'mediumDate' }}
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="5">
                        <app-pagination [pagination]="contracts" [(page)]="page" (page_change)="changePage($event)"></app-pagination>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>

    <ng-template #no_contracts>
        <tr>
            <td colspan="4" *ngIf="contracts">
                No contracts found
            </td>
            <td colspan="4" *ngIf="!contracts">
                <app-loadingbar></app-loadingbar>
            </td>
        </tr>
    </ng-template>
</app-modal>
