<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Numbers</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <ng-container *appViperBrandIs="['np']">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdlkuQOU1iMhomvjbfnCqhOdopFlfueEsaPs4eWxo5xAyMsWQ/viewform" target="_blank">
                        <div class="alert alert-info" style="text-align:center;width:40%;padding:1%;float:right;margin-bottom:0;">
                            <h2 style="color:white;">Port in a Number</h2>
                            <span>Move an existing number to us</span>
                        </div>
                    </a>
                </ng-container>
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Numbers</h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <ng-container *ngTemplateOutlet="list_numbers"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #list_numbers>
    <p>Want to greet callers with a personalised menu or announcement?  Just click <strong>Edit</strong> against the number and then <strong>Set Call Forwarding</strong> to your chosen <strong>Menu</strong>, <strong>Voicemail</strong>, <strong>Announcement</strong> etc</p>


    <div class="alert alert-danger" *ngIf="fragment === '999'">
        <h4>Registering your number for 999 services</h4>
        <p>Before you can use a number as an outbound caller ID (CLI) you must complete the 999 registration for that number. Any numbers without a completed 999 registration are not made available for use with VoIP outbound calls</p>
        <p>Please select the phone number to register, select EDIT then 999 registration</p>
    </div>

    <h3 *ngIf="numbers !== null"> You have {{ numbers?.length || 0 }} {{ numbers?.length === 1 ? 'number' : 'numbers' }}</h3>

    <a class="btn btn-lg btn-block btn-outline-success text-lg" routerLink="/numbers/new" *appViperHasUserRole="'Account Owner'">
        <i class="fas fa-plus "></i>
        Add New Number
    </a>
    <br>

    <ng-container *ngIf="numbers?.length else no_switchboard_objects">
        <div class="card custom-card" *ngFor="let number of numbers; let i = index">
            <div class="card-body darkcard">
                <div class="row">
                    <div class="col">
                        <h2 class="whitetext">
                            <span class="flag-icon flag-icon-{{ (number.object_data.e164_number|number_to_country_code).toLowerCase() }}" [title]="number.object_data.e164_number|number_to_country_prefix"></span>
                            

                            {{ number.object_data.e164_number|number_to_local }}
                            -
                            <span class="text-muted text-sm">{{ number.object_data.name }}</span>
                            &nbsp;
                            <span class="badge badge-pill badge-contrast badge-success" *ngIf="services[number.service_instance_id]|has_expired">Expired</span>
                        </h2>
                    </div>
                    <div class="col">
                        <a class="btn btn-primary editbutton" routerLink="/numbers/{{ number.uuid }}">Manage my Number</a>
                    </div>
                </div>
                <app-list-subservices-partial [service]="services[number.service_instance_id]" [switchboard_object]="number"></app-list-subservices-partial>
                <div class="row">
                    <!-- calls this month -->
                </div>
<!--                     <div class="row">
                        <div class="col text-right text-muted text-sm d-none d-lg-block">
                            <strong>Last Edited: </strong> 
                            <span class="datetime">{{ (number.updated_at)|date:'medium' }}</span>
                        </div>
                    </div> -->

                </div>

                <i class="fas fa-arrow-down" style="text-align:center;color:#0e3852;font-size:2em;margin-top:1%"></i>
                <span class="forward-text">Currently forwards to</span>
                            

            <div class="card-footer custom-footer">
                <div class="row">
                    <div class="col" *ngIf="getSwitchboardObject(number.object_data.switchboard_destination_uuid) as destination else no_destination">
                        <app-forwarding-to-partial [has-rules]="number.has_rules" [destination]="destination" show-edit="true"></app-forwarding-to-partial>
                    </div>
                    <ng-template #no_destination>
                        <div class="col">
                            <span class="text-muted">No Destination Set</span>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #no_switchboard_objects>
    <div class="card-body">
        <p *ngIf="numbers?.length === 0 else loading">No Numbers Found</p>
    </div>
</ng-template>

<ng-template #loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
