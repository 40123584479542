import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from '../login/authentication.guard';
import { RoleGuard } from '../sections/roles/role.guard';

import { EventLogComponent } from './event-log/event-log.component';
import { AdminKycVerifyComponent } from './admin-kyc-verify/admin-kyc-verify.component';
import { AdminHistoricKycComponent } from './admin-historic-kyc/admin-historic-kyc.component';

const routes: Routes = [
    { path: 'event-log', canActivate: [AuthenticationGuard, RoleGuard], component: EventLogComponent, data: {roles: ['Admin'], data: {title: 'Event Log'}} },
    { path: 'event-log/:page', canActivate: [AuthenticationGuard, RoleGuard], component: EventLogComponent, data: {roles: ['Admin'], data: {title: 'Event Log'}} },
    { path: 'admin-kyc', canActivate: [AuthenticationGuard, RoleGuard], component: AdminKycVerifyComponent, data: {roles: ['Admin'], data: {title: 'Know Your Customer Verify'}} },
    { path: 'admin-historic-kyc', canActivate: [AuthenticationGuard, RoleGuard], component: AdminHistoricKycComponent, data: {roles: ['Admin'], data: {title: 'Historic KYC'}} },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AdminRoutingModule { }
