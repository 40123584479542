import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { KnowYourCustomerService } from '../know-your-customer.service';

declare var toastr: any;

@Component({
    selector: 'app-admin-kyc-verify',
    templateUrl: './admin-kyc-verify.component.html',
    styleUrls: ['./admin-kyc-verify.component.css']
})
export class AdminKycVerifyComponent implements OnInit {

    kycs;
    page = 1;

    constructor(
        private kycService: KnowYourCustomerService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.loadKYCs();
    }

    loadKYCs() {
        this.kycService.getAdminKYC(this.page)
            .subscribe(kycs => {
                this.kycs = {
                    ...kycs,
                    data: kycs.data.sort((a, b) => b.account_id - a.account_id)
                };
            });
    }
    

    changePage(page) {
        this.page =  page;
        this.loadKYCs();
    }

    verify(kyc) {
        this.kycService.verifyKYC(kyc.account_id)
        .subscribe(() => {
            toastr.success(`KYC Details Accepted!`);
            this.page = 1;
            this.loadKYCs();
        });
    }

    hold(kyc) {
        this.kycService.holdKYC(kyc.account_id)
        .subscribe(() => {
            toastr.success(`KYC Details Requires Update`);
            this.page = 1;
            this.loadKYCs();
        });
    }

    deny(kyc) {
        this.kycService.deleteKYC(kyc.account_id)
        .subscribe(() => {
            toastr.info(`KYC Details Rejected`);
            this.page = 1;
            this.loadKYCs();
        });
    }

}
