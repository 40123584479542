<div class="table-responsive table-striped">
    <table class="table">
        <thead class="thead">
            <tr class="tr darkcard">
                <th class="th" colspan="5"><button class="btn btn-primary is-primary" (click)="newUser()">New User</button>
                </th>
            </tr>
        </thead>
        <tbody class="tbody">
            <ng-container *ngIf="users?.length else no_users">
                <tr class="tr" *ngFor="let user of users">
                    <td class="td">
                        <img ngxGravatar [email]="user.email" fallback="mm" alt="User Gravatar">
                        <br>
                        {{ user.first_name }} {{ user.last_name }}
                        <br>
                        <span class="text-sm-center text-muted">({{ user.email }})</span>
                        <ng-container *ngIf="user.verified_at === null">
                            <br>
                            <span class="text-danger">User Unverified</span>
                            <br>
                            <button class="btn btn-sm btn-danger" (click)="resendVerification(user)">Resend Verification Email</button>
                            <br>
                            <button class="btn btn-sm btn-success" (click)="forceVerification(user)">Force Verification</button>
                        </ng-container>
                    </td>
                    <td class="td">
                        <ng-container *ngFor="let role of user.roles">
                            <span class="badge badge-pill badge-contrast">{{ role.name }}</span><br>
                        </ng-container>
                        <span class="badge badge-pill badge-success" *ngIf="user.roles.length === 0">Basic User</span>
                    </td>
                    <td *appViperHasUserRole="'Admin'">
                        <button class="btn btn-secondary btn-sm is-link" title="Email Logs" (click)="viewLogs(user)">
                            <i class="fas fa-receipt"></i>
                            <!-- Email Logs -->
                        </button>
                        <button class="btn btn-secondary btn-sm is-link" title="Keys" (click)="keys_component.open(user)">
                            <i class="fas fa-key"></i>
                            <!-- Keys -->
                        </button>
                    </td>
                    <td class="td">
                        <button class="btn btn-secondary btn-sm is-link" title="Notification Settings" (click)="notifications_component.open(user)">
                            <i class="far fa-bell"></i>
                            <!-- Notification<br>Settings -->
                        </button>
                        <button class="btn btn-secondary btn-sm is-link" title="Roles" (click)="editRoles.manageRoles(user)">
                            <i class="fas fa-user-tag"></i>
                            <!-- Roles -->
                        </button>
                        <button class="btn btn-secondary is-link" (click)="editUser(user)">
                            Edit
                        </button>
                    </td>
                    <td class="td">
                        <button class="btn btn-danger is-danger" (click)="deleteUser(user)">Delete</button>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>

<ng-template #no_users>
    <tr class="tr">
        <td class="td" colspan="4" *ngIf="users else is_loading">No users found</td>
    </tr>
</ng-template>

<app-modal (save)="saveUser()" #userDetail>
    <div class="field">
        <label class="label">First Name</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="First Name" [(ngModel)]="currentUser.first_name">
        </div>
    </div>
    <div class="field">
        <label class="label">Surname</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Surname" [(ngModel)]="currentUser.last_name">
        </div>
    </div>
    <div class="field">
        <label class="label">Email</label>
        <div class="control">
            <input class="input form-control" type="email" placeholder="email@website.com" [(ngModel)]="currentUser.email">
        </div>
    </div>
    <div class="field">
        <label class="label">Password</label>
    </div>
    <div class="field is-grouped">
        <div class="control">
            <input class="input form-control" type="password" [(ngModel)]="currentUser.password">
        </div>
        <app-password-generator *appViperHasUserRole="'Admin'" (generate)="currentUser.password = $event;currentUser.password_confirmation = $event"></app-password-generator>
    </div>
    <div class="field">
        <label class="label">Password Confirmation</label>
        <div class="control">
            <input class="input form-control" type="password" [(ngModel)]="currentUser.password_confirmation">
        </div>
    </div>

    <div class="field">
        <label for="" class="label">VoIP User</label>
        <div class="control">
            <select name="" id="" class="input form-control" [(ngModel)]="user_config.sip_switchboard_object_uuid">
                <option value="">[None]</option>
                <option *ngFor="let sip of sips" [value]="sip.uuid">{{ sip.extension_number }} - {{ sip.object_data.name }}</option>
            </select>
        </div>
    </div>

    <div class="field">
        <div class="control">
            <label class="label">
                Enable Email 2FA
                <input class="" type="checkbox" [(ngModel)]="user_config.email_2fa">
            </label>
        </div>
    </div>
</app-modal>

<app-roles #editRoles></app-roles>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>

<app-modal title="Email Logs" (save)="emailLogsDetail.close()" #emailLogsDetail>
    <div class="table-responsive">
        <table class="table is-bordered is-striped is-hoverable is-fullwidth">
            <thead class="thead">
                <tr class="tr">
                    <th class="th">Subject</th>
                    <th class="th">Sent</th>
                </tr>
            </thead>
            <tbody class="tbody" *ngIf="email_logs?.path else no_logs">
                <tr class="tr" *ngFor="let log of email_logs.data">
                    <td class="td">{{ log.subject }}</td>
                    <td class="td">{{ (log.created_at)|date:'medium' }}</td>
                </tr>
            </tbody>
            <tfoot class="tfoot">
                <tr class="tr">
                    <th class="th" colspan="5">
                        <app-pagination [pagination]="email_logs" (page_change)="viewLogs(null, $event)"></app-pagination>
                    </th>
                </tr>
            </tfoot>
        </table>
    </div>
</app-modal>

<ng-template #no_logs>
    <tbody class="tbody">
        <tr class="tr">
            <td class="td" colspan="2">
                <ng-container *ngIf="email_logs else is_loading">No Logs Found</ng-container>
            </td>
        </tr>
    </tbody>
</ng-template>

<app-keys [account_id]="account_id" #keys_component></app-keys>
<app-user-notifications #notifications_component></app-user-notifications>
