import { Component, OnInit, OnChanges, Input, ViewChild } from '@angular/core';

import { LoginService } from '../../login/login.service';
import { ServicesService } from '../../services/services.service';

import { LocationHelper } from '../../location.helper';

import { Account } from '../../accounts/account';

declare var window: any;

@Component({
    selector: 'app-get-started',
    templateUrl: './get-started.component.html',
    styleUrls: ['./get-started.component.css']
})
export class GetStartedComponent implements OnInit {

    account: Account;
    hasSeenTutorial = false;

    @ViewChild('get_started') getStartedModal;

    constructor(
        private authService: LoginService,
        private servicesService: ServicesService,
    ) { }

    ngOnInit(): void {
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account = account;
            this.getTutorial();
        });
    }

    open () {
        window.shownTutorial = true;
        this.getStartedModal.open();
    }

    getTutorial() {
        this.authService.getAccountConfig(this.account.id)
        .subscribe(preferences => {
            this.hasSeenTutorial = +preferences['portal_tutorial_complete'] === 1;

            if (!this.hasSeenTutorial && !window.shownTutorial) {
                this.open();
            }
        });
    }

    doNotShowTutorialAnyMore() {
        this.authService.updateAccountConfig(this.account, {
            'portal_tutorial_complete': 1,
        }).subscribe();
        this.getStartedModal.close();
    }

}
