<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Handsets</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Handsets</h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <a class="btn btn-lg btn-block btn-primary text-lg" routerLink="/handsets/phonebook">
                    <i class="fa-solid fa-book"></i> Handset Phonebook
                </a>
                <br>
                <app-handsets></app-handsets>
            </div>
        </div>
    </div>
</div>
