import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { LoginService } from '../../login/login.service';
import { SwitchboardService } from '../../switchboard/switchboard.service';
import { CdrService } from '../../cdr.service';

import { SwitchboardObject } from '../../switchboard/switchboard_object';

declare var moment: any;
declare var $: any;

function getDateRange(from = null, to = null) {
    let dateArray = [];
    let currentDate = moment().subtract(30, 'days');
    let stopDate = moment();

    if (from && to) {
        currentDate = moment(`${from.year}-${from.month}-${from.day}`);
        stopDate = moment(`${to.year}-${to.month}-${to.day}`);
    }

    while (currentDate <= stopDate) {
        dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
        currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
}

@Component({
    selector: 'app-account-cdrs',
    templateUrl: './account-cdrs.component.html',
    styleUrls: ['./account-cdrs.component.css']
})
export class AccountCdrsComponent implements OnInit {

    @Input() account_id;
    @Input() switchboard_objects: SwitchboardObject[];

    page: number;
    direction = 'inbound';
    number = null;
    search_term = '';
    search_by = 'cli';

    cdrs: SwitchboardObject[];
    cdr_counts;
    stats_chart;

    @ViewChild('datepicker') datepicker;

    constructor(
        private authService: LoginService,
        private cdrService: CdrService,
        private switchboardService: SwitchboardService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit() {
        this.initDatepicker();
        this.loadStats();
    }

    loadStats() {
        if (this.datepicker) {
            this.loadCDRs();
        } else {
            setTimeout(() => this.loadStats(), 500);
        }
    }

    splitToDate(date: string) {
        const dateList = date.split('-');
        return {
            year: +dateList[0],
            month: +dateList[1],
            day: +dateList[2],
        };
    }

    initDatepicker() {
        const start_date = this.activatedRoute.snapshot.queryParamMap.get('start_date');
        if (start_date) {
            this.datepicker.fromDate = this.splitToDate(start_date);
        }
        const end_date = this.activatedRoute.snapshot.queryParamMap.get('end_date');
        if (end_date) {
            this.datepicker.toDate = this.splitToDate(end_date);
        }
    }

    getStartDate() {
        if (this.datepicker.fromDate) {
            return `${this.datepicker.fromDate.year}-${this.datepicker.fromDate.month}-${this.datepicker.fromDate.day}`;
        }
    }

    getEndDate() {
        if (this.datepicker.toDate) {
            return `${this.datepicker.toDate.year}-${this.datepicker.toDate.month}-${this.datepicker.toDate.day}`;
        }
    }

    filter() {
        let params = {
            start_date: this.getStartDate(),
            end_date: this.getEndDate(),
        };

        if (this.search_term) {
            params['search'] = this.search_term;
            params['search_by'] = this.search_by;
        }

        this.loadCDRs();
    }

    changePage(page) {
        this.page =  page;
        this.loadCDRs();
    }

    download() {
        this.loadCDRs(true);
    }

    loadCDRs(download = false) {
        let request;
        let data = {};

        if (this.datepicker.fromDate) {
            data = {
                start_time_range_start: this.getStartDate(),
                start_time_range_end: this.getEndDate(),
            };
        }

        if (this.page) {
            data['page'] = this.page;
        }

        if (download) {
            data['download'] = 1;
        }

        if (this.search_term) {
            data[this.search_by] = this.search_term;
        }

        if (this.number) {
            request = this.cdrService.readCDRsForAccountExtension(this.direction, this.account_id, this.number, data);
        } else {
            request = this.cdrService.readCDRsForAccount(this.direction, this.account_id, data);
        }

        if (!download) {
            this.cdrs = null;
            request.subscribe(cdrs => {
                this.cdrs = cdrs;

                const floatHeader = function () {
                    const table = $('.table-float-header');
                    if (table.length) {
                        table.floatThead({
                            responsiveContainer: function($table) {
                                return $table.closest('.table-responsive');
                            }
                        });
                    } else {
                        setTimeout(floatHeader, 500);
                    }
                };
                floatHeader();
            });
        }
    }

}
