import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PipesModule } from '../../pipes/pipes.module';
import { LayoutModule } from '../../layout/layout.module';
import { ComponentsModule } from '../../components/components.module';
import { SwitchboardModule } from '../../switchboard/switchboard.module';
import { SectionsModule } from '../../sections/sections.module';
import { AudioModule } from '../../audio/audio.module';

import { PortalComponentDestinationSelectComponent } from './portal-component-destination-select/portal-component-destination-select.component';
import { PortalComponentMediaSelectComponent } from './portal-component-media-select/portal-component-media-select.component';
import { PortalComponentRenameSwitchboardObjectComponent } from './portal-component-rename-switchboard-object/portal-component-rename-switchboard-object.component';
import { PortalComponentTimeoutSwitchboardObjectComponent } from './portal-component-timeout-switchboard-object/portal-component-timeout-switchboard-object.component';
import { PortalComponentFieldEditSwitchboardObjectComponent } from './portal-component-field-edit-switchboard-object/portal-component-field-edit-switchboard-object.component';
import { PortalComponentDeleteSwitchboardObjectComponent } from './portal-component-delete-switchboard-object/portal-component-delete-switchboard-object.component';
import { PortalComponentAddonsComponent } from './portal-component-addons/portal-component-addons.component';
import { HandsetsComponent } from '../handset-provisioning/handsets/handsets.component';

@NgModule({
    imports: [
        RouterModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,

        NgbModule,

        PipesModule,
        ComponentsModule,
        SwitchboardModule,
        SectionsModule,
        AudioModule,
    ],
    declarations: [
        PortalComponentDestinationSelectComponent,
        PortalComponentMediaSelectComponent,
        PortalComponentRenameSwitchboardObjectComponent,
        PortalComponentTimeoutSwitchboardObjectComponent,
        PortalComponentFieldEditSwitchboardObjectComponent,
        PortalComponentDeleteSwitchboardObjectComponent,
        PortalComponentAddonsComponent,
        HandsetsComponent,
    ],
    exports: [
        PortalComponentDestinationSelectComponent,
        PortalComponentMediaSelectComponent,
        PortalComponentRenameSwitchboardObjectComponent,
        PortalComponentTimeoutSwitchboardObjectComponent,
        PortalComponentFieldEditSwitchboardObjectComponent,
        PortalComponentDeleteSwitchboardObjectComponent,
        PortalComponentAddonsComponent,
        HandsetsComponent,
    ],
    bootstrap: []
})

export class PortalComponentsModule { }
