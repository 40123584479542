import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';

import { catchError, map, tap, concatMap } from 'rxjs/operators';

import { LoginService } from '../login/login.service';

import { Cacheable } from '../tools/cacheable';
import { Client } from '../tools/client';

import { ServiceTypes } from '../switchboard/switchboard_object';

@Injectable({
    providedIn: 'root'
})
export class ServicesService extends Client {

    protected serviceURL = environment.services.service;

    public account_services: Cacheable<any> = new Cacheable<any>();
    public services: Cacheable<any> = new Cacheable<any>();

    constructor(
        http: HttpClient,
        private authService: LoginService,
    ) {
        super(http);

        this.account_services.getHandler = () => {
            return this.getCurrentAccountServices();
        };

        this.services.getHandler = () => {
            return this.getServices();
        };

        this.refresh();
    }

    refresh() {
        this.account_services.refresh();
        this.services.refresh();
    }

    hasRefreshed() {
        const cached_attributes = [
            this.account_services.hasData(),
            this.services.hasData(),
        ];
        for (let attr of cached_attributes) {
            if (!attr) {
                return false;
            }
        }
        return true;
    }

    getCachedAccountServices() {
        return this.account_services.getData();
    }

    getCurrentAccountServices() {
        return this.authService.getCurrentUser()
            .pipe(concatMap(user => this.getServicesForAccount(user.account_id)));
    }

    getSwitchboardTypes() {
        return this.callApi('get', '/services/types')
        .pipe(
            tap(() => this.log('get switchboard types')),
            catchError(this.handleError('getSwitchboardTypes', []))
        );
    }

    /* Service */

    getServices() {
        return this.callApi('get', '/services')
        .pipe(
            tap(() => this.log('get services')),
            catchError(this.handleError('getServices', []))
        );
    }

    getCachedServices() {
        return this.services.getData();
    }

    getService(id) {
        return this.callApi('get', `/services/${id}`)
        .pipe(
            tap(() => this.log('get service ' + id)),
            catchError(this.handleError('getService', []))
        );
    }

    getSubServiceForService(id) {
        return this.callApi('get', `/services/${id}/subservices`)
        .pipe(
            tap(() => this.log('get subservices for service ' + id)),
            catchError(this.handleError('getSubServiceForService', []))
        );
    }

    getSubServiceForServiceWithBundles(id) {
        return this.callApi('get', `/services/${id}/subservices`, { with_bundles: true })
        .pipe(
            tap(() => this.log('get subservices for service ' + id)),
            catchError(this.handleError('getSubServiceForServiceWithBundles', []))
        );
    }

    getPossibleSubServicesForInstance(account_id, account_service_id) {
        return this.callApi('get', `/accounts/${account_id}/services/${account_service_id}/subservices/available`)
        .pipe(
            tap(() => this.log('get subservices for account service ' + account_service_id)),
            catchError(this.handleError('getPossibleSubServicesForInstance', []))
        );
    }

    createService(service) {
        return this.callApi('post', '/services', service)
        .pipe(
            tap(() => this.log('created service')),
            tap(login => this.refresh()),
            catchError(this.handleError('createService', []))
        );
    }

    updateService(service) {
        const service_id = service.id;
        return this.callApi('put', `/services/${service_id}`, service)
        .pipe(
            tap(apps => this.log('modified service ' + service)),
            tap(login => this.refresh()),
            catchError(this.handleError('updateService', []))
        );
    }

    deleteService(service) {
        const service_id = service.id;
        return this.callApi('delete', `/services/${service_id}`)
        .pipe(
            tap(apps => this.log('delete service ' + service)),
            tap(login => this.refresh()),
            catchError(this.handleError('deleteService', []))
        );
    }

    attachSubService(service_id, subservice_id) {
        return this.callApi('post', `/services/${service_id}/subservices/${subservice_id}`)
        .pipe(
            tap(apps => this.log('attaching subservice ' + subservice_id + ' to service ' + service_id)),
            tap(login => this.refresh()),
            catchError(this.handleError('attachSubService', []))
        );
    }

    detatchSubService(service_id, subservice_id) {
        return this.callApi('delete', `/services/${service_id}/subservices/${subservice_id}`)
        .pipe(
            tap(apps => this.log('detaching subservice ' + subservice_id + ' to service ' + service_id)),
            tap(login => this.refresh()),
            catchError(this.handleError('detatchSubService', []))
        );
    }

    getServiceBundles(service_id) {
        return this.callApi('get', `/services/${service_id}/bundles`)
        .pipe(
            tap(() => this.log('get subservices for service ' + service_id)),
            catchError(this.handleError('getServiceBundles', []))
        );
    }

    attachServiceBundle(service_id, bundle_id) {
        return this.callApi('post', `/services/${service_id}/bundles/${bundle_id}`)
        .pipe(
            tap(() => this.log(`attach bundle ${bundle_id} for service ${service_id}`)),
            tap(login => this.refresh()),
            catchError(this.handleError('attachServiceBundle', []))
        );
    }

    detachServiceBundle(service_id, bundle_id) {
        return this.callApi('delete', `/services/${service_id}/bundles/${bundle_id}`)
        .pipe(
            tap(() => this.log(`detach bundle ${bundle_id} for service ${service_id}`)),
            tap(login => this.refresh()),
            catchError(this.handleError('detachServiceBundle', []))
        );
    }

    getAvailableServices(account_id, object_type = null) {
        let data = {};
        if (object_type) {
            data = { switchboard_type: object_type };
        }
        return this.callApi('get', `/accounts/${account_id}/services/available`, data)
        .pipe(
            tap(() => this.log('get subservices for account ' + account_id)),
            catchError(this.handleError('getAvailableServices', []))
        );
    }

    /* Subservice */

    getSubServices() {
        return this.callApi('get', '/subservices')
        .pipe(
            tap(() => this.log('get subservices')),
            catchError(this.handleError('getSubServices', []))
        );
    }

    getSubService(id) {
        return this.callApi('get', `/subservices/${id}`)
        .pipe(
            tap(() => this.log('get subservice ' + id)),
            catchError(this.handleError('getSubService', []))
        );
    }

    createSubService(subservice) {
        return this.callApi('post', '/subservices', subservice)
        .pipe(
            tap(() => this.log('created subservice')),
            tap(login => this.refresh()),
            catchError(this.handleError('createSubService', []))
        );
    }

    updateSubService(subservice) {
        const subservice_id = subservice.id;
        return this.callApi('put', `/subservices/${subservice_id}`, subservice)
        .pipe(
            tap(apps => this.log('modified subservice ' + subservice)),
            tap(login => this.refresh()),
            catchError(this.handleError('updateSubService', []))
        );
    }

    deleteSubService(subservice) {
        const subservice_id = subservice.id;
        return this.callApi('delete', `/subservices/${subservice_id}`)
        .pipe(
            tap(apps => this.log('delete subservice ' + subservice)),
            tap(login => this.refresh()),
            catchError(this.handleError('deleteSubService', []))
        );
    }

    /* Account Services */

    getServicesForAccount(account_id, type = null) {
        let query = {};
        if (type) {
            query['switchboard_type'] = type;
        }
        return this.callApi('get', `/accounts/${account_id}/services`, query)
        .pipe(
            tap(apps => this.log('get services for ' + account_id)),
            catchError(this.handleError('getServicesForAccount', []))
        );
    }

    addServiceToAccount(account_id, service_id) {
        return this.callApi('post', `/accounts/${account_id}/services/${service_id}`)
        .pipe(
            tap(apps => this.log('add service ' + service_id + ' for ' + account_id)),
            tap(login => this.refresh()),
            catchError(this.handleError('addServiceToAccount', []))
        );
    }

    addMultipleServicesToAccount(account_id, service_id, quantity) {
        return this.callApi('post', `/accounts/${account_id}/services/${service_id}/quantity/${quantity}`)
        .pipe(
            tap(apps => this.log(`add ${quantity} services ` + service_id + ' for ' + account_id)),
            tap(login => this.refresh()),
            catchError(this.handleError('addMultipleServicesToAccount', []))
        );
    }

    renewServiceToAccount(account_id, service_id) {
        return this.callApi('post', `/accounts/${account_id}/services/${service_id}/renew`)
        .pipe(
            tap(apps => this.log(`Renew service ${service_id} for account ${account_id}`)),
            tap(login => this.refresh()),
            catchError(this.handleError('renewServiceToAccount', []))
        );
    }

    deleteServiceOnAccount(account_id, service_id) {
        return this.callApi('delete', `/accounts/${account_id}/services/${service_id}`)
        .pipe(
            tap(apps => this.log('delete service ' + service_id + ' for ' + account_id)),
            tap(() => this.account_services.refresh()),
            catchError(this.handleError('deleteServiceOnAccount', 'FAIL'))
        );
    }

    getSubServicesForServiceInstance(account_id, service_id) {
        return this.callApi('get', `/accounts/${account_id}/services/${service_id}/subservices`)
        .pipe(
            tap(apps => this.log('add get subservices for account service ' + service_id + ' for ' + account_id)),
            catchError(this.handleError('getSubServicesForServiceInstance', []))
        );
    }

    addSubServiceToAccount(account_id, service_id, subservice_id) {
        return this.callApi('post', `/accounts/${account_id}/services/${service_id}/subservices/${subservice_id}`)
        .pipe(
            tap(apps => this.log('add subservice ' + subservice_id + ' service ' + service_id + ' for ' + account_id)),
            tap(login => this.refresh()),
            catchError(this.handleError('addSubServiceToAccount', []))
        );
    }

    deleteSubServiceOnAccount(account_id, service_id, subservice_id) {
        return this.callApi('delete', `/accounts/${account_id}/services/${service_id}/subservices/${subservice_id}`)
        .pipe(
            tap(apps => this.log('delete subservice ' + subservice_id + ' service for ' + account_id)),
            tap(login => this.refresh()),
            catchError(this.handleError('deleteSubServiceOnAccount', []))
        );
    }

    getSubServiceBundles(service_id) {
        return this.callApi('get', `/subservices/${service_id}/bundles`)
        .pipe(
            tap(() => this.log('get subservices for service ' + service_id)),
            catchError(this.handleError('getServiceBundles', []))
        );
    }

    attachSubServiceBundle(service_id, bundle_id) {
        return this.callApi('post', `/subservices/${service_id}/bundles/${bundle_id}`)
        .pipe(
            tap(() => this.log(`attach bundle ${bundle_id} for service ${service_id}`)),
            tap(login => this.refresh()),
            catchError(this.handleError('attachServiceBundle', []))
        );
    }

    detachSubServiceBundle(service_id, bundle_id) {
        return this.callApi('delete', `/subservices/${service_id}/bundles/${bundle_id}`)
        .pipe(
            tap(() => this.log(`detach bundle ${bundle_id} for service ${service_id}`)),
            tap(login => this.refresh()),
            catchError(this.handleError('detachServiceBundle', []))
        );
    }

    renewAccountService(account_id, service_id) {
        return this.callApi('post', `/accounts/${account_id}/services/${service_id}/renew`)
        .pipe(
            tap(apps => this.log(`renew service ${service_id} for ${account_id}`)),
            tap(login => this.refresh()),
            catchError(this.handleError('renewAccountService', []))
        );
    }
}
