import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { LoginService } from '../../../login/login.service';
import { SwitchboardService } from '../../../switchboard/switchboard.service';

import { Rule } from '../../../switchboard/rules/rule.model';
import { RuleHelper } from '../rule.helper';

declare var toastr: any;
declare var alertify: any;
declare var $: any;

@Component({
    selector: 'app-nine-to-five-wizard',
    templateUrl: './nine-to-five-wizard.component.html',
    styleUrls: ['./nine-to-five-wizard.component.css']
})
export class NineToFiveWizardComponent implements OnInit {

    @Input('rule-name') ruleName = 'Open';
    @Input() account_id;
    @Input() object_id;

    object;
    switchboard_objects;
    
    rules;

    @Output() generated = new EventEmitter<any>();

    days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

    wizard = {
        mon: null,
        tue: null,
        wed: null,
        thu: null,
        fri: null,
        sat: null,
        sun: null,
        redirect: null,
    };
    wizard_generating = false;
    wizard_generated = null;
    wizard_generation_message = null;
    closed_redirect;

    time_now = new Date();

    constructor(
        private route: ActivatedRoute,
        private authService: LoginService,
        private switchboardService: SwitchboardService
    ) { }

    ngOnInit(): void {
        // TODO: replace with actual server time
        setInterval(() => this.time_now = new Date(), 1000);

        // this.getSwitchboardObjects();
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Check if 'account_id' or 'object_id' has changed
        if (changes.account_id || changes.object_id) {
            this.getSwitchboardObjects();
        }
    }

    getSwitchboardObjects() {
        this.object = null;
        this.switchboard_objects = null;
        this.switchboardService.getSwitchboardObjectsForAccount(this.account_id)
        .subscribe(objects => {
            this.switchboard_objects = objects;
            for (let object of objects) {
                if (object.uuid === this.object_id) {
                    this.object = object;
                }
            }
            this.getRulesForObject();
        });
    }

    getRulesForObject() {
        this.switchboardService.getSwitchboardObjectRules(this.account_id, this.object.uuid)
        .subscribe(rules => {
            this.rules = <Rule[]>rules;
            this.startWizard();
        });
    }

    @Input('rules')
    set value(rules: any) {
        this.rules = rules;
        this.startWizard();
    }

    getActiveRule() {
        return RuleHelper.evaluateRules(this.rules);
    }

    startWizard() {
        const rules = this.getRulesForState(this.ruleName);
        this.wizard_generating = false;
        this.wizard_generated = null;
        this.wizard_generation_message = null;

        this.wizard = {
            mon: null,
            tue: null,
            wed: null,
            thu: null,
            fri: null,
            sat: null,
            sun: null,
            redirect: this.getStateRedirect(this.ruleName),
        };

        for (let rule of rules) {
            let day = null;
            for (let weekday of this.days) {
                if (rule[weekday]) {
                    day = weekday;
                }
            }

            if (day) {
                this.wizard[day] = {
                    time_start: rule.time_range_start,
                    time_end: rule.time_range_end,
                };
            }
        }

        this.closed_redirect = this.object.object_data.switchboard_destination_uuid;
    }

    deleteAllRules(state = null) {
        for (let rule of this.rules) {
            if (!state || state === rule.name) {
                this.switchboardService.deleteSwitchboardObjectRule(this.account_id, this.object.uuid, rule).subscribe();
            }
        }
    }

    getRulesForState(state) {
        return this.rules.filter(rule => rule.name === state);
    }

    // Legacy. Used for inverted rules.
    backOneMinute(time) {
        let [hour, minutes] = time.split(':').map(x => +x);

        if (!minutes) {
            minutes = 60;
            hour -= 1;
        }

        if (hour === -1) {
            hour = 23;
        }

        minutes -= 1;
        hour = ('' + hour).padStart(2, '0');
        minutes = ('' + minutes).padStart(2, '0');

        return `${hour}:${minutes}`;
    }

    calculateInvertedRules () {
        let times;
        const rules = [];
        for (let day of this.days) {
            times = this.wizard[day];
            if (!times) {
                rules.push({
                    day: day,
                    time_start: '00:00',
                    time_end: '23:59',
                });
            } else {
                if (times.time_start !== '00:00') {
                    rules.push({
                        day: day,
                        time_start: '00:00',
                        time_end: this.backOneMinute(times.time_start),
                    });
                }
                if (times.time_end !== '23:59') {
                    rules.push({
                        day: day,
                        time_start: times.time_end,
                        time_end: '23:59',
                    });
                }
            }
        }
        return rules
    }

    calculateBasicRules() {
        let times;
        const rules = [];

        for (let day of this.days) {
            times = this.wizard[day];
            if (times) {
                rules.push({
                    day: day,
                    time_start: times.time_start,
                    time_end: times.time_end,
                });

            }
        }

        return rules;
    }

    getStateRedirect(state) {
        const state_rules = this.getRulesForState(state);
        if (state_rules.length) {
            return state_rules[0].switchboard_destination_uuid;
        }
        return this.object.object_data.switchboard_destination_uuid;
    }

    createRule(name, time_start, time_end, day, redirect = null) {
        if (!redirect) {
            redirect = this.getStateRedirect(name);
        }

        const rule = new Rule();
        rule.name = name;
        rule.time_range_start = time_start;
        rule.time_range_end = time_end;
        rule[day] = 1;
        rule.switchboard_destination_uuid = redirect;

        return this.switchboardService.createSwitchboardObjectRule(this.account_id, this.object.uuid, rule);
    }

    saveDefaultRedirect() {
        this.switchboardService.updateSwitchboardObjectForAccount(this.account_id, {
            uuid: this.object.uuid,
            switchboard_destination_uuid: this.closed_redirect,
        }).subscribe();
    }

    generateBasicRules() {
        const rules = this.calculateBasicRules();
        let count = 0;
        const total = rules.length;
        const generated = [];

        this.deleteAllRules(this.ruleName);

        const genRule = () => {
            if (rules.length === 0) {
                this.wizard_generating = false;
                this.wizard_generated = generated;
                this.getSwitchboardObjects();
                this.generated.emit();
                return false;
            }

            const rule = rules.shift();
            count += 1;
            this.wizard_generation_message = `Creating rule (${count}/${total})`;

            this.createRule(this.ruleName, rule.time_start, rule.time_end, rule.day, this.wizard.redirect)
            .subscribe(generated_rule => {
                generated.push(generated_rule);
                genRule();
            });
        };

        this.wizard_generating = true;
        this.saveDefaultRedirect();
        genRule();
    }

}
