import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { LoginService } from '../login/login.service';
import { SwitchboardService } from '../switchboard/switchboard.service';
import { ServicesService } from '../services/services.service';

import { ACCOUNT_STATES_RAW } from '../pipes/account-state.pipe';

import { Account } from './account';

declare var toastr: any;

@Component({
    selector: 'app-accounts',
    templateUrl: './accounts.component.html',
    styleUrls: ['./accounts.component.css']
})

export class AccountsComponent implements OnInit {

    accounts: any = null; // TODO: Make a paginator interface to use as the type here
    page;
    account_totals;
    services;

    search_company = '';
    search_id = '';
    search_email = '';
    search_state = '';
    search_service = null;
    search_contact = '';
    search_ddref = '';

    private sid_start = 1000000;

    readonly active_states = ACCOUNT_STATES_RAW;

    @Input() newAccount: Account = new Account();

    @ViewChild('accountDetail') accountDetail;

    constructor(
        private route: ActivatedRoute,
        private authService: LoginService,
        private switchboardService: SwitchboardService,
        private servicesService: ServicesService,
        private router: Router
    ) { }

    ngOnInit() {
        this.search_company = this.route.snapshot.queryParamMap.get('company') || '';
        this.search_id = this.route.snapshot.queryParamMap.get('qid') || '';
        this.search_email = this.route.snapshot.queryParamMap.get('email') || '';
        this.search_state = this.route.snapshot.queryParamMap.get('state') || '';
        this.search_service = this.route.snapshot.queryParamMap.get('service') || '';
        this.search_contact = this.route.snapshot.queryParamMap.get('contact') || '';
        this.search_ddref = this.route.snapshot.queryParamMap.get('dd_ref') || '';
        this.route.paramMap.subscribe(params => {
            this.page = +params.get('page');
            this.getAccounts();
            this.servicesService.getServices()
                .subscribe(services => this.services = services);
        });
    }

    getAccounts() {
        this.authService.getAccountTotals()
            .subscribe(totals => this.account_totals = totals);
        this.authService.getAccounts(this.page, {
            company_name: this.search_company,
            id: this.search_id,
            email: this.search_email,
            active_state: this.search_state,
            has_service_id: this.search_service,
            contact_number: this.search_contact,
            dd_ref: this.search_ddref
        })
        .subscribe(accounts => this.accounts = accounts);
    }

    searchAccounts() {
        this.router.navigate(['/accounts'], {
            queryParams: {
                company: this.search_company,
                qid: this.search_id,
                email: this.search_email,
                state: this.search_state,
                service: this.search_service,
                contact: this.search_contact,
                dd_ref: this.search_ddref,
            }
        });
        this.getAccounts();
    }

    createAccount() {
        this.newAccount = new Account();
        this.accountDetail.open();
    }

    saveAccount() {
        this.authService.createAccount(this.newAccount)
        .subscribe(account => {
            if (account.account.id) {
                this.accountDetail.close();
                this.router.navigate([`/accounts/detail/${account.account.id}`]);
            } else {
                this.accountDetail.stopLoading();
            }
        });
    }

    commit(account) {
        this.switchboardService.commitAccount(account.id)
        .subscribe(
            () => toastr.success('Switchboard comitted'),
            () => console.log('What went wrong?')
        );
    }

}
