export const NumbersHelper = {
    minScore: 0,
    midScore: 4,
    maxScore: 9999,

    getAppropriateService(number, services) {
        const score = number.score;
        const number_services = services.filter(service => service.switchboard_type === 'Number');
        const scores = number_services.map(service => service.switchboard_quality);
        scores.sort((a, b) => a - b);

        let winning_score = 0;
        while (scores[winning_score] < score) {
            winning_score += 1;
        }

        let service;
        for (service of number_services) {
            if (service.switchboard_quality === scores[winning_score]) {
                return service;
            }
        }

        return null;
    },

    getFullNumber(number) {
        return number.fullNumber || (number.country + number.area.replace(/^0/, '') + number.number);
    },

};
