export class Application {
    create_key: string;
    name: string;
    short_name: string;
    portal_url: string;
    company_name: string;
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    password_confirmation: string;
}
