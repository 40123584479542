<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/handsets">Handsets</a></li>
                <li class="breadcrumb-item active" aria-current="page">Speed Dial</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Handset Speed Dial</h2>
                        <h3 *ngIf="handset">{{ handset.device_manufacturer }} {{ handset.device_model }} ({{ handset.mac_code }})</h3>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <p><a routerLink="/handsets" class="btn btn-danger">Back</a></p>
                <div class="table-responsive table-striped">
                    <table class="table">
                        <tbody class="tbody">
                            <ng-container *ngIf="handset_blfs?.length else no_blfs">
                                <tr class="tr" *ngFor="let blf of handset_blfs">
                                    <td class="td">
                                        <h4>{{ blf.blf_line_number }} - {{ blf.name }}</h4>
                                        <ng-container *ngIf="blf.blf_type">
                                            <span class="text-muted text-sm" *ngIf="getLineSip(blf) as sip else no_sip">
                                                <ng-container *ngIf="blfs[sip.extension_number] as state">
                                                    <i class="fas fa-circle text-lg"
                                                       [title]="state"
                                                       [class.text-danger]="state == 'In use'"
                                                       [class.text-blinking]="state == 'Ringing'"
                                                       [class.text-success]="state == 'Not in use'"
                                                    ></i>
                                                    &nbsp;
                                                </ng-container>
                                                <app-forwarding-to-partial [destination]="sip"></app-forwarding-to-partial>
                                            </span>
                                        </ng-container>
                                        <ng-container *ngIf="!blf.blf_type">
                                            {{ blf.speed_dial_number }}
                                        </ng-container>
                                    </td>
                                    <td class="td">
                                        <button class="btn btn-secondary is-link" (click)="editBLF(blf)">Edit</button>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #no_blfs>
    <tr class="tr">
        <td class="td" colspan="2" *ngIf="handset_blfs else is_loading">No blfs found</td>
    </tr>
</ng-template>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>

<app-modal title="Edit Line" (save)="saveBLF()" #blf_line_modal>
    <div class="field">
        <label class="label">Name</label>
        <input class="input form-control" type="text" placeholder="Name" [(ngModel)]="blf_line.name">
    </div>

    <div class="field">
        <label class="label">Type</label>
        <br>
        <label>
            <input type="radio" [(ngModel)]="blf_line.blf_type" [value]="0" [checked]="blf_line.blf_type === 0"> Phone
        </label>
        &nbsp;
        <label>
            <input type="radio" [(ngModel)]="blf_line.blf_type" [value]="1" [checked]="blf_line.blf_type === 1"> VoIP User
        </label>
    </div>

    <div class="field" *ngIf="!blf_line.blf_type">
        <label class="label">
            Phone Number
            <!-- <a style="cursor: pointer; " tabindex="0" data-toggle="popover" data-trigger="focus" title="" data-content="This is usually on a label on the back of the phone"><i class="far fa-question-circle"></i></a> -->

        </label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Phone Number" [(ngModel)]="blf_line.speed_dial_number">
        </div>
    </div>

    <div class="field" *ngIf="blf_line.blf_type">
        <label class="label">
            VoIP User
            <!-- <a style="cursor: pointer; " tabindex="0" data-toggle="popover" data-trigger="focus" title="" data-content="This is usually on a label on the back of the phone"><i class="far fa-question-circle"></i></a> -->

        </label>
        <app-switchboard-object-dropdown [(model)]="blf_line.speed_dial_number"
                                         object-key="extension_number"
                                         [allow_none]="true"
                                         [switchboard_objects]="sips"></app-switchboard-object-dropdown>
    </div>
</app-modal>
