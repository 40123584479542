import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';

import { LoginService } from '../login/login.service';
import { ServicesService } from '../services/services.service';
import { SwitchboardService } from '../switchboard/switchboard.service';
import { BillingService } from '../billing.service';
import { RatesService } from '../rates/rates.service';

declare var toastr: any;
declare var moment: any;
declare var alertify: any;

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.css']
})
export class ShopComponent implements OnInit {

    account_id;

    services;
    sub_services;
    bundles;

    service_details = {
        'Voicemail': {description: 'Personalised voicemail to email', name: 'Voicemail inbox', url: '/voicemails/new'},
        'Menu': {description: '"Welcome to XZY, press 1 for sales, 2 for accounts etc"', name: 'IVR Auto Attendant Menu', url: '/menus/new'},
        'Announcement': {description: '"Welcome to XYZ, please hold while we put you through"', name: 'Intro Announcement', url: '/announcements/new'},
        'Group': {description: 'Additional call forwarding/hunt groups', name: 'Hunt Groups', url: '/groups/new'},
        'Number': {description: 'Add additional telephone numbers to your account', name: 'Additional number', url: '/numbers/new'},
        'Channel': {description: 'When you need extra capacity to carry more calls then buy channels', name: 'Channels', url: '/channels'},
        'Sip': {description: 'Add additional VoIP users to create a virtual telephone system', name: 'VoIP User', url: '/sips/new'},
        'CallRecording': {description: '', name: 'Call Recording', url: '/call-recordings'},
        'MusicOnHold': {description: '', name: 'Music On Hold', url: '/music-on-hold'},
    };

    constructor(
        private authService: LoginService,
        private billingService: BillingService,
        private ratesService: RatesService,
        private servicesService: ServicesService,
        private switchboardService: SwitchboardService,
    ) { }

    ngOnInit() {
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account_id = account.id;
            this.getServices();
        });
    }

    getServices() {
        this.servicesService.getAvailableServices(this.account_id)
        .subscribe(services => {
            const single_service = {};
            for (let service of services) {
                if (single_service[service.switchboard_type]) {
                    if (single_service[service.switchboard_type].retail_cost > service.retail_cost) {
                        single_service[service.switchboard_type] = service;
                        single_service[service.switchboard_type]['multiple'] = true;
                    }
                } else {
                    single_service[service.switchboard_type] = service;
                }
            }
            this.services = Object.values(single_service);
        });
        this.servicesService.getSubServices()
        .subscribe(services => {
            this.sub_services = services;
            for (let service of this.sub_services) {
                this.servicesService.getSubServiceBundles(service.id)
                    .subscribe(bundles => service['bundles'] = bundles);
            }
        });
        this.ratesService.getBundles()
            .subscribe(bundles => this.bundles = bundles);
    }

    getSheets(service) {
        if (!service.bundles || !this.bundles) {
            return false;
        }
        const bundle_ids = service.bundles.map(bundle => bundle.id);
        const bundles = this.bundles.filter(bundle => bundle_ids.indexOf(bundle.id) !== -1);
        const sheets = bundles.map(bundle => bundle.sheets);
        const flat_sheets = [].concat.apply([], sheets);
        const unique_flat_sheets = [...new Set(flat_sheets)];
        return unique_flat_sheets;
    }

}
