import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { LoginService } from '../../login/login.service';
import { SwitchboardService } from '../../switchboard/switchboard.service';

import { SwitchboardObject } from '../../switchboard/switchboard_object';

@Component({
  selector: 'app-portal-media',
  templateUrl: './portal-media.component.html',
  styleUrls: ['./portal-media.component.css']
})
export class PortalMediaComponent implements OnInit {

    account_id;

    constructor(
        private authService: LoginService,
        private switchboardService: SwitchboardService,
    ) { }

    ngOnInit() {
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account_id = account.id;
        });
    }

}
