<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Queues</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Queues</h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <ng-container *ngTemplateOutlet="list_queues"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #list_queues>
    <h3 *ngIf="queues !== null"> You have {{ queues?.length || 0 }} queue{{ queues?.length === 1 ? '' : 's' }}</h3>

    <a class="btn btn-lg btn-block btn-outline-success text-lg" *ngIf="hasAvailableServices" routerLink="/queues/new">
        <i class="fas fa-plus "></i>
        New Queue
    </a>

    <br>

    <ng-container *ngIf="queues?.length else no_switchboard_objects">
        <div class="card" *ngFor="let switchboard_object of queues; let i = index">
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <h2>
                            {{ switchboard_object.object_data.name }}
                            -
                            <span class="text-muted text-sm" data-toggle="tooltip" data-placement="bottom" title="Every part of your system has an extension number that you can call directly using our VoIP service, or transfer a call to">Extension Number {{ switchboard_object.extension_number }}</span>
                            &nbsp;
                            <span class="badge badge-pill badge-contrast badge-success" *ngIf="expired[switchboard_object.service_instance_id]">Expired</span>
                        </h2>
                    </div>
                    <div class="col-2">
                        <a class="btn btn-primary" routerLink="/queues/{{ switchboard_object.uuid }}">Edit</a>
                    </div>
                </div>
                <app-list-subservices-partial [sub_services]="sub_services[switchboard_object.service_instance_id]" [switchboard_object]="switchboard_object"></app-list-subservices-partial>
                <div class="row">
                    <div class="col" *ngIf="media && getSwitchboardMedia(switchboard_object.object_data.switchboard_media_file_uuid) as file">
                        <p>
                            Using {{ file.name }}

                            <button class="btn btn-success" [class.btn-danger]="file.player && !file.player.paused" (click)="playSound(file)" target="_blank">
                                Preview

                                <i class="fas fa-play" *ngIf="file.player && !file.player.paused"></i>
                            </button>
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col" *ngIf="switchboard_objects && getSwitchboardObject(switchboard_object.object_data.switchboard_destination_uuid) as destination">
                        Forwarding to: 
                        <br>
                        <app-forwarding-to-partial [destination]="destination" show-edit="true"></app-forwarding-to-partial>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col text-right text-muted text-sm">
                        <strong>Last Edited: </strong> <span class="datetime">{{ (switchboard_object.updated_at)|date:'medium' }}</span>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <br>
</ng-template>

<ng-template #no_switchboard_objects>
    <div class="card-body">
        <p *ngIf="queues?.length === 0 else loading">No Queues Found</p>
    </div>
</ng-template>

<ng-template #loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
