<ng-container *ngIf="invoices && invoices.length else no_invoices">
    <ng-container *ngTemplateOutlet="list_kashflow_invoices"></ng-container>
</ng-container>

<ng-template #list_kashflow_invoices>
    <div class="table-responsive">
        <table class="table align-items-center table-flush table-striped">
            <thead>
                <th>Date</th>
                <th>Paid</th>
                <th>&nbsp;</th>
            </thead>
            <tbody>
                <tr *ngFor="let invoice of invoices">
                    <td>{{ invoice.InvoiceDate|format_local_date }}</td>
                    <td class="text-align">&pound;{{ (invoice.AmountPaid * 100)|format_balance }}</td>
                    <td>
                        <a [href]="invoice.PermaLink" target="_blank" class="btn btn-primary">Download Invoice</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #no_invoices>
    <div class="card-body">
        <p *ngIf="invoices else loading">No invoices found</p>
    </div>
</ng-template>

<ng-template #loading>
    <app-loadingbar></app-loadingbar>
</ng-template>