export const AudioHelper = {
    loadFileToAudioElement(elementID, file = null) {
        return (data) => {
            const audioElement = <HTMLMediaElement> document.getElementById(elementID);
            const blobUrl = AudioHelper.getObjectURLFromData(data);

            let sourceElement = audioElement.firstElementChild;
            if (!sourceElement) {
                sourceElement = document.createElement('source');
                audioElement.appendChild(sourceElement);

                audioElement.addEventListener('ended', () => {
                    if (file) {
                        file.loaded = false;
                    }
                });
            }

            sourceElement.setAttribute('src', blobUrl);
            sourceElement.setAttribute('type', 'audio/mpeg');

            audioElement.load();
            audioElement.play();
        };
    },
    downloadAudioFile(fileName) {
        return (data) => {
            const url = AudioHelper.getObjectURLFromData(data);
            const link = window.document.createElement('a');
            link.href = url;
            link.download = fileName;
            link.click();
        };
    },
    getObjectURLFromData(data) {
        const blob = new Blob([data], { type: 'audio/vnd.wav'});
        return window.URL.createObjectURL(blob);
    },
    createAudioObjectFromData(data) {
        const audio = new Audio();
        audio.autoplay = true;
        audio.src = AudioHelper.getObjectURLFromData(data);
        audio.addEventListener('ended', () => this.whisper_preview.player.pause());
        audio.play();
        return audio;
    }
};
