import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { LoginService } from '../../../login/login.service';
import { SwitchboardService } from '../../../switchboard/switchboard.service';

import { SwitchboardObject } from '../../../switchboard/switchboard_object';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-portal-component-destination-select',
    templateUrl: './portal-component-destination-select.component.html',
    styleUrls: ['./portal-component-destination-select.component.css']
})
export class PortalComponentDestinationSelectComponent implements OnInit {

    @Input() types = [
        // 'queue',
        'group',
        'announcement',
        'menu',
        'voicemail',
        'sip',
    ];

    private account_id: number;

    switchboard_objects: SwitchboardObject[] = [];
    object_type;

    @Input() root: SwitchboardObject;
    @Input() field = 'switchboard_destination_uuid';
    @Input() nullable = false;

    @Output() save = new EventEmitter<string>();

    constructor(
        private authService: LoginService,
        private switchboardService: SwitchboardService,
        private router: Router
    ) { }

    ngOnInit() {
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account_id = account.id;
            this.getSwitchboardObjects();
        });
    }

    rootDestination() {
        return this.root && this.root.object_data[this.field];
    }

    getSwitchboardObjects() {
        this.switchboard_objects = [];
        this.switchboardService.getSwitchboardObjectsForAccount(this.account_id)
            .subscribe(objects => this.switchboard_objects = objects);
    }

    getDestinationObject() {
        for (let object of this.switchboard_objects) {
            if (object.uuid === this.rootDestination()) {
                return object;
            }
        }
        return null;
    }

    selectObject(object) {
        let data = {
            uuid: this.root.uuid,
        };
        data[this.field] = object.uuid;

        const saveField = () => {
            this.switchboardService.updateSwitchboardObjectForAccount(this.account_id, data)
            .subscribe(response => {
                if (object.object_data) {
                    toastr.success(`Set destination to ${object.object_data.name}`);
                } else {
                    toastr.success(`Cleared destination!`);
                }
                this.root.object_data[this.field] = object.uuid;
                this.save.emit();
                this.object_type = null;
            });
        };

        if (object.uuid) {
            saveField();
        } else {
            alertify.confirm(`Are you sure you want to remove this destination and have the call end here?`, () => {
                saveField();
            });
        }
    }

}
