<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <ng-container *ngIf="invoice">
                    <li class="breadcrumb-item"><a routerLink="/invoices">Invoices</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Pay Invoice</li>
                </ng-container>
                <ng-container *ngIf="!invoice">
                    <li class="breadcrumb-item active" aria-current="page">Topup</li>
                </ng-container>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">
                            {{ invoice ? 'Pay Invoice' : 'Topup'}}
                        </h2>
                    </div>
                </div>
            </div>
            <div class="card-body" *ngIf="current_amount else price_list">
                <ng-container *ngIf="payment_success !== null else braintree_client">
                    <ng-container *ngTemplateOutlet="payment_done"></ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #price_list>
    <div class="card-body">
        <ng-container *ngIf="hasBilling else address_required">
            <h3>Choose a price to topup:</h3>
            <div class="alert alert-secondary text-center" role="alert">
                <ng-container  *appViperHasUserRole="'Account Owner'">
                    &pound;5 topup available via <a routerLink="/auto-topup">Auto Topup</a>.
                    <br>
                </ng-container>
                Your transaction will appear as "VoIP Number Services" on your bank account
                <br>
                <br>
                Please note;
                Credit expires after 90 days (3 months) but can be rolled over by adding another top up within that time.
                <br>
                <br>
                Payments can not be reversed or refunded.
            </div>
            <ng-container *ngFor="let price of prices">
                <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" (click)="current_amount = price;generateToken()">
                    <i class="fas fa-pound-sign"></i> {{ price|format_balance }}
                </div>
            </ng-container>
            <ng-container  *appViperHasUserRole="'Account Owner'">
                <hr>
                <a class="btn btn-lg btn-link btn-block btn-outline-warning text-lg" routerLink="/auto-topup">
                    Configure Auto Topup
                </a>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #address_required>
    <p class="alert alert-warning">
        You will need a billing address before you can topup your account.
    </p>

    <a class="btn btn-lg btn-block btn-outline-warning text-lg" routerLink="/settings/addresses">
        Set Address
    </a>
</ng-template>

<ng-template #braintree_client>
    <h1 class="text-lg text-center">
        <i class="fas fa-pound-sign"></i> {{ current_amount|format_balance }}
    </h1>
    <ng-container *ngIf="!invoice">
        <div class="btn btn-lg btn-block btn-danger text-lg" *ngIf="braintree_instance" (click)="current_amount = price;braintree_instance = null">
            Change Amount
        </div>
    </ng-container>
    <div id="dropin-container"></div>
    <div class="btn btn-lg btn-block btn-outline-success text-lg" *ngIf="braintree_instance else loading" (click)="useCard()">
        Make Payment
    </div>
</ng-template>

<ng-template #payment_done>
    <h1 class="text-lg text-center">
        <i class="fas fa-check-circle text-success text-lg" *ngIf="payment_success === true"></i>
        <i class="fas fa-times-circle text-danger text-lg" *ngIf="payment_success !== true"></i>
        <br>
        Payment {{ payment_success === true ? 'Success' : 'Failed' }}!
    </h1>
    <p *ngIf="payment_success !== true">{{ payment_success }}</p>

    <ng-container *ngIf="payment_success === true">
            <ng-container *ngIf="app?.short_name === 'np'">
                <h3 class="text-center">Happy with your service? Leave us a review!</h3>
    
                <p>We are on TrustPilot, so we'd appreciate if you could take a moment to <a href="https://uk.trustpilot.com/review/numberpeople.co.uk" rel="_blank">give us an honest review here</a>.</p>
    
                <p class="text-center">
                    <img src="/assets/images/trustpilot.png" />
                </p>    
            </ng-container>
     </ng-container>

    <div *ngIf="!invoice" class="btn btn-lg btn-block btn-link btn-outline-light text-lg" (click)="reset()">
        Make Another Payment
    </div>

    <div *ngIf="invoice" class="btn btn-lg btn-block btn-link btn-outline-light text-lg" routerLink="/invoices">
        Go back to invoices
    </div>
</ng-template>

<ng-template #loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
