<table class="table">
    <thead class="thead">
        <tr class="tr">
            <th colspan="2">
                <!-- type filter goes here -->
                <div class="dropdown">
                    <button id="filterDropdown" class="btn btn-secondary dropdown-toggle" aria-haspopup="true" aria-controls="dropdown-menu" data-toggle="dropdown">
                        <span *ngIf="filter_type">Filtered By: {{ filter_type|alias }}</span>
                        <span *ngIf="!filter_type">Filter By Service:</span>
                    </button>
                    <div class="dropdown-menu" role="menu" aria-labelledby="filterDropdown">
                        <div class="dropdown-content">
                            <a class="dropdown-item" (click)="filter_type = null">
                                All
                            </a>
                            <hr class="dropdown-divider">
                            <ng-container *ngFor="let type of switchboardTypes">
                                <a class="dropdown-item" (click)="filter_type = type">
                                    <i [class]="{object_type: type}|switchboard_object_icon"></i> {{ type|alias }} ({{ serviceCounts[type] || 0 }})
                                </a>
                            </ng-container>
                        </div>
                    </div>
                </div>

                &nbsp;

                <!-- status filter goes here -->
                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdown-status-filter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Status<span *ngIf="filter_status"> ({{ filter_status }})</span>:
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdown-status-filter">
                        <a class="dropdown-item" (click)="filter_status = null">All</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" (click)="filter_status = 'Active'">Active</a>
                        <a class="dropdown-item" (click)="filter_status = 'Maxed Out'">Maxed Out</a>
                        <a class="dropdown-item" (click)="filter_status = 'Expired'">Expired</a>
                    </div>
                </div>

                &nbsp;
                
                <ng-container *appViperHasUserRole="'Admin'">
                    <button class="btn btn-primary is-primary serviceButton" (click)="serviceDialog()">Add Service</button>
                </ng-container>
            </th>
        </tr>
    </thead>
</table>

<div class="table-responsive table-striped">
    <table class="table">
        <tbody class="tbody" *ngIf="services && services.length else no_services">
            <ng-container *ngFor="let service of services">
                <ng-container *ngIf="(!filter_type || filter_type === service.service.switchboard_type) && (!filter_status || checkServiceFilter(service))">
                    <tr class="tr">
                        <td class="td" style="word-wrap: break-word; ">
                            <h3>
                                <i [class]="{object_type: type}|switchboard_object_icon"></i>
                                &nbsp;
                                <ng-container *ngIf="getSwitchboardObject(service) as object">
                                    <ng-container *appViperHasUserRole="'Admin'; else includeLink">
                                        <span *ngIf="object.object_type == 'Number'">
                                        <span class="flag-icon flag-icon-{{ (object.object_data.e164_number|number_to_country_code).toLowerCase() }}" [title]="object.object_data.e164_number|number_to_country_prefix"></span>
                                        {{ object.object_data.e164_number|number_to_local }} - </span>
                                        {{ object.object_data.name }}

                                        <span class="text-sm text-muted">({{ object.extension_number }})</span>
                                    </ng-container>
                                    <ng-template #includeLink>
                                        <a [routerLink]="('/' + object.object_type.toLowerCase() + 's/' + object.uuid)" class="text-lg">
                                            <span *ngIf="object.object_type == 'Number'">
                                            <span class="flag-icon flag-icon-{{ (object.object_data.e164_number|number_to_country_code).toLowerCase() }}" [title]="object.object_data.e164_number|number_to_country_prefix"></span>
                                            {{ object.object_data.e164_number|number_to_local }} - </span>
                                            {{ object.object_data.name }}

                                            <span class="text-sm text-muted">({{ object.extension_number }})</span>
                                        </a>
                                    </ng-template>
                                    
                                </ng-container>
                                &nbsp;
                                <span class="text-muted text-sm">
                                    <ng-container *appViperHasUserRole="'Admin'; else justServiceName">
                                        <a routerLink="/services/detail/{{ service.service.id }}">({{ service.service.name }})</a>
                                    </ng-container>
                                    <ng-template #justServiceName>({{ service.service.name }})</ng-template>
                                </span>
                                &nbsp;
                                <span class="badge badge-pill badge-danger" *ngIf="service.expired">Expired</span>
                                <ng-container *ngIf="service.is_bundle">
                                    <span class="badge badge-pill badge-info" *ngIf="service.has_minutes">{{ service|minutes_used }} minutes used</span>
                                    <span class="badge badge-pill badge-warning" *ngIf="!service.has_minutes">Maxed Out</span>
                                </ng-container>
                            </h3>

                            <span class="text-lg">
                                &pound;{{ service.service.retail_cost|format_balance }}
                            </span>

                            <br />

                            <ng-container *ngIf="service.sub_service_instances?.length">
                                Addons:
                                <ul>
                                    <li *ngFor="let subservice of service.sub_service_instances">
                                        <ng-container *appViperHasUserRole="'Admin'; else justSubserviceName">
                                            <a routerLink="/subservices/detail/{{ subservice.sub_service.id }}">{{ subservice.sub_service.name }}</a>
                                        </ng-container>
                                        <ng-template #justSubserviceName>
                                            {{ subservice.sub_service.name }}
                                        </ng-template>

                                        <span class="badge badge-success" syle="background-color: #DF3B5F; color: #f1eaec">
                                            <ng-container *ngIf="subservice.sheet_instances.length">Bundle</ng-container>
                                            <ng-container *ngIf="!subservice.sheet_instances.length">Add-On</ng-container>
                                        </span>
                                        <span class="badge badge-pill badge-danger" *ngIf="subservice.expired">Expired</span>
                                        <ng-container *ngIf="subservice.is_bundle">
                                            <span class="badge badge-pill badge-info" *ngIf="subservice.has_minutes">{{ subservice|minutes_used }} minutes used</span>
                                            <span class="badge badge-pill badge-warning" *ngIf="!subservice.has_minutes">Maxed Out</span>
                                        </ng-container>

                                        <br>

                                        <span class="text-sm text-muted">
                                            {{ (subservice.created_at)|date:'medium' }}
                                        </span>

                                        <br>

                                        <span class="text-sm text-muted">
                                            &pound;{{ subservice.sub_service.retail_cost|format_balance }}

                                            <ng-container *ngIf="subservice.expires_at">
                                                renews
                                                <span title="Renewal at {{ oneDayLess(subservice.expires_at)|date:'medium' }} Expiring at {{ subservice.expires_at|date:'medium' }}">{{ oneDayLess(subservice.expires_at)|from_now }}</span>
                                                <br>
                                                <span class="text-muted text-sm">({{ oneDayLess(subservice.expires_at)|date:'medium' }})</span>
                                            </ng-container>
                                        </span>

                                        <br>

                                        <ng-container *ngIf="subservice.service && !subservice.has_minutes">
                                            <button class="btn btn-success btn-sm" (click)="renewSubService(subservice)">Activate</button>
                                        </ng-container>
                                        <button class="btn btn-danger is-danger btn-sm" (click)="deleteSubService(subservice)">Delete</button>
                                    </li>
                                </ul>

                                
                            </ng-container>

                            <p *ngIf="service.expires_at && app.billing_type !== 1">
                                <strong style="font-weight: 900;">Renews:  
                                    <span title="Renewal at {{ oneDayLess(service.expires_at)|date:'medium' }} Expiring at {{ service.expires_at|date:'medium' }}">{{ oneDayLess(service.expires_at)|from_now }}</span>
                                </strong>
                                &nbsp;
                                <span class="text-muted">({{ oneDayLess(service.expires_at)|date:'medium' }})</span>
                            </p>

                            <p class="text-sm text-muted">
                                <span style="font-weight:600;">Created on:</span> {{ (service.created_at)|date:'medium' }}
                            </p>
                            <ng-container *appViperHasUserRole="'Admin'">
                                <button class="btn btn-secondary" (click)="subServiceDialog(service)">
                                    Add Sub-service
                                </button>
                            </ng-container>
                            <ng-container *ngIf="service.is_bundle && !service.has_minutes">
                                <button class="btn btn-success" (click)="renewService(service)">Activate</button>
                            </ng-container>
                            <button *appViperIsNotPostpay class="btn btn-danger is-danger" (click)="deleteService(service)">Delete</button>
                            <ng-container *appViperIsPostpay>
                                <button *appViperHasUserRole="'Admin'" class="btn btn-danger is-danger" (click)="deleteService(service)">Delete</button>
                            </ng-container>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
        </tbody>
    </table>
</div>

<ng-template #no_services>
    <tbody class="tbody">
        <tr class="tr">
            <td class="td" colspan="1">
                <ng-container *ngIf="services else is_loading">No services found</ng-container>
            </td>
        </tr>
    </tbody>
</ng-template>

<app-modal title="Add Service" (save)="addService()" #addServiceModal>
    <div class="field">
        <label class="label">Service</label>
        <div class="control">
            <div class="select">
                <select class="form-control" [(ngModel)]="newAccountService">
                    <option *ngFor="let service of available_services" value="{{ service.id }}">{{ service.name }}</option>
                </select>
            </div>
        </div>
    </div>
</app-modal>

<app-modal title="Add Subservice" (save)="addSubService()" #addSubServiceModal>
    <div class="field">
        <label class="label">Sub Service</label>
        <div class="control">
            <button class="btn btn-info is-info is-loading is-outlined" disabled *ngIf="available_sub_services == null">&nbsp;</button>
            <div class="select" *ngIf="available_sub_services">
                <select class="form-control" [(ngModel)]="newSubService">
                    <option *ngFor="let subservice of available_sub_services" value="{{ subservice.id }}">{{ subservice.name }}</option>
                </select>
            </div>
        </div>
    </div>
</app-modal>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
