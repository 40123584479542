<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{ direction|capitalize }} Call Blocking</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">{{ direction|capitalize }} Call Blocking</h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <p>
                    Use call blocking to block specific or partial telephone numbers both inbound or outbound
                </p>
                <p>
                    Select INBOUND or OUTBOUND. For inbound blocking please enter the number exactly as you see it on your phone.  For outbound blocking enter the number in its international format, ie 441234567890 which would block the specific UK number 01234567890, or 4412345 which would block any number in the range 012345xxxxx
                </p>
                <p class="text-lg"><a class="btn btn-dark" routerLink="/call-restrictions/inbound">Inbound</a> <a class="btn btn-dark" routerLink="/call-restrictions/outbound">Outbound</a></p>
                <ul class="list-group">
                    <li class="list-group-item" *ngFor="let call_restriction of call_restrictions?.data">
                        {{ call_restriction.e164_number }}
                        <button class="btn btn-danger btn-sm" (click)="unblock(call_restriction)">Unblock</button>
                    </li>
                    <li class="list-group-item" *ngIf="call_restrictions?.data?.length === 0">No Call Restrictions</li>
                    <li class="list-group-item">
                        <div class="input-group mb-3">
                            <ng-container *ngIf="direction === 'inbound' else outbound_input">
                                <input type="text" [(ngModel)]="new_number" class="form-control" placeholder="Number to Block" aria-label="Block Number" aria-describedby="button-call-block">
                            </ng-container>
                            <ng-template #outbound_input>
                                <form [formGroup]="phoneForm" #telephoneForm>
                                    <ngx-intl-tel-input
                                        cssClass="form-control telephone-input"
                                        [preferredCountries]="[CountryISO.UnitedKingdom, CountryISO.UnitedStates]"
                                        [selectedCountryISO]="CountryISO.UnitedKingdom"
                                        [enableAutoCountrySelect]="true"
                                        [enablePlaceholder]="true"
                                        [searchCountryFlag]="true"
                                        [selectFirstCountry]="false"
                                        [phoneValidation]="true"
                                        [numberFormat]="PhoneNumberFormat.National"
                                        name="phone"
                                        formControlName="phone"
                                        #telephoneInput
                                    ></ngx-intl-tel-input>
                                </form>
                            </ng-template>
                            <div class="input-group-append">
                                <button class="btn btn-primary" type="button" id="button-call-block" (click)="block()">Block</button>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item" *ngIf="call_restrictions">
                        <app-pagination [pagination]="call_restrictions" [(page)]="page" (page_change)="changePage($event)"></app-pagination>
                    </li>
                    <li class="list-group-item" *ngIf="!call_restrictions">
                        <app-loadingbar></app-loadingbar>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
