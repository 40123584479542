import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

@Pipe({name: 'minutes_used'})
export class MinutesUsedPipe implements PipeTransform {

    transform(bundle: any) {
        return bundle.sheet_instances.reduce((a, b) => a + b.minutes_used, 0);
    }
}
