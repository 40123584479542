<div class="alert alert-danger" role="alert" *ngIf="has_no_addresses">
    TIP - You don't have any addresses saved - you will need to save an address for use when TOPPING UP or adding VOIP USERS - Click on <a routerLink="/settings/addresses">ADDRESSES</a> to add an address
</div>

<ng-container *appViperBrandIs="['np']">
    <div class="alert alert-danger" role="alert" *ngIf="account.is_business && !this.kyc.company_name" style="display:flex;align-items:center;">
        <i class="fa-solid fa-triangle-exclamation" style="padding:1%; padding-right:3%; font-size:3em;"></i>
        <div>
            It looks like this account is for business use. Please ensure that you submit your business information <a routerLink="/settings/details" style="font-weight:600;">here</a>
            <br>
            If we've got this wrong, and you're not a business, <a href='https://support.numberpeople.co.uk/open.php?topicId=36' style="font-weight:600;">please let us know</a>   
        </div>
    </div>
</ng-container>