import { Component, OnInit } from '@angular/core';
import { SwitchboardService } from '../../../switchboard/switchboard.service';
import { LoginService } from '../../../login/login.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-voip-status-card',
  templateUrl: './voip-status-card.component.html',
  styleUrls: ['./voip-status-card.component.css']
})
export class VoipStatusCardComponent implements OnInit {

  sips;
  account;

  busyLightsInterval;
  busyLights;
  private onDestroy = new Subject<void>();

  voip_card_toggle = false;

  constructor(
    private loginService: LoginService,
    private switchboardService: SwitchboardService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.loginService.getCurrentAccount()
      .pipe(
        switchMap(account => {
          this.account = account;
          return this.switchboardService.readSipsOnAccount(this.account.id);
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe(sips => {
        this.sips = sips;
        this.startBusyLightsInterval();
      });
  }

  ngOnDestroy() {
    clearInterval(this.busyLightsInterval);
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  startBusyLightsInterval() {
    this.busyLightsInterval = setInterval(() => this.loadBusyLights(), 30 * 1000);
    this.loadBusyLights(); // Load initially without waiting for the interval
  }

  loadBusyLights() {
    this.switchboardService.getSwitchboardBlfs(this.account.id)
      .subscribe(blfs => this.busyLights = blfs);
  }

  setupUser(sip) {
    this.router.navigate(['/sips', sip.uuid], { queryParams: { openModal: 'true' } });
  }

  toggleVoIPCard() {
    this.voip_card_toggle = !this.voip_card_toggle;
  }

}
