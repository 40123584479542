export const PriceHelper = {
    fieldToPounds(object, field): void {
        object[field] /= 100;
        object[field] += '';
        if (!object[field].match(/\./)) {
            object[field] += '.';
        }
        while (!object[field].match(/\.\d\d$/)) {
            object[field] += '0';
        }
    },
    fieldsToPounds(object, fields): void {
        for (let field of fields) {
            PriceHelper.fieldToPounds(object, field);
        }
    },
    getObjectInPence(object, fields): any {
        const new_object = { ...object };
        for (let field of fields) {
            new_object[field] *= 100;
        }
        return new_object;
    }
};
