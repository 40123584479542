<app-modal title="" size="big" #get_started>
    <ng-container *appViperBrandIs="['lv']">
        <h2>Set Up your VoIP Users</h2>
        <div class="flexrow">
            <div class="flexcol">
                <div class="flexpad">
                    <p>Now that you have created your account, you will firstly need to set up your VoIP users.</p>
                    <p>We have made this process as simple as possible, so just follow the simple steps.</p>
                    <p>You'll find user guides and other help videos within our <a routerLink="/help" style="font-weight:600;">help files</a> which you have access to at anytime.</p>
                    <img src="https://i1.wp.com/lovevoip.co.uk/wp-content/uploads/2020/05/loveworkinganywhere.png?w=266&ssl=1" class="flexImg">
                </div>
            </div>
            <div class="flexcol1">
                <div class="helpbox">
                    <div class="flexpad">
                        <ol class="styledOrder">
                            <li>Navigate to "My VoIP Users" on the portal dashboard.</li>
                            <img src="https://lovevoip.co.uk/wp-content/uploads/2020/10/voipusers.png">
                            <li>Then, next to each VoIP User, click edit.</li>
                            <img src="https://lovevoip.co.uk/wp-content/uploads/2020/10/voipuseredit.png">
                            <li>Next, choose the top option, "Show QR Code and Set up your Device"</li>
                            <img src="https://lovevoip.co.uk/wp-content/uploads/2020/10/showqr.png">
                            <li>On the next page, click on the large green button, "Regenerate Password and show QR code"</li>
                            <img src="https://lovevoip.co.uk/wp-content/uploads/2020/10/regenpass.png">
                            <li>You will then be presented with the SIP credentials to set up your own device. Follow the oncreen instructions for how to set up your chosen devices including
                                <ul class="noList">
                                    <li>Pc/Laptop</li>
                                    <li>VoIP Phones</li>
                                    <li>Mobile devices via Softphone Apps (like Grandstream Wave or ZoIPer)</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *appViperBrandIs="['np']">
        <!-- number people code goes here -->
        <div class="introModal">
            <div class="flexrow">
                <div style="margin:4%;">
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2020/03/newnsprof.png" style="width:13em;">
                </div>
                <div>
                    <h2>Welcome to Number People</h2>
                    <p>Thank you for logging into your customer portal. This is where you manage your phone number/s, make top-up payments, view call stats, add Pro features, plus much more!</p>
                    <p>Please take a moment to read the information below and become familiar with the portal. If you need any help, don't hesitate to contact us</p>
                </div>
            </div>
            <div class="flexrow">
                <div class="flexcol">
                    <div class="flexpad">
                        <h3>Below are a few useful tips to help you get started.</h3>
                        <h4>Free Trial</h4>
                        <p>Your free trial allows you to test out your number with us and gives you a small amount of free test credit which lasts <strong>up to</strong> 30 days, after which time it will expire if not used.</p>
                        <p>After the free trial period has ended, or you have used all of your free credit, simply make a top-up to continue to use your account.</p>
                        <h4>Setting up a VoIP user</h4>
                        <p>If you have chosen to use VoIP, then you will need to set up your VoIP user via the button on the top of the dashboard. You can also click <a routerLink="/help" style="font-weight:600;">HERE</a> to view our VoIP installation help guides and videos.</p>
                    </div>
                </div>
                <div class="flexcol1">
                    <div class="helpbox">
                        <div class="flexpad">
                            <h3>How can your new number/s be used?</h3>
                            <ul>
                                <li><span style="font-weight:600">Forward inbound calls to a destination telephone number anywhere in the world</span><br>Please ensure you have enough credit on your account to pay for the call forwarding</li>
                                <li><span style="font-weight:600">FREE inbound calls to a VoIP app or VoIP device (0800 users will be charged 7.5p per minute)</span><br>Simple to install and can be used on mobile, PC, Laptop or VoIP Phone, just pay £1 per month for the VoIP user</li>
                                <li><span style="font-weight:600">Make outbound calls using VoIP that display the virtual number as the caller ID</span><br>Display your virtual phone number as your outbound caller ID</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flexrow">
                <div style="margin:4%;">
                    <img src="https://numberpeople.co.uk/wp-content/uploads/2020/01/superman-man-300x192.png" style="width:13em;">
                </div>
                <div>
                    <h2>Supercharge your virtual number</h2>
                    <p>Your portal is where you can add some fantastic features to enhance your virtual phone number;</p>
                    <ul>
                        <li>Add an intro announcement (Welcome to XYZ, please hold while we put you through)</li>
                        <li>Add an IVR Menu (Welcome to XYZ, press 1 for sales, 2 for accounts…)</li>
                        <li>Add personalised Voicemail to email</li>
                        <li>Add Call Whisper to recognise inbound calls</li>
                        <li>Set opening hours to your telephone number/s</li>
                        <li>Set up call recording</li>
                        <li>Play Customised Music on Hold with Personalised Announcements</li>
                        <li>Add an unlimited call bundle (inc some international destinations)</li>
                    </ul>
                </div>
            </div>
            <div>
                <div class="helpbox">
                    <h3>Call Bundles</h3>
                    <p>Call bundles can be purchased from the dashboard, just look for <span style="font-weight:600">INBOUND</span> or <span style="font-weight:600">OUTBOUND CALL BUNDLES.</span></p>
                </div>
                <p>We hope that you love our services, If you need any help then please firstly use the <span style="font-weight:600">NEED HELP</span> button on the portal to view our selection of helpful PDF's and videos, or look at the FAQ's page on our website. If these do not help then you can <a href="http://support.numberpeople.co.uk/" style="font-weight:600;">RAISE A SUPPORT TICKET</a> or <span style="font-weight:600">LIVE-CHAT</span> with us.</p>
            </div>
        </div>
    </ng-container>
    <ng-container *appViperBrandIs="['vv']">
        <div class="introModal">
            <div style="text-align: center;">
                <p>
                    <img src="https://vivivoip.co.uk/wp-content/uploads/2022/01/vivi-vector-features.svg" style="width:20em;">
                </p>
                <h2>Hello, welcome to your customer portal</h2>
                <p>Your customer portal is designed to give you full control over your telephone system as well as deliver a wide range of services including call statistics and full remote handset management, giving you one place to control everything to do with your communications.</p>
                <p>If you would like a live demonstration of your portal then just contact us here at <a href="mail:support@vivivoip.co.uk">support@vivivoip.co.uk</a> and one of our friendly team members will book a slot that is convenient for you.</p>
            </div>
        </div>
    </ng-container>
    <ng-container *appViperBrandIsNot="['lv', 'np', 'vv']">
        <div class="introModal">
            <div style="text-align: center;">
                <h2>Thanks for signing up!</h2>
                <p>Your account is now ready to use. Please take the time to have a look around the dashboard and become familiar with the services & features, ensuring you add any personalisation you need.</p>
                <p>You will find lots of helpful information within our <a routerLink="/help" style="font-weight:600;">help files</a>, so head there to view videos and help files for the most commonly used features.</p>
                <p>So, go ahead and take a look around, you will have some free test credit to use to try out everything and make some calls. Just let us know if you need any help!</p>
                <p style="font-weight: 600;">Customer Service Team</p>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!hasSeenTutorial">
        <button class="btn btn-block btn-secondary doNotShowButton" (click)="doNotShowTutorialAnyMore()">Do Not Show Me This Again</button>
    </ng-container>
</app-modal>