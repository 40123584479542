import { Component, OnInit, OnChanges, Input, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { LoginService } from '../../login/login.service';

import { LocationHelper } from '../../location.helper';

declare var toastr: any;
declare var window: any;
declare var $: any;

@Component({
    selector: 'app-basic-layout',
    templateUrl: './basic-layout.component.html',
    styleUrls: ['./basic-layout.component.css']
})
export class BasicLayoutComponent implements OnInit {

    public app;
    public datenow;
    public mainsite;

    constructor(
        private authService: LoginService,
        private titleService: Title,
    ) { }

    ngOnInit() {
        this.mainsite = LocationHelper.getMainSite();
        this.datenow = new Date();
        this.authService.getApps()
        .subscribe(apps => this.app = LocationHelper.getAppFromURL(apps));
    }

}
