import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { LoginService } from '../../login/login.service';
import { SwitchboardService } from '../switchboard.service';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {

    @Input() account_id;

    notes: any[];

    new_note_name = '';
    new_note = '';

    edit_note_id;
    edit_note_name = '';
    edit_note = '';

    user: any;

    @ViewChild('edit_modal') editModal;

    constructor(
        private loginService: LoginService,
        private switchboardService: SwitchboardService
    ) { }

    ngOnInit() {
        this.loginService.getCurrentUser()
            .subscribe(user => this.user = user);
        this.getNotes();
    }

    getNotes() {
        this.switchboardService.getNotes(this.account_id)
            .subscribe(notes => this.notes = notes);
    }

    addNote() {
        this.switchboardService.createNote(this.account_id, {
            name: this.new_note_name,
            content: this.new_note
        }).subscribe(response => {
            if (response.id) {
                toastr.success('Note added!');
                this.new_note_name = '';
                this.new_note = '';
                this.getNotes();
            }
        });
    }

    edit(note) {
        this.edit_note_id = note.id;
        this.edit_note_name = note.name;
        this.edit_note = note.content;
        this.editModal.open();
    }

    updateNote() {
        this.switchboardService.updateNote(this.account_id, {
            id: this.edit_note_id,
            name: this.edit_note_name,
            content: this.edit_note
        }).subscribe(response => {
            if (response.id) {
                toastr.success('Note updated!');
                this.edit_note_name = '';
                this.edit_note = '';
                this.editModal.close();
                this.getNotes();
            }
        });
    }

    delete(note) {
        alertify.confirm('Are you sure you want to delete this note?', () => {
            this.switchboardService.deleteNote(this.account_id, note)
                .subscribe(() => this.getNotes());
        });
    }
}
