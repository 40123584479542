import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';

import { LoginService } from '../../login/login.service';
import { ServicesService } from '../../services/services.service';
import { SwitchboardService } from '../../switchboard/switchboard.service';
import { BillingService } from '../../billing.service';

declare var toastr: any;
declare var moment: any;
declare var alertify: any;

@Component({
    selector: 'app-account-services',
    templateUrl: './account-services.component.html',
    styleUrls: ['./account-services.component.css']
})
export class AccountServicesComponent implements OnInit {

    @Input() account_id: number;

    @ViewChild('addServiceModal') serviceModal;
    @ViewChild('addSubServiceModal') subServiceModal;

    public services = null;
    public slot_counts = null;
    serviceCounts;

    switchboardTypes: string[];

    balance;

    all_sub_services;

    switchboard_objects: any[];
    available_services: any[];
    available_sub_services = null;

    newAccountService: number = null;
    subServiceService: number = null;
    newSubService: number = null;

    @Input() filter_type = null;
    @Input() filter_status = null;

    app;

    constructor(
        private authService: LoginService,
        private billingService: BillingService,
        private servicesService: ServicesService,
        private switchboardService: SwitchboardService,
    ) { }

    ngOnInit() {
        this.servicesService.getSwitchboardTypes()
            .subscribe(types => this.switchboardTypes = types);
        this.getServices();
        this.servicesService.getServices()
            .subscribe(services => this.available_services = services);
        this.servicesService.getSubServices()
            .subscribe(services => this.all_sub_services = services);
        this.getBalance();
        this.authService.getCurrentApp()
            .subscribe(app => this.app = app);
    }

    getBalance() {
        this.billingService.getBalanceForAccount(this.account_id)
            .subscribe(balance => this.balance = balance);
    }

    public getServices() {
        this.services = null;
        this.servicesService.getServicesForAccount(this.account_id)
        .subscribe(services => {
            let serviceCount = {};

            this.services = services;

            for (let service of this.services) {
                if (!serviceCount[service.service.switchboard_type]) {
                    serviceCount[service.service.switchboard_type] = 0;
                }
                serviceCount[service.service.switchboard_type] += 1;

                service.expired = service.expires_at && moment(service.expires_at).isBefore();

                for (let subservice of service.sub_service_instances) {
                    subservice.expired = subservice.expires_at && moment(subservice.expires_at).isBefore();
                }
            }

            this.serviceCounts = serviceCount;

            this.switchboardService.getSwitchboardObjectsForAccount(this.account_id)
            .subscribe(objects => {
                this.switchboard_objects = objects;
                this.slotCount();
            });
        });
    }

    /* I have no idea what this thing is used for */
    slotCount() {
        let counts = {};
        for (let service of this.services) {
            counts[service.id] = {
                max_slots: service.service.switchboard_quantity,
                slots_taken: 0,
                full: false,
            };
        }
        for (let object of this.switchboard_objects) {
            if (counts[object.service_instance_id]) {
                counts[object.service_instance_id].slots_taken += 1;
                if (counts[object.service_instance_id].slots_taken === counts[object.service_instance_id].max_slots + 1) {
                    counts[object.service_instance_id].full = true;
                }
            } else {
                console.log(`Unknown service for ${object.extension_number}: ${object.service_instance_id}`);
            }
        }
        this.slot_counts = counts;
    }

    serviceDialog() {
        this.newAccountService = null;
        this.serviceModal.open();
    }

    addService() {
        this.servicesService.addServiceToAccount(this.account_id, this.newAccountService)
        .subscribe(service => {
            if (service.id) {
                toastr.success('Service added!');
                this.getServices();
                this.serviceModal.close();
            } else {
                this.serviceModal.stopLoading();
            }

            this.getBalance();
        });
    }

    deleteService(service) {
        if (service.deleted) {
            return toastr.error('Account service has already been deleted');
        }
        alertify.confirm(`Are you sure you want to delete this service?`, () => {
            this.services = null;
            this.servicesService.deleteServiceOnAccount(this.account_id, service.id)
                .subscribe(() => this.getServices());
            service.deleted = true;
        });
    }

    deleteSubService(subservice) {
        if (subservice.deleted) {
            return toastr.error('Account service has already been deleted');
        }
        alertify.confirm(`Are you sure you want to delete this subservice?`, () => {
            this.services = null;
            this.servicesService.deleteSubServiceOnAccount(this.account_id, subservice.service_instance_id, subservice.id)
                .subscribe(() => this.getServices());
            subservice.deleted = true;
        });
    }

    renewSubService(subservice) {
        console.log(subservice, this.all_sub_services);
        for (let sub_service of this.all_sub_services) {
            if (subservice['sub_service_id'] === sub_service['id'] && sub_service['retail_cost'] > this.balance) {
                toastr.error('Not enough credit');
                return false;
            }
        }

        alertify.confirm(`Are you sure you want to renew this subservice?`, () => {
            this.services = null;
            this.servicesService.deleteSubServiceOnAccount(this.account_id, subservice.service_instance_id, subservice.id)
            .subscribe(() => {
                this.servicesService.addSubServiceToAccount(this.account_id, subservice.service_instance_id, subservice.sub_service_id)
                .subscribe(() => {
                    this.getServices();
                    this.getBalance();
                });
            });
            subservice.deleted = true;
        });
    }

    subServiceDialog(service) {
        this.newSubService = null;
        this.subServiceService = service.id;

        this.available_sub_services = null;
        this.servicesService.getPossibleSubServicesForInstance(this.account_id, this.subServiceService)
            .subscribe(subservices => this.available_sub_services = subservices);

        this.subServiceModal.open();
    }

    addSubService() {
        if (this.newSubService) {
            for (let sub_service of this.all_sub_services) {
                if (+this.newSubService === sub_service['id'] && sub_service['retail_cost'] > this.balance) {
                    toastr.error('Not enough credit');
                    this.subServiceModal.stopLoading();
                    return false;
                }
            }

            this.servicesService.addSubServiceToAccount(this.account_id, this.subServiceService, this.newSubService)
            .subscribe(subservice => {
                if (subservice.id) {
                    toastr.success('Sub Service added!');
                    this.getServices();
                    this.subServiceModal.close();
                } else {
                    this.subServiceModal.stopLoading();
                }
                this.getBalance();
            });
        } else {
            toastr.error('Sub Service required');
            this.subServiceModal.stopLoading();
        }
    }

    oneDayLess(date) {
        if (!date) {
            return date;
        }

        return moment(date).subtract(1, 'days');
    }

    renewService(service) {
        if (service.service.retail_cost > this.balance) {
            toastr.error('Not enough credit');
            return false;
        }

        alertify.confirm(`Are you sure you want to renew this service?`, () => {
            this.services = null;
            this.servicesService.renewServiceToAccount(this.account_id, service.id)
            .subscribe(() => {
                this.getServices();
                this.getBalance();
            });
        });
    }

    checkServiceFilter(service) {
        const hasFilteredSubService = !!service.sub_service_instances.filter(ss => this.isServiceFiltered(ss)).length;
        return hasFilteredSubService || this.isServiceFiltered(service);
    }

    isServiceFiltered(service) {
        const expired = !!service.expired;
        const maxed_out = service.is_bundle && !service.has_minutes;
        switch (this.filter_status) {
            case 'Active':
                return !expired && !maxed_out;
            case 'Expired':
                return expired;
            case 'Maxed Out':
                return maxed_out;
            default:
                return true;
        }
    }

    getSwitchboardObject(service) {
        if (this.switchboard_objects) {
            for (let object of this.switchboard_objects) {
                if (object.service_instance_id === service.id) {
                    return object;
                }
            }
        }
        return null;
    }

}
