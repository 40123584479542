import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

export const ACCOUNT_STATES = [
    'No Switchboard',
    'In Trial',
    'Active',
    'Suspended on Outbound Calls',
    'Suspended on Outbound & Inbound Calls',
    'Cancelled',
    'Frozen'
];

export const ACCOUNT_STATES_RAW = [
    'No Switchboard',
    'Trial',
    'Active',
    'Suspended Outbound',
    'Suspended Outbound & Inbound',
    'Cancelled',
    'Frozen'
];

@Pipe({name: 'formatAccountState'})
export class AccountStatePipe implements PipeTransform {

    transform(state: any) {
        return ACCOUNT_STATES[state];
    }
}
