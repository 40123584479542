import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { FormGroup, FormControl, Validators } from '@angular/forms';

// import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

import { LoginService } from '../../login/login.service';
import { ContractsService } from '../contracts.service';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-contract-types',
    templateUrl: './contract-types.component.html',
    styleUrls: ['./contract-types.component.css']
})
export class ContractTypesComponent implements OnInit {

    contracts: any;
    current_contract: any = {};

    page: number;

    @ViewChild('contractDetail') contractDetail;

    constructor(
        private route: ActivatedRoute,
        private authService: LoginService,
        private contractsService: ContractsService,
    ) { }

    ngOnInit(): void {
        this.getContractTypes();
    }

    getContractTypes() {
        this.contractsService.getContractTypes()
            .subscribe(contracts => this.contracts = contracts);
    }

    newContract() {
        this.current_contract = {};
        this.contractDetail.open();
    }

    editContract(contract) {
        this.current_contract = contract;
        this.contractDetail.open();
    }

    deleteContract(contract) {
        alertify.confirm(`Are you sure you want to delete ${contract.name}?`, () => {
            this.contractsService.deleteContractType(contract)
            .subscribe(out => {
                toastr.info('Deleting Contract Type!');
                this.getContractTypes();
            });
        });
    }

    saveContract() {
        let request;
        if (this.current_contract.uuid) {
            request = this.contractsService.updateContractType(this.current_contract);
        } else {
            request = this.contractsService.createContractType(this.current_contract);
        }

        request.subscribe(contract => {
            if (contract.uuid) {
                toastr.success(`Contract Type Saved`);
                this.getContractTypes();
                this.contractDetail.close();
            } else {
                this.contractDetail.stopLoading();
            }
        });
    }

}
