import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';

import { BillingService } from '../../../billing.service';
import { CdrService } from '../../../cdr.service';
import { CoinprintService } from '../../../billing/coinprint.service';

declare var toastr: any;
declare var alertify: any;
declare var $: any;

@Component({
    selector: 'app-invoices-coinprint',
    templateUrl: './invoices-coinprint.component.html',
    styleUrls: ['./invoices-coinprint.component.css']
})
export class InvoicesCoinprintComponent implements OnInit {

    @Input() account_id;

    invoices;
    cdrs;
    extension_cdrs;
    payments;

    @Input() admin = false;
    page = 1;

    current_invoice;
    cdrs_page;

    credit_note = null;
    new_payment: any;
    current_payment: any;

    @ViewChild('cdrsModal') cdrsModal;
    @ViewChild('credit_note_modal') creditNoteModal;
    @ViewChild('paymentsModal') paymentsModal;

    constructor(
        private billingService: BillingService,
        private cdrService: CdrService,
        private coinprintService: CoinprintService,
    ) { }

    ngOnInit(): void {
        this.getInvoices();
    }

    getInvoices(page = null) {
        if (page) {
            this.page = page;
        }
        this.billingService.getCoinprintInvoices(this.account_id, this.page)
        .subscribe(invoices => {
            this.invoices = invoices;
        });

        if (!this.coinprintService.hasKey()) {
            this.billingService.getCoinprintKey()
            .subscribe(key => {
                this.coinprintService.setKey(key);
            });
        }
    }

    invoiceTotal(invoice) {
        return Math.round(+invoice.NetAmount * 100) + Math.round(+invoice.VATAmount * 100);
    }

    newCreditNote(invoice) {
        this.current_invoice = invoice;
        this.credit_note = { lines: [] };
        this.creditNoteModal.open();
    }

    addLine() {
        this.credit_note.lines.push({
            quantity: 1,
            unit_price: '0.00',
        });
    }

    removeLine(line) {
        this.credit_note.lines.splice(this.credit_note.lines.indexOf(line), 1);
    }

    saveCreditNote() {
        this.coinprintService.createCreditNote(this.current_invoice, this.credit_note)
        .subscribe(response => {
            if (response['id']) {
                this.getInvoices();
                this.creditNoteModal.close();
            } else {
                console.error('Error Creating Credit Note', response);
                this.creditNoteModal.stopLoading();
            }
        }, (error) => {
            console.error('Error Creating Credit Note', error);
            this.creditNoteModal.stopLoading();
        });
    }

    creditNoteTotal() {
        return this.credit_note.lines.reduce((a, b) => (b.unit_price * b.quantity) + a, 0);
    }

    addCreditNoteTax(value) {
        return (1 + +this.current_invoice.tax_rate) * value;
    }

    // getPayments() {
    //     this.coinprintService.readInvoicePayments(this.current_invoice)
    //         .subscribe(payments => this.payments = payments);
    // }  

    getPayments(callback?: (payments: any) => void) {
        this.coinprintService.readInvoicePayments(this.current_invoice)
            .subscribe(payments => {
                this.payments = payments;
                if (callback) {
                    callback(payments);
                }
            });
    }
    
    // viewPayments(invoice) {
    //     this.current_invoice = invoice;
    //     this.payments = null;
    //     this.new_payment = {};
    //     this.getPayments();
    //     this.paymentsModal.open();
    // }

    viewPayments(invoice) {
        this.current_invoice = invoice;
        this.payments = null;
        this.new_payment = {};
        this.getPayments((fetchedPayments) => {
            this.payments = fetchedPayments.sort((b, a) => 
                new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
            );
            this.paymentsModal.open();
        });
    }
    
    addPayment() {
        this.coinprintService.createInvoicePayment(this.current_invoice, this.new_payment)
        .subscribe(payment => {
            if (payment['uuid']) {
                this.new_payment = {};
                this.getPayments();
                this.getInvoices();
                toastr.success('Payment added');
            }
        });
    }

    editPayment(payment) {
        this.current_payment = { ...payment };
        for (let p of this.payments) {
            p.edit = false;
        }
        payment.edit = true;
    }

    savePayment() {
        this.coinprintService.updateInvoicePayment(this.current_invoice, this.current_payment)
        .subscribe(payment => {
            if (payment['uuid']) {
                this.getPayments();
                this.getInvoices();
                toastr.success('Payment saved');
                for (let p of this.payments) {
                    p.edit = false;
                }
            }
        });
    }

    deletePayment(payment) {
        alertify.confirm(`Are you sure you want to delete this payment?`, () => {
            this.coinprintService.deleteInvoicePayment(this.current_invoice, payment)
            .subscribe(() => {
                this.getPayments();
                this.getInvoices();
                toastr.success('Payment deleted!');
            });
        });
    }

    invoiceToPay(invoice) {
        return (Math.floor(+invoice.final_total * 100) - Math.floor(+invoice.paid_amount * 100));
    }

    downloadCDRs(invoice) {
        this.cdrService.downloadCDRCSV(this.account_id, invoice);
    }
}
