<a class="card-stats mb-4 mb-xl-0" *ngIf="this.sips.length" (click)="toggleVoIPCard();">
    <div class="card-body">
        <div class="row">
            <div class="col titlecol">
                <p class="card-title text-uppercase text-muted mb-0">VoIP User Setup and Status <i class="fas fa-chevron-down"></i></p>
                <ng-container *ngIf="voip_card_toggle === true">
                    <hr style ="border-top: 1px solid white;">
                    <div class="bound-parent">
                        <div class="bound-box">
                            <span class="text-sm text-nowrap">
                                Click a user below to setup your device.
                            </span>
                            <div class="key-box">
                                <b style="margin-right:1%; border-right:18px solid rgb(83, 83, 83);"></b>Offline/Unavailiable
                                <b style="margin-right:1%; margin-left:2%; border-right:18px solid rgb(230, 41, 16);"></b>On a Call
                                <b style="margin-right:1%; margin-left:2%; border-right:18px solid rgba(43,161,3);"></b>Ready to use
                            </div>
                            <div class="voip-grid">
                                <div *ngFor="let switchboard_object of sips; let i = index" class="voip-card-background">
                                    <a class="text-center" (click)="setupUser(switchboard_object)">
                                        <div class="voip-card cardrounded"
                                            [ngClass]="{
                                                'bg-use': busyLights[switchboard_object.extension_number] === 'In use',
                                                'bg-ringing': busyLights[switchboard_object.extension_number] === 'Ringing',
                                                'bg-active': busyLights[switchboard_object.extension_number] === 'Not in use'
                                            }">
                                            <!-- <i class="fas fa-phone"></i> -->
                                        </div>
                                        <p class="voip-title">{{ switchboard_object.object_data.name }}</p>
                                    </a>
                                </div>
                            </div>
                            <br>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</a>

<ng-template #loading>
    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
</ng-template>
