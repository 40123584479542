import { Component, OnInit, Input, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { LoginService } from '../../../login/login.service';
import { SwitchboardService } from '../../../switchboard/switchboard.service';
import { MediaService } from '../../../audio/media.service';

import { SwitchboardObject } from '../../../switchboard/switchboard_object';
import { AudioHelper } from '../../../tools/audio';

declare var toastr: any;

@Component({
    selector: 'app-portal-component-media-select',
    templateUrl: './portal-component-media-select.component.html',
    styleUrls: ['./portal-component-media-select.component.css']
})
export class PortalComponentMediaSelectComponent implements OnInit, OnDestroy {

    @Input() account_id;
    @Input() switchboard_object: SwitchboardObject;
    @Input() tts_text;
    @Input() type = 'media';
    @Input() update = true;

    media: any[];
    media_preview: any;
    new_media: string = null;
    use_existing = true;

    upload_type = 'existing'; // existing, upload, tts

    uploading = false;

    @Output() save = new EventEmitter<any>();

    @ViewChild('media_modal') mediaModal;
    @ViewChild('tts_form') tts_form;

    constructor(
        private mediaService: MediaService,
        private switchboardService: SwitchboardService,
    ) { }

    ngOnInit() {
        this.getMedia();
    }

    ngOnDestroy() {
        this.stopCurrentMedia();
    }

    open() {
        this.new_media = this.switchboard_object.object_data[`switchboard_${this.type}_file_uuid`];
        this.upload_type = !!this.new_media ? 'existing' : null;
        this.mediaModal.open();
    }

    getMedia() {
        let request;
        if (this.account_id) {
            if (this.type === 'media') {
                request = this.mediaService.getSwitchboardMedia(this.account_id);
            }
            if (this.type === 'music') {
                request = this.mediaService.getSwitchboardMusic(this.account_id);
            }
            request.subscribe(media => {
                this.media = media;
            });
        } else {
            setTimeout(() => this.getMedia(), 1000);
        }
    }

    getMediaFile() {
        if (this.media) {
            for (let file of this.media) {
                if (file.uuid === this.switchboard_object.object_data[`switchboard_${this.type}_file_uuid`]) {
                    return file;
                }
            }
        }
        return null;
    }

    stopCurrentMedia() {
        if (this.media_preview) {
            const player = <HTMLMediaElement> document.getElementById('media-preview');
            player.pause();
        }
    }

    previewMedia() {
        let request;
        this.stopCurrentMedia();

        if (this.type === 'media') {
            request = this.mediaService.downloadSwitchboardMedia(this.account_id, {uuid: this.new_media});
        }
        if (this.type === 'music') {
            request = this.mediaService.downloadSwitchboardMusic(this.account_id, {uuid: this.new_media});
        }
        request.subscribe(data => {
            this.media_preview = true;
            setTimeout(() => AudioHelper.loadFileToAudioElement('media-preview')(data), 100);
        });
    }

    close() {
        this.mediaModal.close();
        this.save.emit();
    }

    saveMedia() {
        switch (this.upload_type) {
            case 'existing':
                this.saveExistingMedia();
                break;
            case 'tts':
                this.tts_form.create();
                break;
            default:
                this.close();
        }
    }

    updateObject(data, callback) {
        if (this.update) {
            this.switchboardService.updateSwitchboardObjectForAccount(this.account_id, data)
                .subscribe(callback);
        } else {
            callback();
        }
    }

    saveExistingMedia() {
        const data = {
            uuid: this.switchboard_object.uuid,
        };
        data[`switchboard_${this.type}_file_uuid`] = this.new_media;

        this.updateObject(data, () => {
            toastr.success(`Saved media!`);
            this.switchboard_object.object_data[`switchboard_${this.type}_file_uuid`] = this.new_media;
            this.close();
        });
    }

    uploadFile(file) {
        this.uploading = true;
        const data = {
            uuid: this.switchboard_object.uuid,
        };
        data[`switchboard_${this.type}_file_uuid`] = file.uuid;
        if (file.is_verified != 0) {
            this.updateObject(data, response => {
                this.switchboard_object.object_data[`switchboard_${this.type}_file_uuid`] = file.uuid;
                this.uploading = false;
                this.getMedia();
                this.close();
            });
        }
        else {
            this.uploading = false;
            this.getMedia();
            this.close();
            toastr.success('File Uploaded and pending approval')
        }
    }

    createTTS(media) {
        this.uploading = true;
        this.updateObject({
            uuid: this.switchboard_object.uuid,
            switchboard_media_file_uuid: media.uuid,
        }, response => {
            this.switchboard_object.object_data.switchboard_media_file_uuid = media.uuid;
            this.uploading = false;
            this.getMedia();
            this.close();
        });
    }

}
