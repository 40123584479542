import { Directive, ViewContainerRef, TemplateRef, OnInit, Input } from '@angular/core';

import { LoginService } from '../login/login.service';
import { ServicesService } from '../services/services.service';

@Directive({
    selector: '[appViperHasInboundBundles]'
})
export class HasInboundBundlesDirective implements OnInit {

    constructor(
        private viewContainerRef: ViewContainerRef,
        private template: TemplateRef<any>,
        private servicesService: ServicesService,
    ) { }

    ngOnInit(): void {
        this.servicesService.getCachedServices()
            .subscribe(services => this.inboundBundlesExist(services));
    }

    inboundBundlesExist(services) {
        this.viewContainerRef.clear();
        const inboundBundles = services.filter(service => service.switchboard_type === 'Number' && service.has_bundle);
        const hasInboundBundle = !!inboundBundles.length;
        if (hasInboundBundle) {
            this.viewContainerRef.createEmbeddedView(this.template);
        }
    }

}
