<div class="table-responsive">
    <table class="table align-items-center table-flush table-striped">
        <thead>
            <tr>
                <th>Due At</th>
                <th>Total</th>
                <th>&nbsp;</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let proforma of proformas?.data">
                <tr>
                    <td>{{ proforma.due_at|format_local_date }}</td>
                    <td>&pound;{{ proforma.final_total|number:'1.2-2' }}</td>
                    <td>
                        <a [href]="proforma.download_link" target="_blank" class="btn btn-primary">Download</a>
                        <button class="btn btn-success" *ngIf="!proforma.has_invoice" (click)="convert(proforma)">Convert To Invoice</button>
                    </td>
                </tr>
            </ng-container>
        </tbody>
        <tfoot>
            <tr>
                <td colspan="3">
                    <button class="btn btn-primary" (click)="newProforma()">Create a new Pro Forma</button>
                </td>
            </tr>
            <tr>
                <td colspan="3">
                    <app-pagination [pagination]="proformas" [(page)]="page" (page_change)="getProformas($event)"></app-pagination>
                </td>
            </tr>
        </tfoot>
    </table>
</div>


<app-modal title="New Pro Forma" size="big" (save)="saveProforma()" #proforma_modal>
    <div class="table-responsive" *ngIf="proforma">
        <table class="table align-items-center table-flush table-striped">
            <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th>Quantity</th>
                    <th>Price (pound)</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let line of proforma.lines">
                    <tr>
                        <td><input class="form-control" type="text" placeholder="Label" [(ngModel)]="line.label"></td>
                        <td><input class="form-control" type="text" placeholder="Quantity" [(ngModel)]="line.quantity"></td>
                        <td>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text" id="btnGroupAddon">&pound;</div>
                                </div>
                                <input class="form-control" type="text" placeholder="Price" [(ngModel)]="line.unit_price">
                            </div>
                        </td>
                        <td><button class="btn btn-sm btn-danger" (click)="removeLine(line)">X</button></td>
                    </tr>
                </ng-container>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="4">
                        <button class="btn btn-success" (click)="addLine()">Add Line</button>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>

    <div class="field">
        <label class="label">Add Tax/VAT</label>
        <div class="control">
            <input type="checkbox" [(ngModel)]="has_tax">
        </div>
    </div>

    <div class="field" *ngIf="has_tax">
        <label class="label">Tax Rate (%)</label>
        <div class="control">
            <input type="text" class="form-control" placeholder="Tax Rate" [(ngModel)]="proforma.tax_rate">
        </div>
    </div>

    <div class="field" id="dp">
        <label class="label">Due Date</label>
        <div class="control">
            <input
                class="form-control"
                (click)="datepicker.toggle()"
                type="text"
                ngbDatepicker
                [(ngModel)]="datepickerdate"
                #datepicker="ngbDatepicker"
                (dateSelect)="onDateSelect($event)"
            />
        </div>
    </div>
</app-modal>
