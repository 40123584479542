import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';

import { MediaService } from '../../media.service';
import { SwitchboardService } from '../../../switchboard/switchboard.service';

declare var toastr: any;
declare var $: any;

@Component({
  selector: 'app-media-upload-form',
  templateUrl: './media-upload-form.component.html',
  styleUrls: ['./media-upload-form.component.css']
})
export class MediaUploadFormComponent implements OnInit {

    @Input() account_id: number;
    @Input() uploadTo = 'switchboard';

    @Output() save = new EventEmitter<any>();

    uploading = false;

    constructor(
        private mediaService: MediaService,
        private switchboardService: SwitchboardService,
    ) { }

    ngOnInit() {
    }

    prepareFileData(fileData) {
        const data = new FormData();

        data.append('file', fileData, fileData.name);
        data.append('name', fileData.name);

        return data;
    }

    uploadData(data) {
        this.uploading = true;

        let request;
        switch (this.uploadTo) {
            case 'switchboard':
                request = this.mediaService.uploadSwitchboardMedia(this.account_id, data);
                break;
            case 'music':
                request = this.mediaService.uploadSwitchboardMusic(this.account_id, data);
                break;
            case 'library':
                request = this.mediaService.uploadLibraryMusic(data);
                break;
            case 'dno-blocklist':
                request = this.switchboardService.uploadDNOBlocklist(data);
                break;
        }

        request.subscribe(uploaded_file => {
            this.uploading = false;
            this.save.emit(uploaded_file);
            toastr.success(`${uploaded_file.name} uploaded!`);
            if (uploaded_file.is_verified == '0') {
                toastr.success(`File awaiting Verification`);
            }
        });
    }

    

    fileBrowseHandler(files) {
        const fileData = <File>files[0];
        const data = this.prepareFileData(fileData);
        this.uploadData(data);
    }

}
