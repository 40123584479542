import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LoginService } from '../../../login/login.service';
import { SwitchboardService } from '../../../switchboard/switchboard.service';
import { ServicesService } from '../../../services/services.service';

declare var moment: any;
declare var toastr: any;
declare var alertify: any;
declare var $: any;

@Component({
    selector: 'app-handset-speeddial',
    templateUrl: './handset-speeddial.component.html',
    styleUrls: ['./handset-speeddial.component.css']
})
export class HandsetSpeeddialComponent implements OnInit {

    available_devices;
    
    account_id;
    handset_id;

    handset;
    handset_blfs;
    sips;
    blfs;

    blf_line: any = {};

    blfInterval;

    @ViewChild('blf_line_modal') blfModal;

    constructor(
        private route: ActivatedRoute,
        private authService: LoginService,
        private switchboardService: SwitchboardService,
        private serviceService: ServicesService,
    ) { }

    ngOnInit(): void {
        this.handset_id = +this.route.snapshot.paramMap.get('handset_id');
        this.account_id = +this.route.snapshot.paramMap.get('account_id');
        this.getHandset();
        this.getAvailableDevices();
        this.getSips();
    }

    getAvailableDevices() {
        this.switchboardService.getSupportedDevices()
            .subscribe(devices => this.available_devices = devices);
    }

    getSips() {
        if (this.account_id) {
            this.switchboardService.readSipsOnAccount(this.account_id)
                .subscribe(sips => this.sips = sips);
            this.blfInterval = setInterval(() => this.getBLFs(), 30 * 1000);
            this.getBLFs();
        } else {
            setTimeout(() => this.getSips(), 500);
        }
    }

    getBLFs() {
        this.switchboardService.getSwitchboardBlfs(this.account_id)
            .subscribe(blfs => this.blfs = blfs);
    }

    getHandset() {
        if (this.account_id) {
            this.switchboardService.getSipDevice(this.account_id, this.handset_id)
            .subscribe(handset => {
                this.handset = handset;
                this.getHandsetBLFs();
            });
        } else {
            this.authService.getCurrentAccount()
            .subscribe(account => {
                this.account_id = account.id;
                this.getHandset();
            });
        }
    }

    getHandsetBLFs() {
        this.switchboardService.getSipDeviceBLF(this.account_id, this.handset)
            .subscribe(handset_blfs => this.handset_blfs = handset_blfs);
    }

    getLineSip(blf) {
        if (this.sips) {
            for (let sip of this.sips) {
                if (+blf.speed_dial_number === sip.extension_number) {
                    return sip;
                }
            }
        }
        return null;
    }

    editBLF(blf) {
        this.blf_line = Object.assign({}, blf);
        this.blfModal.open();
    }

    saveBLF() {
        this.switchboardService.updateSipDeviceBLF(this.account_id, this.handset, this.blf_line)
        .subscribe(lines => {
            if (lines !== 'UPDATE_FAIL') {
                toastr.success('Speed Dial Updated');
                this.handset_blfs = lines;
                this.blfModal.close();
            } else {
                this.blfModal.stopLoading();
            }
        });
    }

}
