export class Account {
    id: number;
    company_name: string;
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    password_confirmation: string;
    active_state: number;
}
