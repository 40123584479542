import { Directive, ViewContainerRef, TemplateRef, OnInit, Input } from '@angular/core';

import { LoginService } from '../login/login.service';

@Directive({
    selector: '[appViperBrandIsNot]'
})
export class BrandIsNotDirective implements OnInit {

    @Input('appViperBrandIsNot') brands: string[] = [];

    constructor(
        private viewContainerRef: ViewContainerRef,
        private template: TemplateRef<any>,
        private authService: LoginService,
    ) { }

    ngOnInit(): void {
        this.authService.getCurrentApp()
        .subscribe(app => {
            this.viewContainerRef.clear();
            if (!this.brands.includes(app.short_name)) {
                this.viewContainerRef.createEmbeddedView(this.template);
            }
        });
    }

}
