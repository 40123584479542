// From http://devinstance.net/articles/20171021/rxjs-cacheable
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

declare type GetDataHandler<T> = () => Observable<T>;

export class Cacheable<T> {

    protected data: T;
    protected subjectData: Subject<T>;
    protected observableData: Observable<T>;

    private isBeingHandled: boolean;

    public getHandler: GetDataHandler<T>;

    constructor() {
        this.resetReplay();
    }

    private resetReplay(): void {
        this.isBeingHandled = false;
        this.subjectData = new ReplaySubject(1);
        this.observableData = this.subjectData.asObservable();
    }

    private createHandler(): void {
        if (!this.getHandler) {
            throw new Error('getHandler is not defined');
        }

        let handler = this.getHandler();
        if (handler) {
            handler.pipe(map((r: T) => {
                this.data = r;
                return r;
            }))
            .subscribe(
                result => this.subjectData.next(result),
                err => this.subjectData.error(err)
            );
            this.isBeingHandled = true;
        }
    }

    public isObserved(): boolean {
        return this.hasData() || this.isBeingHandled;
    }

    public getData(): Observable<T> {
        if (!this.isObserved()) {
            this.createHandler();
        }
        return this.observableData;
    }

    public hasData(): boolean {
        if (Array.isArray(this.data)) {
            return !!this.data.length;
        }
        return !!this.data;
    }

    public getRaw(): T {
        return this.data;
    }

    public resetCache(): void {
        this.data = null;
        this.resetReplay();
    }

    public refresh(): void {
        this.resetCache();
        this.getData();
    }
}
