import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';

import { Observable, of } from 'rxjs';
import { catchError, map, tap, concatMap } from 'rxjs/operators';

import { Client } from '../tools/client';

import { LoginService } from '../login/login.service';

declare var toastr: any;
declare var alertify: any;

@Injectable({
    providedIn: 'root'
})
export class SwitchboardService extends Client {
    protected serviceURL = environment.services.switchboard;

    constructor(
        http: HttpClient,
        private authService: LoginService,
    ) {
        super(http);
    }

    /* Switchboard */

    createSwitchboard(account_id) {
        return this.callApi('post', `/accounts/${account_id}/switchboard`)
        .pipe(
            tap(_ => this.log('creating switchboard for account ' + account_id)),
            catchError(this.handleError('createSwitchboard', []))
        );
    }

    getSwitchboard(switchboard_uuid) {
        return this.callApi('get', `/switchboards/${switchboard_uuid}`)
        .pipe(
            tap(_ => this.log('getting switchboard ' + switchboard_uuid)),
            catchError(this.handleError('getSwitchboard', []))
        );
    }

    updateSwitchboard(switchboard) {
        return this.callApi('put', `/switchboards/${switchboard.uuid}`, switchboard)
        .pipe(
            tap(_ => this.log('updating switchboard ' + switchboard.uuid)),
            catchError(this.handleError('updateSwitchboard', []))
        );
    }

    deleteSwitchboard(switchboard) {
        return this.callApi('delete', `/switchboards/${switchboard.uuid}`)
        .pipe(
            tap(_ => this.log('deleting switchboard ' + switchboard.uuid)),
            catchError(this.handleError('deleteSwitchboard', []))
        );
    }

    getSwitchboardFromAccount(account_id) {
        return this.callApi('get', `/accounts/${account_id}/switchboard`)
        .pipe(
            tap(_ => this.log('getting switchboard for account ' + account_id)),
            catchError(this.handleError('getSwitchboardFromAccount', []))
        );
    }

    updateSwitchboardFromAccount(account_id, switchboard) {
        return this.callApi('put', `/accounts/${account_id}/switchboard`, switchboard)
        .pipe(
            tap(_ => this.log('updating switchboard for account ' + account_id)),
            catchError(this.handleError('updateSwitchboardFromAccount', []))
        );
    }

    deleteSwitchboardFromAccount(account_id) {
        return this.callApi('delete', `/accounts/${account_id}/switchboard`)
        .pipe(
            tap(_ => this.log('deleting switchboard for account ' + account_id)),
            catchError(this.handleError('deleteSwitchboardFromAccount', []))
        );
    }

    getSwitchboardConfig(account_id) {
        return this.callApi('get', `/accounts/${account_id}/switchboard/config`)
        .pipe(
            tap(_ => this.log('getting switchboard config for account ' + account_id)),
            catchError(this.handleError('getSwitchboardConfig', []))
        );
    }

    updateSwitchboardConfig(account_id, config) {
        return this.callApi('put', `/accounts/${account_id}/switchboard/config`, config)
        .pipe(
            tap(_ => this.log('updating switchboard config for account ' + account_id)),
            catchError(this.handleError('updateSwitchboardConfig', []))
        );
    }

    commitSwitchboard(switchboard) {
        return this.callApi('post', `/switchboards/${switchboard.id}/commit`)
        .pipe(
            tap(_ => this.log(`comitting switchboard for switchboard ${switchboard.id}`)),
            catchError(this.handleError('createSwitchboard', []))
        );
    }

    commitAccount(account_id) {
        return this.callApi('post', `/accounts/${account_id}/switchboard/commit`)
        .pipe(
            tap(_ => this.log(`comitting switchboard for account ${account_id}`)),
            catchError(this.handleError('createSwitchboard', []))
        );
    }

    /* Switchboard Objects */

    getSwitchboardObjectsForAccount(account_id) {
        return this.callApi('get', `/accounts/${account_id}/switchboard/objects`)
        .pipe(
            tap(_ => this.log('getting switchboard objects for account ' + account_id)),
            catchError(this.handleError('getSwitchboardObjectsForAccount', []))
        );
    }

    getSwitchboardObject(switchboard_object_uuid: string) {
        return this.authService.getCurrentUser()
            .pipe(concatMap(user => this.readSwitchboardObjectForAccount(user.account_id, switchboard_object_uuid)));
    }

    readSwitchboardObjectForAccount(account_id, switchboard_object_uuid) {
        return this.callApi('get', `/accounts/${account_id}/switchboard/objects/${switchboard_object_uuid}`)
        .pipe(
            tap(_ => this.log(`getting switchboard object ${switchboard_object_uuid}`)),
            catchError(this.handleError('getSwitchboardObject', null))
        );
    }

    updateSwitchboardObjectForAccount(account_id, switchboard_object) {
        return this.callApi('put', `/accounts/${account_id}/switchboard/objects/${switchboard_object.uuid}`, switchboard_object)
        .pipe(
            tap(_ => this.log('updating switchboard object ' + switchboard_object.uuid + ' for account ' + account_id)),
            catchError(this.handleError('updateSwitchboardObjectForAccount', []))
        );
    }

    updateSwitchboardObjectExtensionForAccount(account_id, switchboard_object, extension_number) {
        return this.callApi('put', 
            `/accounts/${account_id}/switchboard/objects/${switchboard_object.uuid}/update_extension_number`, {extension_number})
        .pipe(
            tap(_ => this.log('updating switchboard object extension_number ' + switchboard_object.uuid + ' for account ' + account_id)),
            catchError(this.handleError('updateSwitchboardObjectExtensionForAccount', []))
        );
    }


    deleteSwitchboardObjectsForAccount(account_id, switchboard_object) {
        return this.callApi('delete', `/accounts/${account_id}/switchboard/objects/${switchboard_object.uuid}`)
        .pipe(
            tap(_ => this.log('deleting switchboard object ' + switchboard_object.uuid + ' for account ' + account_id)),
            catchError(this.handleError('deleteSwitchboardObjectsForAccount', []))
        );
    }

    createObjectOnAccount(account_id, object_type, switchboard_object) {
        return this.callApi('post', `/accounts/${account_id}/switchboard/objects/${object_type}`, switchboard_object)
        .pipe(
            tap(_ => this.log(`creating switchboard object ${object_type} for account ${account_id}`)),
            catchError(this.handleError('createObjectOnAccount', []))
        );
    }

    readObjectsOnAccount(account_id, object_type, data = null) {
        data = Object.assign({}, data || {});
        return this.callApi('get', `/accounts/${account_id}/switchboard/objects/${object_type}`, data)
        .pipe(
            tap(_ => this.log(`get switchboard objects ${object_type} for account ${account_id}`)),
            catchError(this.handleError('createObjectOnAccount', []))
        );
    }

    createQueueOnAccount = (account_id, queue) => this.createObjectOnAccount(account_id, 'queues', queue);
    readQueuesOnAccount = (account_id, data = null) => this.readObjectsOnAccount(account_id, 'queues', data);

    createGroupOnAccount = (account_id, queue) => this.createObjectOnAccount(account_id, 'groups', queue);
    readGroupsOnAccount = (account_id, data = null) => this.readObjectsOnAccount(account_id, 'groups', data);

    createAnnouncementOnAccount = (account_id, queue) => this.createObjectOnAccount(account_id, 'announcements', queue);
    readAnnouncementsOnAccount = (account_id, data = null) => this.readObjectsOnAccount(account_id, 'announcements', data);

    createMenuOnAccount = (account_id, queue) => this.createObjectOnAccount(account_id, 'menus', queue);
    readMenusOnAccount = (account_id, data = null) => this.readObjectsOnAccount(account_id, 'menus', data);

    createVoicemailOnAccount = (account_id, queue) => this.createObjectOnAccount(account_id, 'voicemails', queue);
    readVoicemailsOnAccount = (account_id, data = null) => this.readObjectsOnAccount(account_id, 'voicemails', data);

    createNumberOnAccount = (account_id, queue) => this.createObjectOnAccount(account_id, 'numbers', queue);
    readNumbersOnAccount = (account_id, data = null) => this.readObjectsOnAccount(account_id, 'numbers', data);

    createSipOnAccount = (account_id, queue) => this.createObjectOnAccount(account_id, 'sips', queue);
    readSipsOnAccount = (account_id, data = null) => this.readObjectsOnAccount(account_id, 'sips', data);

    toggleSwitchboardObjectValue(account_id, switchboard_object, field, options) {
        const defaults = {
            enable: 'enable',
            disable: 'disable',
            field_name: field.replace(/_/g, ' '),
            confirm_message: (_direction, _options) => `Are you sure you want to ${_direction} ${_options.field_name}?`,
            success_message: (_direction, _options) => `${_options.field_name} ${_direction}d!`,
        };
        options = Object.assign(defaults, options);
        const fieldValue = switchboard_object.object_data[field];
        const toggle = +!fieldValue;
        const direction = toggle ? options.enable : options.disable;
        const data = { uuid: switchboard_object.uuid };
        data[field] = toggle;

        alertify.confirm(options.confirm_message(direction, options), () => {
            this.updateSwitchboardObjectForAccount(account_id, data)
            .subscribe(response => {
                toastr.success(options.success_message(direction, options));
                switchboard_object.object_data[field] = toggle;
            });
        });
    }

    getSwitchboardBlfs(account_id) {
        return this.callApi('get', `/accounts/${account_id}/switchboard/blfs`)
        .pipe(
            tap(_ => this.log(`getting switchboard blf for ${account_id}`)),
            catchError(this.handleError('getSwitchboardBlfs', null))
        );
    }

    /* Members */

    getInternalMembers(account_id, object) {
        return this.callApi('get', `/accounts/${account_id}/switchboard/objects/groups/${object.uuid}/internal_members`)
        .pipe(
            tap(_ => this.log(`fetching internal members for ${object.object_data.name}`)),
            catchError(this.handleError('getInternalMembers', []))
        );
    }

    getExternalMembers(account_id, object) {
        return this.callApi('get', `/accounts/${account_id}/switchboard/objects/groups/${object.uuid}/external_members`)
        .pipe(
            tap(_ => this.log(`fetching external members for ${object.object_data.name}`)),
            catchError(this.handleError('getExternalMembers', []))
        );
    }

    createInternalMember(account_id, object, member) {
        return this.callApi('post', `/accounts/${account_id}/switchboard/objects/groups/${object.uuid}/internal_members`, member)
        .pipe(
            tap(_ => this.log(`creating internal member for ${object.object_data.name}`)),
            catchError(this.handleError('createInternalMembers', []))
        );
    }

    createExternalMember(account_id, object, member) {
        return this.callApi('post', `/accounts/${account_id}/switchboard/objects/groups/${object.uuid}/external_members`, member)
        .pipe(
            tap(_ => this.log(`creating external member for ${object.object_data.name}`)),
            catchError(this.handleError('createExternalMembers', []))
        );
    }

    updateInternalMember(account_id, object, member) {
        return this.callApi('put', `/accounts/${account_id}/switchboard/objects/groups/${object.uuid}/internal_members/${member.uuid}`, member)
        .pipe(
            tap(_ => this.log(`updating internal member for ${object.object_data.name}`)),
            catchError(this.handleError('updateInternalMembers', []))
        );
    }

    updateExternalMember(account_id, object, member) {
        return this.callApi('put', `/accounts/${account_id}/switchboard/objects/groups/${object.uuid}/external_members/${member.uuid}`, member)
        .pipe(
            tap(_ => this.log(`updating external member for ${object.object_data.name}`)),
            catchError(this.handleError('updateExternalMembers', []))
        );
    }

    deleteInternalMember(account_id, object, member) {
        return this.callApi('delete', `/accounts/${account_id}/switchboard/objects/groups/${object.uuid}/internal_members/${member.uuid}`)
        .pipe(
            tap(_ => this.log(`updating internal member for ${object.object_data.name}`)),
            catchError(this.handleError('deleteInternalMembers', []))
        );
    }

    deleteExternalMember(account_id, object, member) {
        return this.callApi('delete', `/accounts/${account_id}/switchboard/objects/groups/${object.uuid}/external_members/${member.uuid}`)
        .pipe(
            tap(_ => this.log(`updating external member for ${object.object_data.name}`)),
            catchError(this.handleError('deleteExternalMembers', []))
        );
    }

    /* Rules */

    getSwitchboardObjectRules(account_id, switchboard_object) {
        return this.callApi('get', `/accounts/${account_id}/switchboard/objects/${switchboard_object}/rules`)
        .pipe(
            tap(_ => this.log(`fetching rules for switchboard object ${switchboard_object}`)),
            catchError(this.handleError('getSwitchboardObjectRules', []))
        );
    }

    createSwitchboardObjectRule(account_id, switchboard_object, rule) {
        return this.callApi('post', `/accounts/${account_id}/switchboard/objects/${switchboard_object}/rules`, rule)
        .pipe(
            tap(_ => this.log(`creating rule for switchboard object ${switchboard_object}`)),
            catchError(this.handleError('createSwitchboardObjectRule', []))
        );
    }

    updateSwitchboardObjectRule(account_id, switchboard_object, rule) {
        return this.callApi('put', `/accounts/${account_id}/switchboard/objects/${switchboard_object}/rules/${rule.uuid}`, rule)
        .pipe(
            tap(_ => this.log(`updating rule for switchboard object ${switchboard_object}`)),
            catchError(this.handleError('updateSwitchboardObjectRule', []))
        );
    }

    deleteSwitchboardObjectRule(account_id, switchboard_object, rule) {
        return this.callApi('delete', `/accounts/${account_id}/switchboard/objects/${switchboard_object}/rules/${rule.uuid}`)
        .pipe(
            tap(_ => this.log(`deleting rule for switchboard object ${switchboard_object}`)),
            catchError(this.handleError('deleteSwitchboardObjectRule', []))
        );
    }

    /* temporary rules */

    getSwitchboardObjectTempRules(account_id, switchboard_object) {
        return this.callApi('get', `/accounts/${account_id}/switchboard/objects/${switchboard_object}/temp_rules`)
        .pipe(
            tap(_ => this.log(`fetching rules for switchboard object ${switchboard_object}`)),
            catchError(this.handleError('getSwitchboardObjectTempRules', []))
        );
    }

    createSwitchboardObjectTempRule(account_id, switchboard_object, rule) {
        return this.callApi('post', `/accounts/${account_id}/switchboard/objects/${switchboard_object}/temp_rules`, rule)
        .pipe(
            tap(_ => this.log(`creating rule for switchboard object ${switchboard_object}`)),
            catchError(this.handleError('createSwitchboardObjectTempRule', []))
        );
    }

    updateSwitchboardObjectTempRule(account_id, switchboard_object, rule) {
        return this.callApi('put', `/accounts/${account_id}/switchboard/objects/${switchboard_object}/temp_rules/${rule.uuid}`, rule)
        .pipe(
            tap(_ => this.log(`updating rule for switchboard object ${switchboard_object}`)),
            catchError(this.handleError('updateSwitchboardObjectTempRule', []))
        );
    }

    deleteSwitchboardObjectTempRule(account_id, switchboard_object, rule) {
        return this.callApi('delete', `/accounts/${account_id}/switchboard/objects/${switchboard_object}/temp_rules/${rule.uuid}`)
        .pipe(
            tap(_ => this.log(`deleting rule for switchboard object ${switchboard_object}`)),
            catchError(this.handleError('deleteSwitchboardObjectTempRule', []))
        );
    }

    /* Servers */

    getServers(direction) {
        return this.callApi('get', `/servers/${direction}`)
        .pipe(
            tap(_ => this.log(`fetching ${direction} servers`)),
            catchError(this.handleError('getServers', []))
        );
    }

    createServer(direction, server) {
        return this.callApi('post', `/servers/${direction}`, server)
        .pipe(
            tap(_ => this.log(`creating ${direction} server`)),
            catchError(this.handleError('createServer', []))
        );
    }

    updateServer(direction, server) {
        return this.callApi('put', `/servers/${direction}/${server.id}`, server)
        .pipe(
            tap(_ => this.log(`updating ${direction} server ${server.id}`)),
            catchError(this.handleError('updateServer', []))
        );
    }

    deleteServer(direction, server) {
        return this.callApi('delete', `/servers/${direction}/${server.id}`)
        .pipe(
            tap(_ => this.log(`deleting ${direction} server ${server.id}`)),
            catchError(this.handleError('deleteServer', []))
        );
    }

    getServerDropletInfo(direction, server) {
        return this.callApi('get', `/servers/${direction}/${server.id}/droplet`)
        .pipe(
            tap(_ => this.log(`fetching droplet info for ${direction} server ${server.id}`)),
            catchError(this.handleError('getServerDropletInfo', []))
        );
    }

    /* Inbound Server */

    getInboundServers = () => this.getServers('inbound');
    createInboundServer = (server) => this.createServer('inbound', server);
    updateInboundServer = (server) => this.updateServer('inbound', server);
    deleteInboundServer = (server) => this.deleteServer('inbound', server);

    /* Outbound server */

    getOutboundServers = () => this.getServers('outbound');
    createOutboundServer = (server) => this.createServer('outbound', server);
    updateOutboundServer = (server) => this.updateServer('outbound', server);
    deleteOutboundServer = (server) => this.deleteServer('outbound', server);

    commitOutboundServer(server) {
        return this.call('post', `https://${server}/commit`);
    }

    /* Switchboard Servers */

    getSwitchboardServers = () => this.getServers('switchboard');
    createSwitchboardServer = (server) => this.createServer('switchboard', server);
    updateSwitchboardServer = (server) => this.updateServer('switchboard', server);
    deleteSwitchboardServer = (server) => this.deleteServer('switchboard', server);

    getSwitchboardServerSecret(server) {
        return this.callApi('get', `/servers/switchboard/${server.id}/secret`)
        .pipe(
            tap(_ => this.log(`getting secret for switchboard server ${server.id}`)),
            catchError(this.handleError('getSwitchboardServerSecret', []))
        );
    }

    commitSwitchboardServer(server) {
        return this.call('post', `https://${server}/commit/all`);
    }

    /* Server Trunks */

    getServerTrunks() {
        return this.callApi('get', `/server_trunks`)
        .pipe(
            tap(_ => this.log(`fetching switchboard servers`)),
            catchError(this.handleError('getServerTrunks', []))
        );
    }

    createServerTrunk(server) {
        return this.callApi('post', `/server_trunks`, server)
        .pipe(
            tap(_ => this.log(`creating switchboard server`)),
            catchError(this.handleError('createSwitchboardObjectRule', []))
        );
    }

    updateServerTrunk(server) {
        return this.callApi('put', `/server_trunks/${server.id}`, server)
        .pipe(
            tap(_ => this.log(`updating switchboard server ${server.id}`)),
            catchError(this.handleError('updateServerTrunk', []))
        );
    }

    deleteServerTrunk(server) {
        return this.callApi('delete', `/server_trunks/${server.id}`)
        .pipe(
            tap(_ => this.log(`deleting switchboard server ${server.id}`)),
            catchError(this.handleError('deleteServerTrunk', []))
        );
    }

    /* Number Address */

    getNumberAddress(account_id, switchboard_object) {
        return this.callApi('get', `/accounts/${account_id}/switchboard/objects/numbers/${switchboard_object.uuid}/address`)
        .pipe(
            tap(_ => this.log(`fetching number address for object ${switchboard_object.uuid}`)),
            catchError(this.handleError('getNumberAddress', []))
        );
    }

    updateNumberAddress(account_id, switchboard_object, address) {
        return this.callApi('put', `/accounts/${account_id}/switchboard/objects/numbers/${switchboard_object.uuid}/address`, address)
        .pipe(
            tap(_ => this.log(`updating number address ${address.id} for object ${switchboard_object.uuid}`)),
            catchError(this.handleError('updateNumberAddress', 'FAIL'))
        );
    }

    deleteNumberAddress(switchboard_object) {
        return this.callApi('delete', `/accounts/${switchboard_object.account_id}/switchboard/objects/numbers/${switchboard_object.uuid}/address`)
        .pipe(
            tap(_ => this.log(`deleting number address for object ${switchboard_object.uuid}`)),
            catchError(this.handleError('deleteNumberAddress', 'FAIL'))
        );
    }

    /* Notes */

    getNotes(account_id) {
        return this.callApi('get', `/accounts/${account_id}/switchboard/notes`)
        .pipe(
            tap(_ => this.log(`fetching switchboard notes`)),
            catchError(this.handleError('getNotes', []))
        );
    }

    createNote(account_id, note) {
        return this.callApi('post', `/accounts/${account_id}/switchboard/notes`, note)
        .pipe(
            tap(_ => this.log(`creating switchboard note`)),
            catchError(this.handleError('createSwitchboardObjectRule', []))
        );
    }

    updateNote(account_id, note) {
        return this.callApi('put', `/accounts/${account_id}/switchboard/notes/${note.id}`, note)
        .pipe(
            tap(_ => this.log(`updating switchboard note ${note.id}`)),
            catchError(this.handleError('updateNote', []))
        );
    }

    deleteNote(account_id, note) {
        return this.callApi('delete', `/accounts/${account_id}/switchboard/notes/${note.id}`)
        .pipe(
            tap(_ => this.log(`deleting switchboard note ${note.id}`)),
            catchError(this.handleError('deleteNote', []))
        );
    }

    /* Numbering */

    searchNumbers(country_code, area, minScore = null, maxScore = null, searchTerm = null) {
        let url = `/numbers/search/${country_code}/${area}/${minScore}/${maxScore}`;

        if (searchTerm) {
            url += `/${searchTerm}`;
        }

        return this.callApi('get', url)
        .pipe(
            tap(_ => this.log(`searching phone numbers`)),
            catchError(this.handleError('searchNumbers', []))
        );
    }

    getCountryCodes() {
        return this.callApi('get', `/numbers/countries`)
        .pipe(
            tap(_ => this.log(`fetching area codes`)),
            catchError(this.handleError('getCountryCodes', []))
        );
    }

    getAreaCodes(country) {
        return this.callApi('get', `/numbers/areas/${country}`)
        .pipe(
            tap(_ => this.log(`fetching area codes`)),
            catchError(this.handleError('getAreaCodes', []))
        );
    }

    getTopNumbers(country, area, minScore = 0) {
        return this.callApi('get', `/numbers/top/${country}/${area}/${minScore}`)
        .pipe(
            tap(_ => this.log(`fetching top numbers`)),
            catchError(this.handleError('getTopNumbers', []))
        );
    }

    /* Call Restriction */

    getAllCallRestrictions(account_id, direction, page = 1) {
        return this.callApi('get', `/accounts/${account_id}/switchboard/restrictions/${direction}`, {page: page})
        .pipe(
            tap(_ => this.log(`getting ${direction} call restrictions`)),
            catchError(this.handleError('getAllCallRestrictions', []))
        );
    }

    createCallRestriction(account_id, direction, number) {
        return this.callApi('post', `/accounts/${account_id}/switchboard/restrictions/${direction}`, {
            e164_number: number,
        })
        .pipe(
            tap(_ => this.log(`creating a call restriction on ${direction}`)),
            catchError(this.handleError('createCallRestriction', []))
        );
    }

    updateCallRestriction(account_id, direction, uuid, number) {
        return this.callApi('put', `/accounts/${account_id}/switchboard/restrictions/${direction}/${uuid}`, {
            e164_number: number,
        })
        .pipe(
            tap(_ => this.log(`updating a call restriction on ${direction}`)),
            catchError(this.handleError('updateCallRestriction', []))
        );
    }

    deleteCallRestriction(account_id, direction, e164_number) {
        return this.callApi('delete', `/accounts/${account_id}/switchboard/restrictions/${direction}/${e164_number}`)
        .pipe(
            tap(_ => this.log(`updating a call restriction on ${direction}`)),
            catchError(this.handleError('deleteCallRestriction', []))
        );
    }

    /* Global Restrictions */

    getGlobalCallRestrictions(direction, page = 1, search = null) {
        let data = { page: page };
        if (search) {
            data['e164_number'] = search;
        }
        return this.callApi('get', `/call_restrictions/${direction}`, data)
        .pipe(
            tap(_ => this.log(`getting ${direction} call restrictions`)),
            catchError(this.handleError('getGlobalCallRestrictions', []))
        );
    }

    createGlobalCallRestriction(direction, number, level = 1) {
        return this.callApi('post', `/call_restrictions/${direction}`, {
            e164_number: number,
            block_level: level,
        })
        .pipe(
            tap(_ => this.log(`creating a call restriction on ${direction}`)),
            catchError(this.handleError('createGlobalCallRestriction', []))
        );
    }

    updateGlobalCallRestriction(direction, number, level = 1) {
        return this.callApi('put', `/call_restrictions/${direction}/${number}`, {
            block_level: level,
        })
        .pipe(
            tap(_ => this.log(`updating a call restriction on ${direction}`)),
            catchError(this.handleError('updateGlobalCallRestriction', []))
        );
    }

    deleteGlobalCallRestriction(direction, number) {
        return this.callApi('delete', `/call_restrictions/${direction}/${number}`)
        .pipe(
            tap(_ => this.log(`updating a call restriction on ${direction}`)),
            catchError(this.handleError('deleteGlobalCallRestriction', []))
        );
    }

    clickToCall(switchboard_object, sip) {
        return this.callApi('post', `/clicktocall/admin/${switchboard_object.uuid}`, {
            from_sip: sip.extension_number
        }).pipe(
            tap(_ => this.log(`Calling ${sip.extension_number} with ${switchboard_object.object_data.name}`)),
            catchError(this.handleError('clickToCall', []))
        ).subscribe(() => toastr.success(`Calling ${sip.extension_number}...`));
    }

    adminSearchNumbers(search, page = 1) {
        return this.callApi('get', `/admin/search/switchboard_objects/numbers/${search}`, { page: page })
        .pipe(
            tap(_ => this.log(`Searching numbers for ${search}`)),
            catchError(this.handleError('adminSearchNumbers', []))
        );
    }

    adminSearchDivertNumbers(search, page = 1) {
        return this.callApi('get', `/admin/search/switchboard_objects/external_group_members/${search}`, { page: page })
        .pipe(
            tap(_ => this.log(`Searching divert numbers for ${search}`)),
            catchError(this.handleError('adminSearchDivertNumbers', []))
        );
    }

    getRegistrationServer(account) {
        return this.callApi('get', `/accounts/${account}/switchboard/server`)
        .pipe(
            tap(_ => this.log(`Get registration server for ${account}`)),
            catchError(this.handleError('getRegistrationServer', []))
        );
    }

    getAccountCommitStats(account) {
        return this.callApi('get', `/accounts/${account}/switchboard/server/stats/commit`)
        .pipe(
            tap(_ => this.log(`Get commit stats for ${account}`)),
            catchError(this.handleError('getAccountCommitStats', []))
        );
    }

    getPBXCommitStats(pbx) {
        return this.call('get', `https://${pbx}/stats/commit`)
        .pipe(
            tap(_ => this.log(`Get commit stats for ${pbx}`)),
            catchError(this.handleError('getAccountCommitStats', []))
        );
    }

    /* DNO Call Restrictions */

    getDNOBlocklist() {
        return this.callApi('get', `/admin/blacklist`)
        .pipe(
            tap(_ => this.log(`Get DNO Blacklist`)),
            catchError(this.handleError('getDNOBlocklist', []))
        );
    }

    uploadDNOBlocklist(data) {
        return this.upload('post', this.serviceURL + `/admin/blacklist`, data)
        .pipe(
            tap(_ => this.log(`uploading DNO Blocklist`)),
            catchError(this.handleError('uploadDNOBlocklist', []))
        );
    }

    /* Sip Device Provisioning */

    getSupportedDevices() {
        return this.callApi('get', `/provision/supported_devices`)
        .pipe(
            tap(_ => this.log(`Get supported sip devices`)),
            catchError(this.handleError('getSupportedDevices', []))
        );
    }


    getSipDevices(account_id) {
        return this.callApi('get', `/accounts/${account_id}/provision/sip_devices`)
        .pipe(
            tap(_ => this.log(`fetching sip devices for ${account_id}`)),
            catchError(this.handleError('getSipDevices', []))
        );
    }

    getSipDevice(account_id, handset_id) {
        return this.callApi('get', `/accounts/${account_id}/provision/sip_devices/${handset_id}`)
        .pipe(
            tap(_ => this.log(`fetching sip device ${handset_id} for ${account_id}`)),
            catchError(this.handleError('getSipDevice', []))
        );
    }

    getSipDeviceAvailableLines(account_id, handset_id) {
        return this.callApi('get', `/accounts/${account_id}/provision/sip_devices/${handset_id}/lines/options`)
        .pipe(
            tap(_ => this.log(`fetching sip device available lines for ${handset_id} for ${account_id}`)),
            catchError(this.handleError('getSipDeviceAvailableLines', []))
        );
    }

    createSipDevice(account_id, device) {
        return this.callApi('post', `/accounts/${account_id}/provision/sip_devices`, device)
        .pipe(
            tap(_ => this.log(`creating sip device for ${account_id}`)),
            catchError(this.handleError('createSipDevice', []))
        );
    }

    createCloudSoftphone(account_id, device, password) {
        device.device_manufacturer = 'IPVN';
        device.device_model = 'Cloud Softphone';
        device.mac_code = Number('99'+account_id+''+device.extension_number);
        device.admin_username = account_id+''+device.extension_number;
        device.admin_password = password;
        return this.callApi('post', `/accounts/${account_id}/provision/sip_devices`, device)
        .pipe(
            tap(_ => this.log(`creating sip device for ${account_id}`)),
            catchError(this.handleError('createSipDevice', []))
        );
    }

    updateCloudSoftphone(account_id, handset_id, password, sip) {
        sip.device_manufacturer = 'IPVN';
        sip.device_model = 'Cloud Softphone';
        sip.mac_code = Number('99'+account_id+''+sip.extension_number);
        sip.admin_username = account_id+''+sip.extension_number;
        sip.admin_password = password;
        return this.callApi('put', `/accounts/${account_id}/provision/sip_devices/${handset_id}`, sip)
        .pipe(
            tap(_ => this.log(`updating sip device ${handset_id} for ${account_id}`)),
            catchError(this.handleError('updateSipDevice', []))
        );
    }

    updateCloudSoftphoneLine(account_id, line, cloud_user) {
        return this.callApi('put', `/accounts/${account_id}/provision/sip_devices/${line.id}/lines/${line.sip_device_lines[0].id}`, {
            switchboard_object_uuid: cloud_user,
        })
        .pipe(
            tap(_ => this.log(`updating sip device ${line.sip_device_id} for ${account_id}`)),
            catchError(this.handleError('updateSipDeviceLine', []))
        );
    }

    deleteCloudSoftphone(account_id, device) {
        return this.callApi('delete', `/accounts/${account_id}/provision/sip_devices/${device}`)
        .pipe(
            tap(_ => this.log(`deleting server ${device} for ${account_id}`)),
            catchError(this.handleError('deleteSipDevice', []))
        );
    }

    updateSipDevice(account_id, device) {
        return this.callApi('put', `/accounts/${account_id}/provision/sip_devices/${device.id}`, device)
        .pipe(
            tap(_ => this.log(`updating sip device ${device.id} for ${account_id}`)),
            catchError(this.handleError('updateSipDevice', []))
        );
    }


    provisionSipDevice(account_id, device) {
        return this.callApi('post', `/accounts/${account_id}/provision/sip_devices/${device.id}/generate`)
        .pipe(
            tap(_ => this.log(`provisioning sip device ${device.id} for ${account_id}`)),
            catchError(this.handleError('provisionSipDevice', []))
        );
    }

    deleteSipDevice(account_id, device) {
        return this.callApi('delete', `/accounts/${account_id}/provision/sip_devices/${device.id}`)
        .pipe(
            tap(_ => this.log(`deleting server ${device.id} for ${account_id}`)),
            catchError(this.handleError('deleteSipDevice', []))
        );
    }

    updateSipDeviceLine(account_id, line) {
        return this.callApi('put', `/accounts/${account_id}/provision/sip_devices/${line.sip_device_id}/lines/${line.id}`, {
            switchboard_object_uuid: line.switchboard_object_uuid,
        })
        .pipe(
            tap(_ => this.log(`updating sip device ${line.sip_device_id} for ${account_id}`)),
            catchError(this.handleError('updateSipDeviceLine', []))
        );
    }

    updateMultiSipDeviceLine(account_id, lines) {
        const device_id = lines[0].sip_device_id;
        return this.callApi('put', `/accounts/${account_id}/provision/sip_devices/${device_id}/lines/multi`, {
            sip_device_lines: lines,
        })
        .pipe(
            tap(_ => this.log(`updating sip device ${device_id} for ${account_id}`)),
            catchError(this.handleError('updateMultiSipDeviceLine', []))
        );
    }

    getSipDeviceBLF(account_id, device) {
        return this.callApi('get', `/accounts/${account_id}/provision/sip_devices/${device.id}/blfs`)
        .pipe(
            tap(_ => this.log(`fetching sip blfs for ${device.id} on account ${account_id}`)),
            catchError(this.handleError('getSipDeviceBLF', []))
        );
    }

    updateSipDeviceBLF(account_id, device, blf) {
        return this.callApi('put', `/accounts/${account_id}/provision/sip_devices/${device.id}/blfs/${blf.id}`, blf)
        .pipe(
            tap(_ => this.log(`updating sip blf for ${device.id} on account ${account_id}`)),
            catchError(this.handleError('updateSipDeviceBLF', 'UPDATE_FAIL'))
        );
    }
}
