<div class="table-responsive">
    <table class="table table-striped">
        <thead class="thead">
            <tr class="tr">
                <th class="th textalignleft" [attr.colspan]="2">
                    <button class="btn btn-primary is-primary" (click)="upload_modal.open()">Create New Media</button>
                </th>
            </tr>
        </thead>
        <tbody class="tbody textalignleft">
            <ng-container *ngIf="files else no_files">
                <tr class="tr" *ngFor="let file of files">
                    <td class="td">
                        <audio *ngIf="file.loaded" id="media-audio-controller-{{ file.uuid }}" controls controlsList="nodownload"></audio>
                        {{ file.name }}
                        <button class="btn btn-sm btn-outline-secondary" (click)="new_name = file.name;current_file = file;rename_modal.open()">
                            <i class="fas fa-edit"></i>
                        </button>
                        <span class="text-sm text-muted">{{ (file.created_at)|date:'medium' }}</span>
                        <ng-container *ngIf="file.is_verified === 0"><span style="font-weight:600;"> (Pending Verification) </span></ng-container>
                        <ng-container *ngIf="file.is_verified === 2"><span style="font-weight:600; color:Red;"> (Not Verified) </span></ng-container>
                    </td>
                    <td>
                        <button *ngIf="!file.loaded" class="btn btn-success" (click)="getSound(file)" title="Play" target="_blank">
                            <i class="fas fa-play"></i>
                        </button>
                        <button  *appViperHasUserRole="'Admin'" class="btn btn-primary" (click)="download(file)" title="Download" target="_blank">
                            <i class="fas fa-download"></i>
                        </button>                      
                        <ng-container *ngIf="file.is_verified === 1">
                            <button class="btn btn-danger is-danger" *appViperHasUserRole="'Account Owner','Admin'" (click)="delete(file)">
                                <i class="fas fa-trash"></i>
                            </button>
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngIf="file.is_verified != 1">
                            <button *appViperHasUserRole="'Admin'" class="btn btn-sucess" (click)="accept(file)" title="accept" target="_blank">
                                <span>Approve</span>
                            </button> 
                            <button *appViperHasUserRole="'Admin'" class="btn btn-danger" (click)="deny(file)" title="deny" target="_blank">
                                <span>Deny</span>
                            </button> 
                        </ng-container>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>

<ng-template #media_disabled>
    <ng-container *ngIf="media_enabled === false else is_loading">
        <div class="alert alert-warning">
            Please top up your account before using media uploading.
        </div>
    </ng-container>
</ng-template>

<ng-template #no_files>
    <tr class="tr">
        <td class="td" colspan="1" *ngIf="files !== null else is_loading">No media found</td>
    </tr>
</ng-template>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>

<app-modal title="Change The Media File Name" size="small" (save)="rename(new_name)" #rename_modal>
    <input type="text" class="form-control" [(ngModel)]="new_name">
</app-modal>

<app-modal title="Upload Media" #upload_modal>
    <ng-container>
        <div>
            This is where you store all of the media that you will use on your phone system, including music on hold and announcements. Once the media is here you can use it across your system. Use this to upload music or a pre recorded announcement, or create a new announcement using our powerful text to speech engine.
        </div>

        <hr>

        <app-media-upload-form [account_id]="account_id" (save)="uploadFile($event)" #upload_form></app-media-upload-form>

        <p class="text-center text-lg">
            <strong>Or&nbsp;<span class="">type in your script. Select the language and voice model</span></strong>
        </p>

        <app-portal-tts-form tts_text="Use this box to type in your script for your Auto attendant, Announcement, Call whisper or Voicemail" [account_id]="account_id" (save)="createTTS($event)" #tts_form></app-portal-tts-form>
        
        <br>

        <div class="input-group">
            <button (click)="tts_form.preview()" class="btn btn-primary">Preview</button>
            &nbsp;
            <button class="btn btn-primary" (click)="tts_form.create()">Create and Save</button>
            &nbsp;
            <button class="btn btn-danger" *appViperHasUserRole="'Admin'" (click)="tts_form.reset()">Reset TTS</button>
        </div>
    </ng-container>
</app-modal>
