import { NgModule } from '@angular/core';

// Core Modules
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Installed Modules
import { GravatarModule } from 'ngx-gravatar';

// My Modules
import { PipesModule } from '../pipes/pipes.module';
import { ComponentsModule } from '../components/components.module';

import { SwitchboardModule } from '../switchboard/switchboard.module';

// Components
import { RolesComponent } from './roles/roles.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { AddressesComponent } from './addresses/addresses.component';
import { KycComponent } from './kyc/kyc.component';

//Other
import { DirectivesModule } from '../directives/directives.module';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        GravatarModule,
        PipesModule,
        ComponentsModule,
        SwitchboardModule,
        DirectivesModule,
    ],
    declarations: [
        RolesComponent,           // Users
        TransactionsComponent,    // Account
        AddressesComponent,
        KycComponent,
    ],
    bootstrap: [],
    exports: [
        RolesComponent,
        TransactionsComponent,
        AddressesComponent,
        KycComponent,
    ]
})

export class SectionsModule { }
