export class Address {
    id: number;
    title: string;
    first_name: string;
    last_name: string;
    address_line_1: string;
    address_line_2: string;
    city: string;
    state_province: string;
    country_region: string;
    postal_code: string;
}
