import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from '../../login/authentication.guard';
import { RoleGuard } from '../../sections/roles/role.guard';

import { TopupComponent } from './topup/topup.component';
import { AutoTopupComponent } from './auto-topup/auto-topup.component';
import { PortalInvoicesComponent } from './portal-invoices/portal-invoices.component';


const routes: Routes = [
    { path: 'topup', canActivate: [AuthenticationGuard], component: TopupComponent, data: {title: 'Topup'} },
    { path: 'auto-topup', canActivate: [AuthenticationGuard, RoleGuard], component: AutoTopupComponent, data: {roles: ['Account Owner'], title: 'Auto Topup'} },
    { path: 'invoices', canActivate: [AuthenticationGuard, RoleGuard], component: PortalInvoicesComponent, data: {roles: ['Account Owner'], type: 'coinprint', title: 'Invoices'} },
    { path: 'invoices/legacy', canActivate: [AuthenticationGuard, RoleGuard], component: PortalInvoicesComponent, data: {roles: ['Account Owner'], type: 'kashflow', title: 'Invoices'} },
    { path: 'invoices/pay/:invoice_id', canActivate: [AuthenticationGuard], component: TopupComponent, data: {title: 'Pay Invoice'} },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class PortalBillingRoutingModule { }
