import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LoginService } from '../../../login/login.service';
import { SwitchboardService } from '../../../switchboard/switchboard.service';
import { ServicesService } from '../../../services/services.service';

import { ClipboardHelper } from '../../../tools/clipboard.helper';

declare var moment: any;
declare var toastr: any;
declare var alertify: any;
declare var $: any;

@Component({
    selector: 'app-handsets',
    templateUrl: './handsets.component.html',
    styleUrls: ['./handsets.component.css']
})
export class HandsetsComponent implements OnInit, OnDestroy {

    @Input() account_id;
    @Input() admin = false;

    handsets;
    available_devices;
    sips;
    available_sips;
    blfs;

    blfInterval;

    current_handset: any = {};
    device_line: any = {};

    @ViewChild('handset_modal') handsetModal;
    @ViewChild('destination_modal') destinationModal;

    constructor(
        private route: ActivatedRoute,
        private authService: LoginService,
        private switchboardService: SwitchboardService,
        private serviceService: ServicesService,
    ) { }

    ngOnInit(): void {
        this.getHandsets();
        this.getAvailableDevices();
        this.getSips();
    }

    ngOnDestroy() {
        clearInterval(this.blfInterval);
    }

    getAvailableDevices() {
        this.switchboardService.getSupportedDevices()
            .subscribe(devices => this.available_devices = devices);
    }

    getSips() {
        if (this.account_id) {
            this.switchboardService.readSipsOnAccount(this.account_id)
            .subscribe(sips => {
                this.sips = sips;
                this.available_sips = sips;
            });
            this.blfInterval = setInterval(() => this.getBLFs(), 30 * 1000);
            this.getBLFs();
        } else {
            setTimeout(() => this.getSips(), 500);
        }
    }

    getBLFs() {
        this.switchboardService.getSwitchboardBlfs(this.account_id)
            .subscribe(blfs => this.blfs = blfs);
    }

    getLineSip(device_line) {
        if (this.sips) {
            for (let sip of this.sips) {
                if (device_line.switchboard_object_uuid === sip.uuid) {
                    return sip;
                }
            }
        }
        return null;
    }

    getHandsets() {
        if (this.account_id) {
            this.switchboardService.getSipDevices(this.account_id)
                .subscribe(handsets => this.handsets = handsets);
        } else {
            this.authService.getCurrentAccount()
            .subscribe(account => {
                this.account_id = account.id;
                this.getHandsets();
            });
        }
    }

    initPopover() {
        $(function () {
            $('[data-toggle="popover"]').popover({
                trigger: 'focus'
            });
        });
    }

    newHandset() {
        this.current_handset = {};
        this.handsetModal.title = 'New Handset';
        this.handsetModal.open();
        this.initPopover();
    }

    saveHandset() {
        if (this.current_handset.id) {
            this.switchboardService.updateSipDevice(this.account_id, this.current_handset)
            .subscribe(handset => {
                if (handset.id) {
                    this.switchboardService.updateMultiSipDeviceLine(this.account_id, this.current_handset.sip_device_lines)
                    .subscribe(lines => {
                        toastr.success('Handset Updated!');
                        this.getHandsets();
                        this.handsetModal.close();
                    });
                } else {
                    this.handsetModal.stopLoading();
                }
            });
        } else {
            this.switchboardService.createSipDevice(this.account_id, this.current_handset)
            .subscribe(handset => {
                if (handset.id) {
                    toastr.success('Handset Created!');
                    this.getHandsets();
                    this.handsetModal.close(() => this.editHandset(handset));
                } else {
                    this.handsetModal.stopLoading();
                }
            });
        }
    }

    provisionHandset(handset) {
        this.switchboardService.provisionSipDevice(this.account_id, handset)
            .subscribe(() => toastr.success(`Provisioned`));
    }

    editHandset(handset) {
        this.current_handset = JSON.parse(JSON.stringify(handset));
        if (!this.admin) {
            this.switchboardService.getSipDeviceAvailableLines(this.account_id, this.current_handset.id)
                .subscribe(sips => this.available_sips = sips);
        }
        this.handsetModal.title = 'Edit Handset';
        this.handsetModal.open();
        this.initPopover();
    }

    deleteHandset(handset) {
        alertify.confirm(`Are you sure you want to remove this handset?`, () => {
            this.switchboardService.deleteSipDevice(this.account_id, handset)
            .subscribe(() => {
                toastr.success('Device removed');
                this.handsetModal.close();
                this.getHandsets();
            });
        });
    }

    setLineDestination(device_line) {
        this.device_line = Object.assign({}, device_line);
        this.destinationModal.open();
    }

    saveLineDestination() {
        this.switchboardService.updateSipDeviceLine(this.account_id, this.device_line)
        .subscribe(line => {
            toastr.success('Destination updated');
            this.getHandsets();
            this.destinationModal.close();
        });
    }

    linesUsed() {
        return this.current_handset.sip_device_lines.filter(line => line.switchboard_object_uuid).length;
    }

    addToClipboard() {
        ClipboardHelper.addToClipboard(this.current_handset.provision_path);
    }


}
