import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Core Modules
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// // Installed Modules
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

// My Modules
import { PipesModule } from '../pipes/pipes.module';

// Components
import { LoadingComponent } from './loading/loading.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ModalComponent } from './modal/modal.component';
import { TabsComponent } from './tabs/tabs.component';
import { TabComponent } from './tabs/tab/tab.component';
import { PasswordGeneratorComponent } from './password-generator/password-generator.component';
import { SwitchboardObjectDropdownComponent } from './switchboard-object-dropdown/switchboard-object-dropdown.component';
import { LoadingbarComponent } from './loadingbar/loadingbar.component';
import { TimeDropdownComponent } from './time-dropdown/time-dropdown.component';
import { NgbdDatepickerRangeComponent } from './ngbd-datepicker-range/ngbd-datepicker-range.component';
import { LiveChatComponent } from './live-chat/live-chat.component';
import { ForwardingToPartialComponent } from './forwarding-to-partial/forwarding-to-partial.component';
import { RateCheckerComponent } from './rate-checker/rate-checker.component';
import { ListSubservicesPartialComponent } from './list-subservices-partial/list-subservices-partial.component';

@NgModule({
    imports: [
        RouterModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,

        NgbModule,
        NgxIntlTelInputModule,

        PipesModule,
    ],
    declarations: [
        LoadingComponent,
        PaginationComponent,
        ModalComponent,
        TabsComponent,
        TabComponent,
        PasswordGeneratorComponent,
        SwitchboardObjectDropdownComponent,
        LoadingbarComponent,
        TimeDropdownComponent,
        NgbdDatepickerRangeComponent,
        LiveChatComponent,
        ForwardingToPartialComponent,
        RateCheckerComponent,
        ListSubservicesPartialComponent,
    ],
    exports: [
        LoadingComponent,
        PaginationComponent,
        ModalComponent,
        TabsComponent,
        TabComponent,
        PasswordGeneratorComponent,
        SwitchboardObjectDropdownComponent,
        LoadingbarComponent,
        TimeDropdownComponent,
        NgbdDatepickerRangeComponent,
        LiveChatComponent,
        ForwardingToPartialComponent,
        RateCheckerComponent,
        ListSubservicesPartialComponent,
    ],
    bootstrap: [NgbdDatepickerRangeComponent]
})

export class ComponentsModule { }
