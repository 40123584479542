<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Shop</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">
                            Shop
                        </h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="alert alert-primary text-center">
                <h3>Want to add more services?</h3>
                Firstly please make sure that you have enough credit on your account balance to purchase the services required below.
                <br>
                After purchase, you can EDIT the service to add custom greetings etc (use the MEDIA icon on the dashboard to upload or create new greetings).
                <br>
                For help with sending calls from your number into a service like a MENU then please see our video on <a href="https://www.youtube.com/watch?v=klkojnja1bU" class="dark-link" ref="_blank">changing your forwarding destination</a></div>
            </div>
            <div class="table-responsive">
                <ng-container *ngTemplateOutlet="services_list"></ng-container>
            </div>
            <div class="card-body">
                <p class="alert alert-primary text-center">
                    Your account credit balance is used to pay for all services, so make sure you have credit to pay for these first
                    <br>
                    All services are purchased prepaid and will attempt to auto renew after 30 days unless cancelled
                </p>
            </div>
        </div>
    </div>
</div>

<ng-template #services_list>
    <table class="table">
        <thead>
            <tr>
                <th>&nbsp;</th>
                <th>Cost</th>
                <th>Service</th>
                <th>Description</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let service of services">
                <td>
                    <ng-container *ngIf="service_details[service.switchboard_type]?.url as url else coming_soon">
                        <a class="btn btn-primary" routerLink="{{ url }}">Buy</a>
                    </ng-container>
                </td>
                <td><ng-container *ngIf="service.multiple">From</ng-container> &pound;{{ service.retail_cost|format_balance }} per month</td>
                <td>
                    <i class="service|switchboard_object_icon" [title]="service.switchboard_type"></i>
                    {{ service_details[service.switchboard_type]?.name || service.name }}
                </td>
                <td>{{ service_details[service.switchboard_type]?.description }}</td>
            </tr>
            <tr *ngFor="let service of sub_services">
                <td><a class="btn btn-primary" routerLink="{{ service.switchboard_type === 'Number' ? '/inbound-bundles' : '/outbound-bundles' }}">Buy</a></td>
                <td>&pound;{{ service.retail_cost|format_balance }} per month</td>
                <td>
                    <i class="service|switchboard_object_icon" [title]="service.switchboard_type"></i>
                    {{ service.name }}
                </td>
                <td>Covers {{ getSheets(service)[0].minutes }} minutes of inbound calls per month {{ service.switchboard_type === 'Number' ? 'forwarded to your destination number' : 'over VoIP' }}</td>
            </tr>
        </tbody>
    </table>
</ng-template>

<ng-template #coming_soon>
    <button class="btn btn-danger disabled" disabled>Coming Soon...</button>
</ng-template>
