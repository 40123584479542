<div class="table-responsive">
    <table class="table">
        <!-- <thead class="thead">
            <tr class="tr">
                <th class="th">User</th>
                <th class="th">Note</th>
                <th class="th">&nbsp;</th>
                <th class="th">&nbsp;</th>
            </tr>
        </thead> -->
        <tbody class="tbody">
            <ng-container *ngIf="notes else no_notes">
                <tr class="tr" *ngFor="let note of notes">
                    <td class="td" style="text-align: left; white-space: pre-line;">
                        <div class="row">
                            <div class="col-1.5">
                                <img ngxGravatar [email]="note.user.email" fallback="mm" alt="User Gravatar"/>
                                &nbsp;
                            </div>
                            <div class="col">
                                <strong>{{ note.user.first_name }} {{ note.user.last_name }}</strong>
                                -
                                <strong>{{ note.name }}</strong>
                                <br>
                                {{ note.content }}
                                <br>
                                <span class="text-sm-right text-light">At {{ ((note.updated_at || note.created_at))|date:'medium' }}</span>
                            </div>
                        </div>
                    </td>
                    <td class="td">
                        <ng-container *ngIf="note.user.id == user.id" >
                            <button class="btn btn-primary" (click)="edit(note)" target="_blank">Edit</button>
                        </ng-container>
                    </td>
                    <td class="td">
                        <button class="btn btn-danger is-danger" (click)="delete(note)">Delete</button>
                    </td>
                </tr>
            </ng-container>
        </tbody>
        <tfoot class="tfoot">
            <tr class="tr">
                <td class="th" colspan="2">
                    <input [(ngModel)]="new_note_name" class="form-control" placeholder="Name">
                    <br>
                    <textarea [(ngModel)]="new_note" class="form-control" placeholder="Your Note..."></textarea>
                </td>
                <td>
                    <button class="btn btn-primary is-primary" (click)="addNote()">Add Note</button>
                </td>
            </tr>
        </tfoot>
    </table>
</div>

<ng-template #no_notes>
    <tr>
        <td colspan="4">
            <ng-container *ngIf="notes !== null else loading">
                No notes
            </ng-container>
        </td>
    </tr>
</ng-template>

<ng-template #loading>
    <app-loadingbar></app-loadingbar>
</ng-template>

<app-modal title="Edit Note" (save)="updateNote()" size="big" #edit_modal>
    <input [(ngModel)]="edit_note_name" class="form-control" placeholder="Name">
    <textarea [(ngModel)]="edit_note" class="form-control" placeholder="Your Note..."></textarea>
</app-modal>
