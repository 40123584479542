import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { SwitchboardService } from '../../switchboard/switchboard.service';

import { InboundServer } from './inbound-server.model';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-inbound-server',
    templateUrl: './inbound-server.component.html',
    styleUrls: ['./inbound-server.component.css']
})
export class InboundServerComponent implements OnInit {

    servers: InboundServer[];

    current_server: InboundServer = new InboundServer();
    droplet_info: any;

    @ViewChild('serverDetail') serverDetail;
    @ViewChild('dropletInfo') dropletModal;

    constructor(
        private switchboardService: SwitchboardService
    ) { }

    ngOnInit() {
        this.loadServers();
    }

    loadServers() {
        this.servers = null;
        this.switchboardService.getInboundServers()
            .subscribe(servers => this.servers = servers);
    }

    newServer() {
        this.current_server = new InboundServer();
        this.serverDetail.title = 'New Server';
        this.serverDetail.open();
    }

    editServer(server) {
        this.current_server = server;
        this.serverDetail.title = 'Edit Server';
        this.serverDetail.open();
    }

    deleteServer(server) {
        alertify.confirm(`Are you sure you want to delete ${server.server_host}`, () => {
            this.switchboardService.deleteInboundServer(server)
                .subscribe(_ => this.loadServers());
        });
    }

    saveServer() {
        let request;
        if (this.current_server.id) {
            request = this.switchboardService.updateInboundServer(this.current_server);
        } else {
            request = this.switchboardService.createInboundServer(this.current_server);
        }

        request.subscribe(response => {
            this.serverDetail.loading = false;
            if (response + '') {
                this.serverDetail.close();
                this.loadServers();
                toastr.success('Inbound Server Saved!');
            }
        });
    }

    getDropletInfo(server) {
        this.current_server = server;
        this.droplet_info = null;
        this.switchboardService.getServerDropletInfo('inbound', server)
            .subscribe(info => this.droplet_info = info);
        this.dropletModal.open();
    }

}
