import { Component, OnInit } from '@angular/core';

import { LoginService } from '../../../login/login.service';

import { RoleHelper } from '../../../sections/roles/role.helper';

@Component({
    selector: 'app-address-badge',
    templateUrl: './address-badge.component.html',
    styleUrls: ['./address-badge.component.css']
})
export class AddressBadgeComponent implements OnInit {

    account;
    roles;

    addresses: any;

    constructor(
        private loginService: LoginService,
    ) { }

    ngOnInit(): void {
        this.loginService.getCurrentAccount()
        .subscribe(account => {
            this.account = account;

            this.loginService.getRolesForCurrentUser()
            .subscribe(roles => {
                this.roles = roles;
                this.loadAddresses();
            });
        });
    }

    loadAddresses() {
        if (RoleHelper.hasRoleName(this.roles, 'Admin') || RoleHelper.hasRoleName(this.roles, 'Server Admin')) {
            this.loginService.getAdminAddresses()
                .subscribe(addresses => this.addresses = addresses);
        }
    }

}
