import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { BillingService } from '../../billing.service';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-card-history',
    templateUrl: './card-history.component.html',
    styleUrls: ['./card-history.component.css']
})
export class CardHistoryComponent implements OnInit {

    @Input() account_id;

    history: any; // This is a paginated object
    page = 1;

    constructor(
        private billingService: BillingService,
    ) { }

    ngOnInit() {
        this.getHistory();
    }

    getHistory() {
        if (this.account_id) {
            this.billingService.getBraintreeHistory(this.account_id, this.page)
                .subscribe(history => this.history = history);
        } else {
            this.billingService.getBraintreeGlobalHistory(this.page)
                .subscribe(history => this.history = history);
        }
    }

    changePage(page) {
        this.page =  page;
        this.getHistory();
    }

    toggleIgnore(card_history) {
        const action = card_history.ignore_record ? 'unignore' : 'ignore';

        alertify.confirm(`Are you sure you want to ${action} this history?`, () => {
            this.billingService.toggleCardHistory(card_history)
            .subscribe(() => {
                this.getHistory();
                toastr.success(`History ${action}d`);
            });
        });
    }

}
