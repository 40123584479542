import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PipesModule } from '../../pipes/pipes.module';
import { DirectivesModule } from '../../directives/directives.module';
import { LayoutModule } from '../../layout/layout.module';
import { ComponentsModule } from '../../components/components.module';
import { BillingModule } from '../../billing/billing.module';

import { PortalBillingRoutingModule } from './portal-billing-routing.module';

import { TopupComponent } from './topup/topup.component';
import { AutoTopupComponent } from './auto-topup/auto-topup.component';
import { PortalInvoicesComponent } from './portal-invoices/portal-invoices.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,

        NgbModule,

        PipesModule,
        DirectivesModule,
        ComponentsModule,
        LayoutModule,
        BillingModule,

        PortalBillingRoutingModule,
    ],
    declarations: [
        TopupComponent,
        AutoTopupComponent,
        PortalInvoicesComponent,
    ],
    bootstrap: []
})

export class PortalBillingModule { }
