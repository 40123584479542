import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from './login/authentication.guard';
import { GuestGuard } from './login/guest.guard';
import { RoleGuard } from './sections/roles/role.guard';

import { LoginComponent } from './login/login.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { VerifyUserComponent } from './verify-user/verify-user.component';

import { HomeComponent } from './home/home.component';
import { SubServicesComponent } from './subservices/subservices.component';
import { SubServiceComponent } from './subservices/subservice/subservice.component';
import { ServicesComponent } from './services/services.component';
import { ServiceComponent } from './services/service/service.component';
import { ApplicationsComponent } from './applications/applications.component';

import { GraphComponent } from './graph/graph.component';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { GlobalBlocksComponent } from './global-blocks/global-blocks.component';
import { DnoBlocksComponent } from './dno-blocks/dno-blocks.component';
import { NumberSearchComponent } from './number-search/number-search.component';
import { SignupLogComponent } from './signup-log/signup-log.component';
import { GlobalCardHistoryComponent } from './global-card-history/global-card-history.component';
import { AdminCdrsComponent } from './admin-cdrs/admin-cdrs.component';
import { AdminDuplicateDestinationsComponent } from './admin-duplicate-destinations/admin-duplicate-destinations.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { ShopComponent } from './shop/shop.component';
import { BulkSwitchboardGenerateComponent } from './bulk-switchboard-generate/bulk-switchboard-generate.component';
import { PortalFaqComponent } from './portal-faq/portal-faq.component';
import { AdminReportsComponent } from './admin-reports/admin-reports.component';
import { PortalHelpComponent } from './portal-help/portal-help.component';
import { AdminVerifyAddressesComponent } from './admin-verify-addresses/admin-verify-addresses.component';
import { AdminVerifyMediaComponent } from './admin-verify-media/admin-verify-media.component';

const routes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'login', canActivate: [GuestGuard], component: LoginComponent, data: {title: 'Login'} },
    { path: 'password-reset/:user/:token', canActivate: [GuestGuard], component: PasswordResetComponent, data: {title: 'Password Reset'} },
    { path: 'verify/:user/:token', canActivate: [GuestGuard], component: VerifyUserComponent, data: {title: 'Verify User'} },

    { path: 'home', canActivate: [AuthenticationGuard], component: HomeComponent, data: {title: 'Home'} },

    { path: 'applications', canActivate: [AuthenticationGuard, RoleGuard], component: ApplicationsComponent, data: {roles: ['Admin'], title: 'Applications'} },
    { path: 'services/detail/:id', canActivate: [AuthenticationGuard, RoleGuard], component: ServiceComponent, data: {roles: ['Admin'], title: 'Services'} },
    { path: 'services', canActivate: [AuthenticationGuard, RoleGuard], component: ServicesComponent, data: {roles: ['Admin'], title: 'Services'} },
    { path: 'subservices/detail/:id', canActivate: [AuthenticationGuard, RoleGuard], component: SubServiceComponent, data: {roles: ['Admin'], title: 'Subservices'} },
    { path: 'subservices', canActivate: [AuthenticationGuard, RoleGuard], component: SubServicesComponent, data: {roles: ['Admin'], title: 'Subservices'} },

    { path: 'graph', canActivate: [AuthenticationGuard], component: GraphComponent, data: {title: 'Graph'} },

    { path: 'global-blocks/:direction/:page', canActivate: [AuthenticationGuard, RoleGuard], component: GlobalBlocksComponent, data: {roles: ['Server Admin'], title: 'Global Blocks'} },
    { path: 'global-blocks/:direction', canActivate: [AuthenticationGuard, RoleGuard], component: GlobalBlocksComponent, data: {roles: ['Server Admin'], title: 'Global Blocks'} },
    { path: 'global-blocks', canActivate: [AuthenticationGuard, RoleGuard], component: GlobalBlocksComponent, data: {roles: ['Server Admin'], title: 'Global Blocks'} },

    { path: 'dno-blocks', canActivate: [AuthenticationGuard, RoleGuard], component: DnoBlocksComponent, data: {roles: ['Server Admin'], title: 'DNO Blocks'} },

    { path: 'number-search', canActivate: [AuthenticationGuard, RoleGuard], component: NumberSearchComponent, data: {roles: ['Admin'], title: 'Number Search'} },

    /* Admin reports */

    { path: 'admin-reports/:name/:page', canActivate: [AuthenticationGuard, RoleGuard], component: AdminReportsComponent, data: {roles: ['Admin'], title: 'Admin Reports'} },
    { path: 'admin-reports/:name', canActivate: [AuthenticationGuard, RoleGuard], component: AdminReportsComponent, data: {roles: ['Admin'], title: 'Admin Reports'} },
    { path: 'admin-reports', canActivate: [AuthenticationGuard, RoleGuard], component: AdminReportsComponent, data: {roles: ['Admin'], title: 'Admin Reports'} },

    { path: 'signup-log/:page', canActivate: [AuthenticationGuard, RoleGuard], component: SignupLogComponent, data: {roles: ['Admin'], title: 'Signup Log'} },
    { path: 'signup-log', canActivate: [AuthenticationGuard, RoleGuard], component: SignupLogComponent, data: {roles: ['Admin'], title: 'Signup Log'} },

    { path: 'admin-addresses', canActivate: [AuthenticationGuard, RoleGuard], component: AdminVerifyAddressesComponent, data: {roles: ['Admin'], title: 'Addresses Administration'} },
    { path: 'admin-media', canActivate: [AuthenticationGuard, RoleGuard], component: AdminVerifyMediaComponent, data: {roles: ['Admin'], title: 'Media Administration'} },

    { path: 'cdrs/:direction/:page', canActivate: [AuthenticationGuard, RoleGuard], component: AdminCdrsComponent, data: {roles: ['Admin'], title: 'CDRs'} },
    { path: 'cdrs/:direction', canActivate: [AuthenticationGuard, RoleGuard], component: AdminCdrsComponent, data: {roles: ['Admin'], title: 'CDRs'} },
    { path: 'cdrs', canActivate: [AuthenticationGuard, RoleGuard], component: AdminCdrsComponent, data: {roles: ['Admin'], title: 'CDRs'} },

    { path: 'duplicate-destinations/:direction/:page', canActivate: [AuthenticationGuard, RoleGuard], component: AdminDuplicateDestinationsComponent, data: {roles: ['Admin'], title: 'Duplicate Destinations'} },
    { path: 'duplicate-destinations/:direction', canActivate: [AuthenticationGuard, RoleGuard], component: AdminDuplicateDestinationsComponent, data: {roles: ['Admin'], title: 'Duplicate Destinations'} },
    { path: 'duplicate-destinations', canActivate: [AuthenticationGuard, RoleGuard], component: AdminDuplicateDestinationsComponent, data: {roles: ['Admin'], title: 'Duplicate Destinations'} },

    { path: 'card-history/:page', canActivate: [AuthenticationGuard, RoleGuard], component: GlobalCardHistoryComponent, data: {roles: ['Admin'], title: 'Card History'} },
    { path: 'card-history', canActivate: [AuthenticationGuard, RoleGuard], component: GlobalCardHistoryComponent, data: {roles: ['Admin'], title: 'Card History'} },

    { path: 'accounts/detail/:account_id/switchboard-bulk', canActivate: [AuthenticationGuard, RoleGuard], component: BulkSwitchboardGenerateComponent, data: {roles: ['Account Owner'], title: 'Shop'} },

    { path: 'shop', canActivate: [AuthenticationGuard, RoleGuard], component: BulkSwitchboardGenerateComponent, data: {roles: ['Account Owner'], title: 'Shop'} },

    { path: 'portal-faq', canActivate: [AuthenticationGuard], component: PortalFaqComponent, data: {title: 'FAQ'} },
    { path: 'help', canActivate: [AuthenticationGuard], component: PortalHelpComponent, data: {title: 'Help'} },

    { path: '**', component: PageNotFoundComponent, data: {title: '404 - Page Not Found'} },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
