import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';

import { ClipboardHelper } from '../../tools/clipboard.helper';

@Component({
    selector: 'app-password-generator',
    templateUrl: './password-generator.component.html',
    styleUrls: ['./password-generator.component.css']
})
export class PasswordGeneratorComponent implements OnInit {

    private value: string;
    @Input() label = 'Generate Password';
    @Input() charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    @Input() length = 15;

    @Output() generate = new EventEmitter<any>();

    constructor() { }

    ngOnInit() {
    }

    generatePassword() {
        this.value = '';
        for (let i = 0; i < this.length; i++) {
            this.value += this.charset[Math.floor(Math.random() * this.charset.length)];
        }
        ClipboardHelper.addToClipboard(this.value);
        this.generate.emit(this.value);
        return this.value;
    }
}
