import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { LoginService } from '../../login/login.service';

import { ClipboardHelper } from '../../tools/clipboard.helper';
import { AuthenticationHelper } from '../../login/authentication.helper';

declare var toastr: any;
declare var alertify: any;

@Component({
  selector: 'app-keys',
  templateUrl: './keys.component.html',
  styleUrls: ['./keys.component.css']
})
export class KeysComponent {

    @Input() account_id;
    @Input() user;

    keys;
    newKeyName;
    newKeyHours;
    generatedKey;

    @ViewChild('keys_modal') keysModal;

    constructor(
        private authService: LoginService,
        private router: Router,
    ) { }

    getKeys() {
        let request;
        this.keys = null;
        if (this.user) {
            request = this.authService.getKeysForUser(this.account_id, this.user);
        } else {
            request = this.authService.getKeysForCurrentUser();
        }
        request.subscribe(keys => this.keys = keys);
    }

    delete(key) {
        alertify.confirm(`Are you sure you want to remove this key?`, () => {
            this.authService.deleteKeyForUser(this.account_id, this.user, key.token_id)
                .subscribe(respose => this.getKeys());
        });
    }

    open(user) {
        this.user = user;
        this.generatedKey = null;
        this.newKeyName = null;
        this.newKeyHours = null;
        this.getKeys();
        this.keysModal.open();
    }

    generate() {
        this.authService.createKeyForUser(this.account_id, this.user, this.newKeyName, this.newKeyHours)
        .subscribe(key => {
            if (key.token) {
                this.generatedKey = key.token;
                this.getKeys();
                this.newKeyName = null;
                this.newKeyHours = null;
                toastr.success(`Key generated!`);
            }
        });
    }

    login() {
        this.authService.createKeyForUser(this.account_id, this.user, this.newKeyName, this.newKeyHours)
        .subscribe(key => {
            if (key.token) {
                AuthenticationHelper.setProxyKey(key.token);
                location.pathname = '/home';
            }
        });
    }

    addToClipboard() {
        ClipboardHelper.addToClipboard(this.generatedKey);
    }

}
