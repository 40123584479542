<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Verify Know Your Customer Addresses</h2>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table align-items-center table-flush table-float-header table-striped">
                    <thead>
                        <tr>
                            <th>Company Name</th>
                            <th>Office Address</th>
                            <th>Existing Details</th>
                            <th>Senior Manager</th>
                            <th>Status</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="kycs?.total else no_kycs">
                        <ng-container *ngFor="let kyc of kycs.data">
                            <tr *ngIf="!kyc.is_verified">
                                <td>
                                    Account: <a routerLink="/accounts/detail/{{ kyc.account_id }}">{{ kyc.account_id + 1000000 }}</a><br><br>

                                    {{ kyc.company_name }}<br>
                                    CNUM: {{ kyc.company_number }}<br>
                                    {{ kyc.trading_name }}<br>
                                    {{ kyc.industry }}
                                </td>
                                <td>
                                    {{ kyc.office_address_line_1 }},<br>
                                    {{ kyc.office_address_line_2 }},<br>
                                    {{ kyc.office_city }},<br>
                                    {{ kyc.office_state_province }},<br>
                                    {{ kyc.office_postal_code }}<br>
                                    {{ kyc.office_country }}
                                </td>
                                <td>
                                    {{ kyc.existing_phone_numbers }}<br>
                                    {{ kyc.existing_websites }}
                                </td>
                                <td>
                                    {{ kyc.senior_manager_name }}<br>
                                    {{ kyc.senior_manager_email }}<br>
                                    {{ kyc.senior_manager_phone }}

                                </td>
                                <td>
                                    <h3>
                                        <ng-container *ngIf="!kyc.on_hold">
                                            <span class="badge bg-info" *ngIf="!kyc.is_verified">Pending</span>
                                            <span class="badge bg-success" *ngIf="kyc.is_verified">Validated</span>
                                        </ng-container>
                                        <ng-container *ngIf="kyc.on_hold">
                                            <span class="badge bg-warning">On Hold<br>({{ kyc.updated_at|date:'medium' }})</span>
                                        </ng-container>
                                    </h3>
                                </td>
                                <td>
                                    <button class="btn btn-success" (click)="verify(kyc)" *ngIf="!kyc.on_hold"><i class="fas fa-check"></i> Accept</button>
                                    <button class="btn btn-warning" (click)="hold(kyc)" *ngIf="!kyc.on_hold"><i class="fas fa-check"></i> Require Update</button>
                                    <button class="btn btn-danger" (click)="deny(kyc)"><i class="fas fa-times"></i> Deny</button>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="5">
                                <app-pagination [pagination]="kycs" [(page)]="page" (page_change)="changePage($event)"></app-pagination>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="card-body">
        <app-loadingbar></app-loadingbar>
    </div>
</ng-template>

<ng-template #no_kycs>
    <tr>
        <td colspan="5">No KYC Details To Verify</td>
    </tr>
</ng-template>
