import { Component, OnInit } from '@angular/core';
import { SwitchboardService } from '../switchboard/switchboard.service';
import { LoginService } from '../login/login.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  sips;
  account;
  user;
  app;

  busyLightsInterval;
  busyLights;
  private onDestroy = new Subject<void>();

  voip_card_toggle = false;
  view_financial = false;
  isTrial = true;
  isVV = false;

  constructor(
    private loginService: LoginService,
    private switchboardService: SwitchboardService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.loginService.getCurrentAccount()
      .pipe(
        switchMap(account => {
          this.account = account;
          this.checkAccountState();
          return this.switchboardService.readSipsOnAccount(this.account.id);
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe(sips => {
        this.sips = sips;
        this.startBusyLightsInterval();
      });
    this.loginService.getCurrentApp()
      .subscribe (app => this.app = app)
    this.loginService.getCurrentUser()
      .subscribe(user => {
        this.user = user;
        this.checkRoles();
      });
  }

  ngOnDestroy() {
    clearInterval(this.busyLightsInterval);
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  checkAccountState() {
    if (this.account.active_state == 1) {
      this.isTrial=true;
    }
    else {
      this.isTrial=false;
    }
  }

  checkRoles() {
    if (this.user && this.user.roles) {
      // Check if any of the user's roles match 'Account Owner' or 'Admin'
      const hasRelevantRole = this.user.roles.some(role => 
        role.name === 'Account Owner' || role.name === 'Admin'
      );
  
      if (this.app.short_name != "vv" || hasRelevantRole) {
        this.view_financial = true;
      }
    }
    if (this.app.short_name == 'vv') {
      this.isVV = true;
    }
  }  

  startBusyLightsInterval() {
    this.busyLightsInterval = setInterval(() => this.loadBusyLights(), 30 * 1000);
    this.loadBusyLights(); // Load initially without waiting for the interval
  }

  loadBusyLights() {
    this.switchboardService.getSwitchboardBlfs(this.account.id)
      .subscribe(blfs => this.busyLights = blfs);
  }

  setupUser(sip) {
    this.router.navigate(['/sips', sip.uuid], { queryParams: { openModal: 'true' } });
  }

  toggleVoIPCard() {
    this.voip_card_toggle = !this.voip_card_toggle;
  }

  getConditionalText(switchboard_object: any): { text: string, class: string } {
    const status = this.busyLights[switchboard_object.extension_number];
    switch (status) {
        case 'In use':
            return { text: 'On a Call', class: 'bg-in-use' };
        case 'Not in use':
            return { text: 'User is Ready', class: 'bg-not-in-use' };
        default:
            return { text: 'Offline - Click to Set Up User', class: 'bg-offline' };
    }
  }

}
