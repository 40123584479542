import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.css']
})
export class PaginationComponent {

    @Input() pagination;
    @Input() url;
    @Input() page;

    @Output() page_change = new EventEmitter<number>();

    constructor(
        private activatedRoute: ActivatedRoute
    ) {}

    notOnePage () {
        return 1 !== this.pagination.last_page;
    }

    notFirstOrLastPage(page) {
        return page > 1 && page < this.pagination.last_page;
    }

    previous_page() {
        return this.pagination.current_page - 1;
    }

    next_page() {
        return this.pagination.current_page + 1;
    }

    getURL(page) {
        if (this.page) {
            return null;
        }
        return [this.url, page];
    }

    getQueryParams() {
        return this.activatedRoute.snapshot.queryParamMap['params'];
    }

    setPage(page) {
        this.page = page;
        this.page_change.emit(page);
    }

}
