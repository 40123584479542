import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

@Pipe({name: 'capitalize'})
export class CapitalizePipe implements PipeTransform {

    transform(value: any) {
        if (value) {
            return value.split(' ').map(x => x.charAt(0).toUpperCase() + x.slice(1)).join(' ');
        }
        return value;
    }
}
