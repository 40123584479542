import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';

import { LoginService } from '../../login/login.service';
import { SwitchboardService } from '../../switchboard/switchboard.service';
import { ServicesService } from '../../services/services.service';
import { KnowYourCustomerService } from 'src/app/admin/know-your-customer.service';

// import { Account } from '../../accounts/account';

import { CountryCodes } from '../../tools/country-codes';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-portal-new-number',
    templateUrl: './portal-new-number.component.html',
    styleUrls: ['./portal-new-number.component.css']
})
export class PortalNewNumberComponent implements OnInit {

    app;
    account_id;

    account_created_at;
    can_purchase_number;
    is_business;
    convertedDate;
    // account: Account;

    services;
    free_services;
    numbers;
    memorable_numbers;
    top_numbers;

    countryCodes;
    areas;

    country_code;
    area;
    minScore = 0;
    midScore = 4;
    maxScore = 9999;
    searchTerm = '';
    loading = false;

    switchboardConfig;
    number_count = 0;
    all_services;
    kyc;

    object_name;

    givenDate;
    limitDate;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: LoginService,
        private switchboardService: SwitchboardService,
        private servicesService: ServicesService,
        private kycService: KnowYourCustomerService,
    ) { }

    ngOnInit() {
        this.switchboardService.getCountryCodes()
            .subscribe(countries => this.countryCodes = countries);

        this.authService.getCurrentAccount()
            .pipe(
                tap(account => {
                    this.account_id = account.id;
                    this.account_created_at = account.created_at;
                    this.is_business = account.is_business;
                    this.country_code = '44';
                    this.getServices();
                    this.getAreas();
                }),
                switchMap(account => this.switchboardService.getSwitchboardConfig(account.id)),
                tap(switchboardConfig => this.switchboardConfig = switchboardConfig),
                switchMap(switchboardConfig => 
                    this.is_business ? this.kycService.getKYC(this.account_id) : [null] // Using [null] to bypass the KYC fetch for non-business accounts
                )
            )
            .subscribe(kyc => {
                if (kyc) {
                    this.kyc = kyc;
                }
                this.checkDateAndSetProperty(this.account_created_at);
            }, error => {
                console.error('An error occurred', error);
            });

        this.authService.getCurrentApp()
            .subscribe(app => this.app = app);
            
        this.createCustomAlertifyDialog();
    }

    getAreas() {
        if (this.country_code) {
            this.area = null;
            this.switchboardService.getAreaCodes(this.country_code)
                .subscribe(areas => {
                    if (!this.is_business) {
                        this.areas = areas.data.filter(area => 
                            area.prefix !== '033' && area.prefix !== '0844' && area.prefix !== '080' && area.prefix !== '0345' && area.prefix !== '0843');
                    } else {
                        this.areas = areas.data;
                    }
                });
        }
    }

    getServices() {
        let object_type = 'Number';
        this.switchboardService.getSwitchboardObjectsForAccount(this.account_id)
        .subscribe(objects => {
            let used_services = objects
                .filter(object => object.object_type === object_type)
                .map(object => object.service_instance_id);
            this.servicesService.getServicesForAccount(this.account_id, object_type)
            .subscribe(services => {
                this.free_services = services.filter(service => !used_services.includes(service.id))
                
                services.forEach(service => {
                    if (service.service && service.service.switchboard_type === "Number") {
                      this.number_count++;
                    }
                });
            });
        });

        this.servicesService.getAvailableServices(this.account_id, 'number')
            .subscribe(services => this.services = services);
    }

    getTopFive() {
        this.switchboardService.getTopNumbers(this.country_code, this.area, this.midScore)
            .subscribe(numbers => this.top_numbers = numbers);
    }

    getNumbers() {
        this.numbers = null;
        if (this.country_code && this.area) {
            this.switchboardService.searchNumbers(this.country_code, this.area, this.minScore, this.midScore, '')
            .subscribe(numbers => {
                this.numbers = numbers;
            });
            this.getMemorableNumbers();
            this.getTopFive();
        }
    }

    getMemorableNumbers() {
        this.switchboardService.searchNumbers(this.country_code, this.area, this.midScore + 1, this.maxScore, this.searchTerm)
            .subscribe(numbers => this.memorable_numbers = numbers);
    }

    getService(number) {
        let service;
        const score = number.score;
        const scores = this.services.map(service => service.switchboard_quality);
        scores.sort((a, b) => a - b);

        let winning_score = 0;
        while (scores[winning_score] <= score) {
            winning_score += 1;
        }

        for (service of this.free_services) {
            if (service.service.switchboard_quality === scores[winning_score]) {
                return service;
            }
        }

        for (service of this.services) {
            if (service.switchboard_quality === scores[winning_score]) {
                return service;
            }
        }

        return null;
    }

    createSwitchboardObject(serviceInstance, number) {
        let data = {
            name: number.fullNumber,
            service_instance_id: serviceInstance.id,
            e164_number: number.fullNumber,
        };

        this.switchboardService.createObjectOnAccount(this.account_id, `numbers`, data)
        .subscribe(new_object => {
            if (new_object.uuid) {
                this.router.navigateByUrl(`/numbers/${new_object.uuid}`);
            } else {
                this.loading = false;
            }
        });
    }

    purchase(number) {
        const service = this.getService(number);
        const cost = ((service.service_id ? service.service.retail_cost : service.retail_cost) / 100).toFixed(2);

        number.fullNumber = number.number;
        if (this.can_purchase_number) {
            alertify.confirm(`Are you sure you want to buy "${number.fullNumber}" for £${cost}?`, () => {
                this.loading = true;
                if (service.service_id) {
                    this.createSwitchboardObject(service, number);
                } else {
                    this.servicesService.addServiceToAccount(this.account_id, service.id)
                        .subscribe(serviceInstance => this.createSwitchboardObject(serviceInstance, number));
                }
            });
        } else {
            alertify.htmlDialog(`<p>Please contact <a href="${this.app.support_url}" target="_blank">Support</a></p> to purchase numbers.</p>`).setHeader('Warning');
        }
    }

    checkDateAndSetProperty(yourDateStr) {
        const givenDate = new Date(yourDateStr);
        const currentDate = new Date();
        const thirtyOneDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 31));
        this.givenDate = givenDate;
        this.limitDate = thirtyOneDaysAgo;

        if (this.is_business == 0 && givenDate <= thirtyOneDaysAgo && this.switchboardConfig.number_limit > this.number_count) {
            this.can_purchase_number = true;
        }
        else if (this.kyc) {
            if (this.kyc.is_verified && this.switchboardConfig.number_limit > this.number_count) {
                this.can_purchase_number = true;
            }
        }
    }

    createCustomAlertifyDialog() {
        alertify.dialog('htmlDialog', function factory() {
            return {
              main: function(message) {
                this.message = message;
              },
              setup: function() {
                return {
                  buttons: [{ text: "Close", className: "btn btn-danger", key: 27 /* Esc */ }],
                  focus: { element: 0 }
                };
              },
              prepare: function() {
                this.setContent(this.message);
              }
            };
          });
    }
    
    
    

}
