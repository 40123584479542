import { Injectable } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

declare var toastr: any;

@Injectable({
  providedIn: 'root'
})
export class GetAddressService implements OnDestroy {

    protected serviceURL = 'https://api.getAddress.io';
    protected apiKey = 'yfl8jpeYsk2c1kBxmbRMng30370';
    protected subscribed = [];

    constructor(
        private http: HttpClient
    ) { }

    protected log(message: string) {
        console.log(`Service: ${message} `);
        // this.messageService.add(`HeroService: ${message}`);
    }

    protected showError(message) {
        if (message === 'Invalid token') {
            return false;
        }
        toastr.error(message);
    }

    protected handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            const client = this;
            function outputMessages(messages) {
                for (let key in messages) {
                    let message = messages[key];
                    if (typeof message === 'string') {
                        message = [message];
                    }
                    for (let note of message) {
                        client.showError(note);
                    }
                }
            }

            if (typeof error.error === 'string') {
                this.showError(error.error);
            }

            if (error.error) {
                if (error.error.messages) {
                    outputMessages(error.error.messages);
                }

                if (error.error.message) {
                    this.showError(error.error.message);
                }
            }

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    protected call(method, url, data = {}, options = {}) {
        let request;
        const defaultHttpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            params: null,
        };
        const httpOptions = Object.assign(defaultHttpOptions, options);

        if (method === 'get' || method === 'delete') {
            httpOptions.params = data;
            request = this.http[method](url, httpOptions);
        } else {
            request = this.http[method](url, data, httpOptions);
        }

        this.subscribed.push(request);
        return request;
    }

    public unsubscribe() {
        for (let request of this.subscribed) {
            this.unsubscribe();
        }
    }

    ngOnDestroy() {
        this.unsubscribe();
    }

    protected callApi(method, path, data = {}, options = {}) {
        data['api-key'] = this.apiKey;
        return this.call(method, this.serviceURL + path, data, options);
    }

    public getAddresses(postcode, house = null) {
        let path = `/find/${postcode}`;
        if (house) {
            path += `/${house}`;
        }
        return this.callApi('get', path)
        .pipe(
            tap(_ => this.log('fetching addresses for postcode')),
            catchError(this.handleError('getAddresses', []))
        );
    }

    public getExpandedAddresses(postcode, house = null) {
        let path = `/find/${postcode}`;
        if (house) {
            path += `/${house}`;
        }
        return this.callApi('get', path, {expand: true})
        .pipe(
            tap(_ => this.log('fetching addresses for postcode')),
            catchError(this.handleError('getExpandedAddresses', []))
        );
    }
}
