import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { Observable, of } from 'rxjs';
import { catchError, map, tap, concatMap } from 'rxjs/operators';

import { Client } from '../tools/client';

@Injectable({
    providedIn: 'root'
})
export class KnowYourCustomerService extends Client {

    protected serviceURL = environment.services.api;

    getAdminKYC(page = 1) {
        return this.callApi('get', `/admin/kyc`, {page: page})
        .pipe(
            tap(apps => this.log(`fetching kyc needing approval`)),
            catchError(this.handleError('getAdminKYC', []))
        );
    }


    getKYC(account_id) {
        return this.callApi('get', `/accounts/${account_id}/kyc`)
        .pipe(
            tap(_ => this.log(`fetching kyc for customer ${account_id}`)),
            catchError(this.handleError('getKYC', []))
        );
    }

    getHistoricKYC(account_id, page = 1) {
        return this.callApi('get', `/accounts/${account_id}/kyc/historic`, { page })
        .pipe(
            tap(_ => this.log(`fetching historic kycs for customer ${account_id}`)),
            catchError(this.handleError('getHistoricKYC', []))
        );
    }

    createKYC(account_id, kyc) {
        return this.callApi('post', `/accounts/${account_id}/kyc`, kyc)
        .pipe(
            tap(_ => this.log(`creating kyc`)),
            catchError(this.handleError('createKYC', 'FAIL'))
        );
    }

    verifyKYC(account_id) {
        return this.callApi('post', `/accounts/${account_id}/kyc/verify`)
        .pipe(
            tap(_ => this.log(`verifying kyc for account ${account_id}`)),
            catchError(this.handleError('verifyKYC', 'FAIL'))
        );
    }

    holdKYC(account_id) {
        return this.callApi('post', `/accounts/${account_id}/kyc/hold`)
        .pipe(
            tap(_ => this.log(`holding kyc for account ${account_id}`)),
            catchError(this.handleError('updateKYC', 'FAIL'))
        );
    }

    deleteKYC(account_id) {
        return this.callApi('delete', `/accounts/${account_id}/kyc`)
        .pipe(
            tap(_ => this.log(`deleting kyc for account ${account_id}`)),
            catchError(this.handleError('deleteKYC', 'FAIL'))
        );
    }

}
