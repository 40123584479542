<div class="container-fluid">
    <!-- Toggler -->
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidenav-collapse-main" aria-controls="sidenav-main" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
    </button>
    <!-- Brand -->
    <a class="navbar-brand pt-0" routerLink="/">
        <ng-container *ngIf="!app else brand_logo">
            <!--<img src="/assets/images/viper-logo.png" class="navbar-brand-img" *ngIf="app !== undefined" alt="Viper Logo"> -->
        </ng-container>
    </a>
    <!-- User -->
    <ul class="nav align-items-center d-md-none float-right">
        <!--
        <li class="nav-item dropdown">
            <a class="nav-link nav-link-icon" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="ni ni-bell-55"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-arrow dropdown-menu-right" aria-labelledby="navbar-default_dropdown_1">
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">Something else here</a>
            </div>
        </li>
        -->
        <li class="nav-item dropdown">
            <a class="nav-link" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div class="media align-items-center">
                    <span class="avatar avatar-sm rounded-circle" *ngIf="!!user">
                        <img ngxGravatar [email]="user.email" fallback="mm" alt="User Gravatar">
                    </span>
                </div>
            </a>
            <div class="dropdown-menu dropdown-menu-arrow dropdown-menu-right">
                <div class=" dropdown-header noti-title">
                    <h6 class="text-overflow m-0">
                        Welcome!
                        <div class="dropdown-divider"></div>
                        <span class="text-muted" *ngIf="!!account">
                            {{ account.company_name }}
                            <br>
                            <ng-container *ngIf="user">{{ user.email }}</ng-container>
                            <br>
                            Account number - {{ sid_start + account.id }}
                            <br>
                            Your account is currently: {{ account.active_state|formatAccountState }}
                        </span>
                    </h6>


                </div>

                <!-- <a href="./examples/profile.html" class="dropdown-item">
                    <i class="ni ni-single-02"></i>
                    <span>My profile</span>
                </a> -->
                <a routerLink="/settings" class="dropdown-item">
                    <i class="ni ni-settings-gear-65"></i>
                    <span>Settings</span>
                </a>
                <!-- <a href="./examples/profile.html" class="dropdown-item">
                    <i class="ni ni-calendar-grid-58"></i>
                    <span>Activity</span>
                </a> -->
                <a [href]="supportUrl" target="_blank" class="dropdown-item">
                    <i class="ni ni-support-16"></i>
                    <span>Support</span>
                </a>
                <a class="dropdown-item show-pointer d-md-none d-xs-block" *ngIf="auth_code" (click)="supportCodeToClipboard()">
                    <span>Support Code: {{ auth_code }}</span>
                </a>
                <div class="dropdown-divider"></div>
                <button (click)="logout()" class="dropdown-item">
                    <i class="ni ni-user-run"></i>
                    <span>Logout</span>
                </button>
            </div>
        </li>
    </ul>
    <!-- Collapse -->
    <div class="collapse navbar-collapse" id="sidenav-collapse-main">
        <!-- Collapse header -->
        <div class="navbar-collapse-header d-md-none">
            <div class="row">
                <div class="col-6 collapse-brand">
                    <a routerLink="/">
                        <ng-container *ngIf="!app else brand_logo">
                            <!--<img src="/assets/images/viper-logo.png" *ngIf="app !== undefined" alt="Viper Logo"> -->
                        </ng-container>
                    </a>
                </div>
                <div class="col-6 collapse-close">
                    <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#sidenav-collapse-main" aria-controls="sidenav-main" aria-expanded="false" aria-label="Toggle sidenav">
                    <span></span>
                    <span></span>
                    </button>
                </div>
            </div>
        </div>
        <!-- Form -->
        <!--
        <form class="mt-4 mb-3 d-md-none">
            <div class="input-group input-group-rounded input-group-merge">
                <input type="search" class="form-control form-control-rounded form-control-prepended" placeholder="Search" aria-label="Search">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <span class="fa fa-search"></span>
                    </div>
                </div>
            </div>
        </form>
        -->
        <!-- Navigation -->
        <div class="navigation">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/home" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <i class="ni ni-tv-2 text-primary"></i> Dashboard
                    </a>
                </li>
                <li class="nav-item" *appViperHasUserRole="'Admin'">
                    <a class="nav-link" routerLink="/account" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <i class="fa fa-user-circle"></i> My Account
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" routerLink="/media" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <i class="fa fa-file-audio"></i> My Media
                    </a>
                </li>

                <li class="nav-item">
                    <a routerLink="/call-stats" class="nav-link" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <i class="fas fa-chart-line"></i> Call Stats &amp; History
                    </a>
                </li>

                <li class="nav-item">
                    <a routerLink="/call-restrictions" class="nav-link" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <i class="fas fa-phone-slash"></i> Call Blocking
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" routerLink="/voicemail-inbox" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <i class="fas fa-microphone-alt"></i> Voicemail Messages
                    </a>
                </li>

                <li class="nav-item" *appViperAccountHasService="'CallRecording'">
                    <a class="nav-link" routerLink="/call-recordings" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <i class="fas fa-compact-disc"></i> Call Recordings
                    </a>
                </li>

                <!-- <li class="nav-item" *appViperAccountHasService="'MusicOnHold'">
                    <a class="nav-link" routerLink="/music-on-hold" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <i class="fas fa-music"></i> Music On Hold
                    </a>
                </li> -->

                <li class="nav-item" *appViperIsNotPostpay>
                    <a class="nav-link" routerLink="/help" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <i class="fas fa-question"></i> Help
                    </a>
                </li>

                <!-- <li class="nav-item" *appViperHasUserRole="'Account Owner'">
                    <a class="nav-link" routerLink="/graph" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <i class="fas fa-sitemap"></i> My Overview
                    </a>
                </li> -->
                <!-- 
                        <i class="ni ni-planet text-blue"></i> Icons
                        <i class="ni ni-pin-3 text-orange"></i> Maps
                        <i class="ni ni-single-02 text-yellow"></i> User profile
                        <i class="ni ni-bullet-list-67 text-red"></i> Tables
                        <i class="ni ni-key-25 text-info"></i> Login
                        <i class="ni ni-circle-08 text-pink"></i> Register
                -->
            </ul>

            <div id="side-nav-accordion">

                <ng-container *ngIf="this.sips.length">
                    <hr class="my-3">
                    <h6 class="navbar-heading btn btn-link" data-toggle="collapse" data-target="#side-nav-setup">
                        Setup VoIP Users
                        <i class="fas fa-chevron-down"></i>
                    </h6>
                    
                    <ul class="navbar-nav collapse" id="side-nav-setup" data-parent="#side-nav-setup" routerLinkActive="show">
                        <li *ngFor="let switchboard_object of sips; let i = index">
                            <a routerLink="/sips/{{ switchboard_object.uuid }}" class="nav-link" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fas fa-phone"></i> {{ switchboard_object.object_data.name }}
                            </a>        
                        </li>
                    </ul>
                </ng-container>

                <ng-container *ngIf="view_financial">
                    <hr class="my-3">
                    <h6 class="navbar-heading btn btn-link" data-toggle="collapse" data-target="#side-nav-billing">
                        Billing
                        <i class="fas fa-chevron-down"></i>
                    </h6>
                    
                    <ul class="navbar-nav collapse" id="side-nav-billing" data-parent="#side-nav-accordion" routerLinkActive="show">

                        <ng-container *appViperIsNotPostpay>
                            <li class="nav-item">
                                <a routerLink="/topup" class="nav-link" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <i class="fas fa-funnel-dollar"></i> Topup
                                </a>
                            </li>

                            <ng-container *ngIf="config.auto_topup_available">
                                <li class="nav-item" *appViperHasUserRole="'Account Owner'">
                                    <a routerLink="/auto-topup" class="nav-link" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                        <i class="fas fa-coins"></i> Auto Topup
                                    </a>
                                </li>
                            </ng-container>
                        </ng-container>
                        <li class="nav-item" *appViperHasUserRole="'Account Owner'">
                            <a routerLink="/invoices" class="nav-link" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fas fa-file-alt"></i> Invoices
                            </a>
                        </li>
                    </ul>
                </ng-container>

                <ng-container  *appViperHasUserRole="['Admin', 'Server Admin']">
                    <hr class="my-3">
                    <h6 class="navbar-heading btn btn-link" data-toggle="collapse" data-target="#side-nav-administration">
                        Administration
                        <i class="fas fa-chevron-down"></i>
                    </h6>

                    <ul class="navbar-nav collapse" id="side-nav-administration" data-parent="#side-nav-accordion" routerLinkActive="show">
                        <li class="nav-item" *appViperHasUserRole="'Admin'">
                            <a class="nav-link" routerLink="/accounts" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fa fa-user-circle"></i> Accounts
                            </a>
                        </li>

                        <li class="nav-item" *appViperHasUserRole="'Admin'">
                            <a class="nav-link" routerLink="/admin-addresses" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fas fa-address-card"></i> Admin Addresses <app-address-badge></app-address-badge>
                            </a>
                        </li>

                        <li class="nav-item" *appViperHasUserRole="'Admin'">
                            <a class="nav-link" routerLink="/admin-kyc" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fas fa-address-card"></i> Admin KYC <app-kyc-badge></app-kyc-badge>
                            </a>
                        </li>

                        <li class="nav-item" *appViperHasUserRole="'Admin'">
                            <a class="nav-link" routerLink="/admin-media" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fa fa-file-audio"></i> Verify Media <app-verify-media-badge></app-verify-media-badge>
                            </a>
                        </li>

                        <li class="nav-item" *appViperHasUserRole="'Admin'">
                            <a class="nav-link" routerLink="/number-search" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fas fa-search"></i> Number Search
                            </a>
                        </li>

                        <li class="nav-item" *appViperHasUserRole="'Admin'">
                            <a class="nav-link" routerLink="/voicemail-inboxes" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fas fa-microphone-alt"></i> Voicemail Inboxes
                            </a>
                        </li>

                        <li class="nav-item" *appViperHasUserRole="'Admin'">
                            <a class="nav-link" routerLink="/applications" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fa fa-apple-alt"></i> Applications
                            </a>
                        </li>

                        <li class="nav-item" *appViperHasUserRole="'Admin'">
                            <a class="nav-link" routerLink="/services" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fa fa-concierge-bell"></i> Services
                            </a>
                        </li>

                        <li class="nav-item" *appViperHasUserRole="'Admin'">
                            <a class="nav-link" routerLink="/subservices" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fa fa-concierge-bell"></i> Sub-Services
                            </a>
                        </li>

                        <li class="nav-item" *appViperHasUserRole="'Server Admin'">
                            <a routerLink="/global-blocks" class="nav-link" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fas fa-phone-slash"></i> Global Blocking
                            </a>
                        </li>

                        <li class="nav-item" *appViperHasUserRole="'Server Admin'">
                            <a routerLink="/dno-blocks" class="nav-link" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fas fa-phone-slash"></i> DNO Blocking
                            </a>
                        </li>
                    </ul>
                </ng-container>

                <ng-container *appViperHasUserRole="'Admin'">
                    <hr class="my-3">
                    <h6 class="navbar-heading btn btn-link" data-toggle="collapse" data-target="#side-nav-reports">
                        Admin Reports
                        <i class="fas fa-chevron-down"></i>
                    </h6>

                    <ul class="navbar-nav collapse" id="side-nav-reports" data-parent="#side-nav-accordion" routerLinkActive="show">
                        <li class="nav-item" *appViperHasUserRole="'Admin'">
                            <a class="nav-link" routerLink="/admin-reports" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="far fa-newspaper"></i> Admin Reports
                            </a>
                        </li>

                        <li class="nav-item" *appViperHasUserRole="'Admin'">
                            <a class="nav-link" routerLink="/signup-log" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fas fa-receipt"></i> Signup Log
                            </a>
                        </li>

                        <li class="nav-item" *appViperHasUserRole="'Admin'">
                            <a class="nav-link" routerLink="/event-log" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fas fa-receipt"></i> Event Logs
                            </a>
                        </li>

                        <li class="nav-item" *appViperHasUserRole="'Admin'">
                            <a class="nav-link" routerLink="/cdrs" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fas fa-chart-line"></i> CDRs
                            </a>
                        </li>

                        <li class="nav-item" *appViperHasUserRole="'Admin'">
                            <a class="nav-link" routerLink="/duplicate-destinations" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="far fa-copy"></i> Duplicate Destinations
                            </a>
                        </li>

                        <li class="nav-item" *appViperHasUserRole="'Admin'">
                            <a class="nav-link" routerLink="/card-history" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="far fa-credit-card"></i> Card History
                            </a>
                        </li>

                        <ng-container *appViperIsPostpay>
                            <li class="nav-item" *appViperHasUserRole="'Admin'">
                                <a class="nav-link" routerLink="/direct-debit-exports" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <i class="far fa-newspaper"></i> Direct Debit Exports
                                </a>
                            </li>

                            <li class="nav-item" *appViperHasUserRole="'Admin'">
                                <a class="nav-link" routerLink="/direct-debit-payer-exports" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <i class="far fa-newspaper"></i> Direct Debit Payer Exports
                                </a>
                            </li>
                        </ng-container>
                    </ul>
                </ng-container>

                <ng-container>
                    <hr class="my-3">
                    <h6 class="navbar-heading btn btn-link" data-toggle="collapse" data-target="#side-nav-switchboard">
                        Switchboard
                        <i class="fas fa-chevron-down"></i>
                    </h6>

                    <ul class="navbar-nav collapse" id="side-nav-switchboard" data-parent="#side-nav-accordion" routerLinkActive="show">
                        <li class="nav-item">
                            <a routerLink="/handsets"  class="nav-link" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fas fa-phone"></i> Handsets
                            </a>
                        </li>


                        <li class="nav-item" *appViperServiceExists="'Number'">
                            <a routerLink="/numbers"  class="nav-link" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fas fa-cloud"></i> Numbers
                            </a>
                        </li>

                        <li class="nav-item" *appViperServiceExists="'Group'">
                            <a routerLink="/groups" class="nav-link" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fas fa-users"></i> Call Forwarding Groups
                            </a>
                        </li>

                        <li class="nav-item" *appViperServiceExists="'Announcement'">
                            <a routerLink="/announcements" class="nav-link" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fas fa-bullhorn"></i> Announcements
                            </a>
                        </li>

                        <li class="nav-item" *appViperServiceExists="'Menu'">
                            <a routerLink="/menus" class="nav-link" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fas fa-bars"></i> Menus
                            </a>
                        </li>

                        <li class="nav-item" *appViperServiceExists="'Voicemail'">
                            <a routerLink="/voicemails" class="nav-link" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fas fa-microphone-alt"></i> Voicemail Boxes
                            </a>
                        </li>

                        <!-- <li class="nav-item">
                            <a routerLink="/queues" class="nav-link" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fas fa-hourglass"></i> Queues
                            </a>
                        </li> -->

                        <li class="nav-item" *appViperServiceExists="'Sip'">
                            <a routerLink="/sips" class="nav-link" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fas fa-phone"></i> VoIP Users
                            </a>
                        </li>

                        <li class="nav-item" *appViperServiceExists="'Channel'" >
                            <a routerLink="/channels" class="nav-link" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fas fa-code-branch"></i> Channels
                            </a>
                        </li>
                    </ul>
                </ng-container>
                
                <ng-container *appViperHasUserRole="'Admin'">
                    <hr class="my-3">
                    <h6 class="navbar-heading btn btn-link" data-toggle="collapse" data-target="#side-nav-rates">
                        Rates
                        <i class="fas fa-chevron-down"></i>
                    </h6>
                    <ul class="navbar-nav collapse" id="side-nav-rates" data-parent="#side-nav-accordion" routerLinkActive="show">
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/rates" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fa fa-money-bill-alt"></i> Rates
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/ingress-rates" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fa fa-money-bill-alt"></i> Ingress Rates
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/rate-overrides" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fa fa-money-bill-alt"></i> Rate Overrides
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/sheets" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fa fa-newspaper"></i> Sheets
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/bundles" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fa fa-shopping-basket"></i> Bundles
                            </a>
                        </li>

                        <li class="nav-item" *appViperIsPostpay>
                            <a class="nav-link" routerLink="/contract-types" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <i class="fas fa-file-signature"></i> Contract Types
                            </a>
                        </li>
                    </ul>
                </ng-container>

                <ng-container *appViperHasUserRole="'Server Admin'">
                    <hr class="my-3">
                    <h6 class="navbar-heading btn btn-link" data-toggle="collapse" data-target="#side-nav-servers">
                        Servers
                        <i class="fas fa-chevron-down"></i>
                    </h6>
                    <ul class="navbar-nav collapse" id="side-nav-servers" data-parent="#side-nav-accordion" routerLinkActive="show">
                        <li class="nav-item">
                            <a class="nav-link" routerLinkActive="active" routerLink="/inbound-servers">
                                <i class="fa fa-server"></i> Inbound Servers
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLinkActive="active" routerLink="/outbound-servers">
                                <i class="fa fa-server"></i> Outbound Servers
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLinkActive="active" routerLink="/switchboard-servers">
                                <i class="fa fa-server"></i> Switchboard Servers
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLinkActive="active" routerLink="/server-trunks">
                                <i class="fa fa-server"></i> Server Trunks
                            </a>
                        </li>
                    </ul>
                </ng-container>
            </div>
        </div>

    </div>
</div>


<ng-template #brand_logo>
    <img src="/assets/images/{{ app.short_name }}/logo.png" class="navbar-brand-img" alt="{{ app.name }} Logo">
</ng-template>
