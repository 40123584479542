<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/settings">Account Settings</a></li>
                <li class="breadcrumb-item active" aria-current="page">Addresses</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Addresses</h2>
                    </div>
                </div>
            </div>
            <div class="container">
                <ng-container *ngIf="account_config else loading">
                    <h3>Account Addresses</h3>

                    <div class="field">
                        <label class="label">Billing Address</label>
                        <select class="form-control" [(ngModel)]="account_config.billing_address_id">
                            <ng-container *ngFor="let address of addressTab.addresses">
                                <option [value]="address.id">{{ address.first_name }} {{ address.last_name }}, {{ address.address_line_1 }}, {{ address.address_line_2 }}, {{ address.city }}, {{ address.state_province }}, {{ address.country_region }}, {{ address.postal_code }}</option>
                            </ng-container>
                        </select>
                    </div>
                            
                    <!-- <div class="field">
                        <label class="label">Shipping Address</label>
                        <select class="form-control" [(ngModel)]="account_config.shipping_address_id">
                            <ng-container *ngFor="let address of addressTab.addresses">
                                <option [value]="address.id">{{ address.first_name }} {{ address.last_name }}, {{ address.address_line_1 }}, {{ address.address_line_2 }}, {{ address.city }}, {{ address.state_province }}, {{ address.country_region }}, {{ address.postal_code }}</option>
                            </ng-container>
                        </select>
                    </div> -->

                    <br>

                    <button class="btn btn-primary is-link" (click)="saveAccount()">Save</button>

                    <h3>Available Addresses</h3>
                    <app-addresses [account_id]="account.id" #addressTab></app-addresses>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
