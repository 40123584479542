import {Component} from '@angular/core';
import {NgbDate, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-ngbd-datepicker-range',
    templateUrl: './ngbd-datepicker-range.component.html',
    styleUrls: ['./ngbd-datepicker-range.component.css']
})
export class NgbdDatepickerRangeComponent {

    pickerVisible: boolean;

    hoveredDate: NgbDate;

    fromDate: NgbDate;
    toDate: NgbDate;

    constructor(calendar: NgbCalendar) {
        this.fromDate = null;
        this.toDate = null;
    }

    onDateSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
            this.toDate = date;
            this.pickerVisible = false;
        } else {
            this.toDate = null;
            this.fromDate = date;
        }
    }

    displayDate() {
        if (!this.fromDate || !this.toDate) {
            return null;
        }
        return `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day} to ${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`;
    }

    isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
        return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
    }

    clear() {
        this.fromDate = null;
        this.toDate = null;
    }

}
