import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { LoginService } from '../../../login/login.service';
import { SwitchboardService } from '../../../switchboard/switchboard.service';

import { SwitchboardObject } from '../../../switchboard/switchboard_object';

declare var toastr: any;

@Component({
  selector: 'app-portal-voicemail-edit',
  templateUrl: './portal-voicemail-edit.component.html',
  styleUrls: ['./portal-voicemail-edit.component.css']
})
export class PortalVoicemailEditComponent implements OnInit {

    account_id;
    parent;

    uuid: string;
    switchboard_object: SwitchboardObject;
    sips: SwitchboardObject[];
    new_email;
    subscription_sip;

    @ViewChild('email_modal') emailModal;

    constructor(
        private route: ActivatedRoute,
        private authService: LoginService,
        private switchboardService: SwitchboardService
    ) { }

    ngOnInit() {
        this.uuid = this.route.snapshot.paramMap.get('uuid');
        this.getSwitchboardObject();
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account_id = account.id;
            this.getSips();
            this.getParent();
        });
    }

    getParent() {
        const parent_uuid = this.route.snapshot.queryParamMap.get('parent');
        if (parent_uuid) {
            this.switchboardService.getSwitchboardObject(parent_uuid)
                .subscribe(parent => this.parent = parent);
        }
    }

    backToParent() {
        window.history.go(-2);
    }

    getSwitchboardObject() {
        this.switchboardService.getSwitchboardObject(this.uuid)
            .subscribe((object: SwitchboardObject) => this.switchboard_object = object);
    }

    getSips() {
        this.switchboardService.readSipsOnAccount(this.account_id)
            .subscribe(sips => this.sips = sips);
    }

    saveEmail() {
        this.switchboardService.updateSwitchboardObjectForAccount(this.account_id, {
            uuid: this.switchboard_object.uuid,
            email: this.new_email,
        }).subscribe(response => {
            toastr.success(`Saved email!`);
            this.switchboard_object.object_data.email = this.new_email;
            this.emailModal.close();
        });
    }

    notSubscribed(sip) {
        if (this.switchboard_object) {
            for (let subscribedSip of this.switchboard_object.object_data.subscribers) {
                if (subscribedSip.uuid === sip.uuid) {
                    return false;
                }
            }
        }
        return true;
    }

    subscribe() {
        this.switchboardService.updateSwitchboardObjectForAccount(this.account_id, {
            uuid: this.subscription_sip,
            voicemail_uuid: this.uuid,
        })
        .subscribe(response => {
            toastr.success(`Subscribed!`);
            this.getSwitchboardObject();
        });
    }

    unsubscribe(sip) {
        this.switchboardService.updateSwitchboardObjectForAccount(this.account_id, {
            uuid: sip.uuid,
            voicemail_uuid: null,
        })
        .subscribe(response => {
            toastr.success(`Unsubscribed!`);
            this.getSwitchboardObject();
        });
    }

}
