<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Switchboard Servers</h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <h3>Commit Stats</h3>
                        <canvas id="chart-stats" style="max-width: 640px; max-height: 240px;"></canvas>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table align-items-center table-flush">
                    <tbody *ngIf="servers else no_servers">
                        <tr *ngFor="let server of servers">
                            <th scope="row">{{ server.name }}</th>
                            <td>
                                <button class="btn btn-info" (click)="getDropletInfo(server)">Droplet Info</button>
                                <button class="btn btn-info" (click)="commit(server)">Commit</button>
                                <button class="btn btn-secondary" (click)="editServer(server)">Edit</button>
                                <button class="btn btn-danger" (click)="deleteServer(server)">Delete</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
                <div class="col text-right">
                    <button (click)="newServer()" class="btn btn-sm btn-primary" data-toggle="modal" data-target="#accountDetail">New Server</button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-modal (save)="saveServer()" title="New Server" #serverDetail>
    <div class="field form-group">
        <label class="label form-control-label">Name</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Name" [(ngModel)]="current_server.name">
        </div>
    </div>
    <div class="field form-group">
        <label class="label form-control-label">Server Host</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Server Host" [(ngModel)]="current_server.server_host">
        </div>
    </div>
    <div class="field form-group">
        <label class="label form-control-label">Registration Host</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Registration Host" [(ngModel)]="current_server.registration_host">
        </div>
    </div>
    <div class="field form-group">
        <label class="label form-control-label">Outbound Server</label>
        <div class="control">
            <select class="form-control" [(ngModel)]="current_server.outbound_server_id">
                <!-- <option value="">[None]</option> -->
                <option *ngFor="let server of outbound_servers" value="{{ server.id }}">{{ server.name }}</option>
            </select>
        </div>
    </div>
</app-modal>

<app-modal title="Droplet info for {{ current_server?.name }}" #dropletInfo>
    <ng-container *ngIf="droplet_info else is_loading">
        <h3>
            <i 
                class="fas fa-circle"
                [class.text-danger]="droplet_info.status == 'inactive'"
                [class.text-success]="droplet_info.status == 'active'"
                [title]="droplet_info.status"
            ></i>
            {{ droplet_info.name }}
            <i class="fas fa-lock" title="Locked" *ngIf="droplet_info.locked"></i>
            <i class="fas fa-lock-open" title="Unlocked" *ngIf="!droplet_info.locked"></i>
        </h3>

        <p>
            <i class="fab fa-ubuntu" *ngIf="droplet_info.image.distribution == 'Ubuntu'"></i>
            <i class="fab fa-suse" *ngIf="droplet_info.image.distribution == 'Suse'"></i>
            <i class="fab fa-redhat" *ngIf="droplet_info.image.distribution == 'Red Hat'"></i>
            <i class="fab fa-fedora" *ngIf="droplet_info.image.distribution == 'Fedora'"></i>
            <i class="fab fa-centos" *ngIf="droplet_info.image.distribution == 'CentOS'"></i>
            {{ droplet_info.image.description }} ({{ droplet_info.image.size_gigabytes}}GB)
        </p>

        <ng-container *ngIf="droplet_info.networks.v4 as networks">
            <h4>IPv4 Addresses</h4>
            <table class="table align-items-center" >
                <tr>
                    <th>IP</th>
                    <th>Netmask</th>
                    <th>Gateway</th>
                    <th>Type</th>
                </tr>
                <tr *ngFor="let network of networks">
                    <td>{{ network.ip_address }}</td>
                    <td>{{ network.netmask }}</td>
                    <td>{{ network.gateway }}</td>
                    <td>{{ network.type }}</td>
                </tr>
            </table>
        </ng-container>


        <ng-container>
            <h4>Size</h4>
            <table class="table align-items-center">
                <tr>
                    <th>Memory</th>
                    <td>{{ droplet_info.size.memory }}MB</td>
                </tr>
                <tr>
                    <th>Price (monthly)</th>
                    <td>&pound;{{ droplet_info.size.price_monthly }}</td>
                </tr>
                <tr>
                    <th>Disk</th>
                    <td>{{ droplet_info.size.disk }}</td>
                </tr>
            </table>
        </ng-container>

        <ng-container *ngIf="droplet_info.features as features">
            <h4>Features</h4>
            <span class="badge badge-info" *ngFor="let feature of features">{{ feature }}</span>
        </ng-container>

        <ng-container *ngIf="droplet_info.tags as tags">
            <h4>Tags</h4>
            <span class="badge badge-info" *ngFor="let tag of tags">{{ tag }}</span>
        </ng-container>
    </ng-container>
</app-modal>

<ng-template #no_servers>
    <tbody class="tbody">
        <tr class="tr">
            <td class="td" colspan="4">
                <span *ngIf="servers else is_loading">No Servers Found</span>
            </td>
        </tr>
    </tbody>
</ng-template>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
