<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/{{ object_type }}s">{{ object_type.replace('_', ' ')|alias|capitalize }}s</a></li>
                <li class="breadcrumb-item active" aria-current="page">New {{ object_type.replace('_', ' ')|alias|capitalize }}</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">
                            New {{ object_type.replace('_', ' ')|alias|capitalize }}
                        </h2>
                    </div>
                </div>
            </div>
            <div class="card-body" *ngIf="free_services?.length || services?.length else no_services">
                <label for="name_field">Name</label>
                <input type="text" class="form-control" [placeholder]="default_name" id="name_field" [(ngModel)]="object_name" maxlength="128">

                <ng-container *ngIf="object_type === 'sip'">
                    <label for="object-number">Outbound Caller ID</label>

                    <select class="form-control" [(ngModel)]="object_number" (change)="checkNumber()" id="object-number">
                        <option value="">Select Number</option>
                        <ng-container *ngFor="let number of numbers">
                            <!-- <option *ngIf="number.object_data.address_record" [value]="number.uuid">{{ number.object_data.e164_number|number_to_local }} ({{ number.object_data.name }})</option> -->
                            <option [value]="number.uuid">{{ number.object_data.e164_number|number_to_local }} ({{ number.object_data.name }})</option>
                        </ng-container>
                    </select>

                    <app-portal-number-emergency 
                        [account_id]="account_id"
                        [switchboard_object]="switchboard_object"
                        (save)="object_number = emergency_modal.switchboard_object.uuid"
                        #emergency_modal
                    ></app-portal-number-emergency>

                    <br>

                    <div class="alert alert-secondary">
                        Only numbers with 999 registration are valid to use. To check 999 registrations <a routerLink="/numbers" fragment="999">click here go to the numbers page</a> where you can edit the 999 registration for your numbers.
                    </div>

                    <!-- <label for="object-country">
                        Country Of Origin
                        <span class="text-sm text-muted">- This is where the VoIP user is based</span>
                    </label>
                    <input id="object-country" type="text" class="form-control" list="countryCodes" [(ngModel)]="object_country">
                    <datalist id="countryCodes">
                        <option *ngFor="let code of countryCodes" [value]="code.number">{{ code.name }}</option>
                    </datalist> -->
                </ng-container>

                <ng-container *ngIf="includeMedia()">
                    <br>
                    <p>
                        Currently Using: {{ media_component.getMediaFile()?.name || '[No Media]' }}
                        <button class="btn btn-primary" (click)="media_component.open()">Select Audio</button>
                    </p>
                </ng-container>

                <h3>Checkout</h3>

                <table class="table align-items-center table-flush">
                    <tbody *ngIf="services">
                        <tr *ngFor="let service of free_services">
                            <th scope="row">
                                <h3>Available</h3>
                            </th>
                            <td>
                                <h4>{{ service.service.name }}</h4>
                            </td>
                            <td>
                                <button class="btn btn-primary" (click)="purchaseWithServiceInstance(service)">Use</button>
                            </td>
                        </tr>
                        <tr *ngFor="let service of services">
                            <th scope="row">
                                <h3>£{{ service.retail_cost|format_balance }}</h3>
                            </th>
                            <td>
                                <h4>{{ service.name }}</h4>
                            </td>
                            <td>
                                <button class="btn btn-primary" (click)="purchase(service)" *appViperHasUserRole="'Account Owner'">Purchase</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ng-template #no_services>
                <div class="card-body">
                    <p>You currently cannot purchase this item</p>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<app-portal-component-media-select
    [account_id]="account_id"
    [switchboard_object]="object_media"
    [tts_text]="object_type === 'announcement' ? 'Welcome to XYZ, please hold while we put you through' : 'Welcome to XYZ. Press 1 for Sales. Press 2 for Service. Press 3 for support. or hold for the next available operator.'"
    [update]="false"
    #media_component
></app-portal-component-media-select>
