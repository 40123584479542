<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Call Forwarding Groups</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Call Forwarding Groups</h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <ng-container *ngTemplateOutlet="list_groups"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #list_groups>
    <div class="alert alert-danger" *ngIf="fragment === 'call-whisper'">
        <h4>Call Whisper</h4>
        <p>This free service is great when receiving calls on your mobile phone and lets you know that the inbound call is via your virtual number by playing you a personalised announcement, which you record in the MEDIA section of the portal (the caller hears the ring-ring, only you hear the announcement).</p>
        <p>To enable/disable/edit call whisper simply EDIT the required GROUP below and then select the CALL WHISPER option.</p>
    </div>

    <h3 *ngIf="groups !== null"> You have {{ groups?.length || 0 }} group{{ groups?.length === 1 ? '' : 's' }}</h3>

    <ng-container *appViperHasUserRole="'Account Owner'">
        <a class="btn btn-lg btn-block btn-outline-success text-lg" *ngIf="hasAvailableServices" routerLink="/groups/new">
            <i class="fas fa-plus "></i>
            New Call Forwarding Group
        </a>
    </ng-container>
    <hr>

    <ng-container *ngIf="groups?.length else no_switchboard_objects">
        <ng-container *ngFor="let group of groups; let i = index">
            <div class="card">
                <div class="card-body darkbody">
                    <div class="row">
                        <div class="col">
                            <h2 class="whitetext">
                                {{ group.object_data.name }}
                                -
                                <span class="text-muted text-sm" data-toggle="tooltip" data-placement="bottom" title="Every part of your system has an extension number that you can call directly using our VoIP service, or transfer a call to">Extension Number {{ group.extension_number }}</span>
                                &nbsp;
                                <span class="badge badge-pill badge-contrast badge-success" *ngIf="expired[group.service_instance_id]">Expired</span>
                            </h2>
                        </div>
                        <div class="col-2">
                            <a class="btn btn-primary" routerLink="/groups/{{ group.uuid }}">Edit</a>
                        </div>
                    </div>
                    <app-list-subservices-partial [sub_services]="sub_services[group.service_instance_id]" [switchboard_object]="group"></app-list-subservices-partial>
                    <div class="row">
                        <div class="col">
                            <p>
                                <span class="internal-members">
                                    <span class="text-lg">{{ group.object_data.internal_members.length }}</span> VoIP Users
                                </span>
                                |
                                <span class="external-members">
                                    <span class="text-lg">{{ group.object_data.external_members.length }}</span> Divert Numbers
                                </span>
                                <ng-container *ngIf="group.object_data.internal_members?.length || group.object_data.external_members?.length">
                                    <ul class="text-sm">
                                        <ng-container *ngIf="group.object_data.external_members.concat(group.object_data.internal_members) as members">
                                            <ng-container *ngFor="let member of members; let i = index">
                                                <li *ngIf="i < 2">
                                                    <ng-container *ngIf="member.e164_number">
                                                        <span class="flag-icon flag-icon-{{ (member.e164_number|number_to_country_code).toLowerCase() }}" [title]="member.e164_number|number_to_country_prefix"></span>
                                                        {{ member.e164_number|number_to_local }}
                                                    </ng-container>
                                                    <ng-container *ngIf="member.sip">
                                                        {{ member.sip.extension_number}} - {{ member.sip.object_data.name }}
                                                    </ng-container>
                                                </li>
                                            </ng-container>
                                            <ng-container *ngIf="members.length > 2">
                                                <li>..and {{ members.length - 2 }} more</li>
                                            </ng-container>
                                        </ng-container>
                                    </ul>
                                </ng-container>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col" *ngIf="switchboard_objects && getSwitchboardObject(group.object_data.switchboard_destination_uuid) as destination">
                            Forwarding to: 
                            <app-forwarding-to-partial [destination]="destination" show-edit="true"></app-forwarding-to-partial>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col text-right text-muted text-sm">
                            <strong>Last Edited: </strong> <span class="datetime">{{ (group.updated_at)|date:'medium' }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <br>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #no_switchboard_objects>
    <div class="card-body">
        <p *ngIf="groups?.length === 0 else loading">No Groups Found</p>
    </div>
</ng-template>

<ng-template #loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
