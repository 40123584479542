import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PipesModule } from '../../pipes/pipes.module';
import { LayoutModule } from '../../layout/layout.module';
import { ComponentsModule } from '../../components/components.module';
import { SwitchboardModule } from '../../switchboard/switchboard.module';
import { SectionsModule } from '../../sections/sections.module';
import { AudioModule } from '../../audio/audio.module';

import { AccountsModule } from '../../accounts/accounts.module';

import { PortalComponentsModule } from '../components/portal-components.module';
import { PortalSettingsRoutingModule } from './portal-settings-routing.module';

import { PortalSettingsComponent } from './portal-settings/portal-settings.component';
import { PortalUsersComponent } from './portal-users/portal-users.component';
import { PortalAddressesComponent } from './portal-addresses/portal-addresses.component';
import { PortalServicesComponent } from './portal-services/portal-services.component';
import { PortalKycComponent } from './portal-kyc/portal-kyc.component';


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        NgbModule,
        PipesModule,
        LayoutModule,
        ComponentsModule,
        SwitchboardModule,
        SectionsModule,
        AudioModule,

        AccountsModule,

        PortalComponentsModule,

        PortalSettingsRoutingModule,
    ],
    declarations: [
        PortalSettingsComponent,
        PortalUsersComponent,
        PortalAddressesComponent,
        PortalServicesComponent,
        PortalKycComponent,
    ],
    bootstrap: []
})

export class PortalSettingsModule { }
