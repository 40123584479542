<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item" *ngIf="account_id"><a routerLink="/accounts/detail/{{ account_id }}">Account</a></li>
                <li class="breadcrumb-item active" aria-current="page">Media</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Media</h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <p>Create personalised announcements like voicemail or IVR Auto Attendant Menus and save them here to use throughout your system.  Either use our text to speech system which allows you to type in your message or upload pre-recorded media files. We accept no responsibility for content added to your personal media library</p>
                <p>Once you've enabled music on hold, you can upload your own custom files, or select one of the available tracks from the Library. When you've done this, you can enable it as on hold music by editing the <a routerLink="/sips">VoIP User</a> you want to use it with. You can also add music on hold to a <a routerLink="/groups">Call Group</a> to replace the standard ringing a caller will hear.</p>
            </div>
            <ng-container *ngIf="account">
                <ng-container *ngTemplateOutlet="list_music"></ng-container>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #list_music>
    <app-music [account_id]="account.id" #music_tab></app-music>
</ng-template>
