import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from '../../login/authentication.guard';
import { RoleGuard } from '../../sections/roles/role.guard';

import { PortalSettingsComponent } from './portal-settings/portal-settings.component';
import { PortalUsersComponent } from './portal-users/portal-users.component';
import { PortalAddressesComponent } from './portal-addresses/portal-addresses.component';
import { PortalServicesComponent } from './portal-services/portal-services.component';
import { PortalKycComponent } from './portal-kyc/portal-kyc.component';


const routes: Routes = [
    { path: 'settings', canActivate: [AuthenticationGuard, RoleGuard], component: PortalSettingsComponent, data: {roles: ['Account Owner'], title: 'Settings'} },
    { path: 'settings/users', canActivate: [AuthenticationGuard, RoleGuard], component: PortalUsersComponent, data: {roles: ['Account Owner'], title: 'Users'} },
    { path: 'settings/addresses', canActivate: [AuthenticationGuard, RoleGuard], component: PortalAddressesComponent, data: {roles: ['Account Owner'], title: 'Addresses'} },
    { path: 'settings/services', canActivate: [AuthenticationGuard, RoleGuard], component: PortalServicesComponent, data: {roles: ['Account Owner'], title: 'Services'} },
    { path: 'settings/details', canActivate: [AuthenticationGuard, RoleGuard], component: PortalKycComponent, data: {roles: ['Account Owner'], title: 'Customer Details'} },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class PortalSettingsRoutingModule { }
