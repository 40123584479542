<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/handsets">Handsets</a></li>
                <li class="breadcrumb-item active" aria-current="page">Phonebook</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Handset Phonebook</h2>
                        <!-- <h3 *ngIf="handset">{{ handset.device_manufacturer }} {{ handset.device_model }} ({{ handset.mac_code }})</h3> -->
                    </div>
                </div>
            </div>
            <div class="card-body">
                <p><a routerLink="/handsets" class="btn btn-danger">Back</a></p>
                <p><button class="btn btn-primary" (click)="manage_groups_modal.open()">Manage Groups</button></p>
                <div class="table-responsive table-striped">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Number</th>
                                <th>Group</th>
                                <th>&nbsp;</th>
                            </tr>
                            <tr class="tr">
                                <td class="td">
                                    <input class="form-control" type="text" [(ngModel)]="new_contact.first_name" placeholder="First Name">
                                    <br>
                                    <input class="form-control" type="text" [(ngModel)]="new_contact.last_name" placeholder="Last Name">
                                </td>
                                <td>
                                    <label for="">Office Number: </label>
                                    <form [formGroup]="new_office_number">
                                        <ngx-intl-tel-input
                                                [preferredCountries]="[CountryISO.UnitedKingdom, CountryISO.UnitedStates]"
                                                [selectedCountryISO]="CountryISO.UnitedKingdom"
                                                [enableAutoCountrySelect]="true"
                                                [enablePlaceholder]="true"
                                                [searchCountryFlag]="true"
                                                [selectFirstCountry]="false"
                                                [phoneValidation]="true"
                                                [numberFormat]="PhoneNumberFormat.National"
                                                name="phone"
                                                formControlName="phone"
                                            ></ngx-intl-tel-input>
                                    </form>
                                    <br>
                                    <label for="">Mobile Number: </label>
                                    <form [formGroup]="new_mobile_number">
                                        <ngx-intl-tel-input
                                                [preferredCountries]="[CountryISO.UnitedKingdom, CountryISO.UnitedStates]"
                                                [selectedCountryISO]="CountryISO.UnitedKingdom"
                                                [enableAutoCountrySelect]="true"
                                                [enablePlaceholder]="true"
                                                [searchCountryFlag]="true"
                                                [selectFirstCountry]="false"
                                                [phoneValidation]="true"
                                                [numberFormat]="PhoneNumberFormat.National"
                                                name="phone"
                                                formControlName="phone"
                                            ></ngx-intl-tel-input>
                                    </form>
                                </td>
                                <td>
                                    <select class="form-control"
                                        #newContactGroup
                                        [(ngModel)]="new_contact.phonebook_group_id"
                                        (change)="checkOpenContact(newContactGroup.value)"
                                    >
                                        <option value="">Select a group</option>
                                        <option [value]="-1">[Create New Group]</option>
                                        <option *ngFor="let group of phonebook_groups" [value]="group.id">{{ group.name }}</option>
                                    </select>
                                </td>
                                <td class="td">
                                    <button class="btn btn-primary" (click)="createContact()">Create</button>
                                    <!-- <button class="btn btn-success" (click)="saveContact(contact)">Edit</button> -->
                                    <!-- <button class="btn btn-secondary" (click)="editContact(contact)">Cancel</button> -->
                                </td>
                            </tr>
                        </thead>
                        <tbody class="tbody">
                            <ng-container *ngIf="contacts?.length else no_contacts">
                                <ng-container *ngFor="let contact of contacts">
                                    <tr *ngIf="current_contact?.id === contact.id">
                                        <td class="td">
                                            <input class="form-control" type="text" [(ngModel)]="current_contact.first_name" placeholder="First Name">
                                            <br>
                                            <input class="form-control" type="text" [(ngModel)]="current_contact.last_name" placeholder="Last Name">
                                        </td>
                                        <td>
                                            <label for="">Office Number: </label>
                                            <form [formGroup]="current_office_number">
                                                <ngx-intl-tel-input
                                                        [preferredCountries]="[CountryISO.UnitedKingdom, CountryISO.UnitedStates]"
                                                        [selectedCountryISO]="CountryISO.UnitedKingdom"
                                                        [enableAutoCountrySelect]="true"
                                                        [enablePlaceholder]="true"
                                                        [searchCountryFlag]="true"
                                                        [selectFirstCountry]="false"
                                                        [phoneValidation]="true"
                                                        [numberFormat]="PhoneNumberFormat.National"
                                                        name="phone"
                                                        formControlName="phone"
                                                    ></ngx-intl-tel-input>
                                            </form>
                                            <br>
                                            <label for="">Mobile Number: </label>
                                            <form [formGroup]="current_mobile_number">
                                                <ngx-intl-tel-input
                                                        [preferredCountries]="[CountryISO.UnitedKingdom, CountryISO.UnitedStates]"
                                                        [selectedCountryISO]="CountryISO.UnitedKingdom"
                                                        [enableAutoCountrySelect]="true"
                                                        [enablePlaceholder]="true"
                                                        [searchCountryFlag]="true"
                                                        [selectFirstCountry]="false"
                                                        [phoneValidation]="true"
                                                        [numberFormat]="PhoneNumberFormat.National"
                                                        name="phone"
                                                        formControlName="phone"
                                                    ></ngx-intl-tel-input>
                                            </form>
                                        </td>
                                        <td>
                                            <select class="form-control"
                                                #newContactGroup
                                                [(ngModel)]="current_contact.phonebook_group_id"
                                                (change)="checkOpenContact(newContactGroup.value)"
                                            >
                                                <option value="">Select a group</option>
                                                <option [value]="-1">[Create New Group]</option>
                                                <option *ngFor="let group of phonebook_groups" [value]="group.id">{{ group.name }}</option>
                                            </select>
                                        </td>
                                        <td class="td">
                                            <button class="btn btn-primary is-link" (click)="saveContact()">Save</button>
                                            <button class="btn btn-secondary" (click)="current_contact = {}">Cancel</button>
                                        </td>
                                    </tr>
                                    <tr *ngIf="current_contact?.id !== contact.id">
                                        <td class="td">
                                            {{ contact.first_name }}
                                            {{ contact.last_name }}
                                        </td>
                                        <td>
                                            <ng-container *ngIf="contact.office_number">
                                                Office Number:
                                                <span class="flag-icon flag-icon-{{ (contact.office_number|number_to_country_code).toLowerCase() }}" [title]="contact.office_number|number_to_country_prefix"></span>
                                                {{ contact.office_number|number_to_local }}
                                            </ng-container>
                                            <br>
                                            <ng-container *ngIf="contact.mobile_number">
                                                Mobile Number:
                                                <span class="flag-icon flag-icon-{{ (contact.mobile_number|number_to_country_code).toLowerCase() }}" [title]="contact.mobile_number|number_to_country_prefix"></span>
                                                {{ contact.mobile_number|number_to_local }}
                                            </ng-container>
                                        </td>
                                        <td>
                                            {{ contact.phonebook_group?.name }}
                                        </td>
                                        <td class="td">
                                            <button class="btn btn-secondary is-link" (click)="editContact(contact)">Edit</button>
                                            <button class="btn btn-danger" (click)="deleteContact(contact)">Delete</button>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #no_contacts>
    <tr class="tr">
        <td class="td" colspan="4" *ngIf="contacts else is_loading">No contacts found</td>
    </tr>
</ng-template>
<ng-template #no_groups>
    <tr class="tr">
        <td class="td" colspan="2" *ngIf="phonebook_groups else is_loading">No groups found</td>
    </tr>
</ng-template>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>

<app-modal title="Create Group" (save)="saveGroup()" #group_modal>
    <div class="field">
        <label class="label">Name</label>
        <input class="input form-control" type="text" placeholder="Name" [(ngModel)]="current_group.name">
    </div>
</app-modal>

<app-modal title="Manage Group" #manage_groups_modal>
    <div class="table-responsive">
        <table class="table">
            <ng-container *ngIf="phonebook_groups.length else no_groups">
                <ng-container *ngFor="let group of phonebook_groups">
                    <tr *ngIf="current_group?.id !== group.id">
                        <td>
                            {{ group.name }}
                        </td>
                        <td>
                            <button class="btn btn-secondary" (click)="editGroup(group)">Edit</button>
                            <button class="btn btn-danger" (click)="deleteGroup(group)">Delete</button>
                        </td>
                    </tr>
                    <tr *ngIf="current_group?.id === group.id">
                        <td>
                            <input class="form-control" type="text" [(ngModel)]="current_group.name" placeholder="Name">
                        </td>
                        <td>
                            <button class="btn btn-primary" (click)="saveGroup()">Save</button>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
        </table>
    </div>
</app-modal>