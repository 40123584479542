import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

import { LoginService } from '../../login/login.service';
import { BillingService } from '../../billing.service';

import { Transaction } from './transaction';

declare var toastr: any;


@Component({
    selector: 'app-transactions',
    templateUrl: './transactions.component.html',
    styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {

    @Input() account_id;
    @Input() users;

    @Output() transactionChange = new EventEmitter<any>();

    admins;

    page = 1;
    transactions; // paginated object
    transaction: any = {};
    transaction_disabled = false;

    constructor(
        private authService: LoginService,
        private billingService: BillingService,
    ) { }

    ngOnInit() {
        this.getTransactions();
        this.authService.getUsersForCurrentAccount()
            .subscribe(users => this.admins = users);
    }

    getTransactions(page = null) {
        if (page === this.page) {
            return false;
        }
        if (page) {
            this.page = page;
        }
        this.transactions = null;
        this.billingService.getTransactionsForAccount(this.account_id, this.page)
            .subscribe(transactions => this.transactions = transactions);
    }

    addTransaction() {
        if (!this.transaction['amount']) {
            toastr.error('Amount required');
            return false;
        }
        if (!this.transaction['reason']) {
            toastr.error('Reason required');
            return false;
        }
        this.transaction_disabled = true;
        this.billingService.createTransactionForAccount(this.account_id, {
            reason: this.transaction.reason,
            amount: this.transaction.amount * 100,
        })
        .subscribe(response => {
            this.transaction = {};
            toastr.success('Transaction added');
            this.getTransactions();
            this.transaction_disabled = false;
            this.transactionChange.emit();
        });
    }

    findUser(user_id) {
        if (this.users) {
            for (const user of this.users) {
                if (user.id === user_id) {
                    return user;
                }
            }
        }

        if (this.admins) {
            for (const user of this.admins) {
                if (user.id === user_id) {
                    return user;
                }
            }
        }

        return null;
    }

}
