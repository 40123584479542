<a href="https://status.ip-vn.com/" target="_blank">
    <div class="alert alert-danger" *ngIf="hasIncidents">
        <i class="fa fa-exclamation-circle alertIcon"></i>
        <a href="https://status.ip-vn.com/" target="_blank">
            <i class="fa fa-chevron-right chevron-icon" aria-hidden="true"></i>
        </a>
        We are currently expreriencing issues.
        You can view a more detailed report on our <a class="alert-link" href="https://status.ip-vn.com/" target="_blank">Status Page</a>
    </div>
</a>

<a href="https://status.ip-vn.com/" target="_blank">
    <div class="alert alert-primary" *ngIf="hasMaintenance">
        <i class="fa fa-exclamation-circle alertIcon"></i>
        <a href="https://status.ip-vn.com/" target="_blank">
            <i class="fa fa-chevron-right chevron-icon" aria-hidden="true"></i>
        </a>
        There's maintenance scheduled soon.
        You can see more details about this on our <a class="alert-link" href="https://status.ip-vn.com/" target="_blank">Status Page</a>
    </div>
</a>
