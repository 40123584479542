<ng-container>
    <div class="row">
        <div class="col">
            <h3>Graph Summary</h3>
            <canvas id="chart-stats" style="max-height: 240px;"></canvas>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col">
            <h4>From Date Range</h4>
            <app-ngbd-datepicker-range #datepicker></app-ngbd-datepicker-range>
            <button class="btn btn-primary" (click)="getPaymentHistory()">Filter</button>
        </div>
    </div>
</ng-container>