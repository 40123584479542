import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { LoginService } from '../../login/login.service';
import { SwitchboardService } from '../../switchboard/switchboard.service';
import { CdrService } from '../../cdr.service';

import { SwitchboardObject } from '../../switchboard/switchboard_object';

declare var moment: any;
declare var Chart: any;
declare var $: any;

interface CallRecord {
    uuid: string;
    from_extension: string;
}

interface CdrResult {
    data: CallRecord[];
}

function getDateAtDaysAgo(days) {
    return moment().subtract(days, 'days');
}

function getDateRange(from = null, to = null) {
    let dateArray = [];
    let currentDate = getDateAtDaysAgo(30);
    let stopDate = moment();

    if (from && to) {
        currentDate = moment(`${from.year}-${from.month}-${from.day}`);
        stopDate = moment(`${to.year}-${to.month}-${to.day}`);
    }

    while (currentDate <= stopDate) {
        dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
        currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
}

@Component({
    selector: 'app-portal-callstats',
    templateUrl: './portal-callstats.component.html',
    styleUrls: ['./portal-callstats.component.css']
})
export class PortalCallstatsComponent implements OnInit, OnDestroy {

    @Input() account_id;

    page: number;
    direction = 'inbound';
    number = null;
    search_term = '';
    search_by = 'cli';

    cdrs: SwitchboardObject[];
    switchboard_objects: SwitchboardObject[];
    ingress_numbers: SwitchboardObject[];
    cdr_counts;
    stats_chart;

    selectedItems: any[] = [];
    cdrResults: {[key: string]: CdrResult} = {};
    flattenedResults: CallRecord[] = [];
    allCdrResults = [];

    @ViewChild('datepicker') datepicker;

    constructor(
        private authService: LoginService,
        private cdrService: CdrService,
        private switchboardService: SwitchboardService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit() {
        this.initDatepicker();
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account_id = account.id;
            this.loadStats();
            this.loadSwitchboardObjects();
        });
    }

    ngOnDestroy() {
        $('#chart_tooltip').hide();
    }

    loadStats() {
        this.search_term = this.activatedRoute.snapshot.queryParamMap.get('search');
        this.search_by = this.activatedRoute.snapshot.queryParamMap.get('search_by') || this.search_by;

        this.activatedRoute.paramMap.subscribe(params => {
            this.direction = params.get('direction') || this.direction;
            this.number = params.get('number');
            this.page = +params.get('page');
            this.loadCDRs();
        });
    }

    getCurrentPath() {
        let path = `/call-stats/${this.direction}`;
        if (this.number) {
            path += `/number/${this.number}`;
        }
        return path;
    }

    splitToDate(date: string) {
        const dateList = date.split('-');
        return {
            year: +dateList[0],
            month: +dateList[1],
            day: +dateList[2],
        };
    }

    initDatepicker() {
        if (this.datepicker) {
            const start_date = this.activatedRoute.snapshot.queryParamMap.get('start_date');
            if (start_date) {
                this.datepicker.fromDate = this.splitToDate(start_date);
            }
            const end_date = this.activatedRoute.snapshot.queryParamMap.get('end_date');
            if (end_date) {
                this.datepicker.toDate = this.splitToDate(end_date);
            }
        } else {
            setTimeout(() => this.initDatepicker(), 500);
        }
    }

    setDateToDaysAgo(days) {
        let start_date = getDateAtDaysAgo(days);
        let end_date = moment();
        this.datepicker.fromDate = {
            year: start_date.year(),
            month: start_date.month() + 1,
            day: start_date.date(),
        };
        this.datepicker.toDate = {
            year: end_date.year(),
            month: end_date.month() + 1,
            day: end_date.date(),
        };
        this.filter()
    }

    getStartDate() {
        if (this.datepicker.fromDate) {
            return `${this.datepicker.fromDate.year}-${this.datepicker.fromDate.month}-${this.datepicker.fromDate.day}`;
        }
    }

    getEndDate() {
        if (this.datepicker.toDate) {
            return `${this.datepicker.toDate.year}-${this.datepicker.toDate.month}-${this.datepicker.toDate.day}`;
        }
    }

    filter() {
        let params = {
            start_date: this.getStartDate(),
            end_date: this.getEndDate(),
        };

        if (this.search_term) {
            params['search'] = this.search_term;
            params['search_by'] = this.search_by;
        }

        this.router.navigate([this.getCurrentPath()], {
            queryParams: params
        });
        // this.loadCDRs();
        this.onSelectionChange();
    }

    loadCDRs(download = false) {
        if (this.datepicker) {
            let request;
            let data = {};
            if (this.datepicker.fromDate) {
                data = {
                    start_time_range_start: this.getStartDate(),
                    start_time_range_end: this.getEndDate(),
                };
            }
            if (this.page) {
                data['page'] = this.page;
            }

            if (download) {
                data['download'] = 1;
            }

            if (this.search_term) {
                data[this.search_by] = this.search_term;
            }

            if (this.number) {
                request = this.cdrService.readCDRsForAccountExtension(this.direction, this.account_id, this.number, data);
            } else {
                request = this.cdrService.readCDRsForAccount(this.direction, this.account_id, data);
            }

            if (!download) {
                this.cdrs = null;
                request.subscribe(cdrs => {
                    this.cdrs = cdrs;

                    // const floatHeader = function () {
                    //     const table = $('.table-float-header');
                    //     if (table.length) {
                    //         table.floatThead({
                    //             responsiveContainer: function($table) {
                    //                 return $table.closest('.table-responsive');
                    //             }
                    //         });
                    //     } else {
                    //         setTimeout(floatHeader, 500);
                    //     }
                    // };
                    // floatHeader();
                });
                this.getGraph();
            }
        } else {
            setTimeout(() => this.loadCDRs(download), 500);
        }
    }

    loadSwitchboardObjects() {
        this.switchboardService.getSwitchboardObjectsForAccount(this.account_id)
            .subscribe(objects => this.switchboard_objects = objects);
        this.switchboardService.readNumbersOnAccount(this.account_id, {
            ingress_enabled: 1,
        }).subscribe(numbers => this.ingress_numbers = numbers);
    }

    download() {
        this.loadCDRs(true);
    }

    getGraph() {
        if (this.stats_chart) {
            this.stats_chart.destroy();
        }

        this.cdrService.getCDRCounts(this.account_id, this.direction, this.number, this.datepicker.fromDate, this.datepicker.toDate)
        .subscribe(counts => {
            this.cdr_counts = counts;
            const range = getDateRange(this.datepicker.fromDate, this.datepicker.toDate);
            const exists = this.cdr_counts.map(x => x.day);
            for (let date of range) {
                if (exists.indexOf(date) === -1) {
                    this.cdr_counts.push({'day': date, 'number_of_calls': 0});
                }
            }
            this.cdr_counts.sort((a, b) => {
                if (a.day < b.day) {
                    return -1;
                }

                if (a.day > b.day) {
                    return 1;
                }

                return 0;
            });
            this.makeChart();
        });
    }


    makeChart() {
        let canvasElement = <HTMLCanvasElement>document.getElementById('chart-stats');
        let ctx = canvasElement.getContext('2d');

        this.stats_chart = new Chart(ctx, {
            type: 'bar',
            data: {
                datasets: [
                    {
                        label: `${this.direction} calls`,
                        data: this.cdr_counts.map(x => x.number_of_calls),
                    }
                ],
                labels: this.cdr_counts.map(x => x.day),
            },
            options: {
                responsive: true,
            }
        });
    }

    onSelectionChange(download = false): void {
        this.selectedItems = this.switchboard_objects.filter(obj => obj.selected);
        this.allCdrResults = [];
        let data = {};
    
        if (this.datepicker.fromDate) {
            data = {
                start_time_range_start: this.getStartDate(),
                start_time_range_end: this.getEndDate(),
            };
        }
    
        if (this.page) {
            data['page'] = this.page;
        }
    
        if (download) {
            data['download'] = 1;
        }
    
        if (this.search_term) {
            data[this.search_by] = this.search_term;
        }
    
        const promises = this.selectedItems.map(object => {
            var extensionValue = object.object_data.e164_number ? object.object_data.e164_number : object.extension_number;
            // if (!object.object_data.e164_number) {
            //     this.direction = 'outbound';
            // }
            // else if () {

            // }
            
            // Convert Observable to Promise
            return this.cdrService.readCDRsForAccountExtension(this.direction, this.account_id, extensionValue, data).toPromise();
        });
    
        Promise.all(promises).then(results => {
            // Process results here
            results.forEach((result, index) => {
                const extensionValue = this.selectedItems[index].object_data.e164_number ? this.selectedItems[index].object_data.e164_number : this.selectedItems[index].extension_number;
                this.allCdrResults.push({
                    identifier: extensionValue,
                    data: result.data
                });
            });
        }).catch(error => {
            // Handle any errors here
        });
    }
    
    updateFlattenedResults() {
        this.flattenedResults = Object.values(this.cdrResults)
                                      .reduce((acc, item) => acc.concat(item.data), [] as CallRecord[]);
    }

    toggleCheckbox(object: any, event: Event): void {
        event.stopPropagation();
        object.selected = !object.selected;
    }

    clearSelections(): void {
        this.switchboard_objects.forEach(obj => obj.selected = false);
    }
}
