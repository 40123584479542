<ng-container *ngIf="fullpage else log_content">
    <div class="row">
        <div class="col-xl-12 mb-5 mb-xl-0">
            <div class="card shadow">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Account Event Logs</li>
                  </ol>
                </nav>
                <div class="card-header border-0">
                    <div class="row align-items-center">
                        <div class="col">
                            <h2 class="mb-0">Account Event Logs</h2>
                        </div>
                    </div>
                </div>
                <ng-container *ngTemplateOutlet="log_content"></ng-container>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #no_logs>
    <tr>
        <td colspan="3">
            <span *ngIf="event_logs else is_loading">No Logs</span>
        </td>
    </tr>
</ng-template>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>

<ng-template #log_content>
    <div class="card-body">
        <div class="row">
            <div class="col">
                <app-ngbd-datepicker-range #datepicker></app-ngbd-datepicker-range>
                <select class="form-control" [(ngModel)]="event_type">
                    <option value="">All</option>
                    <option value="CREATED">Created</option>
                    <option value="ACTIVATED">Activated</option>
                    <option value="CANCELLED">Cancelled</option>
                    <option value="DELETED">Deleted</option>
                </select>
            </div>
            <div class="col">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button class="btn btn-primary" (click)="page = 1;getEventLog()">
                    Filter
                </button>
            </div>
            <div class="col">
            </div>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table is-bordered is-striped is-hoverable is-fullwidth">
            <thead>
                <tr>
                    <th>Account ID</th>
                    <th>Event Type</th>
                    <th>Balance Snapshot</th>
                    <th>Date</th>
                    <th>Updated By</th>
                </tr>
            </thead>
            <!-- <tbody *ngIf="event_logs?.path && event_logs?.data?.length else no_logs"> -->
            <tbody *ngIf="event_logs else no_logs">
                <tr *ngFor="let log of event_logs">
                    <td>{{ log.account_id }}</td>
                    <td>{{ log.event_type }}</td>
                    <td>&pound;{{ log.balance_snapshot|format_balance }}</td>
                    <td>{{ (log.created_at)|date:'medium' }}</td>
                    <td>{{ (log.userName || log.user_id) ? (log.userName + ' - ' + log.user_id) : 'System' }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer">
        <app-pagination [pagination]="event_logs" [(page)]="page" (page_change)="changePage($event)"></app-pagination>
    </div>
</ng-template>
