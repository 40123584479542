<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/groups">Call Forwarding Groups</a></li>
                <li class="breadcrumb-item active" aria-current="page">Edit Call Forwarding Group</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">
                            <span *ngIf="switchboard_object">
                                {{ switchboard_object.object_data.name }}
                                -
                                <span class="text-muted text-sm" data-toggle="tooltip" data-placement="bottom" title="Every part of your system has an extension number that you can call directly using our VoIP service, or transfer a call to">
                                    Extension Number {{ switchboard_object.extension_number }}
                                    <button class="btn btn-sm btn-outline-secondary" (click)="new_name = switchboard_object.object_data.name;rename_modal.open()">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                </span>
                            </span>

                        </h2>
                    </div>
                </div>
            </div>
            <div class="card-body" *ngIf="switchboard_object && media && sips else loading">
                <ng-container *ngTemplateOutlet="switchboard_object_edit"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #switchboard_object_edit>
    <div *ngIf="parent" class="btn btn-lg btn-block btn-outline-info text-lg darkcard" (click)="backToParent()">
        <i class="far fa-hand-point-left"></i> Go Back
        <br>
        <span class="text-muted text-sm">
            <i [class]="parent.object_type|capitalize|switchboard_object_icon" [title]="parent.object_type"></i>
            {{ parent.object_data.name }}
        </span>
    </div>
    
    <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" (click)="membersComponent.manageMembers(account_id, switchboard_object)">
        <i class="fas fa-users-cog"></i> Forward Calls To
        <br>
        <span class="text-muted text-sm" *ngIf="switchboard_object">
            <span class="external-members">
                <span class="text-lg">{{ switchboard_object.object_data.external_members.length }}</span> Divert Numbers
            </span>

            <span class="internal-members">
                <span class="text-lg">{{ switchboard_object.object_data.internal_members.length }}</span> VoIP Users
            </span>
        </span>
        <ng-container *ngIf="switchboard_object.object_data.internal_members?.length || switchboard_object.object_data.external_members?.length">
            <ul class="text-sm" style="list-style-position: inside;">
                <ng-container *ngIf="switchboard_object.object_data.external_members.concat(switchboard_object.object_data.internal_members) as members">
                    <ng-container *ngFor="let member of members; let i = index">
                        <li *ngIf="i < 2">
                            <ng-container *ngIf="member.e164_number">
                                <span class="flag-icon flag-icon-{{ (member.e164_number|number_to_country_code).toLowerCase() }}" [title]="member.e164_number|number_to_country_prefix"></span>
                                {{ member.e164_number|number_to_local }}
                            </ng-container>
                            <ng-container *ngIf="member.sip">
                                {{ member.sip.extension_number}} - {{ member.sip.object_data.name }}
                            </ng-container>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="members.length > 2">
                        <li>..and {{ members.length - 2 }} more</li>
                    </ng-container>
                </ng-container>
            </ul>
        </ng-container>
    </div>

    <a class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard"
       *appViperIsBrand="['vv']"
       routerLink="/time-management/{{ switchboard_object?.uuid }}">
        <i class="far fa-clock"></i> Time Management
    </a>

    <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard"
         *ngIf="switchboard_object"
         (click)="new_ring_time = switchboard_object.object_data.timeout;ringtime_modal.open()"
    >
        <i class="far fa-bell"></i> Ring Time
        <br>
        <span class="text-muted text-sm">
            <span class="text-lg">{{ switchboard_object.object_data.timeout }}</span> seconds
        </span>
    </div>

    <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" (click)="destination_modal.open()">
        <i class="fas fa-phone-volume "></i>
        If Not Answered Send To
        <br>
        <span class="text-muted text-sm" *ngIf="forwarding_component.getDestinationObject() as destination">
            <app-forwarding-to-partial [destination]="destination"></app-forwarding-to-partial>
        </span>
    </div>

    <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" 
         (click)="new_audio = switchboard_object.object_data.switchboard_media_file_uuid;use_existing = this.media.length > 0;whisper_modal.open()"
    >
        <i class="fas fa-user-secret"></i> Call Whisper
        <br>
        <div class="text-muted text-sm">
            Turned <span class="text-lg">{{ switchboard_object.object_data.switchboard_media_file_uuid ? 'On' : 'Off' }}</span>
            <ng-container *ngIf="getMediaFile() as media">
                ({{ media.name }})
            </ng-container>
        </div>
    </div>

    <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" 
         (click)="new_audio = switchboard_object.object_data.switchboard_music_file_uuid;use_existing = this.music.length > 0;music_modal.open()"
         *appViperAccountHasService="'MusicOnHold'"
    >
        <i class="fas fa-music"></i> Music On Hold
        <br>
        <div class="text-muted text-sm">
            <ng-container *ngIf="getMusicFile() as music">
                ({{ music.name }})
            </ng-container>
        </div>
    </div>

    <app-portal-component-addons [account_id]="account_id" [switchboard_object]="switchboard_object"></app-portal-component-addons>

    <app-portal-component-delete-switchboard-object [account_id]="account_id" [switchboard_object]="switchboard_object"></app-portal-component-delete-switchboard-object>
</ng-template>

<app-modal title="Rename Number" size="small" (save)="rename()" #rename_modal>
    <input type="text" class="form-control" [(ngModel)]="new_name">
</app-modal>

<app-modal title="Set Destination" size="big" (save)="destination_modal.close()" #destination_modal>
    <app-portal-component-destination-select [root]="switchboard_object" #forwarding_component></app-portal-component-destination-select>
</app-modal>

<app-modal title="Set Ring Time" size="small" (save)="setRingTime()" #ringtime_modal>
    <div class="input-group">
        <input type="text" class="form-control" [(ngModel)]="new_ring_time" maxlength="3">
        <div class="input-group-append">
            <span class="input-group-text" id="basic-addon2">seconds</span>
        </div>
    </div>
</app-modal>

<app-modal title="Call Whisper" size="" (save)="saveWhisperMedia()" #whisper_modal>

    <ng-container *ngIf="new_audio === null">
        <div class="btn btn-success btn-block"  (click)="new_audio = ''">Turn On</div>

        <p class="alert alert-secondary">You can personalise your call whisper announcement after you switch this option on</p>
    </ng-container>

    <ng-container *ngIf="new_audio !== null">
        <div class="btn btn-danger btn-block" (click)="new_audio = null;audio_preview?.pause()">Turn Off</div>
        <p class="text-lg text-center">
            <strong>Or</strong>
        </p>
        <ng-container *ngIf="use_existing">
            <p>Select one of your pre-recorded media files as your call whisper</p>

            <div class="input-group">
                <select class="form-control" [(ngModel)]="new_audio">
                    <option *ngFor="let file of media" [value]="file.uuid">{{ file.name }}</option>
                </select>

                <div class="input-group-append">
                    <button class="btn btn-success" [class.btn-danger]="audio_preview && !audio_preview.paused" (click)="preview('whisper')" target="_blank">
                        Preview

                        <i class="fas fa-play" *ngIf="audio_preview && !audio_preview.paused"></i>
                    </button>
                </div>
            </div>

            <button class="btn btn-block btn-warning" (click)="use_existing = !use_existing">Or Create New File</button>
        </ng-container>
        <ng-container *ngIf="!use_existing">
            <ng-container *ngIf="!uploading">
                <button class="btn btn-block btn-warning" (click)="use_existing = !use_existing" *ngIf="media.length > 0">Choose a previously uploaded file</button>

                <p class="text-center text-lg" *ngIf="media.length > 0">
                    <strong>Or</strong>
                </p>

                <app-media-upload-form [account_id]="account_id" (save)="uploadFile($event, 'whisper')" #upload_form></app-media-upload-form>

                <p class="text-center text-lg">
                    <strong>Or&nbsp;<span class="">type in your script. Select the language and voice model</span></strong>
                </p>

                <app-portal-tts-form tts_text="Type your call whisper message here" [account_id]="account_id" (save)="uploadFile($event)" #tts_form></app-portal-tts-form>
                
                <br>

                <div class="input-group">
                    <button (click)="tts_form.preview()" class="btn btn-primary">Preview</button>
                    &nbsp;
                    <!-- <button class="btn btn-primary" (click)="createTTS()">Create and Save</button> -->
                </div>
            </ng-container>

            <ng-container *ngIf="uploading">
                <app-loadingbar></app-loadingbar>
            </ng-container>
        </ng-container>
    </ng-container>
</app-modal>

<app-modal title="Music On Hold" size="" (save)="saveMusic()" #music_modal>
    <ng-container *ngIf="use_existing">
        <p>Select one of your music files as your on hold music</p>

        <div class="input-group">
            <select class="form-control" [(ngModel)]="new_audio">
                <option [ngValue]="null">[None]</option>
                <option *ngFor="let file of music" [value]="file.uuid">{{ file.name }}</option>
            </select>

            <div class="input-group-append">
                <button class="btn btn-success" [class.btn-danger]="audio_preview && !audio_preview.paused" (click)="preview('music')" target="_blank">
                    Preview

                    <i class="fas fa-play" *ngIf="audio_preview && !audio_preview.paused"></i>
                </button>
            </div>
        </div>

        <button class="btn btn-block btn-warning" (click)="use_existing = !use_existing">Or Create New File</button>
    </ng-container>
    <ng-container *ngIf="!use_existing">
        <ng-container *ngIf="!uploading">
            <button class="btn btn-block btn-warning" (click)="use_existing = !use_existing" *ngIf="music.length > 0">Choose a previously uploaded file</button>

            <p class="text-center text-lg" *ngIf="music.length > 0">
                <strong>Or</strong>
            </p>

            <app-media-upload-form [account_id]="account_id" (save)="uploadFile($event, 'music')" uploadTo="music" #upload_form></app-media-upload-form>
        </ng-container>

        <ng-container *ngIf="uploading">
            <app-loadingbar></app-loadingbar>
        </ng-container>
    </ng-container>
</app-modal>

<app-members [sips]="sips" (save)="getSwitchboardObject()" #membersComponent></app-members>

<ng-template #loading>
    <div class="card-body">
        <app-loadingbar></app-loadingbar>
    </div>
</ng-template>
