import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from '../login/authentication.guard';
import { RoleGuard } from '../sections/roles/role.guard';

import { RatesComponent } from './rates/rates.component';
import { IngressRatesComponent } from './ingress-rates/rates.component';
import { RateUpdateOverrideComponent } from './rate-update-override/rate-update-override.component';
import { BundlesComponent } from './bundles/bundles.component';
import { SheetsComponent } from './sheets/sheets.component';


const routes: Routes = [
    { path: 'rates', canActivate: [AuthenticationGuard, RoleGuard], component: RatesComponent, data: {roles: ['Admin'], title: 'Rates'} },
    { path: 'rates/:page', canActivate: [AuthenticationGuard, RoleGuard], component: RatesComponent, data: {roles: ['Admin'], title: 'Rates'} },
    { path: 'ingress-rates', canActivate: [AuthenticationGuard, RoleGuard], component: IngressRatesComponent, data: {roles: ['Admin'], title: 'Ingress Rates'} },
    { path: 'ingress-rates/:page', canActivate: [AuthenticationGuard, RoleGuard], component: IngressRatesComponent, data: {roles: ['Admin'], title: 'Ingress Rates'} },
    { path: 'rate-overrides', canActivate: [AuthenticationGuard, RoleGuard], component: RateUpdateOverrideComponent, data: {roles: ['Admin'], title: 'Rate Overrides'} },
    { path: 'rate-overrides/:page', canActivate: [AuthenticationGuard, RoleGuard], component: RateUpdateOverrideComponent, data: {roles: ['Admin'], title: 'Rate Overrides'} },
    { path: 'bundles', canActivate: [AuthenticationGuard, RoleGuard], component: BundlesComponent, data: {roles: ['Admin'], title: 'Bundles'} },
    { path: 'sheets', canActivate: [AuthenticationGuard, RoleGuard], component: SheetsComponent, data: {roles: ['Admin'], title: 'Sheets'} },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class RatesRoutingModule { }
