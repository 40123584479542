<h1 class="text-lg text-center" *ngIf="verify !== null">
    <i class="fas fa-check-circle text-success text-lg" *ngIf="verify?.id"></i>
    <i class="fas fa-times-circle text-danger text-lg" *ngIf="!verify?.id"></i>
    <br>
    {{ verify?.id ? 'Your Account has been verified!' : 'Verification failed' }}!
    <ng-container *ngIf="verify?.messages?.verify_code">
        <br>
        {{ verify?.messages?.verify_code }}
    </ng-container>
</h1>
<h1 class="text-lg text-center" *ngIf="verify === null">
    <app-loadingbar></app-loadingbar>
</h1>
<p *ngIf="verify?.id" class="text-center">
    <a href="/login" class="btn btn-danger">Login to your account</a>
</p>
