import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LoginService } from '../login/login.service';

@Component({
    selector: 'app-verify-user',
    templateUrl: './verify-user.component.html',
    styleUrls: ['./verify-user.component.css']
})
export class VerifyUserComponent implements OnInit {

    verify = null;

    constructor(
        private authService: LoginService,
        private route: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.verifyUser();
    }

    verifyUser() {
        const user = this.route.snapshot.paramMap.get('user');
        const token = this.route.snapshot.paramMap.get('token');
        this.authService.verifyUser(user, token)
            .subscribe(response => this.verify = response);
    }

}
