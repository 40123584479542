export const LocationHelper = {
    getAppFromURL(apps) {
        const origin = location.origin;
        for (let app of apps) {
            if (app.portal_url === origin) {
                return app;
            }
        }
        return null;
    },
    getMainSite() {
        return location.origin.replace(/\/\/\w+./, '//');
    },
    getSupport() {
        return location.origin.replace(/\/\/\w+/, '//support');
    }
};
