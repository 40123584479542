import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { CdrService } from '../cdr.service';

declare var moment: any;
declare var Chart: any;
declare var $: any;

@Component({
    selector: 'app-admin-cdrs',
    templateUrl: './admin-cdrs.component.html',
    styleUrls: ['./admin-cdrs.component.css']
})
export class AdminCdrsComponent implements OnInit {

    page: number;
    direction = 'outbound';
    search_term = '';
    search_by = 'cli';

    cdrs;

    constructor(
        private cdrService: CdrService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit() {
        this.loadStats();
    }

    loadStats() {
        this.activatedRoute.queryParams.subscribe(query => {
            this.search_term = query.get('search');
            this.search_by = query.get('search_by') || this.search_by;
        });
        this.activatedRoute.paramMap.subscribe(params => {
            this.page = +params.get('page');
            this.direction = params.get('direction') || this.direction;
            this.loadCDRs();
        });
    }

    splitToDate(date: string) {
        const dateList = date.split('-');
        return {
            year: +dateList[0],
            month: +dateList[1],
            day: +dateList[2],
        };
    }

    filter() {
        let params = {};

        if (this.search_term) {
            params['search'] = this.search_term;
            params['search_by'] = this.search_by;
        }

        this.router.navigate(['cdrs', this.direction], {
            queryParams: params
        });
        this.loadCDRs();
    }

    loadCDRs() {
        let data = {};

        if (this.page) {
            data['page'] = this.page;
        }

        if (this.search_term) {
            data[this.search_by] = this.search_term;
        }

        this.cdrs = null;
        this.cdrService.readCDRs(this.direction, data)
        .subscribe(cdrs => {
            this.cdrs = cdrs;

            const floatHeader = function () {
                const table = $('.table-float-header');
                if (table.length) {
                    table.floatThead({
                        responsiveContainer: function($table) {
                            return $table.closest('.table-responsive');
                        }
                    });
                } else {
                    setTimeout(floatHeader, 500);
                }
            };
            floatHeader();
        });
    }
}
