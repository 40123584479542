import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';

import { Observable, of } from 'rxjs';
import { catchError, map, tap, concatMap } from 'rxjs/operators';

import { Client } from '../../tools/client';

import { LoginService } from '../../login/login.service';

@Injectable({
    providedIn: 'root'
})
export class HandsetService extends Client {
    protected serviceURL = environment.services.switchboard;

    constructor(
        http: HttpClient,
        private authService: LoginService,
    ) {
        super(http);
    }

    getPhonebookGroups(account_id) {
        return this.callApi('get', `/accounts/${account_id}/provision/phonebook/groups`)
        .pipe(
            tap(_ => this.log(`Get phonebook groups for ${account_id}`)),
            catchError(this.handleError('getPhonebookGroups', []))
        );
    }

    createPhonebookGroup(account_id, phonebook_group) {
        return this.callApi('post', `/accounts/${account_id}/provision/phonebook/groups`, phonebook_group)
        .pipe(
            tap(_ => this.log(`Create phonebook group for ${account_id}`)),
            catchError(this.handleError('createPhonebookGroup', []))
        );
    }

    updatePhonebookGroup(account_id, phonebook_group) {
        const phonebook_group_id = phonebook_group.id;
        return this.callApi('put', `/accounts/${account_id}/provision/phonebook/groups/${phonebook_group_id}`, phonebook_group)
        .pipe(
            tap(_ => this.log(`Update phonebook group for ${account_id}`)),
            catchError(this.handleError('updatePhonebookGroup', []))
        );
    }

    deletePhonebookGroup(account_id, phonebook_group) {
        const phonebook_group_id = phonebook_group.id;
        return this.callApi('delete', `/accounts/${account_id}/provision/phonebook/groups/${phonebook_group_id}`)
        .pipe(
            tap(_ => this.log(`Update phonebook group for ${account_id}`)),
            catchError(this.handleError('deletePhonebookGroup', []))
        );
    }

    getContacts(account_id) {
        return this.callApi('get', `/accounts/${account_id}/provision/phonebook/contacts`)
        .pipe(
            tap(_ => this.log(`Get contacts for ${account_id}`)),
            catchError(this.handleError('getContacts', []))
        );
    }

    createContact(account_id, contact) {
        return this.callApi('post', `/accounts/${account_id}/provision/phonebook/contacts`, contact)
        .pipe(
            tap(_ => this.log(`Create contact for ${account_id}`)),
            catchError(this.handleError('createContact', []))
        );
    }

    updateContact(account_id, contact) {
        const contact_id = contact.id;
        return this.callApi('put', `/accounts/${account_id}/provision/phonebook/contacts/${contact_id}`, contact)
        .pipe(
            tap(_ => this.log(`Update contact for ${account_id}`)),
            catchError(this.handleError('updateContact', []))
        );
    }

    deleteContact(account_id, contact) {
        const contact_id = contact.id;
        return this.callApi('delete', `/accounts/${account_id}/provision/phonebook/contacts/${contact_id}`)
        .pipe(
            tap(_ => this.log(`Update contact for ${account_id}`)),
            catchError(this.handleError('deletePhonebookGroup', []))
        );
    }


}
