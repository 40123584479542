<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">VoIP Users</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">VoIP Users</h2>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col">
                        <div class="accordion">
                            <label for="tm" class="accordionitem">
                                <h2><i class="fas fa-play" style="padding-right:3%;"></i>Watch our help video</h2>
                            </label>
                            <input type="checkbox" id="tm"/>
                            <p class="hiddentext">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/p11uKbijzsk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </p>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="card-body">
                <ng-container *ngTemplateOutlet="list_sips"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #list_sips>
    <h3 *ngIf="sips !== null"> You have {{ sips?.length }} {{ sips?.length === 1 ? 'VoIP user' : 'VoIP users' }}</h3>
    <ng-container *ngIf="isTrial == false || isVV == false">    
        <ng-container *appViperHasUserRole="'Account Owner'">
            <a class="btn btn-lg btn-block btn-outline-success text-lg" *ngIf="hasAvailableServices" routerLink="/sips/new">
                <i class="fas fa-plus "></i>
                New VoIP User
            </a>
        </ng-container>
    </ng-container>
    <hr>

    <ng-container *ngIf="sips?.length else no_switchboard_objects">
        <ng-container *ngFor="let switchboard_object of sips; let i = index">
            <div class="card">
                <div class="card-body darkbody">
                    <div class="row">
                        <div class="col">
                            <h2 class="whitetext">
                                <ng-container *ngIf="busyLights[switchboard_object.extension_number] as state">
                                    <i class="fas fa-circle text-lg"
                                       [title]="state"
                                       [class.text-danger]="state == 'In use'"
                                       [class.text-blinking]="state == 'Ringing'"
                                       [class.text-success]="state == 'Not in use'"
                                    ></i>
                                    &nbsp;
                                </ng-container>
                                {{ switchboard_object.object_data.name }}
                                -
                                <span class="text-muted text-sm" data-toggle="tooltip" data-placement="bottom" title="Every part of your system has an extension number that you can call directly using our VoIP service, or transfer a call to">Extension Number {{ switchboard_object.extension_number }}</span>
                                &nbsp;
                                <ng-container *ngIf="services[switchboard_object.service_instance_id] as service">
                                    <ng-container *ngIf="service.is_bundle && !service.has_bundle && sub_service_instances.length">
                                        <span class="badge badge-pill badge-info" *ngIf="service.has_minutes">{{ service|minutes_used }} minutes used</span>
                                        <span class="badge badge-pill badge-warning" *ngIf="!service.has_minutes">Maxed Out</span>
                                    </ng-container>
                                    <ng-container *ngIf="service.has_bundle && sub_service_instances.length">
                                        <span class="badge badge-pill badge-info" *ngIf="service.sub_service_instances[0].has_minutes">{{ service|minutes_used }} minutes used</span>
                                        <span class="badge badge-pill badge-warning" *ngIf="!service.sub_service_instances[0].has_minutes">Maxed Out</span>
                                    </ng-container>

                                    <span class="badge badge-pill badge-contrast badge-success" *ngIf="service|has_expired">Expired</span>
                                </ng-container>
                            </h2>
                        </div>
                        <div class="col-2">
                            <a class="btn btn-primary" routerLink="/sips/{{ switchboard_object.uuid }}">Edit</a>
                        </div>
                    </div>
                    <app-list-subservices-partial [service]="services[switchboard_object.service_instance_id]" [switchboard_object]="switchboard_object"></app-list-subservices-partial>
                    <div class="row">
                        <div class="col" *ngIf="switchboard_objects && switchboard_object.object_data.number as destination">
                            Caller ID: 
                            <span class="flag-icon flag-icon-{{ (destination?.object_data.e164_number|number_to_country_code).toLowerCase() }}" [title]="destination?.object_data.e164_number|number_to_country_prefix"></span>
                            {{ destination?.object_data.e164_number|number_to_local }}
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col text-right text-muted text-sm">
                            <strong>Last Edited: </strong> <span class="datetime">{{ (switchboard_object.updated_at)|date:'medium' }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <br>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #no_switchboard_objects>
    <div class="card-body">
        <p *ngIf="sips?.length === 0 else loading">No VoIP Users Found</p>
    </div>
</ng-template>

<ng-template #loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
