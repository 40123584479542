import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';

import { MediaService } from '../media.service';
import { BillingService } from '../../billing.service';
import { LoginService } from '../../login/login.service';
import { ServicesService } from '../../services/services.service';

import { AudioHelper } from '../../tools/audio';

declare var toastr: any;
declare var alertify: any;
declare var $: any;

@Component({
  selector: 'app-music',
  templateUrl: './music.component.html',
  styleUrls: ['./music.component.css']
})
export class MusicComponent implements OnInit, OnDestroy {

    media_enabled: boolean;
    new_name: string;
    files: any[] = null;
    library: any[] = null;
    current_file: any;

    hasMusicOnHold = false;
    service;

    @Input() account_id: number;

    @ViewChild('rename_modal') renameModal;
    @ViewChild('rename_library_modal') renameLibraryModal;
    @ViewChild('upload_modal') uploadModal;
    @ViewChild('upload_modal_library') uploadModalLibrary;

    constructor(
        private mediaService: MediaService,
        private billingService: BillingService,
        private servicesService: ServicesService,
    ) { }

    ngOnInit() {
        this.billingService.getKashflowInvoices(this.account_id)
            .subscribe(invoices => this.media_enabled = !!invoices.Customer || !!invoices.length);
        this.loadFiles();
        this.getMusicOnHoldService();
        $(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
    }

    getMusicOnHoldService() {
        this.servicesService.getServicesForAccount(this.account_id)
        .subscribe(services => {
            this.hasMusicOnHold = !!services.filter(x => x.service.switchboard_type === 'MusicOnHold').length;
            if (!this.hasMusicOnHold) {
                this.servicesService.getAvailableServices(this.account_id, 'MusicOnHold')
                .subscribe(available_services => {
                    this.service = false;
                    if (available_services.length) {
                        this.service = available_services[0];
                    }
                });
            }
        });
    }

    ngOnDestroy() {
        // $('audio').each(audio => audio.pause());
    }

    loadFiles() {
        this.mediaService.getSwitchboardMusic(this.account_id)
            .subscribe(files => this.files = files);
        this.mediaService.getLibraryMusic()
            .subscribe(library => this.library = library);
    }

    purchaseMusicOnHold() {
        const price = (this.service.retail_cost / 100);
        alertify.confirm(`Are you sure you want to purchase Music On Hold for £${price} per month?`, () => {
            this.servicesService.addServiceToAccount(this.account_id, this.service.id)
            .subscribe(done => {
                this.servicesService.refresh();
                this.hasMusicOnHold = true;
            });
        });
    }

    getSound(file) {
        file.loaded = true;
        this.loadFile(file);
    }

    loadFile(file) {
        const elementID = `music-audio-controller-${file.uuid}`;
        this.mediaService.downloadSwitchboardMusic(this.account_id, file)
            .subscribe(AudioHelper.loadFileToAudioElement(elementID));
    }

    download(file) {
        const fileName = `music-${file.name}.wav`;
        this.mediaService.downloadSwitchboardMusic(this.account_id, file)
            .subscribe(AudioHelper.downloadAudioFile(fileName));
    }

    rename(new_name) {
        this.mediaService.renameSwitchboardMusic(this.account_id, {
            uuid: this.current_file.uuid,
            name: new_name,
        }).subscribe(response => {
            toastr.success(`Saved name!`);
            this.current_file.name = new_name;
            this.renameModal.close();
        });
    }

    delete(file) {
        alertify.confirm(`Are you sure you want to delete ${file.name}?`, () => {
            this.mediaService.deleteSwitchboardMusic(this.account_id, file)
            .subscribe(() => {
                toastr.success(`Music deleted`);
                this.loadFiles();
            });
        });
    }

    uploadFile(file) {
        this.loadFiles();
        this.uploadModal.close();
    }

    uploadLibraryFile(file) {
        this.loadFiles();
        this.uploadModalLibrary.close();
    }

    getLibrarySound(file) {
        file.loaded = true;
        this.loadLibraryFile(file);
    }

    loadLibraryFile(file) {
        const elementID = `library-audio-controller-${file.uuid}`;
        this.mediaService.downloadLibraryMusic(file)
            .subscribe(AudioHelper.loadFileToAudioElement(elementID));
    }

    libraryDownload(file) {
        const fileName = `media-${file.name}.wav`;
        this.mediaService.downloadLibraryMusic(file)
            .subscribe(AudioHelper.downloadAudioFile(fileName));
    }

    libraryRename(new_name) {
        this.mediaService.renameLibraryMusic({
            uuid: this.current_file.uuid,
            name: new_name,
        }).subscribe(response => {
            toastr.success(`Saved name!`);
            this.current_file.name = new_name;
            this.renameLibraryModal.close();
        });
    }

    libraryDelete(file) {
        alertify.confirm(`Are you sure you want to delete ${file.name}?`, () => {
            this.mediaService.deleteLibraryMusic(file)
            .subscribe(() => {
                toastr.success(`Music deleted`);
                this.loadFiles();
            });
        });
    }

    addToMusic(file) {
        alertify.confirm(`Are you sure you want to add ${file.name} to your music?`, () => {
            this.mediaService.addLibraryMusicToSwitchboard(this.account_id, file)
            .subscribe(() => {
                toastr.success(`Library music added!`);
                this.loadFiles();
            });
        });
    }
}
