export class Rule {
    uuid: string;
    name: string;
    switchboard_destination_uuid: string;
    time_range_start: string;
    time_range_end: string;
    days_json: string;
    months_json: string;
    dom_range_start: number;
    dom_range_end: number;
    priority: number;

    days: any;
    months: any;
    editing: boolean;
    saving: boolean;
}
