import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

@Pipe({name: 'isNotNull'})
export class IsNotNullPipe implements PipeTransform {

    private nullValues = [[], {}, null, undefined];

    transform(value: any) {
        for (let nullValue of this.nullValues) {
            if (nullValue === value) {
                return false;
            }
        }
        return true;
    }
}
