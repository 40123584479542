import { Component, OnInit, ContentChildren, QueryList, ElementRef } from '@angular/core';

import { TabComponent } from './tab/tab.component';

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.css']
})
export class TabsComponent implements OnInit {

    private tab;
    @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

    constructor() { }

    ngOnInit() {
    }

    select(selectedTab) {
        this.tabs.forEach(tab => tab.isActive = false);
        selectedTab.isActive = true;
    }

}
