import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

@Pipe({name: 'alias'})
export class AliasPipe implements PipeTransform {

    transform(value: any) {
        value += '';
        return value.replace(/^sip$/i, 'VoIP User')
                    .replace(/^group$/i, 'Call Forwarding Group');
    }
}
