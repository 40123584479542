import { Directive, ViewContainerRef, TemplateRef, OnInit, Input } from '@angular/core';

import { LoginService } from '../login/login.service';
import { ServicesService } from '../services/services.service';

import { RoleHelper } from '../sections/roles/role.helper';

@Directive({
    selector: '[appViperServiceExists]'
})
export class ServiceExistsDirective implements OnInit {

    @Input('appViperServiceExists') service: string|string[];

    @Input('appViperServiceExistsElse') alternative: TemplateRef<any> | null = null;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private template: TemplateRef<any>,
        private servicesService: ServicesService,
    ) { }

    ngOnInit(): void {
        this.servicesService.getCachedServices()
            .subscribe(services => this.render(services));
    }

    getServicesAsArray() {
        const isArray = Array.isArray(this.service);
        if (!isArray) {
            return [this.service];
        }
        return this.service;
    }

    render(services) {
        const hasService = this.accountHasService(services);
        this.viewContainerRef.clear();
        if (hasService) {
            this.viewContainerRef.createEmbeddedView(this.template);
        } else if (this.alternative) {
            this.viewContainerRef.createEmbeddedView(this.alternative);
        }
    }

    accountHasService(services) {
        let hasService = false;
        const accepted_services = this.getServicesAsArray();
        for (let service_type of accepted_services) {
            const matchingServices = services.filter(service => service.switchboard_type === service_type);
            hasService = hasService || !!matchingServices.length;
        }
        return hasService;
    }

}
