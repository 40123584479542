import { NgModule } from '@angular/core';

import { BrandIsDirective } from './brand-is.directive';
import { IsPostpayDirective } from './is-postpay.directive';
import { IsNotPostpayDirective } from './is-not-postpay.directive';
import { AccountHasServiceDirective } from './account-has-service.directive';
import { HasInboundBundlesDirective } from './has-inbound-bundles.directive';
import { HasOutboundBundlesDirective } from './has-outbound-bundles.directive';
import { HasUserRoleDirective } from './has-user-role.directive';
import { ServiceExistsDirective } from './service-exists.directive';
import { BrandIsNotDirective } from './brand-is-not.directive';
import { DndDirective } from './dnd.directive';

@NgModule({
    imports: [],
    declarations: [
        BrandIsDirective,
        IsPostpayDirective,
        IsNotPostpayDirective,
        AccountHasServiceDirective,
        HasInboundBundlesDirective,
        HasOutboundBundlesDirective,
        HasUserRoleDirective,
        ServiceExistsDirective,
        BrandIsNotDirective,
        DndDirective,
    ],
    bootstrap: [],
    exports: [
        BrandIsDirective,
        IsPostpayDirective,
        IsNotPostpayDirective,
        AccountHasServiceDirective,
        HasInboundBundlesDirective,
        HasOutboundBundlesDirective,
        HasUserRoleDirective,
        ServiceExistsDirective,
        BrandIsNotDirective,
        DndDirective,
    ]
})

export class DirectivesModule { }
