import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { LoginService } from '../../../login/login.service';
import { SwitchboardService } from '../../../switchboard/switchboard.service';
import { MediaService } from '../../../audio/media.service';
import { ServicesService } from '../../../services/services.service';

import { SwitchboardObject } from '../../../switchboard/switchboard_object';

declare var moment: any;

@Component({
    selector: 'app-portal-queues',
    templateUrl: './portal-queues.component.html',
    styleUrls: ['./portal-queues.component.css']
})
export class PortalQueuesComponent implements OnInit {

    @Input() account_id;

    hasAvailableServices = false;

    queues: SwitchboardObject[];
    switchboard_objects: SwitchboardObject[];
    current_number: SwitchboardObject;
    media: any[];

    services = [];
    expired = {};
    sub_services = {};

    constructor(
        private authService: LoginService,
        private switchboardService: SwitchboardService,
        private serviceService: ServicesService,
        private mediaService: MediaService
    ) { }

    ngOnInit() {
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account_id = account.id;
            this.loadQueues();
            this.loadSwitchboardObjects();
            this.loadMedia();
        });
    }

    loadQueues() {
        this.switchboardService.readQueuesOnAccount(this.account_id)
            .subscribe(queues => this.queues = queues);
    }

    loadSwitchboardObjects() {
        const object_type = 'Queue';
        this.switchboardService.getSwitchboardObjectsForAccount(this.account_id)
        .subscribe(objects => {
            this.switchboard_objects = objects;
            const used_services = objects
                .filter(object => object.object_type === object_type)
                .map(object => object.service_instance_id);
            this.serviceService.getServicesForAccount(this.account_id, object_type)
            .subscribe(services => {
                this.services = services;
                const free_services = services.filter(service => !used_services.includes(service.id));
                const hasFreeServices = free_services.length > 0;
                this.hasAvailableServices ||= hasFreeServices;
                this.expired = {};
                this.sub_services = {};
                for (let service of this.services) {
                    this.expired[service.id] = service.expires_at && moment(service.expires_at).isBefore();
                    this.sub_services[service.id] = {
                        bundles: [],
                        addons: [],
                    };
                    for (let sub_service of service.sub_service_instances) {
                        let type = sub_service.sheet_instances.length ? 'bundles' : 'addons';
                        sub_service['expired'] = sub_service.expires_at && moment(sub_service.expires_at).isBefore();
                        this.sub_services[service.id][type].push(sub_service);
                    }
                }
            });
        });

        this.serviceService.getAvailableServices(this.account_id, object_type)
            .subscribe(services => this.hasAvailableServices ||= services.length > 0);
    }

    loadMedia() {
        this.mediaService.getSwitchboardMedia(this.account_id)
            .subscribe(media => this.media = media);
    }

    getSwitchboardObject(uuid) {
        for (let object of this.switchboard_objects) {
            if (object.uuid === uuid) {
                return object;
            }
        }
        return null;
    }

    getSwitchboardMedia(uuid) {
        for (let object of this.media) {
            if (object.uuid === uuid) {
                return object;
            }
        }
        return null;
    }

}
