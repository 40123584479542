import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LoginService } from '../../login/login.service';

import { Account } from '../../accounts/account';

@Component({
    selector: 'app-voicemails',
    templateUrl: './voicemails.component.html',
    styleUrls: ['./voicemails.component.css']
})
export class VoicemailsComponent implements OnInit {

    accounts: any = null;
    private sid_start = 1000000;

    @Input() newAccount: Account = new Account();

    constructor(
        private route: ActivatedRoute,
        private authService: LoginService
    ) { }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            const page = +params.get('page');
            this.authService.getAccounts(page)
                .subscribe(accounts => this.accounts = accounts);
        });
    }

}
