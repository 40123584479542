import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, map, concatMap } from 'rxjs/operators';

import { LoginService } from './login.service';

import { AuthenticationHelper } from './authentication.helper';

declare var toastr: any;
declare var moment: any;

@Injectable({
    providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
    private account;

    constructor (
        private authService: LoginService,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
    ) {}

    logoutProxy() {
        AuthenticationHelper.wipeProxyKey();
        toastr.error(`Logged out of proxy login.`);
        return this.router.createUrlTree(['/home']);
    }

    logout() {
        const path = this.route.snapshot.queryParamMap.get('path') || this.location.path();
        AuthenticationHelper.wipeAuthKey();
        return this.router.createUrlTree(['login'], {
            queryParams: {
                path: path,
            }
        });
    }

    redirect() {
        if (AuthenticationHelper.isProxy()) {
            return this.logoutProxy();
        }
        return this.logout();
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const request = this.authService.getKey();

        return request.pipe(map(key => {
            const expiry = key && key.expires_at;
            const hasExpired = !expiry || moment(expiry).isBefore();
            if (hasExpired) {
                return this.redirect();
            }

            return true;
        }));
    }
}
