import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { ReCaptchaV3Service } from 'ng-recaptcha';

import { LoginService } from '../../../login/login.service';
import { BillingService } from '../../../billing.service';

declare var toastr: any;
declare var braintree: any;

@Component({
  selector: 'app-auto-topup',
  templateUrl: './auto-topup.component.html',
  styleUrls: ['./auto-topup.component.css']
})
export class AutoTopupComponent implements OnInit {

    account_id: number;
    account_config;
    hasBilling: boolean;

    topup_prices = [5, 10, 15, 25, 50];
    threshold_prices = [1, 3, 5, 10, 20];

    setting_default = true;
    payment_methods: any[];
    braintree_token: string;
    braintree_instance = null;

    three_d_instance = null;

    constructor(
        private authService: LoginService,
        private billingService: BillingService,
        private recaptchaService: ReCaptchaV3Service,
    ) { }

    ngOnInit() {
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account_id = account.id;
            this.authService.getAccountConfig(account.id)
            .subscribe(config => {
                this.account_config = config;
                this.hasBilling = !!config.billing_address_id;
                if (this.hasBilling) {
                    this.getBraintreeToken();
                }
            });
        });
    }

    getBraintreeToken() {
        this.recaptchaService.execute('submit')
        .subscribe(recaptcha_token => {
            this.setting_default = true;
            this.billingService.generateBraintreeToken(this.account_id, recaptcha_token)
            .subscribe(token => {
                this.braintree_token = token;
                this.initDropinContainer();
            });
        });
    }

    getPaymentMethods(callback = null) {
        this.payment_methods = null;
        this.billingService.getPaymentMethods(this.account_id)
        .subscribe(methods => {
            this.payment_methods = methods;
            if (callback) {
                callback();
            }
        });
    }

    saveAccountConfig() {
        this.authService.updateAccountConfig({
            id: this.account_id,
        }, this.account_config)
            .subscribe(() => toastr.success('Settings saved!'));
    }

    get3DSecureParameters() {
        return {
            amount: '0.01',
        };
    }

    initDropinContainer() {
        this.braintree_instance = false;
        braintree.dropin.create({
            authorization: this.braintree_token,
            container: '#dropin-container',
            vaultManager: true,
            threeDSecure: true,
        }, (createErr, instance) => {
            if (!createErr) {
                for (let card of instance._model._paymentMethods) {
                    if (card.default) {
                        instance._model.selectPaymentOption(card);
                    }
                }
            }

            this.braintree_instance = instance;
            this.setting_default = false;
        });
    }

    setCardAsDefault(cardDetails) {
        this.getPaymentMethods(() => {
            for (let card of this.payment_methods) {
                if (card.last4 === cardDetails.lastFour) {
                    console.log(card);
                    this.billingService.setDefaultPaymentMethod(this.account_id, card.token)
                    .subscribe(() => {
                        toastr.success('Card set as default');
                        this.getBraintreeToken();
                    });
                    return true;
                }
            }
            console.error('No such card???');
        });
    }

    removeCard(cardDetails) {
        this.getPaymentMethods(() => {
            for (let card of this.payment_methods) {
                if (card.last4 === cardDetails.lastFour) {
                    this.billingService.deletePaymentMethod(this.account_id, card.token)
                        .subscribe(() => this.getBraintreeToken());
                    return true;
                }
            }
        });
    }

    defaultCard() {
        this.setting_default = true;
        this.braintree_instance.requestPaymentMethod({
            threeDSecure: this.get3DSecureParameters(),
        }, (err, payload) => {
            if (payload) {
                if (payload.liabilityShifted || payload.type !== 'CreditCard') {
                    this.braintree_instance.teardown();
                    this.setCardAsDefault(payload.details);
                } else {
                    if (payload.binData.prepaid === 'Yes') {
                        toastr.error('We cannot accept a payment from your prepaid card, please use another payment method');
                    } else {
                        toastr.error('Unable to complete 3D secure verification');
                    }
                    this.braintree_instance.teardown();
                    this.removeCard(payload.details);
                }
            } else {
                this.setting_default = false;
            }
        });
    }

    enableTopup() {
        this.account_config.auto_topup_enabled = 1;
        this.account_config.auto_topup_threshold = Math.max(this.account_config.auto_topup_threshold || 0 , this.threshold_prices[0] * 100);
        this.account_config.auto_topup_amount = Math.max(this.account_config.auto_topup_amount || 0 , this.topup_prices[0] * 100);
        this.saveAccountConfig();
        this.getBraintreeToken();
    }

    setThreshold(price) {
        const threshold = (price * 100);
        this.account_config.auto_topup_threshold = (price * 100);
        if ((threshold - 500) > this.account_config.auto_topup_amount) {
            this.account_config.auto_topup_amount = this.topup_prices
                .map(x => x * 100)
                .filter(x => threshold - 500 <= x)[0];
        }
        this.saveAccountConfig();
    }

    setAmount(price) {
        const isDisabled = this.account_config.auto_topup_threshold - 500 > (price * 100);
        if (!isDisabled) {
            this.account_config.auto_topup_amount = (price * 100);
            this.saveAccountConfig();
        }
    }

    setValue(key, value) {
        this.account_config[key] = value;
        this.saveAccountConfig();
    }
}
