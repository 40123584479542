import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';

import { SwitchboardService } from './switchboard.service';
import { LoginService } from '../login/login.service';
import { MediaService } from '../audio/media.service';

import { SwitchboardObject } from './switchboard_object';
import { AudioHelper } from '../tools/audio';
import { PriceHelper } from '../tools/price.helper';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-switchboard',
    templateUrl: './switchboard.component.html',
    styleUrls: ['./switchboard.component.css']
})
export class SwitchboardComponent implements OnInit, OnDestroy {

    readonly sid_start: number = 1000000;

    @Input() account_id;
    @Input() addresses;
    @Input() media = [];

    @Input() service_component;

    @ViewChild('switchboard_object_modal') objectModal;

    switchboard;
    switchboard_config;

    switchboard_objects: SwitchboardObject[];
    switchboard_object: SwitchboardObject = new SwitchboardObject();
    current_extension;

    click_to_call_object;
    account_sips;
    music = [];

    switchboard_filter_search = '';

    private switchboard_servers: any[];

    private saving = false;
    type_form: string;
    is_new = true;

    private filterDropdown = false;
    private objectsFilter = 'all';

    private busyLightsInterval;
    busyLights;

    app;

    audio_preview;

    constructor(
        private authService: LoginService,
        private switchboardService: SwitchboardService,
        private mediaService: MediaService,
    ) { }

    ngOnInit() {
        // NOTE: This is for dialing with SIP.
        this.authService.getCurrentApp()
            .subscribe(app => this.app = app);
        this.authService.getCurrentAccount()
        .subscribe(admin_account => {
            this.switchboardService.readSipsOnAccount(admin_account.id)
                .subscribe(sips => this.account_sips = sips);
        });

        this.mediaService.getSwitchboardMusic(this.account_id)
            .subscribe(music => this.music = music);

        this.switchboardService.getSwitchboardFromAccount(this.account_id)
        .subscribe(switchboard => {
            if (switchboard.account_id) {
                this.switchboard = switchboard;
                this.getSwitchboardConfig();
            } else {
                this.createSwitchboard();
            }
        });

        this.switchboardService.getSwitchboardServers()
            .subscribe(servers => this.switchboard_servers = servers);
        this.getSwitchboardObjects();

        this.busyLightsInterval = setInterval(() => this.loadBusyLights(), 30 * 1000);
    }

    ngOnDestroy() {
        clearInterval(this.busyLightsInterval);
    }

    createSwitchboard() {
        this.switchboardService.createSwitchboard(this.account_id)
        .subscribe(switchboard => {
            this.switchboard = switchboard;
            this.getSwitchboardConfig();
        });
    }

    getSwitchboardConfig() {
        this.switchboardService.getSwitchboardConfig(this.account_id)
        .subscribe(config => {
            this.switchboard_config = config;
            PriceHelper.fieldToPounds(this.switchboard_config, 'max_call_cost');
        });
    }

    getAddress() {
        this.switchboardService.getNumberAddress(this.account_id, this.switchboard_object)
            .subscribe(address => this.switchboard_object['address'] = address);
    }

    saveAddress() {
        this.switchboardService.updateNumberAddress(this.account_id, this.switchboard_object, this.switchboard_object['address'])
            .subscribe();
    }

    setAddress(address) {
        this.switchboard_object['address'] = Object.assign({}, this.addresses[address]);
    }

    filterSwitchboardObjects(type) {
        this.objectsFilter = type;
        this.filterDropdown = false;
    }

    getSwitchboardObjects() {
        this.switchboard_objects = null;
        this.switchboardService.getSwitchboardObjectsForAccount(this.account_id)
        .subscribe(switchboard_objects => {
            this.switchboard_objects = switchboard_objects;
        });
        this.loadBusyLights();
    }

    loadBusyLights() {
        this.switchboardService.getSwitchboardBlfs(this.account_id)
            .subscribe(blfs => this.busyLights = blfs);
    }

    saveSwitchboard() {
        this.saving = true;
        this.switchboardService.updateSwitchboardFromAccount(this.account_id, this.switchboard)
        .subscribe(() => {
            toastr.success('Switchboard updated!');
            this.saving = false;
        });
        const switchboard_config = { ...this.switchboard_config };
        switchboard_config.max_call_cost *= 100;
        this.switchboardService.updateSwitchboardConfig(this.account_id, switchboard_config)
        .subscribe(() => {
            toastr.success('Switchboard config saved!');
        });
    }

    newSwitchboardObject(type) {
        this.is_new = true;
        this.type_form = type;
        this.switchboard_object = new SwitchboardObject();
        if (type === 'number') {
            this.switchboard_object['address'] = {};
        }
        this.objectModal.open();
    }

    editSwitchboardObject(switchboard_object) {
        this.is_new = false;
        this.type_form = switchboard_object.object_type.split(/(?=[A-Z])/).join('_').toLowerCase();
        this.current_extension = switchboard_object.extension_number;
        this.switchboard_object = switchboard_object.object_data;
        this.switchboard_object['uuid'] = switchboard_object.uuid;
        ['destination', 'number', 'sip'].forEach(field => {
            if (this.switchboard_object[field]) {
                this.switchboard_object[`${field}_uuid`] = this.switchboard_object[field]['uuid'];
            }
        });

        if (this.type_form === 'number') {
            this.getAddress();
        }

        this.switchboard_object['service_instance_id'] = switchboard_object.service_instance_id;
        this.objectModal.open();
    }

    deleteSwitchboardObject(switchboard_object) {
        alertify.confirm(`Are you sure you want to delete ${switchboard_object.object_data.name}?`, () => {
            switchboard_object.deleting = true;
            this.switchboardService.deleteSwitchboardObjectsForAccount(this.account_id, switchboard_object)
            .subscribe(() => {
                toastr.success(`Deleting ${switchboard_object.object_type}!`);
                this.getSwitchboardObjects();
                this.service_component.getServices();
            });
        });
    }

    saveSwitchboardObject() {
        let request;
        if (this.is_new) {
            request = this.switchboardService.createObjectOnAccount(this.account_id, `${this.type_form}s`, this.switchboard_object);
        } else {
            request = this.switchboardService.updateSwitchboardObjectForAccount(this.account_id, this.switchboard_object);
        }
        request.subscribe(switchboard_object => {
            if (switchboard_object.uuid) {
                this.switchboard_object.uuid = switchboard_object.uuid;

                if (this.is_new) {
                    toastr.success(`Created ${this.type_form}!`);
                } else {
                    toastr.success(`Updated ${this.type_form}!`);
                }

                if (this.type_form === 'number') {
                    this.saveAddress();
                }

                if (!this.is_new) {
                    this.switchboardService.updateSwitchboardObjectExtensionForAccount(
                        this.account_id, this.switchboard_object, +this.current_extension)
                    .subscribe(response => {
                        if (response.uuid) {
                            this.getSwitchboardObjects();
                            this.service_component.getServices();
                            this.objectModal.close();
                        } else {
                            this.objectModal.stopLoading();
                        }
                    });
                } else {
                    this.getSwitchboardObjects();
                    this.service_component.getServices();
                    this.objectModal.close();
                }

            } else {
                this.objectModal.stopLoading();
            }
        });
    }

    getSips() {
        if (!this.switchboard_objects) {
            return [];
        }
        return this.switchboard_objects.filter(object => object.object_type === 'Sip');
    }

    commit() {
        this.switchboardService.commitAccount(this.account_id)
        .subscribe(
            () => toastr.success('Switchboard comitted'),
            () => console.log('What went wrong?')
        );
    }

    clickToCall(sip) {
        this.switchboardService.clickToCall(this.click_to_call_object, sip);
    }

    stopPreview() {
        if (this.audio_preview && !this.audio_preview.paused) {
            this.audio_preview.pause();
        }
    }

    previewMedia(audio) {
        this.preview('media', audio);
    }

    previewMusic(audio) {
        this.preview('music', audio);
    }

    preview(type = 'media', audio) {
        let request;
        this.stopPreview();

        if (!this.audio_preview || this.audio_preview.uuid !== audio) {
            if (type === 'media') {
                request = this.mediaService.downloadSwitchboardMedia(this.account_id, {uuid: audio});
            } else if (type === 'music') {
                request = this.mediaService.downloadSwitchboardMusic(this.account_id, {uuid: audio});
            }

            request.subscribe(data => {
                this.audio_preview = AudioHelper.createAudioObjectFromData(data);
                this.audio_preview.uuid = audio;
            });
        } else {
            this.audio_preview = null;
        }
    }

}
