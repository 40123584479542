import { NgModule } from '@angular/core';

// Core Modules
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Installed Modules
import { GravatarModule } from 'ngx-gravatar';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

// My Modules
import { PipesModule } from '../pipes/pipes.module';
import { DirectivesModule } from '../directives/directives.module';
import { ComponentsModule } from '../components/components.module';
import { ContractsModule } from '../contracts/contracts.module';

// Components
import { SwitchboardComponent } from './switchboard.component';
import { MembersComponent } from './members/members.component';
import { RulesComponent } from './rules/rules.component';
import { NotesComponent } from './notes/notes.component';
import { CardHistoryComponent } from './card-history/card-history.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,

        GravatarModule,
        NgxIntlTelInputModule,

        PipesModule,
        DirectivesModule,
        ComponentsModule,
        ContractsModule,

        RouterModule,
    ],
    declarations: [
        SwitchboardComponent,
        MembersComponent,
        RulesComponent,
        NotesComponent,
        CardHistoryComponent,
    ],
    bootstrap: [],
    exports: [
        SwitchboardComponent,
        MembersComponent,
        RulesComponent,
        NotesComponent,
        CardHistoryComponent,
    ]
})

export class SwitchboardModule { }
