import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { Observable, of } from 'rxjs';
import { catchError, map, tap, concatMap } from 'rxjs/operators';

import { Client } from '../tools/client';

import { LoginService } from '../login/login.service';

@Injectable({
    providedIn: 'root'
})

export class ContractsService extends Client {

    protected serviceURL = environment.services.billing;

    constructor(
        http: HttpClient,
        private authService: LoginService,
    ) {
        super(http);
    }

    /* Contract Types */

    getContractTypes() {
        return this.callApi('get', '/contract_types')
        .pipe(
            tap(() => this.log('get contract types')),
            catchError(this.handleError('getContractTypes', []))
        );
    }

    getContractType(uuid) {
        return this.callApi('get', `/contract_types/${uuid}`)
        .pipe(
            tap(() => this.log(`get contract types ${uuid}`)),
            catchError(this.handleError('getContractType', []))
        );
    }

    createContractType(contract_type) {
        return this.callApi('post', '/contract_types', contract_type)
        .pipe(
            tap(() => this.log('created  contract type')),
            catchError(this.handleError('createContractType', []))
        );
    }

    updateContractType(contract_type) {
        const contract_type_uuid = contract_type.uuid;
        return this.callApi('put', `/contract_types/${contract_type_uuid}`, contract_type)
        .pipe(
            tap(apps => this.log('modified  contract type ' + contract_type)),
            catchError(this.handleError('updateContractType', []))
        );
    }

    deleteContractType(contract_type) {
        const contract_type_uuid = contract_type.uuid;
        return this.callApi('delete', `/contract_types/${contract_type_uuid}`)
        .pipe(
            tap(apps => this.log('delete contract type ' + contract_type)),
            catchError(this.handleError('deleteContractType', []))
        );
    }

    /* Contract Type Rates */

    getContractTypeRates(contract_type, page = 1) {
        const contract_type_uuid = contract_type.uuid;
        return this.callApi('get', `/contract_types/${contract_type_uuid}/rates`, { page: page })
        .pipe(
            tap(() => this.log('get contract types')),
            catchError(this.handleError('getContractTypeRates', []))
        );
    }

    getContractTypeRatesOverrides(contract_type, page = 1) {
        const contract_type_uuid = contract_type.uuid;
        return this.callApi('get', `/contract_types/${contract_type_uuid}/rates/overrides`, { page: page })
        .pipe(
            tap(() => this.log('get contract types')),
            catchError(this.handleError('getContractTypeRatesOverrides', []))
        );
    }

    createContractTypeRateOverride(rate) {
        const contract_type_uuid = rate.sip_contract_type_uuid;
        return this.callApi('post', `/contract_types/${contract_type_uuid}/rates/overrides`, rate)
        .pipe(
            tap(() => this.log('created  contract type')),
            catchError(this.handleError('createContractTypeRateOverride', []))
        );
    }

    updateContractTypeRateOverride(rate) {
        const contract_type_uuid = rate.sip_contract_type_uuid;
        const rate_id = rate.id;
        return this.callApi('put', `/contract_types/${contract_type_uuid}/rates/overrides/${rate_id}`, rate)
        .pipe(
            tap(apps => this.log('modified  contract type ' + rate)),
            catchError(this.handleError('updateContractTypeRateOverride', []))
        );
    }

    deleteContractTypeRateOverride(rate) {
        const contract_type_uuid = rate.sip_contract_type_uuid;
        const rate_id = rate.id;
        return this.callApi('delete', `/contract_types/${contract_type_uuid}/rates/overrides/${rate_id}`)
        .pipe(
            tap(apps => this.log('delete contract type ' + rate)),
            catchError(this.handleError('deleteContractTypeRateOverride', []))
        );
    }

    /* Contracts */

    getAccountContracts(account_id) {
        return this.callApi('get', `/accounts/${account_id}/switchboard/sip_contracts`)
        .pipe(
            tap(() => this.log(`get contracts for account ${account_id}`)),
            catchError(this.handleError('getAccountContracts', []))
        );
    }

    createContract(account_id, contract) {
        return this.callApi('post', `/accounts/${account_id}/switchboard/objects/${contract.switchboard_object_uuid}/contract`, contract)
        .pipe(
            tap(() => this.log('created contract')),
            catchError(this.handleError('createContract', []))
        );
    }

    updateContract(account_id, contract) {
        return this.callApi('put', `/accounts/${account_id}/switchboard/objects/${contract.switchboard_object_uuid}/contract`, contract)
        .pipe(
            tap(apps => this.log('modified contract')),
            catchError(this.handleError('updateContract', []))
        );
    }

    deleteContract(account_id, sip_uuid) {
        return this.callApi('delete', `/accounts/${account_id}/switchboard/objects/${sip_uuid}/contract`)
        .pipe(
            tap(apps => this.log('delete contract')),
            catchError(this.handleError('deleteContract', []))
        );
    }

    getHistoricContracts(account_id, switchboard_object, page=  1) {
        return this.callApi('get', `/accounts/${account_id}/switchboard/objects/${switchboard_object.uuid}/historic_contracts`, { page: page })
        .pipe(
            tap(() => this.log(`get historic contracts for account ${account_id}`)),
            catchError(this.handleError('getHistoricContracts', []))
        );
    }

}
