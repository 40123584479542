<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{ direction|capitalize }} Bundles</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">
                            {{ direction|capitalize }} Bundles
                            <ng-container *ngIf="getSwitchboardObject() as object">
                                for
                                <ng-container *ngIf="object.object_type === 'Number'">
                                    <span class="flag-icon flag-icon-{{ (object.object_data.e164_number|number_to_country_code).toLowerCase() }}" [title]="object.object_data.e164_number|number_to_country_prefix"></span>
                                    {{ object.object_data.e164_number|number_to_local }} - <span class="text-sm text-muted">{{ object.object_data.name }}</span>
                                </ng-container>
                                <ng-container *ngIf="object.object_type === 'Sip'">
                                    {{ object.extension_number }} - {{ object.object_data.name }}
                                </ng-container>
                            </ng-container>
                        </h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <p *ngIf="direction === 'inbound'">
                    Inbound call bundles are added per telephone number and are used for call forwarding purposes - so if you want to cover the cost of forwarding to a landline or mobile number without paying a per minute charge, then add an inbound call bundle.
                </p>

                <p *ngIf="direction === 'outbound'">
                    Outbound call bundles are added per voip extension and covers outbound calls you make.
                </p>

                <p>Please select where the bundle should be applied for.</p>
                <p>
                    Bundles for: 
                    <span class="dropdown">
                        <button class="btn btn-primary dropdown-toggle" type="button" id="number-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <ng-container *ngIf="switchboard_object && getSwitchboardObject() as object">
                                <ng-container *ngIf="object.object_type === 'Number'">
                                    <span class="flag-icon flag-icon-{{ (object.object_data.e164_number|number_to_country_code).toLowerCase() }}" [title]="object.object_data.e164_number|number_to_country_prefix"></span>
                                    {{ object.object_data.e164_number|number_to_local }} - <span class="text-sm text-muted">{{ object.object_data.name }}</span>
                                </ng-container>
                                <ng-container *ngIf="object.object_type === 'Sip'">
                                    {{ object.extension_number }} - {{ object.object_data.name }}
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="switchboard_object && !getSwitchboardObject()">
                                <app-loadingbar></app-loadingbar>
                            </ng-container>
                            <ng-container *ngIf="!switchboard_object && !getSwitchboardObject()">
                                <ng-container *ngIf="direction === 'inbound'">
                                    [Select Number]
                                </ng-container>
                                <ng-container *ngIf="direction === 'outbound'">
                                    [Select VoIP User]
                                </ng-container>
                            </ng-container>
                        </button>
                        <span class="dropdown-menu" aria-labelledby="number-dropdown">
                            <ng-container *ngFor="let object of switchboard_objects">
                                <ng-container *ngIf="direction === 'inbound' && object.object_type === 'Number'">
                                    <a class="dropdown-item" routerLink="/inbound-bundles/{{ object.uuid }}">
                                        <span class="flag-icon flag-icon-{{ (object.object_data.e164_number|number_to_country_code).toLowerCase() }}"></span>
                                        {{ object.object_data.e164_number|number_to_local }} - <span class="text-sm text-muted">{{ object.object_data.name }}</span>
                                        <span class="text-muted text-sm" *ngIf="getSwitchboardObjectBundle(object) as instance">
                                            <span class="badge badge-pill badge-info">{{ instance.sub_service.name }}</span>
                                            <ng-container *ngIf="!instance.has_minutes">
                                                <span class="badge badge-pill badge-warning">Maxed Out</span>
                                            </ng-container>
                                        </span>
                                    </a>
                                </ng-container>
                                <ng-container *ngIf="direction === 'outbound' && object.object_type === 'Sip'">
                                    <a class="dropdown-item" routerLink="/outbound-bundles/{{ object.uuid }}">
                                        {{ object.extension_number }} - {{ object.object_data.name }}
                                        <span class="text-muted text-sm" *ngIf="getSwitchboardObjectBundle(object) as instance">
                                            <span class="badge badge-pill badge-info">{{ instance.sub_service.name }}</span>
                                            <ng-container *ngIf="!instance.has_minutes">
                                                <span class="badge badge-pill badge-warning">Maxed Out</span>
                                            </ng-container>
                                        </span>
                                    </a>
                                </ng-container>
                            </ng-container>
                        </span>
                    </span>
                </p>

                <ng-container *ngIf="switchboard_object">
                    <ng-container *ngIf="sub_services else is_loading">
                        <ng-container *ngIf="purchased_count">
                            <h3>Current Bundle</h3>
                            <ng-container *ngFor="let sub_service of sub_services">
                                <div class="card" *ngIf="isPurchased(sub_service)">
                                    <div class="card-body darkcard">
                                        <h3 class="card-title">
                                            {{ sub_service.name }}
                                            
                                            <ng-container *ngIf="getAccountService() as account_service">
                                                <ng-container *ngFor="let instance of account_service.sub_service_instances">
                                                    <span class="badge badge-pill badge-info" *ngIf="instance.is_bundle && !instance.expired">{{ minutesUsed(instance) }} minutes used</span>

                                                    <ng-container *ngIf="instance.is_bundle && !instance.has_minutes">
                                                        <span class="badge badge-pill badge-warning">Maxed Out</span>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </h3>
                                        <p class="card-text">&pound;{{ sub_service.retail_cost|format_balance }} for {{ sub_service.renew_days || 30 }} days</p>
                                        
                                        <ng-container *ngIf="getAccountService() as account_service">
                                            <ng-container *ngFor="let instance of account_service.sub_service_instances">
                                                <ng-container *ngIf="instance.is_bundle && !instance.has_minutes">
                                                    <button class="btn btn-warning" (click)="renewBundle(sub_service)">Renew</button>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                        <button class="btn btn-danger" (click)="removeBundle(sub_service)">Remove</button>
                                    </div>
                                </div>
                            </ng-container>
                            <hr>
                        </ng-container>
                        
                        <ng-container *ngIf="available_count">
                            <div class="warningbox">
                                <p *ngIf="direction === 'inbound'">
                                    Where are you going to be forwarding the calls to? Type forwarding destination below
                                </p>

                                <p *ngIf="direction === 'outbound'">
                                    If you would like to check that a specific international destination is covered in an outbound call bundle you can type the telephone number into the rate checker below
                                </p>

                                <app-rate-checker bundles="all" #rate_checker></app-rate-checker>
                            </div>

                            <ng-container *ngIf="rate_checker.rates">

                                <p *ngIf="direction === 'inbound'">Great, all of the bundles displayed below will cover call forwarding to your chosen destination</p>

                                <p *ngIf="direction === 'outbound'">Great, all of the bundles displayed below will cover calls to your chosen destination</p>

                                <p *ngIf="canReccomend() && canNotReccomend()" class="alert alert-secondary">{{ canNotReccomend() }} bundles do not include that destination.</p>

                                <p *ngIf="!canReccomend() && canNotReccomend()" class="alert alert-danger">
                                    <ng-container *ngIf="direction === 'inbound'">
                                        I am sorry, we currently don't have any bundles which cover call forwarding to your chosen destination.
                                    </ng-container>
                                    <ng-container *ngIf="direction === 'outbound'">
                                        I am sorry, we currently don't have any bundles which cover calls to your chosen destination. 
                                    </ng-container>
                                    You can find the call rate prices to this destination as below;
                                    <br>
                                    <strong>&pound;{{ rate_checker.rates.retail_connection_charge|format_balance }}</strong> on connection and charging <strong>&pound;{{ rate_checker.rates.retail_cost_per_minute|format_balance }} per minute</strong>
                                    <br>
                                    Or, you can check a different forwarding destination by entering it above
                                </p>

                                <ng-container *ngFor="let sub_service of sub_services">
                                    <ng-container *ngIf="!isPurchased(sub_service)">
                                        <div class="row">
                                            <div class="col-8">
                                                <div class="card"  [class.transparent-box]="!bundleApplicable(sub_service)">
                                                    <div class="card-body darkcard">
                                                        <h4 class="card-title">{{ sub_service.name }}</h4>
                                                        <p class="card-text">&pound;{{ sub_service.retail_cost|format_balance }} for {{ sub_service.renew_days || 30 }} days</p>
                                                        <button class="btn btn-primary" (click)="purchase(sub_service)" *appViperHasUserRole="'Account Owner'">Purchase</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <p class="alert alert-success" *ngIf="bundleApplicable(sub_service)">
                                                    <i class="far fa-check-circle text-lg"></i>
                                                    Yes, this bundle covers this destination
                                                </p>

                                                <p class="alert alert-danger" *ngIf="!bundleApplicable(sub_service)">
                                                    <i class="far fa-times-circle text-lg"></i>
                                                    Sorry, that destination is not included in this bundle
                                                </p>
                                            </div>
                                        </div>
                                    </ng-container>
                                    
                                </ng-container>

                            </ng-container>
                            <ng-container *ngIf="!rate_checker.rates">
                                <ng-container *ngFor="let sub_service of sub_services">
                                    <div class="card" *ngIf="!isPurchased(sub_service)">
                                        <div class="card-body darkcard">
                                            <h4 class="card-title">{{ sub_service.name }}</h4>
                                            <p class="card-text">&pound;{{ sub_service.retail_cost|format_balance }} for {{ sub_service.renew_days || 30 }} days</p>
                                            <button class="btn btn-primary" (click)="purchase(sub_service)" *appViperHasUserRole="'Account Owner'">Purchase</button>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>

                            <br>

                            <p class="text-sm">Please note; call rates can fluctuate frequently, therefore some destinations may not be covered by the bundle at the time of the call.  Prices &amp; bundle inclusions are correct as of time of purchase.</p>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
