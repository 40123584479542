import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Core Modules
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// // Installed Modules
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// My Modules
import { PipesModule } from '../pipes/pipes.module';
import { DirectivesModule } from '../directives/directives.module';
import { LayoutModule } from '../layout/layout.module';
import { ComponentsModule } from '../components/components.module';
import { AudioRoutingModule } from './audio-routing.module';

// Components
import { VoicemailsComponent } from './voicemails/voicemails.component';
import { VoicemailComponent } from './voicemails/voicemail/voicemail.component';
import { CallRecordingsComponent } from './call-recordings/call-recordings.component';
import { MusicComponent } from './music/music.component';
import { PortalTtsFormComponent } from './media/portal-tts-form/portal-tts-form.component';
import { MediaUploadFormComponent } from './media/media-upload-form/media-upload-form.component';
import { MediaComponent } from './media/media.component';
import { PortalMusicComponent } from './portal-music/portal-music.component';

@NgModule({
    imports: [
        RouterModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        NgbModule,

        PipesModule,
        DirectivesModule,
        LayoutModule,
        ComponentsModule,

        AudioRoutingModule,
    ],
    declarations: [
        VoicemailsComponent,
        VoicemailComponent,
        CallRecordingsComponent,
        PortalMusicComponent,

        MusicComponent,
        PortalTtsFormComponent,
        MediaUploadFormComponent,
        MediaComponent,
    ],
    exports: [
        MusicComponent,
        PortalTtsFormComponent,
        MediaUploadFormComponent,
        MediaComponent,
    ],
})

export class AudioModule { }
