<div class="card-body">
    <h3>{{ direction|capitalize }} CDRs <span *ngIf="number">for {{ number }}</span></h3>
    
    <ng-container *ngTemplateOutlet="direction_options"></ng-container>
    <ng-container *ngTemplateOutlet="filter_options"></ng-container>
</div>

<ng-container *ngIf="cdrs else loading">
    <ng-container [ngSwitch]="direction">
        <ng-container *ngSwitchCase="'inbound'">
            <ng-container *ngTemplateOutlet="list_inbound_stats"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'outbound'">
            <ng-container *ngTemplateOutlet="list_outbound_stats"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'ingress'">
            <ng-container *ngTemplateOutlet="list_ingress_stats"></ng-container>
        </ng-container>
    </ng-container>
</ng-container>


<ng-template #list_inbound_stats>
    <!-- todo -->
    <div class="table-responsive">

        <table class="table align-items-center table-flush table-float-header table-striped">

            <thead>
                <tr>
                    <th>Start Time</th>
                    <th>Received Number</th>
                    <th>Destination</th>
                    <th>cli</th>
                    <th>Duration</th>
                </tr>
            </thead>
            <tbody *ngIf="cdrs && cdrs.data?.length else no_cdrs">
                <tr *ngFor="let cdr of cdrs.data">
                    <td>{{ (cdr.start_time)|date:'medium' }}</td>
                    <td>{{ cdr.recv_number }}</td>
                    <td>{{ cdr.dest }}</td>
                    <td>{{ cdr.cli }}</td>
                    <td>{{ cdr.duration }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="5">
                        <app-pagination [pagination]="cdrs" [(page)]="page" (page_change)="changePage($event)"></app-pagination>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</ng-template>

<ng-template #list_outbound_stats>
    <!-- todo -->
    <div class="table-responsive">
        <table class="table align-items-center table-flush table-float-header">
            <thead>
                <tr>
                    <th>Start Time</th>
                    <th>From Extension</th>
                    <th>Destination</th>
                    <th>cli</th>
                    <th>Duration</th>
                    <th>Cost</th>
                </tr>
            </thead>
            <tbody *ngIf="cdrs && cdrs.data.length else no_cdrs">
                <tr *ngFor="let cdr of cdrs.data">
                    <td>{{ (cdr.start_time)|date:'medium' }}</td>
                    <td>{{ cdr.from_extension }}</td>
                    <td>{{ cdr.dest }}</td>
                    <td>{{ cdr.cli }}</td>
                    <td>{{ cdr.duration }}</td>
                    <td>&pound;{{ cdr.retail_cost|format_balance }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="6">
                        <app-pagination [pagination]="cdrs" [(page)]="page" (page_change)="changePage($event)"></app-pagination>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</ng-template>

<ng-template #list_ingress_stats>
    <!-- todo -->
    <div class="table-responsive">
        <table class="table align-items-center table-flush table-float-header">
            <thead>
                <tr>
                    <th>Start Time</th>
                    <th>Origin</th>
                    <th>Destination</th>
                    <th>cli</th>
                    <th>Duration</th>
                    <th>Cost</th>
                </tr>
            </thead>
            <tbody *ngIf="cdrs && cdrs.data.length else no_cdrs">
                <tr *ngFor="let cdr of cdrs.data">
                    <td>{{ (cdr.start_time)|date:'medium' }}</td>
                    <td>{{ cdr.ingress_origin }}</td>
                    <td>{{ cdr.dest }}</td>
                    <td>{{ cdr.cli }}</td>
                    <td>{{ cdr.duration }}</td>
                    <td>&pound;{{ cdr.retail_cost|format_balance }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="6">
                        <app-pagination [pagination]="cdrs" [(page)]="page" (page_change)="changePage($event)"></app-pagination>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</ng-template>

<ng-template #direction_options>
    <div class="dropdown">
        <button class="btn btn-primary dropdown-toggle" type="button" id="dropdown-inbound" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Inbound
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdown-inbound">
            <a class="dropdown-item" (click)="datepicker.clear(); search_term = ''; direction = 'inbound';number = null;changePage(1);">All</a>
            <div class="dropdown-divider"></div>
            <ng-container *ngFor="let object of switchboard_objects">
                <a class="dropdown-item" *ngIf="object.object_type === 'Number'" (click)="datepicker.clear(); search_term = ''; direction = 'inbound';number = object.object_data.e164_number;changePage(1);" [class.active]="direction == 'inbound' && number == object.object_data.e164_number">{{ object.object_data.e164_number }}</a>
            </ng-container>
        </div>
    </div>

    <div class="dropdown">
        <button class="btn btn-primary dropdown-toggle" type="button" id="dropdown-outbound-sip" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Outbound By User
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdown-outbound-sip">
            <a class="dropdown-item" (click)="datepicker.clear(); search_term = ''; direction = 'outbound';number = null;changePage(1);">All</a>
            <div class="dropdown-divider"></div>
            <ng-container *ngFor="let object of switchboard_objects">
                <a class="dropdown-item" *ngIf="object.object_type === 'Sip'" (click)="datepicker.clear(); search_term = ''; direction = 'outbound';number = object.extension_number;changePage(1);" [class.active]="direction == 'outbound' && number == object.extension_number">{{ object.extension_number }}</a>
            </ng-container>
        </div>
    </div>

    <div class="dropdown">
        <button class="btn btn-primary dropdown-toggle" type="button" id="dropdown-outbound-number" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Outbound By Number
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdown-outbound-number">
            <a class="dropdown-item" (click)="datepicker.clear(); search_term = ''; direction = 'outbound';number = null;changePage(1);">All</a>
            <div class="dropdown-divider"></div>
            <ng-container *ngFor="let object of switchboard_objects">
                <a class="dropdown-item" *ngIf="object.object_type === 'Number'" (click)="datepicker.clear(); search_term = ''; direction = 'outbound';number = object.object_data.e164_number;changePage(1);" [class.active]="direction == 'outbound' && number == object.object_data.e164_number">{{ object.object_data.e164_number }}</a>
            </ng-container>
        </div>
    </div>

    <div class="dropdown">
        <button class="btn btn-primary dropdown-toggle" type="button" id="dropdown-ingress-number" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Ingress By Number
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdown-ingress-number">
            <a class="dropdown-item" (click)="datepicker.clear(); search_term = ''; direction = 'ingress';number = null;changePage(1);">All</a>
            <div class="dropdown-divider"></div>
            <ng-container *ngFor="let object of switchboard_objects">
                <a class="dropdown-item" *ngIf="object.object_type === 'Number'" (click)="datepicker.clear(); search_term = ''; direction = 'ingress';number = object.object_data.e164_number;changePage(1);" [class.active]="direction == 'ingress' && number == object.object_data.e164_number">{{ object.object_data.e164_number }}</a>
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #filter_options>
    <br>
    
    <div class="row">
        <div class="col">
            <h4>From Date Range</h4>
            <app-ngbd-datepicker-range #datepicker></app-ngbd-datepicker-range>
        </div>
    </div>

    <br>

    <div class="row">
        <div class="col">
            <div class="input-group">
                <div class="input-group-prepend">
                    <select class="custom-select" [(ngModel)]="search_by">
                        <option value="cli">CLI</option>
                        <option value="dest">Destination</option>
                    </select>
                </div>
                <input type="text" class="form-control" placeholder="Search" [(ngModel)]="search_term">
            </div>
            <button class="btn btn-primary" (click)="filter()">Filter</button>
            <button class="btn btn-primary" (click)="download()">Download CSV</button>
            <button class="btn btn-danger" (click)="datepicker.clear();filter()">Clear Dates</button>
        </div>
    </div>
    <br>
</ng-template>

<ng-template #loading>
    <div class="card-body">
        <app-loadingbar></app-loadingbar>
    </div>
</ng-template>
