export class OutboundServer {
    id: number;
    name: string;
    server_host: string;
    max_cpc: number;
    max_cpm: number;
    low_trunk_id: number;
    medium_trunk_id: number;
    high_trunk_id: number;
}
