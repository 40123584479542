<nav aria-label="Page navigation example" *ngIf="!url && pagination && pagination.current_page <= pagination.total">
  <ul class="pagination justify-content-center">
    <li class="page-item" [class.disabled]="pagination.current_page === 1">
      <a class="page-link" (click)="setPage(previous_page())" tabindex="-1">
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
      </a>
    </li>
    <li class="page-item">
      <a class="page-link" [class.active]="pagination.current_page === 1" (click)="setPage(1)">1</a>
    </li>
    <ng-container *ngIf="notFirstOrLastPage(pagination.current_page)">
      <li class="page-item disabled" *ngIf="pagination.current_page > 3">
        <a class="page-link" href="#">...</a>
      </li>

      <li class="page-item" *ngIf="notFirstOrLastPage(pagination.current_page - 1)">
        <a class="page-link" (click)="setPage(previous_page())">{{ previous_page() }}</a>
      </li>

      <li class="page-item" *ngIf="notFirstOrLastPage(pagination.current_page)">
        <a class="page-link active" (click)="setPage(pagination.current_page)">{{ pagination.current_page }}</a>
      </li>

      <li class="page-item" *ngIf="notFirstOrLastPage(pagination.current_page + 1)">
        <a class="page-link" (click)="setPage(next_page())">{{ next_page() }}</a>
      </li>

      <li class="page-item disabled" *ngIf="pagination.current_page < pagination.last_page - 2">
        <a class="page-link" href="#">...</a>
      </li>
    </ng-container>


    <li class="page-item" *ngIf="notOnePage()">
      <a class="page-link" [class.active]="pagination.current_page === pagination.last_page" (click)="setPage(pagination.last_page)">{{ pagination.last_page }}</a>
    </li>

    <li class="page-item" [class.disabled]="pagination.current_page === pagination.last_page">
      <a class="page-link" (click)="setPage(next_page())">
        <i class="fa fa-chevron-right" aria-hidden="true"></i>
      </a>
    </li>
  </ul>
</nav>


<nav aria-label="Page navigation example" *ngIf="url && pagination && pagination.current_page <= pagination.total">
  <ul class="pagination justify-content-center">
    <li class="page-item" [class.disabled]="pagination.current_page === 1">
      <a class="page-link" [routerLink]="getURL(previous_page())" tabindex="-1" [queryParams]="getQueryParams()">
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
      </a>
    </li>
    <li class="page-item">
      <a class="page-link" [class.active]="pagination.current_page === 1" [routerLink]="getURL(1)" [queryParams]="getQueryParams()">1</a>
    </li>
    <ng-container *ngIf="notFirstOrLastPage(pagination.current_page)">
      <li class="page-item disabled" *ngIf="pagination.current_page > 3">
        <a class="page-link" href="#">...</a>
      </li>

      <li class="page-item" *ngIf="notFirstOrLastPage(pagination.current_page - 1)">
        <a class="page-link" [routerLink]="getURL(previous_page())" [queryParams]="getQueryParams()">{{ previous_page() }}</a>
      </li>

      <li class="page-item" *ngIf="notFirstOrLastPage(pagination.current_page)">
        <a class="page-link active" [routerLink]="getURL(pagination.current_page)" [queryParams]="getQueryParams()">{{ pagination.current_page }}</a>
      </li>

      <li class="page-item" *ngIf="notFirstOrLastPage(pagination.current_page + 1)">
        <a class="page-link" [routerLink]="getURL(next_page())" [queryParams]="getQueryParams()">{{ next_page() }}</a>
      </li>

      <li class="page-item disabled" *ngIf="pagination.current_page < pagination.last_page - 2">
        <a class="page-link" href="#">...</a>
      </li>
    </ng-container>


    <li class="page-item" *ngIf="notOnePage()">
      <a class="page-link" [class.active]="pagination.current_page === pagination.last_page" [routerLink]="getURL(pagination.last_page)" [queryParams]="getQueryParams()">{{ pagination.last_page }}</a>
    </li>

    <li class="page-item" [class.disabled]="pagination.current_page === pagination.last_page">
      <a class="page-link" [routerLink]="getURL(next_page())" [queryParams]="getQueryParams()">
        <i class="fa fa-chevron-right" aria-hidden="true"></i>
      </a>
    </li>
  </ul>
</nav>
