<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Rates</h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <app-rate-checker #rate_checker></app-rate-checker>

                <ng-container *ngIf="rate_checker.rates as rate">
                    <table class="table is-bordered">
                        <tr>
                            <th>{{ rate.name }}</th>
                            <td>{{ rate.prefix }}</td>
                        </tr>
                        <tr>
                            <th>Connection Charge</th>
                            <td>{{ rate.retail_connection_charge }}p</td>
                        </tr>
                        <tr>
                            <th>Cost Per Minute</th>
                            <td>{{ rate.retail_cost_per_minute }}p</td>
                        </tr>
                        <tr>
                            <th>Wholesale Connection Charge</th>
                            <td>{{ rate.wholesale_connection_charge }}p</td>
                        </tr>
                        <tr>
                            <th>Wholesale Cost Per Minute</th>
                            <td>{{ rate.wholesale_cost_per_minute }}p</td>
                        </tr>
                    </table>
                </ng-container>
            </div>
            <div class="table-responsive">
                <table class="table is-bordered is-striped is-hoverable is-fullwidth">
                    <thead class="thead">
                        <tr class="tr">
                            <th class="th">Prefix</th>
                            <th class="th">Connection Charge</th>
                            <th class="th">Cost Per Minute</th>
                            <th class="th">&nbsp;</th>
                            <th class="th">&nbsp;</th>
                            <th class="th">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody class="tbody" *ngIf="rates?.path else no_rates">
                        <tr class="tr" *ngFor="let rate of rates.data">
                            <td class="td">
                                <h6>{{ rate.name }}</h6>
                                <h5>{{ rate.prefix }}</h5>
                            </td>
                            <td class="td">{{ rate.retail_connection_charge }}p</td>
                            <td class="td">{{ rate.retail_cost_per_minute }}p</td>
                            <td class="td"><button class="btn btn-secondary is-link" (click)="active_rate = rate;attachModal.open()">Attach To Sheet</button></td>
                            <td class="td"><button class="btn btn-secondary is-link" (click)="active_rate = rate;rateDetail.open()">Edit</button></td>
                            <td class="td"><button class="btn btn-danger is-danger" (click)="deleteRate(rate)" [class.is-loading]="rate.deleted">Delete</button></td>
                        </tr>
                    </tbody>
                    <tfoot class="tfoot">
                        <tr class="tr">
                            <th class="th" colspan="7">
                                <app-pagination [pagination]="rates" url="/rates"></app-pagination>

                                <button class="btn btn-primary" (click)="newRate()">Add Rate</button>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>

<app-modal title="Attach Rate" (save)="attachRate()" #attachModal>
    <div class="field">
        <label class="label">Service</label>
        <div class="control">
            <div class="select">
                <select [(ngModel)]="active_sheet" class="form-control">
                    <option *ngFor="let sheet of sheets" value="{{ sheet.id }}">{{ sheet.name }}</option>
                </select>
            </div>
        </div>
    </div>
</app-modal>

<app-modal title="{{ active_rate.id ? 'Edit' : 'New' }} Rate" (save)="saveRate()" #rateDetail>
    <div class="field">
        <label class="label">Name</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Name" [(ngModel)]="active_rate.name">
        </div>
    </div>
    <div class="field">
        <label class="label">Prefix</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Prefix" [(ngModel)]="active_rate.prefix">
        </div>
    </div>
    <div class="field">
        <label class="label">Retail Cost Per Minute</label>
        <div class="control">
            <div class="input-group">
                <input class="input form-control" type="email" placeholder="Cost per minute (pence)" [(ngModel)]="active_rate.retail_cost_per_minute">
                <div class="input-group-append">
                    <div class="input-group-text" id="btnGroupAddon">p</div>
                </div>
            </div>
        </div>
    </div>
    <div class="field">
        <label class="label">Retail Connection Charge</label>
        <div class="control">
            <div class="input-group">
                <input class="input form-control" type="email" placeholder="Cost per minute (pence)" [(ngModel)]="active_rate.retail_connection_charge">
                <div class="input-group-append">
                    <div class="input-group-text" id="btnGroupAddon">p</div>
                </div>
            </div>
        </div>
    </div>
    <div class="field">
        <label class="label">Wholesale Cost Per Minute</label>
        <div class="control">
            <div class="input-group">
                <input class="input form-control" type="email" placeholder="Cost per minute (pence)" [(ngModel)]="active_rate.wholesale_cost_per_minute">
                <div class="input-group-append">
                    <div class="input-group-text" id="btnGroupAddon">p</div>
                </div>
            </div>
        </div>
    </div>
    <div class="field">
        <label class="label">Wholesale Connection Charge</label>
        <div class="control">
            <div class="input-group">
                <input class="input form-control" type="email" placeholder="Cost per minute (pence)" [(ngModel)]="active_rate.wholesale_connection_charge">
                <div class="input-group-append">
                    <div class="input-group-text" id="btnGroupAddon">p</div>
                </div>
            </div>
        </div>
    </div>
    <div class="field">
        <label class="label">Shortcode</label>
        <div class="control">
            <input class="input form-control" type="email" placeholder="Shortcode (optional)" [(ngModel)]="active_rate.shortcode">
        </div>
    </div>
</app-modal>

<ng-template #no_rates>
    <tbody class="tbody">
        <tr class="tr">
            <td class="td" colspan="7">
                <ng-container *ngIf="rates else is_loading">No Rates Found</ng-container>
            </td>
        </tr>
    </tbody>
</ng-template>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
