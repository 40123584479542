<a class="card card-stats mb-4 mb-xl-0" routerLink="/voicemail-inbox">
    <div class="card-body">
        <div class="row">
            <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">Voicemails</h5>
            </div>
            <div class="col-auto">
                <div class="icon icon-shape bg-contrast text-white  rounded-circle shadow">
                    <i class="fas fa-microphone-alt"></i>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p class="h2 font-weight-bold mb-0" *ngIf="voicemail_new !== null else loading">
                    {{ voicemail_new }}
                    <span class="text-sm text-muted">Unread</span>
                    <span class="badge badge-success" *ngIf="voicemail_new !== 0">New</span>
                </p>
                <p class="mb-0" *ngIf="voicemail_count !== null">
                    <span class="text-info" style="margin-right: 5px">{{ voicemail_count }}</span>
                    <span class="text-sm text-nowrap">Total Voicemails</span>
                </p>
                <span class="font-weight-bold mb-0" ></span>
            </div>
        </div>
    </div>
</a>

<ng-template #loading>
    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
</ng-template>