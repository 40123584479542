import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';

import { MediaService } from '../media.service';
import { BillingService } from '../../billing.service';
import { LoginService } from '../../login/login.service';

import { CountryCodes } from '../../tools/country-codes';

import { AudioHelper } from '../../tools/audio';

declare var toastr: any;
declare var alertify: any;
declare var $: any;

@Component({
    selector: 'app-media',
    templateUrl: './media.component.html',
    styleUrls: ['./media.component.css']
})
export class MediaComponent implements OnInit, OnDestroy {

    media_enabled: boolean;
    new_name: string;
    files: any[] = null;
    current_file: any;

    @Input() account_id: number;

    @ViewChild('rename_modal') renameModal;
    @ViewChild('upload_modal') uploadModal;

    constructor(
        private mediaService: MediaService,
        private billingService: BillingService,
    ) { }

    ngOnInit() {
        this.billingService.getKashflowInvoices(this.account_id)
            .subscribe(invoices => this.media_enabled = !!invoices.Customer || !!invoices.length);
        this.loadFiles();
        $(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
    }

    ngOnDestroy() {
        // $('audio').each(audio => audio.pause && audio.pause());
    }

    loadFiles() {
        this.mediaService.getSwitchboardMedia(this.account_id)
            .subscribe(files => this.files = files);
    }

    getSound(file) {
        file.loaded = true;
        this.loadFile(file);
    }

    loadFile(file) {
        const elementID = `media-audio-controller-${file.uuid}`;
        this.mediaService.downloadSwitchboardMedia(this.account_id, file)
            .subscribe(AudioHelper.loadFileToAudioElement(elementID));
    }

    download(file) {
        const fileName = `media-${file.filename}.wav`;
        this.mediaService.downloadSwitchboardMedia(this.account_id, file)
            .subscribe(AudioHelper.downloadAudioFile(fileName));
    }

    rename(new_name) {
        this.mediaService.renameSwitchboardMedia(this.account_id, {
            uuid: this.current_file.uuid,
            name: new_name,
        }).subscribe(response => {
            toastr.success(`Saved name!`);
            this.current_file.name = new_name;
            this.renameModal.close();
        });
    }

    delete(file) {
        alertify.confirm(`Are you sure you want to delete ${file.name}?`, () => {
            this.mediaService.deleteSwitchboardMedia(this.account_id, file)
            .subscribe(() => {
                toastr.success(`Media deleted`);
                this.loadFiles();
            });
        });
    }

    uploadFile(file) {
        this.loadFiles();
        this.uploadModal.close();
    }

    createTTS(tts_file) {
        this.loadFiles();
        this.uploadModal.close();
    }

    accept(media) {
        this.mediaService.updateUnverifiedMedia(media.account_id, media.uuid, 1)
          .subscribe({
            next: (response) => {
              toastr.success(`Media Accepted`);
              this.loadFiles();
            },
            error: (error) => {
              toastr.error(`Error accepting media`);
              console.error(error);
            }
          });
      }
    
      deny(media) {
        this.mediaService.updateUnverifiedMedia(media.account_id, media.uuid, 2)
          .subscribe({
            next: (response) => {
              toastr.success(`Media Denied`);
              this.loadFiles();
            },
            error: (error) => {
              toastr.error(`Error denying media`);
              console.error(error);
            }
        });
      }

}
