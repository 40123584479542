import { environment } from '../../environments/environment';
import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PipesModule } from '../pipes/pipes.module';
import { LayoutModule } from '../layout/layout.module';
import { ComponentsModule } from '../components/components.module';
import { SwitchboardModule } from '../switchboard/switchboard.module';
import { SectionsModule } from '../sections/sections.module';
import { AudioModule } from '../audio/audio.module';

import { AdminRoutingModule } from './admin-routing.module';

import { EventLogComponent } from './event-log/event-log.component';
import { AdminKycVerifyComponent } from './admin-kyc-verify/admin-kyc-verify.component';
import { AdminHistoricKycComponent } from './admin-historic-kyc/admin-historic-kyc.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        NgbModule,
        // RecaptchaV3Module,
        PipesModule,
        LayoutModule,
        ComponentsModule,
        SwitchboardModule,
        SectionsModule,
        AudioModule,

        AdminRoutingModule,
    ],
    declarations: [
        EventLogComponent,
        AdminKycVerifyComponent,
        AdminHistoricKycComponent,
    ],
    exports: [
        EventLogComponent,
        AdminHistoricKycComponent,
    ],
    bootstrap: []
})

export class AdminModule { }
