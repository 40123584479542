import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Client } from '../tools/client';

@Injectable({
    providedIn: 'root'
})
export class RatesService extends Client {
    protected serviceURL = environment.services.rates;

    getAllRates(page = 1) {
        return this.callApi('get', `/rates`, {page: page})
        .pipe(
            tap(() => this.log('getting all rates page ' + page)),
            catchError(this.handleError('readAllRates', []))
        );
    }

    createRate(rate) {
        return this.callApi('post', `/rates`, rate)
        .pipe(
            tap(() => this.log('create rate')),
            catchError(this.handleError('createRate', []))
        );
    }

    updateRate(rate) {
        return this.callApi('put', `/rates/${rate.id}`, rate)
        .pipe(
            tap(() => this.log('update rate ' + rate.id)),
            catchError(this.handleError('updateRate', []))
        );
    }

    deleteRate(rate) {
        return this.callApi('delete', `/rates/${rate.id}`)
        .pipe(
            tap(() => this.log('delete rate ' + rate.id)),
            catchError(this.handleError('updateRate', []))
        );
    }

    attachRateToSheet(rate, sheet) {
        return this.callApi('post', `/sheets/${sheet}/rates/${rate.id}`)
        .pipe(
            tap(() => this.log(`attach rate ${rate.id} from sheet ${sheet}`)),
            catchError(this.handleError('attachRateToSheet', []))
        );
    }

    detachRateToSheet(rate, sheet) {
        return this.callApi('post', `/sheets/${sheet}/rates/${rate.id}`)
        .pipe(
            tap(() => this.log(`detach rate ${rate.id} from sheet ${sheet}`)),
            catchError(this.handleError('detachRateToSheet', []))
        );
    }

    /* Rate Override */

    getAllRateOverrides(page = 1) {
        return this.callApi('get', `/rates/overrides`, {page: page})
        .pipe(
            tap(() => this.log('getting all rates page ' + page)),
            catchError(this.handleError('getAllRateOverrides', []))
        );
    }

    createRateOverride(rate) {
        return this.callApi('post', `/rates/overrides`, rate)
        .pipe(
            tap(() => this.log('create rate')),
            catchError(this.handleError('createRateOverride', []))
        );
    }

    updateRateOverride(rate) {
        return this.callApi('put', `/rates/overrides/${rate.id}`, rate)
        .pipe(
            tap(() => this.log('update rate ' + rate.id)),
            catchError(this.handleError('updateRateOverride', []))
        );
    }

    deleteRateOverride(rate) {
        return this.callApi('delete', `/rates/overrides/${rate.id}`)
        .pipe(
            tap(() => this.log('delete rate ' + rate.id)),
            catchError(this.handleError('deleteRateOverride', []))
        );
    }

    /* Bundles */

    getBundles() {
        return this.callApi('get', `/bundles`)
        .pipe(
            tap(() => this.log('getting all bundles')),
            catchError(this.handleError('getAllBundles', []))
        );
    }

    createBundle(bundle) {
        return this.callApi('post', `/bundles`, bundle)
        .pipe(
            tap(() => this.log('create bundle')),
            catchError(this.handleError('createBundle', []))
        );
    }

    updateBundle(bundle) {
        return this.callApi('put', `/bundles/${bundle.id}`, bundle)
        .pipe(
            tap(() => this.log('update bundle ' + bundle.id)),
            catchError(this.handleError('updateBundle', []))
        );
    }

    deleteBundle(bundle) {
        return this.callApi('delete', `/bundles/${bundle.id}`)
        .pipe(
            tap(() => this.log('delete bundle ' + bundle.id)),
            catchError(this.handleError('deleteBundle', []))
        );
    }

    getSheetsForBundle(bundle) {
        return this.callApi('get', `/bundles/${bundle.id}/sheets`)
        .pipe(
            tap(() => this.log('getting all sheets for bundle ' + bundle.id)),
            catchError(this.handleError('getSheetsForBundle', []))
        );
    }

    attachSheetToBundle(bundle, sheet_id) {
        return this.callApi('post', `/bundles/${bundle.id}/sheets/${sheet_id}`)
        .pipe(
            tap(() => this.log(`adding sheet ${sheet_id} all sheets for bundle ${bundle.id}`)),
            catchError(this.handleError('attachSheetToBundle', []))
        );
    }

    detachSheetFromBundle(bundle, sheet) {
        return this.callApi('delete', `/bundles/${bundle.id}/sheets/${sheet.id}`)
        .pipe(
            tap(() => this.log(`adding sheet ${sheet.id} all sheets for bundle ${bundle.id}`)),
            catchError(this.handleError('attachSheetToBundle', []))
        );
    }

    /* Sheets */

    getSheets() {
        return this.callApi('get', `/sheets`)
        .pipe(
            tap(() => this.log('getting all sheets')),
            catchError(this.handleError('getAllSheets', []))
        );
    }

    createSheet(sheet) {
        return this.callApi('post', `/sheets`, sheet)
        .pipe(
            tap(() => this.log('create sheet')),
            catchError(this.handleError('createSheet', []))
        );
    }

    updateSheet(sheet) {
        return this.callApi('put', `/sheets/${sheet.id}`, sheet)
        .pipe(
            tap(() => this.log('update sheet ' + sheet.id)),
            catchError(this.handleError('updateSheet', []))
        );
    }

    deleteSheet(sheet) {
        return this.callApi('delete', `/sheets/${sheet.id}`)
        .pipe(
            tap(() => this.log('delete sheet ' + sheet.id)),
            catchError(this.handleError('deleteSheet', []))
        );
    }

    getRatesForSheet(sheet, page = 1) {
        return this.callApi('get', `/sheets/${sheet.id}/rates`, {page: page})
        .pipe(
            tap(() => this.log(`get rates for sheet ${sheet.id} page ${page}`)),
            catchError(this.handleError('getRatesForSheet', []))
        );
    }

    /* Sheet Rules */

    getSheetRules(sheet) {
        return this.callApi('get', `/sheets/${sheet.id}/rules`)
        .pipe(
            tap(() => this.log(`get rules for sheet ${sheet.id}`)),
            catchError(this.handleError('getSheetRules', []))
        );
    }

    createSheetRule(sheet, rule) {
        return this.callApi('post', `/sheets/${sheet.id}/rules`, rule)
        .pipe(
            tap(() => this.log('create sheet rule')),
            catchError(this.handleError('createSheetRule', []))
        );
    }

    deleteSheetRule(sheet, rule) {
        return this.callApi('delete', `/sheets/${sheet.id}/rules/${rule.id}`, )
        .pipe(
            tap(() => this.log('delete sheet rule')),
            catchError(this.handleError('deleteSheetRule', []))
        );
    }

    /* Rates Search + Bill */

    searchRates(term, page = 1) {
        return this.callApi('get', `/rates/search`, {
            page: page,
            number: term,
        })
        .pipe(
            tap(() => this.log('Searching rates')),
            catchError(this.handleError('searchRates', []))
        );
    }

    billNumber(number, bundles = []) {
        return this.callApi('post', `/rates/bill`, {
            number: number,
            bundles: bundles,
        })
        .pipe(
            tap(() => this.log('Billing number')),
            catchError(this.handleError('billNumber', []))
        );
    }

    /* Ingress Rate */

    getAllIngressRates(page = 1) {
        return this.callApi('get', `/ingress_rates`, {page: page})
        .pipe(
            tap(() => this.log('getting all rates page ' + page)),
            catchError(this.handleError('getAllIngressRates', []))
        );
    }

    createIngressRate(rate) {
        return this.callApi('post', `/ingress_rates`, rate)
        .pipe(
            tap(() => this.log('create rate')),
            catchError(this.handleError('createIngressRate', []))
        );
    }

    updateIngressRate(rate) {
        return this.callApi('put', `/ingress_rates/${rate.id}`, rate)
        .pipe(
            tap(() => this.log('update rate ' + rate.id)),
            catchError(this.handleError('updateIngressRate', []))
        );
    }

    deleteIngressRate(rate) {
        return this.callApi('delete', `/ingress_rates/${rate.id}`)
        .pipe(
            tap(() => this.log('delete rate ' + rate.id)),
            catchError(this.handleError('deleteIngressRate', []))
        );
    }

}
