import { Directive, ViewContainerRef, TemplateRef, OnInit, Input } from '@angular/core';

import { LoginService } from '../login/login.service';
import { ServicesService } from '../services/services.service';

@Directive({
    selector: '[appViperHasOutboundBundles]'
})
export class HasOutboundBundlesDirective implements OnInit {

    constructor(
        private viewContainerRef: ViewContainerRef,
        private template: TemplateRef<any>,
        private servicesService: ServicesService,
    ) { }

    ngOnInit(): void {
        this.servicesService.getCachedServices()
            .subscribe(services => this.outboundBundlesExist(services));
    }

    outboundBundlesExist(services) {
        this.viewContainerRef.clear();
        const outboundBundles = services.filter(service => service.switchboard_type === 'Sip' && service.has_bundle);
        const hasOutboundBundle = !!outboundBundles.length;
        if (hasOutboundBundle) {
            this.viewContainerRef.createEmbeddedView(this.template);
        }
    }

}
