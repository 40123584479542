<div class="table-responsive">
    <table class="table">
        <thead>
            <tr>
                <th>Amount</th>
                <th>Notes</th>
                <th>Created</th>
                <th>&nbsp;</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="input-group-text" id="btnGroupAddon">&pound;</div>
                        </div>
                        <input class="form-control" [(ngModel)]="current_adjustment.amount" placeholder="Amount">
                    </div>
                </td>
                <td [attr.colspan]="2">
                    <input class="form-control" [(ngModel)]="current_adjustment.name" placeholder="Name">
                    <br>
                    <textarea cols="30" rows="10" class="form-control" [(ngModel)]="current_adjustment.notes" placeholder="Notes..."></textarea>
                    <ng-container *ngIf="recurring">
                        <br>
                        <div class="field" id="dp">
                            <label class="label">Expires At</label>
                            <div class="control">
                                <input
                                    class="form-control"
                                    (click)="datepicker.toggle()"
                                    type="text"
                                    ngbDatepicker
                                    [(ngModel)]="datepickerdate"
                                    #datepicker="ngbDatepicker"
                                    (dateSelect)="onDateSelect($event)"
                                />
                            </div>
                        </div>
                    </ng-container>
                </td>
                <td>
                    <button class="btn btn-primary" (click)="createAdjustment()">Create</button>
                </td>
            </tr>
            <ng-container *ngIf="adjustments?.data?.length else no_adjustments">
                <tr *ngFor="let adjustment of adjustments.data">
                    <td>
                        &pound;{{ adjustment.amount|format_balance }}
                    </td>
                    <td>
                        <b>{{ adjustment.name }}</b>
                        <br>
                        {{ adjustment.notes }}
                    </td>
                    <td>
                        <strong>Created at:</strong> {{ adjustment.created_at|date:'medium' }}
                        <ng-container *ngIf="adjustment.applied_at">
                            <br>
                            <strong>Applied at:</strong> {{ adjustment.applied_at|date:'medium' }}
                        </ng-container>
                        <ng-container *ngIf="adjustment.applied_at === null">
                            <br>
                            <strong>Applied at:</strong>&nbsp;<em>NOT APPLIED</em>
                        </ng-container>
                        <ng-container *ngIf="recurring">
                            <br>
                            <strong>Expires at:</strong> {{ adjustment.expires_at|date:'medium' }}
                        </ng-container>
                    </td>
                    <td>
                        <button class="btn btn-danger" (click)="deleteAdjustment(adjustment)">Delete</button>
                    </td>
                </tr>
            </ng-container>
        </tbody>
        <tfoot>
            <tr>
                <td [attr.colspan]="4">
                    <app-pagination [pagination]="adjustments" [(page)]="page" (page_change)="changePage($event)"></app-pagination>
                </td>
            </tr>
        </tfoot>
    </table>
</div>

<ng-template #no_adjustments>
    <tr>
        <td [attr.colspan]="4" *ngIf="adjustments">
            No invoice adjustments found
        </td>
        <td [attr.colspan]="4" *ngIf="!adjustments">
            <app-loadingbar></app-loadingbar>
        </td>
    </tr>
</ng-template>