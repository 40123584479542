import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-global-card-history',
  templateUrl: './global-card-history.component.html',
  styleUrls: ['./global-card-history.component.css']
})
export class GlobalCardHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
