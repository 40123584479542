<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow extrapadding">
            
            <div class="container"></div>
            	<h2>Help Guides</h2>
            	    <div class="card-columns" style="column-count:2;">
				        <a class="card text-center" href="https://ipvn.s-ip.co.uk/zoipersetup.pdf" target="_blank">
				            <div class="card-body darkcard cardrounded" style="height:9em;">
				                <h5 class="card-title">VoIP App Setup - Zoiper</h5>
				                <i class="fas fa-mobile-alt helpicons"></i>
				            </div>
				        </a>
				        <a class="card text-center" href="https://ipvn.s-ip.co.uk/grandstreamwave.pdf" target="_blank">
				            <div class="card-body darkcard cardrounded" style="height:9em;">
				                <h5 class="card-title">VoIP App Setup - Grandstream Wave</h5>
				                <i class="fas fa-mobile-alt helpicons"></i>
				            </div>
				        </a>
				        <a class="card text-center" href="https://youtu.be/klkojnja1bU" target="_blank">
				            <div class="card-body darkcard cardrounded" style="height:9em;">
				                <h5 class="card-title">Changing your forwarding destination</h5>
				                <i class="fas fa-share helpicons"></i>
				            </div>
    					</a>
				        <a class="card text-center" href="https://ipvn.s-ip.co.uk/services.pdf" target="_blank">
				            <div class="card-body darkcard cardrounded" style="height:9em;">
				                <h5 class="card-title">Adding and removing services</h5>
				                <i class="fas fa-share helpicons"></i>
				            </div>
				        </a>
				        <a class="card text-center" href="https://ipvn.s-ip.co.uk/groups.pdf" target="_blank">
				            <div class="card-body darkcard cardrounded" style="height:9em;">
				                <h5 class="card-title">Set up a call forwarding group</h5>
				                <i class="fas fa-share helpicons"></i>
				            </div>
				        </a>
				        <a class="card text-center" href="https://ipvn.s-ip.co.uk/Menus.pdf" target="_blank">
				            <div class="card-body darkcard cardrounded" style="height:9em;">
				                <h5 class="card-title">Auto Attendant Menu</h5>
				                <i class="fas fa-share helpicons"></i>
				            </div>
				        </a>
				        <a class="card text-center" href="https://ipvn.s-ip.co.uk/callrecording.pdf" target="_blank">
				            <div class="card-body darkcard cardrounded" style="height:9em;">
				                <h5 class="card-title">Recording your calls</h5>
				                <i class="fas fa-share helpicons"></i>
				            </div>
				        </a>
				        <a class="card text-center" href="https://ipvn.s-ip.co.uk/musiconhold.pdf" target="_blank">
				            <div class="card-body darkcard cardrounded" style="height:9em;">
				                <h5 class="card-title">Music on Hold</h5>
				                <i class="fas fa-share helpicons"></i>
				            </div>
				        </a>
    				</div>
    	</div>
	</div>
</div>
