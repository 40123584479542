import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { LoginService } from '../../login/login.service';
import { SwitchboardService } from '../../switchboard/switchboard.service';
import { ServicesService } from '../../services/services.service';

import { CountryCodes } from '../../tools/country-codes';
import { SipsHelper } from '../sips/sips.helper';

declare var toastr: any;
declare var alertify: any;
declare var moment: any;

@Component({
    selector: 'app-portal-new-switchboard-object',
    templateUrl: './portal-new-switchboard-object.component.html',
    styleUrls: ['./portal-new-switchboard-object.component.css']
})
export class PortalNewSwitchboardObjectComponent implements OnInit {

    account_id;
    object_type;
    parent;

    services;
    free_services;
    numbers;

    countryCodes = CountryCodes.e164_prefixes;
    objects_with_media = ['announcement', 'menu'];

    object_name;
    default_name;
    object_number;
    object_country = '44';
    object_media = {
        object_data: {},
    };

    @ViewChild('emergency_modal') emergencyModal;
    @ViewChild('media_component') mediaComponent;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: LoginService,
        private switchboardService: SwitchboardService,
        private servicesService: ServicesService
    ) { }

    ngOnInit() {
        this.route.data.subscribe(data => {
            this.object_type = data.object;

            const object_type = this.object_type.replace('sip', 'VoIP User');
            const date = moment().format('ll');

            this.default_name = `${object_type} ${date}`;
        });
        this.parent = this.route.snapshot.queryParamMap.get('parent');
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account_id = account.id;
            this.getNumbers();
            // this.getMedia();
            this.getServices();
        });
    }

    getServices() {
        const object_type = this.object_type.split('_').map(x => x.charAt(0).toUpperCase() + x.slice(1)).join('');
        this.switchboardService.getSwitchboardObjectsForAccount(this.account_id)
        .subscribe(objects => {
            const used_services = objects
                .filter(object => object.object_type === object_type)
                .map(object => object.service_instance_id);
            this.servicesService.getServicesForAccount(this.account_id, object_type)
            .subscribe(services => {
                this.free_services = services.filter(service => !used_services.includes(service.id));
            });
        });

        this.servicesService.getAvailableServices(this.account_id, object_type)
        .subscribe(services => {
            this.services = services;
            if (this.includeMedia()) {
                this.mediaComponent.open();
            }
        });
    }

    includeMedia(): boolean {
        return this.objects_with_media.indexOf(this.object_type) !== -1;
    }

    getNumbers() {
        this.switchboardService.readNumbersOnAccount(this.account_id)
            .subscribe(numbers => this.numbers = numbers);
    }

    createSwitchboardObject(serviceInstance) {
        if (!serviceInstance.id) {
            return false;
        }

        let data = {
            name: this.object_name || this.default_name,
            service_instance_id: serviceInstance.id,
        };

        if (this.object_type === 'sip') {
            data['number_uuid'] = this.object_number;
            data['local_e164_prefix'] = this.object_country;
            data['secret'] = SipsHelper.generatePassword();
        }

        if (this.includeMedia()) {
            data['switchboard_media_file_uuid'] = this.object_media.object_data['switchboard_media_file_uuid'] || null;
        }

        this.switchboardService.createObjectOnAccount(this.account_id, `${this.object_type}s`, data)
        .subscribe(new_object => {
            const object_type = this.object_type.replace('_', '-');

            if (this.parent) {
                this.switchboardService.updateSwitchboardObjectForAccount(this.account_id, {
                    uuid: this.parent,
                    switchboard_destination_uuid: new_object.uuid,
                }).subscribe(() => {
                    this.router.navigateByUrl(`/${object_type}s/${new_object.uuid}?parent=${this.parent}`);
                });
            } else {
                this.router.navigateByUrl(`/${object_type}s/${new_object.uuid}`);
            }
        });
    }

    purchase(service) {
        const object_type = this.object_type.replace('sip', 'VoIP User');

        if (this.object_type === 'sip') {
            if (!this.object_number) {
                toastr.error(`Please select a number for your ${object_type}`);
                return false;
            }

            if (!this.object_country) {
                toastr.error(`Please select a country code  for your ${object_type}`);
                return false;
            }
        }

        alertify.confirm(`Are you sure you want to buy "${service.name}"?`, () => {
            this.servicesService.addServiceToAccount(this.account_id, service.id)
                .subscribe(serviceInstance => this.createSwitchboardObject(serviceInstance));
        });
    }

    purchaseWithServiceInstance(serviceInstance) {
        const object_type = this.object_type.replace('sip', 'VoIP User');

        if (this.object_type === 'sip') {
            if (!this.object_number) {
                toastr.error(`Please select a number for your ${object_type}`);
                return false;
            }

            if (!this.object_country) {
                toastr.error(`Please select a country code  for your ${object_type}`);
                return false;
            }
        }

        const cost = (serviceInstance.service.retail_cost / 100).toFixed(2);
        alertify.confirm(`Are you sure you want to use "${serviceInstance.service.name}"?

        (You will have to pay £${cost} a month after ${serviceInstance.expires_at})`, () => {
            this.createSwitchboardObject(serviceInstance);
        });
    }

    checkNumber() {
        let number, num;
        for (num of this.numbers) {
            if (num.uuid === this.object_number) {
                number = num;
            }
        }

        if (number && !number.object_data.address_record) {
            this.object_number = '';
            this.emergencyModal.switchboard_object = number;
            this.emergencyModal.open();
        }
    }

}
