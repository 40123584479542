import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

import { LoginService } from '../login/login.service';
import { SwitchboardService } from '../switchboard/switchboard.service';

import { SwitchboardObject } from '../switchboard/switchboard_object';

import { CountryCodes } from '../tools/country-codes';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-global-blocks',
    templateUrl: './global-blocks.component.html',
    styleUrls: ['./global-blocks.component.css']
})
export class GlobalBlocksComponent implements OnInit {

    account_id;

    direction = 'inbound';
    page = 1;
    search = '';

    call_restrictions;

    new_number;

    new_number_2;
    country_code;

    block_level = 1;

    edit_number: any;
    block_level_edit = 1;

    public countryCodes = CountryCodes.e164_prefixes;

    phoneForm = new UntypedFormGroup({
        phone: new UntypedFormControl(undefined, [Validators.required])
    });

    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;

    @ViewChild('modify_level') modifyLevel;

    constructor(
        private authService: LoginService,
        private switchboardService: SwitchboardService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit() {
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account_id = account.id;
            this.activatedRoute.paramMap.subscribe(params => {
                this.direction = params.get('direction') || this.direction;
                this.page = +params.get('page') || this.page;
                this.search = this.activatedRoute.snapshot.queryParams.search;
                this.getBlocks();
            });
        });
    }

    searchBlocks() {
        this.router.navigate([], {
            queryParams: {
                search: this.search,
            }
        });
        this.getBlocks();
    }

    getBlocks() {
        if (this.direction === 'email') {
            this.authService.getEmailBans(this.page, this.search)
                .subscribe(call_restrictions => this.call_restrictions = call_restrictions);
        } else {
            this.switchboardService.getGlobalCallRestrictions(this.direction, this.page, this.search)
                .subscribe(call_restrictions => this.call_restrictions = call_restrictions);
        }

    }

    block() {
        if (this.direction === 'email') {
            this.authService.createEmailBan(this.new_number)
            .subscribe(response => {
                if (response.id) {
                    toastr.success(`Email blocked!`);
                    this.new_number = '';
                    this.new_number_2 = '';
                    this.country_code = '';
                    this.getBlocks();
                }
            });
        } else {
            if (this.direction === 'outbound') {
                if (!this.phoneForm.controls.phone && !this.phoneForm.controls.phone.value.e164Number) {
                    toastr.error('Number required');
                    return false;
                }

                const number = this.phoneForm.controls.phone.value.e164Number;
                this.new_number = number.replace('+', '');
            }

            this.switchboardService.createGlobalCallRestriction(this.direction, this.new_number, this.block_level)
            .subscribe(response => {
                if (response.e164_number) {
                    toastr.success(`Number blocked!`);
                    this.new_number = '';
                    this.new_number_2 = '';
                    this.country_code = '';
                    this.block_level = 1;
                    this.getBlocks();
                    this.phoneForm.reset();
                }
            });
        }
    }

    unblock(number) {
        if (this.direction === 'email') {
            alertify.confirm(`Are you sure you want to unblock ${number.email}?`, () => {
                this.authService.deleteEmailBan(number)
                .subscribe(response => {
                    toastr.success(`Email unblocked!`);
                    this.getBlocks();
                });
            });
        } else {
            alertify.confirm(`Are you sure you want to unblock ${number.e164_number}?`, () => {
                this.switchboardService.deleteGlobalCallRestriction(this.direction, number.e164_number)
                .subscribe(response => {
                    toastr.success(`Number unblocked!`);
                    this.getBlocks();
                });
            });
        }
    }

    modify(number) {
        this.block_level_edit = number.block_level;
        this.edit_number = number;
        this.modifyLevel.open();
    }

    updateLevel() {
        this.switchboardService.updateGlobalCallRestriction(this.direction, this.edit_number.e164_number, this.block_level_edit)
        .subscribe(() => {
            toastr.success('Call restriction level updated');
            this.getBlocks();
            this.modifyLevel.close();
        })
    }

}
