<div class="alert alert-secondary" role="alert">
    Reset your password below:
</div>

<form role="form">
    <div class="form-group">
        <div class="input-group input-group-alternative">
            <div class="input-group-prepend">
                <span class="input-group-text"><i class="ni ni-lock-circle-open"></i></span>
            </div>
            <input class="form-control" placeholder="Password" name="password" type="password" [(ngModel)]="password">
        </div>
    </div>
    <div class="form-group">
        <div class="input-group input-group-alternative">
            <div class="input-group-prepend">
                <span class="input-group-text"><i class="ni ni-lock-circle-open"></i></span>
            </div>
            <input class="form-control" placeholder="Confirm Password" name="password_confirm" type="password" [(ngModel)]="password_confirm">
        </div>
    </div>
    <div class="text-center">
        <button type="button" class="btn btn-primary my-4" (click)="reset()">Reset</button>
    </div>
    <!-- <span style="display:block;text-align:center;">If you are having any issues with the password reset, please click <a (click)="forgot_password.open()" class="password-forgot btn-link">here.</a></span> -->
</form>
