import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Application } from './application.model';

import { LoginService } from '../login/login.service';

@Component({
    selector: 'app-applications',
    templateUrl: './applications.component.html',
    styleUrls: ['./applications.component.css']
})
export class ApplicationsComponent implements OnInit {

    @Input() applications: Application[];
    private sid_start = 1000000;

    newApp = new Application();

    constructor(
        private route: ActivatedRoute,
        private authService: LoginService
    ) { }

    ngOnInit() {
        this.getApps();
    }

    getApps() {
        this.authService.getApps()
            .subscribe(applications => this.applications = applications);
    }

    genShortName() {
        this.newApp['short_name'] = this.newApp['name'].split(' ').map(w => w[0]).join('').toLowerCase();
        if (this.newApp['short_name'].length === 1) {
            this.newApp['short_name'] = this.newApp['name'].substr(0, 3).toLowerCase();
        }
    }

    saveApplication() {
        // TODO
    }
}
