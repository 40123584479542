import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { LoginService } from '../../../login/login.service';
@Component({
  selector: 'app-portal-services',
  templateUrl: './portal-services.component.html',
  styleUrls: ['./portal-services.component.css']
})
export class PortalServicesComponent implements OnInit {

    account_id;

    filter_type;
    filter_status;

    constructor(
        private authService: LoginService,
        private route: ActivatedRoute,
        private router: Router,
    ) { }


    ngOnInit() {
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account_id = account.id;
        });

        this.filter_type = this.route.snapshot.queryParamMap.get('type') || '';
        this.filter_status = this.route.snapshot.queryParamMap.get('status') || '';
    }

}
