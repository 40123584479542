declare var toastr: any;

export const ClipboardHelper = {
    sendTextToClipboard(value) {
        // create temp element
        const copyElement = document.createElement('span');
        copyElement.appendChild(document.createTextNode(value));
        copyElement.id = 'tempCopyToClipboard';
        document.body.append(copyElement);

        // select the text
        const range = document.createRange();
        range.selectNode(copyElement);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);

        // copy & cleanup
        const successful = document.execCommand('copy');
        window.getSelection().removeAllRanges();
        copyElement.remove();
        return successful;
    },
    addToClipboard(value) {
        try {
            const successful = ClipboardHelper.sendTextToClipboard(value);
            if (successful) {
                toastr.info(`Copied '${value}'!`);
            } else {
                toastr.error('Oops, unable to copy');
            }
        } catch (err) {
            toastr.error('Oops, unable to copy');
        }
    }
}