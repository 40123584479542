import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { LoginService } from '../../login/login.service';
import { SwitchboardService } from '../../switchboard/switchboard.service';
import { ServicesService } from '../../services/services.service';
import { MediaService } from '../media.service';

import { AudioHelper } from '../../tools/audio';

declare var toastr: any;
declare var alertify: any;

@Component({
  selector: 'app-call-recordings',
  templateUrl: './call-recordings.component.html',
  styleUrls: ['./call-recordings.component.css']
})
export class CallRecordingsComponent implements OnInit {

    account: any;
    account_id: number;

    page = 1;

    hasCallRecording = false;
    call_recordings;
    objects;
    space;
    service;

    src;
    dest;
    cli;
    direction = 'inbound';
    duration_range_start = null;
    duration_range_end = null;

    callRecordingService;

    @ViewChild('datepicker') datepicker;

    constructor(
        private route: ActivatedRoute,
        private authService: LoginService,
        private switchboardService: SwitchboardService,
        private servicesService: ServicesService,
        private mediaService: MediaService,
        private router: Router
    ) { }

    ngOnInit() {
        this.account_id = +this.route.snapshot.paramMap.get('id');
        this.getAccount();
    }

    listCallRecordings() {
        if (this.datepicker) {
            this.initDatepicker();

            this.src = this.route.snapshot.queryParamMap.get('src') || '';
            this.dest = this.route.snapshot.queryParamMap.get('dest') || '';
            this.cli = this.route.snapshot.queryParamMap.get('cli') || '';
            this.duration_range_start = +this.route.snapshot.queryParamMap.get('duration_range_start') || null;
            this.duration_range_end = +this.route.snapshot.queryParamMap.get('duration_range_end') || null;

            this.route.paramMap.subscribe(params => {
                this.page = +params.get('page') || 1;
                this.direction = params.get('direction') || this.direction;
                this.getCallRecordings();
            });

        } else {
            setTimeout(() => this.listCallRecordings(), 500);
        }
    }

    recordingsURL() {
        return this.account_id ? `/accounts/detail/${this.account.id}/call-recordings/` : '/call-recordings/';
    }

    splitToDate(date: string) {
        const dateList = date.split('-');
        return {
            year: +dateList[0],
            month: +dateList[1],
            day: +dateList[2],
        };
    }

    initDatepicker() {
        const start_date = this.route.snapshot.queryParamMap.get('start_date');
        if (start_date) {
            this.datepicker.fromDate = this.splitToDate(start_date);
        }
        const end_date = this.route.snapshot.queryParamMap.get('end_date');
        if (end_date) {
            this.datepicker.toDate = this.splitToDate(end_date);
        }
    }

    getAccount() {
        let request;
        if (this.account_id) {
            request = this.authService.getAccount(this.account_id);
        } else {
            request = this.authService.getCurrentAccount();
        }
        request.subscribe(account => {
            this.account = account;
            this.switchboardService.getSwitchboardObjectsForAccount(this.account.id)
                .subscribe(objects => this.objects = objects);
            this.servicesService.getServicesForAccount(this.account.id)
            .subscribe(services => {
                const callRecordings = services.filter(x => x.service.switchboard_type === 'CallRecording');
                this.hasCallRecording = !!callRecordings.length;
                this.callRecordingService = callRecordings[0];
                if (this.hasCallRecording) {
                    this.listCallRecordings();
                } else {
                    this.servicesService.getAvailableServices(this.account.id, 'CallRecording')
                    .subscribe(available_services => {
                        this.service = false;
                        available_services.sort(function(a, b) {
                            return parseFloat(a.retail_cost) - parseFloat(b.retail_cost);
                        });
                        if (available_services.length) {
                            this.service = available_services[0];
                        }
                    });
                }
            });

        });
    }

    purchase() {
        const price = (this.service.retail_cost / 100);
        alertify.confirm(`Are you sure you want to purchase Call Recordings for £${price} per month?`, () => {
            this.servicesService.addServiceToAccount(this.account.id, this.service.id)
            .subscribe(done => {
                this.servicesService.refresh();
                this.getAccount();
            });
        });
    }

    getStartDate() {
        if (this.datepicker && this.datepicker.fromDate) {
            return `${this.datepicker.fromDate.year}-${this.datepicker.fromDate.month}-${this.datepicker.fromDate.day} 00:00:00`;
        }
        return null;
    }

    getEndDate() {
        if (this.datepicker && this.datepicker.toDate) {
            return `${this.datepicker.toDate.year}-${this.datepicker.toDate.month}-${this.datepicker.toDate.day} 23:59:59`;
        }
        return null;
    }

    clearSearch() {
        this.direction = 'all';
        this.src = '';
        this.dest = '';
        this.cli = '';
        this.duration_range_start = null;
        this.duration_range_end = null;
        this.datepicker.fromDate = null;
        this.datepicker.toDate = null;
        this.page = 1;
    }

    searchRecordings() {
        let queryParams = {
            src: this.src,
            dest: this.dest,
            cli: this.cli,
            duration_range_start: this.duration_range_start,
            duration_range_end: this.duration_range_end,
        };

        if (this.getStartDate()) {
            queryParams['start_date'] = this.getStartDate().split(' ')[0];
        }

        if (this.getEndDate()) {
            queryParams['end_date'] = this.getEndDate().split(' ')[0];
        }

        this.router.navigate([this.recordingsURL(), this.direction], {
            queryParams: queryParams,
        });
        this.getCallRecordings();
    }

    getSearchData() {
        const data = {};

        if (this.direction !== 'all') {
            data['direction'] = this.direction === 'outbound' ? 1 : 0;
        }

        if (this.src) {
            data['src'] = this.src;
        }

        if (this.dest) {
            data['dest'] = this.dest;
        }

        if (this.cli) {
            data['cli'] = this.cli;
        }

        if (this.duration_range_start !== null) {
            data['duration_range_start'] = this.duration_range_start;
        }

        if (this.duration_range_end !== null) {
            data['duration_range_end'] = this.duration_range_end;
        }

        if (this.getStartDate()) {
            data['start_time_range_start'] = this.getStartDate();
        }

        if (this.getEndDate()) {
            data['start_time_range_end'] = this.getEndDate();
        }

        data['page'] = this.page;

        return data;
    }

    getCallRecordings() {
        const data = this.getSearchData();

        this.mediaService.showUsedAvailableSpace(this.account.id)
            .subscribe(space => this.space = space);

        this.mediaService.searchCallRecordings(this.account.id, data)
        .subscribe(recordings => {
            this.call_recordings = recordings;
        });
    }

    getRecording(call_recording) {
        for (let recording of this.call_recordings.data) {
            recording.loaded = false;
        }
        call_recording.loaded = true;
        const elementID = `call-recording-audio-controller-${call_recording.uuid}`;
        this.mediaService.getCallRecordingDownloadLink(this.account.id, call_recording)
        .subscribe(link => {
            this.mediaService.downloadExternalResource(link.url)
                .subscribe(AudioHelper.loadFileToAudioElement(elementID, call_recording));
        });
    }

    download(call_recording) {
        const direction = call_recording.direction ? 'Outbound' : 'Inbound';
        const fileName = `call-recording-${direction}-${call_recording.src}-to-${call_recording.dest}-(${call_recording.start_time}).wav`;
        this.mediaService.getCallRecordingDownloadLink(this.account.id, call_recording)
        .subscribe(link => {
            this.mediaService.downloadExternalResource(link.url)
                .subscribe(AudioHelper.downloadAudioFile(fileName));
        });
    }

    delete(call_recording) {
        alertify.confirm(`Are you sure you want to delete this call recording?`, () => {
            this.mediaService.deleteCallRecording(this.account.id, call_recording.uuid)
            .subscribe(() => {
                toastr.success(`Call Recording deleted`);
                this.getCallRecordings();
            });
        });
    }

    toggleObjectCallRecording(object) {
        this.switchboardService.toggleSwitchboardObjectValue(
            this.account.id,
            object,
            'callrecording_enabled',
            {field_name: 'Call Recording'}
        );
    }

    selectAll() {
        for (let recording of this.call_recordings.data) {
            recording['checked'] = true;
        }
    }

    selectNone() {
        for (let recording of this.call_recordings.data) {
            recording['checked'] = false;
        }
    }

    bulkList() {
        if (!this.call_recordings) {
            return [];
        }
        return this.call_recordings.data.filter(recording => recording['checked']);
    }

    bulkDownload() {
        const list = this.bulkList();
        const uuid_list = list.map(recording => recording.uuid);

        this.mediaService.getCallRecordingDownloadLinks(this.account.id, uuid_list)
        .subscribe(links => {
            links.urls.forEach((url, i) => {
                const call_recording = this.call_recordings.data[i];
                const direction = call_recording.direction ? 'Outbound' : 'Inbound';
                const fileName = `call-recording-${direction}-${call_recording.src}-to-${call_recording.dest}-(${call_recording.start_time}).wav`;
                this.mediaService.downloadExternalResource(url)
                    .subscribe(AudioHelper.downloadAudioFile(fileName));
            });
        });
    }

    bulkDelete() {
        const list = this.bulkList();
        const uuid_list = list.map(recording => recording.uuid);

        alertify.confirm(`Are you sure you want to delete these call recordings?`, () => {
            this.mediaService.deleteCallRecordings(this.account.id, uuid_list)
            .subscribe(() => {
                toastr.success(`Call Recordings deleted`);
                this.getCallRecordings();
            });
        });
    }


}
