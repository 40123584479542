import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { LoginService } from '../../login/login.service';
import { BillingService } from '../../billing.service';
import { SwitchboardService } from '../../switchboard/switchboard.service';
import { ServicesService } from '../../services/services.service';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-portal-bundles',
    templateUrl: './portal-bundles.component.html',
    styleUrls: ['./portal-bundles.component.css']
})
export class PortalBundlesComponent implements OnInit {

    account_id;
    switchboard_object;
    direction = 'inbound';

    balance;
    switchboard_objects;
    services;
    account_services;
    sub_services;
    bundle_ids;

    available_count = 0;
    purchased_count = 0;

    @ViewChild('rate_checker') rateChecker;

    constructor(
        private route: ActivatedRoute,
        private authService: LoginService,
        private billingService: BillingService,
        private switchboardService: SwitchboardService,
        private servicesService: ServicesService,
    ) { }

    ngOnInit() {
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account_id = account.id;
            this.getSwitchboardObjects();
            this.getBalance();
        });
    }

    getBalance() {
        this.billingService.getBalance()
            .subscribe(balance => this.balance = balance);
    }

    getSwitchboardObject() {
        if (this.switchboard_objects) {
            for (let object of this.switchboard_objects) {
                if (this.switchboard_object === object.uuid) {
                    return object;
                }
            }
        }
        return null;
    }

    getSwitchboardObjectBundle(object) {
        if (this.account_services) {
            for (let service of this.account_services) {
                if (service.id === object.service_instance_id && service.sub_service_instances.length) {
                    for (let instance of service.sub_service_instances) {
                        if (instance.is_bundle) {
                            return instance;
                        }
                    }
                }
            }
        }
        return false;
    }

    getAccountService() {
        const object = this.getSwitchboardObject();
        if (this.account_services && object) {
            for (let service of this.account_services) {
                if (service.id === object.service_instance_id) {
                    return service;
                }
            }
        }
        return null;
    }

    getSwitchboardObjects() {
        this.switchboardService.getSwitchboardObjectsForAccount(this.account_id)
        .subscribe(objects => {
            this.switchboard_objects = objects;
            this.listenToRoute();
        });
    }

    listenToRoute() {
        this.route.data.subscribe(data => this.direction = data.direction || this.direction);
        this.route.paramMap.subscribe(params => {
            this.switchboard_object = params.get('object_uuid');
            this.getServices();
        });
    }

    getServices() {
        this.servicesService.getServicesForAccount(this.account_id)
        .subscribe(services => {
            this.account_services = services;
            this.getSubservices();
        });
    }

    countSubservices() {
        this.purchased_count = 0;
        this.available_count = 0;
        for (let subservice of this.sub_services) {
            if (this.isPurchased(subservice)) {
                this.purchased_count += 1;
            } else {
                this.available_count += 1;
            }
        }
    }

    getSubservices() {
        const accountService = this.getAccountService();
        if (accountService) {
            this.servicesService.getSubServiceForServiceWithBundles(accountService.service_id)
            .subscribe(subservices => {
                this.sub_services = subservices;
                this.countSubservices();
                this.bundle_ids = subservices.map(x => x.id);
            });
        }
    }

    purchase(sub_service) {
        const service = this.getAccountService();
        const currentBundle = this.findCurrentBundle();
        const switchboardObject = this.getSwitchboardObject()

        if (sub_service.retail_cost > this.balance) {
            toastr.error(`Not enough credit`);
            return false;
        }

        let confirmText = `Are you sure you want to purchase this bundle?`;

        if (currentBundle) {
            confirmText = `Are you sure you want to replace the current bundle with this one?`;
            if (currentBundle.sub_service_id === sub_service.id) {
                confirmText = `Are you sure you want to renew this bundle?`;
            }
        }

        if (switchboardObject.object_type === 'Number' && switchboardObject.object_data.e164_number.match(/^4480/)) {
            confirmText += `<br><br>
                When recieving calls from an 0800 number, you will be charged 7.5p per minute ingress charge.
                <br><br>
                This is in addition to any forwarding bundle you purchase. You need to ensure your account is kept in healthy credit to cover the ingress call costs.
                <br><br>
                Would you like to continue with this purchase?`;
        }

        alertify.confirm(confirmText, () => {
            const addSubService = () => {
                this.servicesService.addSubServiceToAccount(this.account_id, service.id, sub_service.id)
                .subscribe(subservice => {
                    if (subservice.id) {
                        toastr.success('Bundle added!');
                        this.getServices();
                    }
                    this.getBalance();
                });
            };

            if (currentBundle) {
                this.servicesService.deleteSubServiceOnAccount(this.account_id, currentBundle.service_instance_id, currentBundle.id)
                    .subscribe(() => addSubService());
            } else {
                addSubService();
            }
        });
    }

    removeBundle(sub_service) {
        let current_bundle;
        const instances = this.getAccountService().sub_service_instances;
        for (let instance of instances) {
            if (instance.sub_service_id === sub_service.id) {
                current_bundle = instance;
            }
        }

        if (current_bundle) {
            alertify.confirm(`Are you sure you want to remove this bundle?`, () => {
                this.servicesService.deleteSubServiceOnAccount(this.account_id, current_bundle.service_instance_id, current_bundle.id)
                    .subscribe(() => this.getServices());
            });
        } else {
            toastr.error(`Couldn't find the bundle`);
        }
    }

    renewBundle(sub_service) {
        this.purchase(sub_service);
    }

    isPurchased(sub_service) {
        const instances = this.getAccountService().sub_service_instances;
        for (let instance of instances) {
            if (instance.sub_service_id === sub_service.id) {
                return true;
            }
        }
        return false;
    }

    public findCurrentBundle() {
        const instances = this.getAccountService().sub_service_instances;
        for (let sub_service of this.sub_services) {
            for (let instance of instances) {
                if (instance.sub_service_id === sub_service.id) {
                    return instance;
                }
            }
        }
        return null;
    }

    bundleApplicable(sub_service) {
        const bundles = this.rateChecker.rates.applicable_bundles.map(x => x.id);
        const serviceBundles = sub_service.bundles.map(x => x.id);
        for (let bundle of bundles) {
            if (serviceBundles.indexOf(bundle)) {
                return true;
            }
        }
        return false;
    }

    canReccomend() {
        let count = 0;
        for (let service of this.sub_services) {
            if (!this.isPurchased(service) && this.bundleApplicable(service)) {
                count += 1;
            }
        }
        return count;
    }

    canNotReccomend() {
        let count = 0;
        for (let service of this.sub_services) {
            if (!this.isPurchased(service) && !this.bundleApplicable(service)) {
                count += 1;
            }
        }
        return count;
    }

    minutesUsed(bundle) {
        // return service.sub_service_instances.map((a, b) => a + b.reduce((a, b) => a + b.minutes_used, 0), 0)
        return bundle.sheet_instances.reduce((a, b) => a + b.minutes_used, 0);
    }
}
