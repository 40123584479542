import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { LoginService } from '../../login/login.service';
import { SwitchboardService } from '../../switchboard/switchboard.service';

import { Rule } from '../../switchboard/rules/rule.model';

declare var toastr: any;
declare var alertify: any;
declare var $: any;

@Component({
    selector: 'app-portal-time-management',
    templateUrl: './portal-time-management.component.html',
    styleUrls: ['./portal-time-management.component.css']
})
export class PortalTimeManagementComponent implements OnInit {

    days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

    account_id;
    object;
    app;

    switchboard_objects;
    objects;
    numbers;
    rules;
    redirect;

    rule = new Rule();

    state = null;
    saving = false;

    @ViewChild('rule_modal') ruleModal;
    @ViewChild('rule_wizard') ruleWizard;
    @ViewChild('destination_modal') destinationModal;
    @ViewChild('destination_modal_default') destinationDefaultModal;

    constructor(
        private route: ActivatedRoute,
        private authService: LoginService,
        private switchboardService: SwitchboardService
    ) { }

    ngOnInit() {
        // this.setupCheckboxes();
        this.authService.getCurrentApp()
            .subscribe(app => this.app = app);
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account_id = account.id;
            this.getSwitchboardObjects();
            this.route.paramMap.subscribe(params => {
                this.object = params.get('number');
                this.getRules();
            });
        });
    }

    getSwitchboardObjects() {
        this.switchboardService.getSwitchboardObjectsForAccount(this.account_id)
        .subscribe(objects => {
            this.switchboard_objects = objects;
            this.getObjects();
        });
    }

    getObjects() {
        let types = ['Number'];
        if (['vv'].includes(this.app.short_name)) {
            types = ['Number', 'Announcement', 'Menu', 'Group'];
        }
        this.objects = this.switchboard_objects.filter(object => types.includes(object.object_type));
    }

    getObject() {
        if (this.objects) {
            return this.getSwitchboardObject(this.object);
        }
        return null;
    }

    getRules() {
        this.rules = null;
        if (this.object) {
            this.switchboardService.getSwitchboardObjectRules(this.account_id, this.object)
            .subscribe(rules => {
                this.rules = <Rule[]>rules;
            });
        }
    }

    getSwitchboardObject(uuid) {
        let object, destination;

        if (this.switchboard_objects) {
            for (object of this.switchboard_objects) {
                if (uuid === object.uuid) {
                    destination = object;
                }
            }
        }

        return destination;
    }

    getDestination(rule) {
        const uuid = rule.switchboard_destination_uuid;
        const destination = this.getSwitchboardObject(uuid);

        if (destination) {
            return `${destination.extension_number} - ${destination.object_data.name}`;
        }

        return '';
    }

    setupCheckboxes() {
        let day;
        this.rule.days = {};
        if (this.rule.days_json) {
            for (day of this.days) {
                this.rule.days[day] = this.rule.days_json.includes(day);
            }
        }
    }

    getRequestData() {
        let day;
        let days = [];
        for (day in this.rule.days) {
            if (this.rule.days[day]) {
                days.push(day);
            }
        }

        this.rule.days_json = JSON.stringify(days);
        return this.rule;
    }

    newRule() {
        this.rule = new Rule();
        this.setupCheckboxes();
        this.ruleModal.open();
    }

    editRule(rule) {
        this.rule = rule;
        this.setupCheckboxes();
        this.ruleModal.open();
    }

    deleteRule(rule) {
        alertify.confirm(`Are you sure you want to delete ${rule.name}`, () => {
            this.switchboardService.deleteSwitchboardObjectRule(this.account_id, this.object, rule)
                .subscribe(_ => this.getRules());
        });
    }

    usePreset(preset) {
        this.rule = preset as Rule;
        this.setupCheckboxes();
    }

    getRulesForState(state) {
        return this.rules.filter(rule => rule.name === state);
    }

    getStates() {
        const states = [];
        const stateNames = [];
        if (this.rules) {
            for (let rule of this.rules) {
                if (stateNames.indexOf(rule.name) === -1) {
                    stateNames.push(rule.name);
                    states.push({
                        name: rule.name,
                        redirect: rule.switchboard_destination_uuid,
                    });
                }
            }
        }
        return states;
    }

    getStateRedirect(state) {
        const number = this.getObject();
        const state_rules = this.getRulesForState(state);
        if (state_rules.length) {
            return state_rules[0].switchboard_destination_uuid;
        }
        return number.object_data.switchboard_destination_uuid;
    }

    createRule(name, time_start, time_end, day, redirect = null) {
        if (!redirect) {
            redirect = this.getStateRedirect(name);
        }

        const rule = new Rule();
        rule.name = name;
        rule.months_json = '[]';
        rule.time_range_start = time_start;
        rule.time_range_end = time_end;
        rule.days_json = JSON.stringify([day]);
        rule.switchboard_destination_uuid = redirect;

        return this.switchboardService.createSwitchboardObjectRule(this.account_id, this.object, rule);
    }

    deleteAllRules(state = null) {
        for (let rule of this.rules) {
            if (!state || state === rule.name) {
                this.switchboardService.deleteSwitchboardObjectRule(this.account_id, this.object, rule).subscribe();
            }
        }
    }

    changeState(state) {
        this.state = state;
        this.destinationModal.open();
    }

    saveState() {
        const rules = this.getRulesForState(this.state.name);

        const saveRule = () => {
            if (rules.length === 0) {
                this.saving = false;
                this.state = null;

                toastr.success('Destination changed!');
                this.destinationModal.close();
                this.getRules();
                return false;
            }

            const rule = rules.shift();
            rule.switchboard_destination_uuid = this.state.redirect;
            this.switchboardService.updateSwitchboardObjectRule(this.account_id, this.object, rule)
                .subscribe(response => saveRule());
        };

        this.saving = true;
        saveRule();
    }

    changeDefault() {
        this.redirect = this.getObject().object_data.switchboard_destination_uuid;
        this.destinationDefaultModal.open();
    }

    saveDefaultRedirect() {
        this.switchboardService.updateSwitchboardObjectForAccount(this.account_id, {
            uuid: this.object,
            switchboard_destination_uuid: this.redirect,
        }).subscribe(() => {
            toastr.success('Destination changed!');
            this.getSwitchboardObjects();
            this.destinationDefaultModal.close();
        });
    }

    saveRule() {
        let request;
        const rule = this.getRequestData();

        rule.switchboard_destination_uuid = this.getStateRedirect(rule.name);
        rule.months_json = '[]';

        if (this.rule.uuid) {
            request = this.switchboardService.updateSwitchboardObjectRule(this.account_id, this.object, rule);
        } else {
            request = this.switchboardService.createSwitchboardObjectRule(this.account_id, this.object, rule);
        }

        request.subscribe(response => {
            if (response + '') {
                this.getRules();
                toastr.success('Rule Saved!');
                this.ruleModal.close();
            } else {
                this.ruleModal.stopLoading();
            }
        });
    }

}
