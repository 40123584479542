export const CountryCodes = {
    countries: {
        'BD': 'Bangladesh',
        'BE': 'Belgium',
        'BF': 'Burkina Faso',
        'BG': 'Bulgaria',
        'BA': 'Bosnia and Herzegovina',
        'BB': 'Barbados',
        'WF': 'Wallis and Futuna',
        'BL': 'Saint Barthelemy',
        'BM': 'Bermuda',
        'BN': 'Brunei',
        'BO': 'Bolivia',
        'BH': 'Bahrain',
        'BI': 'Burundi',
        'BJ': 'Benin',
        'BT': 'Bhutan',
        'JM': 'Jamaica',
        'BV': 'Bouvet Island',
        'BW': 'Botswana',
        'WS': 'Samoa',
        'BQ': 'Bonaire, Saint Eustatius and Saba ',
        'BR': 'Brazil',
        'BS': 'Bahamas',
        'JE': 'Jersey',
        'BY': 'Belarus',
        'BZ': 'Belize',
        'RU': 'Russia',
        'RW': 'Rwanda',
        'RS': 'Serbia',
        'TL': 'East Timor',
        'RE': 'Reunion',
        'TM': 'Turkmenistan',
        'TJ': 'Tajikistan',
        'RO': 'Romania',
        'TK': 'Tokelau',
        'GW': 'Guinea-Bissau',
        'GU': 'Guam',
        'GT': 'Guatemala',
        'GS': 'South Georgia and the South Sandwich Islands',
        'GR': 'Greece',
        'GQ': 'Equatorial Guinea',
        'GP': 'Guadeloupe',
        'JP': 'Japan',
        'GY': 'Guyana',
        'GG': 'Guernsey',
        'GF': 'French Guiana',
        'GE': 'Georgia',
        'GD': 'Grenada',
        'GB': 'United Kingdom (UK)',
        'GA': 'Gabon',
        'SV': 'El Salvador',
        'GN': 'Guinea',
        'GM': 'Gambia',
        'GL': 'Greenland',
        'GI': 'Gibraltar',
        'GH': 'Ghana',
        'OM': 'Oman',
        'TN': 'Tunisia',
        'JO': 'Jordan',
        'HR': 'Croatia',
        'HT': 'Haiti',
        'HU': 'Hungary',
        'HK': 'Hong Kong',
        'HN': 'Honduras',
        'HM': 'Heard Island and McDonald Islands',
        'VE': 'Venezuela',
        'PR': 'Puerto Rico',
        'PS': 'Palestinian Territory',
        'PW': 'Palau',
        'PT': 'Portugal',
        'SJ': 'Svalbard and Jan Mayen',
        'PY': 'Paraguay',
        'IQ': 'Iraq',
        'PA': 'Panama',
        'PF': 'French Polynesia',
        'PG': 'Papua New Guinea',
        'PE': 'Peru',
        'PK': 'Pakistan',
        'PH': 'Philippines',
        'PN': 'Pitcairn',
        'PL': 'Poland',
        'PM': 'Saint Pierre and Miquelon',
        'ZM': 'Zambia',
        'EH': 'Western Sahara',
        'EE': 'Estonia',
        'EG': 'Egypt',
        'ZA': 'South Africa',
        'EC': 'Ecuador',
        'IT': 'Italy',
        'VN': 'Vietnam',
        'SB': 'Solomon Islands',
        'ET': 'Ethiopia',
        'SO': 'Somalia',
        'ZW': 'Zimbabwe',
        'SA': 'Saudi Arabia',
        'ES': 'Spain',
        'ER': 'Eritrea',
        'ME': 'Montenegro',
        'MD': 'Moldova',
        'MG': 'Madagascar',
        'MF': 'Saint Martin',
        'MA': 'Morocco',
        'MC': 'Monaco',
        'UZ': 'Uzbekistan',
        'MM': 'Myanmar',
        'ML': 'Mali',
        'MO': 'Macao',
        'MN': 'Mongolia',
        'MH': 'Marshall Islands',
        'MK': 'Macedonia',
        'MU': 'Mauritius',
        'MT': 'Malta',
        'MW': 'Malawi',
        'MV': 'Maldives',
        'MQ': 'Martinique',
        'MP': 'Northern Mariana Islands',
        'MS': 'Montserrat',
        'MR': 'Mauritania',
        'IM': 'Isle of Man',
        'UG': 'Uganda',
        'TZ': 'Tanzania',
        'MY': 'Malaysia',
        'MX': 'Mexico',
        'IL': 'Israel',
        'FR': 'France',
        'IO': 'British Indian Ocean Territory',
        'SH': 'Saint Helena',
        'FI': 'Finland',
        'FJ': 'Fiji',
        'FK': 'Falkland Islands',
        'FM': 'Micronesia',
        'FO': 'Faroe Islands',
        'NI': 'Nicaragua',
        'NL': 'Netherlands',
        'NO': 'Norway',
        'NA': 'Namibia',
        'VU': 'Vanuatu',
        'NC': 'New Caledonia',
        'NE': 'Niger',
        'NF': 'Norfolk Island',
        'NG': 'Nigeria',
        'NZ': 'New Zealand',
        'NP': 'Nepal',
        'NR': 'Nauru',
        'NU': 'Niue',
        'CK': 'Cook Islands',
        'XK': 'Kosovo',
        'CI': 'Ivory Coast',
        'CH': 'Switzerland',
        'CO': 'Colombia',
        'CN': 'China',
        'CM': 'Cameroon',
        'CL': 'Chile',
        'CC': 'Cocos Islands',
        'CA': 'Canada',
        'CG': 'Republic of the Congo',
        'CF': 'Central African Republic',
        'CD': 'Democratic Republic of the Congo',
        'CZ': 'Czech Republic',
        'CY': 'Cyprus',
        'CX': 'Christmas Island',
        'CR': 'Costa Rica',
        'CW': 'Curacao',
        'CV': 'Cape Verde',
        'CU': 'Cuba',
        'SZ': 'Swaziland',
        'SY': 'Syria',
        'SX': 'Sint Maarten',
        'KG': 'Kyrgyzstan',
        'KE': 'Kenya',
        'SS': 'South Sudan',
        'SR': 'Suriname',
        'KI': 'Kiribati',
        'KH': 'Cambodia',
        'KN': 'Saint Kitts and Nevis',
        'KM': 'Comoros',
        'ST': 'Sao Tome and Principe',
        'SK': 'Slovakia',
        'KR': 'South Korea',
        'SI': 'Slovenia',
        'KP': 'North Korea',
        'KW': 'Kuwait',
        'SN': 'Senegal',
        'SM': 'San Marino',
        'SL': 'Sierra Leone',
        'SC': 'Seychelles',
        'KZ': 'Kazakhstan',
        'KY': 'Cayman Islands',
        'SG': 'Singapore',
        'SE': 'Sweden',
        'SD': 'Sudan',
        'DO': 'Dominican Republic',
        'DM': 'Dominica',
        'DJ': 'Djibouti',
        'DK': 'Denmark',
        'VG': 'British Virgin Islands',
        'DE': 'Germany',
        'YE': 'Yemen',
        'DZ': 'Algeria',
        'US': 'United States',
        'UY': 'Uruguay',
        'YT': 'Mayotte',
        'UM': 'United States Minor Outlying Islands',
        'LB': 'Lebanon',
        'LC': 'Saint Lucia',
        'LA': 'Laos',
        'TV': 'Tuvalu',
        'TW': 'Taiwan',
        'TT': 'Trinidad and Tobago',
        'TR': 'Turkey',
        'LK': 'Sri Lanka',
        'LI': 'Liechtenstein',
        'LV': 'Latvia',
        'TO': 'Tonga',
        'LT': 'Lithuania',
        'LU': 'Luxembourg',
        'LR': 'Liberia',
        'LS': 'Lesotho',
        'TH': 'Thailand',
        'TF': 'French Southern Territories',
        'TG': 'Togo',
        'TD': 'Chad',
        'TC': 'Turks and Caicos Islands',
        'LY': 'Libya',
        'VA': 'Vatican',
        'VC': 'Saint Vincent and the Grenadines',
        'AE': 'United Arab Emirates',
        'AD': 'Andorra',
        'AG': 'Antigua and Barbuda',
        'AF': 'Afghanistan',
        'AI': 'Anguilla',
        'VI': 'U.S. Virgin Islands',
        'IS': 'Iceland',
        'IR': 'Iran',
        'AM': 'Armenia',
        'AL': 'Albania',
        'AO': 'Angola',
        'AQ': 'Antarctica',
        'AS': 'American Samoa',
        'AR': 'Argentina',
        'AU': 'Australia',
        'AT': 'Austria',
        'AW': 'Aruba',
        'IN': 'India',
        'AX': 'Aland Islands',
        'AZ': 'Azerbaijan',
        'IE': 'Ireland',
        'ID': 'Indonesia',
        'UA': 'Ukraine',
        'QA': 'Qatar',
        'MZ': 'Mozambique'
    },
    languages: {
        'alpha2': 'English',
        'aa': 'Afar',
        'ab': 'Abkhazian',
        'ae': 'Avestan',
        'af': 'Afrikaans',
        'ak': 'Akan',
        'am': 'Amharic',
        'an': 'Aragonese',
        'ar': 'Arabic',
        'as': 'Assamese',
        'av': 'Avaric',
        'ay': 'Aymara',
        'az': 'Azerbaijani',
        'ba': 'Bashkir',
        'be': 'Belarusian',
        'bg': 'Bulgarian',
        'bh': 'Bihari languages',
        'bi': 'Bislama',
        'bm': 'Bambara',
        'bn': 'Bengali',
        'bo': 'Tibetan',
        'br': 'Breton',
        'bs': 'Bosnian',
        'ca': 'Catalan; Valencian',
        'ce': 'Chechen',
        'ch': 'Chamorro',
        'co': 'Corsican',
        'cr': 'Cree',
        'cs': 'Czech',
        'cu': 'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic',
        'cv': 'Chuvash',
        'cy': 'Welsh',
        'da': 'Danish',
        'de': 'German',
        'dv': 'Divehi; Dhivehi; Maldivian',
        'dz': 'Dzongkha',
        'ee': 'Ewe',
        'el': 'Greek, Modern (1453-)',
        'en': 'English',
        'eo': 'Esperanto',
        'es': 'Spanish; Castilian',
        'et': 'Estonian',
        'eu': 'Basque',
        'fa': 'Persian',
        'ff': 'Fulah',
        'fi': 'Finnish',
        'fj': 'Fijian',
        'fo': 'Faroese',
        'fr': 'French',
        'fy': 'Western Frisian',
        'ga': 'Irish',
        'gd': 'Gaelic; Scottish Gaelic',
        'gl': 'Galician',
        'gn': 'Guarani',
        'gu': 'Gujarati',
        'gv': 'Manx',
        'ha': 'Hausa',
        'he': 'Hebrew',
        'hi': 'Hindi',
        'ho': 'Hiri Motu',
        'hr': 'Croatian',
        'ht': 'Haitian; Haitian Creole',
        'hu': 'Hungarian',
        'hy': 'Armenian',
        'hz': 'Herero',
        'ia': 'Interlingua (International Auxiliary Language Association)',
        'id': 'Indonesian',
        'ie': 'Interlingue; Occidental',
        'ig': 'Igbo',
        'ii': 'Sichuan Yi; Nuosu',
        'ik': 'Inupiaq',
        'io': 'Ido',
        'is': 'Icelandic',
        'it': 'Italian',
        'iu': 'Inuktitut',
        'ja': 'Japanese',
        'jv': 'Javanese',
        'ka': 'Georgian',
        'kg': 'Kongo',
        'ki': 'Kikuyu; Gikuyu',
        'kj': 'Kuanyama; Kwanyama',
        'kk': 'Kazakh',
        'kl': 'Greenlandic',
        'km': 'Central Khmer',
        'kn': 'Kannada',
        'ko': 'Korean',
        'kr': 'Kanuri',
        'ks': 'Kashmiri',
        'ku': 'Kurdish',
        'kv': 'Komi',
        'kw': 'Cornish',
        'ky': 'Kirghiz',
        'la': 'Latin',
        'lb': 'Luxembourgish',
        'lg': 'Ganda',
        'li': 'Limburgan',
        'ln': 'Lingala',
        'lo': 'Lao',
        'lt': 'Lithuanian',
        'lu': 'Luba-Katanga',
        'lv': 'Latvian',
        'mg': 'Malagasy',
        'mh': 'Marshallese',
        'mi': 'Maori',
        'mk': 'Macedonian',
        'ml': 'Malayalam',
        'mn': 'Mongolian',
        'mr': 'Marathi',
        'ms': 'Malay',
        'mt': 'Maltese',
        'my': 'Burmese',
        'na': 'Nauru',
        'nb': 'Bokmål, Norwegian',
        'nd': 'Ndebele, North; North Ndebele',
        'ne': 'Nepali',
        'ng': 'Ndonga',
        'nl': 'Dutch; Flemish',
        'nn': 'Norwegian Nynorsk',
        'no': 'Norwegian',
        'nr': 'Ndebele, South; South Ndebele',
        'nv': 'Navajo; Navaho',
        'ny': 'Chichewa; Chewa; Nyanja',
        'oc': 'Occitan (post 1500); ProvenÃ§al',
        'oj': 'Ojibwa',
        'om': 'Oromo',
        'or': 'Oriya',
        'os': 'Ossetian; Ossetic',
        'pa': 'Panjabi; Punjabi',
        'pi': 'Pali',
        'pl': 'Polish',
        'ps': 'Pushto; Pashto',
        'pt': 'Portuguese',
        'qu': 'Quechua',
        'rm': 'Romansh',
        'rn': 'Rundi',
        'ro': 'Romanian; Moldavian; Moldovan',
        'ru': 'Russian',
        'rw': 'Kinyarwanda',
        'sa': 'Sanskrit',
        'sc': 'Sardinian',
        'sd': 'Sindhi',
        'se': 'Northern Sami',
        'sg': 'Sango',
        'si': 'Sinhala; Sinhalese',
        'sk': 'Slovak',
        'sl': 'Slovenian',
        'sm': 'Samoan',
        'sn': 'Shona',
        'so': 'Somali',
        'sq': 'Albanian',
        'sr': 'Serbian',
        'ss': 'Swati',
        'st': 'Sotho, Southern',
        'su': 'Sundanese',
        'sv': 'Swedish',
        'sw': 'Swahili',
        'ta': 'Tamil',
        'te': 'Telugu',
        'tg': 'Tajik',
        'th': 'Thai',
        'ti': 'Tigrinya',
        'tk': 'Turkmen',
        'tl': 'Tagalog',
        'tn': 'Tswana',
        'to': 'Tonga (Tonga Islands)',
        'tr': 'Turkish',
        'ts': 'Tsonga',
        'tt': 'Tatar',
        'tw': 'Twi',
        'ty': 'Tahitian',
        'ug': 'Uighur; Uyghur',
        'uk': 'Ukrainian',
        'ur': 'Urdu',
        'uz': 'Uzbek',
        've': 'Venda',
        'vi': 'Vietnamese',
        'vo': 'VolapÃ¼k',
        'wa': 'Walloon',
        'wo': 'Wolof',
        'xh': 'Xhosa',
        'yi': 'Yiddish',
        'yo': 'Yoruba',
        'za': 'Zhuang; Chuang',
        'zh': 'Chinese',
        'zu': 'Zulu'
    },
    e164_prefixes: [
        {
            'name': 'Afghanistan',
            'number': '93'
        },
        {
            'name': 'Albania',
            'number': '355'
        },
        {
            'name': 'Algeria',
            'number': '213'
        },
        {
            'name': 'Andorra',
            'number': '376'
        },
        {
            'name': 'Angola',
            'number': '244'
        },
        {
            'name': 'Antarctica',
            'number': '672'
        },
        {
            'name': 'Argentina',
            'number': '54'
        },
        {
            'name': 'Armenia',
            'number': '374'
        },
        {
            'name': 'Aruba',
            'number': '297'
        },
        {
            'name': 'Australia',
            'number': '61'
        },
        {
            'name': 'Austria',
            'number': '43'
        },
        {
            'name': 'Azerbaijan',
            'number': '994'
        },
        {
            'name': 'Bahrain',
            'number': '973'
        },
        {
            'name': 'Bangladesh',
            'number': '880'
        },
        {
            'name': 'Belarus',
            'number': '375'
        },
        {
            'name': 'Belgium',
            'number': '32'
        },
        {
            'name': 'Belize',
            'number': '501'
        },
        {
            'name': 'Benin',
            'number': '229'
        },
        {
            'name': 'Bhutan',
            'number': '975'
        },
        {
            'name': 'Bolivia',
            'number': '591'
        },
        {
            'name': 'Bosnia and Herzegovina',
            'number': '387'
        },
        {
            'name': 'Botswana',
            'number': '267'
        },
        {
            'name': 'Brazil',
            'number': '55'
        },
        {
            'name': 'British Indian Ocean Territory',
            'number': '246'
        },
        {
            'name': 'Brunei',
            'number': '673'
        },
        {
            'name': 'Bulgaria',
            'number': '359'
        },
        {
            'name': 'Burkina Faso',
            'number': '226'
        },
        {
            'name': 'Burundi',
            'number': '257'
        },
        {
            'name': 'Cambodia',
            'number': '855'
        },
        {
            'name': 'Cameroon',
            'number': '237'
        },
        {
            'name': 'Canada',
            'number': '1'
        },
        {
            'name': 'Cape Verde',
            'number': '238'
        },
        {
            'name': 'Central African Republic',
            'number': '236'
        },
        {
            'name': 'Chad',
            'number': '235'
        },
        {
            'name': 'Chile',
            'number': '56'
        },
        {
            'name': 'China',
            'number': '86'
        },
        {
            'name': 'Christmas Island',
            'number': '61'
        },
        {
            'name': 'Cocos Islands',
            'number': '61'
        },
        {
            'name': 'Colombia',
            'number': '57'
        },
        {
            'name': 'Comoros',
            'number': '269'
        },
        {
            'name': 'Cook Islands',
            'number': '682'
        },
        {
            'name': 'Costa Rica',
            'number': '506'
        },
        {
            'name': 'Croatia',
            'number': '385'
        },
        {
            'name': 'Cuba',
            'number': '53'
        },
        {
            'name': 'Curacao',
            'number': '599'
        },
        {
            'name': 'Cyprus',
            'number': '357'
        },
        {
            'name': 'Czech Republic',
            'number': '420'
        },
        {
            'name': 'Democratic Republic of the Congo',
            'number': '243'
        },
        {
            'name': 'Denmark',
            'number': '45'
        },
        {
            'name': 'Djibouti',
            'number': '253'
        },
        {
            'name': 'East Timor',
            'number': '670'
        },
        {
            'name': 'Ecuador',
            'number': '593'
        },
        {
            'name': 'Egypt',
            'number': '20'
        },
        {
            'name': 'El Salvador',
            'number': '503'
        },
        {
            'name': 'Equatorial Guinea',
            'number': '240'
        },
        {
            'name': 'Eritrea',
            'number': '291'
        },
        {
            'name': 'Estonia',
            'number': '372'
        },
        {
            'name': 'Ethiopia',
            'number': '251'
        },
        {
            'name': 'Falkland Islands',
            'number': '500'
        },
        {
            'name': 'Faroe Islands',
            'number': '298'
        },
        {
            'name': 'Fiji',
            'number': '679'
        },
        {
            'name': 'Finland',
            'number': '358'
        },
        {
            'name': 'France',
            'number': '33'
        },
        {
            'name': 'French Polynesia',
            'number': '689'
        },
        {
            'name': 'Gabon',
            'number': '241'
        },
        {
            'name': 'Gambia',
            'number': '220'
        },
        {
            'name': 'Georgia',
            'number': '995'
        },
        {
            'name': 'Germany',
            'number': '49'
        },
        {
            'name': 'Ghana',
            'number': '233'
        },
        {
            'name': 'Gibraltar',
            'number': '350'
        },
        {
            'name': 'Greece',
            'number': '30'
        },
        {
            'name': 'Greenland',
            'number': '299'
        },
        {
            'name': 'Guatemala',
            'number': '502'
        },
        {
            'name': 'Guinea',
            'number': '224'
        },
        {
            'name': 'Guinea-Bissau',
            'number': '245'
        },
        {
            'name': 'Guyana',
            'number': '592'
        },
        {
            'name': 'Haiti',
            'number': '509'
        },
        {
            'name': 'Honduras',
            'number': '504'
        },
        {
            'name': 'Hong Kong',
            'number': '852'
        },
        {
            'name': 'Hungary',
            'number': '36'
        },
        {
            'name': 'Iceland',
            'number': '354'
        },
        {
            'name': 'India',
            'number': '91'
        },
        {
            'name': 'Indonesia',
            'number': '62'
        },
        {
            'name': 'Iran',
            'number': '98'
        },
        {
            'name': 'Iraq',
            'number': '964'
        },
        {
            'name': 'Ireland',
            'number': '353'
        },
        {
            'name': 'Israel',
            'number': '972'
        },
        {
            'name': 'Italy',
            'number': '39'
        },
        {
            'name': 'Ivory Coast',
            'number': '225'
        },
        {
            'name': 'Japan',
            'number': '81'
        },
        {
            'name': 'Jordan',
            'number': '962'
        },
        {
            'name': 'Kazakhstan',
            'number': '7'
        },
        {
            'name': 'Kenya',
            'number': '254'
        },
        {
            'name': 'Kiribati',
            'number': '686'
        },
        {
            'name': 'Kosovo',
            'number': '383'
        },
        {
            'name': 'Kuwait',
            'number': '965'
        },
        {
            'name': 'Kyrgyzstan',
            'number': '996'
        },
        {
            'name': 'Laos',
            'number': '856'
        },
        {
            'name': 'Latvia',
            'number': '371'
        },
        {
            'name': 'Lebanon',
            'number': '961'
        },
        {
            'name': 'Lesotho',
            'number': '266'
        },
        {
            'name': 'Liberia',
            'number': '231'
        },
        {
            'name': 'Libya',
            'number': '218'
        },
        {
            'name': 'Liechtenstein',
            'number': '423'
        },
        {
            'name': 'Lithuania',
            'number': '370'
        },
        {
            'name': 'Luxembourg',
            'number': '352'
        },
        {
            'name': 'Macau',
            'number': '853'
        },
        {
            'name': 'Macedonia',
            'number': '389'
        },
        {
            'name': 'Madagascar',
            'number': '261'
        },
        {
            'name': 'Malawi',
            'number': '265'
        },
        {
            'name': 'Malaysia',
            'number': '60'
        },
        {
            'name': 'Maldives',
            'number': '960'
        },
        {
            'name': 'Mali',
            'number': '223'
        },
        {
            'name': 'Malta',
            'number': '356'
        },
        {
            'name': 'Marshall Islands',
            'number': '692'
        },
        {
            'name': 'Mauritania',
            'number': '222'
        },
        {
            'name': 'Mauritius',
            'number': '230'
        },
        {
            'name': 'Mayotte',
            'number': '262'
        },
        {
            'name': 'Mexico',
            'number': '52'
        },
        {
            'name': 'Micronesia',
            'number': '691'
        },
        {
            'name': 'Moldova',
            'number': '373'
        },
        {
            'name': 'Monaco',
            'number': '377'
        },
        {
            'name': 'Mongolia',
            'number': '976'
        },
        {
            'name': 'Montenegro',
            'number': '382'
        },
        {
            'name': 'Morocco',
            'number': '212'
        },
        {
            'name': 'Mozambique',
            'number': '258'
        },
        {
            'name': 'Myanmar',
            'number': '95'
        },
        {
            'name': 'Namibia',
            'number': '264'
        },
        {
            'name': 'Nauru',
            'number': '674'
        },
        {
            'name': 'Nepal',
            'number': '977'
        },
        {
            'name': 'Netherlands',
            'number': '31'
        },
        {
            'name': 'Netherlands Antilles',
            'number': '599'
        },
        {
            'name': 'New Caledonia',
            'number': '687'
        },
        {
            'name': 'New Zealand',
            'number': '64'
        },
        {
            'name': 'Nicaragua',
            'number': '505'
        },
        {
            'name': 'Niger',
            'number': '227'
        },
        {
            'name': 'Nigeria',
            'number': '234'
        },
        {
            'name': 'Niue',
            'number': '683'
        },
        {
            'name': 'North Korea',
            'number': '850'
        },
        {
            'name': 'Norway',
            'number': '47'
        },
        {
            'name': 'Oman',
            'number': '968'
        },
        {
            'name': 'Pakistan',
            'number': '92'
        },
        {
            'name': 'Palau',
            'number': '680'
        },
        {
            'name': 'Palestine',
            'number': '970'
        },
        {
            'name': 'Panama',
            'number': '507'
        },
        {
            'name': 'Papua New Guinea',
            'number': '675'
        },
        {
            'name': 'Paraguay',
            'number': '595'
        },
        {
            'name': 'Peru',
            'number': '51'
        },
        {
            'name': 'Philippines',
            'number': '63'
        },
        {
            'name': 'Pitcairn',
            'number': '64'
        },
        {
            'name': 'Poland',
            'number': '48'
        },
        {
            'name': 'Portugal',
            'number': '351'
        },
        {
            'name': 'Qatar',
            'number': '974'
        },
        {
            'name': 'Republic of the Congo',
            'number': '242'
        },
        {
            'name': 'Reunion',
            'number': '262'
        },
        {
            'name': 'Romania',
            'number': '40'
        },
        {
            'name': 'Russia',
            'number': '7'
        },
        {
            'name': 'Rwanda',
            'number': '250'
        },
        {
            'name': 'Saint Barthelemy',
            'number': '590'
        },
        {
            'name': 'Saint Helena',
            'number': '290'
        },
        {
            'name': 'Saint Martin',
            'number': '590'
        },
        {
            'name': 'Saint Pierre and Miquelon',
            'number': '508'
        },
        {
            'name': 'Samoa',
            'number': '685'
        },
        {
            'name': 'San Marino',
            'number': '378'
        },
        {
            'name': 'Sao Tome and Principe',
            'number': '239'
        },
        {
            'name': 'Saudi Arabia',
            'number': '966'
        },
        {
            'name': 'Senegal',
            'number': '221'
        },
        {
            'name': 'Serbia',
            'number': '381'
        },
        {
            'name': 'Seychelles',
            'number': '248'
        },
        {
            'name': 'Sierra Leone',
            'number': '232'
        },
        {
            'name': 'Singapore',
            'number': '65'
        },
        {
            'name': 'Slovakia',
            'number': '421'
        },
        {
            'name': 'Slovenia',
            'number': '386'
        },
        {
            'name': 'Solomon Islands',
            'number': '677'
        },
        {
            'name': 'Somalia',
            'number': '252'
        },
        {
            'name': 'South Africa',
            'number': '27'
        },
        {
            'name': 'South Korea',
            'number': '82'
        },
        {
            'name': 'South Sudan',
            'number': '211'
        },
        {
            'name': 'Spain',
            'number': '34'
        },
        {
            'name': 'Sri Lanka',
            'number': '94'
        },
        {
            'name': 'Sudan',
            'number': '249'
        },
        {
            'name': 'Suriname',
            'number': '597'
        },
        {
            'name': 'Svalbard and Jan Mayen',
            'number': '47'
        },
        {
            'name': 'Swaziland',
            'number': '268'
        },
        {
            'name': 'Sweden',
            'number': '46'
        },
        {
            'name': 'Switzerland',
            'number': '41'
        },
        {
            'name': 'Syria',
            'number': '963'
        },
        {
            'name': 'Taiwan',
            'number': '886'
        },
        {
            'name': 'Tajikistan',
            'number': '992'
        },
        {
            'name': 'Tanzania',
            'number': '255'
        },
        {
            'name': 'Thailand',
            'number': '66'
        },
        {
            'name': 'Togo',
            'number': '228'
        },
        {
            'name': 'Tokelau',
            'number': '690'
        },
        {
            'name': 'Tonga',
            'number': '676'
        },
        {
            'name': 'Tunisia',
            'number': '216'
        },
        {
            'name': 'Turkey',
            'number': '90'
        },
        {
            'name': 'Turkmenistan',
            'number': '993'
        },
        {
            'name': 'Tuvalu',
            'number': '688'
        },
        {
            'name': 'Uganda',
            'number': '256'
        },
        {
            'name': 'Ukraine',
            'number': '380'
        },
        {
            'name': 'United Arab Emirates',
            'number': '971'
        },
        {
            'name': 'United Kingdom (UK)',
            'number': '44'
        },
        {
            'name': 'United States',
            'number': '1'
        },
        {
            'name': 'Uruguay',
            'number': '598'
        },
        {
            'name': 'Uzbekistan',
            'number': '998'
        },
        {
            'name': 'Vanuatu',
            'number': '678'
        },
        {
            'name': 'Vatican',
            'number': '379'
        },
        {
            'name': 'Venezuela',
            'number': '58'
        },
        {
            'name': 'Vietnam',
            'number': '84'
        },
        {
            'name': 'Wallis and Futuna',
            'number': '681'
        },
        {
            'name': 'Western Sahara',
            'number': '212'
        },
        {
            'name': 'Yemen',
            'number': '967'
        },
        {
            'name': 'Zambia',
            'number': '260'
        },
        {
            'name': 'Zimbabwe',
            'number': '263'
        }
    ],
    canadian_area_codes: [
        '204', '226', '236', '249', '250', '289',
        '306', '343', '365', '403', '416', '418',
        '431', '437', '438', '450', '506', '514',
        '519', '579', '581', '587', '604', '613',
        '639', '647', '705', '709', '778', '780',
        '807', '819', '867', '873', '902', '905'
    ],
};
