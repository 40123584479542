import { NgModule } from '@angular/core';

import { CapitalizePipe } from './capitalize.pipe';
import { FormatBalancePipe } from './format_balance.pipe';
import { OrdinalPipe } from './ordinal.pipe';
import { IsNotNullPipe } from './is-not-null.pipe';
import { FromNowPipe } from './from-now.pipe';
import { FormatLocalDatePipe } from './format-local-date.pipe';
import { AliasPipe } from './alias.pipe';
import { NumberToCountryCodePipe } from './number-to-country-code.pipe';
import { NumberToCountryPrefixPipe } from './number-to-country-prefix.pipe';
import { NumberToLocalPipe } from './number-to-local.pipe';
import { HasExpiredPipe } from './has-expired.pipe';
import { MinutesUsedPipe } from './minutes-used.pipe';
import { SwitchboardObjectIconPipe } from './switchboard-object-icon.pipe';
import { AccountStatePipe } from './account-state.pipe';
import { FormatMinutesPipe } from './format-minutes.pipe';

@NgModule({
    imports: [],
    declarations: [
        CapitalizePipe,
        FormatBalancePipe,
        OrdinalPipe,
        IsNotNullPipe,
        FromNowPipe,
        FormatLocalDatePipe,
        AliasPipe,
        NumberToCountryCodePipe,
        NumberToCountryPrefixPipe,
        NumberToLocalPipe,
        HasExpiredPipe,
        MinutesUsedPipe,
        SwitchboardObjectIconPipe,
        AccountStatePipe,
        FormatMinutesPipe,
    ],
    bootstrap: [],
    exports: [
        CapitalizePipe,
        FormatBalancePipe,
        OrdinalPipe,
        IsNotNullPipe,
        FromNowPipe,
        FormatLocalDatePipe,
        AliasPipe,
        NumberToCountryCodePipe,
        NumberToCountryPrefixPipe,
        NumberToLocalPipe,
        HasExpiredPipe,
        MinutesUsedPipe,
        SwitchboardObjectIconPipe,
        AccountStatePipe,
        FormatMinutesPipe,
    ]
})

export class PipesModule { }
