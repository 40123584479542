import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { BillingService } from '../billing.service';

declare var toastr: any;
declare var alertify: any;
declare var moment: any;
declare var Chart: any;

function getDateRange(from = null, to = null) {
    let dateArray = [];
    let currentDate = moment().subtract(30, 'days');
    let stopDate = moment();

    if (from && to) {
        currentDate = moment(`${from.year}-${from.month}-${from.day}`);
        stopDate = moment(`${to.year}-${to.month}-${to.day}`);
    }

    while (currentDate <= stopDate) {
        dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
        currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
}

function splitToDate(date: string) {
    const dateList = date.split('-');
    return {
        year: +dateList[0],
        month: +dateList[1],
        day: +dateList[2],
    };
}

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.css']
})
export class PaymentHistoryComponent implements OnInit {

    history;
    history_chart;

    @ViewChild('datepicker') datepicker;

    constructor(
        private billingService: BillingService,
    ) { }

    ngOnInit() {
        this.datepicker.fromDate = splitToDate(moment().subtract(30, 'days').format('YYYY-MM-DD'));
        this.datepicker.toDate = splitToDate(moment().format('YYYY-MM-DD'));
        console.log(this.datepicker.toDate, this.datepicker.fromDate);
        this.getPaymentHistory();
    }

    getPaymentHistory() {
        if (!this.datepicker) {
            setTimeout(() => this.getPaymentHistory(), 500);
            return false;
        }

        if (this.history_chart) {
            this.history_chart.destroy();
        }

        this.billingService.getBraintreeTransactionAmount(this.datepicker.fromDate, this.datepicker.toDate)
        .subscribe(history => {
            this.history = history;
            const range = getDateRange(this.datepicker.fromDate, this.datepicker.toDate);
            const exists = this.history.map(x => x.day);
            for (let date of range) {
                if (exists.indexOf(date) === -1) {
                    this.history.push({'day': date, 'total_amount': 0});
                }
            }
            this.history.sort((a, b) => {
                if (a.day < b.day) {
                    return -1;
                }

                if (a.day > b.day) {
                    return 1;
                }

                return 0;
            });
            this.makeChart();
        });
    }

    makeChart() {
        const canvasElement = <HTMLCanvasElement>document.getElementById('chart-stats');
        const ctx = canvasElement.getContext('2d');

        this.history_chart = new Chart(ctx, {
            type: 'bar',
            data: {
                datasets: [
                    {
                        label: `Pounds`,
                        data: this.history.map(x => (x.total_amount / 100).toFixed(2)),
                    }
                ],
                labels: this.history.map(x => x.day),
            },
            options: {
                responsive: true,
            }
        });
    }

}
