import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { Auth } from './auth';
import { LoginService } from './login.service';
import { ServicesService } from '../services/services.service';

import { AuthenticationHelper } from './authentication.helper';
import { LocationHelper } from '../location.helper';

declare var toastr: any;
declare var window: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

    apps: any;
    private token: string;

    is_branded: boolean;

    @Input() auth: Auth;
    @Input() app_uuid: string;

    auth_attempt: any = {};

    @ViewChild('forgot_password') forgotPassword;
    @ViewChild('twofactor_auth') twofactorModal;

    constructor(
        private loginService: LoginService,
        private servicesService: ServicesService,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private router: Router
    ) { }

    ngOnInit() {
        this.auth = new Auth();
        this.loginService.getApps().subscribe(apps => {
            this.apps = apps;
            this.is_branded = this.getCurrentApp();
        });
    }

    getCurrentApp() {
        const app = LocationHelper.getAppFromURL(this.apps);
        this.app_uuid = app?.uuid;
        return !!app;
    }

    redirect() {
        const path = this.activatedRoute.snapshot.queryParamMap.get('path') || '/';
        this.loginService.refresh();
        this.servicesService.refresh();
        this.router.navigate([path]);
    }

    login() {
        this.loginService.login(this.auth.email, this.auth.password, this.app_uuid)
        .subscribe(login => {
            this.servicesService.refresh();
            this.auth.password = '';
            this.auth.token = login.token;

            if (login.token) {
                if (login.authenticated) {
                    AuthenticationHelper.setAuthKey(login.token);
                    this.redirect();
                } else {
                    this.auth_attempt = login;
                    this.twofactorModal.open();
                }
            }
        });
    }

    sendTwoFactor() {
        this.loginService.authenticate2FAToken(this.auth_attempt.token, this.auth_attempt.auth_code)
        .subscribe(login => {
            if (login === 'FAIL_AUTH') {
                toastr.error('Incorrect verification code');
                this.twofactorModal.stopLoading();
            } else {
                AuthenticationHelper.setProxyKey(this.auth_attempt.token);
                this.redirect();
            }
        });
    }

    requestReset() {
        this.loginService.requestReset(this.app_uuid, this.auth.email)
        .subscribe(response => {
            toastr.success('Password Request Sent');
            this.forgotPassword.close();
        });
    }

}
