import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Core Modules
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Installed Modules
import { GravatarModule } from 'ngx-gravatar';

// My Modules
import { PipesModule } from '../pipes/pipes.module';
import { DirectivesModule } from '../directives/directives.module';
import { ComponentsModule } from '../components/components.module';

// Components
import { DashboardComponent } from './dashboard/dashboard.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BasicLayoutComponent } from './basic-layout/basic-layout.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { CallStatsCardComponent } from './cards/call-stats-card/call-stats-card.component';
import { VoicemailCardComponent } from './cards/voicemail-card/voicemail-card.component';
import { BalanceCardComponent } from './cards/balance-card/balance-card.component';
import { ServiceNotificationsComponent } from './notifications/service-notifications/service-notifications.component';
import { AddressNotificationsComponent } from './notifications/address-notifications/address-notifications.component';
import { StatusNotificationsComponent } from './notifications/status-notifications/status-notifications.component';
import { AddressBadgeComponent } from './notification-badges/address-badge/address-badge.component';
import { KycBadgeComponent } from './notification-badges/kyc-badge/kyc-badge.component';
import { VoipStatusCardComponent } from './cards/voip-status-card/voip-status-card.component';
import { VerifyMediaBadgeComponent } from './notification-badges/verify-media-badge/verify-media-badge.component';

@NgModule({
    imports: [
        RouterModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        GravatarModule,
        PipesModule,
        DirectivesModule,
        ComponentsModule,
    ],
    declarations: [
        DashboardComponent,
        NavbarComponent,
        SidebarComponent,
        BasicLayoutComponent,
        GetStartedComponent,
        CallStatsCardComponent,
        VoicemailCardComponent,
        BalanceCardComponent,
        ServiceNotificationsComponent,
        AddressNotificationsComponent,
        StatusNotificationsComponent,
        AddressBadgeComponent,
        KycBadgeComponent,
        VoipStatusCardComponent,
        VerifyMediaBadgeComponent,
    ],
    bootstrap: [],
    exports: [
        DashboardComponent,
        BasicLayoutComponent,
        VoipStatusCardComponent,
    ]
})

export class LayoutModule { }
