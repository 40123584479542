<div class="table-responsive">
    <table class="table">
        <thead>
            <tr>
                <th>VoIP User</th>
                <th>Contract</th>
                <th>Device</th>
                <th>Started At</th>
                <th>Expires At</th>
                <th>&nbsp;</th>
            </tr>
        </thead>
        <tbody *ngIf="contracts?.length else no_contracts">
            <tr *ngFor="let contract of contracts">
                <td>
                    {{ contract.switchboard_object.extension_number }} - {{ contract.switchboard_object.object_data.name }}
                </td>
                <td>
                    {{ contract.sip_contract_type.name }}
                </td>
                <td>
                    <ng-container *ngIf="contract.sip_device_id">
                        {{ contract.sip_device.device_manufacturer }} {{ contract.sip_device.device_model }} ({{ contract.sip_device.mac_code }})
                    </ng-container>
                </td>
                <td>
                    {{ contract.started_at|date:'mediumDate' }}
                </td>
                <td>
                    {{ contract.expires_at|date:'mediumDate' }}
                </td>
                <td>
                    <div class="btn btn-secondary" (click)="historicalContractsComponent.open(account_id, contract.switchboard_object)">History</div>
                    <div class="btn btn-secondary" (click)="editContract(contract)">Edit</div>
                    <div class="btn btn-danger" (click)="deleteContract(contract)">Delete</div>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td colspan="6">
                    <button class="btn btn-primary" (click)="newContract()">New Contract</button>
                </td>
            </tr>
        </tfoot>
    </table>
</div>

<ng-template #no_contracts>
    <tr>
        <td colspan="6" *ngIf="contracts">
            No contracts found
        </td>
        <td colspan="6" *ngIf="!contracts">
            <app-loadingbar></app-loadingbar>
        </td>
    </tr>
</ng-template>

<app-modal title="{{ current_contract.edit ? 'Edit' : 'New' }} Contract" (save)="saveContract()" #contractDetail>
    <div class="field">
        <label class="label">VoIP User</label>
        <div class="control">
            <select class="form-control" [(ngModel)]="current_contract.switchboard_object_uuid" *ngIf="!current_contract.edit">
                <!-- <option value="">Select VoIP User</option> -->
                <option *ngFor="let sip of getSips()" [value]="sip.uuid">{{ sip.extension_number }} - {{ sip.object_data.name }}</option>
            </select>
            <span *ngIf="current_contract.edit">{{ current_contract.switchboard_object.extension_number }} - {{ current_contract.switchboard_object.object_data.name }}</span>
        </div>
    </div>

    <div class="field">
        <label class="label">Contract Type</label>
        <div class="control">
            <select class="form-control" [(ngModel)]="current_contract.sip_contract_type_uuid">
                <!-- <option value="">Select Contract</option> -->
                <option *ngFor="let type of contract_types" [value]="type.uuid">{{ type.name }}</option>
            </select>
        </div>
    </div>

    <div class="field">
        <label class="label">Handset</label>
        <div class="control">
            <select class="form-control" [(ngModel)]="current_contract.sip_device_id">
                <!-- <option value="">Select Handset</option> -->
                <option [ngValue]="null">[None]</option>
                <option *ngFor="let handset of handsets" [value]="handset.id">{{ handset.device_manufacturer }} {{ handset.device_model }} ({{ handset.mac_code }})</option>
            </select>
        </div>
    </div>

    <div class="field" id="dp">
        <label class="label">Start Date</label>
        <div class="control">
            <input
                class="form-control"
                (click)="datepicker.toggle()"
                type="text"
                ngbDatepicker
                [(ngModel)]="datepickerdate"
                #datepicker="ngbDatepicker"
                (dateSelect)="onDateSelect($event)"
            />
        </div>
    </div>
</app-modal>

<app-historical-contracts #historicalContractsComponent></app-historical-contracts>
