<nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white" id="sidenav-main">
    <app-sidebar style="width:100%"></app-sidebar>
</nav>


<div class="main-content">
    <app-navbar [isHome]="isHome" #navbar_component></app-navbar>

    <!-- Page content -->
    <div class="container-fluid">
        <ng-content></ng-content>
        <!-- Footer -->
        <footer class="footer">
            <div class="row align-items-center justify-content-xl-between">
                <div class="col-xl-6">
                    <div class="copyright text-center text-xl-left text-muted">
                        &copy; {{ datenow.getFullYear() }} <a [href]="mainsite" class="font-weight-bold ml-1" target="_blank">{{ app?.name || 'Viper' }}</a>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>

<a routerLink="/help" *appViperIsNotPostpay><div class="needhelpfixed"><i class="fas fa-question-circle" style="padding-top:3px; transform: rotate(90deg);"></i>Need help?</div></a>

<app-get-started></app-get-started>

<app-live-chat></app-live-chat>
