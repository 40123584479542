<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item" *ngIf="account_id"><a routerLink="/accounts/detail/{{ account_id }}">Account</a></li>
                <li class="breadcrumb-item active" aria-current="page">Call Recordings</li>
              </ol>
            </nav>
            <ng-container *ngIf="hasCallRecording else purchase_page">
                <ng-container *ngTemplateOutlet="list_recordings"></ng-container>
            </ng-container>
        </div>
    </div>
</div>

<app-modal title="Call Recording Overview" size="big" #overview_modal>
    <div class="row">
        <div class="col">
            <ul>
                <ng-container *ngFor="let object of objects">
                    <ng-container *ngIf="object.object_type === 'Number'">
                        <li>
                            <button
                                class="btn"
                                [class.btn-success]="object.object_data.callrecording_enabled === 1"
                                [class.btn-danger]="object.object_data.callrecording_enabled === 0"
                                (click)="toggleObjectCallRecording(object)">
                                {{ object.object_data.callrecording_enabled ? 'On' : 'Off' }}
                            </button>
                            <span class="flag-icon flag-icon-{{ (object.object_data.e164_number|number_to_country_code).toLowerCase() }}" [title]="object.object_data.e164_number|number_to_country_prefix"></span>
                            {{ object.object_data.e164_number|number_to_local }}
                        </li>
                    </ng-container>
                </ng-container>
            </ul>
        </div>
        <div class="col">
            <ul>
                <ng-container *ngFor="let object of objects">
                    <ng-container *ngIf="object.object_type === 'Sip'">
                        <li>
                            <button
                                class="btn"
                                [class.btn-success]="object.object_data.callrecording_enabled === 1"
                                [class.btn-danger]="object.object_data.callrecording_enabled === 0"
                                (click)="toggleObjectCallRecording(object)">
                                {{ object.object_data.callrecording_enabled ? 'On' : 'Off' }}
                            </button>
                            {{ object.extension_number }}
                            <span class="text-sm text-muted">({{ object.object_data.name }})</span>
                        </li>
                    </ng-container>
                </ng-container>
            </ul>
        </div>
    </div>
</app-modal>

<ng-template #purchase_page>
    <div class="card-header border-0">
        <div class="row align-items-center">
            <div class="col">
                <h2 class="mb-0">Call Recordings</h2>
            </div>
        </div>
    </div>
    <div class="card-body">
        <button class="btn btn-primary btn-block text-lg" *ngIf="service" (click)="purchase()">Purchase Call Recording</button>
        <button class="btn btn-danger btn-block text-lg disabled" *ngIf="service === false" disabled>Coming Soon</button>
    </div>
</ng-template>

<ng-template #list_recordings>
    <div class="card-header border-0">
        <div class="row align-items-center">
            <div class="col">
                <h2 class="mb-0">{{ direction|capitalize }} Call Recordings</h2>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="alert alert-secondary">
            Your call recording package is currently <strong>{{ callRecordingService.service.name }}</strong>. If you want to upgrade or downgrade your call recording service, you can do so at the <a routerLink="/shop">Shop</a>
        </div>

        <br>

        <button class="btn-overview btn btn-primary btn-block btn-lg" (click)="overview_modal.open()"><i class="fa-solid fa-gear"></i> Enable/Disable Call Recording</button>

        <h3>Search Call Recordings</h3>

        <div class="row">
            <div class="col-md-3 col-12">
                <label for="">Date Range</label>
                <app-ngbd-datepicker-range #datepicker></app-ngbd-datepicker-range>
            </div>
            <div class="col-md-3 col-12">
                <label for="">{{ direction === 'outbound' ? 'Your Extension or Phone Number' : 'Number Called' }}</label>
                <input type="text" aria-label="Source" class="form-control" placeholder="{{ direction === 'outbound' ? 'e.g. 1000 or 0301234567' : 'e.g. 07123456789' }}" [(ngModel)]="src" (keyup.enter)="searchRecordings()">
            </div>
            <div class="col-md-3 col-12">
                <label for="">{{ direction === 'inbound' ? 'Your Extension or Phone Number' : 'Number Called' }}</label>
                <input type="text" aria-label="Destination" class="form-control" placeholder="{{ direction === 'inbound' ? 'e.g. 1000 or 0301234567' : 'e.g. 07123456789' }}" [(ngModel)]="dest" (keyup.enter)="searchRecordings()">
            </div>
            <div class="col-md-3 col-12" style="margin-bottom:2%;">
                <label for="">CLI</label>
                <input type="text" aria-label="CLI" class="form-control" placeholder="e.g. 07123456789" [(ngModel)]="cli" (keyup.enter)="searchRecordings()">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <button class="btn btn-primary" (click)="searchRecordings()">Search</button>
                &nbsp;
                <button class="btn btn-danger" (click)="clearSearch();searchRecordings()">Clear</button>
            </div>
        </div>

        <hr>

        <p class="text-lg text-center">
            <!-- <a class="btn" [class.btn-primary]="direction === 'all'" [class.btn-secondary]="direction !== 'all'" routerLink="{{ recordingsURL() }}/all">All Recordings</a> -->
            <a class="btn" [class.btn-primary]="direction === 'inbound'" [class.btn-secondary]="direction !== 'inbound'" routerLink="{{ recordingsURL() }}/inbound">Inbound</a>
            <a class="btn" [class.btn-primary]="direction === 'outbound'" [class.btn-secondary]="direction !== 'outbound'" routerLink="{{ recordingsURL() }}/outbound">Outbound</a>
        </p>
    </div>
    <div class="table-responsive table-striped no-more-table">
        <table class="table align-items-center table-flush">
            <tbody >
                <ng-container *ngIf="call_recordings && call_recordings.path else no_recordings">
                    <tr *ngFor="let call_recording of call_recordings.data">
                        <td scope="row">
                            <input type="checkbox" [(ngModel)]="call_recording.checked">
                            &nbsp;

                            <button [disabled]="call_recording.loaded" class="btn btn-success" (click)="getRecording(call_recording)" title="Play" target="_blank">
                                <i class="fas fa-play"></i>
                            </button>
                            <button class="btn btn-primary" (click)="download(call_recording)" title="Download">
                                <i class="fas fa-download"></i>
                            </button>
                            <button class="btn btn-danger" *appViperHasUserRole="'Account Owner','Admin'" (click)="delete(call_recording)">Delete</button>
                            <ng-container *ngIf="call_recording.loaded">
                                <br><br>
                                <audio id="call-recording-audio-controller-{{ call_recording.uuid }}" controls controlsList="nodownload"></audio>
                            </ng-container>
                        </td>
                        <td>
                            A {{ call_recording.duration }} second {{ call_recording.direction ? 'Outbound' : 'Inbound' }} call
                        </td>
                        <td>
                            Made on {{ (call_recording.start_time)|date:'medium' }}
                        </td>
                        <td>
                            From
                            <ng-container *ngIf="call_recording.src.length > 6">
                                <span class="flag-icon flag-icon-{{ (call_recording.src|number_to_country_code).toLowerCase() }}" [title]="call_recording.src|number_to_country_prefix"></span>


                                {{ call_recording.src|number_to_local }}
                            </ng-container>
                            <ng-container *ngIf="call_recording.src.length <= 6">
                                {{ call_recording.src }}
                            </ng-container>
                            to
                            <ng-container *ngIf="call_recording.dest.length > 6">
                                <span class="flag-icon flag-icon-{{ (call_recording.dest|number_to_country_code).toLowerCase() }}" [title]="call_recording.dest|number_to_country_prefix"></span>


                                {{ call_recording.dest|number_to_local }}
                            </ng-container>
                            <ng-container *ngIf="call_recording.dest.length <= 6">
                                {{ call_recording.dest }}
                            </ng-container>
                            with cli
                            <ng-container *ngIf="call_recording.cli.length > 6">
                                <span class="flag-icon flag-icon-{{ (call_recording.cli|number_to_country_code).toLowerCase() }}" [title]="call_recording.cli|number_to_country_prefix"></span>


                                {{ call_recording.cli|number_to_local }}
                            </ng-container>
                            <ng-container *ngIf="call_recording.cli.length <= 6">
                                {{ call_recording.cli }}
                            </ng-container>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="4">
                        <div class="btn btn-secondary btn-lg btn-block" (click)="selectAll()">Select All</div>
                        <div class="btn btn-secondary btn-lg btn-block" (click)="selectNone()">Select None</div>
                        <ng-container *ngIf="bulkList().length">
                            <br>
                            <div class="btn btn-primary" (click)="bulkDownload()">
                                <i class="fas fa-download"></i>
                                Download Selected
                            </div>

                            <div class="btn btn-danger" (click)="bulkDelete()">
                                Delete Selected
                            </div>
                        </ng-container>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
    <div class="card-footer">
        <app-pagination [pagination]="call_recordings" url="{{ recordingsURL() }}/{{ direction }}"></app-pagination>
    </div>
</ng-template>

<ng-template #no_recordings>
    <tr class="tr">
        <td class="td" colspan="4">
            <span *ngIf="call_recordings else loading">No Recordings Found</span>
        </td>
    </tr>
</ng-template>

<ng-template #loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
