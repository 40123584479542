<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/settings">Account Settings</a></li>
                <li class="breadcrumb-item active" aria-current="page">Services</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Services</h2>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="account_id">
                
                <app-account-services [account_id]="account_id" [filter_type]="filter_type" [filter_status]="filter_status" #services_tab></app-account-services>

            </ng-container>

        </div>
    </div>
</div>
