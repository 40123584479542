import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { ServicesService } from '../../services/services.service';
import { RatesService } from '../../rates/rates.service';

import { PriceHelper } from '../../tools/price.helper';

import { SubService } from '../subservice';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-subservice',
    templateUrl: './subservice.component.html',
    styleUrls: ['./subservice.component.css']
})
export class SubServiceComponent implements OnInit {

    @ViewChild('bundleModal') bundleModal;

    subservice: any;

    private subservice_id: number;
    private bundles;
    private availableBundles;
    private attachedBundle = null;

    constructor(
        private route: ActivatedRoute,
        private servicesService: ServicesService,
        private ratesService: RatesService,
        private router: Router
    ) { }

    ngOnInit() {
        this.subservice_id = +this.route.snapshot.paramMap.get('id');
        this.servicesService.getSubService(this.subservice_id)
            .subscribe(subservice => {
                this.subservice = subservice;
                PriceHelper.fieldToPounds(this.subservice, 'retail_cost');
            });
        this.ratesService.getBundles()
            .subscribe(bundles => this.availableBundles = bundles);
        this.getSubServiceBundles();
    }

    getSubServiceBundles() {
        this.bundles = null;
        this.servicesService.getSubServiceBundles(this.subservice_id)
            .subscribe(bundles => this.bundles = bundles);
    }

    goBack(): void {
        this.router.navigate([`/subservices`]);
    }

    saveSubService() {
        const subservice = { ...this.subservice };
        subservice.retail_cost *= 100;
        this.servicesService.updateSubService(subservice)
            .subscribe(out => toastr.success('Sub Service saved!'));
    }

    deleteSubService() {
        alertify.confirm(`Are you sure you want to delete ${this.subservice.name}?`, () => {
            this.servicesService.deleteSubService(this.subservice)
            .subscribe(out => {
                toastr.info('Deleting subservice!');
                this.goBack();
            });
        });
    }

    notAttachedBundle(bundle) {
        return this.bundles && this.bundles.map(b => b.id).indexOf(bundle.id) === -1;
    }

    attachBundle() {
        if (!this.attachedBundle) {
            toastr.error('Bundle required');
            this.bundleModal.close();
            return false;
        }
        this.servicesService.attachSubServiceBundle(this.subservice.id, this.attachedBundle)
        .subscribe(response => {
            if (response.length) {
                toastr.success('Bundle attached!');
                this.getSubServiceBundles();
            }
            this.bundleModal.close();
        });
    }

    detatchBundle(bundle) {
        alertify.confirm('Are you sure you want to detatch this bundle?', () => {
            bundle.deleted = true;
            this.servicesService.detachSubServiceBundle(this.subservice.id, bundle.id)
            .subscribe(response => {
                toastr.success('Bundle detached!');
                this.getSubServiceBundles();
            });
        });
    }

}
