import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { RatesService } from '../rates.service';

import { Bundle } from './bundle';
import { Sheet } from '../sheets/sheet';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-bundles',
    templateUrl: './bundles.component.html',
    styleUrls: ['./bundles.component.css']
})
export class BundlesComponent implements OnInit {

    bundles: Bundle[] = null;
    sheets: Sheet[] = null;
    allSheets: Sheet[] = null;
    bundle: Bundle = new Bundle;

    sheet_id = null;
    adding_sheet = null;

    @ViewChild('bundleModal') detailsModal;
    @ViewChild('attachedSheetsModal') sheetsModal;

    constructor(
        private ratesService: RatesService,
        private router: Router
    ) { }

    ngOnInit() {
        this.ratesService.getSheets()
            .subscribe(sheets => this.allSheets = sheets);
        this.loadBundles();
    }

    loadBundles() {
        this.ratesService.getBundles()
            .subscribe(bundles => this.bundles = bundles);
    }

    createBundle() {
        this.bundle = new Bundle();
        this.detailsModal.title = 'New Bundle';
        this.detailsModal.open();
    }

    editBundle(bundle) {
        this.bundle = bundle;
        this.detailsModal.title = 'Rename Bundle';
        this.detailsModal.open();
    }

    deleteBundle(bundle) {
        alertify.confirm(`Are you sure you want to delete ${bundle.name}?`, () => {
            this.ratesService.deleteBundle(bundle)
            .subscribe(out => {
                toastr.success('Deleting bundle!');
                this.loadBundles();
            });
        });
    }

    saveBundle() {
        let request;
        const bundle_id = this.bundle['id'];

        if (bundle_id) {
            request = this.ratesService.updateBundle(this.bundle);
        } else {
            request = this.ratesService.createBundle(this.bundle);
        }

        request.subscribe(bundle => {
            const save_type = bundle_id ? 'saved' : 'created';
            if (bundle.id) {
                toastr.success(`Bundle ${save_type}!`);
                this.detailsModal.close();
                this.loadBundles();
            } else {
                this.detailsModal.stopLoading();
            }
        });
    }

    loadAttachedSheets(bundle) {
        this.bundle = bundle;
        this.sheets = null;
        this.sheetsModal.open();
    }

    attachSheet() {
        if (this.sheet_id) {
            this.adding_sheet = true;
            this.ratesService.attachSheetToBundle(this.bundle, this.sheet_id)
            .subscribe(sheet => {
                this.loadBundles();
                toastr.success('Attached Sheet!');
                this.adding_sheet = null;
                this.sheetsModal.close();
            });
        } else {
            toastr.error('Sheet not selected!');
            this.sheetsModal.stopLoading();
        }
    }

    detachSheet(sheet) {
        alertify.confirm(`Are you sure you want to detatch ${sheet.name}?`, () => {
            sheet.deleting = true;
            this.ratesService.detachSheetFromBundle(this.bundle, sheet)
            .subscribe(() => {
                this.loadBundles();
                toastr.success('Detached Sheet!');
            });
        });
    }

    sheetNotAttached(sheet) {
        if (sheet === null) {
            return false;
        }
        return this.bundle['sheets'] && !this.bundle['sheets'].map(x => x.id).includes(sheet.id);
    }

}
