import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { LoginService } from '../../../login/login.service';
import { CdrService } from '../../../cdr.service';
import { BillingService } from '../../../billing.service';
import { SwitchboardService } from '../../../switchboard/switchboard.service';

@Component({
    selector: 'app-invoice-cdrs',
    templateUrl: './invoice-cdrs.component.html',
    styleUrls: ['./invoice-cdrs.component.css']
})
export class InvoiceCdrsComponent implements OnInit {

    account_id: number;
    account;
    invoice_uuid: string;
    invoice;

    sips;
    extension_cdrs = null;

    hasEmptyCDRs;

    constructor(
        private route: ActivatedRoute,
        private authService: LoginService,
        private cdrService: CdrService,
        private billingService: BillingService,
        private switchboardService: SwitchboardService,
    ) { }

    ngOnInit(): void {
        this.account_id = +this.route.snapshot.paramMap.get('account');
        this.invoice_uuid = this.route.snapshot.paramMap.get('invoice');
        this.getAccount();
    }

    getAccount() {
        let request;
        if (this.account_id) {
            request = this.authService.getAccount(this.account_id);
        } else {
            request = this.authService.getCurrentAccount();
        }
        request.subscribe(account => {
            this.account = account;
            this.getCDRsByExtension();
            this.getInvoice();
            this.getSips();
        });
    }

    getInvoice() {
        this.billingService.getCoinprintInvoice(this.account.id, this.invoice_uuid)
            .subscribe(invoice => this.invoice = invoice);
    }

    getSips() {
        this.switchboardService.readSipsOnAccount(this.account.id)
        .subscribe(sips => {
            let sipmap = {};
            for (let sip of sips) {
                sipmap[sip.extension_number] = sip;
            }
            this.sips = sipmap;
        });
    }

    getCDRsByExtension() {
        this.extension_cdrs = null;
        this.cdrService.readCDRsByExtension(this.account.id, this.invoice_uuid)
        .subscribe (cdrs => {
            this.extension_cdrs = cdrs;

            this.hasEmptyCDRs = true;
            for (let extension of cdrs.values()) {
                if (!cdrs.cdrs.length) {
                    this.hasEmptyCDRs = false;
                }
            }
        });
    }

}
