<div class="row">
    <div class="col" *ngIf="getSubServices() as sub_services">
        <div *ngIf="sub_services.bundles[0] as bundle">
            <p>Bundle: {{ bundle.sub_service.name }}
                <span class="badge badge-pill badge-info" *ngIf="bundle.has_minutes">{{ bundle|minutes_used }} minutes used</span>
                <span class="badge badge-pill badge-warning" *ngIf="!bundle.has_minutes">Maxed Out</span>
                <span class="badge badge-pill badge-contrast badge-success" *ngIf="bundle|has_expired">Expired</span>
            </p>
        </div>
        <div *ngIf="sub_services.addons.length">
            <p>Add-Ons:</p>
            <ul>
                <li *ngFor="let addon of sub_services.addons">
                    {{ addon.sub_service.name }} <span class="badge badge-pill badge-contrast badge-success" *ngIf="addon|has_expired">Expired</span>
                </li>
            </ul>
        </div>
    </div>
</div>