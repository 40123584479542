import { Directive, ViewContainerRef, TemplateRef, OnInit, Input } from '@angular/core';

import { LoginService } from '../login/login.service';

@Directive({
    selector: '[appViperIsPostpay]'
})
export class IsPostpayDirective implements OnInit {

    constructor(
        private viewContainerRef: ViewContainerRef,
        private template: TemplateRef<any>,
        private authService: LoginService,
    ) { }

    ngOnInit(): void {
        this.authService.getCurrentApp()
        .subscribe(app => {
            this.viewContainerRef.clear();
            if (app.billing_type === 1) {
                this.viewContainerRef.createEmbeddedView(this.template);
            }
        });
    }

}
