import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { LoginService } from '../login/login.service';
import { SwitchboardService } from '../switchboard/switchboard.service';
import { ServicesService } from '../services/services.service';
import { BillingService } from '../billing.service';

import { CountryCodes } from '../tools/country-codes';
import { SessionHelper } from '../tools/session.helper';
import { NumbersHelper } from '../portal/numbers/numbers.helper';
import { SipsHelper } from '../portal/sips/sips.helper';

declare var toastr: any;
declare var alertify: any;
declare var moment: any;

@Component({
    selector: 'app-bulk-switchboard-generate',
    templateUrl: './bulk-switchboard-generate.component.html',
    styleUrls: ['./bulk-switchboard-generate.component.css']
})
export class BulkSwitchboardGenerateComponent implements OnInit {

    private storage_name = 'shopping-cart';

    app;
    account;
    account_id;
    balance;
    services;
    account_services;
    defined_services;
    single_services;
    sub_services;
    numbers;

    account_created_at;
    can_purchase_number;

    object = {};
    objects = [];
    switchboard_type;
    object_media = {
        object_data: {},
    };

    purchasable_numbers;
    memorable_numbers;
    top_numbers;

    countryCodes = CountryCodes.e164_prefixes;
    areas;

    country_code = '44';
    area;
    minScore = 0;
    midScore = 4;
    maxScore = 9999;
    searchTerm = '';

    generating = false;
    generation_message = '';
    generated = [];
    finished = false;

    global_services = ['MusicOnHold', 'Remote Call Monitoring'];
    singleton_services = ['CallRecording', 'Shared Bundle', 'MusicOnHold', 'Remote Call Monitoring'];
    no_name_services = ['Number', 'CallRecording', 'MusicOnHold', 'Additional'];
    objects_with_media = ['Announcement', 'Menu'];

    service_details = {
        'Voicemail': {description: 'Personalised voicemail to email', name: 'Voicemail inbox', url: '/voicemails/new'},
        'Menu': {description: '"Welcome to XZY, press 1 for sales, 2 for accounts etc"', name: 'IVR Auto Attendant Menu', url: '/menus/new'},
        'Announcement': {description: '"Welcome to XYZ, please hold while we put you through"', name: 'Intro Announcement', url: '/announcements/new'},
        'Group': {description: 'Additional call forwarding/hunt groups', name: 'Hunt Groups', url: '/groups/new'},
        'Number': {description: 'Add additional telephone numbers to your account', name: 'Telephone Numbers', url: '/numbers/new'},
        'Channel': {description: 'When you need extra capacity to carry more calls then buy channels', name: 'Channels', url: '/channels'},
        'Sip': {description: 'Add additional VoIP users to create a virtual telephone system', name: 'VoIP User', url: '/sips/new'},
        'CallRecording': {description: '', name: 'Call Recording', url: '/call-recordings'},
        'MusicOnHold': {description: '', name: 'Music On Hold', url: '/music-on-hold'},
        'Additional': {description: '', name: ''},
    };

    @ViewChild('create_object_modal') create_object_modal;
    @ViewChild('addons_modal') addons_modal;
    @ViewChild('media_component') mediaComponent;

    constructor(
        private route: ActivatedRoute,
        private authService: LoginService,
        private switchboardService: SwitchboardService,
        private servicesService: ServicesService,
        private billingService: BillingService
    ) { }

    ngOnInit(): void {
        this.authService.getCurrentApp()
            .subscribe(app => this.app = app);
        this.getAccount();
        this.createCustomAlertifyDialog();
    }

    getAccount() {
        let request;
        this.account_id = this.route.snapshot.paramMap.get('account_id');

        if (this.account_id) {
            request = this.authService.getAccount(this.account_id);
        } else {
            request = this.authService.getCurrentAccount();
        }

        request.subscribe(account => {
            this.account = account;
            this.authService.getCurrentAccount()
            .subscribe(account => {
                this.account_created_at = account.created_at;
            });
            this.checkDateAndSetProperty(this.account_created_at)
            this.getBalance();
            this.getServices();
            this.getAreas();
            this.objects = SessionHelper.load(this.storage_name, []);
        });
    }

    getBalance() {
        this.billingService.getBalanceForAccount(this.account.id)
            .subscribe(balance => this.balance = balance);
    }

    getServices() {
        this.servicesService.getAvailableServices(this.account.id)
        .subscribe(services => {
            this.services = services;
            this.calculateSingleServices();
        });
        this.switchboardService.readNumbersOnAccount(this.account.id)
            .subscribe(numbers => this.numbers = numbers);
        this.servicesService.getServices()
            .subscribe(services => this.defined_services = services);
        this.getAccountServices();
    }

    getAccountServices() {
        this.servicesService.getServicesForAccount(this.account.id)
            .subscribe(services => this.account_services = services);
    }

    calculateSingleServices() {
        const single_service = {};
        for (let service of this.services) {
            if (single_service[service.switchboard_type]) {
                if (single_service[service.switchboard_type].retail_cost > service.retail_cost) {
                    single_service[service.switchboard_type] = service;
                }
                single_service[service.switchboard_type]['multiple'] = true;
            } else {
                single_service[service.switchboard_type] = service;
            }
        }
        this.single_services = Object.values(single_service);
    }

    objectHasMedia() {
        return this.objects_with_media.indexOf(this.switchboard_type) !== -1
    }

    openCreateModal(switchboard_type) {
        const date = moment().format('ll');
        this.object = {};

        this.object['default_name'] = `${switchboard_type} ${date}`.replace('sip', 'VoIP User');
        this.switchboard_type = switchboard_type;

        if (this.objectHasMedia()) {
            this.object_media = {
                object_data: {},
            };
            setTimeout(() => this.mediaComponent.open());
        } else {
            this.create_object_modal.open();
        }
    }

    getAccountServicesOfType(type) {
        return this.account_services.filter(service => service.service.switchboard_type === type);
    }

    createObject(service) {
        if (this.switchboard_type === 'Sip') {
            if (!this.object['number']) {
                toastr.error(`Please select a number for your VoIP User`);
                return false;
            }
        }

        const currentServices = this.getAccountServicesOfType(service.switchboard_type);
        if (service.switchboard_type === 'CallRecording' && currentServices.length > 0) {
            const previousService = currentServices[0].service;
            let confirmText = `By selecting this service you will change from <strong>${previousService.name}</strong> to <strong>${service.name}</strong><br><br>`;
            if (service.switchboard_quality < previousService.switchboard_quality) {
                const daysDifference = previousService.switchboard_quality - service.switchboard_quality;
                const deletedDate = moment().subtract(daysDifference, 'd').format('LL');
                confirmText += `<div class="alert alert-warning">
                By doing this you will be downgrading your current service
                <br>
                <br>
                Call recording records before <em>${deletedDate}</em> will be <strong>deleted</strong>.
                </div>
                `;
            }
            confirmText += `Would you like to continue with this purchase?`;
            alertify.confirm(confirmText, () => this.pushToCart(service));
            return false;
        }

        this.pushToCart(service);
    }

    pushToCart(service) {
        let object = {
            data: this.object,
            service: service,
            quantity: 1,
        };
        this.objects.push(object);
        this.saveObjects();
        toastr.success('Added to cart!');
        this.jumpToCart();
        this.create_object_modal.close();

        const defined_service = this.getServiceById(service.id);
        if (defined_service && defined_service.sub_services.length) {
            this.openAddonModal(object);
        }
    }

    objectCost(object) {
        let cost = object.service.retail_cost;

        if (object.bundle) {
            cost += object.bundle.retail_cost;
        }

        if (object.addons) {
            for (let addon of object.addons) {
                cost += addon.retail_cost;
            }
        }

        if (object.sub_services) {
            for (let sub_service of object.sub_services) {
                cost += sub_service.retail_cost;
            }
        }

        return cost;
    }

    getTotal() {
        return this.objects.map(object => this.objectCost(object) * object.quantity).reduce((a, b) => a + b, 0);
    }

    getTotalGenerated() {
        return this.generated.map(object => this.objectCost(object)).reduce((a, b) => a + b, 0);
    }

    saveObjects() {
        SessionHelper.save(this.storage_name, this.objects);
    }

    delete(index) {
        this.objects.splice(index, 1);
        this.saveObjects();
    }

    toSnakeCase(text, seperator = '_') {
        return text.replace(/[A-Z]/g, letter => `${seperator}${letter.toLowerCase()}`).replace(new RegExp(`^\\${seperator}`), '');
    }

    formatSwitchboardObjectData(object, serviceInstance) {
        const switchboard_type = object.service.switchboard_type;
        const object_type = switchboard_type.replace('sip', 'VoIP User');

        let data = {
            name: object.data.name || object.data.default_name,
            service_instance_id: serviceInstance.id,
        };

        if (switchboard_type === 'Sip') {
            data['number_uuid'] = object.data.number;
            data['local_e164_prefix'] = '44';
            data['secret'] = SipsHelper.generatePassword();
        }

        if (this.objectHasMedia()) {
            data['switchboard_media_file_uuid'] = this.object_media.object_data['switchboard_media_file_uuid'] || null;
        }

        if (switchboard_type === 'Number') {
            data = Object.assign(data, object.data);
        }

        return data;
    }

    getObjectSubServices(object) {
        let sub_services = [];

        if (object.bundle) {
            sub_services.push(object.bundle);
        }

        if (object.addons && object.addons.length) {
            sub_services = sub_services.concat(object.addons);
        }

        return sub_services;
    }

    generateObject() {
        if (this.objects.length === 0) {
            this.endGeneration();
            return false;
        }

        const object = this.objects.shift();

        this.generation_message = `Creating service ${object.service.name}`;

        this.servicesService.addMultipleServicesToAccount(this.account.id, object.service.id, object.quantity)
        .subscribe(serviceInstances => {
            let count = 0;
            const total = serviceInstances.length;
            const justService = ['Channel', 'MusicOnHold', 'CallRecording', 'Additional'];
            const object_type = this.toSnakeCase(object.service.switchboard_type);


            const createObject = () => {
                if (serviceInstances.length === 0) {
                    this.generateObject();
                    return false;
                }

                const sub_services = this.getObjectSubServices(object);
                const serviceInstance = serviceInstances.shift();
                count += 1;

                const data = this.formatSwitchboardObjectData(object, serviceInstance);
                this.generation_message = `Creating object ${data.name} (${count}/${total})`;

                this.switchboardService.createObjectOnAccount(this.account.id, `${object_type}s`, data)
                .subscribe(new_object => {
                    this.generated.push({
                        data: data,
                        service: object.service,
                        object: new_object,
                        sub_services: sub_services,
                    });

                    if (sub_services.length) {
                        createSubServices(serviceInstance);
                    } else {
                        createObject();
                    }
                });
            };

            const createSubServices = (serviceInstance) => {
                const sub_services = this.getObjectSubServices(object);

                const createSubService = () => {
                    if (sub_services.length === 0) {
                        createObject();
                        return false;
                    }

                    const subService = sub_services.shift();
                    const data = this.formatSwitchboardObjectData(object, serviceInstance);
                    this.generation_message = `Creating ${subService.name} for ${data.name} (${count}/${total})`;

                    this.servicesService.addSubServiceToAccount(this.account.id, serviceInstance.id, subService.id)
                        .subscribe(() => createSubService());
                };

                createSubService();
            };

            if (justService.indexOf(object.service.switchboard_type) === -1) {
                createObject();
            } else {
                this.generated.push({
                    data: {quantity: serviceInstances.length},
                    service: object.service,
                    object: {},
                    sub_services: [],
                });
                this.generateObject();
            }
        });
    }

    endGeneration() {
        this.getBalance();
        this.getAccountServices();
        this.servicesService.refresh();
        this.generating = false;
        this.finished = true;
        SessionHelper.remove(this.storage_name);
    }

    generate() {
        if (this.app.billing_type !== 1 && this.getTotal() > this.balance) {
            toastr.error(`Not enough credit`);
            return false;
        }

        alertify.confirm(`Are you sure you want to purchase these items?`, () => {
            this.generating = true;
            this.generateObject();
        });
    }

    canAdd(service) {
        const isSingleton = this.singleton_services.indexOf(service.switchboard_type) !== -1;
        if (service.unique_per_account) {
            for (let object of this.objects) {
                if (object.service.id === service.id) {
                    return false;
                }

                if (isSingleton && object.service.switchboard_type === service.switchboard_type) {
                    return false;
                }
            }
        }
        return true;
    }

    /* Numbers */

    getAreas() {
        if (this.country_code) {
            this.area = null;
            this.switchboardService.getAreaCodes(this.country_code)
                .subscribe(areas => this.areas = areas.data);
        }
    }

    getNumbers() {
        this.purchasable_numbers = null;
        this.top_numbers = null;
        if (this.country_code && this.area) {
            this.switchboardService.searchNumbers(this.country_code, this.area, this.minScore, this.midScore, '')
                .subscribe(numbers => this.purchasable_numbers = numbers);
            this.switchboardService.getTopNumbers(this.country_code, this.area, this.midScore)
                .subscribe(numbers => this.top_numbers = numbers);
            this.getMemorableNumbers();
        }
    }

    getMemorableNumbers() {
        this.memorable_numbers = null;
        this.switchboardService.searchNumbers(this.country_code, this.area, this.midScore + 1, this.maxScore, this.searchTerm)
            .subscribe(numbers => this.memorable_numbers = numbers);
    }

    canAddNumber(number) {
        let fullNumber;
        for (let object of this.objects) {
            fullNumber = NumbersHelper.getFullNumber(number);
            if (object.data.e164_number === fullNumber) {
                return false;
            }
        }
        return true;
    }

    addNumber(number) {
        if (this.can_purchase_number) {
            const service = NumbersHelper.getAppropriateService(number, this.services);
            const fullNumber = NumbersHelper.getFullNumber(number);

            let object = {
                data: {
                    name: fullNumber,
                    e164_number: fullNumber,
                },
                service: service,
                quantity: 1,
                unique: true,
            };
            this.objects.push(object);
            toastr.success('Added to cart!');
            this.jumpToCart();
            this.saveObjects();

            this.purchasable_numbers = null;
            this.memorable_numbers = null;
            this.top_numbers = null;
            this.area = null;
            this.searchTerm = null;

            this.create_object_modal.close();

            const defined_service = this.getServiceById(service.id);
            if (defined_service && defined_service.sub_services.length) {
                setTimeout(() => this.openAddonModal(object), 500);
            }
        } else {
            alertify.htmlDialog(`<p>Please contact <a href="${this.app.support_url}" target="_blank">Support</a> to purchase numbers.</p>`).setHeader('Warning');
        }
    }

    getNumberService(number) {
        return NumbersHelper.getAppropriateService(number, this.services);
    }

    jumpToCart() {
        document.getElementById('cart').scrollIntoView();
    }

    openAddonModal(object) {
        this.getBundlesAndAddons(object);
        this.addons_modal.open();
    }

    getServiceById(service_id) {
        if (this.defined_services) {
            for (var defined_service of this.defined_services) {
                if (defined_service.id === service_id) {
                    return defined_service;
                }
            }
        }
        return null;
    }

    getBundlesAndAddons(object) {
        let output = {
            bundles: [],
            addons: [],
            object: object,
        };

        const defined_service = this.getServiceById(object.service.id);
        if (defined_service) {
            for (let sub_service of defined_service.sub_services) {
                output[sub_service.is_bundle ? 'bundles' : 'addons'].push(sub_service);
            }
        }

        this.sub_services = output;
    }

    getAddonIndex(addon) {
        return this.sub_services.object.addons.map(x => x.id).indexOf(addon.id);
    }

    removeAddon(addon) {
        if (this.hasAddon(addon)) {
            const addonIndex = this.getAddonIndex(addon);
            this.sub_services.object.addons.splice(addonIndex, 1);
        }
    }

    toggleAddon(addon) {
        if (!this.sub_services.object.addons) {
            this.sub_services.object.addons = [];
        }

        if (this.hasAddon(addon)) {
            this.removeAddon(addon);
        } else {
            this.sub_services.object.addons.push(addon);
        }
        this.saveObjects();
    }

    hasAddon(addon) {
        if (!this.sub_services.object.addons) {
            return false;
        }

        return this.getAddonIndex(addon) !== -1;
    }

    setBundle(bundle) {
        let object = this.sub_services.object;
        if (object.service.switchboard_type === 'Number' && object.data.e164_number.match(/^4480/)) {
            alertify.confirm(`When recieving calls from an 0800 number, you will be charged 7.5p per minute ingress charge.
                <br><br>
                This is in addition to any forwarding bundle you purchase. You need to ensure your account is kept in healthy credit to cover the ingress call costs.
                <br><br>
                Would you like to continue with this purchase?`, () => {
                this.sub_services.object.bundle = bundle;
            });
        } else {
            this.sub_services.object.bundle = bundle;
        }

        this.saveObjects();
    }

    checkDateAndSetProperty(yourDateStr) {
        const givenDate = new Date(yourDateStr);
        const currentDate = new Date();
        const thirtyOneDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 31));
    
        if (givenDate <= thirtyOneDaysAgo) {
            this.can_purchase_number = true;
        }
    }

    createCustomAlertifyDialog() {
        alertify.dialog('htmlDialog', function factory() {
            return {
              main: function(message) {
                this.message = message;
              },
              setup: function() {
                return {
                  buttons: [{ text: "Close", className: "btn btn-danger", key: 27 /* Esc */ }],
                  focus: { element: 0 }
                };
              },
              prepare: function() {
                this.setContent(this.message);
              }
            };
          });
    }
}
