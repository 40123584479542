<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Server Trunks</h2>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table align-items-center table-flush">
                    <tbody *ngIf="trunks else no_trunks">
                        <tr *ngFor="let trunk of trunks">
                            <th scope="row">{{ trunk.name }}</th>
                            <td>
                                <button class="btn btn-secondary" (click)="editTrunk(trunk)">Edit</button>
                            </td>
                            <td>
                                <button class="btn btn-danger" (click)="deleteTrunk(trunk)">Delete</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
                <div class="col text-right">
                    <button (click)="newTrunk()" class="btn btn-sm btn-primary" data-toggle="modal" data-target="#accountDetail">New Trunk</button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-modal (save)="saveTrunk()" title="New Trunk" #trunkDetail>
    <div class="field form-group">
        <label class="label form-control-label">Name</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Name" [(ngModel)]="current_trunk.name">
        </div>
    </div>

    <div class="field form-group">
        <label class="label form-control-label">Trunk Host</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Trunk Host" [(ngModel)]="current_trunk.trunk_host">
        </div>
    </div>

    <div class="field form-group">
        <label class="label form-control-label">Trunk Username</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Trunk Username" [(ngModel)]="current_trunk.trunk_username">
        </div>
    </div>

    <div class="field form-group">
        <label class="label form-control-label">Trunk Secret</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Trunk Secret" [(ngModel)]="current_trunk.trunk_secret">
        </div>
    </div>
</app-modal>

<ng-template #no_trunks>
    <tbody class="tbody">
        <tr class="tr">
            <td class="td" colspan="3">
                <span *ngIf="servers else is_loading">No Trunks Found</span>
            </td>
        </tr>
    </tbody>
</ng-template>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
