<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Signup Log</h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <ng-container>
                    <div class="row">
                        <div class="col">
                            <h3>Graph Summary</h3>
                            <canvas id="chart-stats" style="max-height: 240px;"></canvas>
                        </div>
                    </div>
                    <br>
                </ng-container>

                <div class="row">
                    <div class="col">
                        <h4>From Date Range</h4>
                        <app-ngbd-datepicker-range #datepicker></app-ngbd-datepicker-range>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <button class="btn btn-primary" (click)="filter()">Filter</button>
                        <button class="btn btn-danger" (click)="datepicker.clear();filter()">Clear Dates</button>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <h3>Logs</h3>
                <table class="table is-bordered is-striped is-hoverable is-fullwidth">
                    <thead>
                        <tr>
                            <th>Switchboard ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Number</th>
                            <th>Destination</th>
                            <th>Created At</th>
                        </tr>
                        <!-- <tr>
                            <td>
                                <input type="text" class="form-control" placeholder="Company" [(ngModel)]="search_company" (keyup.enter)="searchAccounts()">
                            </td>
                            <td>
                                <input type="text" class="form-control" placeholder="ID" [(ngModel)]="search_id" (keyup.enter)="searchAccounts()">
                            </td>
                            <td>
                                <input type="text" class="form-control" placeholder="Email" [(ngModel)]="search_email" (keyup.enter)="searchAccounts()">
                            </td>
                            <td>
                                <button class="btn btn-primary" (click)="searchAccounts()">Search</button>
                                <button class="btn btn-danger" (click)="search_company = '';search_id = '';search_email = '';searchAccounts()">Clear</button>
                            </td>
                        </tr> -->
                    </thead>
                    <tbody *ngIf="logs && logs.data?.length else no_logs">
                        
                        <tr *ngFor="let log of logs.data">
                            <td><a routerLink="/accounts/detail/{{ log.switchboard_id - 1000000 }}">{{ log.switchboard_id }}</a></td>
                            <td>{{ log.name }}</td>
                            <td>{{ log.email }}</td>
                            <td>
                                <span
                                    class="flag-icon flag-icon-{{ (log.number_purchased|number_to_country_code).toLowerCase() }}"
                                    [title]="log.number_purchased|number_to_country_prefix"
                                ></span>
                                {{ log.number_purchased|number_to_local }}
                            </td>
                            <td>
                                <ng-container *ngIf="log.destination">
                                    <span
                                        class="flag-icon flag-icon-{{ (log.destination|number_to_country_code).toLowerCase() }}"
                                        [title]="log.destination|number_to_country_prefix"
                                    ></span>
                                    {{ log.destination|number_to_local }}
                                </ng-container>
                            </td>
                            <td>{{ (log.created_at)|date:'medium' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
                <app-pagination [pagination]="logs" url="/signup-log"></app-pagination>
            </div>
        </div>
    </div>
</div>

<ng-template #no_logs>
    <tr>
        <td>
            <span *ngIf="logs else is_loading">No Logs</span>
        </td>
    </tr>
</ng-template>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
