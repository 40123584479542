<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item" *ngIf="!account_id"><a routerLink="/invoices">Invoices</a></li>
                <li class="breadcrumb-item" *ngIf="account_id"><a routerLink="/accounts">Accounts</a></li>
                <li class="breadcrumb-item" *ngIf="account_id"><a routerLink="/accounts/detail/{{ account_id }}">Account</a></li>
                <li class="breadcrumb-item active" aria-current="page">Invoice CDRs</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">
                            Invoice call detail records for Invoice #{{ invoice.id }} ({{ invoice?.created_at|format_local_date }})
                            <span *ngIf="account_id"> for {{ account.company_name }} ({{ account.id + 1000000 }})</span>
                        </h2>
                    </div>
                </div>
            </div>
            <div class="card-body" *ngIf="extension_cdrs !== null else loading">
                <ng-container *ngTemplateOutlet="extension_accordion"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #extension_accordion>
    <div class="accordion" id="extension-accordion">
        <ng-container *ngFor="let extension of extension_cdrs| keyvalue">
            <div class="accordion-item" *ngIf="extension.value.cdrs?.length">
                <h2 class="accordion-header accordion-header-clickable" id="extension-heading-{{ extension.key }}">
                    <a
                        class="accordion-button collapsed"
                        type="button"
                        data-toggle="collapse"
                        [attr.data-target]="'#extension-collapse-' + extension.key"
                        aria-controls="extension-collapse-{{ extension.key }}"
                        style="cursor: pointer; "
                    >
                        <span class="row">
                            <span class="col">
                                <ng-container *ngIf="extension.key.length !== 4">
                                    Divert From
                                    <span class="flag-icon flag-icon-{{ (extension.key|number_to_country_code).toLowerCase() }}" [title]="extension.key|number_to_country_prefix"></span>
                                    {{ extension.key|number_to_local }}
                                </ng-container>
                                <ng-container *ngIf="extension.key.length === 4">
                                    <i class="{{ 'Sip'|switchboard_object_icon }}" title="VoIP User"></i>
                                    {{ extension.key }} - {{ sips[extension.key].object_data.name }}
                                </ng-container>
                                <i class="fas fa-chevron-circle-right"></i>
                                <i class="fas fa-chevron-circle-down"></i>
                            </span>
                            <span *ngIf="extension.value.billsec as billsec" class="col text-sm" style="font-weight: none; text-align: center; ">
                                Duration : {{ billsec }}s ({{ ((billsec - (billsec % 60)) / 60) }}m{{ billsec % 60 }}s)
                            </span>
                            <span *ngIf="extension.value.cost" class="col text-sm" style="font-weight: none; text-align: center; ">
                                Cost : &pound;{{ extension.value.cost|format_balance }}
                            </span>
                        </span>
                    </a>
                </h2>
                <div 
                    id="extension-collapse-{{ extension.key }}"
                    class="collapse"
                    aria-labelledby="extension-heading-{{ extension.key }}"
                    data-parent="#extension-accordion"
                >
                    <div class="accordion-body">
                        <div class="table-responsive">
                            <table class="table align-items-center table-flush table-float-header">
                                <thead>
                                    <tr>
                                        <th>Start Time</th>
                                        <!-- <th>From Extension</th> -->
                                        <th>Destination</th>
                                        <th>cli</th>
                                        <th>Duration</th>
                                        <th>Cost</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="extension.value.cdrs?.length else no_excdrs">
                                    <tr *ngFor="let cdr of extension.value.cdrs">
                                        <td>{{ (cdr.start_time)|date:'medium' }}</td>
                                        <!-- <td>{{ cdr.from_extension }}</td> -->
                                        <td>{{ cdr.dest }}</td>
                                        <td>{{ cdr.cli }}</td>
                                        <td>
                                            {{ cdr.duration|format_minutes }}
                                            <!-- {{ cdr.duration }}s -->
                                            <!-- ({{ ((cdr.duration - (cdr.duration % 60)) / 60) }}m{{ cdr.duration % 60 }}s) -->
                                        </td>
                                        <td>&pound;{{ cdr.retail_cost|format_balance }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <!-- <td colspan="6">
                                            <app-pagination [pagination]="cdrs" [(page)]="cdrs_page" (page_change)="getCDRs(current_invoice, $event)"></app-pagination>
                                        </td> -->
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="hasEmptyCDRs">
            <ng-container *ngFor="let extension of extension_cdrs| keyvalue">
                <ng-container *ngIf="extension.key.length === 4">
                    <div class="accordion-item" *ngIf="!extension.value.cdrs?.length">
                        <h2 class="accordion-header" id="extension-heading-{{ extension.key }}">
                            <span 
                                class="accordion-button collapsed"
                            >
                                <ng-container>
                                    <i class="{{ 'Sip'|switchboard_object_icon }}" title="VoIP User"></i>
                                    {{ extension.key }} - {{ sips[extension.key].object_data.name }}
                                </ng-container>
                            </span>
                        </h2>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #no_excdrs>
    <tr>
        <td colspan="5">
            <ng-container>No CDRs Found</ng-container>
        </td>
    </tr>
</ng-template>

<ng-template #loading>
    <app-loadingbar></app-loadingbar>
</ng-template>