import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { ContractsService } from '../contracts.service';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-account-contracts',
    templateUrl: './account-contracts.component.html',
    styleUrls: ['./account-contracts.component.css']
})
export class AccountContractsComponent implements OnInit {

    @Input() account_id;
    @Input() switchboard_objects;
    @Input() handsets;

    contracts;
    contract_types;
    current_contract: any = {};

    datepickerdate;

    @ViewChild('contractDetail') contractDetail;
    @ViewChild('datepicker') datepicker;

    constructor(
        private contractsService: ContractsService,
    ) { }

    ngOnInit(): void {
        this.getContracts();
        this.getContractTypes();
    }

    getContracts() {
        this.contractsService.getAccountContracts(this.account_id)
            .subscribe(contracts => this.contracts = contracts);
    }

    getContractTypes() {
        this.contractsService.getContractTypes()
            .subscribe(types => this.contract_types = types);
    }

    getSips() {
        if (!this.switchboard_objects) {
            return null;
        }
        return this.switchboard_objects.filter(object => object.object_type === 'Sip');
    }

    newContract() {
        this.current_contract = {};
        this.datepickerdate = '';
        this.contractDetail.open();
    }

    editContract(contract) {
        this.current_contract = contract;
        this.current_contract['edit'] = true;
        const splitDate = this.current_contract.started_at.split('T')[0];
        this.datepickerdate = splitDate;
        this.contractDetail.open();
    }

    deleteContract(contract) {
        alertify.confirm(`Are you sure you want to cancel this contract?`, () => {
            this.contractsService.deleteContract(this.account_id, contract.switchboard_object_uuid)
            .subscribe(out => {
                toastr.info('Cancelling contract!');
                this.getContracts();
            });
        });
    }

    saveContract() {
        let request;

        if (this.current_contract.edit) {
            request = this.contractsService.updateContract(this.account_id, this.current_contract);
        } else {
            request = this.contractsService.createContract(this.account_id, this.current_contract);
        }

        request.subscribe(contract => {
            if (contract.switchboard_object_uuid) {
                toastr.success('Contract Saved!');
                this.getContracts();
                this.contractDetail.close();
            } else {
                this.contractDetail.stopLoading();
            }
        });
    }

    onDateSelect(date_picker) {
        this.current_contract.started_at = `${date_picker.year}-${date_picker.month}-${date_picker.day}`;
    }

}
