import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { LoginService } from '../../../login/login.service';
import { SwitchboardService } from '../../../switchboard/switchboard.service';
import { ServicesService } from '../../../services/services.service';

import { SwitchboardObject } from '../../../switchboard/switchboard_object';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-portal-number-edit',
    templateUrl: './portal-number-edit.component.html',
    styleUrls: ['./portal-number-edit.component.css']
})
export class PortalNumberEditComponent implements OnInit {

    account_id;
    parent;

    uuid: string;
    switchboard_object: SwitchboardObject;

    constructor(
        private route: ActivatedRoute,
        private authService: LoginService,
        private switchboardService: SwitchboardService,
        private servicesService: ServicesService,
    ) { }

    ngOnInit() {
        this.uuid = this.route.snapshot.paramMap.get('uuid');
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account_id = account.id;
            this.getSwitchboardObject();
            this.getParent();
        });
    }

    getParent() {
        const parent_uuid = this.route.snapshot.queryParamMap.get('parent');
        if (parent_uuid) {
            this.switchboardService.getSwitchboardObject(parent_uuid)
                .subscribe(parent => this.parent = parent);
        }
    }

    backToParent() {
        window.history.go(-2);
    }

    getSwitchboardObject() {
        this.switchboardService.getSwitchboardObject(this.uuid)
        .subscribe((object: SwitchboardObject) => {
            this.switchboard_object = object;
            this.servicesService.getServicesForAccount(this.account_id)
            .subscribe((services: any[]) => {
                for (let service of services) {
                    if (object.service_instance_id === service.id) {
                        object.has_bundle = service.has_bundle;
                        object.minutes_used = service.sheet_instances.reduce((a, b) => a + b.minutes_used, 0);
                        object.bundle = service.sub_service_instances.filter(x => x.is_bundle)[0];
                        object.service_instance = service;
                    }
                }
            });
        });
    }

    toggleACR() {
        this.switchboardService.toggleSwitchboardObjectValue(
            this.account_id,
            this.switchboard_object,
            'acr_enabled',
            {field_name: 'Anonymous Call Rejection'}
        );
    }

    toggleCallRecording() {
        this.switchboardService.toggleSwitchboardObjectValue(
            this.account_id,
            this.switchboard_object,
            'callrecording_enabled',
            {field_name: 'Call Recording'}
        );
    }
}
