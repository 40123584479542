// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  sip_register: 'pbx2-staging.s-ip.co.uk',
  recaptcha_key: '6LdYt84ZAAAAAB4htpzK_tb-itDoMjJ6VpAXiTc6',
  services: {
      api: 'https://voip-4u.co.uk',
      billing: 'https://voip-4u.co.uk',
      auth: 'https://voip-4u.co.uk',
      media: 'https://voip-4u.co.uk',
      rates: 'https://voip-4u.co.uk',
      service: 'https://voip-4u.co.uk',
      cdr: 'https://voip-4u.co.uk',
      switchboard: 'https://voip-4u.co.uk',
      notification: 'https://voip-4u.co.uk',
      invoicing: 'https://invoice-api-7vclw.ondigitalocean.app',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
