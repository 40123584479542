<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow" *ngIf="account else is_loading">
            <ng-container *ngIf="account.id else account_deleted">
                <div class="card-header border-0">
                    <div class="row align-items-center">
                        <div class="col">
                            <h2 class="mb-0">Account</h2>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <ng-container>
                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field column3">
                                    <label class="label">Account</label>
                                    <p class="control is-expanded ">
                                        {{ account.id + sid_start }}
                                    </p>
                                    <span *ngIf="auth_code">
                                        <label class="label">Support Auth Code</label>
                                        <p class="control">
                                            {{ auth_code }}
                                        </p>
                                        </span>
                                        <div class="field">
                                            <label class="label">Sign up date</label>
                                            <p class="control">
                                                {{ (account.created_at)|date:'medium' }}
                                            </p>
                                        </div>
                                </div>
                                <div class="field column2">
                                    <label class="label" for="company-name-input">Company Name</label>
                                    <div class="control">
                                        <input id="company-name-input" class="input form-control" type="text" placeholder="Company name" [(ngModel)]="account.company_name">
                                    </div>
                                    <label class="label" for="contact-number-input">Contact Number</label>
                                    <div class="control">
                                        <input id="contact-number-input" class="input form-control" type="text" placeholder="Contact Number" [(ngModel)]="account.contact_number">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field column2">
                                    <label class="label">Account Referral Code</label>
                                    <p class="control is-expanded ">
                                        {{ app?.short_name.toUpperCase() }}_{{ account.id }}
                                    </p>
                                    <label class="label" for="company-name-input">Referral Code</label>
                                    <div class="control">
                                        <input id="company-name-input" class="input form-control" type="text" placeholder="Referral Code" [(ngModel)]="account.referral_code" autocomplete="off">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="column2">
                            <div class="field">
                                <label class="label">Balance</label>
                                <p class="control">
                                    <i class="fas fa-pound-sign"></i>&nbsp;{{ balance|format_balance }}
                                </p>
                            </div>
                            <div class="field">
                                <label class="label">Is Business?</label>
                                <p class="control">
                                    <input type="checkbox" (change)="account.is_business = +account.is_business" [(ngModel)]="account.is_business">
                                </p>
                            </div>
                        </div>

                        <div class="field column2">
                            <label class="label" for="active-state-select">Active State: {{ account.active_state_reason }}</label>
                            <select id="active-state-select" class="form-control" [(ngModel)]="account.active_state">
                                <ng-container *ngFor="let state of active_states;index as i">
                                    <option [value]="i">{{ state }}</option>
                                </ng-container>
                            </select>

                            <label class="label" for="billing-address-select">Billing Address</label>
                            <ng-container *ngIf="account_config else is_loading">
                                <select id="billing-address-select" class="form-control" [(ngModel)]="account_config.billing_address_id">
                                    <ng-container *ngFor="let address of addressTab.addresses">
                                        <option [value]="address.id">{{ address.first_name }} {{ address.last_name }}, {{ address.address_line_1 }}, {{ address.address_line_2 }}, {{ address.city }}, {{ address.state_province }}, {{ address.country_region }}, {{ address.postal_code }}</option>
                                    </ng-container>
                                </select>
                                <br>
                            </ng-container>
                            <!-- TODO: when shipping address is added, reconsider uncommenting this -->
                            <!--
                            <label class="label" for="shipping-address-select">Shipping Address</label>
                            <select id="shipping-address-select" class="form-control" [(ngModel)]="account_config.shipping_address_id">
                                <ng-container *ngFor="let address of addressTab.addresses">
                                    <option [value]="address.id">{{ address.first_name }} {{ address.last_name }}, {{ address.address_line_1 }}, {{ address.address_line_2 }}, {{ address.city }}, {{ address.state_province }}, {{ address.country_region }}, {{ address.postal_code }}</option>
                                </ng-container>
                            </select>
                            -->
                        </div>

                        <div class="field is-grouped">
                            <button class="btn btn-primary is-link" (click)="saveAccount()">Save</button>
                            <button class="btn btn-danger is-danger" (click)="deleteAccount()" *ngIf="account_id">Delete</button>
                            <button class="btn btn-outline-secondary is-text" (click)="goBack()" *ngIf="account_id">Cancel</button>
                        </div>
                    </ng-container>
                </div>
                <app-tabs>
                    <app-tab name="Switchboard" [isActive]="true">
                        <app-switchboard
                            [account_id]="account.id"
                            [addresses]="addressTab.addresses"
                            [media]="media_tab.files"
                            [service_component]="services_tab"
                            #switchboard_component
                        ></app-switchboard>
                    </app-tab>
                    <app-tab name="Users">
                        <app-users [account_id]="account.id" #users_tab></app-users>
                    </app-tab>
                    <app-tab name="Handsets">
                        <app-handsets [account_id]="account.id" [admin]="true" #handsets_tab></app-handsets>
                    </app-tab>
                    <app-tab name="Services">
                        <app-account-services [account_id]="account.id" #services_tab></app-account-services>
                    </app-tab>
                    <app-tab name="Contracts" *appViperIsPostpay>
                        <app-account-contracts
                            [account_id]="account.id"
                            [switchboard_objects]="switchboard_component.switchboard_objects"
                            [handsets]="handsets_tab.handsets"
                        ></app-account-contracts>
                    </app-tab>
                    <app-tab name="Billing Preferences">
                        <div class="section" *ngIf="account_config else is_loading">

                            <label class="checkbox">
                                Auto Topup Available:
                                <label class="btn "
                                [class.btn-success]="+account_config.auto_topup_available"
                                [class.btn-danger]="!+account_config.auto_topup_available"
                                (click)="account_config.auto_topup_available = +!+account_config.auto_topup_available">
                                    {{ +account_config.auto_topup_available ? "On" : "Off" }}
                                </label>
                            </label>

                            <ng-container *ngIf="+account_config.auto_topup_available">
                                <div class="field">
                                    <label class="checkbox">
                                        Auto Topup:
                                        <label class="btn "
                                        [class.btn-success]="+account_config.auto_topup_enabled"
                                        [class.btn-danger]="!+account_config.auto_topup_enabled"
                                        (click)="account_config.auto_topup_enabled = +!+account_config.auto_topup_enabled">
                                            {{ +account_config.auto_topup_enabled ? "On" : "Off" }}
                                        </label>
                                    </label>
                                </div>

                                <ng-container *ngIf="+account_config.auto_topup_enabled">
                                    <div class="field">
                                        <div class="control">
                                            <label class="label">Auto Topup Threshold</label>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text" id="btnGroupAddon">&pound;</div>
                                                </div>
                                                <input class="input form-control" type="text" placeholder="Threshold" [(ngModel)]="account_config.auto_topup_threshold">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field">
                                        <div class="control">
                                            <label class="label">Auto Topup Amount</label>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text" id="btnGroupAddon">&pound;</div>
                                                </div>
                                                <input class="input form-control" type="text" placeholder="Amount" [(ngModel)]="account_config.auto_topup_amount">
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>




                            <div class="field">
                                <div class="control">
                                    <label class="label">Low Balance Threshold</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text" id="btnGroupAddon">&pound;</div>
                                        </div>
                                        <input class="input form-control" type="text" placeholder="Threshold" [(ngModel)]="account_config.low_balance_threshold">
                                    </div>
                                </div>
                            </div>

                            <div class="field">
                                <div class="control">
                                    <label class="label">Post Pay Credit Limit</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text" id="btnGroupAddon">&pound;</div>
                                        </div>
                                        <input class="input form-control" type="text" placeholder="Post Pay Credit Limit" [(ngModel)]="account_config.pp_credit_limit">
                                    </div>
                                </div>
                            </div>

                            <div class="control">
                                <button class="btn btn-primary is-link" (click)="saveAccountConfig()">Save Billing Preferences</button>
                            </div>
                        </div>
                    </app-tab>
                    <app-tab name="Addresses">
                        <app-addresses [account_id]="account.id" #addressTab></app-addresses>
                    </app-tab>
                    <app-tab name="Know Your Customer">
                        <app-kyc *ngIf="account" [admin]="true" [account]="account"></app-kyc>
                    </app-tab>
                    <app-tab name="Historic KYC">
                        <app-admin-historic-kyc [account_id]="account?.id" [fullpage]="false"></app-admin-historic-kyc>
                    </app-tab>
                    <app-tab name="Media">
                        <a routerLink="/voicemail-inboxes/detail/{{ account.id }}" class="btn btn-secondary">Voicemail Inbox</a>
                        <a routerLink="/accounts/detail/{{ account.id }}/call-recordings" class="btn btn-secondary">Call Recordings</a>
                        <a routerLink="/accounts/detail/{{ account.id }}/media" class="btn btn-secondary">Music On Hold</a>
                        <app-media [account_id]="account.id" #media_tab></app-media>
                    </app-tab>
                    <app-tab name="Transactions">
                        <app-transactions [account_id]="account.id" [users]="users_tab.users" (transactionChange)="updateBalance()"></app-transactions>
                    </app-tab>
                    <app-tab name="Direct Debits" *appViperIsPostpay>
                        <app-direct-debits [account_id]="account.id"></app-direct-debits>
                    </app-tab>
                    <app-tab name="Invoice Adjustment" *appViperIsPostpay>
                        <app-invoice-adjustment [account_id]="account.id"></app-invoice-adjustment>
                    </app-tab>
                    <app-tab name="Recurring Invoice Adjustment" *appViperIsPostpay>
                        <app-invoice-adjustment [account_id]="account.id" [recurring]="true"></app-invoice-adjustment>
                    </app-tab>
                    <app-tab name="Invoices (Kashflow)">
                        <app-invoices-kashflow [account_id]="account?.id" *ngIf="account?.id"></app-invoices-kashflow>
                    </app-tab>
                    <app-tab name="Invoices (Coinprint)">
                        <app-invoices-coinprint [admin]="true" [account_id]="account?.id" *ngIf="account?.id"></app-invoices-coinprint>
                    </app-tab>
                    <app-tab name="Pro Forma Invoices">
                        <app-proforma-invoices *ngIf="account?.id" [account_id]="account?.id"></app-proforma-invoices>
                    </app-tab>
                    <app-tab name="CDRs">
                        <app-account-cdrs *ngIf="account?.id" [account_id]="account.id" [switchboard_objects]="switchboard_component.switchboard_objects"></app-account-cdrs>
                    </app-tab>
                    <app-tab name="Card History">
                        <app-card-history *ngIf="account?.id" [account_id]="account.id"></app-card-history>
                    </app-tab>
                    <app-tab name="Event Log">
                        <app-event-log [account_id]="account?.id" [fullpage]="false"></app-event-log>
                    </app-tab>
                    <app-tab name="Fraud Report">
                        <app-fraud-report *ngIf="account?.id" [account_id]="account?.id"></app-fraud-report>
                    </app-tab>
                    <app-tab name="Call Block Report">
                        <app-call-block-report *ngIf="account?.id" [account_id]="account?.id"></app-call-block-report>
                    </app-tab>
                    <app-tab name="Notes">
                        <app-notes [account_id]="account.id"></app-notes>
                    </app-tab>
                </app-tabs>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #account_deleted>
    <div class="card-body">
        <h1 class="text-lg text-center">
            <i class="fas fa-times-circle text-danger text-lg"></i>
            <br>
            Account deleted or not found
        </h1>
    </div>
</ng-template>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
