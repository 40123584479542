import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { SwitchboardService } from '../../../switchboard/switchboard.service';

import { SwitchboardObject } from '../../../switchboard/switchboard_object';

declare var toastr: any;

@Component({
    selector: 'app-portal-component-rename-switchboard-object',
    templateUrl: './portal-component-rename-switchboard-object.component.html',
    styleUrls: ['./portal-component-rename-switchboard-object.component.css']
})
export class PortalComponentRenameSwitchboardObjectComponent implements OnInit {

    @Input() account_id;
    @Input() switchboard_object: SwitchboardObject;

    new_name: string;

    @ViewChild('rename_modal') renameModal;

    constructor(
        private switchboardService: SwitchboardService
    ) { }

    ngOnInit() {
    }

    open() {
        this.new_name = this.switchboard_object.object_data.name;
        this.renameModal.open();
    }

    rename() {
        this.switchboardService.updateSwitchboardObjectForAccount(this.account_id, {
            uuid: this.switchboard_object.uuid,
            name: this.new_name,
        }).subscribe(response => {
            toastr.success(`Saved name!`);
            this.switchboard_object.object_data.name = this.new_name;
            this.renameModal.close();
        });
    }

}
