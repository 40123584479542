<div class="main-content">
    <!-- Navbar -->
    <nav class="navbar navbar-top navbar-horizontal navbar-expand-md navbar-dark navbar-bg">
        <div class="container px-4">
            <a class="navbar-brand" href="/">
                <ng-container *ngIf="!app else brand_logo">
                    <!--<img src="/assets/images/viper-logo.png" *ngIf="app !== undefined" alt="Viper Logo"> -->
                </ng-container>
            </a>
        </div>
    </nav>
    <!-- Header -->
    <div class="header bg-gradient-primary py-7 py-lg-8">
        <div class="separator separator-bottom separator-skew zindex-100">
            <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
        </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
        <div class="row justify-content-center">
            <div class="col-lg-5 col-md-7">
                <div class="card bg-secondary shadow border-0">
                    <div class="card-body px-lg-5 py-lg-5">
                        <ng-content></ng-content>
                    </div>
                </div>
                <div class="row mt-3">
                    
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Footer -->
<footer class="py-5">
    <div class="container">
        <div class="row align-items-center justify-content-xl-between">
            <div class="col-xl-6">
                <div class="copyright text-center text-xl-left text-muted">
                    &copy; {{ datenow.getFullYear() }} <a [href]="mainsite" class="font-weight-bold ml-1" target="_blank">{{ app?.name || 'Viper' }}</a>
                </div>
            </div>
        </div>
    </div>
</footer>

<ng-template #brand_logo>
    <img src="/assets/images/{{ app.short_name }}/logo.png" alt="{{ app.name }} Logo">
</ng-template>

<app-live-chat></app-live-chat>