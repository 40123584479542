import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { LoginService } from '../../../login/login.service';
import { SwitchboardService } from '../../../switchboard/switchboard.service';
import { MediaService } from '../../../audio/media.service';

import { SwitchboardObject } from '../../../switchboard/switchboard_object';
import { AudioHelper } from '../../../tools/audio';

declare var toastr: any;

@Component({
    selector: 'app-portal-group-edit',
    templateUrl: './portal-group-edit.component.html',
    styleUrls: ['./portal-group-edit.component.css']
})
export class PortalGroupEditComponent implements OnInit, OnDestroy {

    account_id;
    parent;

    uuid: string;
    new_name: string;
    new_ring_time: number = null;
    switchboard_object: SwitchboardObject;

    switchboard_objects: SwitchboardObject[] = [];
    sips: SwitchboardObject[];

    media: any[];
    music: any[];
    audio_preview: any;

    new_audio: string = null;
    use_existing = true;
    uploading = false;

    @ViewChild('rename_modal') renameModal;
    @ViewChild('ringtime_modal') ringtimeModal;
    @ViewChild('whisper_modal') whisperModal;
    @ViewChild('music_modal') musicModal;
    @ViewChild('tts_form') tts_form;

    constructor(
        private route: ActivatedRoute,
        private authService: LoginService,
        private mediaService: MediaService,
        private switchboardService: SwitchboardService
    ) { }

    ngOnInit() {
        this.uuid = this.route.snapshot.paramMap.get('uuid');
        this.getSwitchboardObject();
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account_id = account.id;
            this.getSips();
            this.getMedia();
            this.getParent();
        });
    }

    ngOnDestroy() {
        this.stopPreview();
    }

    getParent() {
        const parent_uuid = this.route.snapshot.queryParamMap.get('parent');
        if (parent_uuid) {
            this.switchboardService.getSwitchboardObject(parent_uuid)
                .subscribe(parent => this.parent = parent);
        }
    }

    backToParent() {
        window.history.go(-2);
    }

    getSwitchboardObject() {
        this.switchboardService.getSwitchboardObject(this.uuid)
            .subscribe((object: SwitchboardObject) => this.switchboard_object = object);
    }

    getSips() {
        this.switchboardService.readSipsOnAccount(this.account_id)
            .subscribe(sips => this.sips = sips);
    }

    getMedia() {
        this.mediaService.getSwitchboardMedia(this.account_id)
            .subscribe(media => this.media = media);
        this.mediaService.getSwitchboardMusic(this.account_id)
            .subscribe(music => this.music = music);
    }

    getMediaFile() {
        for (let file of this.media) {
            if (file.uuid === this.switchboard_object.object_data.switchboard_media_file_uuid) {
                return file;
            }
        }
        return null;
    }

    getMusicFile() {
        if (this.music) {
            for (let file of this.music) {
                if (file.uuid === this.switchboard_object.object_data.switchboard_music_file_uuid) {
                    return file;
                }
            }
        }
        return null;
    }

    rename() {
        this.switchboardService.updateSwitchboardObjectForAccount(this.account_id, {
            uuid: this.switchboard_object.uuid,
            name: this.new_name,
        }).subscribe(response => {
            toastr.success(`Saved name!`);
            this.switchboard_object.object_data.name = this.new_name;
            this.renameModal.close();
        });
    }

    setRingTime() {
        this.switchboardService.updateSwitchboardObjectForAccount(this.account_id, {
            uuid: this.switchboard_object.uuid,
            timeout: this.new_ring_time,
        }).subscribe(response => {
            toastr.success(`Saved ring time!`);
            this.switchboard_object.object_data.timeout = this.new_ring_time;
            this.ringtimeModal.close();
        });
    }

    stopPreview() {
        if (this.audio_preview && !this.audio_preview.paused) {
            this.audio_preview.pause();
        }
    }

    preview(type = 'whisper') {
        let request;
        this.stopPreview();

        if (!this.audio_preview || this.audio_preview.uuid !== this.new_audio) {
            if (type === 'whisper') {
                request = this.mediaService.downloadSwitchboardMedia(this.account_id, {uuid: this.new_audio});
            } else if (type === 'music') {
                request = this.mediaService.downloadSwitchboardMusic(this.account_id, {uuid: this.new_audio});
            }

            request.subscribe(data => {
                this.audio_preview = AudioHelper.createAudioObjectFromData(data);
                this.audio_preview.uuid = this.new_audio;
            });
        } else {
            this.audio_preview = null;
        }
    }

    saveWhisperMedia() {
        if (this.new_audio !== null && !this.use_existing) {
            this.tts_form.create();
        } else {
            this.saveWhisper();
        }
    }

    saveWhisper() {
        if (this.new_audio === '') {
            toastr.error('Please add media for your call whisper');
            this.whisperModal.stopLoading();
            return false;
        }

        this.uploading = true;
        this.switchboardService.updateSwitchboardObjectForAccount(this.account_id, {
            uuid: this.switchboard_object.uuid,
            switchboard_media_file_uuid: this.new_audio,
        }).subscribe(response => {
            this.uploading = false;
            toastr.success(`Saved call whisper!`);
            this.switchboard_object.object_data.switchboard_media_file_uuid = this.new_audio;
            this.whisperModal.close();
        });
    }

    saveMusic() {
        if (this.new_audio === '') {
            toastr.error('Please add music for your music on hold');
            this.musicModal.stopLoading();
            return false;
        }

        this.uploading = true;
        this.switchboardService.updateSwitchboardObjectForAccount(this.account_id, {
            uuid: this.switchboard_object.uuid,
            switchboard_music_file_uuid: this.new_audio,
        }).subscribe(response => {
            this.uploading = false;
            toastr.success(`Saved music on hold!`);
            this.switchboard_object.object_data.switchboard_music_file_uuid = this.new_audio;
            this.musicModal.close();
        });
    }

    uploadFile(file, type = 'whisper') {
        this.getMedia();
        this.new_audio = file.uuid;
        if (type === 'whisper') {
            this.saveWhisper();
        } else if (type === 'music') {
            this.saveMusic();
        }
    }

}
