import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

import { CountryCodes } from '../tools/country-codes';

@Pipe({name: 'number_to_local'})
export class NumberToLocalPipe implements PipeTransform {

    transform(number: any) {
        let foundPrefix = null;
        let biggestPrefix = 0;

        if (!number) {
            return number;
        }

        for (let prefix of CountryCodes.e164_prefixes) {
            if (number.startsWith(prefix.number) && prefix.number.length > biggestPrefix) {
                biggestPrefix = prefix.number.length;
                foundPrefix = prefix;
            }
        }

        if (foundPrefix) {
            return (foundPrefix.number === '44' ? '0' : '') + number.substr(foundPrefix.number.length);
        }

        return number;
    }
}
