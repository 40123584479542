import { Component, OnInit, EventEmitter, Input, Output, OnDestroy } from '@angular/core';

declare var $: any;

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.css']
})

export class ModalComponent implements OnInit, OnDestroy {

    active: boolean;
    loading: boolean;

    @Input() modalid: string;
    @Input() title: string;
    @Input() size: string;
    @Output() save = new EventEmitter<any>();

    constructor() { }

    ngOnInit() {
        if (!this.modalid) {
            // generate a random string for modal
            const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            this.modalid = 'modal_';
            for (let i = 0; i < 15; i++) {
                this.modalid += charset[Math.floor(Math.random() * charset.length)];
            }
        }
    }

    ngOnDestroy() {
        this.close();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');
    }

    open() {
        let modal = $('#' + this.modalid);
        this.active = true;
        modal.off('hidden.bs.modal');
        modal.modal({
            keyboard: false,
            backdrop: 'static',
        });
    }

    close(callback = null) {
        let modal = $('#' + this.modalid);
        this.loading = false;
        this.active = false;
        if (callback) {
            modal.on('hidden.bs.modal', function (e) {
                callback();
            });
        }
        return modal.modal('hide');
    }

    stopLoading() {
        this.loading = false;
    }

}
