<app-modal (save)="rulesModal.close()" size="big" #rulesModal>
    <div class="table-responsive">
        <table *ngIf="rules else is_loading" class="table is-bordered is-striped is-hoverable is-fullwidth">
            <thead>
                <tr>
                    <th colspan="5">RULE</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let rule of rules">
                    <ng-container *ngIf="rule.editing else static_internal">
                        <ng-container *ngTemplateOutlet="form_internal"></ng-container>
                    </ng-container>

                    <ng-template #static_internal>
                        <th>
                            <h5>{{ rule.name }}</h5>
                            <h6 class="text-muted">
                                Redirect to:
                                <br />
                                {{ getDestination(rule) }}
                            </h6>
                        </th>
                        <td>
                            <h5 *ngIf="rule.time_range_start && rule.time_range_end">{{ rule.time_range_start }} to {{ rule.time_range_end }}</h5>
                            <h5 *ngIf="rule.dom_range_start && rule.dom_range_end">{{ rule.dom_range_start|ordinal }} to {{ rule.dom_range_end|ordinal }}</h5>
                        </td>
                        <td>
                            <strong>
                                <span *ngFor="let day of days" [class.is-gray]="!rule[day]" [class.is-green]="rule[day]">{{ day[0].toUpperCase() }}</span>
                            </strong>
                        </td>

                        <td>
                            <strong>
                                <span *ngFor="let month of months; index as i" [class.is-gray]="!rule[month]" [class.is-green]="rule[month]">
                                    <br *ngIf="i % 3 === 0 && i !== 0" />
                                    {{ month[0].toUpperCase() }}
                                </span>
                            </strong>
                        </td>

                        <td>
                            <button class="btn btn-secondary" (click)="editRule(rule)" [disabled]="internal_disabled">Edit</button>
                            <button class="btn btn-danger" (click)="deleteRule(rule)" [disabled]="internal_disabled">Delete</button>
                        </td>
                    </ng-template>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <ng-container *ngIf="!current_rule.uuid && current_rule.editing else new_internal">
                        <ng-container *ngTemplateOutlet="form_internal"></ng-container>
                    </ng-container>
                </tr>
            </tfoot>
        </table>
    </div>
</app-modal>

<ng-template #new_internal>
    <th colspan="7"><button class="btn btn-primary" (click)="newRule()">New Rule</button></th>
</ng-template>

<!--
    name: string;
    switchboard_destination_uuid: string;
    time_range_start: string;
    time_range_end: string;
    days_json: string;
    months_json: string;

    dom_range_start: number;
    dom_range_end: number;
-->

<ng-template #form_internal>
    <td colspan="5">
        <div class="form-row">
            <div class="col-md-6">
                <label for="rule-form-name">Name</label>
                <input id="rule-form-name" type="text" class="form-control" placeholder="Name" [(ngModel)]="current_rule.name">
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="rulesInputDestination">Destination</label>
                    <app-switchboard-object-dropdown [(model)]="current_rule.switchboard_destination_uuid" 
                                                     [switchboard_objects]="switchboard_objects"></app-switchboard-object-dropdown>
                </div>
            </div>
        </div>

        <div class="form-row">
            <div class="col-md-6">
                <label>Time Range</label>
                <app-time-dropdown [(model)]="current_rule.time_range_start"></app-time-dropdown>
            </div>
            <div class="col-md-6">
                <label>&nbsp;</label>
                <app-time-dropdown [(model)]="current_rule.time_range_end"></app-time-dropdown>
            </div>
        </div>

        <div class="form-row">
            <div class="col-md-12">
                <div class="form-group">
                    <label>Weekdays:</label>
                </div>
                <div class="form-group">
                    <div *ngFor="let day of days" class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input" id="rule-check-day-{{ day }}" [(ngModel)]="current_rule[day]">
                        <label class="custom-control-label" for="rule-check-day-{{ day }}">{{ day.toUpperCase() }}</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-row">
            <div class="col-md-12">
                <div class="form-group">
                    <label>Months:</label>
                </div>
                
                <div class="form-group">
                    <ng-container *ngFor="let month of months; index as i">
                        <div *ngIf="i < 6" class="custom-control custom-checkbox custom-control-inline">
                            <input type="checkbox" class="custom-control-input" id="rule-check-month-{{ month }}" [(ngModel)]="current_rule[month]">
                            <label class="custom-control-label" for="rule-check-month-{{ month }}">{{ month.toUpperCase() }}</label>
                        </div>
                    </ng-container>
                </div>

                <div class="form-group">
                    <ng-container *ngFor="let month of months; index as i">
                        <div *ngIf="i >= 6" class="custom-control custom-checkbox custom-control-inline">
                            <input type="checkbox" class="custom-control-input" id="rule-check-month-{{ month }}" [(ngModel)]="current_rule[month]">
                            <label class="custom-control-label" for="rule-check-month-{{ month }}">{{ month.toUpperCase() }}</label>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="form-row">
            <div class="col-md-6">
                <label>Days of Month</label>
                <input type="text" class="form-control" id="days-of-month-range" placeholder="Start Day" [(ngModel)]="current_rule.dom_range_start">
            </div>
            <div class="col-md-6">
                <label>&nbsp;</label>
                <input type="text" class="form-control" placeholder="End Day" [(ngModel)]="current_rule.dom_range_end">
            </div>
        </div>

        <div class="form-row">
            <div class="col-md-12">
                <button class="btn btn-success" (click)="saveRule()">Save</button>
                <button class="btn btn-danger" (click)="current_rule.editing = false">Cancel</button>
            </div>
        </div>
    </td>
</ng-template>

<ng-template #is_loading>
    <table class="table">
        <tr class="tr">
            <td class="td" colspan="5">
                <app-loadingbar></app-loadingbar>
            </td>
        </tr>
    </table>
</ng-template>