import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from '../login/authentication.guard';
import { RoleGuard } from '../sections/roles/role.guard';

import { InboundServerComponent } from './inbound-server/inbound-server.component';
import { OutboundServerComponent } from './outbound-server/outbound-server.component';
import { SwitchboardServerComponent } from './switchboard-server/switchboard-server.component';
import { ServerTrunkComponent } from './server-trunk/server-trunk.component';

const routes: Routes = [
    { path: 'inbound-servers', canActivate: [AuthenticationGuard, RoleGuard], component: InboundServerComponent, data: {roles: ['Server Admin'], title: 'Inbound Servers'} },
    { path: 'outbound-servers', canActivate: [AuthenticationGuard, RoleGuard], component: OutboundServerComponent, data: {roles: ['Server Admin'], title: 'Outbound Servers'} },
    { path: 'switchboard-servers', canActivate: [AuthenticationGuard, RoleGuard], component: SwitchboardServerComponent, data: {roles: ['Server Admin'], title: 'Switchboard Servers'} },
    { path: 'server-trunks', canActivate: [AuthenticationGuard, RoleGuard], component: ServerTrunkComponent, data: {roles: ['Server Admin'], title: 'Server Trunks'} },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class ServersRoutingModule { }
