import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { tap, retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

declare var toastr: any;

@Injectable({
    providedIn: 'root'
})
export class CoinprintService {

    private endpoint: string = environment.services.invoicing;
    private key: string;

    constructor(private httpClient: HttpClient) {}

    getKey() {
        return this.key;
    }

    hasKey() {
        return !!this.getKey();
    }

    setKey(key: string) {
        this.key = key;
    }

    processError(err: any): Observable<never> {
        let message: any = '';

        if (err.error instanceof ErrorEvent) {
            message = err.error.message;
        } else if (err.error) {
            message = err.error;
        } else {
            message = `Error Code: ${err.status}\nMessage: ${err.message}`;
        }

        console.log('processError:', message);

        if (message instanceof String) {
            toastr.error(message);
        } else {
            let mgs: any;
            for (mgs of Object.values(message)) {
                console.log(mgs);
                for (let m of mgs) {
                    toastr.error(m);
                }
            }
        }

        return throwError(message);
    }

    getHeaders(): any {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.key}`,
            }),
        };
    }

    getProformas(customer_id, page = 1): Observable<any> {
        const httpOptions = Object.assign(this.getHeaders(), {});
        httpOptions.params = {
            customer_id,
            page,
            append_download_link: 1,
        };
        return this.httpClient
            .get<any>(this.endpoint + `/v1/proformas`, httpOptions)
            .pipe(
                retry(1),
                catchError(this.processError)
            );
    }

    createProforma(account_id: string, proforma: any) {
        return this.httpClient
            .post<any>(this.endpoint + `/v1/customers/${account_id}/proformas`, proforma, this.getHeaders())
            .pipe(
                retry(1),
                catchError(this.processError)
            );
    }

    downloadProforma(proforma) {
        const httpOptions = Object.assign(this.getHeaders(), {});
        httpOptions.responseType = 'arraybuffer';
        return this.httpClient.get<any>(proforma.download_link, httpOptions);
    }

    convertProforma(proforma) {
        return this.httpClient
            .post<any>(this.endpoint + `/v1/proformas/${proforma.uuid}/convert`, {}, this.getHeaders())
            .pipe(
                retry(1),
                catchError(this.processError)
            );
    }

    createCreditNote(invoice, credit_note) {
        return this.httpClient
            .post<any>(this.endpoint + `/v1/invoices/${invoice.uuid}/credit_notes`, credit_note, this.getHeaders())
            .pipe(
                retry(1),
                catchError(this.processError)
            );
    }

    getCreditNoteDownloadLink(credit_note) {
        return this.httpClient
            .get<any>(this.endpoint + `/v1/credit_notes/${credit_note.uuid}/download_link`, this.getHeaders())
            .pipe(
                retry(1),
                catchError(this.processError)
            );
    }

    readInvoicePayments(invoice) {
        return this.httpClient
            .get<any>(this.endpoint + `/v1/invoices/${invoice.uuid}/payments`, this.getHeaders())
            .pipe(
                retry(1),
                catchError(this.processError)
            );
    }

    createInvoicePayment(invoice, payment) {
        return this.httpClient
            .post<any>(this.endpoint + `/v1/invoices/${invoice.uuid}/payments`, payment, this.getHeaders())
            .pipe(
                retry(1),
                catchError(this.processError)
            );
    }

    updateInvoicePayment(invoice, payment) {
        return this.httpClient
            .put<any>(this.endpoint + `/v1/invoices/${invoice.uuid}/payments/${payment.uuid}`, payment, this.getHeaders())
            .pipe(
                retry(1),
                catchError(this.processError)
            );
    }

    deleteInvoicePayment(invoice, payment) {
        return this.httpClient
            .delete<any>(this.endpoint + `/v1/invoices/${invoice.uuid}/payments/${payment.uuid}`, this.getHeaders())
            .pipe(
                retry(1),
                catchError(this.processError)
            );
    }
}
