const AUTH_TOKEN = 'authtoken';
const PROXY_TOKEN = 'authtoken-proxy';

export const AuthenticationHelper = {
    setAuthKey(token) {
        localStorage.setItem(AUTH_TOKEN, token);
    },
    setProxyKey(token) {
        localStorage.setItem(PROXY_TOKEN, token);
    },
    wipeAuthKey() {
        localStorage.removeItem(AUTH_TOKEN);
    },
    wipeProxyKey() {
        localStorage.removeItem(PROXY_TOKEN);
    },
    wipeAllKeys() {
        this.wipeAuthKey();
        this.wipeProxyKey();
    },
    isProxy() {
        return !!localStorage.getItem(PROXY_TOKEN);
    },
    hasKey() {
        return !!localStorage.getItem(AUTH_TOKEN);
    },
    getKey() {
        return localStorage.getItem(PROXY_TOKEN) || localStorage.getItem(AUTH_TOKEN);
    }
};
