<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Applications</h2>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table is-bordered is-striped is-hoverable is-fullwidth">
                    <thead class="thead">
                        <tr class="tr">
                            <th class="th">App Name</th>
                        </tr>
                    </thead>
                    <tbody class="tbody" *ngIf="applications else no_apps">
                        <tr class="tr" *ngFor="let application of applications">
                            <td class="td"><a href="{{ application.portal_url }}" rel="_blank">{{ application.name }}</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- 
            <div class="card-footer">
                <div class="col text-right">
                    <button (click)="applicationModal.open()" class="btn btn-sm btn-primary">New Application</button>
                </div>
            </div>
            -->
        </div>
    </div>
</div>

<ng-template #no_apps>
    <tbody class="tbody">
        <tr class="tr">
            <td class="td" colspan=2>No Applications Found</td>
        </tr>
    </tbody>
</ng-template>


<app-modal (save)="saveApplication()" title="New Application" #applicationModal modalid="applicationModal">
    <div class="control">
        <label class="label form-control-label">Create Key</label>
        <input class="input form-control" type="password" placeholder="Create Key" [(ngModel)]="newApp.create_key">
    </div>
    <hr>
    <div class="field form-group">
        <h4>Application Details</h4>
        <div class="control">
            <label class="label form-control-label">Name</label>
            <input class="input form-control" type="text" placeholder="Application Name" [(ngModel)]="newApp.name" (keyup)="genShortName()">
        </div>

        <div class="control">
            <label class="label form-control-label">Short Name</label>
            <input class="input form-control" type="text" placeholder="Short Name" [(ngModel)]="newApp.short_name">
        </div>

        <div class="control">
            <label class="label form-control-label">Portal Url</label>
            <input class="input form-control" type="text" placeholder="https://portal.application.website" [(ngModel)]="newApp.portal_url">
        </div>
    </div>
    <hr>
    <div class="field form-group">
        <h4>Account Details</h4>
        <div class="control">
            <label class="label form-control-label">Company Name</label>
            <input class="input form-control" type="text" placeholder="Company Name" [(ngModel)]="newApp.company_name">
        </div>

        <div class="control">
            <label class="label form-control-label">First Name</label>
            <input class="input form-control" type="text" placeholder="First Name" [(ngModel)]="newApp.first_name">
        </div>

        <div class="control">
            <label class="label form-control-label">Surname</label>
            <input class="input form-control" type="text" placeholder="Surname" [(ngModel)]="newApp.last_name">
        </div>

        <div class="control">
            <label class="label form-control-label">Email</label>
            <input class="input form-control" type="email" placeholder="email@website.com" [(ngModel)]="newApp.email">
        </div>

        <div class="control">
            <label class="label form-control-label">Password</label>
            <input class="input form-control" type="password" [(ngModel)]="newApp.password">
        </div>

        <app-password-generator (generate)="newApp.password = $event;newApp.password_confirmation = $event"></app-password-generator>

        <div class="control">
            <label class="label form-control-label">Password Confirmation</label>
            <input class="input form-control" type="password" [(ngModel)]="newApp.password_confirmation">
        </div>
    </div>
</app-modal>
