<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Auto Topup</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">
                            Auto Topup
                        </h2>
                    </div>
                </div>
            </div>
            <div class="card-body" *ngIf="account_config else loading">
                <ng-container *ngIf="account_config.auto_topup_enabled == 1 else enable_auto_topup">
                    <ng-container *ngTemplateOutlet="topup_settings"></ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="card-body">
        <app-loadingbar></app-loadingbar>
    </div>
</ng-template>

<ng-template #enable_auto_topup>
    <div class="btn btn-lg btn-block btn-success text-lg" (click)="enableTopup()">
        Enable Auto Topup
    </div>
</ng-template>

<ng-template #topup_settings>
    <ng-container *ngIf="hasBilling else address_required">
        <h3>If balance falls below:</h3>
        <div class="card-deck">
            <div class="card is-link" 
                 *ngFor="let price of threshold_prices" 
                 (click)="setThreshold(price)"
                 [class.selected]="account_config.auto_topup_threshold == (price * 100)">
                <div class="card-body darkcard">
                    <h3 class="text-lg text-center card-title"><i class="fas fa-pound-sign"></i> {{ price }}</h3>
                </div>
            </div>
        </div>

        <h3>Top up by:</h3>
        <div class="card-deck">
            <div class="card is-link" 
                 [class.disabled]="account_config.auto_topup_threshold - 500 > (price * 100)"
                 *ngFor="let price of topup_prices" 
                 (click)="setAmount(price)"
                 [class.selected]="account_config.auto_topup_amount == (price * 100)">
                <div class="card-body darkcard">
                    <h3 class="text-lg text-center card-title"><i class="fas fa-pound-sign"></i> {{ price }}</h3>
                </div>
            </div>
        </div>

        <div>
            <p>Credit on your account expires after a period of 90-days. You can prevent your credit from expiring by topping up before the expiry date, rolling the full balance over for a further 90 days.</p>
            <p>
                Would you like to set your auto top-up to work so it will automatically top-up to prevent any credit from expiring?
                <span class="input-group">
                    <button class="btn" [class.btn-success]="account_config.auto_topup_instead_of_expire" [class.text-muted]="!account_config.auto_topup_instead_of_expire" [class.btn-secondary]="!account_config.auto_topup_instead_of_expire" (click)="setValue('auto_topup_instead_of_expire', 1)">Yes</button>
                    <button class="btn" [class.btn-danger]="!account_config.auto_topup_instead_of_expire" [class.text-muted]="!account_config.auto_topup_instead_of_expire" [class.btn-secondary]="account_config.auto_topup_instead_of_expire" (click)="setValue('auto_topup_instead_of_expire', 0)">No</button>
                </span>
            </p>
            <p class="text-muted">(Currently: {{ account_config.auto_topup_instead_of_expire ? 'Auto-Topping Up to prevent expiring' : 'Expiring without Auto-Topup' }})</p>
            
        </div>

        <h3>Card Details</h3>
        <div id="dropin-container"></div>
        <button class="btn btn-outline-success" *ngIf="!setting_default else loading" (click)="defaultCard()">Set Card As Default</button>

        <p class="text-center text-lg">or</p>
        <div class="btn btn-lg btn-block btn-danger text-lg" (click)="account_config.auto_topup_enabled = 0; saveAccountConfig()">
            Disable Auto Topup
        </div>
    </ng-container>
</ng-template>

<ng-template #address_required>
    <p class="alert alert-warning">
        You will need a billing address before you can topup your account.
    </p>

    <a class="btn btn-lg btn-block btn-outline-warning text-lg" routerLink="/settings/addresses">
        Set Address
    </a>
</ng-template>
