<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Card History</h2>
                    </div>
                </div>
            </div>
            <div class="card-body" *ngIf="history.page === 1">
                <app-payment-history></app-payment-history>
            </div>
            <app-card-history #history></app-card-history>
        </div>
    </div>
</div>
