import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { LoginService } from '../../login/login.service';
import { NotificationService } from '../../notification.service';
import { BillingService } from '../../billing.service';
import { Subscription } from 'rxjs';

import { User } from '../user';
import { Account } from '../account';

import { PriceHelper } from '../../tools/price.helper';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.css']
})

export class AccountComponent implements OnInit, OnDestroy {

    app;

    account: Account;
    account_config: any;
    auth_code: any;

    readonly sid_start: number = 1000000;

    account_id: number;

    private balance: number;

    active_states;
    billing_preferences;

    private billingAddressSub: Subscription;

    @ViewChild('parent') parent;

    constructor(
        private route: ActivatedRoute,
        private authService: LoginService,
        private billingService: BillingService,
        private router: Router,
    ) { }

    ngOnInit() {
        this.account_id = +this.route.snapshot.paramMap.get('id');
        this.authService.getActiveSates()
            .subscribe(states => this.active_states = states);
        this.authService.getCurrentApp()
            .subscribe(app => this.app = app);
        this.getAccount();
        this.billingAddressSub = this.authService.currentBillingAddress.subscribe(
            address => {
              if (address) {
                // Update the local billing address display
                this.account_config.billing_address = address;
              }
            }
        );
    }

    ngOnDestroy() {
        if (this.billingAddressSub) {
          this.billingAddressSub.unsubscribe();
        }
      }

    saveAccountConfig() {
        if (this.account_config.billing_address_id) {
            const priceFields = ['auto_topup_threshold', 'auto_topup_amount', 'low_balance_threshold', 'pp_credit_limit'];
            const account_config = PriceHelper.getObjectInPence(this.account_config, priceFields);
            this.authService.updateAccountConfig(this.account, account_config)
                .subscribe(out => toastr.success('Account config saved!'));
        }
    }

    getAccountConfig() {
        this.authService.getAccountConfig(this.account.id)
        .subscribe(config => {
            this.account_config = config;

            const priceFields = ['auto_topup_threshold', 'auto_topup_amount', 'low_balance_threshold', 'pp_credit_limit'];
            PriceHelper.fieldsToPounds(this.account_config, priceFields);
        });
    }

    getSupportAuth() {
        this.authService.getAccountSupportAuth(this.account.id)
            .subscribe(auth => this.auth_code = auth.auth_code);
    }

    getAccount() {
        let request;
        if (this.account_id) {
            request = this.authService.getAccount(this.account_id);
        } else {
            request = this.authService.getCurrentAccount();
        }
        request.subscribe(account => {
            this.account = account;
            if (account.id) {
                this.getAccountConfig();
                this.billingService.getBalanceForAccount(this.account.id)
                    .subscribe(balance => this.balance = balance);
                this.billingService.getSpecificAccountPreferences(this.account.id)
                    .subscribe(preferences => this.billing_preferences = preferences);
                this.getSupportAuth();
            }
        });
    }

    goBack(): void {
        this.router.navigate([`/accounts`]);
    }

    saveAccount() {
        this.authService.updateAccount(this.account)
            .subscribe(out => toastr.success('Account saved!'));
        this.saveAccountConfig();
    }

    deleteAccount() {
        alertify.confirm(`Are you sure you want to delete ${this.account.company_name}?`, () => {
            this.authService.deleteAccount(this.account)
            .subscribe(out => {
                toastr.info('Deleting account!');
                this.goBack();
            });
        });
    }


    updateBalance() {
        this.billingService.getBalanceForAccount(this.account.id).subscribe(balance => this.balance = balance);
    }
}
