<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">CDRs</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">{{ direction|capitalize }} CDRs</h2>
                    </div>
                </div>
            </div>
            <div class="card-body" >

                <h3>Filter Options</h3>
                
                <div class="row">
                    <div class="col">
                        <a routerLink="/cdrs/inbound" class="btn btn-primary" type="button">
                            Inbound
                        </a>

                        <a routerLink="/cdrs/outbound" class="btn btn-primary" type="button">
                            Outbound
                        </a>

                        <a routerLink="/cdrs/ingress" class="btn btn-primary" type="button">
                            Ingress
                        </a>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <select class="custom-select" [(ngModel)]="search_by">
                                    <option value="cli">CLI</option>
                                    <option value="dest">Destination</option>
                                </select>
                            </div>
                            <input type="text" class="form-control" placeholder="Search" [(ngModel)]="search_term">
                        </div>
                        <button class="btn btn-primary" (click)="filter()">Filter</button>
                    </div>
                </div>
                <br>
                <ng-container *ngIf="cdrs else loading">
                    <ng-container [ngSwitch]="direction">
                        <ng-container *ngSwitchCase="'inbound'">
                            <ng-container *ngTemplateOutlet="list_inbound_stats"></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'outbound'">
                            <ng-container *ngTemplateOutlet="list_outbound_stats"></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'ingress'">
                            <ng-container *ngTemplateOutlet="list_ingress_stats"></ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
            <div class="card-footer">
                <app-pagination [pagination]="cdrs" url="/cdrs/{{ direction }}"></app-pagination>
            </div>
        </div>
    </div>
</div>

<ng-template #list_inbound_stats>
    <!-- todo -->
    <div class="table-responsive">

        <table class="table align-items-center table-flush table-float-header table-striped">

            <thead>
                <tr>
                    <th>Account</th>
                    <th>Start Time</th>
                    <th>Received Number</th>
                    <th>Destination</th>
                    <th>cli</th>
                    <!-- <th>Answer Time</th> -->
                    <!-- <th>End Time</th> -->
                    <th>Duration</th>
                    <!-- <th>Charge</th> -->
                </tr>
            </thead>
            <tbody *ngIf="cdrs && cdrs.data.length else no_cdrs">
                <tr *ngFor="let cdr of cdrs.data">
                    <td>
                        <a routerLink="/accounts/detail/{{ cdr.account_id }}">{{ 1000000 + cdr.account_id }}</a>
                    </td>
                    <td>{{ (cdr.start_time)|date:'medium' }}</td>
                    <td>{{ cdr.recv_number }}</td>
                    <td>{{ cdr.dest }}</td>
                    <td>{{ cdr.cli }}</td>
                    <!-- <td>{{ cdr.answer_time }}</td> -->
                    <!-- <td>{{ cdr.end_time }}</td> -->
                    <td>{{ cdr.duration }}</td>
                    <!-- <td>&pound;{{ cdr.billsec|format_balance }}</td> -->
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #list_outbound_stats>
    <!-- todo -->
    <div class="table-responsive">
        <table class="table align-items-center table-flush table-float-header">
            <thead>
                <tr>
                    <th>Account</th>
                    <th>Start Time</th>
                    <th>From Extension</th>
                    <th>Destination</th>
                    <th>cli</th>
                    <!-- <th>Answer Time</th> -->
                    <!-- <th>End Time</th> -->
                    <th>Duration</th>
                    <!-- <th>Billsec</th> -->
                    <th>Cost</th>
                </tr>
            </thead>
            <tbody *ngIf="cdrs && cdrs.data.length else no_cdrs">
                <tr *ngFor="let cdr of cdrs.data">
                    <td>
                        <a routerLink="/accounts/detail/{{ cdr.account_id }}">{{ 1000000 + cdr.account_id }}</a>
                    </td>
                    <td>{{ (cdr.start_time)|date:'medium' }}</td>
                    <td>{{ cdr.from_extension }}</td>
                    <td>{{ cdr.dest }}</td>
                    <td>{{ cdr.cli }}</td>
                    <!-- <td>{{ cdr.answer_time }}</td> -->
                    <!-- <td>{{ cdr.end_time }}</td> -->
                    <td>{{ cdr.billsec }}</td>
                    <!-- <td>&pound;{{ cdr.billsec|format_balance }}</td> -->
                    <td>&pound;{{ cdr.retail_cost|format_balance }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #list_ingress_stats>
    <!-- todo -->
    <div class="table-responsive">
        <table class="table align-items-center table-flush table-float-header">
            <thead>
                <tr>
                    <th>Start Time</th>
                    <th>Origin</th>
                    <th>Destination</th>
                    <th>cli</th>
                    <th>Duration</th>
                    <th>Cost</th>
                </tr>
            </thead>
            <tbody *ngIf="cdrs && cdrs.data.length else no_cdrs">
                <tr *ngFor="let cdr of cdrs.data">
                    <td>{{ (cdr.start_time)|date:'medium' }}</td>
                    <td>{{ cdr.ingress_origin }}</td>
                    <td>{{ cdr.dest }}</td>
                    <td>{{ cdr.cli }}</td>
                    <td>{{ cdr.duration }}</td>
                    <td>&pound;{{ cdr.retail_cost|format_balance }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #no_cdrs>
    <tbody>
        <tr>
            <td class="text-center" [attr.colspan]="7">
                No CDRs
            </td>
        </tr>
    </tbody>
</ng-template>

<ng-template #loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
