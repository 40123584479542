import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LoginService } from '../../login/login.service';
import { ContractsService } from '../contracts.service';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-contract-rate-overrides',
    templateUrl: './contract-rate-overrides.component.html',
    styleUrls: ['./contract-rate-overrides.component.css']
})
export class ContractRateOverridesComponent implements OnInit {

    contract_type: any;
    rates: any;
    current_rate: any = {};

    page: number;

    @ViewChild('rateDetail') rateDetail;

    constructor(
        private route: ActivatedRoute,
        private authService: LoginService,
        private contractsService: ContractsService,
    ) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            const contract_uuid = params.get('uuid');
            this.page = +params.get('page') || 1;
            this.contractsService.getContractType(contract_uuid)
            .subscribe(contract_type => {
                console.log("CIOONTA", contract_type)
                this.contract_type = contract_type;
                this.getRateOverrides();
            });
        });
    }

    getRateOverrides() {
        this.contractsService.getContractTypeRatesOverrides(this.contract_type, this.page)
            .subscribe(rates => this.rates = rates);
    }

    newRate() {
        this.current_rate = {
            sip_contract_type_uuid: this.contract_type.uuid,
        };
        this.rateDetail.open();
    }

    editRate(rate) {
        this.current_rate = rate;
        this.rateDetail.open();
    }

    deleteRate(rate) {
        alertify.confirm(`Are you sure you want to delete ${rate.name}?`, () => {
            this.contractsService.deleteContractTypeRateOverride(rate)
            .subscribe(out => {
                toastr.info('Deleting Rate Override!');
                this.getRateOverrides();
            });
        });
    }

    saveRate() {
        let response;
        if (this.current_rate.id) {
            response = this.contractsService.updateContractTypeRateOverride(this.current_rate);
        } else {
            response = this.contractsService.createContractTypeRateOverride(this.current_rate);
        }
        response.subscribe(rate => {
            if (rate.id) {
                toastr.success('Rate Override Saved!');
                this.getRateOverrides();
                this.rateDetail.close();
            } else {
                this.rateDetail.stopLoading();
            }
        });
    }


}
