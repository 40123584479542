import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

import { LoginService } from '../../login/login.service';
import { SwitchboardService } from '../../switchboard/switchboard.service';

import { SwitchboardObject } from '../../switchboard/switchboard_object';

import { CountryCodes } from '../../tools/country-codes';

declare var toastr: any;
declare var alertify: any;

@Component({
  selector: 'app-portal-call-restrictions',
  templateUrl: './portal-call-restrictions.component.html',
  styleUrls: ['./portal-call-restrictions.component.css']
})
export class PortalCallRestrictionsComponent implements OnInit {

    account_id;

    direction = 'inbound';
    page = 1;

    call_restrictions;

    new_number;

    new_number_2;
    country_code;

    public countryCodes = CountryCodes.e164_prefixes;

    phoneForm = new UntypedFormGroup({
        phone: new UntypedFormControl(undefined, [Validators.required])
    });

    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;

    constructor(
        private authService: LoginService,
        private switchboardService: SwitchboardService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit() {
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account_id = account.id;
            this.activatedRoute.paramMap.subscribe(params => {
                this.page = 1;
                this.direction = params.get('direction') || this.direction;
                this.getCallRestrictions();
            });
        });
    }

    changePage(page) {
        this.page =  page;
        this.getCallRestrictions();
    }

    getCallRestrictions() {
        this.switchboardService.getAllCallRestrictions(this.account_id, this.direction, this.page)
            .subscribe(call_restrictions => this.call_restrictions = call_restrictions);
    }

    block() {
        if (this.direction === 'outbound') {

            if (!this.phoneForm.controls.phone && !this.phoneForm.controls.phone.value.e164Number) {
                toastr.error('Number required');
                return false;
            }

            const number = this.phoneForm.controls.phone.value.e164Number;
            this.new_number = number.replace('+', '');
        }

        this.switchboardService.createCallRestriction(this.account_id, this.direction, this.new_number)
        .subscribe(response => {
            if (response.e164_number) {
                toastr.success(`Number blocked!`);
                this.new_number = '';
                this.new_number_2 = '';
                this.country_code = '';
                this.getCallRestrictions();
                this.phoneForm.reset();
            }
        });
    }

    unblock(number) {
        alertify.confirm(`Are you sure you want to unblock ${number.e164_number}?`, () => {
            this.switchboardService.deleteCallRestriction(this.account_id, this.direction, number.e164_number)
            .subscribe(response => {
                toastr.success(`Number unblocked!`);
                this.getCallRestrictions();
            });
        });
    }

}
