import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { LoginService } from '../../../login/login.service';

declare var toastr: any;

@Component({
    selector: 'app-portal-addresses',
    templateUrl: './portal-addresses.component.html',
    styleUrls: ['./portal-addresses.component.css']
})
export class PortalAddressesComponent implements OnInit {

    account;
    account_config;

    constructor(
        private authService: LoginService,
    ) { }

    ngOnInit() {
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account = account;
            this.authService.getAccountConfig(account.id)
                .subscribe(config => this.account_config = config);
        });
    }

    saveAccount() {
        this.authService.updateAccountConfig(this.account, this.account_config)
            .subscribe(out => toastr.success('Addresses saved!'));
    }

}
