<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item" *ngIf="report_type"><a routerLink="/admin-reports">Admin Reports</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{ report_type|capitalize }} Admin Reports</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">{{ report_type|capitalize }} Admin Reports</h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <a class="btn btn-primary" *ngFor="let type of report_types" routerLink="/admin-reports/{{ type }}">{{ type|capitalize }}</a>
            </div>
            <div class="table-responsive">
                <table class="table is-bordered is-striped is-hoverable is-fullwidth">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>
                                <ng-container *ngIf="!report_type">Latest Report</ng-container>
                                <ng-container *ngIf="report_type">Date Created</ng-container>
                            </th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="!report_type && reports?.length">
                        
                        <tr *ngFor="let report of reports">
                            <td>{{ report.name }}</td>
                            <td>{{ (report.created_at)|date:'medium' }}</td>
                            <td>
                                <button class="btn btn-primary" (click)="viewReport(report)">View Latest</button>
                            </td>
                        </tr>
                    </tbody>

                    <tbody *ngIf="report_type && reports?.path">
                        
                        <tr *ngFor="let report of reports.data">
                            <td>{{ report.name }}</td>
                            <td>{{ (report.created_at)|date:'medium' }}</td>
                            <td>
                                <button class="btn btn-primary" (click)="viewReport(report)">View</button>
                            </td>
                        </tr>
                    </tbody>

                    <ng-container *ngIf="!(reports?.length || reports?.data?.length)">
                        <ng-container *ngTemplateOutlet="no_reports"></ng-container>
                    </ng-container>
                </table>
            </div>
            <div class="card-footer">
                <app-pagination *ngIf="report_type" [pagination]="reports" url="/admin-reports/{{ report_type }}"></app-pagination>
            </div>
        </div>
    </div>
</div>

<app-modal size="big" title="{{ current_report?.name }} - {{ (current_report?.created_at)|date:'medium' }}" #report_modal>
    <ng-container *ngIf="current_report?.url_name === 'service'">
        <ng-container *ngTemplateOutlet="service_report"></ng-container>
    </ng-container>
    <ng-container *ngIf="current_report?.url_name === 'conversion'">
        <ng-container *ngTemplateOutlet="conversion_report"></ng-container>
    </ng-container>
</app-modal>

<ng-template #service_report>
    <div class="table-responsive">
        <table class="table is-bordered is-striped is-hoverable is-fullwidth">
            <thead>
                <tr>
                    <th>Service Name</th>
                    <th>Quantity</th>
                    <th>Price Per</th>
                    <th>Total Expenditure</th>
                </tr>
            </thead>
            <tbody>
                
                <tr *ngFor="let service of reportData()">
                    <td>{{ service['Service Name'] }}</td>
                    <td>{{ service['Quantity'] }}</td>
                    <td class="text-right">&pound;{{ service['Price Per']|format_balance }}</td>
                    <td class="text-right">&pound;{{ service['Total Expenditure']|format_balance }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #conversion_report>
    <div class="table-responsive">
        <table class="table is-bordered is-striped is-hoverable is-fullwidth">
            <thead>
                <tr>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Signup Count</th>
                    <th>Converted (active) accounts</th>
                    <th>Percent Converted</th>
                </tr>
            </thead>
            <tbody>
                
                <tr *ngFor="let data of reportData()">
                    <td>{{ (data['From Date'])|date:'medium' }}</td>
                    <td>{{ (data['To Date'])|date:'medium' }}</td>
                    <td>{{ data['Signup Count'] }}</td>
                    <td>{{ data['Converted (active) accounts'] }}</td>
                    <td>{{ data['Percent Converted'] }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #no_reports>
    <tr>
        <td colspan="3">
            <span *ngIf="reports else is_loading">No Reports</span>
        </td>
    </tr>
</ng-template>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
