import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

declare var moment: any;

@Pipe({name: 'from_now'})
export class FromNowPipe implements PipeTransform {

    transform(value: any) {
        return moment(value).fromNow();
    }
}


