<div class="card text-center">
    <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
            <li *ngFor="let tab of tabs" class="nav-link" [class.active]="tab.isActive">
                <a (click)="select(tab)">{{ tab.name }}</a>
            </li>
        </ul>
    </div>
    <ng-content></ng-content>
</div>
