import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LoginService } from '../login/login.service';
import { SwitchboardService } from '../switchboard/switchboard.service';

import { Account } from '../accounts/account';
import { SwitchboardObject } from '../switchboard/switchboard_object';

declare var cytoscape: any;

@Component({
    selector: 'app-graph',
    templateUrl: './graph.component.html',
    styleUrls: ['./graph.component.css']
})
export class GraphComponent implements OnInit {

    account_id: number;
    account: Account;
    switchboard_objects: SwitchboardObject[];

    constructor(
        private route: ActivatedRoute,
        private authService: LoginService,
        private switchboardService: SwitchboardService
    ) { }

    ngOnInit() {
        this.account_id = +this.route.snapshot.paramMap.get('id');
        this.getAccount();
    }

    getAccount() {
        let request;
        if (this.account_id) {
            request = this.authService.getAccount(this.account_id);
        } else {
            request = this.authService.getCurrentAccount();
        }
        request.subscribe(account => {
            this.account = account;
            this.getSwitchboardObjects();
        });
    }

    getSwitchboardObjects() {
        this.switchboard_objects = null;
        this.switchboardService.getSwitchboardObjectsForAccount(this.account.id)
        .subscribe(switchboard_objects => {
            this.switchboard_objects = switchboard_objects;
            // this.serviceCount();
            this.drawGraph();
        });
    }

    drawGraph() {
        const elements = {nodes: [], edges: []};

        this.switchboard_objects.forEach(object => {
            const name = `${object.extension_number} (${object.object_type})\n${object.object_data.name}`;
            elements.nodes.push({data: {id: object.uuid, name: name}});
        });

        this.switchboard_objects.forEach(object => {
            let destination;

            switch (object.object_type) {
                case 'Menu':
                    for (let i = 0; i < 10; i++) {
                        destination = object.object_data[`option_${i}_uuid`];
                        if (destination) {
                            elements.edges.push({
                                data: {
                                    name: `${i}`,
                                    source: object.uuid,
                                    target: destination
                                }
                            });
                        }
                    }
                    break;
                default:
                    destination = object.object_data.switchboard_destination_uuid;
                    if (destination) {
                        elements.edges.push({
                            data: {
                                source: object.uuid,
                                target: destination
                            }
                        });
                    }
            }
        });

        const graph = cytoscape({
            container: document.getElementById('my_canvas'),
            layout: {
                name: 'breadthfirst'
            },
            elements: elements,
            style: [
                {
                    selector: 'node',
                    style: {
                        'content': 'data(name)',
                        'shape-polygon-points': 'round-rectangle',
                    }
                },

                {
                    selector: 'edge',
                    style: {
                        'content': 'data(name)',
                        'curve-style': 'bezier',
                        'target-arrow-shape': 'triangle'
                    }
                },

                {
                    'selector': 'node[points]',
                    'style': {
                        'shape-polygon-points': 'round-rectangle',
                        'label': 'polygon\n(custom points)',
                        'text-wrap': 'wrap'
                    }
                }
            ]
        });

    }

}
