<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Sheets</h2>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table is-bordered is-striped is-hoverable is-fullwidth">
                    <thead class="thead">
                        <tr class="tr">
                            <th class="th">Name</th>
                            <th class="th">&nbsp;</th>
                            <th class="th">&nbsp;</th>
                            <th class="th">&nbsp;</th>
                            <th class="th">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody class="tbody" *ngIf="sheets && sheets.length else no_disks">
                        <tr class="tr" *ngFor="let sheet of sheets">
                            <td class="td">{{ sheet.name }}</td>
                            <td class="td"><button class="btn btn-secondary is-link" (click)="ratesModal.open();getSheetRates(sheet)" [attr.disabled]="sheet.deleting">Rates</button></td>
                            <td><button class="btn btn-secondary is-link" (click)="rulesModal.open();getRules(sheet)" [attr.disabled]="sheet.deleting">Rules</button></td>
                            <td class="td"><button class="btn btn-secondary is-link" (click)="editSheet(sheet)" [attr.disabled]="sheet.deleting">Edit</button></td>
                            <td class="td"><button class="btn btn-danger is-danger" (click)="deleteSheet(sheet)" [attr.disabled]="sheet.deleting">Delete</button></td>
                        </tr>
                    </tbody>
                    <tfoot class="tfoot">
                        <tr class="tr">
                            <th class="th" colspan="5">
                                <button class="btn btn-primary is-primary" (click)="createSheet()">Add Sheet</button>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>

<ng-template #no_disks>
    <tbody class="tbody">
        <tr class="tr">
            <td class="td" colspan="4">
                <div *ngIf="sheets else loading_bar">No Sheets Found</div>
            </td>
        </tr>
    </tbody>
</ng-template>

<app-modal title="New Sheet" (save)="saveSheet()" #sheetModal>
    <div class="field">
        <label class="label">Name</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Name" [(ngModel)]="sheet.name">
        </div>
    </div>
    <div class="field">
        <label class="label">Minutes</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Number of minutes the sheet is applicable for" [(ngModel)]="sheet.minutes">
        </div>
    </div>
    <div class="field">
        <label class="label">Formula</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Math formula to apply to applicable rate costs" [(ngModel)]="sheet.formula">
        </div>
    </div>
</app-modal>

<app-modal size="big" title="{{ sheet.name }} Rates" #ratesModal>
    <div class="table-responsive">
        <table class="table is-bordered is-striped is-hoverable is-fullwidth">
            <thead class="thead">
                <tr class="tr">
                    <th class="th">Name</th>
                    <th class="th">Prefix</th>
                    <th class="th">Connection Charge</th>
                    <th class="th">Cost Per Minute</th>
                    <th class="th">&nbsp;</th>
                </tr>
            </thead>
            <tbody class="tbody" *ngIf="rates?.path else no_rates">
                <tr class="tr" *ngFor="let rate of rates.data">
                    <td class="td">{{ rate.name }}</td>
                    <td class="td">{{ rate.prefix }}</td>
                    <td class="td">{{ rate.retail_connection_charge }}p</td>
                    <td class="td">{{ rate.retail_cost_per_minute }}p</td>
                    <td class="td"><button class="btn btn-danger is-danger" (click)="detatchRate(rate)" [class.is-loading]="rate.deleted">Detach</button></td>
                </tr>
            </tbody>
            <tfoot class="tfoot">
                <tr class="tr">
                    <th class="th" colspan="5">
                        <app-pagination [pagination]="rates" [(page)]="rates_page" (page_change)="getSheetRates(null, $event)"></app-pagination>
                    </th>
                </tr>
            </tfoot>
        </table>
    </div>
</app-modal>

<ng-template #no_rates>
    <tbody class="tbody">
        <tr class="tr">
            <td class="td" colspan="5">
                <ng-container *ngIf="rates else loading_bar">No Rates Found</ng-container>
            </td>
        </tr>
    </tbody>
</ng-template>

<app-modal title="{{ sheet?.name }} Rules" size="big" #rulesModal>
    <div class="table-responsive">
        <table class="table is-bordered is-striped is-hoverable is-fullwidth">
            <thead class="thead">
                <tr class="tr">
                    <th class="th">Prefix</th>
                    <th class="th">Cost Per Minute</th>
                    <th class="th">Cost Per Connection</th>
                    <th class="th">&nbsp;</th>
                </tr>
            </thead>
            <tbody class="tbody">
                <ng-container *ngIf="sheet_rules?.length else no_rules">
                    <tr class="tr" *ngFor="let rule of sheet_rules">
                        <td class="td">
                            <ng-container *ngIf="rule.prefix && rule.prefix_not">Starts with {{ rule.prefix }} except for {{ rule.prefix_not }}</ng-container>
                            <ng-container *ngIf="!rule.prefix && rule.prefix_not">Doesn't start with {{ rule.prefix_not }}</ng-container>
                            <ng-container *ngIf="rule.prefix && !rule.prefix_not">Starts with {{ rule.prefix }}</ng-container>
                            <ng-container *ngIf="!rule.prefix && !rule.prefix_not">Any prefix</ng-container>
                        </td>
                        <td class="td">
                            <ng-container *ngIf="rule.cpm_greater_than && rule.cpm_less_than">Between {{ rule.cpm_greater_than }}p and {{ rule.cpm_less_than }}p</ng-container>
                            <ng-container *ngIf="!rule.cpm_greater_than && rule.cpm_less_than">Below {{ rule.cpm_less_than }}p</ng-container>
                            <ng-container *ngIf="rule.cpm_greater_than && !rule.cpm_less_than">Above {{ rule.cpm_greater_than }}p</ng-container>
                            <ng-container *ngIf="!rule.cpm_greater_than && !rule.cpm_less_than">Any price</ng-container>
                            
                        </td>
                        <td class="td">
                            <ng-container *ngIf="rule.cpc_greater_than && rule.cpc_less_than">Between {{ rule.cpc_greater_than }}p and {{ rule.cpc_less_than }}p</ng-container>
                            <ng-container *ngIf="!rule.cpc_greater_than && rule.cpc_less_than">Below {{ rule.cpc_less_than }}p</ng-container>
                            <ng-container *ngIf="rule.cpc_greater_than && !rule.cpc_less_than">Above {{ rule.cpc_greater_than }}p</ng-container>
                            <ng-container *ngIf="!rule.cpc_greater_than && !rule.cpc_less_than">Any price</ng-container>
                        </td>
                        <td class="td"><button class="btn btn-danger is-danger" (click)="removeRule(rule)" [class.is-loading]="rule.deleted">Delete</button></td>
                    </tr>
                </ng-container>
            </tbody>
            <tfoot class="tfoot">
                <tr class="tr">
                    <th>
                        <label>Starts with: <input class="form-control" [(ngModel)]="new_rule.prefix" placeholder="In E164 format (e.g. 447 rather than 07)"></label>
                        <br>
                        <label>Doesn't start with: <input class="form-control" [(ngModel)]="new_rule.prefix_not" placeholder="In E164 format (e.g. 447 rather than 07)"></label>
                    </th>
                    <th>
                        <label>
                            More than:
                            <div class="input-group">
                                <input class="form-control" [(ngModel)]="new_rule.cpm_greater_than" placeholder="in pence">
                                <div class="input-group-append">
                                    <div class="input-group-text" id="btnGroupAddon">p</div>
                                </div>
                            </div>
                        </label>
                        <br>
                        <label>
                            Less than:
                            <div class="input-group">
                                <input class="form-control" [(ngModel)]="new_rule.cpm_less_than" placeholder="in pence">
                                <div class="input-group-append">
                                    <div class="input-group-text" id="btnGroupAddon">p</div>
                                </div>
                            </div>
                        </label>
                    </th>
                    <th>
                        <label>
                            More than:
                            <div class="input-group">
                                <input class="form-control" [(ngModel)]="new_rule.cpc_greater_than" placeholder="in pence">
                                <div class="input-group-append">
                                    <div class="input-group-text" id="btnGroupAddon">p</div>
                                </div>
                            </div>
                        </label>
                        <br>
                        <label>
                            Less than:
                            <div class="input-group">
                                <input class="form-control" [(ngModel)]="new_rule.cpc_less_than" placeholder="in pence">
                                <div class="input-group-append">
                                    <div class="input-group-text" id="btnGroupAddon">p</div>
                                </div>
                            </div>
                        </label>
                    </th>
                    <th>
                        <button class="btn btn-primary" (click)="addRule()">Add Rule</button>
                    </th>
                </tr>
            </tfoot>
        </table>
    </div>
</app-modal>

<ng-template #no_rules>
    <tbody class="tbody">
        <tr class="tr">
            <td class="td" colspan="4">
                <ng-container>No Rules Found</ng-container>
            </td>
        </tr>
    </tbody>
</ng-template>

<ng-template #loading_bar>
    <app-loadingbar></app-loadingbar>
</ng-template>
