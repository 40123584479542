<ng-container *ngIf="switchboard_objects else loading_bar">
    <div class="table-responsive table-striped table-left-align" style="min-height:30vh;">
        <table class="table align-items-center table-flush">
            <thead class="thead">
                <tr class="tr">
                    <th class="th" colspan="6">
                        <div class="btn btn-secondary" (click)="getSwitchboardObjects()" title="Refresh Switchboard">
                            <i class="fas fa-sync-alt"></i>
                        </div>
                        <input [(ngModel)]="switchboard_filter_search" type="text" class="form-control" style="width: auto; display: inline; ">
                        <span>&nbsp;</span>
                        <div class="dropdown" [class.is-active]="filterDropdown">
                            <button id="filterDropdown" class="btn btn-secondary dropdown-toggle" aria-haspopup="true" aria-controls="dropdown-menu" data-toggle="dropdown">
                                <span>Filter By: {{ objectsFilter|capitalize }}</span>
                            </button>
                            <div class="dropdown-menu" role="menu" aria-labelledby="filterDropdown">
                                <div class="dropdown-content">
                                    <a class="dropdown-item" (click)="filterSwitchboardObjects('all')">
                                        All
                                    </a>
                                    <hr class="dropdown-divider">
                                    <a class="dropdown-item"  (click)="filterSwitchboardObjects('queue')">
                                        <i class="far fa-hourglass"></i> Queues
                                    </a>
                                    <a class="dropdown-item"  (click)="filterSwitchboardObjects('group')">
                                        <i class="fas fa-users"></i> Groups
                                    </a>
                                    <a class="dropdown-item"  (click)="filterSwitchboardObjects('announcement')">
                                        <i class="fas fa-bullhorn"></i> Announcements
                                    </a>
                                    <a class="dropdown-item"  (click)="filterSwitchboardObjects('menu')">
                                        <i class="fas fa-bars"></i> Menus
                                    </a>
                                    <a class="dropdown-item"  (click)="filterSwitchboardObjects('voicemail')">
                                        <i class="fas fa-microphone-alt"></i> Voicemails
                                    </a>
                                    <a class="dropdown-item"  (click)="filterSwitchboardObjects('number')">
                                        <i class="fas fa-cloud"></i> Numbers
                                    </a>
                                    <a class="dropdown-item"  (click)="filterSwitchboardObjects('sip')">
                                        <i class="fas fa-phone"></i> Sips
                                    </a>
                                </div>
                            </div>
                        </div>
                        <span>&nbsp;</span>
                        <div class="dropdown is-hoverable">
                            <button id="dropdownNewObject" class="btn btn-primary dropdown-toggle" aria-haspopup="true" aria-controls="dropdown-menu" data-toggle="dropdown">
                                <span>Create New:</span>
                            </button>
                            <div class="dropdown-menu" role="menu" aria-labelledby="dropdownNewObject">
                                <div class="dropdown-content">
                                    <a class="dropdown-item"  (click)="newSwitchboardObject('queue')">
                                        <i class="far fa-hourglass"></i> Queue
                                    </a>
                                    <a class="dropdown-item"  (click)="newSwitchboardObject('group')">
                                        <i class="fas fa-users"></i> Group
                                    </a>
                                    <a class="dropdown-item"  (click)="newSwitchboardObject('announcement')">
                                        <i class="fas fa-bullhorn"></i> Announcement
                                    </a>
                                    <a class="dropdown-item"  (click)="newSwitchboardObject('menu')">
                                        <i class="fas fa-bars"></i> Menu
                                    </a>
                                    <a class="dropdown-item"  (click)="newSwitchboardObject('voicemail')">
                                        <i class="fas fa-microphone-alt"></i> Voicemail
                                    </a>
                                    <a class="dropdown-item"  (click)="newSwitchboardObject('number')">
                                        <i class="fas fa-cloud"></i> Number
                                    </a>
                                    <a class="dropdown-item"  (click)="newSwitchboardObject('sip')">
                                        <i class="fas fa-phone"></i> Sip
                                    </a>
                                </div>
                            </div>
                        </div>
                        <a routerLink="/accounts/detail/{{ account_id }}/switchboard-bulk" class="btn btn-primary">Create Bulk</a>
                    </th>
                </tr>
                <tr class="tr">
                    <th class="th">Extension Type</th>
                    <th class="th">Name</th>
                    <th class="th">&nbsp;</th>
                    <th class="th">&nbsp;</th>
                </tr>
            </thead>
            <tbody class="tbody">
                <ng-container *ngIf="switchboard_objects.length else no_objects">
                    <ng-container *ngFor="let object of switchboard_objects">
                        <tr *ngIf="
                            (objectsFilter === 'all' || object.object_type.toLowerCase() === objectsFilter) 
                            && object.object_data.name.toLowerCase().includes(switchboard_filter_search.toLowerCase())">
                            <td>
                                <h5>
                                    <span class="ext-number">{{ object.extension_number }}</span>
                                    <ng-container *ngIf="busyLights && object.object_type === 'Sip'">
                                        <ng-container *ngIf="busyLights[object.extension_number] as state">
                                            <i class="fas fa-circle text-lg"
                                               [title]="state"
                                               [class.text-danger]="state == 'In use'"
                                               [class.text-blinking]="state == 'Ringing'"
                                               [class.text-success]="state == 'Not in use'"
                                            ></i>
                                            &nbsp;
                                        </ng-container>
                                        &nbsp;
                                    </ng-container>

                                    <i [class]="object|switchboard_object_icon"></i> {{ object.object_type }}

                                    <!-- <ng-container>
                                        <i class="fas fa-file-signature"></i>
                                    </ng-container> -->

                                    <a title="Click To Call"
                                       class="btn btn-sm btn-outline-secondary"
                                       *ngIf="object.object_type !== 'Number'"
                                       href="tel:2100{{ sid_start + account_id }}{{ object.extension_number }}"
                                    >
                                        <i class="fas fa-phone-volume"></i>
                                    </a>

                                    <a title="Preview Media"
                                       class="btn btn-sm btn-outline-secondary"
                                       *ngIf="object.object_data.switchboard_media_file_uuid"
                                       (click)="previewMedia(object.object_data.switchboard_media_file_uuid)"
                                    >
                                        <i class="fa-solid fa-play" style="color: #4385b1"></i>
                                    </a>

                                    <a title="Preview Music On Hold"
                                       class="btn btn-sm btn-outline-secondary"
                                       *ngIf="object.object_data.switchboard_music_file_uuid"
                                       (click)="previewMusic(object.object_data.switchboard_music_file_uuid)"
                                    >
                                        <i class="fa-solid fa-play" style="color: #4385b1"></i>
                                    </a>
                                </h5>
                            </td>
                            <td>
                                {{ object.object_data.name }}
                            </td>
                            <td class="text-right">
                                <ng-container  *ngIf="object.object_type === 'Queue' || object.object_type === 'Group'">
                                    <button class="btn btn-secondary" (click)="membersComponent.manageMembers(account_id, object)">Members</button> 
                                </ng-container>
                                <ng-container *ngIf="object.object_type === 'Sip' && app.billing_type === 1">
                                    <button class="btn btn-secondary" (click)="historicalContractsComponent.open(account_id, object)">Contracts History</button>
                                </ng-container>
                                <button class="btn btn-secondary" (click)="rulesComponent.manageRules(account_id, object)" *ngIf="object.object_type !== 'Sip'">Rules
                                    <span class="badge badge-pill badge-contrast badge-info" *ngIf="object.has_rules">Has Rules</span>
                                </button>
                                <button class="btn btn-info is-link" (click)="editSwitchboardObject(object)">Edit</button>
                                <button class="btn btn-danger is-danger" (click)="deleteSwitchboardObject(object)" [attr.disabled]="object.deleting">Delete</button>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </tbody>
        </table>
    </div>
    <ng-template #no_objects>
        <tr class="tr"><td class="td" colspan="6">No Switchboard Objects Found</td></tr>
    </ng-template>
</ng-container>

<div class="card-body">
    <ng-container *ngIf="switchboard else loading_bar">
        <div class="field form-group">
            <label class="label form-control-label">Switchboard Server</label>
            <div class="control">
                <div class="select">
                    <select class="form-control" [(ngModel)]="switchboard.switchboard_server_id">
                        <option *ngFor="let switchboard_server of switchboard_servers" value="{{ switchboard_server.id }}">{{ switchboard_server.name }}</option>
                    </select>
                </div>
            </div>
        </div>
        <ng-container *ngIf="switchboard_config else loading_bar">
            <div class="field form-group column4">
                <label class="label form-control-label">Max Cost Per Minute</label>
                <div class="control">
                    <div class="input-group">
                        <input class="input form-control" type="text" placeholder="max cost per minute" [(ngModel)]="switchboard_config.max_cpm">
                        <div class="input-group-append">
                            <div class="input-group-text" id="btnGroupAddon">p</div>
                        </div>
                    </div>
                </div>
                <label class="label form-control-label">Max Cost Per Connection</label>
                <div class="control">
                    <div class="input-group">
                        <input class="input form-control" type="text" placeholder="max cost per connection" [(ngModel)]="switchboard_config.max_cpc">
                        <div class="input-group-append">
                            <div class="input-group-text" id="btnGroupAddon">p</div>
                        </div>
                    </div>
                </div>
                <label class="label form-control-label">Max Call Cost (Total)</label>
                <div class="control">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="input-group-text" id="btnGroupAddon">&pound;</div>
                        </div>
                        <input class="input form-control" type="text" placeholder="max call cost" [(ngModel)]="switchboard_config.max_call_cost">
                    </div>
                </div>
                <label class="label form-control-label">Max Channels to Destination</label>
                <div class="control">
                    <input class="input form-control" type="text" placeholder="max channels to destination" [(ngModel)]="switchboard_config.max_channels_to_destination">
                </div>
            </div>
            <div class="field form-group column2">
                <label class="label form-control-label">Number Purchase Limit</label>
                <div class="control">
                    <input class="input form-control" type="text" placeholder="purchase number limit" [(ngModel)]="switchboard_config.number_limit">
                </div>
            </div>
            <div class="field form-group column3">
                <label for="" class="label form-control-label">Default E164 prefix</label>
                <div class="control">
                    <input class="input form-control" type="text" placeholder="" [(ngModel)]="switchboard_config.sip_default_e164_prefix">
                </div>
                <label for="" class="label form-control-label">Strip Prefix Count</label>
                <div class="control">
                    <input class="input form-control" type="text" placeholder="" [(ngModel)]="switchboard_config.sip_strip_prefix_count">
                </div>

                <label for="" class="label form-control-label">Allow Untrusted UK CLIs On Divert</label>
                <div class="control">
                    <input class="" type="checkbox" (change)="switchboard_config.allow_untrusted_uk_divert_cli = +switchboard_config.allow_untrusted_uk_divert_cli" [(ngModel)]="switchboard_config.allow_untrusted_uk_divert_cli">
                </div>
            </div>
        </ng-container>

        <div class="field form-group">
            <div class="control">
                <button class="btn  btn-secondary" (click)="saveSwitchboard()" [class.is-loading]="saving">Update Switchboard</button>
            </div>
        </div>
    </ng-container>
</div>

<div class="card-body">
    <app-modal title="{{ is_new ? 'New' : 'Edit' }} {{ type_form|capitalize }}" [ngSwitch]="type_form" (save)="saveSwitchboardObject()" #switchboard_object_modal modalid="switchboard_object_modal">
        <div class="field form-group">
            <label class="label form-control-label">Name</label>
            <div class="control">
                <input class="input form-control" type="text" placeholder="Name" [(ngModel)]="switchboard_object.name">
            </div>
        </div>
        <div class="field form-group" *ngIf="!is_new && type_form !== 'number'">
            <label class="label form-control-label">Extension Number</label>
            <div class="control">
                <input class="input form-control" type="text" placeholder="Extension Number" [(ngModel)]="current_extension">
            </div>
        </div>
        <div class="field form-group">
            <label class="label form-control-label">Service Instance</label>
            <div class="control">
                <div class="select" *ngIf="service_component.slot_counts && service_component.services">
                    <select class="form-control" [(ngModel)]="switchboard_object.service_instance_id">
                        <option value="">Select Service</option>
                        <ng-container *ngFor="let service of service_component.services">
                            <option value="{{ service.id }}" *ngIf="service.service.switchboard_type.toLowerCase() === type_form?.replace('_', '') && !service_component.slot_counts[service.id].full">
                                {{ service.service.name }} - {{ (service.created_at)|date:'medium' }} ({{ service_component.slot_counts[service.id].slots_taken }}/{{ service_component.slot_counts[service.id].max_slots }})
                            </option>
                        </ng-container>
                    </select>
                </div>

                <ng-container *ngIf="!(service_component.service_counts && service_component.services)">
                    <app-loadingbar></app-loadingbar>                    
                </ng-container>
            </div>
        </div>

        <div class="field form-group" *ngIf="['sip', 'number'].indexOf(type_form) === -1">
            <label class="form-control-label">Media</label>
            <div class="input-group">
                <select class="form-control" [(ngModel)]="switchboard_object.switchboard_media_file_uuid">
                    <option *ngFor="let file of media" [value]="file.uuid">{{ file.name }}</option>
                </select>

                <div class="input-group-append">
                    <button class="btn btn-success" [class.btn-danger]="audio_preview && !audio_preview.paused" (click)="preview('media', switchboard_object.switchboard_media_file_uuid)" target="_blank">
                        Preview

                        <i class="fas fa-play" *ngIf="audio_preview && !audio_preview.paused"></i>
                    </button>
                </div>
            </div>
        </div>

        <div class="field form-group" *ngIf="type_form === 'sip' || type_form === 'group'">
            <label class="form-control-label">Music On Hold</label>
            <div class="input-group">
                <select class="form-control" [(ngModel)]="switchboard_object.switchboard_music_file_uuid">
                    <option *ngFor="let file of music" [value]="file.uuid">{{ file.name }}</option>
                </select>

                <div class="input-group-append">
                    <button class="btn btn-success" [class.btn-danger]="audio_preview && !audio_preview.paused" (click)="preview('music', switchboard_object.switchboard_music_file_uuid)" target="_blank">
                        Preview

                        <i class="fas fa-play" *ngIf="audio_preview && !audio_preview.paused"></i>
                    </button>
                </div>
            </div>
        </div>

        <ng-container *ngSwitchCase="'queue'">
            <div class="field form-group">
                <label class="label form-control-label">Timeout</label>
                <div class="control">
                    <input class="input form-control" type="text" placeholder="Number of seconds to ring" [(ngModel)]="switchboard_object.timeout">
                </div>
            </div>
            <div class="field form-group">
                <label class="label form-control-label">Destination</label>
                <div class="control">
                    <app-switchboard-object-dropdown [(model)]="switchboard_object.switchboard_destination_uuid" 
                                                     [switchboard_objects]="switchboard_objects"></app-switchboard-object-dropdown>
                </div>
            </div>
            <div class="field form-group">
                <label class="label form-control-label">Strategy Type</label>
                <div class="control">
                    <div class="select">
                        <select class="form-control" [(ngModel)]="switchboard_object.strategy_type">
                            <option value="0">Ring All</option>
                            <option value="1">Round Robin</option>
                            <option value="2">Least Recent</option>
                            <option value="3">Fewest Calls</option>
                            <option value="4">Random</option>
                            <option value="5">rrmemory</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="field form-group">
                <label class="label form-control-label">Announce Frequency</label>
                <div class="control">
                    <input class="input form-control" type="text" placeholder="How often to announce holdtime to caller (0 = off)" [(ngModel)]="switchboard_object.announce_frequency">
                </div>
            </div>
            <div class="field form-group">
                <label class="label form-control-label">Include Holdtime?</label>
                <div class="control">
                    <div class="select">
                        <select class="form-control" [(ngModel)]="switchboard_object.announce_holdtime">
                            <option value="0">No</option>
                            <option value="1">Yes</option>
                            <option value="2">Once</option>
                        </select>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'group'">
            <div class="field form-group">
                <label class="label form-control-label">Timeout</label>
                <div class="control">
                    <input class="input form-control" type="text" placeholder="Number of seconds to ring" [(ngModel)]="switchboard_object.timeout">
                </div>
            </div>
            <div class="field form-group">
                <label class="label form-control-label">Destination</label>
                <div class="control">
                    <app-switchboard-object-dropdown [(model)]="switchboard_object.switchboard_destination_uuid" 
                                                     [switchboard_objects]="switchboard_objects"></app-switchboard-object-dropdown>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'announcement'">
            <div class="field form-group">
                <label class="label form-control-label">Destination</label>
                <div class="control">
                    <app-switchboard-object-dropdown [(model)]="switchboard_object.switchboard_destination_uuid" 
                                                     [switchboard_objects]="switchboard_objects"></app-switchboard-object-dropdown>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'menu'">
            <div class="field form-group">
                <label class="label form-control-label">Timeout</label>
                <div class="control">
                    <input class="input form-control" type="text" placeholder="Number of seconds to ring" [(ngModel)]="switchboard_object.timeout">
                </div>
            </div>
            <div class="field form-group">
                <label class="label form-control-label">Destination</label>
                <div class="control">
                    <app-switchboard-object-dropdown [(model)]="switchboard_object.switchboard_destination_uuid" 
                                                     [switchboard_objects]="switchboard_objects"></app-switchboard-object-dropdown>
                </div>
            </div>
            <hr>
            <div class="field form-group">
                <label class="label form-control-label">Option 0</label>
                <div class="control">
                    <app-switchboard-object-dropdown allow_none="1"
                                                     [(model)]="switchboard_object.option_0_uuid" 
                                                     [switchboard_objects]="switchboard_objects"></app-switchboard-object-dropdown>
                </div>
            </div>
            <div class="field form-group">
                <label class="label form-control-label">Option 1</label>
                <div class="control">
                    <app-switchboard-object-dropdown allow_none="1"
                                                     [(model)]="switchboard_object.option_1_uuid" 
                                                     [switchboard_objects]="switchboard_objects"></app-switchboard-object-dropdown>
                </div>
            </div>
            <div class="field form-group">
                <label class="label form-control-label">Option 2</label>
                <div class="control">
                    <app-switchboard-object-dropdown allow_none="1"
                                                     [(model)]="switchboard_object.option_2_uuid" 
                                                     [switchboard_objects]="switchboard_objects"></app-switchboard-object-dropdown>
                </div>
            </div>
            <div class="field form-group">
                <label class="label form-control-label">Option 3</label>
                <div class="control">
                    <app-switchboard-object-dropdown allow_none="1"
                                                     [(model)]="switchboard_object.option_3_uuid" 
                                                     [switchboard_objects]="switchboard_objects"></app-switchboard-object-dropdown>
                </div>
            </div>
            <div class="field form-group">
                <label class="label form-control-label">Option 4</label>
                <div class="control">
                    <app-switchboard-object-dropdown allow_none="1"
                                                     [(model)]="switchboard_object.option_4_uuid" 
                                                     [switchboard_objects]="switchboard_objects"></app-switchboard-object-dropdown>
                </div>
            </div>
            <div class="field form-group">
                <label class="label form-control-label">Option 5</label>
                <div class="control">
                    <app-switchboard-object-dropdown allow_none="1"
                                                     [(model)]="switchboard_object.option_5_uuid" 
                                                     [switchboard_objects]="switchboard_objects"></app-switchboard-object-dropdown>
                </div>
            </div>
            <div class="field form-group">
                <label class="label form-control-label">Option 6</label>
                <div class="control">
                    <app-switchboard-object-dropdown allow_none="1"
                                                     [(model)]="switchboard_object.option_6_uuid" 
                                                     [switchboard_objects]="switchboard_objects"></app-switchboard-object-dropdown>
                </div>
            </div>
            <div class="field form-group">
                <label class="label form-control-label">Option 7</label>
                <div class="control">
                    <app-switchboard-object-dropdown allow_none="1"
                                                     [(model)]="switchboard_object.option_7_uuid" 
                                                     [switchboard_objects]="switchboard_objects"></app-switchboard-object-dropdown>
                </div>
            </div>
            <div class="field form-group">
                <label class="label form-control-label">Option 8</label>
                <div class="control">
                    <app-switchboard-object-dropdown allow_none="1"
                                                     [(model)]="switchboard_object.option_8_uuid" 
                                                     [switchboard_objects]="switchboard_objects"></app-switchboard-object-dropdown>
                </div>
            </div>
            <div class="field form-group">
                <label class="label form-control-label">Option 9</label>
                <div class="control">
                    <app-switchboard-object-dropdown allow_none="1"
                                                     [(model)]="switchboard_object.option_9_uuid" 
                                                     [switchboard_objects]="switchboard_objects"></app-switchboard-object-dropdown>
                </div>
            </div>
            <div class="field form-group">
                <label class="label form-control-label">Option Star (*)</label>
                <div class="control">
                    <app-switchboard-object-dropdown allow_none="1"
                                                     [(model)]="switchboard_object.option_star_uuid" 
                                                     [switchboard_objects]="switchboard_objects"></app-switchboard-object-dropdown>
                </div>
            </div>
            <div class="field form-group">
                <label class="label form-control-label">Option Hash (#)</label>
                <div class="control">
                    <app-switchboard-object-dropdown allow_none="1"
                                                     [(model)]="switchboard_object.option_hash_uuid" 
                                                     [switchboard_objects]="switchboard_objects"></app-switchboard-object-dropdown>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'voicemail'">
            <div class="field form-group">
                <label class="label form-control-label">Email</label>
                <div class="control">
                    <input class="input form-control" type="text" placeholder="email@voicemail-haver.com" [(ngModel)]="switchboard_object.email">
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'number'">
            <div class="field form-group">
                <label class="label form-control-label">E164 Number</label>
                <div class="control">
                    <input class="input form-control" type="text" placeholder="441234567890" [(ngModel)]="switchboard_object.e164_number">
                </div>
            </div>
            <div class="field form-group">
                <label class="label form-control-label">Destination</label>
                <div class="control">
                    <app-switchboard-object-dropdown allow_none="1"
                                                     [(model)]="switchboard_object.switchboard_destination_uuid" 
                                                     [switchboard_objects]="switchboard_objects"></app-switchboard-object-dropdown>
                </div>
            </div>

            <div class="field form-group">
                <label class="label form-control-label">Anonymous Call Rejection</label>
                <label class="radio">
                    <input type="radio" name="acr_enabled" [value]="1" [(ngModel)]="switchboard_object.acr_enabled">
                    Enabled
                </label>
                <label class="radio">
                    <input type="radio" name="acr_enabled" [value]="0" [(ngModel)]="switchboard_object.acr_enabled">
                    Disabled
                </label>
            </div>

            <div class="field form-group">
                <label class="label form-control-label">Call Recording Enabled</label>
                <label class="radio">
                    <input type="radio" name="callrecording_enabled" [value]="1" [(ngModel)]="switchboard_object.callrecording_enabled">
                    Yes
                </label>
                <label class="radio">
                    <input type="radio" name="callrecording_enabled" [value]="0" [(ngModel)]="switchboard_object.callrecording_enabled">
                    No
                </label>
            </div>

            <ng-container *ngIf="switchboard_object.address">
                <hr />

                <div class="accordion" id="number-address-accordion">
                    <div class="accordion-item">
                        <h3 class="accordion-header" id="number-address-heading">
                            <a
                                class="accordion-button"
                                type="button"
                                data-toggle="collapse"
                                data-target="#number-address-details"
                                aria-expanded="true"
                                aria-controls="number-address-details"
                                style="cursor: pointer; "
                            >
                                Emergency Address <i class="fas fa-chevron-circle-right"></i>
                            </a>
                        </h3>
                        <div
                            id="number-address-details"
                            class="collapse"
                            aria-labelledby="number-address-heading"
                            data-parent="#number-address-accordion"
                        >
                            <div class="field">
                                <select class="form-control" #addressDropdown (change)="setAddress(addressDropdown.value);addressDropdown.value = ''">
                                    <option value="">Select Saved Address</option>
                                    <ng-container *ngFor="let address of addresses; let i = index">
                                        <option [value]="i">{{ address.first_name }} {{ address.last_name }}, {{ address.address_line_1 }}, {{ address.address_line_2 }}, {{ address.city }}, {{ address.state_province }}, {{ address.country_region }}, {{ address.postal_code }}</option>
                                    </ng-container>
                                </select>
                            </div>

                            <div class="field">
                                <label class="label">Title</label>
                                <div class="control">
                                    <input class="input form-control" type="text" placeholder="Title" [(ngModel)]="switchboard_object.address.title">
                                </div>
                            </div>

                            <div class="field">
                                <label class="label">First Name</label>
                                <div class="control">
                                    <input class="input form-control" type="text" placeholder="First Name" [(ngModel)]="switchboard_object.address.first_name">
                                </div>
                            </div>
                            <div class="field">
                                <label class="label">Last Name</label>
                                <div class="control">
                                    <input class="input form-control" type="text" placeholder="Last Name" [(ngModel)]="switchboard_object.address.last_name">
                                </div>
                            </div>

                            <div class="field">
                                <label class="label">Company Name</label>
                                <div class="control">
                                    <input class="input form-control" type="text" placeholder="Company Name" [(ngModel)]="switchboard_object.address.company_name">
                                </div>
                            </div>

                            <div class="field">
                                <label class="label">Company Suffix</label>
                                <div class="control">
                                    <input class="input form-control" type="text" placeholder="Company Suffix" [(ngModel)]="switchboard_object.address.company_suffix">
                                </div>
                            </div>

                            <div class="field">
                                <label class="label">Address Line 1</label>
                                <div class="control">
                                    <input class="input form-control" type="text" placeholder="Address Line 1" [(ngModel)]="switchboard_object.address.address_line_1">
                                </div>
                            </div>
                            <div class="field">
                                <label class="label">Address Line 2</label>
                                <div class="control">
                                    <input class="input form-control" type="text" placeholder="Address Line 2" [(ngModel)]="switchboard_object.address.address_line_2">
                                </div>
                            </div>

                            <div class="field">
                                <label class="label">City</label>
                                <div class="control">
                                    <input class="input form-control" type="text" placeholder="City" [(ngModel)]="switchboard_object.address.city">
                                </div>
                            </div>
                            <div class="field">
                                <label class="label">State Province</label>
                                <div class="control">
                                    <input class="input form-control" type="text" placeholder="State Province" [(ngModel)]="switchboard_object.address.state_province">
                                </div>
                            </div>
                            <div class="field">
                                <label class="label">Country</label>
                                <div class="control">
                                    <input class="input form-control" type="text" placeholder="Country" [(ngModel)]="switchboard_object.address.country_region">
                                </div>
                            </div>
                            <div class="field">
                                <label class="label">Postal Code</label>
                                <div class="control">
                                    <input class="input form-control" type="text" placeholder="Postal Code" [(ngModel)]="switchboard_object.address.postal_code">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'sip'">
            <div class="field form-group">
                <label class="label form-control-label">Number</label>
                <div class="control">
                    <app-switchboard-object-dropdown [(model)]="switchboard_object.number_uuid" 
                                                     [switchboard_objects]="switchboard_objects"
                                                     object_type="Number"></app-switchboard-object-dropdown>
                </div>
            </div>

            <div class="accordion" id="sip-redirect-accordion">
                <div class="accordion-item">
                    <h3 class="accordion-header" id="sip-redirect-heading">
                        <a class="accordion-button" type="button" data-toggle="collapse" data-target="#sip-redirect-details" aria-expanded="true" aria-controls="sip-redirect-details" style="cursor: pointer; ">
                            Sip Redirect <i class="fas fa-chevron-circle-right"></i>
                        </a>
                    </h3>
                    <div id="sip-redirect-details" class="collapse" aria-labelledby="sip-redirect-heading" data-parent="#sip-redirect-accordion">
                        <div class="field form-group">
                            <label class="label form-control-label">Divert Number</label>
                            <div class="control">
                                <input class="input form-control" type="text" placeholder="e.g. 447123456789" [(ngModel)]="switchboard_object.divert_number">
                            </div>
                        </div>

                        <div class="field form-group">
                            <label class="label form-control-label">Sim Card Number</label>
                            <div class="control">
                                <input class="input form-control" type="text" placeholder="" [(ngModel)]="switchboard_object.sim_card_number">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <hr>

            <div class="accordion" id="remote-call-accordion">
                <div class="accordion-item">
                    <h3 class="accordion-header" id="remote-call-heading">
                        <a class="accordion-button" type="button" data-toggle="collapse" data-target="#remote-call-details" aria-expanded="true" aria-controls="remote-call-details" style="cursor: pointer; ">
                            Remote Call Monitoring <i class="fas fa-chevron-circle-right"></i>
                        </a>
                    </h3>
                    <div id="remote-call-details" class="collapse" aria-labelledby="remote-call-heading" data-parent="#remote-call-accordion">
                        <!-- TODO EXTRA FIELDS -->
                        <label for="" class="label form-control-label">
                            Remote Call Monitoring Enabled
                        </label>
                        <div class="control">
                            <input class="" type="checkbox" (change)="switchboard_object.rcm_enabled = +switchboard_object.rcm_enabled" [(ngModel)]="switchboard_object.rcm_enabled">
                        </div>
                        <br>
                        <label for="" class="label form-control-label">
                            Add Immunity (i.e. Do not monitor the call)
                        </label>
                        <div class="control">
                            <input class="" type="checkbox" (change)="switchboard_object.rcm_immune = +switchboard_object.rcm_immune" [(ngModel)]="switchboard_object.rcm_immune">
                        </div>
                    </div>
                </div>
            </div>

            <hr>

            <div class="field form-group">
                <label class="label form-control-label">Secret</label>
            </div>
            <div class="field is-grouped">
                <div class="control">
                    <input class="input form-control" type="text" placeholder="The encrypted password" [(ngModel)]="switchboard_object.secret">
                </div>
                <app-password-generator label="Generate Secret" (generate)="switchboard_object.secret = $event"></app-password-generator>
            </div>
            <div class="field form-group">
                <label class="label form-control-label">Local E164 Prefix</label>
                <div class="control">
                    <input class="input form-control" type="text" placeholder="" [(ngModel)]="switchboard_object.local_e164_prefix">
                </div>
            </div>
            <div class="field form-group">
                <label class="label form-control-label">International Enabled</label>
                <label class="radio">
                    <input type="radio" name="international_enabled" [value]="1" [(ngModel)]="switchboard_object.international_enabled">
                    Yes
                </label>
                <label class="radio">
                    <input type="radio" name="international_enabled" [value]="0" [(ngModel)]="switchboard_object.international_enabled">
                    No
                </label>
            </div>
            <div class="field form-group">
                <label class="label form-control-label">Withhold CLI</label>
                <label class="radio">
                    <input type="radio" name="withheld_cli" [value]="1" [(ngModel)]="switchboard_object.withheld_cli">
                    Yes
                </label>
                <label class="radio">
                    <input type="radio" name="withheld_cli" [value]="0" [(ngModel)]="switchboard_object.withheld_cli">
                    No
                </label>
            </div>
            <div class="field form-group">
                <label class="label form-control-label">Voicemail</label>
                <div class="control">
                    <app-switchboard-object-dropdown allow_none="1"
                                                     [(model)]="switchboard_object.voicemail_uuid" 
                                                     [switchboard_objects]="switchboard_objects"
                                                     object_type="Voicemail"></app-switchboard-object-dropdown>
                </div>
            </div>
            <div class="field form-group">
                <label class="label form-control-label">Call Recording Enabled</label>
                <label class="radio">
                    <input type="radio" name="callrecording_enabled" [value]="1" [(ngModel)]="switchboard_object.callrecording_enabled">
                    Yes
                </label>
                <label class="radio">
                    <input type="radio" name="callrecording_enabled" [value]="0" [(ngModel)]="switchboard_object.callrecording_enabled">
                    No
                </label>
            </div>
        </ng-container>
    </app-modal>
</div>
<ng-template #loading_bar>
<app-loadingbar></app-loadingbar>
</ng-template>
<app-members [sips]="getSips()" #membersComponent></app-members>
<app-rules [switchboard_objects]="switchboard_objects" #rulesComponent></app-rules>

<app-modal title="Click To Call" (save)="click_to_call_modal.close()" #click_to_call_modal>
    <ng-container *ngFor="let object of account_sips">
        <div class="btn btn-block btn-outline-info text-lg" *ngIf="object.object_type === 'Sip'" (click)="clickToCall(object)">
            <i class="fas fa-phone-volume"></i> {{ object.extension_number }} - {{ object.object_data.name }}
        </div>
    </ng-container>
</app-modal>

<app-historical-contracts #historicalContractsComponent></app-historical-contracts>
