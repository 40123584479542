import { Component, OnInit } from '@angular/core';
import { MediaService } from '../audio/media.service';
import { SwitchboardService } from '../switchboard/switchboard.service';
import { LoginService } from '../login/login.service';

import { forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AudioHelper } from '../tools/audio';

declare var toastr: any;

@Component({
  selector: 'app-admin-verify-media',
  templateUrl: './admin-verify-media.component.html',
  styleUrls: ['./admin-verify-media.component.css']
})
export class AdminVerifyMediaComponent implements OnInit {

  media;
  page = 1;
  account_id;
  switchboard;

  constructor(
    private switchboardService: SwitchboardService,
    private mediaService: MediaService,
    private authService: LoginService,
  ) { }

  ngOnInit(): void {
    this.loadMedia();
  }


loadMedia() {
  this.authService.getCurrentAccount().subscribe(account => {
    this.account_id = account.id;
    this.mediaService.getUnverifiedMedia(this.account_id, this.page)
      .subscribe(mediaArray => {
        if (mediaArray.length > 0) {
          // Use forkJoin to wait for all additional data calls to complete
          const allMediaRequests = mediaArray.map(mediaItem => 
            this.getAdditionalData(mediaItem.account_id)
              .pipe(
                catchError(error => {
                  // Handle or log the error
                  console.error('Error fetching additional data', error);
                  return of(null); // Return null or a default value in case of error
                }),
                map(additionalData => ({
                  ...mediaItem, // Spread the original media item
                  additionalData // Add the additional data
                }))
              )
          );

          forkJoin(allMediaRequests).subscribe(completedMediaArray => {
            this.media = completedMediaArray;
            // Now this.media contains the original media items with the additional data
          });
        } else {
          this.media = [];
        }
      });
  });
}

getAdditionalData(accountId: number) {
  return this.switchboardService.getSwitchboardFromAccount(accountId).pipe(
    map(data => data || 0),
    catchError(error => {
      console.error('Error fetching additional data', error);
      return of(0); 
    })
  );
}


  changePage(page) {
      this.page =  page;
      this.loadMedia();
  }

  getSound(item) {
    item.loaded = true;
    this.loadFile(item);
  }

  loadFile(file) {
      const elementID = `media-audio-controller-${file.uuid}`;
      this.mediaService.downloadSwitchboardMedia(file.account_id, file)
          .subscribe(AudioHelper.loadFileToAudioElement(elementID));
  }

  accept(media) {
    this.mediaService.updateUnverifiedMedia(media.account_id, media.uuid, 1)
      .subscribe({
        next: (response) => {
          toastr.success(`Media Accepted`);
          this.loadMedia();
        },
        error: (error) => {
          toastr.error(`Error accepting media`);
          console.error(error);
        }
      });
  }

  deny(media) {
    this.mediaService.updateUnverifiedMedia(media.account_id, media.uuid, 2)
      .subscribe({
        next: (response) => {
          toastr.success(`Media Denied`);
          this.loadMedia();
        },
        error: (error) => {
          toastr.error(`Error denying media`);
          console.error(error);
        }
    });
  }

}
