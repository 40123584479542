import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { SwitchboardService } from '../../switchboard/switchboard.service';

import { ServerTrunk } from './server-trunk.model';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-server-trunk',
    templateUrl: './server-trunk.component.html',
    styleUrls: ['./server-trunk.component.css']
})
export class ServerTrunkComponent implements OnInit {

    trunks: ServerTrunk[];

    current_trunk: ServerTrunk = new ServerTrunk();

    @ViewChild('trunkDetail') trunkDetail;

    constructor(
        private switchboardService: SwitchboardService
    ) { }

    ngOnInit() {
        this.loadTrunks();
    }

    loadTrunks() {
        this.trunks = null;
        this.switchboardService.getServerTrunks()
            .subscribe(trunks => this.trunks = trunks);
    }

    newTrunk() {
        this.current_trunk = new ServerTrunk();
        this.trunkDetail.title = 'New Trunk';
        this.trunkDetail.open();
    }

    editTrunk(server) {
        this.current_trunk = server;
        this.trunkDetail.title = 'Edit Trunk';
        this.trunkDetail.open();
    }

    deleteTrunk(server) {
        alertify.confirm(`Are you sure you want to delete ${server.name}`, () => {
            this.switchboardService.deleteServerTrunk(server)
                .subscribe(_ => this.loadTrunks());
        });
    }

    saveTrunk() {
        let request;
        if (this.current_trunk.id) {
            request = this.switchboardService.updateServerTrunk(this.current_trunk);
        } else {
            request = this.switchboardService.createServerTrunk(this.current_trunk);
        }

        request.subscribe(response => {
            console.log(response);
            this.trunkDetail.loading = false;
            if (response + '') {
                this.trunkDetail.close();
                this.loadTrunks();
                toastr.success('Server Trunk Saved!');
            }
        });
    }

}
