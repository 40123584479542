<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/numbers">Numbers</a></li>
                <li class="breadcrumb-item active" aria-current="page">New Number</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">
                            New Number
                        </h2>
                    </div>
                </div>
            </div>
            <div class="card-body" *ngIf="!loading else generating">
                <!-- <label for="object-name">Name</label> -->
                <!-- <input id="object-name" type="text" class="form-control" placeholder="Give This a Name (e.g. 'My Number')" [(ngModel)]="object_name"> -->
                <label>Select Area Code</label>
                <div>
                    <div class="row">
                        <!-- <div class="col-lg-3 col-12">
                            <ng-select [items]="countryCodes.data" 
                                       bindLabel="name" 
                                       bindValue="code" 
                                       [(ngModel)]="country_code"
                                       (change)="getAreas()"
                                       >
                            </ng-select>
                        </div> -->
                        <span class="invisible-lg hidden-lg d-lg-none">&nbsp;</span>
                        <div class="col-lg-9 col-12">
                            <ng-select [items]="areas" 
                                       bindLabel="label" 
                                       bindValue="prefix" 
                                       [(ngModel)]="area"
                                       (change)="getNumbers()"
                                       >
                            </ng-select>
                        </div>
                    </div>

                    <br>
                    <div class="input-group-append" *ngIf="area">
                        <button class="btn btn-outline-primary" (click)="getNumbers()">Search</button>
                    </div>
                </div>

                <br>

                <ng-container *ngIf="numbers !== null else generating">
                    <app-tabs *ngIf="(numbers || memorable_numbers)">
                        <app-tab name="Regular Numbers" [isActive]="true">
                            <div class="card-columns customcard">
                                <ng-container *ngFor="let number of numbers">
                                    <div class="card" *ngIf="number">
                                        <div class="card-body darkcard">
                                            <h3 class="card-title">
                                                {{ number.area }} {{ number.number }}
                                                <ng-container *ngIf="getService(number) as service">
                                                    <br>
                                                    <div class="text-muted text-sm" *ngIf="service.service_id">
                                                        Free
                                                        <ng-container *appViperIsNotPostpay>
                                                            until {{ service.expires_at|date:'shortDate' }} then
                                                        </ng-container>
                                                    </div>
                                                    <span class="badge badge-pill badge-success" >
                                                         £{{ (service.service_id ? service?.service.retail_cost : service.retail_cost)|format_balance }}/month
                                                    </span>
                                                </ng-container>
                                            </h3>
                                            <button class="btn btn-primary" [attr.disabled]="!getService(number) || null" (click)="purchase(number)">Purchase</button>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="btn btn-lg btn-block btn-outline-warning text-lg" (click)="getNumbers()">More Numbers</div>
                        </app-tab>
                        <app-tab name="Memorable Numbers">
                            <input type="text" class="form-control" style="width: 50%; margin: 10px" placeholder="Search for a specific number" [(ngModel)]="searchTerm" (keyup)="getMemorableNumbers()">
                            <div class="card-columns">
                                <ng-container *ngFor="let number of memorable_numbers">
                                    <div class="card" *ngIf="number">
                                        <div class="card-body darkcard">
                                            <h3 class="card-title">
                                                {{ number.area }} {{ number.number }}
                                                <br>
                                                <ng-container *ngIf="getService(number) as service">
                                                    <div class="text-muted text-sm" *ngIf="service.service_id">
                                                        Free
                                                        <ng-container *appViperIsNotPostpay>
                                                            until {{ service.expires_at|date:'shortDate' }} then
                                                        </ng-container>
                                                    </div>
                                                    <span class="badge badge-pill badge-success" >
                                                         £{{ (service.service_id ? service?.service.retail_cost : service.retail_cost)|format_balance }}/month
                                                    </span>
                                                </ng-container>
                                            </h3>
                                            <button class="btn btn-primary" [attr.disabled]="!getService(number) || null" (click)="purchase(number)">Purchase</button>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </app-tab>
                        <app-tab name="Top 5 Numbers">
                            <ng-container *ngIf="top_numbers?.length">
                                <div class="card-columns">
                                    <ng-container *ngFor="let number of top_numbers">
                                        <div class="card" *ngIf="number">
                                            <div class="card-body darkcard">
                                                <h3 class="card-title">
                                                    {{ number.area }} {{ number.number }}
                                                    <br>
                                                    <ng-container *ngIf="getService(number) as service">
                                                        <div class="text-muted text-sm" *ngIf="service.service_id">
                                                            Free
                                                            <ng-container *appViperIsNotPostpay>
                                                                until {{ service.expires_at|date:'shortDate' }} then
                                                            </ng-container>
                                                        </div>
                                                        <span class="badge badge-pill badge-success" >
                                                             £{{ (service.service_id ? service?.service.retail_cost : service.retail_cost)|format_balance }}/month
                                                        </span>
                                                    </ng-container>
                                                </h3>
                                                <button class="btn btn-primary" [attr.disabled]="!getService(number) || null" (click)="purchase(number)">Purchase</button>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </app-tab>
                    </app-tabs>
                </ng-container>

                
            </div>
        </div>
    </div>
</div>

<ng-template #generating>
    <div class="card-body">
        <app-loadingbar></app-loadingbar>
    </div>
</ng-template>
