<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Verify Media</h2>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <!-- Table Header -->
                <table class="table align-items-center table-flush table-float-header table-striped">
                    <thead>
                        <tr>
                            <th>Account</th>
                            <th>State</th>
                            <th>Media Name</th>
                            <th>Status</th>
                            <th>File</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="media?.length; else no_media">
                        <ng-container *ngFor="let item of media">
                            <tr *ngIf="item.additionalData.account">
                                <td><a href='/accounts/detail/{{ item.account_id }}'>{{ item.account_id }}</a></td>
                                <td>{{ item.additionalData?.account?.active_state|formatAccountState  }}</td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.is_verified ? 'Verified' : 'Unverified' }}</td>
                                <td>
                                    <audio *ngIf="item.loaded" id="media-audio-controller-{{ item.uuid }}" controls controlsList="nodownload"></audio>
                                    <button *ngIf="!item.loaded" class="btn btn-success" (click)="getSound(item)" title="Play" target="_blank">
                                        <i class="fas fa-play"></i>
                                    </button>
                                </td>
                                <td>
                                    <button class="btn btn-success btn-sm" (click)="accept(item)">Approve</button>
                                    <button class="btn btn-danger btn-sm" (click)="deny(item)">Deny</button>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
            <!-- Pagination or other controls here -->
        </div>
    </div>
</div>

<ng-template #no_media>
    <tr>
        <td colspan="3">No Media to verify</td>
    </tr>
</ng-template>
