<div class="select">
    <select class="form-control" [(ngModel)]="model" (ngModelChange)="modelChange.emit(model)">
        <option [ngValue]="null" *ngIf="allow_none">[None]</option>
        <ng-container *ngFor="let type of objectTypes()">
            <optgroup [label]="type|alias" *ngIf="canShowType(type)">
                <ng-container *ngFor="let object of switchboard_objects">
                    <option value="{{ object[object_key] }}" *ngIf="type === object.object_type">
                        {{ object.object_data.name }}
                    </option>
                </ng-container>
            </optgroup>
        </ng-container>
        
    </select>
</div>
