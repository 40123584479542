import { NgModule } from '@angular/core';

// Core Modules
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// My Modules
import { PipesModule } from '../pipes/pipes.module';
import { ComponentsModule } from '../components/components.module';
import { LayoutModule } from '../layout/layout.module';

import { ServersRoutingModule } from './servers-routing.module';

// Components
import { InboundServerComponent } from './inbound-server/inbound-server.component';
import { OutboundServerComponent } from './outbound-server/outbound-server.component';
import { ServerTrunkComponent } from './server-trunk/server-trunk.component';
import { SwitchboardServerComponent } from './switchboard-server/switchboard-server.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        PipesModule,
        ComponentsModule,
        LayoutModule,
        ServersRoutingModule,
    ],
    declarations: [
        InboundServerComponent,
        OutboundServerComponent,
        ServerTrunkComponent,
        SwitchboardServerComponent,
    ],
    bootstrap: [],
    exports: [
        InboundServerComponent,
        OutboundServerComponent,
        ServerTrunkComponent,
        SwitchboardServerComponent,
    ]
})

export class ServersModule { }
