import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { LoginService } from '../login/login.service';
import { SwitchboardService } from '../switchboard/switchboard.service';

import { CountryCodes } from '../tools/country-codes';

@Component({
    selector: 'app-number-search',
    templateUrl: './number-search.component.html',
    styleUrls: ['./number-search.component.css']
})
export class NumberSearchComponent implements OnInit {

    readonly sid_base = 1000000;

    country_code = '';
    number_search = '';

    results_numbers;
    results_diverts;

    public countryCodes = CountryCodes.e164_prefixes;

    constructor(
        private authService: LoginService,
        private switchboardService: SwitchboardService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit() {
        // TODO
    }

    getFullNumber() {
        return this.country_code + this.number_search.replace(/^0/, '');
    }

    search() {
        const fullNumber = this.getFullNumber();
        this.switchboardService.adminSearchNumbers(fullNumber)
            .subscribe(numbers => this.results_numbers = numbers);
        this.switchboardService.adminSearchDivertNumbers(fullNumber)
            .subscribe(numbers => this.results_diverts = numbers);
    }

}
