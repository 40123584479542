import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, map, concatMap } from 'rxjs/operators';

import { LoginService } from './login.service';

import { AuthenticationHelper } from './authentication.helper';

declare var toastr: any;
declare var moment: any;

@Injectable({
    providedIn: 'root'
})
export class GuestGuard implements CanActivate {

    constructor (
        private authService: LoginService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    redirect() {
        const path = this.route.snapshot.queryParamMap.get('path') || '/';
        return this.router.createUrlTree([path]);
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        if (AuthenticationHelper.hasKey()) {
            return this.authService.getCurrentAccount().pipe(map(login => {
                if (login.id) {
                    return this.redirect();
                }

                AuthenticationHelper.wipeAllKeys();
                return true;
            }));
        }

        return true;
    }

}
