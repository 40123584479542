<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Account Settings</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Account Settings</h2>
                    </div>
                </div>
            </div>
            <div class="container">
                <ng-conatiner *ngIf="!loading else loading_view">
                    <div class="card-columns" style="column-count:1;">
                        <a class="card">
                            <div class="card-body darkcard">
                                <h3 style="color:white;font-weight:800;">Company Name:</h3>
                                <h4 style="color:white;">{{ account.company_name }}</h4>
                                <button style="width:100%;" class="btn btn-primary" (click)="rename()">Rename</button>
                            </div>
                        </a>
                    </div>
                    
                    <div class="card-columns">
                        <a class="card text-center" routerLink="/settings/users">
                            <div class="card-body darkcard">
                                <i class="fas fa-users"></i>
                                <h5 class="card-title">Users and Notifications</h5>
                            </div>
                        </a>

                        <a class="card text-center" routerLink="/settings/details">
                            <div class="card-body darkcard">
                                <i class="fa-solid fa-info"></i>
                                <h5 class="card-title">Customer Details</h5>
                            </div>
                        </a>


                        <a class="card text-center" routerLink="/settings/addresses">
                            <div class="card-body darkcard">
                                <i class="far fa-address-card"></i>
                                <h5 class="card-title">Addresses</h5>
                            </div>
                        </a>

                        <a class="card text-center" routerLink="/settings/services">
                            <div class="card-body darkcard">
                                <i class="fas fa-concierge-bell"></i>
                                <h5 class="card-title">Services</h5>
                            </div>
                        </a>
                    </div>
                    <div class="card-columns" style="column-count:1;">
                        <a class="card">
                            <div class="card-body darkcard">
                                <h3 style="color:white;font-weight:800;">Show callers' ID on call forwarding:</h3>
                                <div class="alert alert-info">
                                    When you receive a forwarded call, we will present the caller ID of the person calling you. If you could prefer to view your virtual number as the caller ID you can disable the setting below
                                </div>
                                <h4 style="color:white;">{{ switchboard_config.allow_untrusted_uk_divert_cli ? 'Enabled' : 'Disabled' }}</h4>
                                <button style="width:100%;" class="btn" [class.btn-success]="!switchboard_config.allow_untrusted_uk_divert_cli" [class.btn-danger]="switchboard_config.allow_untrusted_uk_divert_cli" (click)="toggleUntrustedCLI()">{{ switchboard_config.allow_untrusted_uk_divert_cli ? 'Disable' : 'Enable' }}</button>
                            </div>
                        </a>
                    </div>
                    <ng-container *ngIf="account.application_uuid != '193d2c39-ded5-49c4-899f-a3fa32b3916c'">
                        <div class="card-columns" style="column-count:1;">
                            <a class="card">
                                <div class="card-body darkcard" *ngIf="account.active_state !== cancelledState">
                                    Click the button below to cancel your account
                                    <button style="width:100%;" class="btn btn-danger" (click)="cancelAccount()">Cancel Account</button>
                                </div>
                                <div class="card-body darkcard" *ngIf="account.active_state === cancelledState">
                                    Your account is currently <strong>Cancelled</strong>
                                </div>
                            </a>
                        </div>
                    </ng-container>
                </ng-conatiner>
            </div>

        </div>
    </div>
</div>

<app-modal title="Rename Company" size="small" (save)="saveName()" #rename_modal>
    <input type="text" class="form-control" [(ngModel)]="new_name">
</app-modal>

<app-modal #cancelled_modal size="big">
    We are sorry to hear that you wish to cancel your account. 

    <ng-container *ngIf="app.short_name !== 'lv'">
        Take a look at our <a href="https://lovevoip.co.uk">sister company</a>
        <br>

        <a href="https://lovevoip.co.uk">
            <img src="/assets/images/lv-banner-ad.png" alt="LoveVoIP" style="width: 100%">
        </a>

        <br>
    </ng-container>

    <ng-container *ngIf="false && survey_link">
        Please complete the following brief feedback form to help us improve our services in the future.

        <br>
        <a class="btn btn-success" style="width: 100%; " href="{{ survey_link }}" target="_blank">Send Feedback (Opens in new tab)</a>
    </ng-container>
</app-modal>

<ng-template #loading_view>
    <app-loadingbar></app-loadingbar>
</ng-template>
