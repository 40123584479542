import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from '../login/authentication.guard';
import { RoleGuard } from '../sections/roles/role.guard';

import { VoicemailsComponent } from './voicemails/voicemails.component';
import { VoicemailComponent } from './voicemails/voicemail/voicemail.component';
import { CallRecordingsComponent } from './call-recordings/call-recordings.component';
import { PortalMusicComponent } from './portal-music/portal-music.component';


const routes: Routes = [
    { path: 'voicemail-inboxes', canActivate: [AuthenticationGuard, RoleGuard], component: VoicemailsComponent, data: {roles: ['Admin'], title: 'Voicemail Inboxes'} },
    { path: 'voicemail-inboxes/:page', canActivate: [AuthenticationGuard, RoleGuard], component: VoicemailsComponent, data: {roles: ['Admin'], title: 'Voicemail Inboxes'} },
    { path: 'voicemail-inboxes/detail/:id', canActivate: [AuthenticationGuard, RoleGuard], component: VoicemailComponent, data: {roles: ['Admin'], title: 'Voicemail Inbox'} },
    { path: 'voicemail-inbox', canActivate: [AuthenticationGuard], component: VoicemailComponent, data: {title: 'Voicemail Inbox'} },

    { path: 'call-recordings', canActivate: [AuthenticationGuard], component: CallRecordingsComponent, data: {title: 'Call Recordings'} },
    { path: 'call-recordings/:direction', canActivate: [AuthenticationGuard], component: CallRecordingsComponent, data: {title: 'Call Recordings'} },
    { path: 'call-recordings/:direction/:page', canActivate: [AuthenticationGuard], component: CallRecordingsComponent, data: {title: 'Call Recordings'} },
    { path: 'accounts/detail/:id/call-recordings', canActivate: [AuthenticationGuard, RoleGuard], component: CallRecordingsComponent, data: {roles: ['Admin'], title: 'Call Recordings'} },
    { path: 'accounts/detail/:id/call-recordings/:direction', canActivate: [AuthenticationGuard, RoleGuard], component: CallRecordingsComponent, data: {roles: ['Admin'], title: 'Call Recordings'} },
    { path: 'accounts/detail/:id/call-recordings/:direction/:page', canActivate: [AuthenticationGuard, RoleGuard], component: CallRecordingsComponent, data: {roles: ['Admin'], title: 'Call Recordings'} },

    { path: 'media', canActivate: [AuthenticationGuard], component: PortalMusicComponent, data: {title: 'Media'} },
    { path: 'media/:direction', canActivate: [AuthenticationGuard], component: PortalMusicComponent, data: {title: 'Media'} },
    { path: 'media/:direction/:page', canActivate: [AuthenticationGuard], component: PortalMusicComponent, data: {title: 'Media'} },
    { path: 'accounts/detail/:id/media', canActivate: [AuthenticationGuard, RoleGuard], component: PortalMusicComponent, data: {roles: ['Admin'], title: 'Media'} },
    { path: 'accounts/detail/:id/media/:direction', canActivate: [AuthenticationGuard, RoleGuard], component: PortalMusicComponent, data: {roles: ['Admin'], title: 'Media'} },
    { path: 'accounts/detail/:id/media/:direction/:page', canActivate: [AuthenticationGuard, RoleGuard], component: PortalMusicComponent, data: {roles: ['Admin'], title: 'Media'} },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AudioRoutingModule { }
