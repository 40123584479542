<div class="form-inline">
    <select class="form-control" [(ngModel)]="hour" (ngModelChange)="onModelChange()">
        <option value="00">00</option>
        <option value="01">01</option>
        <option value="02">02</option>
        <option value="03">03</option>
        <option value="04">04</option>
        <option value="05">05</option>
        <option value="06">06</option>
        <option value="07">07</option>
        <option value="08">08</option>
        <option value="09">09</option>
        <option value="10">10</option>
        <option value="11">11</option>
        <option value="12">12</option>
        <option value="13">13</option>
        <option value="14">14</option>
        <option value="15">15</option>
        <option value="16">16</option>
        <option value="17">17</option>
        <option value="18">18</option>
        <option value="19">19</option>
        <option value="20">20</option>
        <option value="21">21</option>
        <option value="22">22</option>
        <option value="23">23</option>
    </select>
    <select class="form-control" [(ngModel)]="minutes" (ngModelChange)="onModelChange()">
        <option value="00" *ngIf="0 % minutesInterval === 0">00</option>
        <option value="01" *ngIf="1 % minutesInterval === 0">01</option>
        <option value="02" *ngIf="2 % minutesInterval === 0">02</option>
        <option value="03" *ngIf="3 % minutesInterval === 0">03</option>
        <option value="04" *ngIf="4 % minutesInterval === 0">04</option>
        <option value="05" *ngIf="5 % minutesInterval === 0">05</option>
        <option value="06" *ngIf="6 % minutesInterval === 0">06</option>
        <option value="07" *ngIf="7 % minutesInterval === 0">07</option>
        <option value="08" *ngIf="8 % minutesInterval === 0">08</option>
        <option value="09" *ngIf="9 % minutesInterval === 0">09</option>
        <option value="10" *ngIf="10 % minutesInterval === 0">10</option>
        <option value="11" *ngIf="11 % minutesInterval === 0">11</option>
        <option value="12" *ngIf="12 % minutesInterval === 0">12</option>
        <option value="13" *ngIf="13 % minutesInterval === 0">13</option>
        <option value="14" *ngIf="14 % minutesInterval === 0">14</option>
        <option value="15" *ngIf="15 % minutesInterval === 0">15</option>
        <option value="16" *ngIf="16 % minutesInterval === 0">16</option>
        <option value="17" *ngIf="17 % minutesInterval === 0">17</option>
        <option value="18" *ngIf="18 % minutesInterval === 0">18</option>
        <option value="19" *ngIf="19 % minutesInterval === 0">19</option>
        <option value="20" *ngIf="20 % minutesInterval === 0">20</option>
        <option value="21" *ngIf="21 % minutesInterval === 0">21</option>
        <option value="22" *ngIf="22 % minutesInterval === 0">22</option>
        <option value="23" *ngIf="23 % minutesInterval === 0">23</option>
        <option value="24" *ngIf="24 % minutesInterval === 0">24</option>
        <option value="25" *ngIf="25 % minutesInterval === 0">25</option>
        <option value="26" *ngIf="26 % minutesInterval === 0">26</option>
        <option value="27" *ngIf="27 % minutesInterval === 0">27</option>
        <option value="28" *ngIf="28 % minutesInterval === 0">28</option>
        <option value="29" *ngIf="29 % minutesInterval === 0">29</option>
        <option value="30" *ngIf="30 % minutesInterval === 0">30</option>
        <option value="31" *ngIf="31 % minutesInterval === 0">31</option>
        <option value="32" *ngIf="32 % minutesInterval === 0">32</option>
        <option value="33" *ngIf="33 % minutesInterval === 0">33</option>
        <option value="34" *ngIf="34 % minutesInterval === 0">34</option>
        <option value="35" *ngIf="35 % minutesInterval === 0">35</option>
        <option value="36" *ngIf="36 % minutesInterval === 0">36</option>
        <option value="37" *ngIf="37 % minutesInterval === 0">37</option>
        <option value="38" *ngIf="38 % minutesInterval === 0">38</option>
        <option value="39" *ngIf="39 % minutesInterval === 0">39</option>
        <option value="40" *ngIf="40 % minutesInterval === 0">40</option>
        <option value="41" *ngIf="41 % minutesInterval === 0">41</option>
        <option value="42" *ngIf="42 % minutesInterval === 0">42</option>
        <option value="43" *ngIf="43 % minutesInterval === 0">43</option>
        <option value="44" *ngIf="44 % minutesInterval === 0">44</option>
        <option value="45" *ngIf="45 % minutesInterval === 0">45</option>
        <option value="46" *ngIf="46 % minutesInterval === 0">46</option>
        <option value="47" *ngIf="47 % minutesInterval === 0">47</option>
        <option value="48" *ngIf="48 % minutesInterval === 0">48</option>
        <option value="49" *ngIf="49 % minutesInterval === 0">49</option>
        <option value="50" *ngIf="50 % minutesInterval === 0">50</option>
        <option value="51" *ngIf="51 % minutesInterval === 0">51</option>
        <option value="52" *ngIf="52 % minutesInterval === 0">52</option>
        <option value="53" *ngIf="53 % minutesInterval === 0">53</option>
        <option value="54" *ngIf="54 % minutesInterval === 0">54</option>
        <option value="55" *ngIf="55 % minutesInterval === 0">55</option>
        <option value="56" *ngIf="56 % minutesInterval === 0">56</option>
        <option value="57" *ngIf="57 % minutesInterval === 0">57</option>
        <option value="58" *ngIf="58 % minutesInterval === 0">58</option>
        <option value="59">59</option>
    </select>
</div>
