import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { LoginService } from '../../login/login.service';
import { ServicesService } from '../../services/services.service';
import { SwitchboardService } from '../../switchboard/switchboard.service';

declare var alertify: any;

@Component({
    selector: 'app-portal-channels',
    templateUrl: './portal-channels.component.html',
    styleUrls: ['./portal-channels.component.css']
})
export class PortalChannelsComponent implements OnInit {

    readonly type = 'Channel';

    @Input() account_id;

    channels: any[];
    services: any[];
    channel_count;

    @ViewChild('new_channel_modal') channelModal;

    constructor(
        private authService: LoginService,
        private serviceService: ServicesService,
        private switchboardService: SwitchboardService,
    ) { }

    ngOnInit() {
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account_id = account.id;
            this.loadChannels();
            this.loadAvailableServices();
        });
    }

    loadChannels() {
        this.channels = null;
        this.serviceService.getServicesForAccount(this.account_id, this.type)
        .subscribe(channels => {
            this.channels = channels;
            this.channel_count = this.channels.reduce((t, channel) => t + channel.service.switchboard_quantity, 0);
        });
    }

    loadAvailableServices() {
        this.serviceService.getAvailableServices(this.account_id, this.type)
            .subscribe(services => this.services = services);
    }

    delete(channel) {
        alertify.confirm(`Are you sure you want to delete "${channel.service.name}"?`, () => {
            this.channels = null;
            this.serviceService.deleteServiceOnAccount(this.account_id, channel.id)
            .subscribe(serviceInstance => {
                this.switchboardService.commitAccount(this.account_id).subscribe();
                this.loadChannels();
            });
        });
    }

    purchase(service) {
        alertify.confirm(`Are you sure you want to buy "${service.name}"?`, () => {
            this.serviceService.addServiceToAccount(this.account_id, service.id)
            .subscribe(serviceInstance => {
                this.switchboardService.commitAccount(this.account_id).subscribe();
                this.loadChannels();
                this.channelModal.close();
            });
        });
    }

}
