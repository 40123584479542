import { Component, OnInit } from '@angular/core';

import { LoginService } from '../../../login/login.service';
import { BillingService } from '../../../billing.service';

declare var $: any;

@Component({
    selector: 'app-balance-card',
    templateUrl: './balance-card.component.html',
    styleUrls: ['./balance-card.component.css']
})
export class BalanceCardComponent implements OnInit {

    account;

    accountMeta;
    balance;

    constructor(
        private authService: LoginService,
        private billingService: BillingService,
    ) { }

    ngOnInit(): void {
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account = account;
            this.getBalance();
        });
    }

    loadPopover() {
        $(function () {
            $('[data-toggle="popover"]').popover({
                trigger: 'focus'
            });
        });
    }

    getBalance() {
        this.billingService.getBalance()
            .subscribe(balance => this.balance = +balance);
        this.authService.getAccountMeta(this.account.id)
        .subscribe(meta => {
            this.accountMeta = meta;
            this.loadPopover();
        });
    }

    public refresh() {
        this.getBalance();
    }

}
