<ng-container *ngIf="!uploading">
    <div class="dropzone" appDnd (fileDropped)="fileBrowseHandler($event)">
        <input type="file" id="fileDropRef" (change)="fileBrowseHandler($event.target.files)" #fileDropRef />
        <i class="fa-solid fa-upload"></i>
        <h3>Drag and drop a file here</h3>
        <h3>or</h3>
        <label for="fileDropRef">Browse for file</label>
        <div style="text-align:center;font-size:0.8em" *ngIf="uploadTo !== 'dno-blocklist'">
            We accept wav, mp3, mp4 and m4a file formats. Maximum file size 50mb.
        </div>
    </div>


    <p class="alert alert-secondary text-sm" style="font-size: 0.600rem;" *ngIf="uploadTo !== 'dno-blocklist'">
        By uploading this media, you agree that you have the explicit permission granted by the copyright or license holder to reproduce, distribute, display or perform the protected work.
    </p>
</ng-container>

<ng-container *ngIf="uploading">
    <app-loadingbar></app-loadingbar>
</ng-container>
