import { Component, OnInit } from '@angular/core';
import { StatusPageService } from '../../../status-page.service';

@Component({
    selector: 'app-status-notifications',
    templateUrl: './status-notifications.component.html',
    styleUrls: ['./status-notifications.component.css']
})
export class StatusNotificationsComponent implements OnInit {

    hasIncidents: boolean;
    hasMaintenance: boolean;

    constructor(
        private statusPageService: StatusPageService,
    ) { }

    ngOnInit(): void {
        this.getCurrentStatus();
    }

    getCurrentStatus() {
        this.statusPageService.incidents({
            sort: 'id',
            order: 'desc',
        }).subscribe(response => {
            const incidents = response.data.filter(incident => !incident.is_resolved);
            this.hasIncidents = this.hasIncidents || incidents.length > 0;
        });

        this.statusPageService.components({
            sort: 'id',
            order: 'desc',
        }).subscribe(response => {
            const components = response.data.filter(component => component.status !== 1);
            this.hasIncidents = this.hasIncidents || components.length > 0;
        });

        this.statusPageService.maintenance({
            sort: 'id',
            order: 'desc',
        }).subscribe(response => {
            const schedules = response.data.filter(schedule => schedule.status !== 2);
            this.hasMaintenance = this.hasMaintenance || schedules.length > 0;
        });
    }

}
