import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { BillingService } from '../../../billing.service';

@Component({
    selector: 'app-invoices-kashflow',
    templateUrl: './invoices-kashflow.component.html',
    styleUrls: ['./invoices-kashflow.component.css']
})
export class InvoicesKashflowComponent implements OnInit {

    @Input() account_id;

    invoices;

    constructor(
        private billingService: BillingService,
    ) { }

    ngOnInit(): void {
        this.getInvoices();
    }

    getInvoices() {
        this.billingService.getKashflowInvoices(this.account_id)
        .subscribe(invoices => {
            this.invoices = invoices;
            if (this.invoices.Customer) {
                this.invoices = [this.invoices];
            }
        });
    }

}
