
<div class="card-body" *ngIf="!hasMusicOnHold">
    <button class="btn btn-primary btn-block text-lg" *ngIf="service" (click)="purchaseMusicOnHold()">Purchase Music On Hold</button>
    <button class="btn btn-danger btn-block text-lg disabled" *ngIf="service === false" disabled>Coming Soon</button>
</div>
<div class="table-responsive">
    <app-tabs>
        <app-tab name="Media" [isActive]="true">
            <app-media [account_id]="account_id" #media_tab></app-media>
        </app-tab>
        <ng-container *ngIf="hasMusicOnHold">
            <app-tab name="My Music">
                <table class="table table-striped">
                    <thead class="thead">
                        <tr class="tr">
                            <th class="th textalignleft" [attr.colspan]="2">
                                <button class="btn btn-primary is-primary" (click)="upload_modal.open()">Upload New Music</button>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="tbody textalignleft">
                        <ng-container *ngIf="files else no_files">
                            <tr class="tr" *ngFor="let file of files">
                                <td class="td">
                                    <audio *ngIf="file.loaded" id="music-audio-controller-{{ file.uuid }}" controls controlsList="nodownload"></audio>
                                    <br>
                                    <button *ngIf="!file.loaded" class="btn btn-success" (click)="getSound(file)" title="Play" target="_blank">
                                        <i class="fas fa-play"></i>
                                    </button>
                                    &nbsp;
                                    <button *appViperHasUserRole="'Admin'" class="btn btn-primary" (click)="download(file)" title="Download" target="_blank">
                                        <i class="fas fa-download"></i>
                                    </button>
                                    &nbsp;
                                    <button class="btn btn-danger is-danger" (click)="delete(file)">Delete</button>
                                    <br>
                                    {{ file.name }}
                                    <button class="btn btn-sm btn-outline-secondary" (click)="new_name = file.name;current_file = file;rename_modal.open()">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                    <br>
                                    <span class="text-sm text-muted">{{ (file.created_at)|date:'medium' }}</span>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </app-tab>
            <app-tab name="Library">
                <table class="table table-striped">
                    <thead class="thead">
                        <tr class="tr">
                            <th class="th textalignleft" [attr.colspan]="2">
                                <button *appViperHasUserRole="'Admin'" class="btn btn-primary is-primary" (click)="upload_modal_library.open()">Upload New Music</button>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="tbody textalignleft">
                        <ng-container *ngIf="library else no_files">
                            <tr class="tr" *ngFor="let file of library">
                                <td class="td">
                                    <audio *ngIf="file.loaded" id="library-audio-controller-{{ file.uuid }}" controls controlsList="nodownload"></audio>
                                    <br>
                                    <button *ngIf="!file.loaded" class="btn btn-success" (click)="getLibrarySound(file)" title="Play" target="_blank">
                                        <i class="fas fa-play"></i>
                                    </button>
                                    &nbsp;
                                    <button class="btn btn-success" (click)="addToMusic(file)">
                                        <i class="fas fa-plus"></i>
                                        Add To My Music
                                    </button>
                                    <br>
                                    <br>
                                    <button *appViperHasUserRole="'Admin'" class="btn btn-primary" (click)="libraryDownload(file)" title="Download" target="_blank">
                                        <i class="fas fa-download"></i>
                                    </button>
                                    &nbsp;
                                    <button *appViperHasUserRole="'Admin'" class="btn btn-danger is-danger" (click)="libraryDelete(file)">Delete</button>
                                    <br>
                                    {{ file.name }}
                                    <button class="btn btn-sm btn-outline-secondary" *appViperHasUserRole="'Admin'" (click)="new_name = file.name;current_file = file;rename_library_modal.open()">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                    <br>
                                    <span class="text-sm text-muted">{{ (file.created_at)|date:'medium' }}</span>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </app-tab>
        </ng-container>
    </app-tabs>

</div>

<ng-template #media_disabled>
    <ng-container *ngIf="media_enabled === false else is_loading">
        <div class="alert alert-warning">
            Please top up your account before using media uploading.
        </div>
    </ng-container>
</ng-template>

<ng-template #no_files>
    <tr class="tr">
        <td class="td" colspan="1" *ngIf="files !== null else is_loading">No music found</td>
    </tr>
</ng-template>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>

<app-modal title="Change The Music File Name" size="small" (save)="rename(new_name)" #rename_modal>
    <input type="text" class="form-control" [(ngModel)]="new_name">
</app-modal>

<app-modal title="Change The Music File Name" size="small" (save)="libraryRename(new_name)" #rename_library_modal>
    <input type="text" class="form-control" [(ngModel)]="new_name">
</app-modal>

<app-modal title="Upload Music" #upload_modal>
    <ng-container>
        <div>
            This is where you store all of the music that you will use on your phone system, including music on hold and announcements. Once the media is here you can use it across your system. Use this to upload music or a pre recorded announcement, or create a new announcement using our powerful text to speech engine.
        </div>

        <hr>

        <app-media-upload-form [account_id]="account_id" (save)="uploadFile($event)" uploadTo="music" #upload_form></app-media-upload-form>
    </ng-container>
</app-modal>

<app-modal title="Upload Music" #upload_modal_library>
    <ng-container>
        <div>
            This is where you store all of the music that you will use on your phone system, including music on hold and announcements. Once the media is here you can use it across your system. Use this to upload music or a pre recorded announcement, or create a new announcement using our powerful text to speech engine.
        </div>

        <hr>

        <app-media-upload-form (save)="uploadLibraryFile($event)" uploadTo="library" #upload_form></app-media-upload-form>
    </ng-container>
</app-modal>
