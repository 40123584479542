import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {

    private messages: string[] = [
        'We are logging you in to your account now, please wait',
    ];

    message: string;

    constructor() { }

    ngOnInit() {
        this.setRandomMessage();
        setInterval(() => this.setRandomMessage(), 2000);
    }

    setRandomMessage() {
        this.message = this.messages[Math.floor(Math.random() * this.messages.length)];
    }

}
