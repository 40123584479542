<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Channels</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Channels</h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <ng-container *ngTemplateOutlet="list_channels"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #list_channels>
    <p>
        Channels are virtual telephone lines. 
    </p>
    <p>
        One channel can carry one inbound or outbound call.
        Channels are allocated dynamically, so they can be used for inbound or outbound use, and are account wide.
        The more calls you need to carry at a time, the more channels you will need on your account.
    </p>
    <p>
        You can add more channels below.
    </p>

    <h3> You have {{ channel_count }} {{ channel_count === 1 ? 'Channel' : 'Channels' }}</h3>

    <button class="btn btn-lg btn-block btn-outline-success text-lg" (click)="new_channel_modal.open()" *appViperHasUserRole="'Account Owner'">
        <i class="fas fa-plus "></i>
        Add More Channels
    </button>

    <div class="table-responsive">
        <table class="table is-bordered is-striped is-hoverable is-fullwidth">
            <thead>
                <tr>
                    <th>Channel Name</th>
                    <th>Channels</th>
                    <th>Cost</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="channels?.length else no_switchboard_objects">
                    <ng-container *ngFor="let channel of channels; let i = index">
                        <tr>
                            <td>{{ channel.service.name }}</td>
                            <td>{{ channel.service.switchboard_quantity }}</td>
                            <td>&pound;{{ channel.service.retail_cost|format_balance }}</td>
                            <td>
                                <button class="btn btn-danger" (click)="delete(channel)" *appViperHasUserRole="'Account Owner'">
                                    Delete
                                </button>
                            </td>
                        </tr>     
                    </ng-container>
                </ng-container>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #no_switchboard_objects>
    <tr>
        <td colspan="4">
            <ng-container *ngIf="channels?.length === 0 else loading">No Channels Found</ng-container>
        </td>
    </tr>
</ng-template>

<ng-template #loading>
    <app-loadingbar></app-loadingbar>
</ng-template>

<app-modal title="New Channel" #new_channel_modal>
    <table class="table align-items-center table-flush">
        <tbody *ngIf="services else no_services">
            <tr *ngFor="let service of services">
                <th style="padding:0.5rem;" scope="row">
                    <h3>£{{ service.retail_cost|format_balance }}</h3>
                </th>
                <td style="padding:0.5rem;">
                    <h4 style="white-space:normal;">{{ service.name }} ({{ service.switchboard_quantity }} channels)</h4>
                </td>
                <td style="padding:0.5rem;">
                    <button class="btn btn-primary" (click)="purchase(service)">Purchase</button>
                </td>
            </tr>
        </tbody>
    </table>
</app-modal>

<ng-template #no_services>
    <tr>
        <th>No channels available for purchase</th>
    </tr>
</ng-template>
