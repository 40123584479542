import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { RatesRoutingModule } from './rates-routing.module';

import { ComponentsModule } from '../components/components.module';
import { LayoutModule } from '../layout/layout.module';

import { RatesComponent } from './rates/rates.component';
import { IngressRatesComponent } from './ingress-rates/rates.component';
import { BundlesComponent } from './bundles/bundles.component';
import { SheetsComponent } from './sheets/sheets.component';
import { RateUpdateOverrideComponent } from './rate-update-override/rate-update-override.component';


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,

        LayoutModule,
        ComponentsModule,

        RatesRoutingModule,
    ],
    declarations: [
        RatesComponent,
        IngressRatesComponent,
        BundlesComponent,
        SheetsComponent,
        RateUpdateOverrideComponent,
    ],
    bootstrap: []
})

export class RatesModule { }
