import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { BillingService } from '../../../billing.service';
import { CoinprintService } from '../../coinprint.service';

declare var toastr: any;

@Component({
    selector: 'app-proforma-invoices',
    templateUrl: './proforma-invoices.component.html',
    styleUrls: ['./proforma-invoices.component.css']
})
export class ProformaInvoicesComponent implements OnInit {

    @Input() account_id;

    key;

    proformas;
    proforma: any;

    page = 1;

    datepickerdate;
    has_tax: boolean;

    @ViewChild('proforma_modal') proforma_modal;

    constructor(
        private billingService: BillingService,
        private coinprintService: CoinprintService,
    ) { }

    ngOnInit(): void {
        this.billingService.getCoinprintKey()
        .subscribe(key => {
            this.key = key;
            this.coinprintService.setKey(key);
            this.getProformas();
        });
    }

    getProformas(page = null) {
        if (page) {
            this.page = page;
        }
        this.coinprintService.getProformas(this.account_id, page)
            .subscribe(proformas => this.proformas = proformas);
    }

    newProforma() {
        this.proforma = {
            lines: [],
            tax_rate: 20,
            tax_name: null,
            due_at: null,
        };
        this.datepickerdate = '';
        this.has_tax = false;
        this.proforma_modal.open();
    }

    addLine() {
        this.proforma.lines.push({
            quantity: 1,
            unit_price: '0.00',
        });
    }

    removeLine(line) {
        this.proforma.lines.splice(this.proforma.lines.indexOf(line), 1);
    }

    onDateSelect(date_picker) {
        this.proforma.due_at = `${date_picker.year}-${date_picker.month}-${date_picker.day}`;
    }

    saveProforma() {
        const proforma = { ...this.proforma };
        if (this.has_tax) {
            proforma.tax_name = 'VAT';
            proforma.tax_rate /= 100.00;
        } else {
            delete proforma.tax_name;
            delete proforma.tax_rate;
        }
        this.coinprintService.createProforma(this.account_id, proforma)
        .subscribe(response => {
            if (response['id']) {
                this.getProformas();
                this.proforma_modal.close();
            } else {
                console.error('Error Creating Proforma', response);
                this.proforma_modal.stopLoading();
            }
        });
    }

    convert(proforma) {
        this.coinprintService.convertProforma(proforma)
        .subscribe(() => {
            this.getProformas();
            toastr.success('Invoice created!');
        });
    }

}
