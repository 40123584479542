import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../environments/environment';

import { Observable, of } from 'rxjs';
import { catchError, map, tap, concatMap } from 'rxjs/operators';

import { Client } from './tools/client';

import { LoginService } from './login/login.service';

function downloadAsCSV(csvdata, fileName) {
    const blob = new Blob([csvdata], { type: 'text/csv'});
    const url = window.URL.createObjectURL(blob);
    const link = window.document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
}

@Injectable({
    providedIn: 'root'
})
export class BillingService extends Client {
    protected serviceURL = environment.services.billing;

    constructor(
        http: HttpClient,
        private authService: LoginService,
    ) {
        super(http);
    }

    /* Balance */

    getBalance() {
        return this.authService.getCurrentUser()
            .pipe(concatMap(user => this.getBalanceForAccount(user.account_id)));
    }

    getBalanceForAccount(account_id) {
        return this.callApi('get', `/accounts/${account_id}/balance`)
        .pipe(
            tap(_ => this.log('fetching account balance for account ' + account_id)),
            catchError(this.handleError('getBalanceForAccount', []))
        );
    }

    /* Transaction */

    getTransactions(page = 1) {
        return this.callApi('get', `/transactions`, {page: page})
        .pipe(
            tap(_ => this.log('fetching transactions ')),
            catchError(this.handleError('getTransactions', []))
        );
    }

    getTransactionsForAccount(account_id, page = 1) {
        return this.callApi('get', `/accounts/${account_id}/transactions`, {page: page})
        .pipe(
            tap(_ => this.log('fetching transactions for account ' + account_id)),
            catchError(this.handleError('getTransactionsForAccount', []))
        );
    }

    createTransactionForAccount(account_id, transaction) {
        return this.callApi('post', `/accounts/${account_id}/transactions`, transaction)
        .pipe(
            tap(_ => this.log('creating transaction for account ' + account_id)),
            catchError(this.handleError('createTransactionForAccount', []))
        );
    }

    /* Braintree */

    getBraintreeHistory(account_id, page = 1) {
        return this.callApi('get', `/accounts/${account_id}/payments/braintree/history`, { page: page })
        .pipe(
            tap(_ => this.log(`get braintree card history for account ${account_id}`)),
            catchError(this.handleError('getBraintreeHistory', []))
        );
    }

    getBraintreeGlobalHistory(page = 1) {
        return this.callApi('get', `/payments/braintree/history`, { page: page })
        .pipe(
            tap(_ => this.log(`get braintree card history for everyone`)),
            catchError(this.handleError('getBraintreeGlobalHistory', []))
        );
    }

    toggleCardHistory(card_history) {
        return this.callApi('put', `/accounts/${card_history.account_id}/payments/braintree/history/${card_history.id}`, {
            ignore_record: +!card_history.ignore_record,
        })
        .pipe(
            tap(_ => this.log(`toggle card history ${card_history.id} for account ${card_history.account_id}`)),
            catchError(this.handleError('toggleCardHistory', []))
        );
    }

    makeBraintreePayment(account_id, request) {
        return this.callApi('post', `/accounts/${account_id}/payments/braintree/transaction`, request);
    }

    makeBraintreeInvoicePayment(account_id, request) {
        return this.callApi('post', `/accounts/${account_id}/payments/braintree/invoice_transaction`, request)
        // .pipe(
        //     tap(_ => this.log(`invoice payment for ${account_id}`)),
        //     catchError(this.handleError('makeBraintreeInvoicePayment', 'FAIL'))
        // );
    }

    generateBraintreeToken(account_id, recaptcha_token) {
        return this.callApi('post', `/accounts/${account_id}/payments/braintree/token`, {
            'recaptcha_token': recaptcha_token,
        })
        .pipe(
            tap(_ => this.log(`generate braintree token for ${account_id}`)),
            catchError(this.handleError('generateBraintreeToken', []))
        );
    }

    getPaymentMethods(account_id) {
        return this.callApi('get', `/accounts/${account_id}/payments/braintree/payment_methods`)
        .pipe(
            tap(_ => this.log(`get braintree card history for account ${account_id}`)),
            catchError(this.handleError('getPaymentMethods', []))
        );
    }

    setDefaultPaymentMethod(account_id, methodToken) {
        return this.callApi('put', `/accounts/${account_id}/payments/braintree/payment_methods/${methodToken}`)
        .pipe(
            tap(_ => this.log(`get braintree card history for account ${account_id}`)),
            catchError(this.handleError('setDefaultPaymentMethod', []))
        );
    }

    deletePaymentMethod(account_id, methodToken) {
        return this.callApi('delete', `/accounts/${account_id}/payments/braintree/payment_methods/${methodToken}`)
        .pipe(
            tap(_ => this.log(`get braintree card history for account ${account_id}`)),
            catchError(this.handleError('setDefaultPaymentMethod', []))
        );
    }

    getBraintreeTransactionAmount(from = null, to = null) {
        let data = {};

        if (from && to) {
            data = {
                start_time_range_start: `${from.year}-${from.month}-${from.day}`,
                start_time_range_end: `${to.year}-${to.month}-${to.day}`,
            };
        }

        return this.callApi('get', `/payments/braintree/history/total`, data)
        .pipe(
            tap(_ => this.log(`get braintree transaction amount per day`)),
            catchError(this.handleError('getBraintreeTransactionAmount', []))
        );
    }

    /* Kashflow */

    getKashflowInvoices(account_id) {
        return this.callApi('get', `/accounts/${account_id}/invoices/kashflow`)
        .pipe(
            tap(_ => this.log(`get kashflow invoices account ${account_id}`)),
            catchError(this.handleError('getKashflowInvoices', []))
        );
    }

    /* Coinprint */

    getCoinprintInvoices(account_id, page = 1) {
        return this.callApi('get', `/accounts/${account_id}/invoices/coinprint`, { page })
        .pipe(
            tap(_ => this.log(`get coinprint invoices account ${account_id}`)),
            catchError(this.handleError('getCoinprintInvoices', []))
        );
    }

    getCoinprintInvoice(account_id, invoice_id) {
        return this.callApi('get', `/accounts/${account_id}/invoices/coinprint/${invoice_id}`)
        .pipe(
            tap(_ => this.log(`get coinprint invoice account ${account_id}`)),
            catchError(this.handleError('getCoinprintInvoice', []))
        );
    }

    getCoinprintKey() {
        return this.callApi('get', `/app/coinprint_key`)
        .pipe(
            tap(_ => this.log(`get coinprint key`)),
            catchError(this.handleError('getCoinprintKey', []))
        );
    }

    /* Test Credit */

    checkTestCredit(account_id, callback = null) {
        return this.getTestCredit(account_id);
    }

    getTestCredit(account_id) {
        return this.callApi('post', `/accounts/${account_id}/test_credit`)
        .pipe(
            tap(_ => this.log(`get test credit for account ${account_id}`)),
            catchError(this.handleError('getTestCredit', []))
        );
    }

    getAccountPreferences() {
        return this.authService.getCurrentUser()
            .pipe(concatMap(user => this.getSpecificAccountPreferences(user.account_id)));
    }

    /* Invoice adjustments */

    getInvoiceAdjustments(account_id, page = 1) {
        return this.callApi('get', `/accounts/${account_id}/invoice_adjustments`, { page: page })
        .pipe(
            tap(_ => this.log(`get invoice adjustments for account ${account_id}`)),
            catchError(this.handleError('getInvoiceAdjustments', []))
        );
    }

    createInvoiceAdjustment(account_id, adjustment) {
        return this.callApi('post', `/accounts/${account_id}/invoice_adjustments`, adjustment)
        .pipe(
            tap(_ => this.log(`create invoice adjustment for account ${account_id}`)),
            catchError(this.handleError('createInvoiceAdjustment', []))
        );
    }

    deleteInvoiceAdjustment(account_id, adjustment) {
        return this.callApi('delete', `/accounts/${account_id}/invoice_adjustments/${adjustment.uuid}`)
        .pipe(
            tap(_ => this.log(`delete invoice adjustment for account ${account_id}`)),
            catchError(this.handleError('deleteInvoiceAdjustment', []))
        );
    }

    getRecurringInvoiceAdjustments(account_id, page = 1) {
        return this.callApi('get', `/accounts/${account_id}/recurring_invoice_adjustments`, { page: page })
        .pipe(
            tap(_ => this.log(`get recurring invoice adjustments for account ${account_id}`)),
            catchError(this.handleError('getRecurringInvoiceAdjustments', []))
        );
    }

    createRecurringInvoiceAdjustment(account_id, adjustment) {
        return this.callApi('post', `/accounts/${account_id}/recurring_invoice_adjustments`, adjustment)
        .pipe(
            tap(_ => this.log(`create recurring invoice adjustment for account ${account_id}`)),
            catchError(this.handleError('createRecurringInvoiceAdjustment', []))
        );
    }

    deleteRecurringInvoiceAdjustment(account_id, adjustment) {
        return this.callApi('delete', `/accounts/${account_id}/recurring_invoice_adjustments/${adjustment.uuid}`)
        .pipe(
            tap(_ => this.log(`delete recurring invoice adjustment for account ${account_id}`)),
            catchError(this.handleError('deleteRecurringInvoiceAdjustment', []))
        );
    }

    /* Direct Debits */

    getDirectDebits(account_id, page = 1) {
        return this.callApi('get', `/accounts/${account_id}/direct_debits`, { page: page })
        .pipe(
            tap(_ => this.log(`get direct debit for account ${account_id}`)),
            catchError(this.handleError('getDirectDebits', []))
        );
    }

    createDirectDebit(account_id, direct_debit) {
        return this.callApi('post', `/accounts/${account_id}/direct_debits`, direct_debit)
        .pipe(
            tap(_ => this.log(`create direct debit for account ${account_id}`)),
            catchError(this.handleError('createDirectDebit', []))
        );
    }

    updateDirectDebit(account_id, direct_debit) {
        return this.callApi('put', `/accounts/${account_id}/direct_debits/${direct_debit.uuid}`, direct_debit)
        .pipe(
            tap(_ => this.log(`create direct debit for account ${account_id}`)),
            catchError(this.handleError('createDirectDebit', []))
        );
    }

    deleteDirectDebit(account_id, direct_debit) {
        return this.callApi('delete', `/accounts/${account_id}/direct_debits/${direct_debit.uuid}`)
        .pipe(
            tap(_ => this.log(`delete direct debit for account ${account_id}`)),
            catchError(this.handleError('deleteDirectDebit', []))
        );
    }

    /* Direct Debit Exports */

    getDirectDebitExports(page = 1) {
        return this.callApi('get', `/direct_debits/exports`, { page: page })
        .pipe(
            tap(_ => this.log(`get direct debit exports`)),
            catchError(this.handleError('getDirectDebitExports', []))
        );
    }

    downloadDirectDebitExport(dd_export) {
        return this.downloadResourcePath(`/direct_debits/exports/${dd_export.uuid}`)
        .subscribe(csv => {
            downloadAsCSV(csv, `direct-debit-export-${dd_export.created_at}.csv`);
        });
    }

    getDirectDebitPayerExports(page = 1) {
        return this.callApi('get', `/direct_debits/payer_exports`, { page: page })
        .pipe(
            tap(_ => this.log(`get direct debit payer exports`)),
            catchError(this.handleError('getDirectDebitPayerExports', []))
        );
    }

    downloadDirectDebitPayerExport(dd_export) {
        return this.downloadResourcePath(`/direct_debits/payer_exports/${dd_export.uuid}`)
        .subscribe(csv => {
            downloadAsCSV(csv, `direct-debit-export-${dd_export.created_at}.csv`);
        });
    }
}
