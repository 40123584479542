import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { ServicesService } from '../services.service';
import { RatesService } from '../../rates/rates.service';

import { PriceHelper } from '../../tools/price.helper';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-service',
    templateUrl: './service.component.html',
    styleUrls: ['./service.component.css']
})
export class ServiceComponent implements OnInit {

    @Input() service;
    @ViewChild('attachSubserviceModal') subserviceModal;
    @ViewChild('bundleModal') bundleModal;

    private service_id;
    private subservices;
    private availableSubservices;
    private attachedSubservice = null;
    private bundles;
    private availableBundles;
    private attachedBundle = null;

    constructor(
        private route: ActivatedRoute,
        private servicesService: ServicesService,
        private ratesService: RatesService,
        private router: Router
    ) { }

    ngOnInit() {
        this.service_id = +this.route.snapshot.paramMap.get('id');
        this.getService();
        this.servicesService.getSubServices()
            .subscribe(subservices => this.availableSubservices = subservices);
        this.ratesService.getBundles()
            .subscribe(bundles => this.availableBundles = bundles);
        this.getSubServices();
        this.getServiceBundles();
    }

    getService() {
        this.servicesService.getService(this.service_id)
        .subscribe(service => {
            this.service = service;
            PriceHelper.fieldToPounds(this.service, 'retail_cost');
        });
    }

    getSubServices() {
        this.subservices = null;
        this.servicesService.getSubServiceForService(this.service_id)
            .subscribe(subservices => this.subservices = subservices);
    }

    getServiceBundles() {
        this.getService();
        return; // TODO : Remove when getServiceBundles() exists
        this.bundles = null;
        this.servicesService.getServiceBundles(this.service_id)
            .subscribe(bundles => this.bundles = bundles);
    }

    goBack(): void {
        this.router.navigate([`/services`]);
    }

    saveService() {
        const service = { ...this.service };
        service.retail_cost *= 100;
        this.servicesService.updateService(service)
            .subscribe(out => toastr.success('Service saved!'));
    }

    deleteService() {
        alertify.confirm(`Are you sure you want to delete ${this.service.company_name}?`, () => {
            this.servicesService.deleteService(this.service)
            .subscribe(out => {
                toastr.info('Deleting service!');
                this.goBack();
            });
        });
    }

    subserviceDialog() {
        this.attachedSubservice = null;
        this.subserviceModal.open();
    }

    notAttachedSubservice(subservice) {
        return this.subservices && this.subservices.map(s => s.id).indexOf(subservice.id) === -1;
    }

    attachSubservice() {
        if (!this.attachedSubservice) {
            toastr.error('Subservice required');
            this.subserviceModal.close();
            return false;
        }
        this.servicesService.attachSubService(this.service_id, this.attachedSubservice)
        .subscribe(response => {
            if (response.length) {
                toastr.success('Subservice attached!');
                this.getSubServices();
            }
            this.subserviceModal.close();
        });
    }

    detatchSubservice(subservice) {
        alertify.confirm('Are you sure you want to detatch this subservice?', () => {
            subservice.deleted = true;
            this.servicesService.detatchSubService(this.service_id, subservice.id)
            .subscribe(response => {
                toastr.success('Subservice detached!');
                this.getSubServices();
            });
        });
    }

    notAttachedBundle(bundle) {
        const bundles = this.service.bundles;
        return bundles && bundles.map(b => b.id).indexOf(bundle.id) === -1;
    }

    attachBundle() {
        if (!this.attachedBundle) {
            toastr.error('Bundle required');
            this.bundleModal.close();
            return false;
        }
        this.servicesService.attachServiceBundle(this.service_id, this.attachedBundle)
        .subscribe(response => {
            if (response.length) {
                toastr.success('Bundle attached!');
                this.getServiceBundles();
            }
            this.bundleModal.close();
        });
    }

    detatchBundle(bundle) {
        alertify.confirm('Are you sure you want to detatch this bundle?', () => {
            bundle.deleted = true;
            this.servicesService.detachServiceBundle(this.service_id, bundle.id)
            .subscribe(response => {
                toastr.success('Bundle detached!');
                this.getServiceBundles();
            });
        });
    }

}
