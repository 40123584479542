import { Component, OnInit, Input } from '@angular/core';

import { LoginService } from '../../login/login.service';
import { SwitchboardService } from '../../switchboard/switchboard.service';

import { LocationHelper } from '../../location.helper';
import { ClipboardHelper } from '../../tools/clipboard.helper';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

    readonly sid_start: number = 1000000;

    @Input() roles;
    account;
    config;
    user;
    app;

    supportUrl;
    auth_code: any;

    sips;
    switchboard_object;

    view_financial = false;

    constructor(
        private loginService: LoginService,
        private switchboardService: SwitchboardService,
    ) { }

    ngOnInit() {
        this.supportUrl = LocationHelper.getSupport();
        this.loginService.getCurrentAccount()
        .subscribe(account => {
            this.account = account;
            this.loginService.getAccountConfig(account.id)
                .subscribe(config => this.config = config);
            this.loginService.getAccountSupportAuth(this.account.id)
                .subscribe(auth => this.auth_code = auth.auth_code);
            this.switchboardService.readSipsOnAccount(this.account.id)
                .subscribe(sips => this.sips = sips);
        });
        this.loginService.getCurrentUser()
            .subscribe(user => this.user = user);
        this.loginService.getCurrentApp()
            .subscribe(app => {
                this.app = app;
                this.checkRoles(); 
            });
    }
    

    logout() {
        this.loginService.logout();
    }

    supportCodeToClipboard() {
        ClipboardHelper.addToClipboard(this.auth_code);
    }

    checkRoles() {
        if (this.user && this.user.roles) {
          // Check if any of the user's roles match 'Account Owner' or 'Admin'
          const hasRelevantRole = this.user.roles.some(role => 
            role.name === 'Account Owner' || role.name === 'Admin'
          );
      
          if (this.app.short_name != "vv" || hasRelevantRole) {
            this.view_financial = true;
          }
        }
      }  

}
