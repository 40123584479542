import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { ReCaptchaV3Service } from 'ng-recaptcha';

import { environment } from '../../../../environments/environment';

import { LoginService } from '../../../login/login.service';
import { BillingService } from '../../../billing.service';

declare var braintree: any;
declare var toastr: any;
declare var grecaptcha: any;

@Component({
    selector: 'app-topup',
    templateUrl: './topup.component.html',
    styleUrls: ['./topup.component.css']
})
export class TopupComponent implements OnInit {

    app;
    account_id: number;
    invoice: any;

    hasBilling: boolean;

    current_amount: number;
    braintree_token: string;
    braintree_instance = null;
    braintree_nonce: string;

    payment_success = null;

    prices: number[] = [1000, 1500, 2500, 5000];

    @ViewChild('dashboard_component') dashboard;

    constructor(
        private authService: LoginService,
        private billingService: BillingService,
        private recaptchaService: ReCaptchaV3Service,
        private activatedRoute: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account_id = account.id;
            this.authService.getAccountConfig(account.id)
                .subscribe(config => this.hasBilling = !!config.billing_address_id);
            this.getInvoice();
            this.billingService.getBraintreeHistory(account.id)
            .subscribe(
                response => this.processBraintreeHistory(response),
                error => console.error('Error fetching Braintree history:', error)
            );
        });
        this.authService.getCurrentApp().subscribe(app => this.app = app);
    }

    getInvoice() {
        const invoice_id = this.activatedRoute.snapshot.paramMap.get('invoice_id');
        if (invoice_id) {
            this.billingService.getCoinprintInvoice(this.account_id, invoice_id)
            .subscribe(invoice => {
                this.invoice = invoice;
                const outstanding = Math.round(invoice.final_total * 100) - Math.round(invoice.paid_amount * 100)
                if (outstanding) {
                    this.current_amount = outstanding;
                    this.generateToken();
                }
            });
        }
    }

    reset() {
        this.current_amount = 0;
        this.braintree_token = null;
        this.braintree_instance = null;
        this.braintree_nonce = null;
        this.payment_success = null;
        this.getInvoice();
    }

    generateToken() {
        this.recaptchaService.execute('submit')
        .subscribe(recaptcha_token => {
            this.billingService.generateBraintreeToken(this.account_id, recaptcha_token)
            .subscribe(token => {
                if (token) {
                    this.braintree_token = token;
                    this.initDropinContainer();
                }
            });
        });
    }

    get3DSecureParameters() {
        return {
            amount: this.current_amount / 100,
        };
    }

    initDropinContainer() {
        this.braintree_instance = false;
        braintree.dropin.create({
            authorization: this.braintree_token,
            container: '#dropin-container',
            vaultManager: true,
            threeDSecure: true,
        }, (createErr, instance) => {
            this.braintree_instance = instance;
        });
    }

    useCard() {
        const instance = this.braintree_instance;
        this.braintree_instance = null;
        instance.requestPaymentMethod({
            threeDSecure: this.get3DSecureParameters(),
        }, (err, payload) => {
            if (payload) {
                if (payload.liabilityShifted || payload.type !== 'CreditCard') {
                    this.braintree_nonce = payload.nonce;
                    this.makePayment();
                } else {
                    if (payload.binData.prepaid === 'Yes') {
                        toastr.error('We cannot accept a payment from your prepaid card, please use another payment method');
                    } else {
                        toastr.error('Unable to complete 3D secure verification');
                    }
                    instance.teardown();
                    this.billingService.getPaymentMethods(this.account_id)
                    .subscribe(methods => {
                        for (let card of methods) {
                            if (card.last4 === payload.details.lastFour) {
                                this.billingService.deletePaymentMethod(this.account_id, card.token)
                                    .subscribe(() => {
                                        this.generateToken();
                                    });
                                return true;
                            }
                        }
                        console.error('No such card???');
                    });
                }
            } else {
                this.braintree_instance = instance;
            }
        });
    }

    makePayment() {
        let request;
        if (this.invoice) {
            request = this.billingService.makeBraintreeInvoicePayment(this.account_id, {
                invoice_id: this.invoice.id,
                payment_method_nonce: this.braintree_nonce,
                amount: this.current_amount
            });
        } else {
            request = this.billingService.makeBraintreePayment(this.account_id, {
                payment_method_nonce: this.braintree_nonce,
                amount: this.current_amount
            });
        }
 
        request.subscribe(
            response => {
                this.payment_success = true;
                this.dashboard.getBalance();
            },
            response => {
                this.payment_success = response.error;
                this.dashboard.getBalance();
            }
        );

        
    }

    processBraintreeHistory(response: any) {
        for (const transaction of response.data) {
            if (transaction.processor_response_code == '2099') {
                this.addPrice(500);
                break; // Exit loop after finding the first occurrence
            }
        }
    }
    
    addPrice(price: number) {
        if (!this.prices.includes(price)) {
            this.prices.unshift(price); // Add 500 to the beginning of the array
        }
    }

}
