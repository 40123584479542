import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { LoginService } from '../../login/login.service';

import { RoleHelper } from './role.helper';

import { User } from '../../accounts/user';

@Component({
    selector: 'app-roles',
    templateUrl: './roles.component.html',
    styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {

    user: User = new User();

    allRoles: any[];
    assignableRoles: any[];
    roles: any[];

    account;

    user_roles;

    @ViewChild('rolesModal') rolesModal;

    constructor(
        private authService: LoginService
    ) { }

    ngOnInit() {
        this.authService.getRolesForCurrentUser()
            .subscribe(roles => this.user_roles = roles);
        this.authService.getPossibleRoles().subscribe(roles => {
            this.allRoles = roles;
            this.assignableRoles = this.allRoles.filter(role => this.canAssign(role));
        });
    }

    canAssign(role) {
        // const accountIsSpecial = this.user.account && this.user.account.requiredProperty;

        const dependsOnSelf = role.grant_depends_on_self && RoleHelper.hasRoleID(this.user_roles, role.id);
        const dependsOnRole = role.grant_depends_on_role && RoleHelper.hasRoleID(this.user_roles, role.grant_depends_on_role);
        return dependsOnSelf || dependsOnRole;
        // return hasRequiredProperty && (dependsOnSelf || dependsOnRole);
    }

    getRoles() {
        this.roles = null;
        this.allRoles.forEach(role => delete role.disabled);
        this.authService.getRolesForUser(this.user).subscribe(roles => this.roles = roles);
    }

    manageRoles(user) {
        this.user = user;
        this.authService.getAccount(user.account_id).subscribe(account => {
            this.account = account;
            if (this.account.special === 1) {
                this.assignableRoles = this.allRoles;
            } else {
                this.assignableRoles = this.allRoles.filter(role => role.id === 2);
            }
            this.getRoles();
            this.rolesModal.open();
        });
      }
    

    roleExists(role) {
        return RoleHelper.hasRoleID(this.roles, role.id);
    }

    addRole(role) {
        role.disabled = true;
        this.authService.giveRoleToUser(this.user, role).subscribe(() => this.getRoles());
    }

    removeRole(role) {
        role.disabled = true;
        this.authService.removeRoleFromUser(this.user, role).subscribe(() => this.getRoles());
    }

}
