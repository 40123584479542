<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/voicemails">Voicemail Boxes</a></li>
                <li class="breadcrumb-item active" aria-current="page">Edit Voicemail Box</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">
                            Voicemail Box
                            <span *ngIf="switchboard_object">
                                {{ switchboard_object.object_data.name }}
                                -
                                <span class="text-muted text-sm" data-toggle="tooltip" data-placement="bottom" title="Every part of your system has an extension number that you can call directly using our VoIP service, or transfer a call to">
                                    Extension Number {{ switchboard_object.extension_number }}
                                    <button class="btn btn-sm btn-outline-secondary" (click)="rename_modal.open()">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                </span>
                            </span>

                        </h2>
                    </div>
                </div>
            </div>
            <div class="card-body" *ngIf="switchboard_object else loading">
                <ng-container *ngTemplateOutlet="switchboard_object_edit"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #switchboard_object_edit>
    <div *ngIf="parent" class="btn btn-lg btn-block btn-outline-info text-lg" (click)="backToParent()">
        <i class="far fa-hand-point-left"></i> Go Back
        <br>
        <span class="text-muted text-sm">
            <i [class]="parent.object_type|capitalize|switchboard_object_icon" [title]="parent.object_type"></i>
            {{ parent.object_data.name }}
        </span>
    </div>

    <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg" (click)="media_component.open()">
        <i class="far fa-file-audio"></i> Announcement
        <br>
        <div class="text-muted text-sm">
            Using: {{ media_component.getMediaFile()?.name || '[No Media]' }}
        </div>
    </div>
    <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg" (click)="new_email = switchboard_object.object_data.email;email_modal.open()">
        <i class="far fa-envelope-open"></i> Email Address
        <br>
        <div class="text-muted text-sm">
            {{ switchboard_object.object_data.email }}
        </div>
    </div>
    <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg" (click)="subscribers_modal.open()">
        <i class="fas fa-user-friends"></i> VoIP Users subscribed to this mailbox
        <div class="text-muted text-sm">
            {{ switchboard_object.object_data.subscribers.length }} subscribers
        </div>
    </div>

    <app-portal-component-delete-switchboard-object [account_id]="account_id" [switchboard_object]="switchboard_object"></app-portal-component-delete-switchboard-object>
</ng-template>

<app-portal-component-rename-switchboard-object [account_id]="account_id" [switchboard_object]="switchboard_object" #rename_modal></app-portal-component-rename-switchboard-object>

<app-portal-component-media-select tts_text="Thank you for calling, unfortunately we can't take your call right now so please leave your message after the tone" [account_id]="account_id" [switchboard_object]="switchboard_object" #media_component></app-portal-component-media-select>

<app-modal title="Set Email" size="small" (save)="saveEmail()" #email_modal>
    <p>Add an email address to email any incoming voicemails - you can only use 1 email address per mail box</p>
    <input class="form-control" [(ngModel)]="new_email">
</app-modal>

<app-modal title="Edit Subscribers" #subscribers_modal>
    <div class="table-responsive">
        <table class="table align-items-center table-flush" *ngIf="switchboard_object">
            <tr *ngFor="let sip of switchboard_object.object_data.subscribers">
                <td>{{ sip.name }} ({{ sip.switchboard_object.extension_number }})</td>
                <td><a class="btn btn-secondary" routerLink="/sips/{{ sip.uuid }}">Edit</a></td>
                <td><div class="btn btn-danger" (click)="unsubscribe(sip)">Unsubscribe</div></td>
            </tr>
        </table>
    </div>
    <div class="input-group">
        <select class="form-control" [(ngModel)]="subscription_sip" [disabled]="subscription_sip === 'disabled'">
            <ng-container *ngFor="let sip of sips">
                <option value="{{ sip.uuid }}" *ngIf="notSubscribed(sip)">
                    {{ sip.extension_number }} - {{ sip.object_data.name }}
                </option>
            </ng-container>
        </select>
        <div class="input-group-append">
            <button class="btn btn-primary" type="button" (click)="subscribe()">Select</button>
        </div>
    </div>
</app-modal>

<ng-template #loading>
    <div class="card-body">
        <app-loadingbar></app-loadingbar>
    </div>
</ng-template>
