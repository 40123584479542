import { environment } from '../../environments/environment';
import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgSelectModule } from '@ng-select/ng-select';

import { PipesModule } from '../pipes/pipes.module';
import { DirectivesModule } from '../directives/directives.module';
import { LayoutModule } from '../layout/layout.module';
import { ComponentsModule } from '../components/components.module';
import { SwitchboardModule } from '../switchboard/switchboard.module';
import { SectionsModule } from '../sections/sections.module';
import { AudioModule } from '../audio/audio.module';

import { PortalComponentsModule } from './components/portal-components.module';
import { PortalBillingModule } from './billing/portal-billing.module';
import { PortalSettingsModule } from './settings/portal-settings.module';
import { PortalRoutingModule } from './portal-routing.module';

import { PortalNumbersComponent } from './numbers/portal-numbers/portal-numbers.component';
import { PortalNumberEmergencyComponent } from './numbers/portal-number-emergency/portal-number-emergency.component';
import { PortalNumberEditComponent } from './numbers/portal-number-edit/portal-number-edit.component';
import { PortalNewNumberComponent } from './portal-new-number/portal-new-number.component';

import { PortalGroupsComponent } from './groups/portal-groups/portal-groups.component';
import { PortalGroupEditComponent } from './groups/portal-group-edit/portal-group-edit.component';
import { PortalAnnouncementsComponent } from './announcements/portal-announcements/portal-announcements.component';
import { PortalAnnouncementEditComponent } from './announcements/portal-announcement-edit/portal-announcement-edit.component';
import { PortalMenusComponent } from './menus/portal-menus/portal-menus.component';
import { PortalMenuEditComponent } from './menus/portal-menu-edit/portal-menu-edit.component';
import { PortalVoicemailsComponent } from './voicemails/portal-voicemails/portal-voicemails.component';
import { PortalVoicemailEditComponent } from './voicemails/portal-voicemail-edit/portal-voicemail-edit.component';
import { PortalQueuesComponent } from './queues/portal-queues/portal-queues.component';
import { PortalQueueEditComponent } from './queues/portal-queue-edit/portal-queue-edit.component';
import { PortalSipsComponent } from './sips/portal-sips/portal-sips.component';
import { PortalSipEditComponent } from './sips/portal-sip-edit/portal-sip-edit.component';
import { PortalChannelsComponent } from './portal-channels/portal-channels.component';

import { PortalNewSwitchboardObjectComponent } from './portal-new-switchboard-object/portal-new-switchboard-object.component';

import { PortalTimeManagementComponent } from './portal-time-management/portal-time-management.component';
import { NineToFiveWizardComponent } from './portal-time-management/nine-to-five-wizard/nine-to-five-wizard.component';
import { PortalTemporaryCloseComponent } from './portal-temporary-close/portal-temporary-close.component';

import { PortalMediaComponent } from './portal-media/portal-media.component';
import { PortalCallstatsComponent } from './portal-callstats/portal-callstats.component';
import { PortalCallRestrictionsComponent } from './portal-call-restrictions/portal-call-restrictions.component';
import { PortalBundlesComponent } from './portal-bundles/portal-bundles.component';
import { PortalFlowComponent } from './portal-flow/portal-flow.component';
import { HandsetProvisioningComponent } from './handset-provisioning/handset-provisioning.component';
import { HandsetSpeeddialComponent } from './handset-provisioning/handset-speeddial/handset-speeddial.component';
import { HandsetPhonebookComponent } from './handset-provisioning/handset-phonebook/handset-phonebook.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,

        NgbModule,
        RecaptchaV3Module,
        NgxIntlTelInputModule,
        NgSelectModule,

        PipesModule,
        DirectivesModule,
        LayoutModule,
        ComponentsModule,
        SwitchboardModule,
        SectionsModule,
        AudioModule,

        PortalComponentsModule,
        PortalBillingModule,
        PortalSettingsModule,

        PortalRoutingModule,
    ],
    declarations: [
        PortalNumbersComponent,
        PortalNumberEmergencyComponent,
        PortalNumberEditComponent,
        PortalGroupsComponent,
        PortalGroupEditComponent,
        PortalCallstatsComponent,
        PortalAnnouncementsComponent,
        PortalAnnouncementEditComponent,
        PortalMenusComponent,
        PortalMenuEditComponent,
        PortalVoicemailsComponent,
        PortalVoicemailEditComponent,
        PortalQueuesComponent,
        PortalQueueEditComponent,
        PortalSipsComponent,
        PortalSipEditComponent,
        PortalMediaComponent,
        PortalNewSwitchboardObjectComponent,
        PortalNewNumberComponent,
        PortalCallRestrictionsComponent,
        PortalTimeManagementComponent,
        PortalTemporaryCloseComponent,
        PortalChannelsComponent,
        PortalBundlesComponent,
        PortalFlowComponent,
        NineToFiveWizardComponent,
        HandsetProvisioningComponent,
        HandsetSpeeddialComponent,
        HandsetPhonebookComponent,
    ],
    providers: [
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha_key },
    ],
    bootstrap: []
})

export class PortalModule { }
