<div class="card card-stats mb-4 mb-xl-0" *appViperIsNotPostpay>
    <div class="card-body topupcardrounded">
        <div class="row">
            <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0 topupcardrounded">Account Balance</h5>
            </div>
            <div class="col-auto">
                <div class="icon icon-shape bg-contrast text-white rounded-circle shadow">
                    <i class="fas fa-pound-sign"></i>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <span class="h2 font-weight-bold mb-0" *ngIf="balance !== null else loading">&pound;{{ balance|format_balance }}</span>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <span class="text-sm" *ngIf="accountMeta">expires {{ (account.active_state === 1 ? accountMeta.test_credit_expires_at : accountMeta.credit_expires_at)|date:'mediumDate' }} <a style="color: white; cursor: pointer; " tabindex="0" data-toggle="popover" data-trigger="focus" title="" data-content="Topping up your account now will extend the expiry date by 90 days"><i class="far fa-question-circle"></i></a></span>
            </div>
        </div>
        <!-- <p class="mt-3 mb-0 text-muted text-sm">
            <span class="text-warning mr-2"><i class="fas fa-arrow-down"></i> 1.10%</span>
            <span class="text-nowrap">Since yesterday</span>
        </p> -->
    </div>
    <div class="card-footer text-center">
        <a routerLink="/topup" class="font-weight-bold">Top Up <i class="fa fa-chevron-right"></i></a>
    </div>
</div>

<div class="card card-stats mb-4 mb-xl-0" *appViperIsPostpay>
    <div class="card-body topupcardrounded-del">
        <div class="row">
            <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0 topupcardrounded">This month's call spend</h5>
            </div>
            <div class="col-auto">
                <div class="icon icon-shape bg-contrast text-white rounded-circle shadow">
                    <i class="fas fa-pound-sign"></i>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <span class="h2 font-weight-bold mb-0" *ngIf="balance !== null else loading">&pound;{{ (balance * -1)|format_balance }}</span>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col">
                <span class="text-sm" *ngIf="accountMeta">expires {{ (account.active_state === 1 ? accountMeta.test_credit_expires_at : accountMeta.credit_expires_at)|date:'mediumDate' }} <a style="color: white; cursor: pointer; " tabindex="0" data-toggle="popover" data-trigger="focus" title="" data-content="Topping up your account now will extend the expiry date by 90 days"><i class="far fa-question-circle"></i></a></span>
            </div>
        </div> -->
        <!-- <p class="mt-3 mb-0 text-muted text-sm">
            <span class="text-warning mr-2"><i class="fas fa-arrow-down"></i> 1.10%</span>
            <span class="text-nowrap">Since yesterday</span>
        </p> -->
    </div>
    <!-- <div class="card-footer text-center">
        <a routerLink="/invoices" class="font-weight-bold">Invoices <i class="fa fa-chevron-right"></i></a>
    </div> -->
</div>

<ng-template #loading>
    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
</ng-template>