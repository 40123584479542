import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Client } from '../tools/client';

@Injectable({
    providedIn: 'root'
})
export class MediaService extends Client {
    protected serviceURL = environment.services.media;

    /* Switchboard Media */

    getSwitchboardMedia(account_id) {
        return this.callApi('get', `/accounts/${account_id}/media/switchboard`)
        .pipe(
            tap(_ => this.log(`fetching switchboard media`)),
            catchError(this.handleError('getSwitchboardMedia', []))
        );
    }

    downloadSwitchboardMedia(account_id, switchboardMediaFile) {
        return this.downloadResource(this.serviceURL + `/accounts/${account_id}/media/switchboard/${switchboardMediaFile.uuid}`);
    }

    uploadSwitchboardMedia(account_id, data) {
        return this.upload('post', this.serviceURL + `/accounts/${account_id}/media/switchboard/upload`, data)
        .pipe(
            tap(_ => this.log(`uploading switchboard media`)),
            catchError(this.handleError('uploadSwitchboardMedia', []))
        );
    }

    renameSwitchboardMedia(account_id, file) {
        return this.callApi('put', `/accounts/${account_id}/media/switchboard/${file.uuid}`, file)
        .pipe(
            tap(_ => this.log(`renaming switchboard media`)),
            catchError(this.handleError('renameSwitchboardMedia', []))
        );
    }

    deleteSwitchboardMedia(account_id, file) {
        return this.callApi('delete', `/accounts/${account_id}/media/switchboard/${file.uuid}`)
        .pipe(
            tap(_ => this.log(`deleting switchboard media`)),
            catchError(this.handleError('deleteSwitchboardMedia', []))
        );
    }

    getUnverifiedMedia(account_id, page = 1) {
        return this.callApi('get', `/accounts/${account_id}/media/switchboardverify`, {page: page})
        .pipe(
            tap(apps => this.log(`fetching media needing approval`)),
            catchError(this.handleError('getAdminMedia', []))
        );
    }

    updateUnverifiedMedia(account_id, media_uuid, isVerified) {
        return this.callApi('post', `/accounts/${account_id}/media/switchboardverifyupdate/${media_uuid}`, {isVerified: isVerified})
            .pipe(
                tap(apps => this.log(`updating unverified media`)),
                catchError(this.handleError('updateAdminMedia', []))
            );
    }

    /* Text to speech */

    getTTSVoices() {
        return this.callApi('get', `/tts/voices`)
        .pipe(
            tap(_ => this.log(`fetching TTS voices`)),
            catchError(this.handleError('getTTSVoices', []))
        );
    }

    getTTSDailyLimit(account_id) {
        return this.callApi('get', `/accounts/${account_id}/media/switchboard/tts/limit`)
        .pipe(
            tap(_ => this.log(`fetching TTS limit for account ${account_id}`)),
            catchError(this.handleError('getTTSDailyLimit', []))
        );
    }

    getTTSPreview(data) {
        return this.callApi('post', `/tts/preview`, data, { responseType: 'arraybuffer' })
        .pipe(
            tap(_ => this.log(`fetching TTS preview`)),
            catchError(this.handleError('getTTSVoices', []))
        );
    }

    createTTSMedia(account_id, data) {
        return this.callApi('post', `/accounts/${account_id}/media/switchboard/tts`, data)
        .pipe(
            tap(_ => this.log(`creating TTS limit for account ${account_id}`)),
            catchError(this.handleError('createTTSMedia', []))
        );
    }

    resetDailyTTSLimit(account_id) {
        return this.callApi('delete', `/accounts/${account_id}/media/switchboard/tts/limit`)
        .pipe(
            tap(_ => this.log(`resetting tts limit for ${account_id}`)),
            catchError(this.handleError('resetDailyTTSLimit', []))
        );
    }

    /* Voicemail */

    getAllVoicemails(account_id) {
        return this.callApi('get', `/accounts/${account_id}/media/voicemail`)
        .pipe(
            tap(_ => this.log(`fetching voicemails for ${account_id}`)),
            catchError(this.handleError('getAllVoicemails', []))
        );
    }

    getVoicemailsForExtension(account_id, extension) {
        return this.callApi('get', `/accounts/${account_id}/media/voicemail/${extension}`)
        .pipe(
            tap(_ => this.log(`fetching voicemails for ${account_id} for extension ${extension}`)),
            catchError(this.handleError('getVoicemailsForExtension', []))
        );
    }

    downloadVoicemail(account_id, voicemail, age) {
        const path = `/accounts/${account_id}/media/voicemail/${voicemail.origmailbox}/${age}/${voicemail.id}.wav`;
        return this.downloadResource(this.serviceURL + path);
    }

    markVoicemailRead(account_id, extension, msg_id) {
        return this.callApi('post', `/accounts/${account_id}/media/voicemail/${extension}/new/${msg_id}`)
        .pipe(
            tap(_ => this.log(`marking voicemail message ${msg_id} for ${account_id} for extension ${extension} as read`)),
            catchError(this.handleError('markVoicemailRead', []))
        );
    }

    markVoicemailUnread(account_id, extension, msg_id) {
        return this.callApi('post', `/accounts/${account_id}/media/voicemail/${extension}/old/${msg_id}`)
        .pipe(
            tap(_ => this.log(`marking voicemail message ${msg_id} for ${account_id} for extension ${extension} as unread`)),
            catchError(this.handleError('markVoicemailUnread', []))
        );
    }

    deleteVoicemail(account_id, extension, age, msg_id) {
        return this.callApi('delete', `/accounts/${account_id}/media/voicemail/${extension}/${age}/${msg_id}`)
        .pipe(
            tap(_ => this.log(`deleting voicemail message ${msg_id} for ${account_id} for extension ${extension}`)),
            catchError(this.handleError('deleteVoicemail', []))
        );
    }

    getVoicemailCapacity(account_id, extension) {
        return this.callApi('get', `/accounts/${account_id}/media/voicemail/${extension}/capacity`)
        .pipe(
            tap(_ => this.log(`fetching capacity for ${account_id} for extension ${extension}`)),
            catchError(this.handleError('getVoicemailCapacity', []))
        );
    }

    deleteAllVoicemails(account_id, extension) {
        return this.callApi('delete', `/accounts/${account_id}/media/voicemail/${extension}/all`)
        .pipe(
            tap(_ => this.log(`deleting all voicemails for extension ${extension}`)),
            catchError(this.handleError('deleteAllVoicemails', []))
        );
    }

    deleteAllReadVoicemails(account_id, extension) {
        return this.callApi('delete', `/accounts/${account_id}/media/voicemail/${extension}/old/all`)
        .pipe(
            tap(_ => this.log(`deleting all read voicemails for extension ${extension}`)),
            catchError(this.handleError('deleteAllReadVoicemails', []))
        );
    }

    deleteAllUnreadVoicemails(account_id, extension) {
        return this.callApi('delete', `/accounts/${account_id}/media/voicemail/${extension}/new/all`)
        .pipe(
            tap(_ => this.log(`deleting all unread voicemails for extension ${extension}`)),
            catchError(this.handleError('deleteAllUnreadVoicemails', []))
        );
    }

    /* Call Recordings */

    searchCallRecordings(account, data) {
        const path  = `/accounts/${account}/media/recording`;
        return this.callApi('get', path, data)
        .pipe(
            tap(_ => this.log(`searching call recordings for account ${account}`)),
            catchError(this.handleError('searchCallRecordings', []))
        );
    }

    downloadCallRecording(account, callRecording) {
        return this.downloadResource(this.serviceURL + `/accounts/${account}/media/recording/${callRecording}`);
    }

    deleteCallRecording(account, callRecording) {
        return this.callApi('delete', `/accounts/${account}/media/recording/${callRecording}`)
        .pipe(
            tap(_ => this.log(`deleting call recording ${callRecording}`)),
            catchError(this.handleError('deleteCallRecording', []))
        );
    }

    showUsedAvailableSpace(account) {
        const path = `/accounts/${account}/media/recording/usage`;
        return this.callApi('get', path)
        .pipe(
            tap(_ => this.log(`get call recordings space for account ${account}`)),
            catchError(this.handleError('showUsedAvailableSpace', []))
        );
    }

    getCallRecordingDownloadLink(account_id, call_recording) {
        const path = `/accounts/${account_id}/media/recording/${call_recording.uuid}`;
        return this.callApi('get', path)
        .pipe(
            tap(_ => this.log(`get call recording download link for account ${account_id}`)),
            catchError(this.handleError('getCallRecordingDownloadLink', []))
        );
    }

    getCallRecordingDownloadLinks(account_id, call_recordings) {
        const path = `/accounts/${account_id}/media/recording/multi/download`;
        return this.callApi('post', path, { uuids: call_recordings })
        .pipe(
            tap(_ => this.log(`get call recording download links for account ${account_id}`)),
            catchError(this.handleError('getCallRecordingDownloadLinks', []))
        );
    }

    deleteCallRecordings(account_id, call_recordings) {
        const path = `/accounts/${account_id}/media/recording/multi/delete`;
        return this.callApi('post', path, { uuids: call_recordings })
        .pipe(
            tap(_ => this.log(`delete call recordings for account ${account_id}`)),
            catchError(this.handleError('deleteCallRecordings', []))
        );
    }

    /* Switchboard Music */

    getSwitchboardMusic(account_id) {
        return this.callApi('get', `/accounts/${account_id}/media/music`)
        .pipe(
            tap(_ => this.log(`fetching switchboard music`)),
            catchError(this.handleError('getSwitchboardMusic', []))
        );
    }

    downloadSwitchboardMusic(account_id, music) {
        return this.downloadResource(this.serviceURL + `/accounts/${account_id}/media/music/${music.uuid}`);
    }

    uploadSwitchboardMusic(account_id, data) {
        return this.upload('post', this.serviceURL + `/accounts/${account_id}/media/music/upload`, data)
        .pipe(
            tap(_ => this.log(`uploading switchboard music`)),
            catchError(this.handleError('uploadSwitchboardMusic', []))
        );
    }

    renameSwitchboardMusic(account_id, file) {
        return this.callApi('put', `/accounts/${account_id}/media/music/${file.uuid}`, file)
        .pipe(
            tap(_ => this.log(`renaming switchboard music`)),
            catchError(this.handleError('renameSwitchboardMusic', []))
        );
    }

    deleteSwitchboardMusic(account_id, file) {
        return this.callApi('delete', `/accounts/${account_id}/media/music/${file.uuid}`)
        .pipe(
            tap(_ => this.log(`deleting switchboard music`)),
            catchError(this.handleError('deleteSwitchboardMusic', []))
        );
    }

    /* Music Library */

    getLibraryMusic() {
        return this.callApi('get', `/music/library`)
        .pipe(
            tap(_ => this.log(`fetching library music`)),
            catchError(this.handleError('getLibraryMusic', []))
        );
    }

    downloadLibraryMusic(music) {
        return this.downloadResource(this.serviceURL + `/music/library/${music.uuid}`);
    }

    addLibraryMusicToSwitchboard(account_id, file) {
        return this.callApi('post', `/accounts/${account_id}/media/music/add_from_library/${file.uuid}`)
        .pipe(
            tap(_ => this.log(`adding library music`)),
            catchError(this.handleError('addLibraryMusicToSwitchboard', []))
        );
    }

    uploadLibraryMusic(data) {
        return this.upload('post', this.serviceURL + `/music/library/upload`, data)
        .pipe(
            tap(_ => this.log(`uploading library music`)),
            catchError(this.handleError('uploadLibraryMusic', []))
        );
    }

    renameLibraryMusic(file) {
        return this.callApi('put', `/music/library/${file.uuid}`, file)
        .pipe(
            tap(_ => this.log(`renaming library music`)),
            catchError(this.handleError('renameLibraryMusic', []))
        );
    }

    deleteLibraryMusic(file) {
        return this.callApi('delete', `/music/library/${file.uuid}`)
        .pipe(
            tap(_ => this.log(`deleting library music`)),
            catchError(this.handleError('deleteLibraryMusic', []))
        );
    }
}
