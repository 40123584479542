import { Component, OnInit } from '@angular/core';

import { LoginService } from '../../login/login.service';

declare var window: any;

@Component({
    selector: 'app-live-chat',
    templateUrl: './live-chat.component.html',
    styleUrls: ['./live-chat.component.css']
})
export class LiveChatComponent implements OnInit {

    private user;
    private app;

    readonly sid_start = 1000000;
    readonly chat_attrs = {
        position_placement: 'bottom_right',
        identifier: 'Portal',
        mode: 'widget',
        lhc_base_url: '//lhc.si-p.co.uk/',
        wheight: 450,
        wwidth: 350,
        pheight: 520,
        pwidth: 500,
        leaveamessage: true,
        check_messages: false,

        department: [2],
        theme: 4
    };

    constructor(
        private authService: LoginService,
    ) { }

    ngOnInit() {
        this.authService.getCurrentUser()
        .subscribe(user => {
            this.user = user;
            this.getChatAttrs();
        });
        this.authService.getCurrentApp()
        .subscribe(app => {
            this.app = app;
            if (this.app && !window.lcInitialised) {
                this.initChat();
            }
        });
    }

    getChatAttrs() {
        const attrs = this.chat_attrs;

        if (this.app) {
            switch (this.app.short_name) {
                case 'np':
                    attrs['theme'] = 4;
                    attrs['department'] = [2];
                    attrs['identifier'] = 'Number People';
                    break;
                case 'lv':
                    attrs['theme'] = 2;
                    attrs['department'] = [5];
                    attrs['identifier'] = 'Love VoIP';
                    break;
                case 'mbn':
                    attrs['theme'] = 3;
                    attrs['department'] = [6];
                    attrs['identifier'] = 'My Business Number';
                    break;
                case 'vv':
                    attrs['theme'] = 1;
                    attrs['department'] = [1];
                    attrs['identifier'] = 'Vivi VoIP';
                    break;
            }
        }

        if (this.user) {
            attrs['lhc_var'] = {
                email: this.user.email,
                username: `${this.user.first_name} ${this.user.last_name}`,
                account_id: (this.sid_start + this.user.account_id),
            };
        }


        window.LHC_API = window.LHC_API || {};
        window.LHC_API.args = attrs;
    }

    initChat() {
        this.getChatAttrs();

        (function() {
            const popupScript = document.createElement('script');
            popupScript.type = 'text/javascript';
            popupScript.setAttribute('crossorigin', 'anonymous');
            popupScript.async = true;
            const date = new Date();
            const datestring = '' + date.getFullYear() + date.getMonth() + date.getDate();
            popupScript.src = '//lhc.si-p.co.uk/design/defaulttheme/js/widgetv2/index.js?' + datestring;
            const scriptTag = document.getElementsByTagName('script')[0];
            scriptTag.parentNode.insertBefore(popupScript, scriptTag);
        })();

        this.loadHotjar();

        window.lcInitialised = true;
    }

    loadHotjar() {
        const hjids = {
            np: 1597279,
            lv: 2198430,
            mbn: 3457603,
        };
        const hjid = hjids[this.app.short_name];

        if (hjid) {
            (function(h, o, t, j, a, r) {
                h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments); };
                h._hjSettings = { hjid: hjid, hjsv: 6 };
                a = o.getElementsByTagName('head')[0];
                r = o.createElement('script'); r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
            })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
        }
    }

}
