import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { SwitchboardService } from '../../../switchboard/switchboard.service';
import { ServicesService } from '../../../services/services.service';

import { SwitchboardObject } from '../../../switchboard/switchboard_object';

declare var alertify: any;

@Component({
    selector: 'app-portal-component-delete-switchboard-object',
    templateUrl: './portal-component-delete-switchboard-object.component.html',
    styleUrls: ['./portal-component-delete-switchboard-object.component.css']
})
export class PortalComponentDeleteSwitchboardObjectComponent implements OnInit {

    @Input() account_id: number;
    @Input() switchboard_object: SwitchboardObject;

    constructor(
        private switchboardService: SwitchboardService,
        private servicesService: ServicesService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit() {
    }

    delete() {
        alertify.confirm(`Are you sure you want to delete ${this.switchboard_object.object_data.name}?`, () => {
            this.servicesService.deleteServiceOnAccount(this.account_id, this.switchboard_object.service_instance_id)
            .subscribe(response => {
                if (response !== 'FAIL') {
                    const page = `/${this.switchboard_object.object_type.toLowerCase()}s`;
                    this.router.navigate([page]);
                    // window.location.href = page;
                }
            });
        });
    }

}
