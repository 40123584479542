import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { ContractsService } from '../contracts.service';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-historical-contracts',
    templateUrl: './historical-contracts.component.html',
    styleUrls: ['./historical-contracts.component.css']
})
export class HistoricalContractsComponent {

    @Input() account_id;
    @Input() switchboard_object;

    contracts: any;

    page = 1;

    @ViewChild('historicalContractModal') historicalContractModal;

    constructor(
        private contractsService: ContractsService,
    ) { }

    getContracts() {
        this.contractsService.getHistoricContracts(this.account_id, this.switchboard_object, this.page)
            .subscribe(contracts => this.contracts = contracts);
    }

    public open(account_id, switchboard_object) {
        this.page = 1;
        this.contracts = null;
        this.account_id = account_id;
        this.switchboard_object = switchboard_object;
        this.getContracts();
        this.historicalContractModal.open();
    }

    changePage(page) {
        this.page = page;
        this.getContracts();
    }

}
