<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/menus">Menus</a></li>
                <li class="breadcrumb-item active" aria-current="page">Edit Menu</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">
                            Menu
                            <span *ngIf="switchboard_object">
                                {{ switchboard_object.object_data.name }}
                                -
                                <span class="text-muted text-sm" data-toggle="tooltip" data-placement="bottom" title="Every part of your system has an extension number that you can call directly using our VoIP service, or transfer a call to">
                                    Extension Number {{ switchboard_object.extension_number }}
                                    <button class="btn btn-sm btn-outline-secondary" (click)="rename_modal.open()">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                </span>
                            </span>

                        </h2>
                    </div>
                </div>
            </div>
            <div class="card-body" *ngIf="switchboard_object else loading">
                <ng-container *ngTemplateOutlet="switchboard_object_edit"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #switchboard_object_edit>
    <div *ngIf="parent" class="btn btn-lg btn-block btn-outline-info text-lg darkcard" (click)="backToParent()">
        <i class="far fa-hand-point-left"></i> Go Back
        <br>
        <span class="text-muted text-sm">
            <i [class]="parent.object_type|capitalize|switchboard_object_icon" [title]="parent.object_type"></i>
            {{ parent.object_data.name }}
        </span>
    </div>

    <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" (click)="media_component.open()">
        <i class="far fa-file-audio"></i> Announcement
        <br>
        <div class="text-muted text-sm">
            Using: {{ media_component.getMediaFile()?.name || '[No Media]' }}
        </div>
    </div>

    <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard"
         id="options-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="fas fa-bars"></i> Menu Options
        <div class="text-muted text-sm" *ngIf="countOptions() as count">
            Currently: {{ count }} {{ count === '1' ? 'option' : 'options' }} 
        </div>
    </div>
    <div class="dropdown-menu dropdown-menu-xl" aria-labelledby="options-dropdown">
        <div class="dropdown-item text-center" *ngIf="!switchboard_object.object_data.option_1_uuid" (click)="option_1_modal.open()">Press 1</div>
        <div class="dropdown-item text-center" *ngIf="!switchboard_object.object_data.option_2_uuid" (click)="option_2_modal.open()">Press 2</div>
        <div class="dropdown-item text-center" *ngIf="!switchboard_object.object_data.option_3_uuid" (click)="option_3_modal.open()">Press 3</div>
        <div class="dropdown-item text-center" *ngIf="!switchboard_object.object_data.option_4_uuid" (click)="option_4_modal.open()">Press 4</div>
        <div class="dropdown-item text-center" *ngIf="!switchboard_object.object_data.option_5_uuid" (click)="option_5_modal.open()">Press 5</div>
        <div class="dropdown-item text-center" *ngIf="!switchboard_object.object_data.option_6_uuid" (click)="option_6_modal.open()">Press 6</div>
        <div class="dropdown-item text-center" *ngIf="!switchboard_object.object_data.option_7_uuid" (click)="option_7_modal.open()">Press 7</div>
        <div class="dropdown-item text-center" *ngIf="!switchboard_object.object_data.option_8_uuid" (click)="option_8_modal.open()">Press 8</div>
        <div class="dropdown-item text-center" *ngIf="!switchboard_object.object_data.option_9_uuid" (click)="option_9_modal.open()">Press 9</div>
        <div class="dropdown-item text-center" *ngIf="!switchboard_object.object_data.option_0_uuid" (click)="option_0_modal.open()">Press 0</div>
        <div class="dropdown-item text-center" *ngIf="!switchboard_object.object_data.option_star_uuid" (click)="option_star_modal.open()">Press <i class="fas fa-asterisk"></i></div>
        <div class="dropdown-item text-center" *ngIf="!switchboard_object.object_data.option_hash_uuid" (click)="option_hash_modal.open()">Press <i class="fas fa-hashtag"></i></div>
    </div>

    <br>
    
    <div class="btn btn-lg btn-link btn-block btn-outline-secondary text-lg darkcard"
         *ngIf="switchboard_object.object_data.option_1_uuid"
         (click)="option_1_modal.open()">
        Press 1
        <br>
        <span class="text-muted text-sm" *ngIf="option_1_component.getDestinationObject() as destination">
            Forwarding to:
            <app-forwarding-to-partial [destination]="destination"></app-forwarding-to-partial>
        </span>
    </div>

    <div class="btn btn-lg btn-link btn-block btn-outline-secondary text-lg darkcard"
         *ngIf="switchboard_object.object_data.option_2_uuid"
         (click)="option_2_modal.open()">
        Press 2
        <br>
        <span class="text-muted text-sm" *ngIf="option_2_component.getDestinationObject() as destination">
            Forwarding to:
            <app-forwarding-to-partial [destination]="destination"></app-forwarding-to-partial>
        </span>
    </div>

    <div class="btn btn-lg btn-link btn-block btn-outline-secondary text-lg darkcard"
         *ngIf="switchboard_object.object_data.option_3_uuid"
         (click)="option_3_modal.open()">
        Press 3
        <br>
        <span class="text-muted text-sm" *ngIf="option_3_component.getDestinationObject() as destination">
            Forwarding to:
            <app-forwarding-to-partial [destination]="destination"></app-forwarding-to-partial>
        </span>
    </div>

    <div class="btn btn-lg btn-link btn-block btn-outline-secondary text-lg darkcard"
         *ngIf="switchboard_object.object_data.option_4_uuid"
         (click)="option_4_modal.open()">
        Press 4
        <br>
        <span class="text-muted text-sm" *ngIf="option_4_component.getDestinationObject() as destination">
            Forwarding to:
            <app-forwarding-to-partial [destination]="destination"></app-forwarding-to-partial>
        </span>
    </div>

    <div class="btn btn-lg btn-link btn-block btn-outline-secondary text-lg darkcard"
         *ngIf="switchboard_object.object_data.option_5_uuid"
         (click)="option_5_modal.open()">
        Press 5
        <br>
        <span class="text-muted text-sm" *ngIf="option_5_component.getDestinationObject() as destination">
            Forwarding to:
            <app-forwarding-to-partial [destination]="destination"></app-forwarding-to-partial>
        </span>
    </div>

    <div class="btn btn-lg btn-link btn-block btn-outline-secondary text-lg darkcard"
         *ngIf="switchboard_object.object_data.option_6_uuid"
         (click)="option_6_modal.open()">
        Press 6
        <br>
        <span class="text-muted text-sm" *ngIf="option_6_component.getDestinationObject() as destination">
            Forwarding to:
            <app-forwarding-to-partial [destination]="destination"></app-forwarding-to-partial>
        </span>
    </div>

    <div class="btn btn-lg btn-link btn-block btn-outline-secondary text-lg darkcard"
         *ngIf="switchboard_object.object_data.option_7_uuid"
         (click)="option_7_modal.open()">
        Press 7
        <br>
        <span class="text-muted text-sm" *ngIf="option_7_component.getDestinationObject() as destination">
            Forwarding to:
            <app-forwarding-to-partial [destination]="destination"></app-forwarding-to-partial>
        </span>
    </div>

    <div class="btn btn-lg btn-link btn-block btn-outline-secondary text-lg darkcard"
         *ngIf="switchboard_object.object_data.option_8_uuid"
         (click)="option_8_modal.open()">
        Press 8
        <br>
        <span class="text-muted text-sm" *ngIf="option_8_component.getDestinationObject() as destination">
            Forwarding to:
            <app-forwarding-to-partial [destination]="destination"></app-forwarding-to-partial>
        </span>
    </div>

    <div class="btn btn-lg btn-link btn-block btn-outline-secondary text-lg darkcard"
         *ngIf="switchboard_object.object_data.option_9_uuid"
         (click)="option_9_modal.open()">
        Press 9
        <br>
        <span class="text-muted text-sm" *ngIf="option_9_component.getDestinationObject() as destination">
            Forwarding to:
            <app-forwarding-to-partial [destination]="destination"></app-forwarding-to-partial>
        </span>
    </div>

    <div class="btn btn-lg btn-link btn-block btn-outline-secondary text-lg"
         *ngIf="switchboard_object.object_data.option_0_uuid"
         (click)="option_0_modal.open()">
        Press 0
        <br>
        <span class="text-muted text-sm" *ngIf="option_0_component.getDestinationObject() as destination">
            Forwarding to:
            <app-forwarding-to-partial [destination]="destination"></app-forwarding-to-partial>
        </span>
    </div>

    <div class="btn btn-lg btn-link btn-block btn-outline-secondary text-lg"
         *ngIf="switchboard_object.object_data.option_star_uuid"
         (click)="option_star_modal.open()">
        Press <i class="fas fa-asterisk"></i>
        <br>
        <span class="text-muted text-sm" *ngIf="option_star_component.getDestinationObject() as destination">
            Forwarding to:
            <app-forwarding-to-partial [destination]="destination"></app-forwarding-to-partial>
        </span>
    </div>

    <div class="btn btn-lg btn-link btn-block btn-outline-secondary text-lg"
         *ngIf="switchboard_object.object_data.option_hash_uuid"
         (click)="option_hash_modal.open()">
        Press <i class="fas fa-hashtag"></i>
        <br>
        <span class="text-muted text-sm" *ngIf="option_hash_component.getDestinationObject() as destination">
            Forwarding to:
            <app-forwarding-to-partial [destination]="destination"></app-forwarding-to-partial>
        </span>
    </div>

    <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" *ngIf="switchboard_object" (click)="timeout_component.open()">
        <i class="far fa-clock"></i> Wait for a response for
        <br>
        <span class="text-muted text-sm">
            <span class="text-lg">{{ switchboard_object.object_data.timeout }}</span> seconds
        </span>
    </div>

    <div class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard" (click)="destination_modal.open()">
        <i class="fas fa-phone-volume "></i>
        If no option detected send to
        <br>
        <span class="text-muted text-sm" *ngIf="forwarding_component.getDestinationObject() as destination">
            <app-forwarding-to-partial [destination]="destination"></app-forwarding-to-partial>
        </span>
    </div>

    <a class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard"
       *appViperBrandIs="['vv']"
       routerLink="/time-management/{{ switchboard_object?.uuid }}">
        <i class="far fa-clock"></i> Time Management
    </a>

    <app-portal-component-delete-switchboard-object [account_id]="account_id" [switchboard_object]="switchboard_object"></app-portal-component-delete-switchboard-object>
</ng-template>

<app-portal-component-rename-switchboard-object [account_id]="account_id" [switchboard_object]="switchboard_object" #rename_modal></app-portal-component-rename-switchboard-object>

<app-modal title="Set Destination" size="big" (save)="destination_modal.close()" #destination_modal>
    <app-portal-component-destination-select [root]="switchboard_object" #forwarding_component></app-portal-component-destination-select>
</app-modal>

<app-portal-component-media-select
    [account_id]="account_id"
    [switchboard_object]="switchboard_object"
    tts_text="Welcome to XYZ. Press 1 for Sales. Press 2 for Service. Press 3 for support. or hold for the next available operator."
    #media_component
></app-portal-component-media-select>

<app-modal title="Set Option 1 Destination" size="big" (save)="destination_modal.close()" #option_1_modal>
    <app-portal-component-destination-select 
        [root]="switchboard_object"
        nullable="true"
        field="option_1_uuid"
        (save)="option_1_modal.close()"
        #option_1_component
    ></app-portal-component-destination-select>
</app-modal>

<app-modal title="Set Option 2 Destination" size="big" (save)="destination_modal.close()" #option_2_modal>
    <app-portal-component-destination-select 
        [root]="switchboard_object"
        nullable="true"
        field="option_2_uuid"
        (save)="option_2_modal.close()"
        #option_2_component
    ></app-portal-component-destination-select>
</app-modal>

<app-modal title="Set Option 3 Destination" size="big" (save)="destination_modal.close()" #option_3_modal>
    <app-portal-component-destination-select 
        [root]="switchboard_object"
        nullable="true"
        field="option_3_uuid"
        (save)="option_3_modal.close()"
        #option_3_component
    ></app-portal-component-destination-select>
</app-modal>

<app-modal title="Set Option 4 Destination" size="big" (save)="destination_modal.close()" #option_4_modal>
    <app-portal-component-destination-select 
        [root]="switchboard_object"
        nullable="true"
        field="option_4_uuid"
        (save)="option_4_modal.close()"
        #option_4_component
    ></app-portal-component-destination-select>
</app-modal>

<app-modal title="Set Option 5 Destination" size="big" (save)="destination_modal.close()" #option_5_modal>
    <app-portal-component-destination-select 
        [root]="switchboard_object"
        nullable="true"
        field="option_5_uuid"
        (save)="option_5_modal.close()"
        #option_5_component
    ></app-portal-component-destination-select>
</app-modal>

<app-modal title="Set Option 6 Destination" size="big" (save)="destination_modal.close()" #option_6_modal>
    <app-portal-component-destination-select 
        [root]="switchboard_object"
        nullable="true"
        field="option_6_uuid"
        (save)="option_6_modal.close()"
        #option_6_component
    ></app-portal-component-destination-select>
</app-modal>

<app-modal title="Set Option 7 Destination" size="big" (save)="destination_modal.close()" #option_7_modal>
    <app-portal-component-destination-select 
        [root]="switchboard_object"
        nullable="true"
        field="option_7_uuid"
        (save)="option_7_modal.close()"
        #option_7_component
    ></app-portal-component-destination-select>
</app-modal>

<app-modal title="Set Option 8 Destination" size="big" (save)="destination_modal.close()" #option_8_modal>
    <app-portal-component-destination-select 
        [root]="switchboard_object"
        nullable="true"
        field="option_8_uuid"
        (save)="option_8_modal.close()"
        #option_8_component
    ></app-portal-component-destination-select>
</app-modal>

<app-modal title="Set Option 9 Destination" size="big" (save)="destination_modal.close()" #option_9_modal>
    <app-portal-component-destination-select 
        [root]="switchboard_object"
        nullable="true"
        field="option_9_uuid"
        (save)="option_9_modal.close()"
        #option_9_component
    ></app-portal-component-destination-select>
</app-modal>

<app-modal title="Set Option 0 Destination" size="big" (save)="destination_modal.close()" #option_0_modal>
    <app-portal-component-destination-select 
        [root]="switchboard_object"
        nullable="true"
        field="option_0_uuid"
        (save)="option_0_modal.close()"
        #option_0_component
    ></app-portal-component-destination-select>
</app-modal>

<app-modal title="Set Option Star Destination" size="big" (save)="destination_modal.close()" #option_star_modal>
    <app-portal-component-destination-select 
        [root]="switchboard_object"
        nullable="true"
        field="option_1_uuid"
        (save)="option_star_modal.close()"
        #option_star_component
    ></app-portal-component-destination-select>
</app-modal>

<app-modal title="Set Option Hash Destination" size="big" (save)="destination_modal.close()" #option_hash_modal>
    <app-portal-component-destination-select 
        [root]="switchboard_object"
        nullable="true"
        field="option_hash_uuid"
        (save)="option_hash_modal.close()"
        #option_hash_component
    ></app-portal-component-destination-select>
</app-modal>

<app-portal-component-timeout-switchboard-object [account_id]="account_id" [switchboard_object]="switchboard_object" #timeout_component></app-portal-component-timeout-switchboard-object>


<ng-template #loading>
    <div class="card-body">
        <app-loadingbar></app-loadingbar>
    </div>
</ng-template>
