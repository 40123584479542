export class Service {
    id: number;
    name: string;
    switchboard_type: string;
    retail_cost: number;
    renew_days: number;
    switchboard_quantity: number;
    switchboard_quality: number;
    unique_per_account: number; // NOTE: Technically a boolean but the API can't seem to handle them
    requires_registrar: number; // NOTE: Technically a boolean but the API can't seem to handle them
    requires_admin: number;     // NOTE: Technically a boolean but the API can't seem to handle them
}
