import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../environments/environment';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Client } from './tools/client';

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends Client {

    protected serviceURL = environment.services.notification;

    getEmailLogs(account, user, page = 1) {
        return this.callApi('get', `/accounts/${account}/users/${user}/emails`, {
            page: page,
        })
        .pipe(
            tap(_ => this.log(`fetching fetching email logs for user ${user}`)),
            catchError(this.handleError('getEmailLogs', []))
        );
    }
}
