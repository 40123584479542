import { Directive, ViewContainerRef, TemplateRef, OnInit, Input } from '@angular/core';

import { LoginService } from '../login/login.service';
import { ServicesService } from '../services/services.service';

import { RoleHelper } from '../sections/roles/role.helper';

@Directive({
    selector: '[appViperHasUserRole]'
})
export class HasUserRoleDirective implements OnInit {

    @Input('appViperHasUserRole') role: string|string[];

    @Input('appViperHasUserRoleElse') alternative: TemplateRef<any> | null = null;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private template: TemplateRef<any>,
        private authService: LoginService,
    ) { }

    ngOnInit(): void {
        this.authService.getRolesForCurrentUser()
            .subscribe(roles => this.render(roles));
    }

    getRolesAsArray() {
        const isArray = Array.isArray(this.role);
        if (!isArray) {
            return [this.role];
        }
        return this.role;
    }

    render(roles) {
        const hasRole = this.accountHasRole(roles);
        this.viewContainerRef.clear();
        if (hasRole) {
            this.viewContainerRef.createEmbeddedView(this.template);
        } else if (this.alternative) {
            this.viewContainerRef.createEmbeddedView(this.alternative);
        }
    }

    accountHasRole(roles) {
        let hasRole = false;
        const accepted_roles = this.getRolesAsArray();
        for (let role of accepted_roles) {
            hasRole = hasRole || RoleHelper.hasRoleName(roles, role);
        }
        return hasRole;
    }

}
