import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { LoginService } from '../login/login.service';
import { SwitchboardService } from '../switchboard/switchboard.service';

import { CountryCodes } from '../tools/country-codes';

declare var moment: any;
declare var Chart: any;
declare var $: any;

function getDateRange(from = null, to = null) {
    let dateArray = [];
    let currentDate = moment().subtract(30, 'days');
    let stopDate = moment();

    if (from && to) {
        currentDate = moment(`${from.year}-${from.month}-${from.day}`);
        stopDate = moment(`${to.year}-${to.month}-${to.day}`);
    }

    while (currentDate <= stopDate) {
        dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
        currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
}

@Component({
  selector: 'app-signup-log',
  templateUrl: './signup-log.component.html',
  styleUrls: ['./signup-log.component.css']
})
export class SignupLogComponent implements OnInit {

    readonly sid_base = 1000000;

    page = 1;

    logs;
    signup_counts;
    stats_chart;

    country_code = '';
    number_search = '';

    @ViewChild('datepicker') datepicker;

    public countryCodes = CountryCodes.e164_prefixes;

    constructor(
        private authService: LoginService,
        private switchboardService: SwitchboardService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.initDatePicker();
        this.activatedRoute.paramMap.subscribe(params => {
            this.page = +params.get('page') || 1;
            this.getLog();
        });
        this.getLogCount();
    }

    splitToDate(date: string) {
        const dateList = date.split('-');
        return {
            year: +dateList[0],
            month: +dateList[1],
            day: +dateList[2],
        };
    }

    initDatePicker() {
        if (this.datepicker) {
            const start_date = this.activatedRoute.snapshot.queryParamMap.get('start_date');
            if (start_date) {
                this.datepicker.fromDate = this.splitToDate(start_date);
            }
            const end_date = this.activatedRoute.snapshot.queryParamMap.get('end_date');
            if (end_date) {
                this.datepicker.toDate = this.splitToDate(end_date);
            }
        } else {
            setTimeout(() => this.initDatePicker(), 500);
        }
    }

    getStartDate() {
        if (this.datepicker.fromDate) {
            return `${this.datepicker.fromDate.year}-${this.datepicker.fromDate.month}-${this.datepicker.fromDate.day}`;
        }
    }

    getEndDate() {
        if (this.datepicker.toDate) {
            return `${this.datepicker.toDate.year}-${this.datepicker.toDate.month}-${this.datepicker.toDate.day + 1}`;
        }
    }

    getLog() {
        if (this.datepicker && this.datepicker.fromDate !== undefined) {
            this.authService.getSignupLog(this.page, this.getStartDate(), this.getEndDate())
                .subscribe(logs => this.logs = logs);
        } else {
            setTimeout(() => this.getLog(), 500);
        }
    }

    getLogCount() {
        if (this.datepicker && this.datepicker.fromDate !== undefined) {
            let dates;
            if (this.datepicker.fromDate) {
                dates = {
                    date_range_start: this.getStartDate(),
                    date_range_end: this.getEndDate(),
                };
            }

            if (this.stats_chart) {
                this.stats_chart.destroy();
            }

            this.authService.getSignupLogCount(dates)
            .subscribe(counts => {
                this.signup_counts = counts;
                this.fillDateRange();
                this.makeChart();
            });
        } else {
            setTimeout(() => this.getLogCount(), 500);
        }
    }

    fillDateRange() {
        const range = getDateRange(this.datepicker.fromDate, this.datepicker.toDate);
        const exists = this.signup_counts.map(x => x.day);
        for (let date of range) {
            if (exists.indexOf(date) === -1) {
                this.signup_counts.push({'day': date, 'number_of_signups': 0});
            }
        }
        this.signup_counts.sort((a, b) => {
            if (a.day < b.day) {
                return -1;
            }

            if (a.day > b.day) {
                return 1;
            }

            return 0;
        });
    }

    makeChart() {
        const canvasElement = <HTMLCanvasElement>document.getElementById('chart-stats');
        const ctx = canvasElement.getContext('2d');

        this.stats_chart = new Chart(ctx, {
            type: 'bar',
            data: {
                datasets: [
                    {
                        label: `Total Signups`,
                        data: this.signup_counts.map(x => x.number_of_signups),
                    }
                ],
                labels: this.signup_counts.map(x => x.day),
            },
            options: {
                responsive: true,
            }
        });
    }

    getFullNumber() {
        return this.country_code + this.number_search.replace(/^0/, '');
    }

    filter() {
        let params = {
            start_date: this.getStartDate(),
            end_date: this.getEndDate(),
        };

        this.router.navigate([], {
            queryParams: params
        });
        this.getLogCount();
        this.getLog();
    }

}
