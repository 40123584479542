import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { RatesService } from '../rates.service';

import { Rate } from './rate';

declare var toastr: any;
declare var alertify: any;

@Component({
    selector: 'app-rates',
    templateUrl: './rates.component.html',
    styleUrls: ['./rates.component.css']
})
export class RatesComponent implements OnInit {

    rates: any; // NOTE: this is a paginated object
    page;
    active_rate: Rate = new Rate();
    sheets;
    active_sheet = null;

    @ViewChild('attachModal') attachModal;
    @ViewChild('rateDetail') rateDetail;

    constructor(
        private route: ActivatedRoute,
        private ratesService: RatesService
    ) { }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            this.page = +params.get('page');
            this.loadRates();
        });
        this.loadRates();
        this.ratesService.getSheets().subscribe(sheets => this.sheets = sheets);
    }

    loadRates() {
        this.ratesService.getAllRates(this.page).subscribe(rates => this.rates = rates);
        this.rates = null;
    }

    saveRate() {
        let response;
        if (this.active_rate.id) {
            response = this.ratesService.updateRate(this.active_rate);
        } else {
            response = this.ratesService.createRate(this.active_rate);
        }
        response.subscribe(rate => {
            if (rate.id) {
                toastr.success('Rate Saved!');
                this.loadRates();
                this.rateDetail.close();
            } else {
                this.rateDetail.stopLoading();
            }
        });
    }

    deleteRate(rate) {
        if (rate.deleted) {
            return false;
        }

        alertify.confirm('Are you sure you want to delete this rate?', () => {
            this.ratesService.deleteRate(rate)
            .subscribe(() => {
                toastr.success('Rate deleted!');
                this.loadRates();
            });
        });
    }

    attachRate() {
        if (!this.active_sheet) {
            toastr.error('Sheet required');
            this.attachModal.stopLoading();
            return false;
        }

        this.ratesService.attachRateToSheet(this.active_rate, this.active_sheet)
        .subscribe(() => {
            this.loadRates();
            toastr.success('Rate attached!');
            this.attachModal.close();
        });
    }

    newRate() {
        this.active_rate = new Rate();
        this.rateDetail.open();
    }

}
