import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LoginService } from '../../../login/login.service';
import { SwitchboardService } from '../../../switchboard/switchboard.service';
import { ServicesService } from '../../../services/services.service';

import { SwitchboardObject } from '../../../switchboard/switchboard_object';

declare var moment: any;

@Component({
    selector: 'app-portal-numbers',
    templateUrl: './portal-numbers.component.html',
    styleUrls: ['./portal-numbers.component.css']
})
export class PortalNumbersComponent implements OnInit {

    @Input() account_id;

    fragment;

    numbers: SwitchboardObject[];
    switchboard_objects: SwitchboardObject[];
    current_number: SwitchboardObject;
    services = {};

    constructor(
        private route: ActivatedRoute,
        private authService: LoginService,
        private switchboardService: SwitchboardService,
        private serviceService: ServicesService,
    ) { }

    ngOnInit() {
        this.route.fragment.subscribe(fragment => this.fragment = fragment);
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account_id = account.id;
            this.loadNumbers();
            this.loadSwitchboardObjects();
        });
    }

    loadNumbers() {
        this.switchboardService.readNumbersOnAccount(this.account_id)
            .subscribe(numbers => this.numbers = numbers);
    }

    loadSwitchboardObjects() {
        this.switchboardService.getSwitchboardObjectsForAccount(this.account_id)
            .subscribe(objects => this.switchboard_objects = objects);
        this.serviceService.getServicesForAccount(this.account_id, 'Number')
        .subscribe(services => {
            for (let service of services) {
                this.services[service.id] = service;
            }
        });
    }

    getSwitchboardObject(uuid) {
        if (this.switchboard_objects) {
            for (let object of this.switchboard_objects) {
                if (object.uuid === uuid) {
                    return object;
                }
            }
        }
        return null;
    }

}
