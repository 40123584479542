import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

declare var toastr: any;

@Injectable({
    providedIn: 'root'
})
export class SimwoodService {

    protected serviceURL = 'https://api.simwood.com';
    private apiuser = 'b6706731a2ad31084c079207b2b49a0a8f4a8617';
    private apipassword = '98485eab6414aabeec7930ac8a325fd4d3849e5f';
    private account = '930938';

    constructor(
        private http: HttpClient
    ) { }

    protected log(message: string) {
        console.log(`Service: ${message} `);
        // this.messageService.add(`HeroService: ${message}`);
    }

    protected handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            function outputMessages(messages) {
                for (let key in messages) {
                    let message = messages[key];
                    if (typeof message === 'string') {
                        message = [message];
                    }
                    for (let note of message) {
                        toastr.error(note);
                    }
                }
            }
            if (error.error.messages) {
                outputMessages(error.error.messages);
            }
            if (error.error.message) {
                toastr.error(error.error.message);
            }

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    protected callApi(method, path, data = {}) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + btoa(`${this.apiuser}:${this.apipassword}`),
            }),
            params: null
        };
        const url = this.serviceURL + path;
        if (method === 'get' || method === 'delete') {
            httpOptions.params = data;
            return this.http[method](url, httpOptions);
        }
        return this.http[method](url, data, httpOptions);
    }

    getInProgressCalls() {
        return this.callApi('get', `/v3/voice/${this.account}/inprogress/current`)
        .pipe(
            tap(_ => this.log(`get current calls in progress`)),
            catchError(this.handleError('getInProgressCalls', []))
        );
    }

    getChannelHistory() {
        return this.callApi('get', `/v3/voice/${this.account}/channels/history`)
        .pipe(
            tap(_ => this.log(`get current channel history`)),
            catchError(this.handleError('getChannelHistory', []))
        );
    }
}
