import { Component, OnInit } from '@angular/core';

import { LoginService } from '../../../login/login.service';
import { MediaService } from '../../../audio/media.service';

@Component({
    selector: 'app-voicemail-card',
    templateUrl: './voicemail-card.component.html',
    styleUrls: ['./voicemail-card.component.css']
})
export class VoicemailCardComponent implements OnInit {

    account;

    voicemail: any[];
    voicemail_count = null;
    voicemail_new = null;

    constructor(
        private authService: LoginService,
        private mediaService: MediaService,
    ) { }

    ngOnInit(): void {
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account = account;
            this.getVoicemails();
        });
    }

    getVoicemails() {
        this.mediaService.getAllVoicemails(this.account.id)
        .subscribe(voicemail => {
            this.voicemail = voicemail;
            this.countVoicemail();
        });
    }

    countVoicemail() {
        this.voicemail_count = 0;
        this.voicemail_new = 0;

        for (let extension in this.voicemail) {
            for (let inbox in this.voicemail[extension]) {
                if (inbox === 'new') {
                    this.voicemail_new += this.voicemail[extension][inbox].length;
                }
                this.voicemail_count += this.voicemail[extension][inbox].length;
            }
        }
    }

}
