import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { Address } from '../sections/addresses/address.model';

import { environment } from '../../environments/environment';

import { Observable, of } from 'rxjs';
import { catchError, map, tap, concatMap } from 'rxjs/operators';

import { Cacheable } from '../tools/cacheable';
import { Client } from '../tools/client';

import { AuthenticationHelper } from './authentication.helper';

declare var toastr: any;
declare var moment: any;

@Injectable({
    providedIn: 'root'
})

export class LoginService extends Client {

    public app: Cacheable<any> = new Cacheable<any>();
    public apps: Cacheable<any> = new Cacheable<any>();
    public key: Cacheable<any> = new Cacheable<any>();
    public user: Cacheable<any> = new Cacheable<any>();
    public account: Cacheable<any> = new Cacheable<any>();
    public roles: Cacheable<any> = new Cacheable<any>();

    protected serviceURL = environment.services.auth;


    constructor(
        http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
    ) {
        super(http);

        this.app.getHandler = () => {
            return this._getCurrentApp();
        };

        this.account.getHandler = () => {
            return this._getCurrentAccount();
        };

        this.user.getHandler = () => {
            return this._getCurrentUser();
        };

        this.key.getHandler = () => {
            return this._getKey();
        };

        this.roles.getHandler = () => {
            return this._getRolesForCurrentUser();
        };

        this.apps.getHandler = () => {
            return this._getApps();
        };

        this.refresh();
    }

    private billingAddressSource = new BehaviorSubject<Address>(null);
    currentBillingAddress = this.billingAddressSource.asObservable();
  
    updateBillingAddress(address: Address) {
      this.billingAddressSource.next(address);
    }
  

    refresh() {
        this.resetCache();
        this.app.refresh();
        this.account.refresh();
        this.user.refresh();
        this.key.refresh();
        this.roles.refresh();
        this.apps.refresh();
    }

    resetCache() {
        this.app.resetCache();
        this.account.resetCache();
        this.user.resetCache();
        this.key.resetCache();
        this.roles.resetCache();
        this.apps.resetCache();
    }

    hasRefreshed() {
        const cached_attributes = [
            this.app.hasData(),
            this.account.hasData(),
            this.user.hasData(),
            this.key.hasData(),
            this.roles.hasData(),
            this.apps.hasData()
        ];
        for (let attr of cached_attributes) {
            if (!attr) {
                return false;
            }
        }
        return true;
    }

    login(email, password, app_uuid): Observable<any> {
        return this.callApi('post', '/login', {
            email: email,
            password: password,
            app_uuid: app_uuid
        }).pipe(
            tap(login => this.log('attempted login')),
            tap(login => {
                if (login['authenticated']) {
                    AuthenticationHelper.setAuthKey(login['token']);
                }
            }),
            tap(login => this.refresh()),
            catchError(this.handleError('login', []))
        );
    }

    logout() {
        sessionStorage.clear();
        this.getCurrentUser()
        .subscribe(user => {
            this.deleteKeyForUser(user.account_id, user, AuthenticationHelper.getKey()).subscribe(response => {
                if (AuthenticationHelper.isProxy()) {
                    AuthenticationHelper.wipeProxyKey();
                    toastr.error(`Logged out of proxy login.`);
                    this.router.navigate(['/home']);
                    window.location.reload();
                } else {
                    AuthenticationHelper.wipeAuthKey();
                    this.refresh();
                    this.router.navigate(['/login']);
                }
            });
        });
    }

    _getApps() {
        return this.callApi('get', '/apps')
        .pipe(
            tap(apps => this.log('fetched apps'))
        );
    }

    getApps() {
        return this.apps.getData();
    }

    _getCurrentApp() {
        return this.callApi('get', '/app')
        .pipe(
            tap(apps => this.log('fetched app'))
        );
    }

    getCurrentApp() {
        return this.app.getData();
    }

    /* Users */

    getUsersForAccount(account) {
        return this.callApi('get', `/accounts/${account}/users`)
        .pipe(
            tap(apps => this.log('fetched users')),
            catchError(this.handleError('getUsersForAccount', []))
        );
    }

    getUsersForCurrentAccount() {
        return this.getCurrentUser()
            .pipe(concatMap(user => this.getUsersForAccount(user.account_id)));
    }

    _getCurrentUser() {
        return this.callApi('get', '/user')
        .pipe(
            tap(login => this.log('checking authenticated')),
            catchError(this.handleError('getCurrentUser', null))
        );
    }

    getCurrentUser() {
        return this.user.getData();
    }

    getUserConfig(account, user) {
        const user_id = user.id;
        return this.callApi('get', `/accounts/${account}/users/${user_id}/config`)
        .pipe(
            tap(apps => this.log('get user config ' + user_id + ' for account ' + account)),
            tap(() => this.user.refresh()),
            catchError(this.handleError('getUserConfig', []))
        );
    }

    createUserForAccount(account, user) {
        return this.callApi('post', `/accounts/${account}/users`, user)
        .pipe(
            tap(apps => this.log('created user for account ' + account)),
            catchError(this.handleError('createUserForAccount', []))
        );
    }

    updateUserForAccount(account, user) {
        const user_id = user.id;
        return this.callApi('put', `/accounts/${account}/users/${user_id}`, user)
        .pipe(
            tap(apps => this.log('modified user ' + user_id + ' for account ' + account)),
            tap(() => this.user.refresh()),
            catchError(this.handleError('updateUserForAccount', []))
        );
    }

    updateUserConfig(account, user, config) {
        const user_id = user.id;
        return this.callApi('put', `/accounts/${account}/users/${user_id}/config`, config)
        .pipe(
            tap(apps => this.log('modified user config ' + user_id + ' for account ' + account)),
            tap(() => this.user.refresh()),
            catchError(this.handleError('updateUserConfig', []))
        );
    }

    deleteUserForAccount(account, user) {
        const user_id = user.id;
        return this.callApi('delete', `/accounts/${account}/users/${user_id}`)
        .pipe(
            tap(apps => this.log('delete user ' + user_id + ' for account ' + account)),
            catchError(this.handleError('deleteUserForAccount', []))
        );
    }

    createUserForCurrentAccount(user) {
        return this.callApi('post', `/users`, user)
        .pipe(
            tap(apps => this.log('created user for current account')),
            catchError(this.handleError('createUserForCurrentAccount', []))
        );
    }

    updateUserForCurrentAccount(user) {
        const user_id = user.id;
        return this.callApi('put', `/users/${user_id}`, user)
        .pipe(
            tap(apps => this.log('modified user ' + user_id + ' for current account')),
            tap(() => this.user.refresh()),
            catchError(this.handleError('updateUserForCurrentAccount', []))
        );
    }

    deleteUserForCurrentAccount(user) {
        const user_id = user.id;
        return this.callApi('delete', `/users/${user_id}`)
        .pipe(
            tap(apps => this.log('delete user ' + user_id + ' for current account')),
            catchError(this.handleError('deleteUserForCurrentAccount', []))
        );
    }

    /* Account */

    getAccounts(page = 1, options = null) {
        if (!options) {
            options = {};
        }
        options.page = page;

        return this.callApi('get', '/accounts', options)
        .pipe(
            tap(login => this.log('getting account page ' + page)),
            catchError(this.handleError('getAccounts', []))
        );
    }

    getAccount(id) {
        return this.callApi('get', `/accounts/${id}`)
        .pipe(
            tap(login => this.log('getting account id ' + id)),
            catchError(this.handleError('getAccount', []))
        );
    }

    getAccountConfig(id) {
        return this.callApi('get', `/accounts/${id}/config`)
        .pipe(
            tap(login => this.log('getting account config id ' + id)),
            catchError(this.handleError('getAccountConfig', []))
        );
    }

    getAccountMeta(account_id) {
        return this.callApi('get', `/accounts/${account_id}/meta`)
        .pipe(
            tap(_ => this.log('fetching metadata for account ' + account_id)),
            catchError(this.handleError('getAccountMeta', []))
        );
    }

    getAccountSupportAuth(account_id) {
        return this.callApi('get', `/accounts/${account_id}/auth_code`)
        .pipe(
            tap(_ => this.log('fetching support auth for account ' + account_id)),
            catchError(this.handleError('getAccountSupportAuth', []))
        );
    }

    _getCurrentAccount() {
        return this.callApi('get', '/account')
        .pipe(
            tap(login => this.log('checking authenticated')),
            // catchError(this.handleError('getCurrentAccount', []))
        );
    }

    getCurrentAccount() {
        return this.account.getData();
    }

    createAccount(account) {
        return this.callApi('post', `/accounts`, account)
        .pipe(
            tap(apps => this.log('created account')),
            catchError(this.handleError('createAccount', []))
        );
    }

    updateAccount(account) {
        const account_id = account.id;
        return this.callApi('put', `/accounts/${account_id}`, account)
        .pipe(
            tap(apps => this.log('modified account ' + account)),
            tap(() => this.account.refresh()),
            catchError(this.handleError('updateAccount', []))
        );
    }

    updateAccountConfig(account, config) {
        const account_id = account.id;
        return this.callApi('put', `/accounts/${account_id}/config`, config)
        .pipe(
            tap(apps => this.log('modified account config ' + account)),
            tap(() => this.account.refresh()),
            catchError(this.handleError('updateAccountConfig', []))
        );
    }

    deleteAccount(account) {
        const account_id = account.id;
        return this.callApi('delete', `/accounts/${account_id}`)
        .pipe(
            tap(apps => this.log('delete account ' + account)),
            catchError(this.handleError('deleteAccount', []))
        );
    }

    /* Password reset */

    requestReset(app_uuid, email) {
        return this.callApi('post', `/password/request`, {app_uuid: app_uuid, email: email})
        .pipe(
            tap(() => this.log(`request password for ${email} on app ${app_uuid}`)),
            catchError(this.handleError('requestReset', []))
        );
    }

    resetPassword(user, token, pass1, pass2) {
        return this.callApi('post', `/password/reset/${user}/${token}`, {
            password: pass1,
            password_confirmation: pass2,
        }).pipe(
            tap(() => this.log(`password reset`)),
        );
    }

    /* Roles */

    getPossibleRoles() {
        return this.callApi('get', '/definitions/roles')
        .pipe(
            tap(apps => this.log('fetched possible roles')),
            catchError(this.handleError('getPossibleRoles', []))
        );
    }

    _getRolesForCurrentUser() {
        return this.getCurrentUser()
            .pipe(concatMap(user => this.getUserRolesByAccount(user.account_id, user.id)));
    }

    getRolesForCurrentUser() {
        return this.roles.getData();
    }

    getRolesForUser(user) {
        return this.getUserRolesByAccount(user.account_id, user.id);
    }

    giveRoleToUser(user, role) {
        return this.callApi('post', `/accounts/${user.account_id}/users/${user.id}/roles/${role.id}`)
        .pipe(
            tap(apps => this.log(`gave role ${role.name} for user ${user.id}`)),
            tap(() => this.roles.refresh()),
            catchError(this.handleError('giveRoleToUser', []))
        );
    }

    removeRoleFromUser(user, role) {
        return this.callApi('delete', `/accounts/${user.account_id}/users/${user.id}/roles/${role.id}`)
        .pipe(
            tap(apps => this.log(`removed role ${role.name} for user ${user.id}`)),
            tap(() => this.roles.refresh()),
            catchError(this.handleError('removeRoleFromUser', []))
        );
    }

    getUserRolesByAccount(account_id, user_id) {
        return this.callApi('get', `/accounts/${account_id}/users/${user_id}/roles`)
        .pipe(
            tap(apps => this.log(`fetched roles for user ${user_id} from account ${account_id}`)),
            catchError(this.handleError('getUserRolesByAccount', []))
        );
    }

    /* Addresses */

    getAddresses(account_id) {
        return this.callApi('get', `/accounts/${account_id}/address_records`)
        .pipe(
            tap(apps => this.log(`fetched addresses for user ${account_id}`)),
            catchError(this.handleError('getAddresses', []))
        );
    }

    getAdminAddresses(page = 1) {
        return this.callApi('get', `/admin/address_records_needing_approval`, {page: page})
        .pipe(
            tap(apps => this.log(`fetching addresses needing approval`)),
            catchError(this.handleError('getAdminAddresses', []))
        );
    }

    createAddress(account_id, address) {
        return this.callApi('post', `/accounts/${account_id}/address_records`, address)
        .pipe(
            tap(apps => this.log(`create address for user ${account_id}`)),
            catchError(this.handleError('createAddress', []))
        );
    }

    updateAddress(account_id, address) {
        return this.callApi('put', `/accounts/${account_id}/address_records/${address.id}`, address)
        .pipe(
            tap(apps => this.log(`update address ${address.id} for user ${account_id}`)),
            catchError(this.handleError('updateAddress', []))
        );
    }

    deleteAddress(account_id, address) {
        return this.callApi('delete', `/accounts/${account_id}/address_records/${address.id}`)
        .pipe(
            tap(apps => this.log(`delete address ${address.id} for user ${account_id}`)),
            catchError(this.handleError('deleteAddress', []))
        );
    }

    getCountryCodes() {
        return this.callApi('get', `/definitions/country_codes`)
        .pipe(
            tap(apps => this.log(`fetched country codes`)),
            catchError(this.handleError('getCountryCodes', []))
        );
    }

    /* Verify */

    verifyUser(user, code) {
        return this.callApi('post', `/users/${user}/verify`, {
            verify_code: code,
        })
        .pipe(
            tap(apps => this.log(`verify user ${user}`)),
            catchError(this.handleError('verifyUser', []))
        );
    }

    forceVerifyUser(account_id, user) {
        return this.callApi('post', `/accounts/${account_id}/users/${user}/force_verify`)
        .pipe(
            tap(apps => this.log(`force verify user ${user}`)),
            catchError(this.handleError('forceVerifyUser', []))
        );
    }

    getActiveSates() {
        return this.callApi('get', `/definitions/active_states`)
        .pipe(
            tap(apps => this.log(`get active states`)),
            catchError(this.handleError('getActiveSates', []))
        );
    }

    /* Keys */

    _getKey() {
        return this.callApi('get', `/token`)
        .pipe(
            tap(apps => this.log(`get current key`)),
            catchError(this.handleError('getKey', null))
        );
    }

    getKey() {
        return this.key.getData();
    }

    deleteKey() {
        return this.callApi('delete', `/token`)
        .pipe(
            tap(apps => this.log(`delete current key`)),
            catchError(this.handleError('deleteKey', []))
        );
    }

    getSpecificKey(key) {
        return this.callApi('get', `/tokens/${key}`)
        .pipe(
            tap(apps => this.log(`get specific key ${key}`)),
            catchError(this.handleError('getSpecificKey', []))
        );
    }

    getKeysForCurrentUser() {
        return this.callApi('get', `/user/tokens`)
        .pipe(
            tap(apps => this.log(`get current user keys`)),
            catchError(this.handleError('getKeysForCurrentUser', []))
        );
    }

    createKeyForCurrentUser(name, expire) {
        return this.callApi('post', `/user/tokens`, {
            name: name,
            expire_length_days: expire,
        })
        .pipe(
            tap(apps => this.log(`create key for current user`)),
            catchError(this.handleError('createKeyForCurrentUser', []))
        );
    }

    getKeysForUser(account_id, user) {
        return this.callApi('get', `/accounts/${account_id}/users/${user.id}/tokens`)
        .pipe(
            tap(apps => this.log(`get current user keys`)),
            catchError(this.handleError('getKeysForCurrentUser', []))
        );
    }

    createKeyForUser(account_id, user, name, expire) {
        return this.callApi('post', `/accounts/${account_id}/users/${user.id}/tokens`, {
            name: name,
            token_life_hours: expire,
        })
        .pipe(
            tap(apps => this.log(`create key for current user`)),
            catchError(this.handleError('createKeyForCurrentUser', []))
        );
    }

    deleteKeyForUser(account_id, user, token_id) {
        return this.callApi('delete', `/accounts/${account_id}/users/${user.id}/tokens/${token_id}`)
        .pipe(
            tap(apps => this.log(`create key for current user`)),
            catchError(this.handleError('createKeyForCurrentUser', []))
        );
    }

    sendVerificationEmail(account_id, user) {
        return this.callApi('post', `/accounts/${account_id}/users/${user.id}/send_verification`)
        .pipe(
            tap(apps => this.log(`send verification email for user`)),
            catchError(this.handleError('sendVerificationEmail', []))
        );
    }

    authenticate2FAToken(token, auth_code) {
        return this.callApi('post', `/token/authenticate`, {
            token: token,
            auth_code: auth_code,
        })
        .pipe(
            tap(apps => this.log(`send verification email for user`)),
            catchError(this.handleError('authenticate2FAToken', 'FAIL_AUTH'))
        );
    }

    /* Email Bans */

    getEmailBans(page = 1, search = null) {
        let data = { page: page };
        if (search) {
            data['email'] = search;
        }
        return this.callApi('get', '/emailbans', data)
        .pipe(
            tap(login => this.log('getting email ban page ' + page)),
            catchError(this.handleError('getEmailBans', []))
        );
    }

    getEmailBan(id) {
        return this.callApi('get', '/emailbans/' + id)
        .pipe(
            tap(login => this.log('getting email ban id ' + id)),
            catchError(this.handleError('getEmailBan', []))
        );
    }

    createEmailBan(email) {
        return this.callApi('post', `/emailbans`, { email: email })
        .pipe(
            tap(apps => this.log('created email ban')),
            catchError(this.handleError('createEmailBan', []))
        );
    }

    updateEmailBan(email) {
        return this.callApi('put', `/emailbans/${email.id}`, email)
        .pipe(
            tap(apps => this.log('modified email ban ' + email)),
            catchError(this.handleError('updateEmailBan', []))
        );
    }

    deleteEmailBan(email) {
        return this.callApi('delete', `/emailbans/${email.id}`)
        .pipe(
            tap(apps => this.log('delete email ban ' + email.id)),
            catchError(this.handleError('deleteEmailBan', []))
        );
    }

    getSignupLog(page = 1, date_start = null, date_end = null) {
        let data = { page: page };
        if (date_start && date_end) {
            data['date_range_start'] = date_start;
            data['date_range_end'] = date_end;
        }
        return this.callApi('get', '/admin/signup_logs', data)
        .pipe(
            tap(login => this.log('getting signup log page ' + page)),
            catchError(this.handleError('getEmailBan', []))
        );
    }

    addSignupLog(email) {
        return this.callApi('post', `/admin/signup_logs`, { email: email })
        .pipe(
            tap(apps => this.log('created signup log')),
            catchError(this.handleError('addSignupLog', []))
        );
    }

    getAccountTotals() {
        return this.callApi('get', `/accounts/count`)
        .pipe(
            tap(apps => this.log(`get current account counts`)),
            catchError(this.handleError('getAccountTotals', []))
        );
    }

    getSignupLogCount(dates = {}) {
        return this.callApi('get', `/admin/signup_logs/count`, dates)
        .pipe(
            tap(apps => this.log('get signup log count')),
            catchError(this.handleError('getSignupLogCount', []))
        );
    }

    getAccountEventLog(page = 1, account_id = null, event_type = null, date_start = null, date_end = null) {
        let data = { page: page };
        if (account_id) {
            data['account_id'] = account_id;
        }
        if (event_type) {
            data['event_type'] = event_type;
        }
        if (date_start && date_end) {
            data['date_range_start'] = date_start;
            data['date_range_end'] = date_end;
        }
        return this.callApi('get', '/admin/account_event_logs', data)
        .pipe(
            tap(login => this.log('getting account event log page ' + page)),
            catchError(this.handleError('getAccountEventLog', []))
        );
    }

    /* Reports */

    readAllReports() {
        return this.callApi('get', `/admin/reports`)
        .pipe(
            tap(login => this.log(`getting admin reports`)),
            catchError(this.handleError('readAllReports', []))
        );
    }

    readReportsOfName(name, page = 1) {
        return this.callApi('get', `/admin/reports/${name}`, { page: page })
        .pipe(
            tap(login => this.log(`getting admin reports of name ${name}`)),
            catchError(this.handleError('readReportsOfName', []))
        );
    }

    readReport(uuid) {
        return this.callApi('get', `/admin/reports/fetch/${uuid}`)
        .pipe(
            tap(login => this.log(`getting admin report of UUID ${uuid}`)),
            catchError(this.handleError('readReport', []))
        );
    }
}
