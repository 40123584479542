<div class="alert alert-secondary" style="display: none;">
    Get your virtual phone number today, no set up costs & no contracts. Instant set up and activation.
</div>

<div class="avatar-box">
    <figure class="user-avatar">
        <img ngxGravatar [email]="auth.email" size="128" fallback="mm" alt="User Gravatar">
    </figure>
</div>

<form role="form">
    <div class="form-group mb-3" *ngIf="!is_branded">
        <div class="input-group input-group-alternative">
            <select [(ngModel)]="app_uuid" name="app_uuid" class="form-control">
                <option value="">Select Application</option>
                <option *ngFor="let app of apps" value="{{ app.uuid }}">{{ app.name }}</option>
            </select>
        </div>
    </div>

    <div class="form-group mb-3">
        <div class="input-group input-group-alternative">
            <div class="input-group-prepend">
                <span class="input-group-text"><i class="ni ni-email-83"></i></span>
            </div>
            <input class="form-control" placeholder="Email" name="email" type="email" [(ngModel)]="auth.email">
        </div>
    </div>
    <div class="form-group">
        <div class="input-group input-group-alternative">
            <div class="input-group-prepend">
                <span class="input-group-text"><i class="ni ni-lock-circle-open"></i></span>
            </div>
            <input (keyup.enter)="login()" class="form-control" placeholder="Password" name="password" type="password" [(ngModel)]="auth.password">
        </div>
    </div>
    <!-- <div class="custom-control custom-control-alternative custom-checkbox">
        <input class="custom-control-input" id=" customCheckLogin" type="checkbox">
        <label class="custom-control-label" for=" customCheckLogin">
            span class="text-muted">Remember me</span>
        </label>
    </div> -->
    <div class="text-center">
        <button type="button" class="btn btn-primary my-4" (click)="login()">Sign in</button>
        <br>
        <a (click)="forgot_password.open()" class="password-forgot btn btn-link">Forgot password?</a>
    </div>
</form>

<app-modal title="Forgot Password" (save)="requestReset()" #forgot_password>
    <div class="field" *ngIf="!is_branded">
        <div class="control">
            <div class="select is-large">
                <select [(ngModel)]="app_uuid" name="app_uuid" class="form-control">
                    <option *ngFor="let app of apps" value="{{ app.uuid }}">{{ app.name }}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="field">
        <div class="control">
            <input class="input is-large form-control" type="email" placeholder="Your Email" autofocus="" [(ngModel)]="auth.email" name="email">
        </div>
    </div>
</app-modal>

<app-modal title="Security Authentication" (save)="sendTwoFactor()" #twofactor_auth>
    An email has been sent with a verification code. Please check your emails and enter your 6 digit verification code.

    <div class="field">
        <div class="control">
            <input class="input is-large form-control" type="text" placeholder="Your Security Code" autofocus="" [(ngModel)]="auth_attempt.auth_code">
        </div>
    </div>
</app-modal>
