<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow extrapadding">
            <!-- <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Welcome! </h2>
                    </div>
                </div>
            </div> -->
            <div class="container">
                <!-- <h3>Need Help?</h3> -->
                    <!-- <div class="row no-gutters justify-content-start">
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 no-gutters">
                            <div class="video-container">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/klkojnja1bU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                        </div> -->
                        <div class="voip-user-status">
                            <ng-container>
                                <h3>VoIP User Status/Setup</h3>
                                <a class="card-stats mb-4 mb-xl-0" *ngIf="this.sips.length" (click)="toggleVoIPCard();">
                                    <div class="bound-parent">
                                        <div class="bound-box">
                                            <div class="voip-grid">
                                                <div *ngFor="let switchboard_object of sips; let i = index" class="voip-card-background">
                                                    <a class="text-center" (click)="setupUser(switchboard_object)">
                                                        <div class="voip-card cardrounded">
                                                            <p class="voip-title">{{ switchboard_object.object_data.name }}</p>
                                                
                                                            <!-- Using ngSwitch for Conditional Rendering -->
                                                            <div [ngSwitch]="busyLights[switchboard_object.extension_number]">
                                                                <!-- Conditional Icon -->
                                                                <img class="voip-card-icon" *ngSwitchCase="'In use'" src="https://content.si-p.co.uk/img/icons/busyicon.png">
                                                                <img class="voip-card-icon" *ngSwitchCase="'Not in use'" src="https://content.si-p.co.uk/img/icons/readyicon.png">
                                                                <img class="voip-card-icon" *ngSwitchDefault src="https://content.si-p.co.uk/img/icons/useroffline.png">
                                                
                                                                <!-- Conditional Text -->
                                                                <p class="voip-card-text" *ngSwitchCase="'In use'">On a call</p>
                                                                <p class="voip-card-text" *ngSwitchCase="'Not in use'">Online</p>
                                                                <p class="voip-card-text" *ngSwitchDefault>Offline - Click to Set Up</p>
                                                            </div>
            
                                                            <div class="hover-content" [ngClass]="getConditionalText(switchboard_object).class">
                                                                {{ getConditionalText(switchboard_object).text }}
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                
                                                
                                            </div>
                                            <br>
                                        </div>
                                    </div>
                                </a>
                            </ng-container>
                        </div>

                <h3>Start Here</h3>
                
                <div class="row">
                    <div class="col">
                        <div class="card-columns">
                            <a class="card text-center" routerLink="/shop" *appViperHasUserRole="'Admin'">
                                <div class="card-body darkcard cardrounded">
                                    <i class="fas fa-shopping-cart"></i>
                                    <h5 class="card-title">Shop</h5>
                                </div>
                            </a>
                            <a class="card text-center" routerLink="/flow">
                                <div class="card-body darkcard cardrounded">
                                    <i class="fas fa-exchange-alt"></i>
                                    <h5 class="card-title">Call Flow Manager</h5>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>


                <ng-container *appViperHasUserRole="'Admin'">
                    <h3>Admin Links</h3>
                    <div class="card-columns">
                        <a class="card text-center" routerLink="/accounts">
                            <div class="card-body darkcard cardrounded">
                                <i class="fa fa-user-circle"></i>
                                <span class="badge badge-pill badge-contrast badge-success">New</span>
                                <h5 class="card-title">Accounts</h5>
                            </div>
                        </a>
                        <div style="height:9em;"></div>
                    </div>
                </ng-container>

                <h3>Quick Links</h3>
                
                <div class="card-columns">
                    <a class="card text-center" routerLink="/numbers" *appViperServiceExists="'Number'">
                        <div class="card-body darkcard cardrounded">
                            <i class="fas fa-cloud"></i>
                            <h5 class="card-title">My Phone Numbers</h5>
                        </div>
                    </a>
                    <a class="card text-center" routerLink="/time-management">
                        <div class="card-body darkcard cardrounded">
                            <i class="far fa-clock"></i>
                            <h5 class="card-title">Time Management</h5>
                        </div>
                    </a>
                    <a class="card text-center" routerLink="/temporary-close">
                        <div class="card-body darkcard cardrounded">
                            <i class="far fa-calendar-times"></i>
                            <h5 class="card-title">Temporary Close</h5>
                        </div>
                    </a>
                    <a class="card text-center" routerLink="/topup" *appViperIsNotPostpay>
                        <div class="card-body darkcard cardrounded">
                            <i class="fas fa-coins"></i>
                            <h5 class="card-title">Top Up</h5>
                        </div>
                    </a>

                    <a class="card text-center" routerLink="/sips" *appViperServiceExists="'Sip'">
                        <div class="card-body darkcard cardrounded">
                            <i class="fas fa-phone-volume"></i>
                            <h5 class="card-title">My VoIP Users</h5>
                        </div>
                    </a>
                    <a class="card text-center" routerLink="/call-stats">
                        <div class="card-body darkcard cardrounded">
                            <i class="fas fa-history"></i>
                            <h5 class="card-title">Call Stats &amp; History</h5>
                        </div>
                    </a>

                    <a class="card text-center" routerLink="/media">
                        <div class="card-body darkcard cardrounded">
                            <i class="fa fa-file-audio"></i>
                            <h5 class="card-title">My Media</h5>
                        </div>
                    </a>

                    <a class="card text-center" href="https://docs.google.com/forms/d/e/1FAIpQLSdlkuQOU1iMhomvjbfnCqhOdopFlfueEsaPs4eWxo5xAyMsWQ/viewform" target="_blank" *appViperBrandIs="['np']">
                        <div class="card-body darkcard cardrounded">
                            <i class="fa fa-cloud"></i>
                            <h5 class="card-title">Port a Number</h5>
                        </div>
                    </a>

                    <a class="card text-center" routerLink="/numbers" fragment="999" *appViperServiceExists="'Number'">
                        <div class="card-body darkcard cardrounded">
                            <i class="fas fa-ambulance"></i>
                            <h5 class="card-title">999 Registration</h5>
                        </div>
                    </a>

                    <a class="card text-center" routerLink="/groups" fragment="call-whisper" *appViperServiceExists="'Group'">
                        <div class="card-body darkcard cardrounded">
                            <i class="fas fa-user-secret"></i>
                            <h5 class="card-title">Call whisper</h5>
                        </div>
                    </a>

                    <!-- <a class="card text-center" routerLink="/music-on-hold">
                        <div class="card-body darkcard cardrounded">
                            <i class="fas fa-music"></i>
                            <h5 class="card-title">Music On Hold</h5>
                        </div>
                    </a> -->

                    <a class="card text-center" routerLink="/help" *appViperIsNotPostpay>
                        <div class="card-body darkcard cardrounded">
                            <i class="fas fa-question-circle"></i>
                            <h5 class="card-title">Help</h5>
                        </div>
                    </a>
                    <div _ngcontent-lna-c134="" style="height: 9em;"></div>
                </div>

                <h3>System</h3>

                <div class="card-columns">
                    <a class="card text-center" routerLink="/numbers" *appViperServiceExists="'Number'">
                        <div class="card-body darkcard cardrounded">
                            <i class="fas fa-cloud"></i>
                            <h5 class="card-title">My Phone Numbers</h5>
                        </div>
                    </a>
                    <a class="card text-center" routerLink="/groups" *appViperServiceExists="'Group'">
                        <div class="card-body darkcard cardrounded">
                            <i class="fas fa-users"></i>
                            <h5 class="card-title">Call Forwarding Groups</h5>
                        </div>
                    </a>
                    <a class="card text-center" routerLink="/menus" *appViperServiceExists="'Menu'">
                        <div class="card-body darkcard cardrounded">
                            <i class="fas fa-bars"></i>
                            <h5 class="card-title">IVR Menus</h5>
                        </div>
                    </a>
                    <a class="card text-center" routerLink="/announcements" *appViperServiceExists="'Announcement'">
                        <div class="card-body darkcard cardrounded">
                            <i class="fas fa-bullhorn"></i>
                            <h5 class="card-title">Intro Announcements</h5>
                        </div>
                    </a>
                    <a class="card text-center" routerLink="/voicemails" *appViperServiceExists="'Voicemail'">
                        <div class="card-body darkcard cardrounded">
                            <i class="fas fa-microphone-alt"></i>
                            <h5 class="card-title">Voicemail Inboxes</h5>
                        </div>
                    </a>
                    <!-- <a class="card text-center" routerLink="/queues" *appViperServiceExists="'Queue'">
                        <div class="card-body">
                            <i class="fas fa-hourglass"></i>
                            <h5 class="card-title">Queues</h5>
                        </div>
                    </a> -->

                    <a class="card text-center" routerLink="/channels" *appViperServiceExists="'Channel'">
                        <div class="card-body darkcard cardrounded">
                            <i class="fas fa-code-branch"></i>
                            <h5 class="card-title">Call Channels</h5>
                        </div>
                    </a>

                    <a class="card text-center" routerLink="/inbound-bundles" *appViperHasInboundBundles>
                        <div class="card-body darkcard cardrounded">
                            <i class="fas fa-star"></i>
                            <h5 class="card-title">Inbound Call Bundles</h5>
                        </div>
                    </a>

                    <a class="card text-center" routerLink="/outbound-bundles" *appViperHasOutboundBundles>
                        <div class="card-body darkcard cardrounded">
                            <i class="fas fa-star"></i>
                            <h5 class="card-title">Outbound Call Bundles</h5>
                        </div>
                    </a>
                    <ng-container *ngIf="isTrial == false || isVV == false">
                        <a class="card text-center" routerLink="/call-recordings" *appViperServiceExists="'CallRecording'">
                            <div class="card-body darkcard cardrounded">
                                <i class="fas fa-compact-disc"></i>
                                <h5 class="card-title">Call Recordings</h5>
                            </div>
                        </a>
                    </ng-container>
                    <!-- <div _ngcontent-lna-c134="" style="height: 9em;"></div> -->
                </div>

                <h3>Account</h3>
                
                <div class="card-columns">
                    <a class="card text-center" routerLink="/settings" *appViperHasUserRole="'Account Owner'">
                        <div class="card-body darkcard cardrounded">
                            <i class="ni ni-settings-gear-65"></i>
                            <h5 class="card-title">Account Settings</h5>
                        </div>
                    </a>
                    <a class="card text-center" routerLink="/call-restrictions">
                        <div class="card-body darkcard cardrounded">
                            <i class="fas fa-phone-slash"></i>
                            <h5 class="card-title">Call Blocking</h5>
                        </div>
                    </a>

                    <a class="card text-center" routerLink="/settings/users" *appViperHasUserRole="'Account Owner'">
                        <div class="card-body darkcard">
                            <i class="fas fa-users"></i>
                            <h5 class="card-title">Account Users</h5>
                        </div>
                    </a>

                    <a class="card text-center" routerLink="/settings/addresses" *appViperHasUserRole="'Account Owner'">
                        <div class="card-body darkcard">
                            <i class="far fa-address-card"></i>
                            <h5 class="card-title">My Addresses</h5>
                        </div>
                    </a>

                    <a class="card text-center" routerLink="/settings/services" *appViperHasUserRole="'Account Owner'">
                        <div class="card-body darkcard">
                            <i class="fas fa-concierge-bell"></i>
                            <h5 class="card-title">My Services</h5>
                        </div>
                    </a>

                    <div style="height:9em;"></div>

                </div>

                <h3 *ngIf="view_financial">Financial</h3>

                <div *ngIf="view_financial" class="card-columns">
                    <ng-container *appViperIsNotPostpay>
                        <a class="card text-center" routerLink="/topup">
                            <div class="card-body darkcard cardrounded">
                                <i class="fas fa-coins"></i>
                                <h5 class="card-title">Top Up Credit</h5>
                            </div>
                        </a>
                        <a class="card text-center" routerLink="/auto-topup" *appViperHasUserRole="'Account Owner'">
                            <div class="card-body darkcard cardrounded">
                                <i class="fas fa-coins"></i>
                                <h5 class="card-title">Auto Top Up</h5>
                            </div>
                        </a>
                    </ng-container>
                    <a class="card text-center" routerLink="/invoices" *appViperHasUserRole="'Account Owner'">
                        <div class="card-body darkcard cardrounded">
                            <i class="fas fa-file-alt"></i>
                            <h5 class="card-title">Invoices</h5>
                        </div>
                    </a>
                    <div style="height:9em;"></div>

                </div>

                

                <!--
                <h3>Wizards</h3>

                <div class="card-columns">
                    <a class="card text-center" routerLink="#">
                        <div class="card-body">
                            <i class="fas fa-door-closed"></i>
                            <h5 class="card-title">Quick Close</h5>
                        </div>
                    </a>
                    <a class="card text-center" routerLink="#">
                        <div class="card-body">
                            <i class="fas fa-share-square"></i>
                            <h5 class="card-title">Quick Divert</h5>
                        </div>
                    </a>
                </div>
                -->

                <h3>Statistics</h3>

                <div class="card-columns">
                    <a class="card text-center" routerLink="/call-stats">
                        <div class="card-body darkcard cardrounded">
                            <i class="fas fa-chart-line"></i>
                            <h5 class="card-title">Call Stats</h5>
                        </div>
                    </a>
                    <div style="height:9em;"></div>
                </div>
            </div>

        </div>
    </div>
</div>
