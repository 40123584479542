import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

declare var moment: any;

@Pipe({name: 'format_minutes'})
export class FormatMinutesPipe implements PipeTransform {

    transform(value: any) {
        const seconds = value % 60;
        const minutes =  (value - seconds) / 60;
        const secondsString = String(seconds).padStart(2, '0');
        const minutesString = String(minutes).padStart(2, '0');
        return `${minutesString}:${secondsString}`;
    }
}
