<button class="btn btn-lg btn-block btn-outline-success text-lg" (click)="newHandset()">
    <i class="fas fa-plus "></i>
    Add New Handset
</button>

<br>

<div class="row headset-card-columns">
    <ng-container *ngIf="handsets?.length else no_handsets">
        <ng-container *ngFor="let display_handset of handsets">
            <ng-container *ngIf="display_handset.device_manufacturer !== 'IPVN'">
                <div class="col-xl-3 col-md-4 col-sm-6 col-12">
                    <a class="card text-center" (click)="editHandset(display_handset)">
                        <div class="card-body darkcard cardrounded">
                            <i class="fas fa-phone"></i>
                            <h5 class="card-title">
                                {{ display_handset.device_manufacturer }}
                                <br>
                                {{ display_handset.device_model }}
                                <br>
                                <span class="text-sm-center text-muted">({{ display_handset.mac_code }})</span>
                            </h5>
                            <br>
                            <ol>
                                <ng-container *ngFor="let line of display_handset.sip_device_lines">
                                    <li *ngIf="getLineSip(line) as handset_sip">
                                        <app-forwarding-to-partial [destination]="handset_sip"></app-forwarding-to-partial>
                                    </li>
                                </ng-container>
                            </ol>
                        </div>
                    </a>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
<div *ngIf="admin">
    <hr>
    <h2>Cloud Softphones</h2>
    <br>
</div>
<div class="row headset-card-columns">
    <ng-container *ngIf="handsets?.length else no_handsets">
        <ng-container *ngFor="let display_handset of handsets">
            <ng-container *ngIf="display_handset.device_manufacturer == 'IPVN' && admin">
                <div class="col-xl-3 col-md-4 col-sm-6 col-12">
                    <a class="card text-center" (click)="editHandset(display_handset)">
                        <div class="card-body darkcard cardrounded">
                            <i class="fas fa-phone"></i>
                            <h5 class="card-title">
                                {{ display_handset.device_manufacturer }}
                                <br>
                                {{ display_handset.device_model }}
                                <br>
                                <span class="text-sm-center text-muted">({{ display_handset.mac_code }})</span>
                            </h5>
                            <br>
                            <ol>
                                <ng-container *ngFor="let line of display_handset.sip_device_lines">
                                    <li *ngIf="getLineSip(line) as handset_sip">
                                        <app-forwarding-to-partial [destination]="handset_sip"></app-forwarding-to-partial>
                                    </li>
                                </ng-container>
                            </ol>
                        </div>
                    </a>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</div>


<ng-template #no_handsets>
    <tr class="tr">
        <td class="td" colspan="2" *ngIf="handsets else is_loading">No handsets found</td>
    </tr>
</ng-template>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>

<app-modal (save)="saveHandset()" #handset_modal>
    <div class="field" *ngIf="!current_handset.id">
        <label class="label">Device Manufacturer</label>
        <select class="input form-control" [(ngModel)]="current_handset.device_manufacturer">
            <option value="">Select Manufacturer</option>
            <option *ngFor="let device of available_devices|keyvalue" [value]="device.key">{{ device.key }}</option>
        </select>
    </div>

    <div class="field" *ngIf="!current_handset.id && current_handset.device_manufacturer">
        <label class="label">Device Model</label>
        <select class="input form-control" [(ngModel)]="current_handset.device_model">
            <option value="">Select Model</option>
            <option *ngFor="let device of available_devices[current_handset.device_manufacturer]|keyvalue" [value]="device.key">{{ device.key }}</option>
        </select>
    </div>

    <div class="field" *ngIf="!current_handset.id">
        <label class="label">
            MAC Code
            <a style="cursor: pointer; " tabindex="0" data-toggle="popover" data-trigger="focus" title="" data-content="This is usually on a label on the back of the phone"><i class="far fa-question-circle"></i></a>

        </label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="MAC Code" [(ngModel)]="current_handset.mac_code">
        </div>
    </div>

    <ng-container *ngIf="current_handset.id">
        <h4 *ngIf="current_handset.id">{{ current_handset.device_manufacturer }} {{ current_handset.device_model }} <span class="text-muted">({{ current_handset.mac_code }})</span></h4>
        <a 
            class="btn btn-secondary is-link"
            *ngIf="available_devices[current_handset.device_manufacturer][current_handset.device_model].blf_lines"
            [routerLink]="admin ? ['/handsets', account_id, 'speed-dial', current_handset.id] : ['/handsets', 'speed-dial', current_handset.id]"
        >Speed Dial</a>
        <button *ngIf="admin" class="btn btn-secondary is-link" (click)="provisionHandset(current_handset)">Provision</button>
        <button class="btn btn-danger is-danger" (click)="deleteHandset(current_handset)">Delete</button>
    </ng-container>

    <ng-container *ngIf="current_handset.provision_path && current_handset.device_manufacturer !== 'IPVN'">
        <hr>
        <p>Please click the link below to see a guide on how to get your device ready to provision</p>
        <p>
            <a href="{{ current_handset.help_path }}" class="btn btn-info" target="_blank">Handset setup guide</a>
        </p>
        <h3>Configuration Server Path</h3>
        <pre><code>{{ current_handset.provision_path }}</code></pre>
        <button class="btn btn-info" (click)="addToClipboard()">Copy Provision Link</button>
    </ng-container>

    <hr>

    <div class="accordion" id="auth-details-accordion" *ngIf="current_handset.device_manufacturer !== 'IPVN'">
        <div class="accordion-item">
            <h3 class="accordion-header" id="auth-details-heading">
                <a class="accordion-button" type="button" data-toggle="collapse" data-target="#auth-details" aria-expanded="true" aria-controls="auth-details" style="cursor: pointer; ">
                    Handset Login <i class="fas fa-chevron-circle-right"></i>
                </a>
                &nbsp;
                <a style="cursor: pointer; " tabindex="0" data-toggle="popover" data-trigger="focus" title="" data-content="Set a new username/password - This is used when logging directly into the handset"><i class="far fa-question-circle"></i></a>
            </h3>
            <div id="auth-details" class="collapse" [class.show]="!current_handset.id" aria-labelledby="auth-details-heading" data-parent="#auth-details-accordion">
                <div class="accordion-body">
                    <div class="field">
                        <label class="label">
                            Admin Username
                        </label>
                        <div class="control">
                            <input class="input form-control" type="text" placeholder="Admin Username" [(ngModel)]="current_handset.admin_username">
                        </div>
                    </div>

                    <div class="field">
                        <label class="label">Admin Password</label>
                        <div class="control">
                            <input class="input form-control" type="password" placeholder="Admin password" [(ngModel)]="current_handset.admin_password">
                        </div>
                    </div>

                    <div class="field" *ngIf="admin">
                        <label class="label">Local SIP Port</label>
                        <div class="control">
                            <input class="input form-control" type="text" placeholder="Local SIP Port" [(ngModel)]="current_handset.local_sip_port">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="current_handset.id && current_handset.device_manufacturer !== 'IPVN'">
        <hr>
        <h3>Device Lines</h3>
        <h4 class="text-muted">{{ linesUsed() }} out of {{ current_handset.sip_device_lines.length }} used</h4>
        <div class="field" *ngFor="let line of current_handset.sip_device_lines">
            <div class="control" *ngIf="linesUsed() >= line.line_number - 1 || line.switchboard_object_uuid">
                <label class="label">Line {{ line.line_number }}</label>
                &nbsp;
                <span class="text-muted text-sm" *ngIf="getLineSip(line) as sip">
                    <ng-container *ngIf="blfs[sip.extension_number] as state">
                        <i class="fas fa-circle text-lg"
                           [title]="state"
                           [class.text-danger]="state == 'In use'"
                           [class.text-blinking]="state == 'Ringing'"
                           [class.text-success]="state == 'Not in use'"
                        ></i>
                    </ng-container>
                </span>
                <app-switchboard-object-dropdown [(model)]="line.switchboard_object_uuid"
                                                 [allow_none]="true"
                                                 [switchboard_objects]="available_sips"></app-switchboard-object-dropdown>
            </div>
        </div>
    </ng-container>

    
</app-modal>

<app-modal title="Set Line Destination" (save)="saveLineDestination()" #destination_modal>
    <app-switchboard-object-dropdown [(model)]="device_line.switchboard_object_uuid"
                                     [allow_none]="true"
                                     [switchboard_objects]="sips"></app-switchboard-object-dropdown>
</app-modal>

