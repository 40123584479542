<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Sub Service</h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <ng-container *ngIf="subservice else is_loading">
                    <div class="field">
                        <label class="label">Name</label>
                        <div class="control">
                            <input class="input form-control" type="text" placeholder="Name" [(ngModel)]="subservice.name">
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Sub Service Type</label>
                        <br>
                        {{ subservice.switchboard_type }}
                    </div>
                    <div class="field">
                        <label class="label">Retail Cost</label>
                        <div class="control">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text" id="btnGroupAddon">&pound;</div>
                                </div>
                                <input class="input form-control" type="text" placeholder="Retail Cost" [(ngModel)]="subservice.retail_cost">
                            </div>
                        </div>
                    </div>
                    <!-- <div class="field">
                        <label class="label">Renew Days</label>
                        <div class="control">
                            <input class="input form-control" type="text" placeholder="Days to renew (0 = doesn't renew)" [(ngModel)]="subservice.renew_days">
                        </div>
                    </div> -->

                    <div class="field">
                        <label class="label">Feature Code</label>
                        <div class="control">
                            <!-- <input class="input form-control" type="text" placeholder="" [(ngModel)]="subservice.feature_code"> -->
                            <select class="input form-control" [(ngModel)]="subservice.feature_code">>
                                <option value="">[None]</option>
                                <option value="GROUP_ID">Group ID</option>
                            </select>
                        </div>
                    </div>

                    <app-tabs *ngIf="['Number', 'Sip', 'Shared Bundle'].indexOf(subservice.switchboard_type) !== -1">
                        <app-tab name="Bundles" [isActive]="true">
                            <table class="table" *ngIf="bundles else is_loading">
                                <thead class="thead">
                                    <tr class="tr">
                                        <th class="th">Name</th>
                                        <th class="th">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody class="tbody">
                                    <tr class="tr" *ngFor="let bundle of bundles">
                                        <td class="td">{{ bundle.name }}</td>
                                        <td class="td"><button class="btn btn-danger is-danger" (click)="detatchBundle(bundle)" [class.is-loading]="bundle.deleted">Detatch</button></td>
                                    </tr>
                                    <tr class="tr" *ngIf="bundles.length === 0">
                                        <td class="td" colspan="2">This subservice has no bundles</td>
                                    </tr>
                                    <tr class="tr" *ngIf="bundles === null">
                                        <td class="td" colspan="2"><app-loadingbar></app-loadingbar></td>
                                    </tr>
                                </tbody>
                                <tfoot class="tfoot">
                                <tr class="tr">
                                    <th class="th" colspan="2"><button class="btn btn-primary is-primary" (click)="bundleModal.open()">Attach Bundle</button></th>
                                </tr>
                                </tfoot>
                            </table>

                            <app-modal title="Attach Bundle" (save)="attachBundle()" #bundleModal>
                                <div class="field">
                                    <label class="label">Bundle</label>
                                    <div class="control">
                                        <div class="select">
                                            <select [(ngModel)]="attachedBundle" class="form-control">
                                                <ng-container *ngFor="let bundle of availableBundles">
                                                    <option *ngIf="notAttachedBundle(bundle)" value="{{ bundle.id }}">{{ bundle.name }}</option>
                                                </ng-container>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </app-modal>
                        </app-tab>
                    </app-tabs>

                    <div class="field is-grouped">
                        <button class="btn btn-primary is-link" (click)="saveSubService()">Save</button>
                        <button class="btn btn-danger is-danger" (click)="deleteSubService()">Delete</button>
                        <button class="btn btn-secondary is-text" (click)="goBack()">Cancel</button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
