import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LoginService } from '../../../login/login.service';

declare var moment: any;
const LEGACY_DATE  = '2022-06-08';

@Component({
    selector: 'app-portal-invoices',
    templateUrl: './portal-invoices.component.html',
    styleUrls: ['./portal-invoices.component.css']
})
export class PortalInvoicesComponent implements OnInit {

    account_id;
    invoices;

    hasLegacy = false;
    invoice_type = 'coinprint';

    constructor(
        private route: ActivatedRoute,
        private authService: LoginService,
    ) { }

    ngOnInit() {
        this.invoice_type = this.route.snapshot.data.type;
        this.authService.getCurrentAccount()
        .subscribe(account => {
            this.account_id = account.id;
            // TODO : Remove this when the invoices have been transferred over
            this.hasLegacy = moment(account.created_at).isBefore(LEGACY_DATE);
        });
    }

}
