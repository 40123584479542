<div class="btn btn-lg btn-link btn-block btn-outline-light text-lg darkcard"
     *ngIf="switchboard_object && (current_addons.length || available_addons.length)"
     (click)="open()"
>
    <i class="fas fa-puzzle-piece"></i> Add-Ons
    <br>
    <ul class="text-muted text-sm" style="list-style-position: inside; ">
        <li *ngFor="let addon of current_addons">{{ addon.sub_service.name }}</li>
    </ul>
    <!-- <span class="text-muted text-sm">
        <span class="text-lg">{{ switchboard_object.object_data.timeout }}</span> seconds
    </span> -->
</div>

<app-modal title="Add-Ons" #addons_modal>
    <table class="table">
        <tr *ngFor="let addon of current_addons">
            <th>{{ addon.sub_service.name }}</th>
            <td>&pound;{{ addon.sub_service.retail_cost|format_balance }} per {{ addon.sub_service.renew_days || 30 }} days</td>
            <td>
                <button
                    class="btn btn-danger"
                    (click)="delete(addon)"
                >
                    Remove Add-On
                </button>
            </td>
        </tr>
        <tr *ngFor="let addon of available_addons">
            <th>{{ addon.name }}</th>
            <td>&pound;{{ addon.retail_cost|format_balance }} per {{ addon.renew_days || 30 }} days</td>
            <td>
                <button
                    class="btn btn-success"
                    (click)="purchase(addon)"
                >
                    Purchase Add-On
                </button>
            </td>
        </tr>
    </table>
</app-modal>
