import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { ReportsService } from '../reports.service';

@Component({
    selector: 'app-fraud-report',
    templateUrl: './fraud-report.component.html',
    styleUrls: ['./fraud-report.component.css']
})
export class FraudReportComponent implements OnInit {

    readonly SCORE_BAD = 70;
    readonly SCORE_OK = 30;

    @Input() account_id;

    report;

    constructor(
        private reportsService: ReportsService,
    ) { }

    ngOnInit(): void {
        this.getReports();
    }

    getReports() {
        this.reportsService.getFraudReport(this.account_id)
            .subscribe(report => this.report = report);
    }

}
