import { NgModule } from '@angular/core';

// Core Modules
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Installed Modules
import { GravatarModule } from 'ngx-gravatar';

// My Modules
import { PipesModule } from '../pipes/pipes.module';
import { DirectivesModule } from '../directives/directives.module';
import { ComponentsModule } from '../components/components.module';
import { LayoutModule } from '../layout/layout.module';
import { SectionsModule } from '../sections/sections.module';
import { AudioModule } from '../audio/audio.module';

import { SwitchboardModule } from '../switchboard/switchboard.module';
import { PortalComponentsModule } from '../portal/components/portal-components.module';
import { AdminModule } from '../admin/admin.module';
import { ReportsModule } from '../reports/reports.module';
import { ContractsModule } from '../contracts/contracts.module';
import { BillingModule } from '../billing/billing.module';

import { AccountsRoutingModule } from './accounts-routing.module';

// Components
import { AccountsComponent } from './accounts.component';
import { AccountComponent } from './account/account.component';
import { AccountServicesComponent } from '../accounts/account-services/account-services.component';
import { UsersComponent } from './users/users.component';
import { KeysComponent } from './keys/keys.component';
import { UserNotificationsComponent } from './user-notifications/user-notifications.component';
import { AccountCdrsComponent } from './account-cdrs/account-cdrs.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,

        GravatarModule,

        PipesModule,
        DirectivesModule,
        ComponentsModule,
        LayoutModule,
        SectionsModule,
        AudioModule,
        SwitchboardModule,
        PortalComponentsModule,

        AdminModule,
        ReportsModule,
        ContractsModule,
        BillingModule,

        AccountsRoutingModule,
    ],
    declarations: [
        AccountsComponent,
        AccountComponent,
        AccountServicesComponent,
        UsersComponent,
        KeysComponent,
        UserNotificationsComponent,
        AccountCdrsComponent,
    ],
    bootstrap: [],
    exports: [
        AccountServicesComponent,
        UsersComponent,
        KeysComponent,
        UserNotificationsComponent,
    ]
})

export class AccountsModule { }
