import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { BillingService } from '../../billing.service';

@Component({
    selector: 'app-direct-debit-payer-exports',
    templateUrl: './direct-debit-payer-exports.component.html',
    styleUrls: ['./direct-debit-payer-exports.component.css']
})
export class DirectDebitPayerExportsComponent implements OnInit {

    dd_exports;
    page = 1;

    dd_export;

    @ViewChild('exportModal') exportModal;

    constructor(
        private billingService: BillingService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe(params => {
            this.page = +params.get('page');
            this.getDirectDebitExports();
        });
    }

    getDirectDebitExports() {
        this.billingService.getDirectDebitPayerExports(this.page)
            .subscribe(dd_exports => this.dd_exports = dd_exports);
    }

    viewExport(dd_export) {
        this.dd_export = dd_export;
        this.exportModal.open();
    }

    downloadExport(dd_export) {
        this.billingService.downloadDirectDebitPayerExport(dd_export);
    }

}
