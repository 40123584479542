<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Services</h2>
                    </div>
                </div>
            </div>
            <div class="table-responsive table-striped">
                <table class="table is-bordered is-striped is-hoverable is-fullwidth">
                    <thead class="thead">
                        <tr class="tr">
                            <th class="th">Name</th>
                            <th class="th">Switchboard Type</th>
                        </tr>
                    </thead>
                    <tbody class="tbody" *ngIf="services && services.length else no_services">
                        <tr class="tr" *ngFor="let service of services">
                            <td class="td"><a routerLink="/services/detail/{{ service.id }}">{{ service.name }}</a></td>
                            <td class="td"><i [class]="{object_type: service.switchboard_type}|switchboard_object_icon"></i> {{ service.switchboard_type }}</td>
                        </tr>
                    </tbody>
                    <tfoot class="tfoot">
                        <tr class="tr">
                            <th class="th" colspan="2">
                                <button class="btn btn-primary is-primary" (click)="createService()">New Service</button>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>

<app-modal (save)="saveService()" title="New Service" #serviceDetail>
    <div class="field">
        <label class="label">Name</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Name" [(ngModel)]="newService.name">
        </div>
    </div>
    <div class="field">
        <label class="label">Service Type</label>
        <div class="control">
            <div class="select">
              <select class="form-control" [(ngModel)]="newService.switchboard_type">
                <option *ngFor="let type of switchboardTypes" value="{{ type }}">{{ type }}</option>
              </select>
            </div>
        </div>
    </div>
    <div class="field">
        <label class="label">Switchboard Quantity</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Number of switchboard objects to assign" [(ngModel)]="newService.switchboard_quantity">
        </div>
    </div>
    <div class="field">
        <label class="label">Switchboard Quality</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Switchboard Quality" [(ngModel)]="newService.switchboard_quality">
        </div>
    </div>
    <div class="field">
        <label class="label">Retail Cost</label>
        <div class="control">
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text" id="btnGroupAddon">&pound;</div>
                </div>
                <input class="input form-control" type="text" placeholder="Retail Cost" [(ngModel)]="newService.retail_cost">
            </div>
        </div>
    </div>
    <!-- <div class="field">
        <label class="label">Renew Days</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Days to renew (0 = doesn't renew)" [(ngModel)]="newService.renew_days">
        </div>
    </div> -->

    <div class="field">
        <label class="label">Unique Per Account</label>
        <div class="control">
            <input class="" type="checkbox" [(ngModel)]="newService.unique_per_account" (change)="newService.unique_per_account = +newService.unique_per_account">
        </div>
    </div>

    <div class="field">
        <label class="label">Requires Registrar</label>
        <div class="control">
            <input class="" type="checkbox" [(ngModel)]="newService.requires_registrar" (change)="newService.requires_registrar = +newService.requires_registrar">
        </div>
    </div>

    <div class="field">
        <label class="label">Requires Admin</label>
        <div class="control">
            <input class="" type="checkbox" [(ngModel)]="newService.requires_admin" (change)="newService.requires_admin = +newService.requires_admin">
        </div>
    </div>

    
</app-modal>

<ng-template #no_services>
    <tbody class="tbody">
        <tr class="tr">
            <td class="td" colspan="2">
                <ng-container *ngIf="services else is_loading">No Services Found.</ng-container>
            </td>
        </tr>
    </tbody>
</ng-template>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
