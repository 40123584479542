<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/contract-types">Contract Types</a></li>
                <li class="breadcrumb-item active" aria-current="page">Rate Overrides</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Rate Overrides for {{ contract_type?.name }}</h2>
                    </div>
                </div>
            </div>
            <div class="table-responsive table-striped">
                <table class="table">
                    <thead class="thead">
                        <tr class="tr">
                            <th class="th">Prefix Starts With</th>
                            <th class="th">Wholesale<br>cost per minute<br>less than</th>
                            <th class="th">Cost Per Minute</th>
                            <th class="th">Wholesale<br>connection charge<br>less than</th>
                            <th class="th">Connection Charge</th>
                            <th class="th">&nbsp;</th>
                            <th class="th">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody class="tbody">
                        <ng-container *ngIf="rates?.length else no_rates">
                            <ng-container *ngFor="let rate of rates">
                                <tr class="tr">
                                    <td class="td">
                                        <h6>{{ rate.name }}</h6>
                                        <h5>{{ rate.prefix_starts_with }}</h5>
                                    </td>
                                    <td class="td">{{ rate.wholesale_cost_per_minute_less_than }}p</td>
                                    <td class="td">{{ rate.retail_cost_per_minute }}p</td>
                                    <td class="td">{{ rate.wholesale_connection_charge_less_than }}p</td>
                                    <td class="td">{{ rate.retail_connection_charge }}p</td>
                                    <td class="td"><button class="btn btn-secondary is-link" (click)="editRate(rate)">Edit</button></td>
                                    <td class="td"><button class="btn btn-danger is-danger" (click)="deleteRate(rate)" [class.is-loading]="rate.deleted">Delete</button></td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </tbody>
                    <tfoot class="tfoot">
                        <tr class="tr">
                            <th class="th" colspan="7">
                                <app-pagination [pagination]="rates" url="/contract-types"></app-pagination>

                                <button class="btn btn-primary" (click)="newRate()">Add Rate Override</button>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>

<ng-template #no_rates>
    <tr class="tr">
        <td class="td" colspan="7">
            <ng-container *ngIf="rates else is_loading">
                No rate overrides found
            </ng-container>
        </td>
    </tr>
</ng-template>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>

<app-modal title="{{ current_rate.id ? 'Edit' : 'New' }} Rate Override" (save)="saveRate()" #rateDetail>
    <div class="field">
        <label class="label">Name</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Name" [(ngModel)]="current_rate.name">
        </div>
    </div>
    <div class="field">
        <label class="label">Prefix Starts With</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Prefix" [(ngModel)]="current_rate.prefix_starts_with">
        </div>
    </div>
    <div class="field">
        <label class="label">Wholesale Cost Per Minute Less Than</label>
        <div class="control">
            <div class="input-group">
                <input class="input form-control" type="text" placeholder="Cost per minute (pence)" [(ngModel)]="current_rate.wholesale_cost_per_minute_less_than">
                <div class="input-group-append">
                    <div class="input-group-text" id="btnGroupAddon">p</div>
                </div>
            </div>
        </div>
    </div>
    <div class="field">
        <label class="label">Wholesale Connection Charge Less Than</label>
        <div class="control">
            <div class="input-group">
                <input class="input form-control" type="text" placeholder="Cost per minute (pence)" [(ngModel)]="current_rate.wholesale_connection_charge_less_than">
                <div class="input-group-append">
                    <div class="input-group-text" id="btnGroupAddon">p</div>
                </div>
            </div>
        </div>
    </div>
    <div class="field">
        <label class="label">Retail Cost Per Minute</label>
        <div class="control">
            <div class="input-group">
                <input class="input form-control" type="text" placeholder="Cost per minute (pence)" [(ngModel)]="current_rate.retail_cost_per_minute">
                <div class="input-group-append">
                    <div class="input-group-text" id="btnGroupAddon">p</div>
                </div>
            </div>
        </div>
    </div>
    <div class="field">
        <label class="label">Retail Connection Charge</label>
        <div class="control">
            <div class="input-group">
                <input class="input form-control" type="text" placeholder="Cost per minute (pence)" [(ngModel)]="current_rate.retail_connection_charge">
                <div class="input-group-append">
                    <div class="input-group-text" id="btnGroupAddon">p</div>
                </div>
            </div>
        </div>
    </div>
</app-modal>