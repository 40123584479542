import { Injectable } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { AuthenticationHelper } from '../login/authentication.helper';

declare var toastr: any;

@Injectable()
export class Client implements OnDestroy {

    protected serviceURL;
    protected subscribed = [];

    constructor(
        private http: HttpClient
    ) { }

    protected log(message: string) {
        if (!environment.production) {
            console.log(`Service: ${message} `);
        }
        // this.messageService.add(`HeroService: ${message}`);
    }

    protected showError(message) {
        if (['Invalid token', 'Unauthorised'].indexOf(message) !== -1) {
            return false;
        }
        toastr.error(message);
    }

    protected handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            const client = this;
            function outputMessages(messages) {
                for (let key in messages) {
                    let message = messages[key];
                    if (typeof message === 'string') {
                        message = [message];
                    }
                    for (let note of message) {
                        client.showError(note);
                    }
                }
            }

            if (typeof error.error === 'string') {
                this.showError(error.error);
            }

            if (error.error) {
                if (error.error.messages) {
                    outputMessages(error.error.messages);
                }

                if (error.error.message) {
                    this.showError(error.error.message);
                }
            }

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    protected call(method, url, data = {}, options = {}) {
        let request;
        const authtoken = AuthenticationHelper.getKey();
        const defaultHttpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authtoken
            }),
            params: null,
        };
        const httpOptions = Object.assign(defaultHttpOptions, options);

        if (method === 'get' || method === 'delete') {
            httpOptions.params = data;
            request = this.http[method](url, httpOptions);
        } else {
            request = this.http[method](url, data, httpOptions);
        }

        this.subscribed.push(request);
        return request;
    }

    public unsubscribe() {
        for (let request of this.subscribed) {
            this.unsubscribe();
        }
    }

    ngOnDestroy() {
        this.unsubscribe();
    }

    protected callApi(method, path, data = {}, options = {}) {
        return this.call(method, this.serviceURL + path, data, options);
    }

    public downloadResource(url: string, data = {}) {
        return this.call('get', url, data, {
            responseType: 'arraybuffer'
        });
    }

    public downloadExternalResource(url: string, data = {}) {
        return this.call('get', url, data, {
            headers: null,
            responseType: 'arraybuffer'
        });
    }

    public downloadResourcePath(path: string, data = {}) {
        return this.downloadResource(this.serviceURL + path, data);
    }

    protected upload(method, url, data = {}, options = {}) {
        const authtoken = AuthenticationHelper.getKey();
        const defaultHttpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + authtoken
            }),
            params: null,
        };
        const httpOptions = Object.assign(defaultHttpOptions, options);

        if (method === 'get' || method === 'delete') {
            httpOptions.params = data;
            return this.http[method](url, httpOptions);
        }
        return this.http[method](url, data, httpOptions);
    }

    public getAccountPreferences() {
        return this.callApi('get', `/account/config`)
        .pipe(
            tap(_ => this.log('fetching preferences for account')),
            catchError(this.handleError('getAccountPreferences', []))
        );
    }

    public updateAccountPreference(preferences) {
        return this.callApi('put', `/account/config`, preferences)
        .pipe(
            tap(_ => this.log('update preferences for account')),
            catchError(this.handleError('updateAccountPreference', []))
        );
    }

    public getAccountPreference(preferenceKey) {
        return this.callApi('get', `/account/config/${preferenceKey}`)
        .pipe(
            tap(_ => this.log(`fetching preference ${preferenceKey} for account`)),
            catchError(this.handleError('getAccountPreference', []))
        );
    }

    public deleteAccountPreference(preferenceKey) {
        return this.callApi('delete', `/account/config/${preferenceKey}`)
        .pipe(
            tap(_ => this.log(`deleting preference ${preferenceKey} for account`)),
            catchError(this.handleError('deleteAccountPreference', []))
        );
    }

    public getSpecificAccountPreferences(account_id) {
        return this.callApi('get', `/accounts/${account_id}/config`)
        .pipe(
            tap(_ => this.log('fetching preferences for account')),
            catchError(this.handleError('getSpecificAccountPreferences', []))
        );
    }

    public updateSpecificAccountPreference(account_id, preferences) {
        return this.callApi('put', `/accounts/${account_id}/config`, preferences)
        .pipe(
            tap(_ => this.log('update preferences for account')),
            catchError(this.handleError('updateSpecificAccountPreference', []))
        );
    }

    public getSpecificAccountPreference(account_id, preferenceKey) {
        return this.callApi('get', `/accounts/${account_id}/config/${preferenceKey}`)
        .pipe(
            tap(_ => this.log(`fetching preference ${preferenceKey} for account`)),
            catchError(this.handleError('getSpecificAccountPreference', []))
        );
    }

    public deleteSpecificAccountPreference(account_id, preferenceKey) {
        return this.callApi('delete', `/accounts/${account_id}/config/${preferenceKey}`)
        .pipe(
            tap(_ => this.log(`deleting preference ${preferenceKey} for account`)),
            catchError(this.handleError('deleteSpecificAccountPreference', []))
        );
    }

    public getApplicationPreferences() {
        return this.callApi('get', `/application/config`)
        .pipe(
            tap(_ => this.log('fetching preferences for account')),
            catchError(this.handleError('getApplicationPreferences', []))
        );
    }

    public updateApplicationPreference(preferences) {
        return this.callApi('put', `/application/config`, preferences)
        .pipe(
            tap(_ => this.log('update preferences for account')),
            catchError(this.handleError('updateApplicationPreference', []))
        );
    }

    public getApplicationPreference(preferenceKey) {
        return this.callApi('get', `/application/config/${preferenceKey}`)
        .pipe(
            tap(_ => this.log(`fetching preference ${preferenceKey} for account`)),
            catchError(this.handleError('getApplicationPreference', []))
        );
    }

    public deleteApplicationPreference(preferenceKey) {
        return this.callApi('delete', `/application/config/${preferenceKey}`)
        .pipe(
            tap(_ => this.log(`deleting preference ${preferenceKey} for account`)),
            catchError(this.handleError('deleteApplicationPreference', []))
        );
    }

    public getUserPreferences() {
        return this.callApi('get', `/user/config`)
        .pipe(
            tap(_ => this.log('fetching preferences for account')),
            catchError(this.handleError('getUserPreferences', []))
        );
    }

    public updateUserPreference(preferences) {
        return this.callApi('put', `/user/config`, preferences)
        .pipe(
            tap(_ => this.log('update preferences for account')),
            catchError(this.handleError('updateUserPreference', []))
        );
    }

    public getUserPreference(preferenceKey) {
        return this.callApi('get', `/user/config/${preferenceKey}`)
        .pipe(
            tap(_ => this.log(`fetching preference ${preferenceKey} for account`)),
            catchError(this.handleError('getUserPreference', []))
        );
    }

    public deleteUserPreference(preferenceKey) {
        return this.callApi('delete', `/user/config/${preferenceKey}`)
        .pipe(
            tap(_ => this.log(`deleting preference ${preferenceKey} for account`)),
            catchError(this.handleError('deleteUserPreference', []))
        );
    }

    public getSpecificUserPreferences(user, ) {
        return this.callApi('get', `/users/${user}/config`)
        .pipe(
            tap(_ => this.log('fetching preferences for account')),
            catchError(this.handleError('getSpecificUserPreferences', []))
        );
    }

    public updateSpecificUserPreferences(user, preferences) {
        return this.callApi('put', `/users/${user}/config`, preferences)
        .pipe(
            tap(_ => this.log('update preferences for account')),
            catchError(this.handleError('updateSpecificUserPreferences', 'FAIL'))
        );
    }

    public getSpecificUserPreference(user, preferenceKey) {
        return this.callApi('get', `/users/${user}/config/${preferenceKey}`)
        .pipe(
            tap(_ => this.log(`fetching preference ${preferenceKey} for account`)),
            catchError(this.handleError('getSpecificUserPreference', []))
        );
    }

    public deleteSpecificUserPreference(user, preferenceKey) {
        return this.callApi('delete', `/users/${user}/config/${preferenceKey}`)
        .pipe(
            tap(_ => this.log(`deleting preference ${preferenceKey} for account`)),
            catchError(this.handleError('deleteSpecificUserPreference', []))
        );
    }

    public healthCheck() {
        return this.callApi('get', `/health_check`)
        .pipe(
            tap(_ => this.log('get basic system metrics')),
            catchError(this.handleError('healthCheck', []))
        );
    }
}
