import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

import { CountryCodes } from '../tools/country-codes';

@Pipe({name: 'number_to_country_prefix'})
export class NumberToCountryPrefixPipe implements PipeTransform {

    transform(number: any) {
        let foundPrefix = null;
        let biggestPrefix = 0;

        if (!number) {
            return number;
        }

        for (let prefix of CountryCodes.e164_prefixes) {
            if (number.startsWith(prefix.number) && prefix.number.length > biggestPrefix) {
                biggestPrefix = prefix.number.length;
                foundPrefix = prefix;
            }
        }

        return foundPrefix && foundPrefix.number;
    }
}
