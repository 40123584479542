import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

@Pipe({name: 'ordinal'})
export class OrdinalPipe implements PipeTransform {

    transform(value: any) {
        if (!value) {
            return value;
        }
        const digit = value % 10;
        let ordinal = 'th';
        if (value - digit !== 10) {
            if (digit === 1) {
                ordinal = 'st';
            } else if (digit === 2) {
                ordinal = 'nd';
            } else if (digit === 3) {
                ordinal = 'rd';
            }
        }
        return `${value}${ordinal}`;
    }
}
