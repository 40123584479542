<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Media</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Media</h2>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="account_id else loading">
                <div class="card-body">
                    <p>Create personalised announcements like voicemail or IVR Auto Attendant Menus and save them here to use throughout your system.  Either use our text to speech system which allows you to type in your message or upload pre-recorded media files. We accept no responsibility for content added to your personal media library</p>
                </div>
                <app-media [account_id]="account_id" #media_tab></app-media>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="card-body">
        <app-loadingbar></app-loadingbar>
    </div>
</ng-template>
