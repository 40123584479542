<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Service</h2>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <ng-container *ngIf="service else is_loading">
                    <div class="field">
                        <label class="label">Name</label>
                        <div class="control">
                            <input class="input form-control" type="text" placeholder="Name" [(ngModel)]="service.name">
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Service Type</label>
                        <br>
                        {{ service.switchboard_type }}
                    </div>
                    <div class="field">
                        <label class="label">Switchboard Quantity</label>
                        <div class="control">
                            <input class="input form-control" type="text" placeholder="Number of switchboard objects to assign" [(ngModel)]="service.switchboard_quantity">
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Switchboard Quality</label>
                        <div class="control">
                            <input class="input form-control" type="text" placeholder="Switchboard Quality" [(ngModel)]="service.switchboard_quality">
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Retail Cost</label>
                        <div class="control">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text" id="btnGroupAddon">&pound;</div>
                                </div>
                                <input class="input form-control" type="text" placeholder="Retail Cost" [(ngModel)]="service.retail_cost">
                            </div>
                        </div>
                    </div>
                    <!-- <div class="field">
                        <label class="label">Renew Days</label>
                        <div class="control">
                            <input class="input form-control" type="text" placeholder="Days to renew (0 = doesn't renew)" [(ngModel)]="service.renew_days">
                        </div>
                    </div> -->

                    <div class="field">
                        <label class="label">Unique Per Account</label>
                        <div class="control">
                            <input class="" type="checkbox" [(ngModel)]="service.unique_per_account" (change)="service.unique_per_account = +service.unique_per_account">
                        </div>
                    </div>

                    <div class="field">
                        <label class="label">Requires Registrar</label>
                        <div class="control">
                            <input class="" type="checkbox" [(ngModel)]="service.requires_registrar" (change)="service.requires_registrar = +service.requires_registrar">
                        </div>
                    </div>

                    <div class="field">
                        <label class="label">Requires Admin</label>
                        <div class="control">
                            <input class="" type="checkbox" [(ngModel)]="service.requires_admin" (change)="service.requires_admin = +service.requires_admin">
                        </div>
                    </div>

                    <app-tabs>
                        <app-tab name="Sub Services" [isActive]="true">
                            <div class="table-responsive table-striped">
                                <table class="table" *ngIf="subservices else is_loading">
                                    <thead class="thead">
                                        <tr class="tr">
                                            <th class="th">Name</th>
                                            <th class="th">Type</th>
                                            <th class="th">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody class="tbody">
                                        <tr class="tr" *ngFor="let subservice of subservices">
                                            <td class="td"><a routerLink="/subservices/detail/{{ subservice.id }}">{{ subservice.name }}</a></td>
                                            <td class="td"><i [class]="{object_type: subservice.switchboard_type}|switchboard_object_icon"></i> {{ subservice.switchboard_type }}</td>
                                            <td class="td"><button class="btn btn-danger is-danger" (click)="detatchSubservice(subservice)" [class.is-loading]="subservice.deleted">Detatch</button></td>
                                        </tr>
                                        <tr class="tr" *ngIf="subservices.length === 0">
                                            <td class="td" colspan="3">This service has no subservices</td>
                                        </tr>
                                        <tr class="tr" *ngIf="subservices === null">
                                            <td class="td" colspan="3"><app-loadingbar></app-loadingbar></td>
                                        </tr>
                                    </tbody>
                                    <tfoot class="tfoot">
                                    <tr class="tr">
                                        <th class="th" colspan="3"><button class="btn btn-primary is-primary" (click)="subserviceDialog()">Attach Subservice</button></th>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <app-modal title="Attach Subservice" (save)="attachSubservice()" #attachSubserviceModal>
                                <div class="field">
                                    <label class="label">Sub Service</label>
                                    <div class="control">
                                        <div class="select">
                                            <select [(ngModel)]="attachedSubservice" class="form-control">
                                                <option value="">Select Sub Service</option>

                                                <ng-container *ngFor="let subservice of availableSubservices">
                                                    <option *ngIf="notAttachedSubservice(subservice)" value="{{ subservice.id }}">{{ subservice.name }}</option>
                                                </ng-container>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </app-modal>
                        </app-tab>
                        <app-tab name="Bundles" *ngIf="['Number', 'Sip', 'Shared Bundle'].indexOf(service.switchboard_type) !== -1">
                            <div class="table-responsive table-striped">
                                <table class="table" *ngIf="service.bundles else is_loading">
                                    <thead class="thead">
                                        <tr class="tr">
                                            <th class="th">Name</th>
                                            <th class="th">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody class="tbody">
                                        <tr class="tr" *ngFor="let bundle of service.bundles">
                                            <td class="td">{{ bundle.name }}</td>
                                            <td class="td"><button class="btn btn-danger is-danger" (click)="detatchBundle(bundle)" [class.is-loading]="bundle.deleted">Detatch</button></td>
                                        </tr>
                                        <tr class="tr" *ngIf="service.bundles.length === 0">
                                            <td class="td" colspan="2">This service has no bundles</td>
                                        </tr>
                                        <tr class="tr" *ngIf="service.bundles === null">
                                            <td class="td" colspan="2"><app-loadingbar></app-loadingbar></td>
                                        </tr>
                                    </tbody>
                                    <tfoot class="tfoot">
                                    <tr class="tr">
                                        <th class="th" colspan="2"><button class="btn btn-primary is-primary" (click)="bundleModal.open()">Attach Bundle</button></th>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>

                            <app-modal title="Attach Bundle" (save)="attachBundle()" #bundleModal>
                                <div class="field">
                                    <label class="label">Bundle</label>
                                    <div class="control">
                                        <div class="select">
                                            <select [(ngModel)]="attachedBundle" class="form-control">
                                                <option value="">Select Bundle</option>
                                                <ng-container *ngFor="let bundle of availableBundles">
                                                    <option *ngIf="notAttachedBundle(bundle)" value="{{ bundle.id }}">{{ bundle.name }}</option>
                                                </ng-container>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </app-modal>
                        </app-tab>
                    </app-tabs>

                    <div class="field is-grouped">
                        <button class="btn btn-primary is-link" (click)="saveService()">Save</button>
                        <button class="btn btn-danger is-danger" (click)="deleteService()">Delete</button>
                        <button class="btn btn-secondary is-text" (click)="goBack()">Cancel</button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
