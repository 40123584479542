<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Contract Types</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Contract Types</h2>
                    </div>
                </div>
            </div>
            <div class="table-responsive table-striped">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Contract Length</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody class="tbody">
                        <ng-container *ngIf="contracts?.length else no_contracts">
                            <ng-container *ngFor="let contract of contracts">
                                <tr>
                                    <td>{{ contract.name }}</td>
                                    <td>
                                        Trial Period: {{ contract.trial_period_months }} months
                                        <br>
                                        Contract Length: {{ contract.contract_length_months }} months
                                    </td>
                                    <td>
                                        <button class="btn btn-primary" routerLink="/contract-types/rates/{{ contract.uuid }}">Rate Overrides</button>
                                    </td>
                                    <td>
                                        <button class="btn btn-secondary" (click)="editContract(contract)">Edit</button>
                                        <button class="btn btn-danger" (click)="deleteContract(contract)">Delete</button>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </tbody>
                    <tfoot class="tfoot">
                        <tr class="tr">
                            <th class="th" colspan="4">

                                <button class="btn btn-primary" (click)="newContract()">Add Contract Type</button>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>

<ng-template #no_contracts>
    <tr class="tr">
        <td class="td" colspan="4">
            <ng-container *ngIf="contracts else is_loading">
                No contract types found
            </ng-container>
        </td>
    </tr>
</ng-template>

<ng-template #is_loading>
    <app-loadingbar></app-loadingbar>
</ng-template>

<app-modal title="{{ current_contract.id ? 'Edit' : 'New' }} Contract Type" (save)="saveContract()" #contractDetail>
    <div class="field">
        <label class="label">Name</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="Name" [(ngModel)]="current_contract.name">
        </div>
    </div>
    <div class="field">
        <label class="label">Trial Period (months)</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="X Months" [(ngModel)]="current_contract.trial_period_months">
        </div>
    </div>
    <div class="field">
        <label class="label">Contract Length (months)</label>
        <div class="control">
            <input class="input form-control" type="text" placeholder="X Months" [(ngModel)]="current_contract.contract_length_months">
        </div>
    </div>
</app-modal>