import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { LoginService } from '../../../login/login.service';
import { MediaService } from '../../media.service';
import { SwitchboardService } from '../../../switchboard/switchboard.service';

import { AudioHelper } from '../../../tools/audio';

declare var audiojs: any;
declare var $: any;
declare var alertify: any;

@Component({
    selector: 'app-voicemail',
    templateUrl: './voicemail.component.html',
    styleUrls: ['./voicemail.component.css']
})
export class VoicemailComponent implements OnInit, OnDestroy {

    admin = true;
    boxes;
    voicemails: any = null;
    account_id: number;
    capacity;

    constructor(
        private route: ActivatedRoute,
        private authService: LoginService,
        private mediaService: MediaService,
        private switchboardService: SwitchboardService,
    ) { }

    ngOnInit() {
        this.account_id = +this.route.snapshot.paramMap.get('id');
        if (this.account_id) {
            this.getVoicemails();
        } else {
            this.admin = false;
            this.authService.getCurrentAccount()
            .subscribe(account => {
                this.account_id = account.id;
                this.getVoicemails();
                this.getVoicemailBoxes();
            });
        }
    }

    ngOnDestroy() {
        $('audio').each(audio => audio.pause());
    }

    getVoicemailBoxes() {
        this.switchboardService.readVoicemailsOnAccount(this.account_id)
            .subscribe(boxes => this.boxes = boxes);
    }

    getVoicemails(callback = null) {
        this.mediaService.getAllVoicemails(this.account_id)
        .subscribe(voicemails => {
            if (!this.voicemails) {
                this.voicemails = voicemails;
            }
            this.getCapacity();
            this.mergeBoxes(voicemails);
            if (callback) {
                callback(voicemails);
            }
        });
    }

    getCapacity() {
        this.capacity = {};
        for (let extension in this.voicemails) {
            this.mediaService.getVoicemailCapacity(this.account_id, extension)
                .subscribe(capacity => this.capacity[extension] = capacity);
        }
    }

    mergeBoxes(voicemails) {
        for (let extension in this.voicemails) {
            if (this.voicemails.hasOwnProperty(extension)) {
                this.voicemails[extension]['all'] = [
                    ...voicemails[extension].old,
                    ...voicemails[extension].new
                ];

                for (let voicemail of this.voicemails[extension]['all']) {
                    voicemail['age'] = voicemail.url.indexOf('/old/') !== -1 ? 'old' : 'new';
                }

                this.voicemails[extension]['all'].sort((a, b) => {
                    if (a.origtime < b.origtime) {
                        return 1;
                    }
                    if (a.origtime > b.origtime) {
                        return -1;
                    }
                    return 0;
                });
            }
        }
    }

    getSound(voicemail) {
        if (voicemail.age === 'new') {
            this.markAsRead(voicemail, () => {
                for (let vmail of this.voicemails[voicemail.origmailbox]['all']) {
                    if (vmail.origtime === voicemail.origtime) {
                        vmail.loaded = true;
                        this.loadSound(vmail);
                    }
                }
            });
        } else {
            voicemail.loaded = true;
            this.loadSound(voicemail);
        }
    }

    loadSound(voicemail) {
        const elementID = `voicemail-audio-controller-${voicemail.origmailbox}-${voicemail.age}-${voicemail.id}`;
        this.mediaService.downloadVoicemail(this.account_id, voicemail, voicemail.age)
            .subscribe(AudioHelper.loadFileToAudioElement(elementID));
    }

    download(voicemail) {
        const fileName = `voicemail-${voicemail.msg_id}.wav`;
        this.mediaService.downloadVoicemail(this.account_id, voicemail, voicemail.age)
            .subscribe(AudioHelper.downloadAudioFile(fileName));
    }

    delete(voicemail) {
        alertify.confirm('Are you sure you want to delete this voicemail?', () => {
            this.mediaService.deleteVoicemail(this.account_id, voicemail.origmailbox, voicemail.age, voicemail.id)
                .subscribe(() => this.getVoicemails());
        });
    }

    markAsRead(voicemail, callback = null) {
        this.mediaService.markVoicemailRead(this.account_id, voicemail.origmailbox, voicemail.id)
        .subscribe(() => {
            this.getVoicemails(callback);
        });
    }

    markAsUnread(voicemail, callback = null) {
        this.mediaService.markVoicemailUnread(this.account_id, voicemail.origmailbox, voicemail.id)
        .subscribe(() => {
            this.getVoicemails(callback);
        });
    }

    deleteAll(mailbox) {
        alertify.confirm('Are you sure you want to delete all voicemails?', () => {
            this.mediaService.deleteAllVoicemails(this.account_id, mailbox)
                .subscribe(() => this.getVoicemails());
        });
    }

    deleteAllRead(mailbox) {
        alertify.confirm('Are you sure you want to delete all read voicemails?', () => {
            this.mediaService.deleteAllReadVoicemails(this.account_id, mailbox)
                .subscribe(() => this.getVoicemails());
        });
    }

    deleteAllUnread(mailbox) {
        alertify.confirm('Are you sure you want to delete all unread voicemails?', () => {
            this.mediaService.deleteAllUnreadVoicemails(this.account_id, mailbox)
                .subscribe(() => this.getVoicemails());
        });
    }
}
