<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">Verify Addresses</h2>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table align-items-center table-flush table-float-header table-striped">
                    <tbody *ngIf="addresses?.total else no_addresses">
                        <ng-container *ngFor="let address of addresses.data">
                            <tr *ngIf="address.needs_verification === 1">
                                <td>
                                    <ng-container *ngIf="address.switchboard_object.object_data as number">
                                        <span class="flag-icon flag-icon-{{ (number.e164_number|number_to_country_code).toLowerCase() }}" [title]="number.e164_number|number_to_country_prefix"></span>
                            
                                        {{ number.e164_number|number_to_local }}<br>
                                    </ng-container>
                                    <a routerLink="/accounts/detail/{{ address.switchboard_object.account_id }}">{{ address.title }} {{ address.first_name }} {{ address.last_name }}</a>,<br>
                                    <ng-container *ngIf="address.company_name || address.company_suffix">{{ address.company_name }} {{ address.company_suffix }},<br></ng-container>
                                    {{ address.address_line_1 }},<br>
                                    {{ address.address_line_2 }},<br>
                                    {{ address.city }},<br>
                                    {{ address.postal_code }},<br>
                                    {{ address.country_region }}
                                </td>
                                <td>
                                    <button class="btn btn-success" (click)="accept(address)"><i class="fas fa-check"></i> Accept</button>
                                    <button class="btn btn-danger" (click)="deny(address)"><i class="fas fa-times"></i> Deny</button>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="2">
                                <app-pagination [pagination]="addresses" [(page)]="page" (page_change)="changePage($event)"></app-pagination>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="card-body">
        <app-loadingbar></app-loadingbar>
    </div>
</ng-template>

<ng-template #no_addresses>
    <tr>
        <td colspan="2">No Addresses To Verify</td>
    </tr>
</ng-template>