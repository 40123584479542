import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { LoginService } from '../login/login.service';

declare var toastr: any;
declare var window: any;

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {

    password;
    password_confirm;

    constructor(
        private authService: LoginService,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit() {
    }

    reset() {
        const user = this.route.snapshot.paramMap.get('user');
        const token = this.route.snapshot.paramMap.get('token');

        if (!this.password) {
            toastr.error('Password required');
            return false;
        }

        if (!this.password_confirm) {
            toastr.error('Password confirm required');
            return false;
        }

        this.authService.resetPassword(user, token, this.password, this.password_confirm)
        .subscribe(response => {
            if (response !== 'Password Error') {
                toastr.success('Password reset!');
                this.router.navigate([`/login`]);
            }
        }, error => {
            if (error.status === 404) {
                toastr.error('Password Reset Token is invalid');
            } else {
                for (let key in error.error.messages) {
                    let message = error.error.messages[key];
                    if (typeof message === 'string') {
                        message = [message];
                    }
                    for (let note of message) {
                        toastr.error(note);
                    }
                }
            }
        });
    }
}
