import { environment } from '../../environments/environment';
import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PipesModule } from '../pipes/pipes.module';
import { LayoutModule } from '../layout/layout.module';
import { ComponentsModule } from '../components/components.module';

import { ContractsRoutingModule } from './contracts-routing.module';

import { ContractTypesComponent } from './contract-types/contract-types.component';
import { ContractRateOverridesComponent } from './contract-rate-overrides/contract-rate-overrides.component';
import { AccountContractsComponent } from './account-contracts/account-contracts.component';
import { HistoricalContractsComponent } from './historical-contracts/historical-contracts.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,

        NgbModule,
        // RecaptchaV3Module,
        // NgxIntlTelInputModule,

        PipesModule,
        LayoutModule,
        ComponentsModule,
        // SwitchboardModule,
        // SectionsModule,
        // AudioModule,

        // PortalComponentsModule,
        // PortalBillingModule,
        // PortalSettingsModule,

        ContractsRoutingModule,
    ],
    declarations: [
        ContractTypesComponent,
        ContractRateOverridesComponent,
        AccountContractsComponent,
        HistoricalContractsComponent,
    ],
    exports: [
        AccountContractsComponent,
        HistoricalContractsComponent,
    ],
    bootstrap: []
})

export class ContractsModule { }
