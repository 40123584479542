import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PipesModule } from '../pipes/pipes.module';
import { LayoutModule } from '../layout/layout.module';
import { ComponentsModule } from '../components/components.module';

import { BillingRoutingModule } from './billing-routing.module';

import { InvoiceAdjustmentComponent } from './invoice-adjustment/invoice-adjustment.component';
import { DirectDebitsComponent } from './direct-debits/direct-debits.component';
import { DirectDebitExportsComponent } from './direct-debit-exports/direct-debit-exports.component';
import { DirectDebitPayerExportsComponent } from './direct-debit-payer-exports/direct-debit-payer-exports.component';
import { InvoicesKashflowComponent } from './invoices/invoices-kashflow/invoices-kashflow.component';
import { InvoicesCoinprintComponent } from './invoices/invoices-coinprint/invoices-coinprint.component';
import { ProformaInvoicesComponent } from './invoices/proforma-invoices/proforma-invoices.component';
import { InvoiceCdrsComponent } from './invoices/invoice-cdrs/invoice-cdrs.component';

@NgModule({
    imports: [
        BrowserModule,
        RouterModule,
        FormsModule,
        HttpClientModule,

        NgbModule,

        PipesModule,
        ComponentsModule,
        LayoutModule,

        BillingRoutingModule,
    ],
    declarations: [
        InvoiceAdjustmentComponent,
        DirectDebitsComponent,
        DirectDebitExportsComponent,
        DirectDebitPayerExportsComponent,
        InvoicesKashflowComponent,
        InvoicesCoinprintComponent,
        ProformaInvoicesComponent,
        InvoiceCdrsComponent,
    ],
    exports: [
        InvoiceAdjustmentComponent,
        DirectDebitsComponent,
        InvoicesKashflowComponent,
        InvoicesCoinprintComponent,
        ProformaInvoicesComponent,
    ],
    bootstrap: []
})

export class BillingModule { }
