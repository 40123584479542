<div class="row">
    <div class="col-xl-12 mb-5 mb-xl-0">
        <div class="card shadow">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Call Statistics</li>
              </ol>
            </nav>
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="mb-0">{{ direction|capitalize }} Call Statistics <span *ngIf="number">for {{ number }}</span></h2>
                    </div>
                </div>
            </div>
            <div class="card-body" >

                <p class="alert alert-primary">Please note that inbound calls that are forwarded to a destination will display as both an inbound & outbound call</p>

                <h3>Filter Options</h3>
                
                <div class="btn-toolbar">
                    <div class="dropdown">
                        <button class="btn btn-primary dropdown-toggle" type="button" id="dropdown-outbound-number" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Date
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdown-ingress-number">
                            <button class="dropdown-item" (click)="datepicker.clear();filter()">Any</button>
                            <button class="dropdown-item" (click)="setDateToDaysAgo(1)">Past 24 Hours</button>
                            <button class="dropdown-item" (click)="setDateToDaysAgo(7)">Past Week</button>
                            <button class="dropdown-item" (click)="setDateToDaysAgo(30)">Past Month</button>
                        </div>
                    </div>
                    
                    <div class="dropdown">
                        <button class="btn btn-primary dropdown-toggle" type="button" id="dropdown-inbound" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Inbound
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdown-inbound">
                            <a class="dropdown-item" routerLink="/call-stats/inbound">All</a>
                            <div class="dropdown-divider"></div>
                            <ng-container *ngFor="let object of switchboard_objects">
                                <a class="dropdown-item" *ngIf="object.object_type === 'Number'" routerLink="/call-stats/inbound/number/{{ object.object_data.e164_number }}" routerLinkActive="active">{{ object.object_data.e164_number }}</a>
                            </ng-container>
                        </div>
                    </div>

                    <div class="dropdown">
                        <button class="btn btn-primary dropdown-toggle" type="button" id="dropdown-outbound-sip" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Outbound By User
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdown-outbound-sip">
                            <a class="dropdown-item" routerLink="/call-stats/outbound">All</a>
                            <div class="dropdown-divider"></div>
                            <ng-container *ngFor="let object of switchboard_objects">
                                <a class="dropdown-item" *ngIf="object.object_type === 'Sip'" routerLink="/call-stats/outbound/number/{{ object.extension_number }}" routerLinkActive="active">{{ object.extension_number }} - {{ object.object_data.name }}</a>
                            </ng-container>
                        </div>
                    </div>

                    <div class="dropdown">
                        <button class="btn btn-primary dropdown-toggle" type="button" id="dropdown-outbound-number" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Outbound By Number
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdown-outbound-number">
                            <a class="dropdown-item" routerLink="/call-stats/outbound">All</a>
                            <div class="dropdown-divider"></div>
                            <ng-container *ngFor="let object of switchboard_objects">
                                <a class="dropdown-item" *ngIf="object.object_type === 'Number'" routerLink="/call-stats/outbound/number/{{ object.object_data.e164_number }}" routerLinkActive="active">{{ object.object_data.e164_number }}</a>
                            </ng-container>
                        </div>
                    </div>

                    <div class="dropdown" *ngIf="ingress_numbers?.length">
                        <button class="btn btn-primary dropdown-toggle" type="button" id="dropdown-outbound-number" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Ingress By Number
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdown-ingress-number">
                            <a class="dropdown-item" routerLink="/call-stats/ingress">All</a>
                            <div class="dropdown-divider"></div>
                            <ng-container *ngFor="let object of ingress_numbers">
                                <a class="dropdown-item" *ngIf="object.object_type === 'Number'" routerLink="/call-stats/ingress/number/{{ object.object_data.e164_number }}" routerLinkActive="active">{{ object.object_data.e164_number }}</a>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <br>

                <div class="form-group">
                    <h4>From Date Range</h4>
                    <app-ngbd-datepicker-range #datepicker></app-ngbd-datepicker-range>
                    <br>
                    <button class="btn btn-danger" (click)="datepicker.clear();filter()">Clear Dates</button>
                </div>

                <div class="row">
                    <div class="col-3">
                        <div class="input-group-prepend">
                            <select class="custom-select" [(ngModel)]="search_by">
                                <option value="cli">Caller ID</option>
                                <option value="dest">Destination</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-9">
                        <input type="text" class="form-control" placeholder="Type Number Here" [(ngModel)]="search_term"> 
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <div style="display:flex;">    
                            <button class="btn btn-primary" (click)="filter()">Filter</button>
                            <button class="btn btn-primary" (click)="download()">Download CSV</button>
                        </div>  
                    </div>
                </div>
                <br>
                <ng-container *ngIf="cdrs else loading">
                    <ng-container [ngSwitch]="direction">
                        <ng-container *ngSwitchCase="'inbound'">
                            <ng-container *ngTemplateOutlet="list_inbound_stats"></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'outbound'">
                            <ng-container *ngTemplateOutlet="list_outbound_stats"></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'ingress'">
                            <ng-container *ngTemplateOutlet="list_ingress_stats"></ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <ng-container>
                    <div class="row">
                        <div class="col">
                            <h3>Graph Summary</h3>
                            <canvas id="chart-stats" style="max-height: 240px;"></canvas>
                        </div>
                    </div>
                    <br>
                </ng-container>
            </div>
            <div class="card-footer">
                <app-pagination [pagination]="cdrs" url="{{ getCurrentPath() }}"></app-pagination>
            </div>
        </div>
    </div>
</div>

<ng-template #list_inbound_stats>
    <!-- todo -->
    <div class="table-responsive no-more-table">

        <table class="table align-items-center table-flush table-float-header table-striped">

            <thead>
                <tr>
                    <th>Start Time</th>
                    <th>Received Number</th>
                    <th>Destination</th>
                    <th>Caller ID</th>
                    <th>Duration</th>
                </tr>
            </thead>
            <tbody *ngIf="cdrs && cdrs.data.length else no_cdrs">
                <tr *ngFor="let cdr of cdrs.data">
                    <td data-title="Start Time">{{ (cdr.start_time)|date:'medium' }}</td>
                    <td data-title="Received Number">{{ cdr.recv_number }}</td>
                    <td data-title="Destination">{{ cdr.dest || 'No Answer' }}</td>
                    <td data-title="cli">{{ cdr.cli }}</td>
                    <td data-title="Duration">{{ cdr.duration }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #list_outbound_stats>
    <!-- todo -->
    <div class="table-responsive no-more-table">
        <table class="table align-items-center table-flush table-float-header">
            <thead>
                <tr>
                    <th>Start Time</th>
                    <th>From Extension</th>
                    <th>Destination</th>
                    <th>Caller ID</th>
                    <th>Duration</th>
                    <th>Cost</th>
                </tr>
            </thead>
            <tbody *ngIf="cdrs && cdrs.data.length else no_cdrs">
                <tr *ngFor="let cdr of cdrs.data">
                    <td data-title="Start Time">{{ (cdr.start_time)|date:'medium' }}</td>
                    <td data-title="From Extension">{{ cdr.from_extension }}</td>
                    <td data-title="Destination">{{ cdr.dest || 'No Answer' }}</td>
                    <td data-title="cli">{{ cdr.cli }}</td>
                    <td data-title="Duration">{{ cdr.billsec }}</td>
                    <td data-title="Cost">&pound;{{ cdr.retail_cost|format_balance }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #list_ingress_stats>
    <!-- todo -->
    <div class="table-responsive no-more-table">
        <table class="table align-items-center table-flush table-float-header">
            <thead>
                <tr>
                    <th>Start Time</th>
                    <th>Origin</th>
                    <th>Destination</th>
                    <th>Caller ID</th>
                    <th>Duration</th>
                    <th>Cost</th>
                </tr>
            </thead>
            <tbody *ngIf="cdrs && cdrs.data.length else no_cdrs">
                <tr *ngFor="let cdr of cdrs.data">
                    <td data-title="Start Time">{{ (cdr.start_time)|date:'medium' }}</td>
                    <td data-title="Origin">{{ cdr.ingress_origin }}</td>
                    <td data-title="Destination">{{ cdr.dest || 'No Answer' }}</td>
                    <td data-title="cli">{{ cdr.cli }}</td>
                    <td data-title="Duration">{{ cdr.duration }}</td>
                    <td data-title="Cost">&pound;{{ cdr.retail_cost|format_balance }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #no_cdrs>
    <tbody>
        <tr>
            <td class="text-center" [attr.colspan]="6">
                No CDRs
            </td>
        </tr>
    </tbody>
</ng-template>

<ng-template #loading>
    <app-loadingbar></app-loadingbar>
</ng-template>
