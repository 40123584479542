export const ServiceTypes = {
    'Sip': 'fas fa-phone',
    'Voicemail': 'fas fa-microphone-alt',
    'Menu': 'fas fa-bars',
    'Group': 'fas fa-users',
    'Announcement': 'fas fa-bullhorn',
    'Queue': 'far fa-hourglass',
    'Number': 'fas fa-cloud',
    'Channel': 'fas fa-code-branch',
    'CallRecording': 'fas fa-compact-disc',
    'MusicOnHold': 'fas fa-music',
    'Shared Bundle': 'fas fa-cubes',
    'Remote Call Monitoring': 'far fa-eye',
};
export class SwitchboardObject {
    uuid: string;
    name: string;
    service_instance_id: number;
    object_type: string;
    extension_number: number;
    object_data: any;

    has_minutes: boolean;
    has_bundle: boolean;
    minutes_used: number;
    bundle: any;

    service_instance: any;

    selected: any;
}
